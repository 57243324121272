import React, { Component } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { resetPasswordUser, checkUserResetPasswordToken } from '../../../vibe/helpers/apiHelper';
import * as Icon from 'react-feather';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_password: '',
      user_confim_password: '',
      token: this.props.match.params.recover_code || null,
      isError: {
        user_password: '',
        user_confim_password: '',
        passwordMatch: '',
      },
      forSubmit: false,
      errorMessage: '',
    }
  }

  componentDidMount() {
    if (this.state.token) {
      checkUserResetPasswordToken(this.state.token, (result) => {
        if (result.message !== 'Token is valid') {
          window.location.href = "/forgot-password";
        }
      }, (error) => {
        this.setState({ errorMessage: error.message })
        //console.log(error);
      })
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ forSubmit: true })

    var validate = this.validateOnSubmit()
    if (validate) {
      this.onLoginClick()
    }

  }

  validateOnSubmit() {
    let isError = { ...this.state.isError };

    if (this.state.user_password.length === 0) {
      isError.user_password = 'Password is required';
    }
    if (this.state.user_confim_password.length === 0) {
      isError.user_confim_password = 'Password is required';
    }
    if (this.state.user_confim_password !== this.state.user_password) {
      isError.passwordMatch = 'Password does\'t match.';
    } else {
      isError.passwordMatch = ''
    }

    this.setState({
      isError,
    })

    return isError.user_password.length === 0 && isError.user_confim_password.length === 0 && isError.passwordMatch.length === 0
  }

  onLoginClick() {
    resetPasswordUser(this.state.token, this.state.user_password, this.state.user_confim_password, (result) => {
      if (result.message === 'Password reset successful') {
        // Redirect to login page with success message
        this.props.history.push({
          pathname: '/login',
          state: { successMessage: 'Password reset successful!\nPlease use your credentials to log in.' }
        });
      }
    }, (error) => {
      this.setState({ errorMessage: error.message })
      //console.log(error);
    })
  }



  onChange = e => {
    e.preventDefault();
    
    this.setState({ errorMessage: '' })

    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "user_password":
        isError.user_password =
          value.length < 8 ? "Password is required or less than 8 char" : "";
        isError.passwordMatch =
          value !== this.state.user_confim_password ? "Password does't match." : "";
        break;
      case "user_confim_password":
        isError.user_confim_password =
          value.length < 8 ? "Password is required or less than 8 char" : "";
        isError.passwordMatch =
          value !== this.state.user_password ? "Password does't match." : "";
        break;
      default:
        break;
    }

    this.setState({
      isError,
      [name]: value
    })
  }



  render() {
    const { isError, user_password, forSubmit, user_confim_password } = this.state;

    return (
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg"><span className="r"></span><span className="r s"></span><span className="r s"></span><span className="r"></span></div>
          <Card>
            <CardBody>
              <Form onSubmit={(e) => this.submitForm(e)} className='mx-auto' noValidate>
                <div className="text-center">
                  <div className="mb-4"><div className="auth-icon"><Icon.Key color="white" /></div></div>
                  <h5 className="mb-4">Please enter a new password</h5>
                </div>
                <FormGroup>
                  <Label for="user_password">Password</Label>
                  <Input type="password" name="user_password" id="user_password"
                    valid={isError.user_password.length === 0 && user_password.length > 0}
                    invalid={(isError.user_password.length > 0 || user_password.length === 0 || this.state.errorMessage.length > 0 || isError.passwordMatch.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_password.length > 0 && (
                    <span className="invalid-feedback">{isError.user_password}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="user_confim_password">Confirm Password</Label>
                  <Input type="password" name="user_confim_password" id="user_confim_password"
                    valid={isError.user_confim_password.length === 0 && user_confim_password.length > 0}
                    invalid={(isError.user_confim_password.length > 0 || user_confim_password.length === 0 || this.state.errorMessage.length > 0 || isError.passwordMatch.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_confim_password.length > 0 && (
                    <span className="invalid-feedback">{isError.user_confim_password}</span>
                  )}
                </FormGroup>
                <Button className={this.state.errorMessage || isError.passwordMatch ? 'is-invalid btn-block mb-4' : 'is-valid btn-block mb-4'} color="primary" type="submit">Reset Password</Button>
                {this.state.errorMessage && (
                  <span className="invalid-feedback mb-4">{this.state.errorMessage}</span>
                )}
                {isError.passwordMatch && (
                  <span className="invalid-feedback mb-4">{isError.passwordMatch}</span>
                )}

              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default Signup;
