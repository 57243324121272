import React, { useState } from 'react';
import ListHeader from '../../elements/form/ListHeader';
import FormDatePicker from '../common/FormDatePicker';
import { Button } from 'reactstrap';
import { getFeeLedgerPeriod } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import { pdfGeneratorFeeLedger } from '../../../vibe/components/utilities/pdfGenerator/pdfGeneratorFeeLedger';

const FeeLedger = () => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const handleStartDateChange = (event) => {
        setStartDate(event.target.value);
    };

    const handleEndDateChange = (event) => {
        setEndDate(event.target.value);
    };

    const handleGenerateStatement = () => {

        getFeeLedgerPeriod(startDate, endDate, async (result) => {
            await pdfGeneratorFeeLedger(result, startDate, endDate)
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })
    };

    return (
        <>
            <ListHeader title={'Fee Ledger'} links={''} />
            <div>
                <div className='row mb-3 align-items-center'>
                    <div className='col-3'>
                        <FormDatePicker label={'From'} onChange={handleStartDateChange} />
                    </div>

                    <div className='col-3'>
                        <FormDatePicker label={'To'} onChange={handleEndDateChange} />
                    </div>

                    <div className="col-3">
                        <Button color="primary" onClick={handleGenerateStatement}>Generate Statement</Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FeeLedger;
