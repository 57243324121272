import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { createAllOrganizationProductMedia, createOrganizationProduct, deleteAllOrganizationProductMedia, getOrganizationProduct, getOrganizationProductMedia, patchAllOrganizationProductMedia, patchOrganizationProduct } from '../../../../vibe/helpers/apiHelper';
import { prettyPrintDate } from '../../../../vibe/helpers/util';
import ArrayInput from '../../../elements/form/ArrayInput';
import { v4 as uuidv4 } from 'uuid';
import MediaSelect from '../../../elements/media/MediaSelect';
import Price from '../../../elements/form/Price';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import EditableForm from '../../common/EditableForm';

class EditOrganizationProduct extends EditableForm { 

    constructor(props) {
        super(props);
        this.state = {
            organizationProductMedia: [],
            editedOrganizationProductMedia: [],
            pendingMedia: [],
            deletedMedia: []
        }
    }

    getItem() {
        let id = this.props.match.params.id
        this.setState({pendingMedia: [], deletedMedia: [], organizationProductMedia: [], editedOrganizationProductMedia: [], loading: true})
        if (id) {
            getOrganizationProduct(id, (organizationProduct) => {
                this.setState({item: organizationProduct, loading: false, error: null})
            }, (error) => {
                this.setState({error: error, loading: false})
            })
            
            getOrganizationProductMedia(id, (media) => {
                this.setState({organizationProductMedia: media})
            }, (error) => {

            })
        } else {
            this.setState({new: true, item: { title: null, archive: false}, loading: false, error: null})
        }
    }

    renderForm(organizationProduct) {
        return <Form>
            <Row>
                <Col sm="6">
              
                    <FormGroup>
                        <Label for="title">Title</Label>
                        <Input type="text" name="title" id="title" defaultValue={organizationProduct.title} onChange={(e) => this.onChange(e.target.value, 'title')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="created_at">Created at</Label>
                        <Input type="text" name="created_at" id="created_at" defaultValue={prettyPrintDate(organizationProduct.created_at)} disabled />
                    </FormGroup>

                    <FormGroup>
                        <Label for="updated_at">Updated at</Label>
                        <Input type="text" name="updated_at" id="updated_at" defaultValue={prettyPrintDate(organizationProduct.updated_at)} disabled />
                    </FormGroup>

                    <FormGroup>
                        <Label for="description">Description</Label>
                        <Input type="text" name="description" id="description" defaultValue={organizationProduct.description} onChange={(e) => this.onChange(e.target.value, 'description')} />
                    </FormGroup>

                    <FormGroup>
                        <Price price={organizationProduct.price} currency={organizationProduct.currency} onChange={this.onChange.bind(this)} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="variable">Variable (comma separated list of values, for example: Small,Medium,Large)</Label>
                        <ArrayInput name="variable" value={organizationProduct.variable} onChange={this.onChange.bind(this)} />
                    </FormGroup>

                    {this.renderMultipleMediaSelect()}

                    <FormGroup check>
                        <Label for="active">
                            <Input type="checkbox" name="active" id="active" defaultChecked={organizationProduct.active} onChange={(e) => this.onChange(e.target.checked, 'active')} />{' '}
                            Active
                        </Label>
                    </FormGroup>

                    </Col>

                    <Col sm="6">
                        <FormGroup>
                            <Label for="content">Content</Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={organizationProduct.content}
                                onReady={editor => {
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange(data, 'content')
                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />
                        </FormGroup>
                    </Col>
                  
                </Row>
            </Form>

    }

    onAddMedia() {
        let pendingMedia = this.state.pendingMedia
        pendingMedia.push(-1)
        this.setState({pendingMedia: pendingMedia})
    }

    onChangePendingMedia(mediaId, index) {
        let pendingMedia = this.state.pendingMedia
        pendingMedia[index] = mediaId
        this.setState({pendingMedia: pendingMedia})
    }

    onDeleteMedia(organizationProductMediaId) {
        let deletedMedia = this.state.deletedMedia
        deletedMedia.push(organizationProductMediaId)
        this.setState({deletedMedia: deletedMedia})
    }

    onDeletePendingMedia(index) {
        let pendingMedia = this.state.pendingMedia
        pendingMedia.splice(index, 1);
        this.setState({pendingMedia: pendingMedia})
    }

    onChangeMedia(mediaId, organizationProductMedia) {
        let allOrganizationProductMedia = this.state.organizationProductMedia
        allOrganizationProductMedia.forEach(item => {
            if (item.id === organizationProductMedia.id) (
                item.media_id = mediaId
            )
        })

        var editedOrganizationProductMedia = this.state.editedOrganizationProductMedia
        var editedProductMedia = editedOrganizationProductMedia.find(editedProductMedia => editedProductMedia.id === organizationProductMedia.id)
        if (editedProductMedia) {
            editedProductMedia.media_id = mediaId
        } else {
            let data = {
                id: organizationProductMedia.id,
                media_id: mediaId
            }
            editedOrganizationProductMedia.push(data)
        }

        this.setState({editedOrganizationProductMedia: editedOrganizationProductMedia, organizationProductMedia: allOrganizationProductMedia})
    }

    renderMultipleMediaSelect() {

        return <React.Fragment>
            <Label for="image">Images</Label>
            {this.state.organizationProductMedia.map((organizationProductMedia, index) => {

                // dont show deleted media
                if (this.state.deletedMedia.includes(organizationProductMedia.id)) {
                    return null
                }

                return <FormGroup className="d-flex" key={organizationProductMedia.id + '_' + organizationProductMedia.media_id}>
                            <MediaSelect files={this.state.files} item={organizationProductMedia} index={index} mediaId={organizationProductMedia.media_id} itemId={organizationProductMedia.id}
                                onChange={(id, item, index) => this.onChangeMedia(id, item)} onDelete={(itemId, index) => this.onDeleteMedia(itemId)} />
                </FormGroup>
            })}

            {this.state.pendingMedia.map((mediaId, index) => {
                return <FormGroup className="d-flex" key={uuidv4()}>
                            <MediaSelect files={this.state.files} item={index} index={index} mediaId={mediaId} itemId={index}
                                onChange={(id, item, index) => this.onChangePendingMedia(id, index)} onDelete={(itemId, index) => this.onDeletePendingMedia(index)} />
                </FormGroup>
            })}
            
            <Button className="mt-2 mb-4" color="primary" onClick={() => this.onAddMedia()}><i className="fa fa-plus"></i>&nbsp;Add image</Button>{' '}

        </React.Fragment>
    }

    onDelete() {
        let data = {
            id: this.state.item.id,
            archive: true
        }
        patchOrganizationProduct(data, async () => {
            setTimeout(() => {
                this.props.history.replace('/organization-products')
            }, 1000)
        }, (error) => {
            this.onFormError(error)
        })
    }

    hasDeleteButton() {
        return true
    }

    onSave() {
        this.createOrUpdateProduct(this.state.new, () => {
            this.onFormSuccess()
        }, (error) => {
            this.onFormError(error)
        })
    }

    async updateProductMedia(productId) {
        await createAllOrganizationProductMedia(productId, this.state.pendingMedia, null, null)
        await patchAllOrganizationProductMedia(this.state.editedOrganizationProductMedia)
        await deleteAllOrganizationProductMedia(this.state.deletedMedia)
    }

    createOrUpdateProduct(create, onSuccess, onError) {
        if (create) {
            createOrganizationProduct(this.state.item, async (result) => {
                onSuccess()
                await this.updateProductMedia(result.id)
                setTimeout(() => {
                    this.props.history.replace('/organization-products/edit/' + result.id)
                  }, 2000)
            }, (error) => {
                onError(error)
            })
        } else {
            patchOrganizationProduct(this.state.item, async () => {
                onSuccess()
                await this.updateProductMedia(this.state.item.id)
                this.getItem()
            }, (error) => {
                onError(error)
            })
        }
    }

}
export default EditOrganizationProduct
