import React, { Component } from 'react';
import { Card, Button } from 'reactstrap';
import { changeVerifyStudentUsers } from '../../../vibe/helpers/apiHelper';


class ManageUsersTemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: this.props.user.status,
        };
    }

    toggleStatus = () => {
        const { user, onError } = this.props;
 
        changeVerifyStudentUsers(
            user.id,
            !user.status,
            (result) => {
                this.setState((prevState) => ({
                    status: !prevState.status,
                }));
            },
            (error) => {
                onError('Error changing user status'); // Send error message to parent
                this.setState((prevState) => ({
                    status: !prevState.status,
                }));
            }
        );
    };

    render() {

        return <Card className={this.state.status ? "card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero" : "card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero fade-card"}>

            <div className="mb-1 text-small w-25 w-sm-100">
                <p className="list-item-heading mb-1">ID</p>
                <p className="list-item-text mb-0">{this.props.user.id}</p>
            </div>

            <div className="mb-1 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Status</p>
                <p className={this.state.status ? 'list-item-text mb-0 text-success' : 'list-item-text mb-0 text-danger'}>{this.state.status ? 'Active' : 'Inactive'}</p>
            </div>

            <div className="mb-1 text-small w-75 w-sm-100">
                <p className="list-item-heading mb-1">Email</p>
                <p className="list-item-text mb-0">{this.props.user.email}</p>
            </div>


            <div className="mb-1 text-small w-75 w-sm-100">
                <p className="list-item-heading mb-1">First Name</p>
                <p className="list-item-text mb-0">{this.props.user.first_name}</p>
            </div>

            <div className="mb-1 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Last Name</p>
                <p className="list-item-text mb-0">{this.props.user.last_name}</p>
            </div>


            <div className="mb-1 text-small w-25 w-sm-100 mr-5">
                <Button style={{minWidth: '100px'}} className={'d-inline-block m-0'} onClick={this.toggleStatus} color={this.state.status ? 'danger' : 'success'}>
                    {this.state.status ? 'Deactivate' : 'Activate'}
                </Button>
            </div>

        </Card>

    }

}

export default ManageUsersTemplate;
