import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';


class MemberDetails extends Component {

  render() {
    return (
        <Card><h4 style={{paddingLeft: "20px", paddingTop:"20px"}}>Member Details</h4>
        <CardBody className='card-body d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
          <div className="mb-0 text-small w-75 w-sm-100">
            <p className="list-item-heading mb-1">Full Name</p>
            <p className="list-item-text mb-0">{this.props?.member?.first_name} {this.props?.member?.last_name}</p>
          </div>
          <div className="mb-0 text-small w-75 w-sm-100">
            <p className="list-item-heading mb-1">Member ID</p>
            <p className="list-item-text mb-0">{this.props?.member?.id}</p>
          </div>
          <div className="mb-0 text-small w-75 w-sm-100">
            <p className="list-item-heading mb-1">Email</p>
            <p className="list-item-text mb-0">{this.props?.member?.email}</p>
          </div>
          <div className="mb-0 text-small w-75 w-sm-100">
            <p className="list-item-heading mb-1">PIN</p>
            <p className="list-item-text mb-0">{this.props?.member?.personal_identification_number}</p>
          </div>
          </CardBody>
        </Card>
    );
  }

}

export default MemberDetails;

