import { createStandaloneToast } from '@chakra-ui/toast'
const Toast = createStandaloneToast()

export async function toastMessage(result, status) {
    var message
    if (typeof result === 'object') {
        message = 'Succeeded: <b class="pr-4">' + result.succeeded + '</b>Failed: <b class="pr-4">' + result.failed + '</b>Skipped: <b>' + result.skipped + '</b>'
    } else {
        message = result
    }
    return Toast({
        title: 'Import Finished!',
        description: <div dangerouslySetInnerHTML={{ __html: message }} />,
        status: status,
        duration: 9000,
        variant: "left-accent",
        position: "bottom-left",
        isClosable: true
    });
}

export async function toastMessageLoading(title, message, status) {
    return Toast({
        title: title,
        description: message,
        status: status,
        duration: null,
        variant: "left-accent",
        position: "bottom-left",
    });
}

export async function toastClose(tid){
    Toast.close(tid)
}