import React, { Component } from 'react';
import {
    Button, Card
} from 'reactstrap';
import { prettyPrintDate } from '../../../vibe/helpers/util';

class MemberDeleteRequest extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    onDeleteDeleteRequest(deleteRequest) {
        this.props.onAddTransactionLogToDelete(deleteRequest)
    }

    isPendingDelete(deleteRequest) {
        const logs = this?.props?.transactionLogsToDelete
        if (!logs || logs.length <= 0) {
            return false
        }
        return logs.some(transactionLogToDelete => transactionLogToDelete.id === deleteRequest.id)
    }

    getCardBackgroundClass(deleteRequest) {
        const isPendingDelete = this.isPendingDelete(deleteRequest)
        var backgroundClass = ''
        if (isPendingDelete) {
            backgroundClass = 'bg-danger'
        }
        return backgroundClass
    }

    render() {
        const deleteRequest = this?.props?.deleteRequest
        if (!deleteRequest) {
            return null
        }

        const data = JSON.parse(deleteRequest.data)

        const backgroundClass = this.getCardBackgroundClass(deleteRequest)

        return  <Card className={"card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero " + backgroundClass}>
            <div className="mb-0 text-small w-25 w-sm-100">
                <p className="list-item-heading mb-1">ID</p>
                <p className="list-item-text mb-0">{deleteRequest.id}</p>
            </div>
            <div className="mb-0 text-small w-25 w-sm-100">
                <p className="list-item-heading mb-1">Created at</p>
                <p className="list-item-text mb-0">{prettyPrintDate(deleteRequest.created_at)}</p>
            </div>
            <div className="mb-0 text-small w-25 w-sm-100">
                <p className="list-item-heading mb-1">Data</p>
                {Object.keys(data).map((key) => {
                    return <p key={"keys_" + key} className="list-item-text mb-0">{key}: {data[key]}</p>
                })}
            </div>
            <div className="mb-0 text-small w-25 w-sm-100">
                <Button color="danger" onClick={(e) => {this.onDeleteDeleteRequest(deleteRequest)}}><i className="fa fa-trash"></i>&nbsp;Delete</Button>
            </div>
        </Card>
    }

}

export default MemberDeleteRequest;