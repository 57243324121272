import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import NavSpacer from './components/NavSpacer';
import NavDivider from './components/NavDivider';
import NavSingleItem from './components/NavSingleItem';
import NavDropdownItem from './components/NavDropdownItem';
import PageAlertContext from '../PageAlert/PageAlertContext';
import SidebarToggleButton from '../Header/components/ToggleSidebarButton';
import * as Icon from 'react-feather';
import { removeTokens, getRefreshToken } from '../../../views/pages/login/Token';

export default class SidebarNav extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.myRef = React.createRef()
  }

  componentDidMount() {
    this.checkSideBarWidth()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isSidebarCollapsed !== prevProps.isSidebarCollapsed) {
      this.checkSideBarWidth()
    }
  }

  checkSideBarWidth() {
    var element = this.myRef.current.getBoundingClientRect().width
    this.setState({ sideBarWidth: element })
  }

  hasItemPermission(item) {
    if (!this.props.user) {
      return false
    }
    else if (item.role && !item.role.includes(this.props.user.role)) {
      return false
    }
    else if (this.props.user.role === 'Union' && item.allowedUnion && !item.allowedUnion.includes(this.props.user.organization_id)) {
      return false
    }
    return true
  }

  render() {
    const navItems = items => {
      return items.map((item, index) => itemType(item, index));
    };

    const itemType = (item, index) => {

      if (!this.hasItemPermission(item)) {
        return null
      }

      if (item.children) {
        item.children = item.children.filter((child) => { return this.hasItemPermission(child) })
        return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
      } else if (item.divider) {
        return <NavDivider key={index} />;
      } else {
        return <NavSingleItem item={item} key={index} />;
      }
    };

    const NavBrand = ({ logo, logo_icon, logoText }) => {
      return (
        <div className="site-logo-bar">
          <NavLink to="/" className="navbar-brand">
            {this.state.sideBarWidth === 50 ?
              logo_icon && <img src={logo_icon} alt="" />
              : logo && <img src={logo} alt="" />}
            {logoText && <span className="logo-text">{logoText}</span>}
          </NavLink>
        </div>
      );
    };

    return (
      <PageAlertContext.Consumer>
        {consumer => {
          const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
          return (
            <div>
              <div ref={this.myRef} className={`app-sidebar ${hasPageAlertClass}`}>
                <NavBrand logo={this.props.logo} logo_icon={this.props.logo_icon} logoText={this.props.logoText} />
                <nav>
                  <ul id="main-menu">
                    {navItems(this.props.nav.top)}

                    {getRefreshToken() &&
                      <li className="nav-item">
                        <a href="/login" onClick={() => {
                          removeTokens()
                        }}>
                          <Icon.LogOut size="20" className="side-nav-icon" />
                          <span className="nav-item-label">Logout</span>
                        </a>
                      </li>}

                    <NavSpacer />
                    <SidebarToggleButton
                      toggleSidebar={this.props.toggleSidebar}
                      isSidebarCollapsed={this.props.isSidebarCollapsed}
                      desktop={true}
                    />
                    {/* {navItems(this.props.nav.bottom)} */}
                  </ul>
                </nav>
              </div>
              {/* {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />} */}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
    );
  }
}
