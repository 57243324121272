import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';

class PayoutOrganization extends Component {

  render() {
    return (
      <Link className="member-card-link" from={'/payouts'} to={'/payouts/organization/' + this.props.organization.id} key={this.props.organization.id}>
        <Card className={"cursor-pointer card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"}>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Organization Name</p>
            <p className="list-item-text mb-0">{this.props.organization.display_name}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Organization ID</p>
            <p className="list-item-text mb-0">{this.props.organization.id}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            {this.props.hasPendingTransactions &&
              <div className="pendingTag">
                <small>Pending</small>
                <svg xmlns="http://www.w3.org/2000/svg" width="45px" height="45px" viewBox="0 0 24 24">
                  <circle cx={18} cy={12} r={0} fill="currentColor">
                    <animate attributeName="r" begin={0.67} calcMode="spline" dur="1.5" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="2;3;2;2">
                    </animate>
                  </circle>
                  <circle cx={12} cy={12} r={0} fill="currentColor">
                    <animate attributeName="r" begin={0.33} calcMode="spline" dur="1.5" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="2;3;2;2">
                    </animate>
                  </circle>
                  <circle cx={6} cy={12} r={0} fill="currentColor">
                    <animate attributeName="r" begin={0} calcMode="spline" dur="1.5" keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8" repeatCount="indefinite" values="2;3;2;2">
                    </animate>
                  </circle>
                </svg>
              </div>
            }
          </div>
        </Card>
      </Link>
    );
  }

}

export default PayoutOrganization;
