import React from 'react';
import { QrReader } from 'react-qr-reader';
import { Button, Card, Col, Modal, ModalBody, ModalHeader, Row, UncontrolledAlert } from 'reactstrap';
import { getMemberTicketByHash, getMemberTicketValidations, validateMemberTicket, validateMemberTicketForOccurrence, getOrganizationEventsWithTickets, getCurrentUser } from '../../../../../vibe/helpers/apiHelper';
import { parseErrorMessage, prettyPrintDate, superPrettyPrintDate } from '../../../../../vibe/helpers/util';
import { v4 as uuidv4 } from 'uuid';
import EventAttendees from '../EventAttendees';
import MembershipScanner from '../../../../elements/membership/scanner/MembershipScanner';


const TICKET_SCAN_ON_ENTRY = 1
const TICKET_SCAN_ANY_TICKET = 2
const VIEW_EVENT_ATTENDEES = 3

// TODO DEV-2355 cleanup this file
class TicketScanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            data: null,
            lastScanHash: '',
            lastScanHashStop: false,
            memberTicketValidate: null,
            memberTicketValidateHash: null,
            scanResult: null,
            memberTicketValidations: [],
            viewHistory: false,
            selectedOccurrence: null,
            selectedEvent: null,
            organizationEvents: [],
            selectedType: null,
            opened: false,
            occurrence: null
        }
        this.toggleModal = this.toggleModal.bind(this)
    }

    componentDidMount() {
        getCurrentUser((user) => {
            getOrganizationEventsWithTickets(user.organization_id, (organizationEvents) => {
                console.log("organizationEvents", user.organization_id, organizationEvents)
                this.setState({ organizationEvents: organizationEvents })
            }, (error) => {
                console.log("error", error)
            })
        })
    }

    /**
     * Updates the ticket count (count, tickets_sold, tickets_validated)
     */
    refreshSelectedEvent(selectedEvent, selectedOccurrence) {

        getCurrentUser((user) => {
            getOrganizationEventsWithTickets(user.organization_id, (organizationEvents) => {

                if (!organizationEvents || organizationEvents.length <= 0) {
                    return
                }

                for (var event of organizationEvents) {
                    if (event.id === selectedEvent.id) {

                        if (!event.organization_event_occurrences || event.organization_event_occurrences.length <= 0) {
                            return
                        }

                        for (var occurrence of event.organization_event_occurrences) {
                            if (occurrence.id === selectedOccurrence.id) {
                                this.setState({ selectedEvent: event, selectedOccurrence: occurrence })
                            }
                        }
                    }
                }

            }, (error) => {
                console.log("error", error)
            })
        })
    }

    willScanQrCode(hash) {
        if (hash && this.state.lastScanHashStop && hash === this.state.lastScanHash) {
            console.log("already scanned, returning...")
            return
        } else {
            this.setState({ lastScanHash: hash, lastScanHashStop: true, memberTicketValidate: null, memberTicketValidateHash: null }, () => {
                this.didScanQrCode(hash)
            })

            setTimeout(() => {
                this.setState({ lastScanHashStop: false })
                console.log("can scan again")
            }, 5000);
        }
    }

    didScanQrCode(resultText) {

        if (resultText && resultText === this.state.lastScanHash) {
            console.log("Scanning same ticket again...")
        }

        if (this.state.selectedType && this.state.selectedType.id === TICKET_SCAN_ANY_TICKET) {

            getMemberTicketValidations(resultText, (memberTicketValidations) => {

                // memberTicketValidations should be empty if not scanned before. 
                // We just fetch all previous validations for this ticket

                let key = uuidv4()

                // warning, has been scanned before
                if (memberTicketValidations && memberTicketValidations.length > 0) {

                    // get last scanned ticket validation
                    let lastMemberTicketValidation = memberTicketValidations[memberTicketValidations.length - 1]

                    let scannedBy = lastMemberTicketValidation.user.first_name + ' ' + lastMemberTicketValidation.user.last_name
                    this.setState({
                        memberTicketValidations: memberTicketValidations,
                        lastMemberTicketValidation: lastMemberTicketValidation,
                        scanResult: {
                            status: 'warning',
                            color: 'warning',
                            title: 'Ticket has already been scanned',
                            message: 'Last scan at ' + prettyPrintDate(lastMemberTicketValidation.created_at) + ' by ' + scannedBy,
                            key: key
                        }
                    })

                } else {

                    getMemberTicketByHash(resultText, (memberTicket) => {
                        console.log("memberTicket yas", memberTicket)
                        // success? show accept/deny options

                        this.setState({
                            memberTicketValidations: [],
                            lastMemberTicketValidation: null,
                            memberTicketValidate: memberTicket,
                            memberTicketValidateHash: resultText,
                            scanResult: {
                                status: 'validate',
                                color: 'success',
                                title: 'Ticket is valid',
                                message: 'Accept/deny?',
                                member_ticket: memberTicket,
                                key: key
                            }
                        })


                    }, (error) => {
                        let key = uuidv4()

                        let errorMessage = parseErrorMessage(error)
                        console.log("SCAN RESULT: FAIL, error: " + errorMessage)

                        this.setState({ scanResult: { status: 'fail', color: 'danger', title: 'Scan failed', message: errorMessage, key: key } })
                    })

                }

            }, (error) => {
                let key = uuidv4()

                let errorMessage = parseErrorMessage(error)
                console.log("SCAN RESULT: FAIL, error: " + errorMessage)

                this.setState({ scanResult: { status: 'fail', color: 'danger', title: 'Scan failed', message: errorMessage, key: key } })
            })

        } else {

            validateMemberTicketForOccurrence(this.state.selectedOccurrence.id, resultText, (memberTicketValidations) => {

                let key = uuidv4()

                // fail, red
                if (!memberTicketValidations || memberTicketValidations.length <= 0) {
                    console.log("SCAN RESULT: FAIL")
                    this.setState({ memberTicketValidations: memberTicketValidations, scanResult: { status: 'fail', color: 'danger', title: 'Scan failed', message: 'Unknown error', key: key } })
                }

                // green, only 1 scan
                if (memberTicketValidations && memberTicketValidations.length === 1) {
                    console.log("SCAN RESULT: SUCCESS")

                    // refresh selected event/occurrence since tickets_validated is updated
                    this.refreshSelectedEvent(this.state.selectedEvent, this.state.selectedOccurrence)

                    let memberTicketValidation = memberTicketValidations[0]
                    let member = memberTicketValidation?.member_ticket?.member
                    let ticketName = memberTicketValidation?.member_ticket?.tickets?.name

                    let name
                    if (member?.first_name || member?.last_name) {
                        name = member?.first_name + ' ' + member?.last_name
                    } else {
                        name = 'No name provided'
                    }

                    let personal_identification_number = member?.personal_identification_number

                    this.setState({ memberTicketValidations: memberTicketValidations, scanResult: { status: 'success', color: 'success', title: 'Ticket scanned', name: name, personal_identification_number: personal_identification_number, ticketName: ticketName, key: key } })

                }

                // yellow? have been scanned before
                if (memberTicketValidations && memberTicketValidations.length > 1) {

                    console.log("SCAN RESULT: WARNING")

                    // the last was is the one that was scanned just now, we want the one before that
                    let lastMemberTicketValidation = memberTicketValidations[memberTicketValidations.length - 2]

                    let scannedBy = lastMemberTicketValidation.user.first_name + ' ' + lastMemberTicketValidation.user.last_name

                    this.setState({
                        memberTicketValidations: memberTicketValidations,
                        lastMemberTicketValidation: lastMemberTicketValidation,
                        scanResult: {
                            status: 'warning',
                            color: 'warning',
                            title: 'Ticket has already been scanned',
                            message: 'Last scan at ' + prettyPrintDate(lastMemberTicketValidation.created_at) + ' by ' + scannedBy,
                            key: key
                        }
                    })

                }

            }, (error) => {
                let key = uuidv4()

                let errorMessage = parseErrorMessage(error)
                console.log("SCAN RESULT: FAIL, error: " + errorMessage)

                this.setState({ scanResult: { status: 'fail', color: 'danger', title: 'Scan failed', message: errorMessage, key: key } })
            })

        }


    }

    // TODO REMOVE THIS FUNCTION
    onTestScan() {
        // asdgfdghfsfgfas
        this.willScanQrCode('6669c0f9d226991224b2b8ec2aa5bbc4')
    }


    renderScanResult(scanResult) {

        if (!scanResult) {
            return null
        }

        let message
        let messageHeader
        let messageSubHeader
        let memberTicket

        if (scanResult.status === 'validate') {

            memberTicket = <span style={{ color: 'black' }}>
                <div>Member name: <b>{scanResult?.member_ticket?.member.first_name + ' ' + scanResult?.member_ticket?.member.last_name}</b></div>
                <div>Ticket name: <b>{scanResult?.member_ticket?.tickets.name}</b></div>
                <div>Occurrence: <b>{scanResult?.member_ticket?.tickets?.organization_event_occurrence?.name}</b></div>
                <div>Event: <b>{scanResult?.member_ticket?.tickets?.organization_event_occurrence?.organization_event?.title}</b></div>
            </span>

        } else if (scanResult.status === 'success') {
            messageHeader = scanResult.name
            if (scanResult.personal_identification_number) {
                messageSubHeader = scanResult.personal_identification_number
            }
            message = scanResult.ticketName

        } else if (scanResult.status === 'fail') {
            message = scanResult.message
        } else if (scanResult.status === 'warning') {
            message = scanResult.message
        }

        // set key so that we can close the alert, and then open a new one later...
        return <UncontrolledAlert color={scanResult.color} key={scanResult.key}>
            <h3>{scanResult.title}</h3>
            {memberTicket ? memberTicket : null}
            {messageHeader ? <div style={{ color: 'black' }}>{messageHeader}</div> : null}
            {messageSubHeader ? <div style={{ color: 'black' }}>{messageSubHeader}</div> : null}
            {message ? <p style={{ color: 'black' }}>{message}</p> : null}
        </UncontrolledAlert>

    }

    onViewHistory() {
        this.setState({ viewHistory: true })
    }

    renderHistory(memberTicketValidations) {
        if (!memberTicketValidations || memberTicketValidations.length <= 0) {
            return null
        }

        // header - information abount member/tickets/occurrence/event
        let member
        let tickets
        let event
        let occurrences
        if (memberTicketValidations[memberTicketValidations.length - 1]) {
            let lastMemberTicketValidation = memberTicketValidations[memberTicketValidations.length - 1]
            member = lastMemberTicketValidation.member_ticket.member
            tickets = lastMemberTicketValidation.member_ticket.tickets
            occurrences = tickets.organization_event_occurrences
            event = tickets.organization_event_occurrences[0].organization_event
        }

        // validation history list
        let ticketsRender = memberTicketValidations.map((memberTicketValidation) => {
            let scannedBy = memberTicketValidation.user.first_name + ' ' + memberTicketValidation.user.last_name
            let scannedAt = prettyPrintDate(memberTicketValidation.created_at)

            return <React.Fragment key={memberTicketValidation.id}>
                <p>Scanned at {scannedAt} by {scannedBy}</p>
            </React.Fragment>
        })

        let occurrencesList = occurrences.map((occurrence) => {
            return <div><b>{occurrence.name}, {prettyPrintDate(occurrence.start_date)} - {prettyPrintDate(occurrence.end_date)}</b></div>
        })

        return <Modal scrollable={true} style={{ maxWidth: '96%' }} isOpen={!!this.state.viewHistory} toggle={() => this.setState({ viewHistory: !this.state.viewHistory })} className="previewModal">
            <ModalHeader toggle={() => this.setState({ viewHistory: !this.state.viewHistory })}>
                <>
                    <div className='h3'>{'Ticket info'}</div>
                    <div>Member name: <b>{member.first_name + ' ' + member.last_name}</b></div>
                    <div>Ticket name: <b>{tickets.name}</b></div>
                    <div>Event: <b>{event.title}</b></div>
                    <div>Occurrences: {occurrencesList}</div>
                </>
            </ModalHeader>
            <ModalBody>
                <div className='h3'>Previous scans</div>
                {ticketsRender}
            </ModalBody>
        </Modal>
    }

    onSelectType(type) {
        if (type.id === VIEW_EVENT_ATTENDEES) {
            return
        }
        this.setState({ selectedType: type })
    }

    onSelectEvent(event) {
        console.log("onSelectEvent", event)
        this.setState({ selectedEvent: event })
    }

    onSelectOccurrence(organizationEventOccurrence) {
        console.log("onSelectOccurrence", organizationEventOccurrence)
        this.setState({ selectedOccurrence: organizationEventOccurrence })
    }

    toggleModal(occurrence) {
        this.setState(prevState => ({
            opened: !prevState.opened,
            occurrence: occurrence
        }))
      }

    isAttendeesModalOpen() {
        return this.state.opened
    }

    renderEventAttendees() {
        var organizationEvent = this.state.selectedEvent
        var occurrence = this.state.selectedOccurrence

        if(!occurrence) {
            return null
        }
        return <>
            <EventAttendees organizationEvent={organizationEvent} onlyDisplayTab={'2'} id={organizationEvent.id} eventId={organizationEvent.id} occurrenceId={occurrence.id} toggle={this.toggleModal} isOpen={this.isAttendeesModalOpen()}/>
        </>
    }

    renderScanTitle() {
        if (this.state.selectedEvent && this.state.selectedOccurrence) {
            return <>
                <h6 className="text-center">{this.state.selectedEvent.title}</h6>
                {/* <h5>{this.state.selectedOccurrence.name}</h5> */}
                <strong><p className="text-center list-item-text mb-0">{superPrettyPrintDate(this.state.selectedOccurrence.start_date)}</p></strong>

                <QrReader
                constraints={{
                    facingMode: 'environment'
                }}
                onResult={(result, error) => {
                    if (!!result) {
                        this.willScanQrCode(result?.text)
                    }

                    if (!!error && JSON.stringify(error) !== JSON.stringify({})) {
                        console.log("error", error);
                    }
                }}
                containerStyle={{padding: '25px 0'}}
                style={{ width: '100%' }} />

                <p className="mb-0 text-center">Tickets scanned:</p>
                <br/>
                <h2 className="text-center">{this.state.selectedOccurrence.ticket_statistics.validated} / {this.state.selectedOccurrence.ticket_statistics.sold}</h2>
                <div className='d-flex justify-content-center'>
                    <Button size="lg" className="px-5 mt-4" color="primary" onClick={() => this.toggleModal()}>Guest List</Button>
                </div>
                {this.state.opened && this.renderEventAttendees()}
            </>
        }
        return null
    }

    onAcceptMemberTicket(memberTicketValidate, hash) {
        if (!hash) {
            return
        }

        console.log("memberTicketValidate", memberTicketValidate)
        validateMemberTicket(hash, (memberTicketValidations) => {
            console.log("memberTicketValidations", memberTicketValidations)

            // todo set to null?
            // this.state.memberTicketValidate, this.state.memberTicketValidateHash
            this.setState({ memberTicketValidate: null, memberTicketValidateHash: null })

            let key = uuidv4()

            // fail, red
            if (!memberTicketValidations || memberTicketValidations.length <= 0) {
                console.log("SCAN RESULT: FAIL")
                this.setState({ memberTicketValidations: memberTicketValidations, scanResult: { status: 'fail', color: 'danger', title: 'Scan failed', message: 'Unknown error', key: key } })
            }

            // green, only 1 scan
            if (memberTicketValidations && memberTicketValidations.length >= 1) {
                console.log("SCAN RESULT: SUCCESS")
                let memberTicketValidation = memberTicketValidations[0]
                let member = memberTicketValidation?.member_ticket?.member
                let ticketName = memberTicketValidation?.member_ticket?.tickets?.name

                let name
                if (member?.first_name || member?.last_name) {
                    name = member?.first_name + ' ' + member?.last_name
                } else {
                    name = 'No name provided'
                }

                let personal_identification_number = member?.personal_identification_number

                this.setState({ memberTicketValidations: memberTicketValidations, scanResult: { status: 'success', color: 'success', title: 'Ticket scanned', name: name, personal_identification_number: personal_identification_number, ticketName: ticketName, key: key } })

            }

        }, (error) => {
            let key = uuidv4()

            let errorMessage = parseErrorMessage(error)
            console.log("SCAN RESULT: FAIL, error: " + errorMessage)

            this.setState({ scanResult: { status: 'fail', color: 'danger', title: 'Scan failed', message: errorMessage, key: key } })
        })
    }

    renderMemberTicketActions(memberTicketValidate, hash) {
        if (memberTicketValidate) {
            // <Button className='ml-2' color="primary" onClick={() => this.onTestScan()}><i className="mr-1" />Test Scan</Button>
            // <div>Accept</div> <div>Deny</div>
            return <Row>
                <Col>
                    <Button className='w-full' color="primary" onClick={() => this.onAcceptMemberTicket(memberTicketValidate, hash)}><i className="mr-1" />Accept</Button>
                </Col>
                {/* <Col>
                    <Button className='w-full' color="primary" onClick={() => this.onTestScan()}><i className="mr-1" />Deny</Button>
                </Col> */}
            </Row>
        }
        return null
    }

    organizationEventHasGuests(organizationEvent) {
        if (!organizationEvent.organization_event_occurrences) {
            return false;
        }
        for (const occurrence of organizationEvent.organization_event_occurrences) {
            if (occurrence.organization_event_occurrence_guests) {
                return true; 
            } 
        }
        return false; 
    }
    
    getOrganizationEventGuestCount(organizationEvent) {
        if (!organizationEvent.organization_event_occurrences) {
            return 0;
        }
        let guestCount = 0;
        for (const occurrence of organizationEvent.organization_event_occurrences) {
            if (occurrence.organization_event_occurrence_guests) {
                guestCount += occurrence.organization_event_occurrence_guests.length;
            }
        }
        return guestCount;
    }
    

    render() {
        
        if (this.state.selectedType && this.state.selectedType.id === TICKET_SCAN_ANY_TICKET) {
            console.log("scan any ticket!")
        } else if (!this.state.selectedType) {

            let types = [{
                id: TICKET_SCAN_ON_ENTRY,
                name: 'Scan ticket for event'
            },
                // {
                //     id: TICKET_SCAN_ANY_TICKET,
                //     name: 'Scan any ticket and validate'
                // },
                // {
                //     id: VIEW_EVENT_ATTENDEES,
                //     name: 'View event attendees (coming soon)'
                // }
            ]
            let tScan = types.map((type) => {
                return <div className="mw-350 text-center align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                    <div onClick={(e) => this.onSelectType(type)} className="card-body" key={type.id}>
                        <div className="mb-0 text-small w-sm-100">
                            {/* <p className="list-item-heading mb-1">Title</p> */}
                            <strong><p className="mb-0">{type.name}</p></strong>
                            <img className="" src="/scanner.png" alt="scanner" />
                        </div>
                </div></div>
            })

            return <div>
                <div className="download-wrapper">                     
                    <a href="https://onelink.to/pu579v">
                        <img src="/download.png" alt="download" />
                    </a>
                </div>
                 <div className="d-flex flex-column flex-lg-row align-items-stretch">
                <div className="mx-2 card">{tScan}</div>
                <div className="mx-2 card">
                    <div className="mw-350 text-center align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <div onClick={(e) => this.onSelectType('membership')} className="card-body">
                         
                        <strong><p className="mb-0">Scan Membership</p></strong>
                            <img className="" src="/membership.png" alt="scanner" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        }else if (this.state.selectedType === 'membership'){
            return <MembershipScanner />
        } 
        else if (!this.state.selectedEvent) {


            if (!this.state.organizationEvents || this.state.organizationEvents.length <= 0) {
                return <div>Loading...</div>
            }

            return this.state.organizationEvents.map((organizationEvent) => {
                if (organizationEvent.ticket_statistics.sold > 0 || this.organizationEventHasGuests(organizationEvent)) {
                    return <div className="text-center mw-350 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <Card className="card-body" onClick={(e) => this.onSelectEvent(organizationEvent)} key={organizationEvent.id}>
                            <div className="mb-0 text-small">
                                <strong><p className="list-item-text mb-0">{organizationEvent.title}</p></strong>
                                <br />
                                <p className="list-item-text mb-0">Tickets sold: {organizationEvent.ticket_statistics.sold}</p>
                                <p className="list-item-text mb-0">Tickets scanned: {organizationEvent.ticket_statistics.validated}</p>
                                <p className="list-item-text mb-0">{this.organizationEventHasGuests(organizationEvent) && 'Guests: ' + this.getOrganizationEventGuestCount(organizationEvent)}</p>
                            </div>
                        </Card>
                    </div>
                }
                return null
            })

        } else if (!this.state.selectedOccurrence) {

            if (!this.state.selectedEvent.organization_event_occurrences || this.state.selectedEvent.organization_event_occurrences.length <= 0) {
                return <div>No occurrence to scan</div>
            }

            return this.state.selectedEvent.organization_event_occurrences.map((organizationEventOccurrence) => {
                if (organizationEventOccurrence.ticket_statistics.sold > 0 || organizationEventOccurrence?.organization_event_occurrence_guests?.length > 0) {
                    return <div className="text-center mw-350 align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <Card onClick={(e) => this.onSelectOccurrence(organizationEventOccurrence)} className="card-body" key={organizationEventOccurrence.id}>
                            <div className="mb-0 text-small w-sm-100">
                                <h6 className="mb-0">{this.state.selectedEvent.title}</h6>
                                <br/>
                                <strong><p className="list-item-text mb-0">{superPrettyPrintDate(organizationEventOccurrence.start_date)}</p></strong>
                                <br/>
                                <p className="list-item-text mb-0">Tickets sold: {organizationEventOccurrence.ticket_statistics.sold}</p>
                                <p className="list-item-text mb-0">Tickets scanned: {organizationEventOccurrence.ticket_statistics.validated}</p>
                                <p className="list-item-text mb-0">{organizationEventOccurrence.organization_event_occurrence_guests && `Guests: ${organizationEventOccurrence.organization_event_occurrence_guests.length}`}</p>
                            </div>
                        </Card>
                    </div>
                }
                return null
                }
            )
        } 

        return <>

            {this.renderScanResult(this.state.scanResult)}

            {this.renderMemberTicketActions(this.state.memberTicketValidate, this.state.memberTicketValidateHash)}

            {/* TODO REMOVE BUTTON */}
            {/* <Button className='ml-2' color="primary" onClick={() => this.onTestScan()}><i className="mr-1" />Test Scan</Button> */}

            {this.state.memberTicketValidations && this.state.memberTicketValidations.length > 0 && <Button className='ml-2' color="primary" onClick={() => this.onViewHistory()}><i className="mr-1" />View history of last scanned ticket</Button>}

            {this.renderScanTitle()}

            {/* <QrReader
                constraints={{
                    facingMode: 'environment'
                }}
                onResult={(result, error) => {
                    if (!!result) {
                        this.willScanQrCode(result?.text)
                    }

                    if (!!error && JSON.stringify(error) !== JSON.stringify({})) {
                        console.log("error", error);
                    }
                }}
                style={{ width: '100%' }} /> */}

            {this.state.viewHistory && this.renderHistory(this.state.memberTicketValidations)}

        </>
    }

}

export default TicketScanner
