import React, { Component } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import MultiLanguageWrapper from '../../pages/common/MultiLanguageWrapper';

class MultiLanguageClassicEditor extends Component {

    render() {
      return (
        <MultiLanguageWrapper
          label={this.props.label}
          data={this.props.data}
          keys={this.props.keys}
          onChange={this.props.onChange}>
          {({ content, onChange }) => (
            <CKEditor
                editor={ClassicEditor}
                data={content}
                onReady={editor => {
                }}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    onChange(data)
                }}
                onBlur={(event, editor) => {
                }}
                onFocus={(event, editor) => {
                }}
            />
          )}
        </MultiLanguageWrapper>
      );
    }
  
  }
  
  export default MultiLanguageClassicEditor;
  