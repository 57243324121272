    export const stockholmTimeZone = 'Europe/Stockholm';
    
    /**
     * get now date in format yyyy-mm-dd hh:mm:ss'T'000Z
     */
    export function formatDate() {
        var d = new Date(),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes(),
            seconds = '' + d.getSeconds();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if (hours.length < 2)
            hours = '0' + hours;
        if (minutes.length < 2)
            minutes = '0' + minutes;
        if (seconds.length < 2)
            seconds = '0' + seconds;
    
        var time = [hours, minutes, seconds].join(':');
        return [year, month, day].join('-') + "T" + time + ".000Z";
    }

    export function getCurrentYear() {
        return new Date().getFullYear()
    }

    /**
     * formats a date to string yyyy-mm-dd hh:mm:ss
     */
    export function formatDateTime(dateString) {
        var d = new Date(dateString),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hours = '' + d.getHours(),
            minutes = '' + d.getMinutes(),
            seconds = '' + d.getSeconds();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if (hours.length < 2)
            hours = '0' + hours;
        if (minutes.length < 2)
            minutes = '0' + minutes;
        if (seconds.length < 2)
            seconds = '0' + seconds;
    
        var time = [hours, minutes, seconds].join(':');
        return [year, month, day].join('-') + " " + time;
    }

    /**
     * formats a date as string yyyy-mm-dd
     */
    export function formatDateYYYYmmDD(date) {
        var d = new Date(date)
        var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    export function toLocaleString(dateString, locale = 'sv-SE', options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' }) {
        var timezoneOffset = new Date().getTimezoneOffset()
        const millsecondOffset = timezoneOffset * 60 * 1000;
        let date = new Date(dateString)
        let newDate = new Date(date.getTime() + millsecondOffset).toLocaleString(locale, options)
        return newDate
    }

    export function getSwedenDateNow() {
        const currentDate = new Date();
        const stockholmTime = currentDate.toLocaleString('sv-SE', {timeZone: stockholmTimeZone});
        const now = new Date(stockholmTime).getTime();
        return now
    }
      
    export function toSwedenDate(dateString) {
        const date = new Date(dateString);
        const dateResult = new Date(date.toISOString().slice(0, -1));
        const stockholmTime = dateResult.toLocaleString('sv-SE', {timeZone: stockholmTimeZone});
        const result = new Date(stockholmTime).getTime();
        return result
    }