import React from 'react';
import { verifyMembershipImageQR } from '../../../../vibe/helpers/apiHelper';
import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { formatDateYYYYmmDD } from '../../../../vibe/helpers/dateHelper';
import { capitalizeFirstLetter, parseErrorMessage } from '../../../../vibe/helpers/util';
import * as Icon from 'react-feather';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import { v4 as uuidv4 } from 'uuid';

class MembershipScanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false
        }
        this.toggle = this.toggle.bind(this)

    }

    componentDidMount() {
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            result: null,
        }));
    }

    qrboxFunction(viewfinderWidth, viewfinderHeight) {
        let minEdgePercentage = 0.75; // 75%
        let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
        let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
        return {
            width: qrboxSize,
            height: qrboxSize
        };
    }

    render() {
        return <>
            {this.renderResult(this.state.result)}
            <Html5QrcodePlugin
                fps={70}
                qrbox={this.qrboxFunction}
                disableFlip={false}
                supportedScanTypes={[0]}
                showTorchButtonIfSupported={true}
                qrCodeSuccessCallback={(decodedText, decodedResult) => {
                    if (!!decodedText) {
                        this.willScanQrCode(decodedText)
                    }

                    this.setState({ modal: true })
                }}
            />
        </>
    }

    renderWarnings(warnings) {
        if (!warnings || warnings.length <= 0) {
            return null
        }

        return <div className='my-3 mx-2'>
                {warnings.map((warning) => {
                    return <div className=''>
                        <p key={warning} className="list-item-text mb-0"><i className={'fa fa-circle fa-icon-small'}></i><span className='ml-2'>{warning.text}</span></p>
                    </div> 
                })}
            </div>
    }

    renderResult(result) {

        if (!result) {
            return null
        }

        return <Modal isOpen={this.state.modal} toggle={this.toggle} className="qrModel">
            <ModalHeader toggle={this.toggle} tag="h4">
                Scan Result
            </ModalHeader>
            <ModalBody>
                {result.result === 'success' && result.data ? (
                    <div>
                        <Icon.CheckCircle size="50" className="check" />
                        {this.renderWarnings(result?.data?.warnings)}
                        <Table striped size="sm" color='#111111'>
                            <tbody>
                                <tr>
                                    <td colSpan="2"><b>Member</b></td>
                                </tr>
                                <tr>
                                    <td>First Name</td>
                                    <td>{result.data.member.first_name}</td>
                                </tr>
                                <tr>
                                    <td>Last Name</td>
                                    <td>{result.data.member.last_name}</td>
                                </tr>
                                <tr>
                                    <td>Personal Identification Number</td>
                                    <td>{result.data.member.personal_identification_number}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table striped size="sm">
                            <tbody>
                                <tr>
                                    <td colSpan="2"><b>Membership</b></td>
                                </tr>
                                <tr>
                                    <td>Membership</td>
                                    <td>{result.data.membership}</td>
                                </tr>
                                <tr>
                                    <td>Membership Organization</td>
                                    <td>{result.data.organization}</td>
                                </tr>
                                <tr>
                                    <td>Valid from</td>
                                    <td>{formatDateYYYYmmDD(result.data.valid_from)}</td>
                                </tr>
                                <tr>
                                    <td>Valid to</td>
                                    <td>{formatDateYYYYmmDD(result.data.valid_to)}</td>
                                </tr>
                                <tr>
                                    <td>Organizations</td>
                                    <td style={{ padding: '16px 4px 4px 4px' }}>
                                        <ul>
                                        {result.data.organizations && result.data.organizations.length > 0 && result.data.organizations.map((organization, index) => {
                                            return <li key={'organization_' + index}>{organization.display_name}</li>
                                                
                                        })}
                                        </ul>
                                    </td>

                                </tr>
                            </tbody>
                        </Table>

                        {this.renderCardExtraText(result.data)}

                    </div>
                )
                    :
                    <>
                        <Icon.XCircle size="50" className="xcheck" />
                        <center>{result.message}</center>
                    </>
                }
            </ModalBody>
        </Modal>
    }

    renderCardExtraText(data) {

        if (!data || !data.extra) {
            return null
        }

        // extra.extra is always an array with objects containing title and an array with key value pairs, 
        // so we just render each item as a table
        if (data.extra.extra && data.extra.extra.length > 0) {
            return data.extra.extra.map((extra) => {
                return this.renderExtraArray(extra)
            })
        } else {
            return this.renderExtraDefault(data.extra)
        }
    }

    renderExtraArray(extra) {
        return <Table key={uuidv4()} striped size="sm">
            <tbody>
                    <tr>
                        <td colSpan="2"><b>{extra?.title}</b></td>
                    </tr>
                    {extra.items && extra.items.length > 0 && extra.items.map(item => {
                        return this.renderExtraArrayItem(item)
                    })}
            </tbody>
        </Table>
    }

    renderExtraArrayItem(item) {

        let keys = Object.keys(item)
        if (!keys || keys.length <= 0) {
            return null
        }

        let values = Object.values(item)

        return <tr key={uuidv4()}>
                    <>
                        <td>{capitalizeFirstLetter(keys[0])}</td>
                        <td>{values?.[0]}</td>
                    </>
                </tr>
    }

     // card_text, card_code
     renderExtraDefault(extra) {
        if (!extra.card_extra_text && !extra.card_code) {
            return null
        }

        return <Table striped size="sm">
                    <tbody>
                        <tr>
                            <td colSpan="2"><b>Extra</b></td>
                        </tr>
                        {extra.card_extra_text && <tr>
                            <td>Text</td>
                            <td>{extra.card_extra_text}</td>
                        </tr>}

                        {extra.card_code && <tr>
                            <td>Code</td>
                            <td>{extra.card_code}</td>
                        </tr>}
                    </tbody>
                </Table>
    }

    willScanQrCode(result) {

        if (this.state.result) {
            return;
        }

        if (this.state.fetching) {
            return
        }

        try {
            let json = JSON.parse(result)

            if (json && json.mmid && json.hash) {
                let memberMembershipId = json.mmid
                let hash = json.hash

                this.setState({fetching: true})

                verifyMembershipImageQR(memberMembershipId, hash, (result) => {
                    this.setState({ result: result, fetching: false })
                }, (error) => {
                    let errorMessage = parseErrorMessage(error)
                    this.setState({ result: { result: 'fail', message: errorMessage }, fetching: false })
                })
            } else {
                this.setState({ result: { result: 'fail', message: 'Invalid QR code. Please try again.' }, fetching: false })
            }
        }
        catch (error) {
            this.setState({ result: { result: 'fail', message: 'Invalid QR code. Please try again.' }, fetching: false })
        }

    }

}

export default MembershipScanner
