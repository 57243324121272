import React, { Component } from 'react';
import { Button, FormGroup, Input, Form } from 'reactstrap';
import { Loader } from '../../../vibe';
import { downloadBlob, getAllMembersAsCsv, getAllMemberships, getCurrentUser, getLatestMembers, getLatestMembersCount, getOrganizationsWithFilter, toEncodedFilterString } from '../../../vibe/helpers/apiHelper';
import Member from '../../elements/members/Member';
import PaginationComp from '../../../vibe/components/utilities/Pagination/Pagination';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';
import { filterValidMemberships, getMembershipSelectOptions } from '../../../vibe/helpers/util';
import { isSuperUser } from '../../../vibe/helpers/permissionHelper';

const pageSize = 10

class Members extends Component {
  constructor(props) {
    var str = props.location.search.replace(/\+/g, "%2B")
    let search = new URLSearchParams(str);
    let status = search.get("status");

    super(props);
    this.state = {
      statusFilter: status ? status : '',
      searchFilter: '',
      submitSearch: false,
      totalCount: null,
      memberships: [],
      membership_id: '',
      organizationFilter: '',
      loading: true,
      membersLoading: true,
      organizations: []
    }
  }

  componentDidMount() {
    getCurrentUser((user) => {
      this.setState({user: user})

      if (this.isSuperUser(user)) {
        this.getRootOrganizations()
      }

      this.getMemberships(user)
    }, (error) => {
      console.log(error)
    })

   
    const where = ''
    const status = this.state.statusFilter
    const search = this.state.searchFilter
    const membershipId = this.state.membership_id
    const organizationId = this.state.organizationFilter

    this.setState({membersLoading: true, totalCount: null, loading: true})
    getLatestMembers(0, membershipId, status, search, organizationId, where, (result) => {
      this.setState({ totalCount: result.totalCount, members: result.members, currentPage: 1, loading: false, membersLoading: false })
      getLatestMembersCount(0, membershipId, status, search, organizationId, where, (result) => {
        this.setState({totalCount: result.count})
      }, (error) => {
        console.log("count error", error)
      })
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1, membersLoading: false })
    })
  }

  getRootOrganizations() {
    const filter = {
      where: {
        root: 1
      }
    }
    getOrganizationsWithFilter(filter, (result) => {
      this.setState({organizations: result})
    }, (error) => {
      console.log("Get organizations error", error)
    })
  }

  getMemberships(user) {

    const filterObject = {
      order: ['valid_to DESC']
    }

    let filter = toEncodedFilterString(filterObject)

    getAllMemberships(filter, (memberships) => {
      if (!isSuperUser(user) && memberships?.length) {
        memberships = filterValidMemberships(memberships)
      }
      
      var options = getMembershipSelectOptions(memberships)
      this.setState({memberships: options})
    }, (error) => {
      this.setState({error: error})
    })    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.statusFilter !== this.state.statusFilter || prevState.submitSearch !== this.state.submitSearch || prevState.membership_id !== this.state.membership_id) {

      if (this.state.statusFilter) {
        this.props.history.push('?status=' + this.state.statusFilter)
      } else {
        this.props.history.push('/members')
      }

      if (this.state.submitSearch) {

        const where = ''
        const status = this.state.statusFilter
        const search = this.state.searchFilter
        const membershipId = this.state.membership_id
        const organizationId = this.state.organizationFilter

        this.setState({membersLoading: true, totalCount: null})

        getLatestMembers(0, membershipId, status, search, organizationId, where, (result) => {
          this.setState({ totalCount: result.totalCount, members: result.members, currentPage: 1, loading: false, submitSearch: false, membersLoading: false })
          getLatestMembersCount(0, membershipId, status, search, organizationId, where, (result) => {
            this.setState({totalCount: result.count})
          }, (error) => {
            console.log("count error", error)
          }) 
        }, (error) => {
          this.setState({ totalCount: 0, members: [], currentPage: -1, loading: false, submitSearch: false, membersLoading: false })
        })
      }
    }
  }

  renderMembers() {
    if (this.state && this.state.members) {
      return this.state.members.map((member) => {
        return <Member key={member.id} member={member}></Member>
      })
    }
  }

  onClickPagination(e, page) {
    this.setState({ currentPage: page, loading: true })
    var offset = (page - 1) * pageSize

    const where = ''
    const status = this.state.statusFilter
    const search = this.state.searchFilter
    const membershipId = this.state.membership_id
    const organizationId = this.state.organizationFilter

    this.setState({membersLoading: true, totalCount: null})
    getLatestMembers(offset, membershipId, status, search, organizationId, where, (result) => {
      this.setState({ totalCount: result.totalCount, members: result.members, loading: false, membersLoading: false })
      getLatestMembersCount(offset, membershipId, status, search, organizationId, where, (result) => {
        this.setState({totalCount: result.count})
      }, (error) => {
        console.log("count error", error)
      })
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1, membersLoading: false })
      console.log(error)
    })
  }

  renderPagination() {
    return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />
  }

  getLastPage() {
    return Math.ceil(this.state.totalCount / pageSize)
  }

  handleSelect = event => {
    this.setState({ statusFilter: event.target.value, submitSearch: true });
  }

  handleOrganizationSelect = (e) => {
    this.setState({ organizationFilter: e.target.value, submitSearch: true });
  }

  onSelectMembership(membershipId) {
    this.setState({membership_id: membershipId, submitSearch: true})
  }

  handleSearch = event => {
    this.setState({ searchFilter: event.target.value, submitSearch: false });
  }

  submitSearch = (e) => {
    e.preventDefault();
    this.setState({ submitSearch: true });
  }

  organizationOptions() {
    if (this.state.organizations && this.state.organizations.length > 0) {
      return this.state.organizations.map((organization, index) => (
        <option key={'organization_' + index} value={organization.id}>
          {organization.display_name}
        </option>
      ))
    }
  }

  membershipOptions() {
    if (this.state.memberships && this.state.memberships.length > 0) {
      return this.state.memberships.map((membership, index) => (
        <option key={'membership_' + index} value={membership.value}>
          {membership.label}
        </option>
      ))
    }
  }
  
  formatNumber(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    return "0"
  }

  isSuperUser(user) {
    if (user?.role === 'SuperUser') {
      return true
    }
    return false
  }

  renderOrganizationFilter() {

    if (!this.isSuperUser(this?.state?.user)) {
      return null
    }

    return <div className={`row mb-3 justify-content-md-end`}>
      <div className="col-md-2">
        <FormGroup className="mb-0 pt-1">
          <Input
            type="select"
            name="select"
            id="organizationFilter"
            defaultValue={this.state.organizationFilter}
            onChange={this.handleOrganizationSelect}>
            <option value="">All Organizations</option>
            {this.organizationOptions()}
          </Input>
        </FormGroup>
      </div>
    </div>
  }

  hasEmptyTotalCount() {
    return this.state.totalCount == null && this.state.totalCount !== 0 
  }

  onClickDownloadAsCSV() {
      getAllMembersAsCsv((blob) => {
        downloadBlob(blob, 'members.csv')
      }, (error) => {
        window.alert('An error occurred')
      })
  }

  render() {
    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>
            <header className="app-header-page justify-content-end">

            {this.state.user && this.state.user.role === 'Union' && <div className="col-md-2 mw-350">
                <FormGroup className="mb-0 pt-1">
                  <Button className='block' color="primary" onClick={() => this.onClickDownloadAsCSV()}>Download as CSV</Button>
                </FormGroup>
            </div>}

              <div className="col-md-3 mw-350">
                <FormGroup className="mb-0 pt-1">
                  <Link className="btn btn-primary block" to={"/members/new/"}>
                    Create member
                  </Link>
                </FormGroup>
              </div>
            </header>

            {this.renderOrganizationFilter()}

            <div className={`row mb-3 justify-content-md-around`}>
              <div className="col-md-2">
                <h1 className="h4 mb-0">Members</h1>
                {!this.state.membersLoading && !this.hasEmptyTotalCount() && <small className="text-muted">{this.formatNumber(this.state.totalCount)} members</small>} 
              </div>

              <div className="col-md-4">
                <Form onSubmit={(e) => this.submitSearch(e)}>
                  <div className="form-row align-items-center justify-content-start">
                    <div className="my-1">
                      {/** 
                       Fake hidden input field needed to stop Chrome autofilling the search input with email value (if saved email/password in browser) 
                      on component mount
                      **/}
                    <input type="text" 
                        autoComplete="off" 
                        value="" 
                        style={{display: 'none', opacity: 0, position: 'absolute', left: '-100000px'}} 
                        readOnly={true}>
                      </input>

                      <Input
                        type="text"
                        placeholder="Search..."
                        name="search"
                        id="search"
                        className="mr-1"
                        onChange={this.handleSearch}
                        title="Search by member ID, PIN, first or last name"
                      />
                    </div>
                    <Button className="ml-1" type="submit" onClick={(e) => this.submitSearch(e)} color="primary">
                        {this.state.submitSearch ? <Icon.Loader className="rotating" size="20" color="#FFFFFF" /> : "Search"}
                    </Button>
                  </div>
                </Form>
              </div>

              <div className="col-md-3">
                <FormGroup className="mb-0 pt-1">
                  <Input
                    type="select"
                    name="select"
                    id="statusFilter"
                    defaultValue={this.state.statusFilter}
                    onChange={this.handleSelect}>
                    <option value="">All Members</option>
                    <option value="A">Active Members</option>
                    <option value="N">Inactive Members</option>
                    <option value="P">Pending Members</option>
                  </Input>
                </FormGroup>
              </div>

              {<div className="col-md-3">
                <FormGroup className="mb-0 pt-1" >
                  <Input
                    type="select"
                    name="select"
                    id="statusFilter"
                    defaultValue={'All Memberships'}
                    onChange={(e) => this.onSelectMembership(e.target.value)}>
                    <option value="">All Memberships</option>
                    {this.membershipOptions()}
                  </Input>
                </FormGroup>
              </div>}

            </div>
            {this.state.membersLoading ? <Loader type="dots" /> : <div>
              {this.renderMembers()}
              {this.state.currentPage > 0 && this.renderPagination()}
            </div>}
            
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default Members;
