import React, { Component } from 'react';
import {
    Card, Button, UncontrolledAlert
} from 'reactstrap';
import {
    fetchMember, fetchMemberMemberships, getOrganizationsByParentId, patchMember, fetchMembershipsAndOrganizations,
    getOrganizationById, fetchVerifiedStudentInfo, patchVerifiedStudentInfoForSemester, createVerifiedStudentInfo, deleteMemberMembershipsByIdAsync,
    patchMemberMembershipAsync, getMembersByEmail, getPlasticCard, deletePlasticCard, getLatestPeriod, getTransactionsByMemberId, getMembershipsByOrganizationChain, saveMemberMembershipAsync, deleteTransactionLogs
} from '../../../vibe/helpers/apiHelper';

import { Link } from "react-router-dom";
import { formatDate } from '../../../vibe/helpers/dateHelper';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import VerifiedStudentInfo from './VerifiedStudentInfo';
import VerifiedStudentInfoInter from './VerifiedStudentInfo_international';
import Memberships from './Memberships';
import MemberForm from './MemberForm';
import MemberSettings from './MemberSettings';
import { isSuperUser } from '../../../vibe/helpers/permissionHelper';
import { CardBody } from 'reactstrap';
import PlasticCard from './PlasticCard';
import MemberTicketTransaction from '../../elements/transaction/MemberTicketTransaction';
import MemberPackageTransaction from '../transaction/MemberPackageTransaction';
import { v4 as uuidv4 } from 'uuid';
import MemberDeleteRequests from './MemberDeleteRequests';


class EditMemberForm extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            activeTab: '1',
            studyStatus: 'N',
            deleteCard: false,
            memberTransactions: [],
            membershipOptions: [],
            transactionLogsToDelete: []
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab,
            });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.memberId !== this.props.memberId) {
            this.fetchMemberData()
        }
    }

    componentDidMount() {
        this.fetchMemberData()
    }

    fetchMemberData() {
        var memberId = this.props.memberId

        this.fetchData(memberId, (memberships, membershipsAndOrganizations) => {
            this.setState({ memberships: memberships, membershipsAndOrganizations: membershipsAndOrganizations, error: null })
        }, (error) => {
            this.setState({ error: error })
        })

        // TOP ORGS
        if (this.props.organizationId) {
            getOrganizationById(this.props.organizationId, (organization) => {
                this.setState({ selectedOrganizations: [this.props.parentId], topOrganizations: [[organization]], organizations: [[organization]], pendingMemberships: [], selectedMembership: null, membershipOptions: [], memberMembershipsToDelete: [] })
            }, (error) => {
                console.log(error)
            })
        } else {
            getOrganizationsByParentId(this.props.parentId, (organizations) => {
                this.setState({ selectedOrganizations: [this.props.parentId], topOrganizations: [organizations], organizations: [organizations], pendingMemberships: [], selectedMembership: null, membershipOptions: [], memberMembershipsToDelete: [] })
            }, (error) => {
                console.log("Error getting top orgs", error);
            })
        }
    }

    getByEmail(email) {
        getMembersByEmail(email, this.state.member.id,
            (members) => {
                this.setState({ memberDuplicate: members })
            },
            (error) => {
                console.log('error', error)
            }
        )
    }

    fetchData(memberId, onSuccess, onError) {

        this.setState({ memberships: [], transactionLogsToDelete: [], membershipsAndOrganizations: [], member: null, showMembershipForm: false, didFetchVerifiedStudentInfo: false, verifiedStudentInfo: null, originalVerifiedStudentInfo: null })

        getTransactionsByMemberId(memberId, (transactions) => {
            this.setState({ memberTransactions: transactions })
        }, (error) => {
            console.log("err", error)
        })

        fetchMember(memberId, (member) => {

            this.setState({ member: member, pin: member.personal_identification_number, studyStatus: member.status })

            this.refreshVerifiedStudentInfo(member)

            this.fetchPlasticCard(member.id)

            if (isSuperUser(this.props.user)) {
                this.getByEmail(this.state.member.email)
            }

            fetchMemberMemberships(memberId, (memberMemberships) => {

                if (memberMemberships && memberMemberships.length > 0) {
                    memberMemberships.forEach((memberMembership) => {
                        if (memberMembership.membership_id) {
                            fetchMembershipsAndOrganizations(memberMembership, (membership, organizations) => {

                                var memberships = this.state.memberships
                                var membershipsAndOrganizations = {
                                    membership: membership,
                                    organizations: organizations,
                                    memberMembership: memberMembership,
                                    isEdited: false
                                }
                                var stateMembershipsAndOrganizations = this.state.membershipsAndOrganizations
                                stateMembershipsAndOrganizations.push(membershipsAndOrganizations)
                                onSuccess(memberships + membership, stateMembershipsAndOrganizations)
                            }, (error) => {
                                console.log("fetchMembershipsAndOrganizations error", error)
                            })
                        }
                    })
                } else {
                    onSuccess([], [])
                }

            }, (error) => {
                console.log("fetchMemberMemberships error", error)
                onError(error)
            })

        }, (error) => {
            console.log("fetchMember error", error)
            onError(error)
        });
    }

    onAddTransactionLogToDelete(transactionLog) {
        var transactionLogsToDelete = this.state.transactionLogsToDelete
        transactionLogsToDelete.push(transactionLog)
        this.setState({transactionLogsToDelete: transactionLogsToDelete})
    }

    onEditSelectedMembership(key, value) {
        var membership = this.state.selectedMembership
        membership[key] = value
        this.setState({selectedMembership: membership})
    }

    onSelectMembership(membershipId) {
        var options = this.state.membershipOptions
        var membership = options.find((option) => option.id === Number(membershipId) )
        this.setState({selectedMembership: membership})
    }

    addMembership() {
        var length = this.state.selectedOrganizations.length

        var membership = this.state.selectedMembership

        if (length <= 0) {
            alert("Error: No organization selected");
            return
        } 

        if (!membership || membership.id <= 0) {
            alert("Error: select membership");
            return
        } 

        const organizations = [...this.state.selectedOrganizations] 
        const organizationChain = organizations.join(",")

        var pendingMemberships = this.state.pendingMemberships
        const pendingMembership = {
            key: uuidv4(),
            member_id: this.state.member.id,
            membership: membership,
            organizations: organizations,
            organization_chain: organizationChain
        }
        pendingMemberships.push(pendingMembership)
        this.toggleMembershipForm()
        this.setState({ pendingMemberships: pendingMemberships, selectedOrganizations: [], organizations: [...this.state.topOrganizations] })
    }

    toggleMembershipForm() {
        this.setState({ showMembershipForm: !this.state.showMembershipForm });
    }

    onChange(e, key) {
        var member = this.state.member
        member[key] = e.target.value
        this.setState(member)
        this.setState({ memberEdited: true })
    }

    getAllEditedMembershipsAndOrganizations() {
        if (this.state.membershipsAndOrganizations && this.state.membershipsAndOrganizations.length > 0) {
            var editedMemberships = this.state.membershipsAndOrganizations.filter(membershipsAndOrganizations => {
                return membershipsAndOrganizations.isEdited
            })
            return editedMemberships
        }
        return []
    }

    renderAlerts() {
        if (this.state) {
            if (this.state.memberSaved) {
                return <UncontrolledAlert color="success">
                    Member saved!
                </UncontrolledAlert>
            } else if (this.state.memberSaveError) {
                return <UncontrolledAlert color="danger">
                    Error saving member, contact support [{this.state.memberSaveError}]
                </UncontrolledAlert>
            }
        }
    }

    saveMember(member, onSuccess, onError) {
        if (this.state.memberEdited) {
            patchMember(member, () => {
                onSuccess()
            }, (error) => {
                onError(error)
            })
        } else {
            onSuccess()
        }
    }

    // TODO solve this callback hell
    onSaveMember() {
        var updatedMember = this.state.member
        var now = formatDate()
        updatedMember.updated_at = now

        if (this.state.member.country !== 'SE') {
            updatedMember.status = this.state.studyStatus
        }
        
        const memberId = updatedMember?.id

        this.setState({ member: updatedMember }, () => {
            this.saveMember(this.state.member, () => {

                if (this.state.deleteCard) {
                    deletePlasticCard(this.state.deleteCard, () => {
                        this.setState({ memberDeleteSuccess: 'Success deleting member!', deleteCard: false })
                    }, (error) => {
                        this.setState({ memberDeleteError: 'Error deleting member! ' + error, deleteCard: false })
                    })
                }

                this.saveMemberships(this.state.pendingMemberships, () => {

                    this.setState({ pendingMemberships: [], membershipOptions: [], selectedMembership: null })

                    var editedMemberships = this.getAllEditedMembershipsAndOrganizations()
                    this.saveEditedMemberMemberships(editedMemberships, () => {

                        this.setState({ editedMemberMembership: {} })

                        this.deleteMemberships(this.state.memberMembershipsToDelete, () => {
                            this.setState({ memberMembershipsToDelete: [] })

                            // Insert or Update verified Information
                            this.verifyStudent(() => {

                                // delete pending transaction logs (member-delete-requests)
                                deleteTransactionLogs(this.state.transactionLogsToDelete, () => {

                                    // fetch all data again
                                    this.fetchData(memberId, (memberships, membershipsAndOrganizations) => {
                                        this.setState({ memberships: memberships, membershipsAndOrganizations: membershipsAndOrganizations, error: null, memberSaved: true })
                                        setTimeout(() => this.setState({ memberSaved: false }), 5000)
                                    }, (error) => {
                                        this.setState({ memberSaveError: error })
                                    })

                                }, (error) => {
                                    console.log("deleteTransactionLogs error", error)
                                }) 

                            }, (error) => {
                                console.log("Error saving verified info ", error);
                                this.setState({ memberSaveError: 'Error saving verified info' })
                            })

                            this.setState({ didEditVerifiedStudentInfo: false, memberEdited: false })
                        }, (error) => {
                            console.log("Error saving deleted memberships", error)
                            this.setState({ memberMembershipsToDelete: [], memberSaveError: 'Error saving deleted memberships' })
                        })
                    }, (error) => {
                        console.log("Error saving edited memberships", error)
                        this.setState({ memberSaveError: 'Error saving edited memberships' })
                    })

                }, (error) => {
                    console.log("Error saving memberships", error)
                    this.setState({ pendingMemberships: [], selectedMembership: null, memberSaveError: 'Error saving memberships' })
                })

            }, (error) => {
                console.log("Error saving member data", error);
                this.setState({ memberSaveError: 'Error saving member data' })
            })
        })

    }

    getPendingMembershipValidDates(pendingMembership) {
        var validFrom = pendingMembership?.membership?._valid_from
        var validTo = pendingMembership?.membership?._valid_to

        // if only validFrom is set, get valid_to from membership
        if (validFrom && !validTo) {
            validTo = pendingMembership?.membership?.valid_to
        }

        // if only validTo is set, get valid_from from membership
        if (!validFrom && validTo) {
            validFrom = pendingMembership?.membership?.valid_from
        }

        return {validFrom: validFrom, validTo: validTo}
    }

    async saveMemberships(statePendingMemberships, onSuccess, onError) {
        var pendingMemberships = statePendingMemberships
        if (pendingMemberships && pendingMemberships.length > 0) {
            for (var i = 0; i < pendingMemberships.length; i++) {
                var pendingMembership = pendingMemberships[i]

                const {validFrom, validTo} = this.getPendingMembershipValidDates(pendingMembership)

                var result = await saveMemberMembershipAsync(pendingMembership.member_id, pendingMembership.membership.id, pendingMembership.organization_chain, validFrom, validTo)
                if (!result) {
                    onError("Unable to save memberMembership")
                }
                if (i === pendingMemberships.length - 1) {
                    onSuccess()
                }
            }
        } else {
            onSuccess()
        }
    }

    async saveEditedMemberMemberships(editedMemberMemberships, onSuccess, onError) {
        if (editedMemberMemberships && editedMemberMemberships.length > 0) {
            for (var i = 0; i < editedMemberMemberships.length; i++) {
                var editedMemberMembership = editedMemberMemberships[i].memberMembership
                var result = await patchMemberMembershipAsync(editedMemberMembership)
                if (!result) {
                    onError("Unable to save memberMembership")
                }
                if (i === editedMemberMemberships.length - 1) {
                    onSuccess()
                }
            }
        } else {
            onSuccess()
        }
    }

    async deleteMemberships(memberMembershipsToDelete, onSuccess, onError) {
        if (memberMembershipsToDelete && memberMembershipsToDelete.length > 0) {
            for (var i = 0; i < memberMembershipsToDelete.length; i++) {
                var memberMembershipId = memberMembershipsToDelete[i]
                var result = deleteMemberMembershipsByIdAsync(memberMembershipId)
                if (!result) {
                    onError("Unable to delete memberMembership")
                }
                if (i === memberMembershipsToDelete.length - 1) {
                    onSuccess()
                }
            }
        } else {
            onSuccess()
        }
    }

    deletePendingMembership(key) {
        var pendingMemberships = this.state.pendingMemberships
        pendingMemberships = pendingMemberships.filter(pendingMembership => {
            return pendingMembership.key !== key
        })
        this.setState({ pendingMemberships: pendingMemberships })
    }

    onChangeMemberMembership(e, key, allMembershipsAndOrganizations, membershipsAndOrganizations) {
        membershipsAndOrganizations.isEdited = true
        membershipsAndOrganizations.memberMembership[key] = e.target.value
        this.setState({ membershipsAndOrganizations: allMembershipsAndOrganizations })
    }

    editMemberMembership(membershipAndOrganizations) {
        var memberMembership = membershipAndOrganizations.memberMembership
        const membership = membershipAndOrganizations.membership

        if (!memberMembership.valid_from) {
            memberMembership.valid_from = membership.valid_from
        }

        if (!memberMembership.valid_to) {
            memberMembership.valid_to = membership.valid_to
        }

        this.setState({ editedMemberMembership: memberMembership })
    }

    cancelEditMemberMembership() {
        this.setState({ editedMemberMembership: {} })
    }

    deleteMembership(memberMembershipId) {
        var memberMembershipsToDelete = this.state.memberMembershipsToDelete
        memberMembershipsToDelete.push(memberMembershipId)
        this.setState({ memberMembershipsToDelete: memberMembershipsToDelete })
    }

    onDeletePlasticCard(cardId) {
        this.setState({ deleteCard: cardId })
    }

    onSelectOrganization(e, index) {
        var selectedOrganizationId = e.target.value

        var organizations = this.state.organizations

        if (selectedOrganizationId <= 0) {
            organizations.length = index + 1
            this.setState({ organizations: organizations })
            return
        }

        var selectedOrganizations = this.state.selectedOrganizations
        selectedOrganizations[index] = selectedOrganizationId

        selectedOrganizations.length = index + 1

        getOrganizationsByParentId(selectedOrganizationId, (organizationsByParent) => {
            organizations[index + 1] = organizationsByParent
            organizations.length = index + 1 + 1
            this.setState({ selectedOrganizations: selectedOrganizations, organizations: organizations })
        }, (error) => {
            console.log(error)
            organizations.length = index + 1
            this.setState({ organizations: organizations })
        })

        const chain = selectedOrganizations.join(',')
        getMembershipsByOrganizationChain(chain, (memberships) => {
            this.setState({membershipOptions: memberships}, () => {
                // select first one
                if (memberships && memberships.length > 0) {
                    this.onSelectMembership(memberships[0].id)
                }
            })
            
        }, (error) => {
            console.log("error", error)
        })

    }

    getDefaultPeriod(memberId, callback) {
        getLatestPeriod(memberId, (period) => {
            callback({ valid_from: period.valid_from, valid_to: period.valid_to })
        }, (error) => {
            callback({ valid_from: null, valid_to: null })
        })
    }

    refreshVerifiedStudentInfo(member) {
        fetchVerifiedStudentInfo(member.personal_identification_number, (verifiedStudentInfo) => {
            this.setState({ didFetchVerifiedStudentInfo: true, verifiedStudentInfo: verifiedStudentInfo, originalVerifiedStudentInfo: { ...verifiedStudentInfo }, verifiedStudentInfoError: null })
        }, (error) => {
            this.getDefaultPeriod(member.id, (period) => {
                let verifiedStudentInfo = { study_pace: '0', valid_from: period.valid_from, valid_to: period.valid_to, personal_identification_number_short: member.personal_identification_number }
                this.setState({ didFetchVerifiedStudentInfo: true, verifiedStudentInfoError: error, verifiedStudentInfo: verifiedStudentInfo, originalVerifiedStudentInfo: { ...verifiedStudentInfo } })
            })
        })
    }

    fetchPlasticCard(memberId) {
        getPlasticCard(memberId, (cardInfo) => {
            this.setState({ plasticCard: cardInfo })
        }, (error) => {
            this.setState({ plasticCard: null })
        })
    }

    undoVerify() {
        this.setState({ didEditVerifiedStudentInfo: false, verifiedStudentInfo: { ...this.state.originalVerifiedStudentInfo } })
    }

    undoVerifyInter() {
        this.setState({ didEditVerifiedStudentInfo: false, memberEdited: false })
        if (this.state.member.status) {
            this.setState({ studyStatus: this.state.member.status })
        } else {
            this.setState({ studyStatus: 'N' })
        }
    }

    editVerification() {
        this.setState({ didEditVerifiedStudentInfo: true })
    }

    verifyStudent(onSuccess, onError) {
        if (!this.state.didEditVerifiedStudentInfo) {
            onSuccess()
        } else if (this.state.verifiedStudentInfo.id) {
            patchVerifiedStudentInfoForSemester(this.state.verifiedStudentInfo, () => {
                onSuccess()
            }, (error) => {
                onError(error)
            })
        } else {
            if (this.state.verifiedStudentInfo.study_pace === '0') {
                onSuccess()
            } else {
                createVerifiedStudentInfo(this.state.verifiedStudentInfo, () => {
                    onSuccess()
                }, (error) => {
                    onError(error)
                })
            }
        }
    }

    updateVerifiedStudentInfo(key, value) {
        let verifiedStudentInfo = this.state.verifiedStudentInfo
        verifiedStudentInfo[key] = value
        this.setState({ verifiedStudentInfo: verifiedStudentInfo })
        this.editVerification()
    }

    renderTicketTransactions() {

        if (!this.state.memberTransactions || this.state.memberTransactions.length <= 0) {
            return null
        }

        return this.state.memberTransactions.map((transaction) => {
            return transaction.ticket_transactions ?
                <MemberTicketTransaction
                    key={transaction.id}
                    transaction={transaction}
                    member={transaction.ticket_transactions[0].member}
                    ticket={transaction.ticket_transactions[0].tickets}
                />
                : <MemberPackageTransaction
                    key={transaction.id}
                    transaction={transaction}
                    member={transaction.package_transactions[0].member}
                    package={transaction.package_transactions[0].package}
                />
        })
    }

    updateRangeValueInter = e => {
        var intVal = parseInt(e.target.value)
        if (intVal === 100) {
            this.setState({ studyStatus: 'A' })
        } else if (intVal === 50) {
            this.setState({ studyStatus: 'T' })
        } else {
            this.setState({ studyStatus: 'N' })
        }
        this.setState({ memberEdited: true })
        this.editVerification()
    }

    render() {
        if (this.state && this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {
            return (
                <React.Fragment>
                    {this.renderAlerts()}
                    <div className="row mb-3">
                        <div className="col">
                            <h1 className="h4 mb-0"> {this.state.member && this.state.member.first_name + ' ' + this.state.member.last_name}</h1>
                        </div>
                    </div>

                    <Card>
                        <div>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1') }}>
                                        Member {this.state.memberEdited ? ' *' : ''}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2') }}>
                                        Verification {this.state.didEditVerifiedStudentInfo ? ' *' : ''}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3') }}>
                                        Memberships {(this.state.memberMembershipsToDelete && this.state.memberMembershipsToDelete.length > 0) || (this.state.pendingMemberships && this.state.pendingMemberships.length > 0) || (this.getAllEditedMembershipsAndOrganizations().length > 0) ? ' *' : ''}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5') }}>
                                        Plastic Card {this.state.deleteCard ? ' *' : ''}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6') }}>
                                        Transactions
                                    </NavLink>
                                </NavItem>

                                {isSuperUser(this.props.user) ?
                                    <>
                                        <NavItem>
                                            <NavLink href="#" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4') }}>
                                                Settings
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink href="#" className={classnames({ active: this.state.activeTab === '7' })} onClick={() => { this.toggle('7') }}>
                                                Delete requests {this.state.transactionLogsToDelete?.length > 0 ? ' *' : ''}
                                            </NavLink>
                                        </NavItem>
                                    </>
                                    : null}
                            </Nav>
                            <TabContent activeTab={this.state.activeTab}>
                                <TabPane tabId="1">
                                    <MemberForm user={this.props.user} member={this.state.member} pin={this.state.pin} onChange={this.onChange.bind(this)} />
                                </TabPane>
                                <TabPane tabId="2">
                                    {this.state.member && this.state.member.country !== 'SE' ?
                                        <VerifiedStudentInfoInter
                                            verifiedStudentInfoError={this.state.verifiedStudentInfoError}
                                            didFetchVerifiedStudentInfo={this.state.didFetchVerifiedStudentInfo}
                                            verifiedStudentInfo={this.state.verifiedStudentInfo}
                                            didEditVerifiedStudentInfo={this.state.didEditVerifiedStudentInfo}
                                            studyStatus={this.state.studyStatus}
                                            updateRangeValueInter={this.updateRangeValueInter.bind(this)}
                                            undoVerifyInter={this.undoVerifyInter.bind(this)}
                                            editVerification={this.editVerification.bind(this)} />
                                        : <VerifiedStudentInfo
                                            verifiedStudentInfoError={this.state.verifiedStudentInfoError}
                                            didFetchVerifiedStudentInfo={this.state.didFetchVerifiedStudentInfo}
                                            verifiedStudentInfo={this.state.verifiedStudentInfo}
                                            didEditVerifiedStudentInfo={this.state.didEditVerifiedStudentInfo}
                                            updateVerifiedStudentInfo={this.updateVerifiedStudentInfo.bind(this)}
                                            undoVerify={this.undoVerify.bind(this)}
                                            editVerification={this.editVerification.bind(this)} />
                                    }
                                </TabPane>

                                <TabPane tabId="3">
                                    <Memberships
                                        selectedMembership={this.state.selectedMembership}
                                        memberMembershipsToDelete={this.state.memberMembershipsToDelete}
                                        showMembershipForm={this.state.showMembershipForm}
                                        organizations={this.state.organizations}
                                        pendingMemberships={this.state.pendingMemberships}
                                        user={this.props.user}
                                        membershipsAndOrganizations={this.state.membershipsAndOrganizations}
                                        editedMemberMembership={this.state.editedMemberMembership}
                                        membershipOptions={this.state.membershipOptions}
                                        onSelectMembership={this.onSelectMembership.bind(this)}
                                        onSelectOrganization={this.onSelectOrganization.bind(this)}
                                        onChangeMemberMembership={this.onChangeMemberMembership.bind(this)}
                                        addMembership={this.addMembership.bind(this)}
                                        onEditSelectedMembership={this.onEditSelectedMembership.bind(this)}
                                        toggleMembershipForm={this.toggleMembershipForm.bind(this)}
                                        deletePendingMembership={this.deletePendingMembership.bind(this)}
                                        cancelEditMemberMembership={this.cancelEditMemberMembership.bind(this)}
                                        editMemberMembership={this.editMemberMembership.bind(this)}
                                        deleteMembership={this.deleteMembership.bind(this)} />
                                </TabPane>

                                <TabPane tabId="5">
                                    <PlasticCard
                                        plasticCard={this.state.plasticCard}
                                        onDeletePlasticCard={this.onDeletePlasticCard.bind(this)}
                                        deleteCard={this.state.deleteCard}
                                    />
                                </TabPane>

                                <TabPane tabId="6">
                                    {
                                        this.state.memberTransactions && this.state.memberTransactions.length > 0 ?
                                            this.renderTicketTransactions()
                                            : <CardBody className="py-4">
                                                <div className="my-5">
                                                    <h5>No Transactions found!</h5>
                                                </div>
                                            </CardBody>
                                    }
                                </TabPane>

                                {isSuperUser(this.props.user) ?
                                    <>
                                        <TabPane tabId="4">
                                            <MemberSettings member={this.state.member} history={this.props.history} />
                                        </TabPane>
                                        <TabPane tabId="7">
                                            <MemberDeleteRequests member={this.state.member} onAddTransactionLogToDelete={this.onAddTransactionLogToDelete.bind(this)} transactionLogsToDelete={this.state.transactionLogsToDelete} />
                                        </TabPane>
                                    </>
                                    : null}

                            </TabContent>
                        </div>
                        <Button color="success edit-button" onClick={() => this.onSaveMember()}><i className="fa fa-check"></i>&nbsp;Save</Button>{' '}
                    </Card>

                    {isSuperUser(this.props.user) && this.state.memberDuplicate && this.state.memberDuplicate.length > 0 &&
                        <Card>
                            <CardBody>
                                We found {this.state.memberDuplicate.length} potential duplicate of this member by email:
                                <ul className="partner-tags">
                                    {this.state.memberDuplicate.map((member, i) => {
                                        return (
                                            <li key={member.id}>
                                                <Link to={"/member/edit/" + member.id} >{member.id}</Link>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </CardBody>
                        </Card>
                    }

                </React.Fragment>
            );
        }
    }

}

export default EditMemberForm;
