import React from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { addMemberMembership, addMemberParentMembership, createInactiveMember, memberExists } from '../../../vibe/helpers/apiHelper';
import { STEP_CONFIRM_EXISTING_MEMBER, STEP_INIT, STEP_LOADING, STEP_MESSAGE, STEP_NEW_MEMBER } from './NewMember';
import Select from 'react-select'
import { getGenderSelectOptions } from '../../../vibe/helpers/util';

class NewMemberInt extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: STEP_INIT,
            notify_member: true,
            membership_id: 0
        }
    }

    onChange(value, key) {
        console.log({value, key})
        this.setState({[key]: value})
    }

    onSelectMembership(membershipId) {
        this.setState({membership_id: membershipId})
    }

    render() {
        return this.renderStep(this.state.step)
    }

    memberSavedCallback = (memberId) => {
        this.setState({step: STEP_MESSAGE, message: 'Member saved! Redirecting...'})
        this.props.onSuccess(memberId)
    }

    onNextClick(currentStep) {

        if (currentStep === STEP_INIT) {
            var data = {
                email: this.state.email
            }
    
            memberExists(data, (result) => {

                if (result && result.result) {
                    if (result.belongs_to_organization) {
                        this.setState({step: STEP_MESSAGE, message: 'Member already exists in your organization, redirecting...'})
                        this.props.onSuccess(result.member.id)
                    } else {
                        this.setState({step: STEP_CONFIRM_EXISTING_MEMBER, member_id: result.member.id, first_name: result.member.first_name, last_name: result.member.last_name})
                    }
                } else {
                    let email = result.member.email
                    this.setState({step: STEP_NEW_MEMBER, email: email})
                }
    
            }, (error) => {
                this.setState({step: STEP_MESSAGE, message: 'Error checking member exists'})
            })
        } else if (currentStep === STEP_NEW_MEMBER) {

            this.setState({step: STEP_MESSAGE, message: 'Saving member...'})

            let data = {
                first_name: this.state.first_name, 
                last_name: this.state.last_name, 
                street_address: this.state.street_address, 
                co_address: this.state.co_address,
                zip_code: this.state.zip_code,
                city: this.state.city,
                email: this.state.email,
                mobile_number: this.state.mobile_number
            }
            createInactiveMember(data, this.state.notify_member, (createMemberResult) => {
                this.addMembership(this.state.membership_id, createMemberResult.id, () => this.memberSavedCallback(createMemberResult.id))
            }, (error) => {
                this.setState({step: STEP_MESSAGE, message: 'Error saving member'})
            })

        } else if (currentStep === STEP_CONFIRM_EXISTING_MEMBER) {
            this.addMembership(this.state.membership_id, this.state.member_id, () => this.memberSavedCallback(this.state.member_id))
        } else {
            this.setState({step: currentStep + 1})
        }

    }

    addMembership(membershipId, memberId, onSuccess) {

        var membershipCallback = (result) => {
            if (result) {
                onSuccess()
            } else {
                this.setState({step: STEP_MESSAGE, message: 'Error saving membership'})
            }
        }
    
        var onError = (error) => {
            this.setState({step: STEP_MESSAGE, message: 'Error saving membership'})
        }
    
        if (membershipId) {
            addMemberMembership(memberId, membershipId, membershipCallback, onError)
        } else {
            addMemberParentMembership(memberId, membershipCallback, onError)
        }
    
    }

    renderStep(step) {
        switch (step) {
            case STEP_LOADING:
                return 'Loading...'
        
            case STEP_INIT:
                return <>
                      <Card>
                        <CardBody>
                                <Row>
                                    <Col sm="4">
                                        
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input type="text" name="email" id="email" onChange={(e) => this.onChange(e.target.value, 'email')} />
                                        </FormGroup>

                                    </Col>
                                </Row>
                        </CardBody>
                      </Card>
                      <Button className='mx-0 my-0 d-inline-block mr-2' onClick={(e) => this.onNextClick(step)} color="success">Next</Button>
                    </>

            case STEP_CONFIRM_EXISTING_MEMBER:
                // confirmation if member already exists
                return <>
                        <p>{this.state.first_name}{' '}{this.state.last_name}{' - '}{this.state.email}</p>
                        <p>This member already exists in our system. Add to your organization?</p>

                        <Row className='mt-4'>
                            <Col sm='6'>
                                <FormGroup className='mt-3'>
                                    <Label for="membership">Membership</Label>
                                    <Select options={this.props.memberships} onChange={(e) => this.onSelectMembership(e.value)} placeholder={"Select membership..."} /> 
                                </FormGroup> 
                            </Col>
                        </Row>

                        <Button className='mx-0 my-0 d-inline-block mr-2' onClick={(e) => this.onNextClick(step)} color="success">Yes</Button>
                    </>

            case STEP_MESSAGE:
                return <>
                        {this.state.message}
                    </>

            case STEP_NEW_MEMBER:
                return <>
                      <Card>
                        <CardBody>

                            <Row>
                                <Col sm="6">

                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="first_name">* First name</Label>
                                            <Input type="text" required={true} name="first_name" id="first_name" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'first_name')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="last_name">* Last name</Label>
                                            <Input type="text" required={true} name="last_name" id="last_name" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'last_name')} />
                                        </div>
                                    </FormGroup>

                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="date_of_birth">Date of birth</Label>
                                            <Input type="date" name="date_of_birth" id="date_of_birth" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'date_of_birth')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="gender">Gender</Label>
                                            <Select options={getGenderSelectOptions()} onChange={(e) => this.onChange(e.value, 'gender')} placeholder={"Select gender..."} /> 
                                        </div>
                                    </FormGroup>

                                </Col>

                                <Col sm="6">
                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="street_address">Street address</Label>
                                            <Input type="text" required={false} name="street_address" id="street_address" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'street_address')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="co_address">C/O</Label>
                                            <Input type="text" name="co_address" id="co_address" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'co_address')} />
                                        </div>
                                    </FormGroup>

                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="zip_code">Zip code</Label>
                                            <Input type="text" name="zip_code" id="zip_code" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'zip_code')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="city">Postal city</Label>
                                            <Input type="text" name="city" id="city" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'city')} />
                                        </div>
                                    </FormGroup>

                                </Col>
                            </Row>

                            <Row>
                                <Col sm='6'>
                                    <FormGroup className='mt-3'>
                                        <Label for="email">* Email</Label>
                                        <Input type="text" required={true} name="email" id="email" disabled value={this.state.email} />
                                     </FormGroup>
                                </Col>

                                <Col sm='6'>
                                    <FormGroup className='mt-3'>
                                        <Label for="mobile_number">Mobile number</Label>
                                        <Input type="text" name="mobile_number" id="mobile_number" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'mobile_number')} />
                                    </FormGroup> 
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col>
                                    <FormGroup check>
                                        <Label for="notify_member">
                                            <Input type="checkbox" name="notify_member" id="notify_member" defaultChecked={this.state.notify_member} onChange={(e) => this.onChange(e.target.checked, 'notify_member')} />{' '}
                                            Notify member
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row className='mt-4'>
                                <Col sm='6'>
                                    <FormGroup className='mt-3'>
                                        <Label for="membership">Membership</Label>
                                        <Select options={this.props.memberships} onChange={(e) => this.onSelectMembership(e.value)} placeholder={"Select membership..."} /> 
                                    </FormGroup> 
                                </Col>
                            </Row>

                        </CardBody>
                      </Card>
                      <Button className='mx-0 my-0 d-inline-block mr-2' onClick={(e) => this.onNextClick(step)} color="success">Create member</Button>
                  </>

            default:
                return null
        }
    }

}

export default NewMemberInt