import React from 'react';
import { Col } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getPayoutOrganizations } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import PayoutOrganization from './PayoutOrganization';

class PayoutOrganizations extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            organizations: []
        }
    }

    componentDidMount() {
        getPayoutOrganizations((organizations) => {
            this.setState({ organizations: organizations.sort(this.customSort), loading: false })
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })
    }

    renderOrganizations(organizations) {
        if (!organizations || organizations.length <= 0) {
            return null
        }

        return organizations.map((organization, i) => {
            return <PayoutOrganization organization={organization} hasPendingTransactions={false} key={i} />
        })
    }

    customSort = (a, b) => {
        const hasPendingA = a.hasPendingTransactions;
        const hasPendingB = b.hasPendingTransactions;
      
        if (hasPendingA === hasPendingB) {
            return 0;
        }
        return hasPendingA ? -1 : 1;

    };

    render() {
        if (this.state.error) {
            return <>Error: {this.state.error}</>
        }

        if (this.state.loading) {
            return <Loader type="dots" />
        }

        return <>
            <header className="app-header-page justify-content-end">
                <div className="col-md-3 mw-350">
                </div>
            </header>

            <div className="row mb-3">
                <div className="col mr-auto">
                    <h1 className="h4 mb-0">Organizations</h1>
                </div>
            </div>

            <div className='row mb-3'>
                <Col>
                    {this.renderOrganizations(this.state.organizations)}
                </Col>
            </div>
        </>
    }

}

export default PayoutOrganizations
