import React, { Component }  from 'react';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledAlert } from "reactstrap";
import { getCurrentUser, getOrganizationById } from '../../../../../vibe/helpers/apiHelper';
import TimePicker from 'rc-time-picker';
import DatePick from '../../../../elements/form/DatePicker';
import moment from 'moment'
import FormSelect from '../../../common/FormSelect';
import { Loader } from '../../../../../vibe';

class EditOccurrenceNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            notification: {
                organization_event_occurrence_id: this.props.occurrence.id,
                title: '',
                text: '',
                new: true
            },
            deleteModal: false,
            topLoader: false,
            saveButtonDisabled: false,
            deleteButtonDisabled: false
        }
        this.toggleModal = this.toggleModal.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this)
        this.toggleDeleteModal = this.toggleDeleteModal.bind(this)

        if (this.props.notification) {

            var data = this.props.notification
            this.state.notification = data
            this.state.notification.organization_event_occurrence_id = this.props.occurrence.id
            this.state.notification.new = false

            var date = data.schedule && new Date(data.schedule).toISOString().split('T')[0];
            var time = data.schedule && new Date(data.schedule).toISOString().split('T')[1].split('.')[0];

            if (time) {
                this.state.currentTime = moment(time, 'HHmmss')
            }

            if (date) {
                this.state.currentDate = date.replace(/-/g,'/')
            }

            this.state.selectedDay = date
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            getOrganizationById(user.organization_id, (organization) => {
                var notification = this.state.notification
                if (!notification.title) {
                    notification.title = organization?.display_name
                    this.setState({notification: notification})
                }
            }, (error) => {

            })
        })
    }

    toggleModal() {
        this.props.toggle()
    }

    onChange(key, value) {
        var notification = this.state.notification
        notification[key] = value
        this.setState({notification: notification})
    }

    onDeleteNotification() {
        this.toggleDeleteModal()
        this.setState({topLoader: true, saveButtonDisabled: true, deleteButtonDisabled: true})
        this.props.onDeleteNotification(this.state.notification, () => {
            this.setState({topLoader: false, saveButtonDisabled: false, deleteButtonDisabled: false})
            this.toggleModal()
        }, (error) => {
            this.setState({topLoader: false, saveButtonDisabled: false, deleteButtonDisabled: false})
            this.setState({notificationSaveError: error })
        })
    }

    onDeleteClick() {
        this.setState({notificationSaveError: null})
        this.toggleDeleteModal()
    }

    onSaveNotification() {
        this.setState({topLoader: true, saveButtonDisabled: true, deleteButtonDisabled: true, notificationSaveError: null})

        let schedule
            if (this.state.currentTime && this.state.selectedDay) {
            // a hack to fix date in firefox
            schedule = '' + this.state.selectedDay + 'T' + this.state.currentTime.format('HH:mm:ss') + ".000Z"
            schedule = schedule.replace(/\//g, '-')
        } 
        var notification = this.state.notification
        notification.schedule = schedule

        this.setState({notification: notification}, () => {
            this.props.onSaveNotification(this.state.notification, () => {
                this.setState({topLoader: false, saveButtonDisabled: false, deleteButtonDisabled: false})
                this.toggleModal()
            }, (error) => {
                this.setState({topLoader: false, saveButtonDisabled: false, deleteButtonDisabled: false})
                this.setState({notificationSaveError: error })
            })
        })
    }

    handleDayChange(day) {
        this.setState({ selectedDay: day });
    }

    onChangeTime(value) {
        this.setState({currentTime: value})
    }

    beforeTodayDate(){
        const now = new Date()
        const nowDate = now.toISOString().split('T')[0]
        return moment(this.state.selectedDay).format('YYYY-MM-DD') !==  nowDate
      }
      
    getDisabledHours = () => {
        if(this.beforeTodayDate()){
          return
        }
      
        var hours = [];
        for (var i = 0; i < moment().hour(); i++){
          hours.push(i);
        }
        return hours;
    }

    getDisabledMinutes = (selectedHour) => {
        if(this.beforeTodayDate()){
          return
        }
        
        var minutes= [];
        if (selectedHour === moment().hour()){
          for(var i =0; i < moment().minute() + 5; i++){
              minutes.push(i);
          }
        }
        return minutes;
    }

    canEditNotification(notification) {
        return notification?.status !== 2 && notification?.status !== 3
    }

    getTitle(notification) {
        if (this.canEditNotification(notification)) {
            if (this.isEditing()) {
                return "Update push notification"
            }
            return "New push notification"
        }
        return "Push notification"
    }

    isEditing() {
        return !!this.props.notification
    }

    formIsValid() {
        return this.state.notification.text && this.state.notification.segment_id && this.state.selectedDay && this.state.currentTime
    }

    getStatusString(notification) {
        switch (notification?.status) {

            case 1:
                return "Scheduled"

            case 2:
                return "Sending"

            case 3: 
                return "Sent"

            default: 
                return "Pending"
        }
    }

    toggleDeleteModal(e = null) {
        this.setState(prevState => ({
            deleteModal: !prevState.deleteModal
        }));
    }

    renderDeleteConfirmModal() {
        return <Modal isOpen={this.state.deleteModal} toggle={this.toggleDelete} className="">
            <ModalHeader toggle={this.toggleDeleteModal}>Delete notification</ModalHeader>
            <ModalBody>
                <p>Are you sure you want to delete this notification? This action can not be undone</p>                      
            </ModalBody>
            <ModalFooter>
                <Button color="danger" className="px-5" onClick={() => this.onDeleteNotification()}>Delete</Button>{' '}
                <Button color="secondary" className="px-5" onClick={this.toggleDeleteModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }

    renderAlerts() {
        if (this.state.notificationSaveError) {
            return <UncontrolledAlert color="danger">
                {this.state.notificationSaveError}
            </UncontrolledAlert>
        }
        return null
    }

    render() {

        return <> 
            <Modal scrollable={false} size='lg' isOpen={true} toggle={this.toggleModal} className="previewModal">

                    {this.renderAlerts()}

                    <ModalHeader toggle={this.toggleModal}><strong>{this.getTitle(this.state.notification)}</strong></ModalHeader>

                    {this.state.topLoader ? <Loader type="dots" /> : null}

                    <ModalBody>
                        <Form className='mt-3'>

                            <FormGroup className='mb-4'>
                                <Label for="status">Status</Label>
                                <div>{this.getStatusString(this.state.notification)}</div>
                            </FormGroup>

                            <FormGroup className='mb-4'>
                                <Label for="title">Notification title</Label>
                                <Input type="text" name="title" id="title" disabled value={this.state.notification.title} placeholder="Title" autoComplete="off" onChange={(e) => this.onChange('title', e.target.value)} required/>
                            </FormGroup>

                            <FormGroup className='mb-4'>
                                <Label for="text">Notification text, maximum length of the text depends on the users device and preferences. Keep it short.</Label>
                                <Input type="textarea" name="text" id="text" disabled={!this.canEditNotification(this.state.notification)} placeholder="Text" value={this.state.notification.text} onChange={(e) => this.onChange("text", e.target.value)}  required />
                            </FormGroup>

                            <FormGroup className='mb-4'>
                                <Label>Schedule</Label>
                                <div className="datePicker">
                                
                                {this.props.notification && this.state.currentDate ?
                                <DatePick handleChange={this.handleDayChange} disabled={!this.canEditNotification(this.state.notification)} value={this.state.currentDate} source='push' />
                                : <DatePick handleChange={this.handleDayChange} disabled={!this.canEditNotification(this.state.notification)} source='push' /> 
                                }
                                    
                                <TimePicker showSecond={false} disabled={!this.canEditNotification(this.state.notification)} onChange={this.onChangeTime} placeholder="HH:MM" allowEmpty={false}  value={this.state.currentTime} 
                                    disabledHours={this.getDisabledHours}  disabledMinutes={this.getDisabledMinutes} inputReadOnly	
                                />
                                </div>
                            </FormGroup>

                            <div className="row">
                                <div className='col-12 col-md-6'>
                                    <FormSelect label={'Segment - Who will receive this notification?'} disabled={!this.canEditNotification(this.state.notification)} options={this.props.segmentOptions} onChange={(e) => this.onChange('segment_id', e.value)} placeholder={"Select segment..."} selected={this.state.notification.segment_id} /> 
                                </div>
                            </div>

                        </Form>
                    </ModalBody>

                    <ModalFooter className='d-flex justify-content-end'>

                        {this.canEditNotification(this.state.notification) && <p className="muted mt-3 list-item-text-small">
                            Push notifications will automatically be sent to all members in your selected segment at the date and time for this booking.
                        </p>} 

                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                        {!this.state?.notification?.new && <Button disabled={!this.canEditNotification(this.state.notification) || this.state.deleteButtonDisabled} size="lg" className="px-5 mt-3" color='danger' onClick={() => this.onDeleteClick()}>Delete</Button> } 
                        <Button size="lg" className="px-5 mt-3" disabled={!this.canEditNotification(this.state.notification) || this.state.saveButtonDisabled || !this.formIsValid()} color="primary" onClick={() => this.onSaveNotification()}>{"Save"}</Button>
                        
                    </ModalFooter>
                       
            </Modal>

            {this.renderDeleteConfirmModal()}

        </>
    }

}

export default EditOccurrenceNotification;