/**
 * Input: array of discounts
 * return an array of all duplicate discount_code by their code
 */
export function getDuplicateStaticCodes(discounts) {

    if (!discounts?.length) {
      return null
    }

    var discountCodes = []

    for (const discount of discounts) {

      if (discount.code_type !== 'static') {
        continue
      }

      if (!discount?.discountCodes?.length) {
        continue
      }

      discountCodes = discountCodes.concat(discount.discountCodes)
    }

    let codeCounts = discountCodes.reduce((acc, discountCode) => {
      acc[discountCode.code] = (acc[discountCode.code] || 0) + 1;
      return acc;
    }, {})
    
    let duplicates = Object.keys(codeCounts).filter(code => codeCounts[code] > 1);
    if (duplicates.length > 0) {
      return duplicates
    } 

    return null
}