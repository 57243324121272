import JSZip from "jszip";
import { toReadablePrice } from "../../views/elements/form/Currency"
import slugify from "slugify";
import { generatePDF, getPdfBlob } from "../components/utilities/pdfGenerator/pdfGenerator";
import { saveAs } from 'file-saver';
import { prettyOccurrenceDateFormat, prettyPrintDate } from "./util";
import { getPayoutTransactionDetails } from "./apiHelper";

export const STATUS_PENDING = 0
export const STATUS_COMPLETE = 1

export async function downloadAllPdfsAsZip(payouts, year, month) {
    if (!payouts || payouts.length <= 0) {
        return
    }

    const zip = new JSZip();

    var index = 0
    for (const payout of payouts) {
    
        if (!payout.transactions || payout.transactions.length <= 0) {
            return
        }

        const organizationName = payout?.organization?.display_name ?? payout?.organization?.name

        var safeFileName = ""
        if (organizationName) {
            // Remove special characters, convert to lower case, replace spaces with -
            safeFileName = "_" + slugify(organizationName, {
                replacement: '-',
                remove: /[*+~.()'"!:@]/g,
                lower: true
            });
        }

        const transactionDetails = await new Promise((resolve, reject) => {
            getPayoutTransactionDetails(payout.id, (transactionDetails) => {
                resolve(transactionDetails)
            }, (error) => {
                console.error('Error generating/editing PDF:', error);
                resolve(null)
            })
        })

        if (!transactionDetails) {
            continue
        }

        const {meta, packageData, ticketData} = generatePdfStatementData(payout, transactionDetails?.organization_details, transactionDetails)
        const pdfBlob = getPdfBlob(meta, packageData, ticketData)
        zip.file(`${year}_${month}_statement_${index + 1}${safeFileName}.pdf`, pdfBlob);
        index++
    }

    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `statements_${year}_${month}.zip`);
}

/*
export function getPDFStatementData(payout) {
    let payoutMeta = getPayoutMeta(payout.transactions)
    payoutMeta.statementId = payout.id
    payoutMeta.period = payout.from_date.split('T')[0] + ' - ' + payout.to_date.split('T')[0]
    payoutMeta.totalTx = payout.transactions.length
    payoutMeta.DatePaid = payout.created_at
    payoutMeta.union = payout?.organization?.organization_details?.[0]
    payoutMeta.net = toReadablePrice(payout.amount, payout.currency)
    payoutMeta.fee = toReadablePrice(payout.fee_amount, payout.currency)
    payoutMeta.total = toReadablePrice(payout.amount_without_fees, payout.currency)

    let packageData = []
    for (let i = 0; i < payoutMeta.packages.length; i++) {
        let data = packagePayoutData(payoutMeta.packages[i])
        packageData.push(data)
    }
    let ticketData = []
    for (let i = 0; i < payoutMeta.tickets.length; i++) {
        let data = ticketPayoutData(payoutMeta.tickets[i])
        ticketData.push(data)
    }

    return {payoutMeta, packageData, ticketData}
}
*/

/*
export function ticketPayoutData(ticket) {

    let transactionAmountPerItem = getTransactionAmountPerItem(ticket.transactions)

    let pricePerItem
    if (transactionAmountPerItem.length === 1) {
        let str = printShort(toReadablePrice(transactionAmountPerItem[0].amount), transactionAmountPerItem[0].currency)
        let string = transactionAmountPerItem[0].count + ' x ' + str
        pricePerItem = string

    } else if (transactionAmountPerItem.length === 0) {
        pricePerItem = 0
    } else {
        let string
        for (var item of transactionAmountPerItem) {
            let str = printShort(toReadablePrice(item.amount), item.currency)
            let temp = item.count + ' x ' + str

            if (!string) {
                string = temp
            } else {
                string += ', '
                string += temp
            }
        }
        pricePerItem = string
    }

    return {
        pricePerItem: pricePerItem,
        eventName: ticket.event,
        ticketName: ticket.name,
        amount: ticket.transactionAmount,
        amountFormatted: toReadablePrice(ticket.transactionAmount, ticket.currency),
        currency: ticket.transactionCurrency,
        quantity: transactionAmountPerItem[0].count,
        unitPrice: toReadablePrice(transactionAmountPerItem[0].amount, transactionAmountPerItem[0].currency),
    }
}


export function packagePayoutData(_package) {
    let transactionAmountPerItem = getTransactionAmountPerItem(_package.transactions)

    let pricePerItem
    if (transactionAmountPerItem.length === 1) {
        let str = printShort(toReadablePrice(transactionAmountPerItem[0].amount), transactionAmountPerItem[0].currency)
        let string = transactionAmountPerItem[0].count + ' x ' + str
        pricePerItem = string

    } else if (transactionAmountPerItem.length === 0) {
        pricePerItem = 0
    } else {
        let string
        for (var item of transactionAmountPerItem) {
            let str = printShort(toReadablePrice(item.amount), item.currency)
            let temp = item.count + ' x ' + str

            if (!string) {
                string = temp
            } else {
                string += ', '
                string += temp
            }
        }
        pricePerItem = string
    }

    return {
        pricePerItem: pricePerItem,
        packageName: _package.name,
        packageCount: _package.count,
        amount: _package.transactionAmount,
        amountFormatted: toReadablePrice(_package.transactionAmount, _package.currency),
        currency: _package.transactionCurrency,
        quantity: transactionAmountPerItem[0].count,
        unitPrice: toReadablePrice(transactionAmountPerItem[0].amount, transactionAmountPerItem[0].currency),

    }
}
*/

function getPackageStatementData(transactionDetails) {
    const data = []

    if (!transactionDetails?.package_transaction_details?.length) {
        return data
    }

    for (const packageTransactionDetail of transactionDetails.package_transaction_details) {
        for (const amount of packageTransactionDetail.amounts_per_item) {
            const packageData = {
                packageName: packageTransactionDetail.name,
                pacakgeCount: packageTransactionDetail.count,
                amount: amount.total_amount,
                amountFormatted: toReadablePrice(amount.total_amount, amount.currency),
                currency: amount.currency,
                quantity: amount.count,
                unitPrice: toReadablePrice(amount.amount, amount.currency)
            }
            data.push(packageData)
        }
    }
    return data
}

function getTicketStatementData(transactionDetails) {

    const data = []

    if (!transactionDetails?.event_transaction_details?.length) {
        return data
    }

    for (const eventTransactionDetail of transactionDetails.event_transaction_details) {
        for (const occurrenceTransactionDetail of eventTransactionDetail.organization_event_occurrences) {
            for (const ticketTransactionDetail of occurrenceTransactionDetail.tickets) {
                for (const amount of ticketTransactionDetail.amounts_per_item) {
                    const ticketData = {
                        eventName: eventTransactionDetail.name,
                        occurrenceName:  getOccurrenceDateString(occurrenceTransactionDetail),
                        ticketName: ticketTransactionDetail.name,
                        amount: amount.total_amount,
                        amountFormatted: toReadablePrice(amount.total_amount, amount.currency),
                        currency: amount.currency,
                        quantity: amount.count,
                        unitPrice:  toReadablePrice(amount.amount, amount.currency)
                    }
                    data.push(ticketData)
                }
            }
        }
    }

    return data
}

export function generatePdfStatementData(payout, organizationDetail, transactionDetails) {
    var meta = {}
    meta.statementId = payout.id
    meta.period = payout.from_date.split('T')[0]  + ' - ' + payout.to_date.split('T')[0]
    meta.totalTx = payout.transactions.length
    meta.DatePaid = payout.created_at
    meta.union = organizationDetail
    meta.net = toReadablePrice(payout.amount, payout.currency)
    meta.fee = toReadablePrice(payout.fee_amount, payout.currency)
    meta.total = toReadablePrice(payout.amount_without_fees, payout.currency)

    const packageData = getPackageStatementData(transactionDetails)
    const ticketData = getTicketStatementData(transactionDetails)

    return {meta: meta, packageData: packageData, ticketData: ticketData}
}

export async function generateAndDownloadPDF(payout, organizationDetail, transactionDetails) {
    try {
        const {meta, packageData, ticketData} = generatePdfStatementData(payout, organizationDetail, transactionDetails)
        await generatePDF(meta, packageData, ticketData);
    } catch (error) {
        console.error('Error generating/editing PDF:', error);
    }
}

export function getOccurrenceDateString(occurrence) {
    const result =  prettyOccurrenceDateFormat(occurrence?.start_date, occurrence?.end_date)
    if (!result) {
        return "" + prettyPrintDate(occurrence.start_date) + " - " + prettyPrintDate(occurrence.end_date)
    }
    return result
}

/*
export function getPayoutMeta(transactions) {
    if (!transactions || transactions.length <= 0) {
        return null
    }

    let tickets = []
    let packages = []
    for (var transaction of transactions) {
        if (transaction.ticket_transactions && transaction.ticket_transactions.length > 0) {

            let ticket = transaction.ticket_transactions[0].tickets
            let eventName = ticket?.organization_event_occurrences?.[0].organization_event?.title

            let ticketTransaction = transaction.ticket_transactions[0]
            let meta = JSON.parse(ticketTransaction.meta)
            let ticketMeta = meta.tickets.find(ticketMeta => ticketMeta.id === ticket.id)
            let count = ticketMeta.count

            let ticketData = {
                id: ticket.id,
                event: eventName,
                name: ticket.name,
                count: count,

                transactionAmount: transaction.amount,
                transactionCurrency: transaction.currency,

                transactions: [transaction]
            }
            addItem(tickets, ticket.id, ticketData)

        } else if (transaction.package_transactions && transaction.package_transactions.length > 0) {
            let _package = transaction.package_transactions[0].package
            let packageData = {
                id: _package.id,
                name: _package.name,
                count: 1,

                transactionAmount: transaction.amount,
                transactionCurrency: transaction.currency,

                transactions: [transaction]
            }
            addItem(packages, _package.id, packageData)
        }
    }

    let totalItemsCount = 0
    let ticketsCount = 0
    let packagesCount = 0

    for (var ticket of tickets) {
        totalItemsCount += ticket.count
        ticketsCount += ticket.count
    }

    for (var _package of packages) {
        totalItemsCount += _package.count
        packagesCount += _package.count
    }

    return {
        totalItemsCount,
        ticketsCount,
        packagesCount,
        tickets,
        packages
    }
}
*/

/*
function getTransactionAmountPerItem(transactions) {

    var data = []

    for (var transaction of transactions) {
        if (transaction.package_transactions && transaction.package_transactions.length > 0) {
            let current = data.find(data => data.amount === transaction.amount && data.currency === transaction.currency)
            if (current) {
                current.count++
            } else {
                data.push({ amount: transaction.amount, currency: transaction.currency, count: 1 })
            }
        } else if (transaction.ticket_transactions && transaction.ticket_transactions.length > 0) {

            let ticketTransaction = transaction.ticket_transactions[0]
            let meta = JSON.parse(ticketTransaction.meta)
            let ticketMeta = meta.tickets.find(ticketMeta => ticketMeta.id === ticketTransaction.tickets.id)
            let count = ticketMeta.count

            let pricePerItem = transaction.amount / count

            let current = data.find(data => data.amount === pricePerItem && data.currency === transaction.currency)
            if (current) {
                current.count = current.count + count
            } else {
                data.push({ amount: pricePerItem, currency: transaction.currency, count: count })
            }

        }
    }

    return data
}
*/

/*
function addItem(array, id, data) {
    var added = false
    for (var item of array) {
        if (item.id === id) {
            added = true
            item.count = item.count + data.count
            item.transactionAmount = item.transactionAmount + data.transactionAmount
            item.transactions.push(...data.transactions)
        }
    }

    if (!added) {
        array.push(data)
    }
}
*/