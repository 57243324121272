import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { getMediaById, getMedia, downloadMedia } from '../../../vibe/helpers/apiHelper';
import LazyImage from '../membership/LazyImage';
import placeholderImage from '../../../assets/images/placeholder.jpeg';
import { isSuperUser } from '../../../vibe/helpers/permissionHelper';

export default class MediaSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            previewModal: false,
            viewImage: false,
            activeFile: false,
            loadMoreIndex: 0,
            totalMedia: 0,
            files: [],
        }
        this.togglePreview = this.togglePreview.bind(this)
        this.addActiveClass = this.addActiveClass.bind(this);
        this.toggleView = this.toggleView.bind(this);
    }

    componentDidMount() {
        this.getImage()
    }

    downloadAllFileImages(medias) {
        var self = this
        medias.forEach((media) => {
            downloadMedia(media.file_path, (blob) => {
                var objectURL = URL.createObjectURL(blob);
                let files = self.state.files
                let newFile = {
                    fileName: media.file_name,
                    mediaId: media.id,
                    file: objectURL,
                    filePath: media.file_path,
                }
                files.push(newFile)
                const sortedFiles = files.sort((a, b) => b.mediaId - a.mediaId)
                self.setState({ files: sortedFiles })
            }, (error) => {
                console.log("DownloadMedia error", error)
            })
        })
    }
    fetchMedia() {
        this.setState({ files: [], fileNames: [] })
        var skip = 0
        getMedia(skip, (medias) => {
            // Check if SuperUser, Filter Images!
            if (this.props.user && isSuperUser(this.props.user)) {
                const currentOrgId = this.props.item.organization_id
                let result = medias.media.filter(media => media.organization_id === currentOrgId);

                medias = {
                    media: result,
                    totalCount: result.length
                }
            }

            this.downloadAllFileImages(medias.media)
            this.setState({
                totalMedia: medias.totalCount
            })
        }, (error) => {
            console.log("Get files error", error)
        })
    }
    loadMore() {
        this.setState((prevState) => ({
            loadMoreIndex: prevState.loadMoreIndex + 1
        }), () => this.fetchMore());
    }
    fetchMore() {
        var skip = this.state.loadMoreIndex * 20
        getMedia(skip, (medias) => {
            this.downloadAllFileImages(medias.media)
        }, (error) => {
            console.log("Get files error", error)
        })
    }


    onPreviewClick(file) {
        this.fetchMedia()
        this.setState({ previewModal: true, mediaPreview: file })
    }

    togglePreview(e = null) {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal
        }));
    }

    toggleView(e = null) {
        this.setState(prevState => ({
            viewImage: !prevState.viewImage
        }));
    }

    getImage(id) {
        var getId = id || this.props.mediaId
        if (getId) {
            getMediaById(getId, (media) => {
                this.setState({ currentImage: media });
            }, (error) => {
                console.log("Error fetching media", error)
            })
        }
    }

    removeImage() {
        this.setState({
            currentImage: '',
        })
        this.props.onDelete(this.props.itemId, this.props.index)
    }

    addActiveClass(file) {
        this.setState({ activeFile: file });
    }

    setActiveMedia() {
        this.togglePreview()
        this.props.onChange(this.state.activeFile.mediaId, this.props.item, this.props.index)
        this.setState({ currentImage: '' }, () => this.getImage(this.state.activeFile.mediaId))
    }

    render() {
        return <React.Fragment>
            <small><span className="btn-link media-select" onClick={(e) => this.onPreviewClick()}>Media library</span></small>

            {/* {this.state.currentImage ?
                <div className="d-flex align-items-end">
                    <div className="mr-2">
                        <div className="attachment-preview" onClick={()=> this.toggleView() }>
                            <div className="thumbnail">
                                <div className="centered">
                                    <LazyImage unloadedSrc={placeholderImage} fileName={this.state.currentImage.file_path} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Button color="primary" onClick={(e) => this.onPreviewClick()}>Replace Image</Button>{' '}
                        <Button color="danger" onClick={(e) => this.removeImage()}>Remove Image</Button>
                    </div>
                </div>
                :
                <Button className="block px-5 py-5" color="secondary" onClick={(e) => this.onPreviewClick()}>Select Image</Button>
            } */}

            <Modal size="lg" scrollable={true} isOpen={this.state.previewModal} toggle={this.togglePreview} className="previewModal">
                <ModalHeader toggle={this.togglePreview}>Media library</ModalHeader>
                <ModalBody>
                    <div className="row">
                        {this.state.files && this.state.files.map((file) => {
                            return (
                                <div key={file.mediaId} className={`mediaWrap ${this.state.activeFile && this.state.activeFile.mediaId === file.mediaId ? 'activeMedia' : ''}`} onClick={() => this.addActiveClass(file)}>
                                    <div className="attachment-preview">
                                        <div className="thumbnail">
                                            <div className="centered">
                                                <LazyImage unloadedSrc={placeholderImage} fileName={file.filePath}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={parseInt(this.state.totalMedia) === this.state.files.length} className="px-5" type="Button" color="primary" size="lg" onClick={() => { this.loadMore() }}>Load More...</Button>
                    <Button size="lg" disabled={!this.state.activeFile} className="px-5" color="primary" onClick={() => this.setActiveMedia()}>Set Image</Button>{' '}
                </ModalFooter>
            </Modal>


            <Modal size="xl" isOpen={this.state.viewImage} toggle={this.toggleView} className="viewModal">
                <ModalHeader toggle={this.toggleView}>Media</ModalHeader>
                <ModalBody>
                    <LazyImage unloadedSrc={placeholderImage} fileName={this.state.currentImage && this.state.currentImage.file_path} alt={'mediaPreview'} />
                    <p className="my-3"><strong>File Name:</strong> {this.state.currentImage && this.state.currentImage.file_name}</p>
                </ModalBody>
                <ModalFooter>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    }
}