import React from 'react';
import ReactSelect from 'react-select';
import { getSelectOptions } from './Currency';

export default class CurrencySelect extends React.Component {
    render() {
        const { currency } = this.props;
        const selectedOption = getSelectOptions().find(option => option.value === currency);
        const defaultValue = currency ? selectedOption : { value: 'SEK', label: 'SEK', name: 'SEK' };

        const customStyles = {
            container: (provided) => ({
                ...provided,
                width: '100%',
            }),
        };

        return <ReactSelect styles={customStyles} isSearchable={false} options={getSelectOptions()} onChange={(e) => this.props.onChange(e.value, "currency")} value={selectedOption || defaultValue} />
    }

}