import React, { Component }  from 'react';
import { Button, Card } from "reactstrap";
import { isDelete, PUBLISH_STATUS_PUBLISHED } from '../../../elements/form/FormAction';
import { capitalizeFirstLetter, prettyPrintDate } from '../../../../vibe/helpers/util';

class InterestListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {

    }

    deleteInterest(interest) {
        if (isDelete(interest)) {
            this.props.onDeleteInterest(interest)
            return
        }

        const memberInterestsCount = interest?.member_interests?.length ?? 0
        if (memberInterestsCount > 0) {
            let confirm = window.confirm("Are you sure you want to delete this registration of interest? All anwser data will be lost")
            if (!confirm) {
                return
            }
        }

        this.props.onDeleteInterest(interest)
    }

    render() {
        const interest = this.props.interest
        if (!interest) {
            return null
        }

        const memberInterestsCount = interest?.member_interests?.length ?? 0

        return <React.Fragment key={interest.id}>

                <Card style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }} className={"card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero " + (isDelete(interest) ? 'bg-danger' : '')}>

                    <div className="mb-0 text-small w-25 w-sm-100 pr-2">
                        <p className="list-item-heading mb-1">Name</p>
                        <p className="list-item-text mb-0">{interest.name}</p>
                        {interest?.status !== PUBLISH_STATUS_PUBLISHED && <p className="list-item-text mb-0 text-danger">{capitalizeFirstLetter(interest?.status)}</p>}
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Release start</p>
                        <p className="list-item-text mb-0">{prettyPrintDate(interest.start_date)} </p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Release end</p>
                        <p className="list-item-text mb-0">{prettyPrintDate(interest.end_date)} </p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Members</p>
                        <p className="list-item-text mb-0">{memberInterestsCount} interested</p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <Button className="btn-block" color="primary" onClick={() => this.props.onClickEditInterest(interest)}><i className="mr-1" />Edit</Button>
                    </div>

                </Card>

            </React.Fragment>
    }

}

export default InterestListItem;