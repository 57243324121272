import React, { Component } from 'react';
import {
    CardBody
} from 'reactstrap';
import { getMemberDeleteRequests } from '../../../vibe/helpers/apiHelper';
import DotsLoader from '../common/DotsLoader';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import MemberDeleteRequest from './MemberDeleteRequest';

class MemberDeleteRequests extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: false,
            deleteRequestLogs: []
        }
    }

    componentDidMount() {
        const memberId = this?.props?.member?.id
        if (!memberId) {
            this.setState({error: 'No member id'})
            return
        }

        this.getMemberDeleteRequests(memberId)
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.member && this.props.member) {
            this.getMemberDeleteRequests(this.props.member.id)
        }
    }

    getMemberDeleteRequests(memberId) {
        getMemberDeleteRequests(memberId, (deleteRequestLogs) => {
            this.setState({deleteRequestLogs: deleteRequestLogs, error: null, loading: false})
        }, (error) => {
            const errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })
    }

    renderDeleteRequests(deleteRequestLogs) {
        return <div>
            {deleteRequestLogs.map((deleteRequestLog) => {
                return <MemberDeleteRequest key={deleteRequestLog.id} deleteRequest={deleteRequestLog} onAddTransactionLogToDelete={this.props.onAddTransactionLogToDelete} transactionLogsToDelete={this.props.transactionLogsToDelete} />
            })}
        </div>
    }

    render() {
        if (this.state.error) {
            return <CardBody className='py-4'><div>{this.state.error}</div></CardBody>
        } else if (this.state.loading) {
            return <DotsLoader />
        } else {
            return <CardBody className='py-4'>
                <h5 className="bold-600">Delete requests initiated by member</h5>
                {this.renderDeleteRequests(this.state.deleteRequestLogs)}
            </CardBody>
        }
    }

}

export default MemberDeleteRequests;