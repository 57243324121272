import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getSegments } from '../../../vibe/helpers/apiHelper';
import Segment from '../../elements/segments/Segment';
import { Link } from "react-router-dom";
import Select from 'react-select'
import Pager from '../../elements/form/Pager';

const pageSize = 10
const allSegments = 'All segments'

class Segments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentPage: 1,
      segmentNameOptions: [],
      selectedSegment: allSegments,
      selectedOrganization: null
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    var where = '&filter[limit]=' + pageSize
    getSegments(0, where, (result) => {
      this.setState({ totalCount: result.totalCount, segments: result.segments, currentPage: 1, loading: false, total_items: [...Array(parseInt(result.totalCount)).keys()].map(i => ({})) })
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1 })
    })

    getSegments(0, '', (result) => {
      let segmentOptions = []
      let organizationOptions = []
      segmentOptions.push({ value: allSegments, label: allSegments })
      let segments = result.segments
      if (segments && segments.length > 0) {
        segments.forEach((segment) => {
          let segmentOption = {
            value: segment.id,
            label: segment.name,
          }
          segmentOptions.push(segmentOption)

          if (!organizationOptions.some(orgOption => orgOption.value === segment?.organization?.id)) {
            let organizationOption = {
              value: segment?.organization?.id,
              label: segment?.organization?.name
            }
            organizationOptions.push(organizationOption)
          }
        })
      }
      this.setState({
        segmentNameOptions: segmentOptions,
        organizationOptions: organizationOptions
      })
    }, (error) => {
      console.log("Error getting customers", error)
    })

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.getSegmentsWithFilter(this.state.currentPage, false);
    }
  }

  getSegmentsWithFilter(page, reset) {
    const offset = (this.state.currentPage - 1) * pageSize;

    let where = '';
    if (this.state.selectedSegment !== allSegments) {
      where = '&filter[where][id][eq]=' + this.state.selectedSegment;
    }

    // Add organization filter if an organization is selected
    if (this.state.selectedOrganization) {
      where += '&filter[where][organization_id][eq]=' + this.state.selectedOrganization.value;
    }

    where += '&filter[limit]=' + pageSize;
    getSegments(offset, where, (result) => {
      if (reset) {
        this.setState({ totalCount: result.totalCount, segments: result.segments, currentPage: 1, loading: false });
      } else {
        this.setState({ totalCount: result.totalCount, segments: result.segments, loading: false });
      }
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1 });
    });
  }

  renderSegments() {
    if (this.state && this.state.segments) {
      return this.state.segments.map((segment) => {
        return <Segment key={segment.id} segment={segment}></Segment>
      })
    }
  }

  onChangePage(page) {
    this.setState({ currentPage: page });
  }

  onSelectSegmentName(e) {
    this.setState({ selectedSegment: e.value }, () => {
      this.getSegmentsWithFilter(1, true)
    })
  }

  onSelectOrganization(e) {
    if (!e) {
      // If "Clear" is selected
      this.setState({ selectedOrganization: null }, () => {
        this.getSegmentsWithFilter(1, true);
      });
    } else {
      this.setState({ selectedOrganization: e }, () => {
        this.getSegmentsWithFilter(1, true);
      });
    }

  }

  render() {

    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>

            <header className="app-header-page justify-content-end">
              <div className="col-md-3 mw-350">
                <FormGroup className="mb-0 pt-1">
                  <Link
                    className="btn btn-primary block"
                    to="/segment/new">
                    New Segment
                  </Link>
                </FormGroup>
              </div>
            </header>

            <div className="row mb-3 align-items-center ">
              <div className="col">
                <h1 className="h4 mb-0">Segments</h1>
              </div>
              <div className="col col-xl-4 col-lg-5 mw-350">
                <Select
                  options={this.state.organizationOptions} // Use your organization data here
                  value={this.state.selectedOrganization}
                  onChange={(e) => this.onSelectOrganization(e)}
                  placeholder={"Select organization"}
                  isClearable={true} 
                />
              </div>
              <div className="col col-xl-4 col-lg-5 mw-350">
                <Select isClearable={true} options={this.state.segmentNameOptions} defaultValue={this.state.segmentNameOptions.length > 0 ? this.state.segmentNameOptions.filter(option => option.label === allSegments) : ''} onChange={(e) => this.onSelectSegmentName(e)} placeholder={"Search segment"} />
              </div>
            </div>

            <div>
              {this.renderSegments()}
              <Pager totalCount={this.state.totalCount} currentPage={this.state.currentPage} onChangePage={(e) => this.onChangePage(e)} />

            </div>
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default Segments;
