import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import './vibe/scss/styles.scss';
import Privacy from './views/pages/privacy-policy/privacy-policy';
import Terms from './views/pages/terms-of-services/terms-of-services';
import Login from './views/pages/login/Login';
import Signup from './views/pages/Signup/Signup';
import ForgotPasword from './views/pages/forgot_password/forgot_password';
import PasswordRecover from './views/pages/password_recover/password_recover';

export default function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/terms" component={Terms} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/login" component={Login} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPasword} />
        <Route path="/password-recover/:recover_code" component={PasswordRecover} />
        <Route path="/" component={DashboardLayout} />
      </Switch>
    </BrowserRouter>
  );
} 