import React from 'react';
import { FormGroup, Label, Button } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getOrganizationDetails, getPayoutById, getPayoutTransactionDetails } from '../../../vibe/helpers/apiHelper';
import { getPayoutStatus, parseErrorMessage } from '../../../vibe/helpers/util';
import { printShort, toReadablePrice } from '../../elements/form/Currency';
import ListHeader from '../../elements/form/ListHeader';
import PayoutPurchaseDetails from './PayoutPurchaseDetails';
import { generateAndDownloadPDF } from '../../../vibe/helpers/payoutHelper';

class ViewPayoutDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pendingTransactions: [],
            pendingTransactionsCount: 0,
            totalAmountPending: [],
            ticketsData: null,
            packagesData: null,
            organizationDetail: null,
            transactionDetails: null
        }
    }

    componentDidMount() {
        let id = parseInt(this.props.match.params.id)
        let payoutId = parseInt(this.props.match.params.payoutId)
        this.setState({ organizationId: id, payoutId: payoutId })

        this.getPayout(payoutId)
        this.getOrg(id)
    }

    getOrg(organizationDetailId) {
        getOrganizationDetails(organizationDetailId, (organizationDetail) => {
            if (organizationDetail) {
                this.setState({ organizationDetail: organizationDetail })
            }
        }, (error) => {
            var errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })
    }


    getPayout(payoutId) {
        getPayoutById(payoutId, (payout) => {
            getPayoutTransactionDetails(payoutId, (transactionDetails) => {
                this.setState({ payout: payout, loading: false, transactionDetails: transactionDetails, error: null })
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage, loading: false })
                console.log("getPayoutTransactionDetails error", error)
            })

        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })
    }

    renderAction(payout) {
        if (!payout) {
            return null
        }

        return <div className='mb-3'>
            <Label for="status" className="list-item-heading mb-1">Status</Label>
            <div>{getPayoutStatus(payout.status)}</div>
        </div>
    }

    // TODO DEV-8413 remove
    /*
    pdfGenerate = async () => {
        let payoutMeta = getPayoutMeta(this.state.payout.transactions)
        payoutMeta.statementId = this.state.payout.id
        payoutMeta.period = this.state.payout.from_date.split('T')[0]  + ' - ' + this.state.payout.to_date.split('T')[0]
        payoutMeta.totalTx = this.state.payout.transactions.length
        payoutMeta.DatePaid = this.state.payout.created_at
        payoutMeta.union = this.state.organizationDetail
        payoutMeta.net = toReadablePrice(this.state.payout.amount, this.state.payout.currency)
        payoutMeta.fee = toReadablePrice(this.state.payout.fee_amount, this.state.payout.currency)
        payoutMeta.total = toReadablePrice(this.state.payout.amount_without_fees, this.state.payout.currency)

        let packageData = []
        for (let i = 0; i < payoutMeta.packages.length; i++) {
            let data = packagePayoutData(payoutMeta.packages[i])
            packageData.push(data)
        }
        let ticketData = []
        for (let i = 0; i < payoutMeta.tickets.length; i++) {
            let data = ticketPayoutData(payoutMeta.tickets[i])
            ticketData.push(data)
        }

        try {
            await generatePDF(payoutMeta, packageData, ticketData);
        } catch (error) {
            console.error('Error generating/editing PDF:', error);
        }
    }
    */

    render() {

        if (this.state.error) {
            return <>Error: {this.state.error}</>
        }

        if (this.state.loading) {
            return <Loader type="dots" />
        }

        return <>

            <ListHeader title={'Payout details'} links={''} />

            <header className="app-header-page justify-content-end">
                <div className="col-md-3 mw-350">
                    <FormGroup className="mb-0 pt-1">
                        <Button color="primary" block={true} onClick={() => generateAndDownloadPDF(this.state.payout, this.state.transactionDetails?.organization_details, this.state.transactionDetails)}>
                            Download PDF
                        </Button>
                    </FormGroup>
                </div>
            </header>

            <div>
                <div className="row mb-3">
                    <div className="col-md-6 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <h4 className="card-title">Payout Summary</h4>
                                <div className="card-text">{this.renderAction(this.state.payout)}</div>
                                <div className="card-text">
                                    <Label className="list-item-heading mb-1" for="notes">Notes</Label>
                                    <div className="bg-light text-black p-2">{this.state.payout.notes ? this.state.payout.notes : ''}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <h4 className="card-title">Financial Summary</h4>
                                <div className="mb-2"><p className="list-item-heading mb-0">Total Sales Amount</p> {printShort(toReadablePrice(this.state.payout.amount_without_fees), this.state.payout.currency)}</div>
                                <div className="mb-2"><p className="list-item-heading mb-0">Fee Amount</p> {printShort(toReadablePrice(this.state.payout.fee_amount), this.state.payout.currency)}</div>
                                <div className="mb-2 bold-600"><p className="list-item-heading mb-0">Payout Amount</p> {printShort(toReadablePrice(this.state.payout.amount), this.state.payout.currency)}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-start align-items-start">
                    <PayoutPurchaseDetails transactions={this.state.payout.transactions} transactionDetails={this.state.transactionDetails} />
                </div>

            </div>
        </>
    }

}

export default ViewPayoutDetails
