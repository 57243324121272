import React, { Component } from "react";

class TransactionMessages extends Component {

    renderTransactionMessages(transaction) {
        if(!transaction || !transaction.transaction_messages || transaction.transaction_messages.length <= 0) {
            return null;
        }
       
        let transactionMessages = transaction.transaction_messages
        return transactionMessages.map((message, index) => {
            return(
                <React.Fragment key={index}>
                    <div dangerouslySetInnerHTML={{ __html: message.message }} />
                </React.Fragment>
            )
        })
    }

    render() {
        return <>
            <div className="row mb-3">
                <div className="col-md-12 col-12">
                    <div className="card h-100">
                        <div className="card-body">
                            <h4 className="card-title">Transaction refund message</h4>
                            <div className="card-text">
                                <p className=" list-item-text mb-3">{this.renderTransactionMessages(this.props.transaction)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default TransactionMessages;