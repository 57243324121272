import { downloadMedia } from "../../../vibe/helpers/apiHelper";
import React from 'react';

export default class LazyImage extends React.Component {

    constructor(props) {
      super(props);
      this.state = {
        loaded: false,
        error: false
      };
    }

    componentDidMount() {
      var cached 
      if (this.props.getCache) {
        cached = this.props.getCache(this.props.fileName)
      }
      if (cached) {
        this.setState({blob: cached, loaded: true})
      } else {
        downloadMedia(this.props.fileName, (blob) => {
            var objectURL = URL.createObjectURL(blob);
            if (this.props.setCache) {
              this.props.setCache(this.props.fileName, objectURL)
            }
            this.setState({blob: objectURL, loaded: true})
        }, (error) => {
            this.setState({error: true})
            console.log("Error getting image option file")
        })
      }
    }
  
    render() {
      if (this.state.error) {
        return <img
          className={this.props.className}
          style={this.props.style}
          src={this.props.unloadedSrc}
          alt={this.props.alt} />
      } else if (!this.state.loaded) {
        return <img
          className={this.props.className}
          style={this.props.style}
          src={this.props.unloadedSrc}
          alt={this.props.alt} />
      }
      return <img
        className={this.props.className}
        style={this.props.style}
        src={this.state.blob}
        alt={this.props.alt} />
    }
  }