import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';

class FormDatePicker extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return <FormGroup>
                    <Label for={this.props.label}>{this.props.label}</Label>
                    <div className='d-flex'>
                        <Input style={{width: '100%'}} onSelect={(e) => console.log("on select", e)} type="date" onBlur={(e) => console.log("blur")} onChange={this.props.onChange} name={this.props.label} id={this.props.label}  />
                    </div>
               </FormGroup>
    }

}

export default FormDatePicker