import React from 'react';
import { Button, Col, FormGroup, Row, Spinner } from 'reactstrap';
import { Loader } from '../../../vibe';
import { completePayoutById, getPayoutTransactionDetails, getPayoutsByYearAndMonth } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import ListHeader from '../../elements/form/ListHeader';
import Select from 'react-select'
import PayoutItemDetailed from './PayoutItemDetailed';
import PayoutConfirmModal from './PayoutConfirmModal';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import { STATUS_PENDING, downloadAllPdfsAsZip, generateAndDownloadPDF } from '../../../vibe/helpers/payoutHelper';
import { MONTHS } from './Payouts';

class AllPayouts extends React.Component { 

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            error: null,
            periodOptions: [],
            selected: {
                year: -1,
                month: -1
            },
            modalOpen: false,
            payouts: [],
            allPdfDownloadLoading: false,
            markAsCompleteResult: {
                successes: 0,
                errors: []
            }
        }
    }

    componentDidMount() {
        const periods = this.generateMonths(2023, 8) // starting from September 2023, until current month - 1
        this.setState({periodOptions: periods}, () => {
            this.onChangePeriod(periods[0])
        })
    }

    generateMonths(startYear, startMonth) {
        const result = [];

        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() - 1;
      
        let year = startYear;
        let month = startMonth;
      
        while (year < currentYear || (year === currentYear && month <= currentMonth)) {
            const label = `${year} ${MONTHS[month]}`
          result.push({
            year: year,
            month: month,
            label: label,
            value: label
          });
      
          month++;
          if (month > 11) {
            month = 0;
            year++;
          }
        }
      
        return result.reverse();
    }

    onChangePeriod(option) {
        var selected = this.state.selected
        selected.period = option.value
        selected.year = option.year
        selected.month = option.month

        this.setState({selected: selected}, () => {
            if (this.isMonthAndYearSelected()) {
                this.fetchPayouts()
            }
        })
    }

    isMonthAndYearSelected() {
        return this.state?.selected?.year >= 0 && this.state?.selected?.month >= 0
    }

    fetchPayouts(onComplete) {
        this.setState({loading: true, markAsCompleteResult: {successes: 0, errors: []}})

        const filter = {
            order: ['status ASC'],
            include: [
                {
                    relation: 'transactions',
                    scope: {
                        include: [
                            {
                                relation: 'package_transactions',
                                scope: {
                                    include: [
                                        {
                                            relation: 'package'
                                        }
                                    ]
                                }
                            },
                            {
                                relation: 'ticket_transactions',
                                scope: {
                                    include: [
                                        {
                                            relation: 'tickets',
                                            scope: {
                                                include: [
                                                    {
                                                        relation: 'organization_event_occurrences',
                                                        scope: {
                                                            include: [
                                                                {
                                                                    relation: 'organization_event'
                                                                }
                                                            ]
                                                        }
                                                    }
                                                ]
                                            }
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    relation: 'organization',
                    scope: {
                        include: [
                            {
                                relation: 'organization_details'
                            }
                        ]
                    }
                }
            ]
        }
        getPayoutsByYearAndMonth(this.state.selected.year, this.state.selected.month, filter, (payouts) => {
            this.setState({payouts: payouts, loading: false, error: null})
            if (onComplete) {
                onComplete()
            }
        }, (error) => { 
            const errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false, payouts: []})
            if (onComplete) {
                onComplete()
            }
        })
    }

    renderSelects() {
        return <>
            <Col md={3} className='mb-3'>
                <Select 
                    options={this.state.periodOptions} 
                    onChange={(e) => this.onChangePeriod(e)} 
                    placeholder={'Select month...'} 
                    value={this.state.periodOptions.filter((option) => option.value === this.state.selected.period)}  />
            </Col>
        </>
    }

    renderPayouts() {
        if (!this.state.payouts || this.state.payouts.length <= 0) {
            return null
        }

        return this.state.payouts.map((payout) => {
            return <PayoutItemDetailed key={payout.id} payout={payout} organizationId={payout.organizationId} onCompletePayout={(payout) => this.onCompletePayout(payout)} onDownloadPdf={(payout) => this.onDownloadPdf(payout)} />
        })
    }

    onCompletePayout(payout) {
        this.setState({ loading: true })
        completePayoutById(payout.id, undefined, () => {
            this.fetchPayouts()
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })
    }

    async onDownloadPdf(payout) {
        getPayoutTransactionDetails(payout.id, (transactionDetails) => {
            try {
                generateAndDownloadPDF(payout, transactionDetails?.organization_details, transactionDetails)
            } catch (error) {
                console.error('Error generating/editing PDF:', error);
            }

        }, (error) => {
            console.error('Error generating/editing PDF:', error);
        })
    }

    async onDownloadAllPdfs() {
        if (!this.state.payouts || this.state.payouts.length <= 0) {
            return
        }
        const {year, month} = this.state.selected
        const monthName = MONTHS[month].toLowerCase()
        this.setState({allPdfDownloadLoading: true}, async () => {
            await downloadAllPdfsAsZip(this.state.payouts, year, monthName)
            this.setState({allPdfDownloadLoading: false})
        })
    }

    onMarkAllAsComplete() {
        this.setState({modalOpen: true})
    }

    renderActionButtons() {
        return <FormGroup className="mb-0 pt-1">
            <Button className='mx-2 my-0 mb-1 d-inline-block mr-2' disabled={!this.isMonthAndYearSelected() || this.filterPayoutsByStatus(this.state.payouts, STATUS_PENDING).length <= 0 || this.state.payouts.length <= 0} onClick={(e) => this.onMarkAllAsComplete(e)} color="success">Mark all as Complete</Button>
            <Button className='mx-2 my-0 mb-1 d-inline-block mr-2' disabled={!this.isMonthAndYearSelected() || this.state.payouts.length <= 0} onClick={(e) => this.onDownloadAllPdfs(e)} color="primary">{this.state.allPdfDownloadLoading ? <Spinner size="sm" /> : 'Download all PDFs'}</Button>  
        </FormGroup>
    }

    toggleModal() {
        this.setState(prevState => ({
            modalOpen: !prevState.modalOpen
        }))
    }

    filterPayoutsByStatus(payouts, status) {
        return payouts.filter((payout) => payout.status === status)
    }

    onCompletePayouts(payouts) {

        this.setState({modalOpen: false})
        if (!payouts || payouts.length <= 0) {
            return
        }

        var payoutsToHandle = payouts.length

        var markAsCompleteResult = this.state.markAsCompleteResult
        markAsCompleteResult = {
            successes: 0,
            errors: []
        }

        this.setState({loading: true})
        for (const payout of payouts) {
            
            completePayoutById(payout.id, undefined, () => {

                payoutsToHandle = payoutsToHandle - 1

                markAsCompleteResult.successes = markAsCompleteResult.successes + 1

                this.onMarkAsCompleteDone(payoutsToHandle, markAsCompleteResult)
            }, (error) => {

                payoutsToHandle = payoutsToHandle - 1

                let errorMessage = parseErrorMessage(error)
                markAsCompleteResult.errors.push(errorMessage)
                
                this.onMarkAsCompleteDone(payoutsToHandle, markAsCompleteResult)
            })
        }
    }

    onMarkAsCompleteDone(payoutsToHandle, markAsCompleteResult) {
        if (payoutsToHandle === 0) {
            this.fetchPayouts(() => {
                this.setState({markAsCompleteResult: markAsCompleteResult})

                // show success message for 5 seconds, show error messages until next fetch
                setTimeout(() => this.setState({ markAsCompleteResult: {successes: 0, errors: markAsCompleteResult.errors} }), 5000)
            })
        }
    }

    renderAlerts() {

        var successAlert = null
        var errorAlert = null

        if (this.state?.markAsCompleteResult?.successes > 0) {
            successAlert = <MessageUncontrolledAlert success={true} successMessage={this.state.markAsCompleteResult.successes + ' Payouts successfully marked as Complete'} />
        }

        if (this.state?.markAsCompleteResult?.errors?.length > 0) {
            const errorString = this.state.markAsCompleteResult.errors.join(', ')
            errorAlert = <MessageUncontrolledAlert error={true} errorMessage={this.state.markAsCompleteResult.errors.length + ' Payouts failed to mark as Complete. Errors: ' + errorString} />
        }

        return <>
                {errorAlert}
                {successAlert}
            </>
    }

    render() {

        if (this.state.loading) {
            return <Loader type="dots" />
        }

        return <>

            {this.renderAlerts()}

            <ListHeader title={'Payouts'} links={''} />

            {this.renderContent()}
        </>
    }

    renderContent() {

        if (this.state.error) {
            return <div>{this.state.error}</div>
        }

        return <>

            <header className="app-header-page justify-content-end">
                {this.renderActionButtons()} 
            </header>

            <Row>
                {this.renderSelects()}
            </Row>

            <div className='row mb-3 mt-5'>
                <Col>
                    {this.renderPayouts()}
                </Col>
            </div> 

            <PayoutConfirmModal toggle={this.toggleModal.bind(this)} onCompletePayouts={(payouts) => this.onCompletePayouts(payouts)} payouts={this.filterPayoutsByStatus(this.state.payouts, STATUS_PENDING)} title={"Mark all as Complete"} opened={this.state.modalOpen} />                    
        </>
    }

}

export default AllPayouts