import React, { Component } from 'react';
import { Input, Col, Form, FormGroup, Label, Row } from 'reactstrap';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { getMediaById, downloadMedia, postMediaDragDrop, } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import FileUpload from '../../elements/media/upload'
import MediaSelect from '../../elements/media/MediaSelect';
import { getCurrentUser } from '../../../vibe/helpers/apiHelper';
import OrganizationTags from '../../pages/organization/event/OrganizationTags';
import { EVENT_TAG_IDS } from '../../../vibe/helpers/organizationHelper';

class OrganizationSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({ user: user, error: null })
        }, (error) => {
            this.setState({ error: error, user: null })
        })

        this.getImage()
    }

    handleFileRemove = (imageKey) => {
        this.props.onChange(null, imageKey)
    }
    handleFileUpload = (uploadedFiles, imageKey) => {
        // Handle the uploaded files here
        let orgId = this.props.organizationDetails.organization_id

        postMediaDragDrop(uploadedFiles, orgId, (media) => {
            this.props.onChange(media[0].id, imageKey)

        }, (error) => {
            var errorMessage = parseErrorMessage(error)
            this.setState({ filesUploadError: errorMessage })
            console.log("Post file error", error)
        })
    }
    getImage() {
        let getLogoId = this.props.organizationDetails.logo_media_id
        let getImageId = this.props.organizationDetails.image_media_id
        let getImagePageId = this.props.organizationDetails.image_media_page_id;

        if (getLogoId) {
            getMediaById(getLogoId, (media) => {
                downloadMedia(media.file_path, (blob) => {
                    var objectURL = URL.createObjectURL(blob);
                    let image = [
                        {
                            preview: objectURL,
                            name: media.file_name,
                            size: media.size,
                            path: media.file_path,
                        }
                    ]
                    this.setState({ currentLogoImage: image })
                }, (error) => {
                    console.log("Error getting image option file")
                })
            }, (error) => {
                console.log("Error fetching media", error)
            })
        }
        if (getImageId) {
            getMediaById(getImageId, (media) => {
                downloadMedia(media.file_path, (blob) => {
                    var objectURL = URL.createObjectURL(blob);
                    let image = [
                        {
                            preview: objectURL,
                            name: media.file_name,
                            size: media.size,
                            path: media.file_path,
                        }
                    ]
                    this.setState({ currentImageMedia: image })
                }, (error) => {
                    console.log("Error getting image option file")
                })
            }, (error) => {
                console.log("Error fetching media", error)
            })
        }
        if (getImagePageId) {
            getMediaById(
              getImagePageId,
              (media) => {
                downloadMedia(
                  media.file_path,
                  (blob) => {
                    var objectURL = URL.createObjectURL(blob);
                    let image = [
                      {
                        preview: objectURL,
                        name: media.file_name,
                        size: media.size,
                        path: media.file_path,
                      },
                    ];
                    this.setState({ currentImagePageMedia: image });
                  },
                  (error) => {
                    console.log('Error getting image option file');
                  }
                );
              },
              (error) => {
                console.log('Error fetching media', error);
              }
            );
          }
    }

    render() {
        let organizationDetails = this.props.organizationDetails
        return <Form>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" name="name" id="name" defaultValue={organizationDetails ? organizationDetails.name : ''} onChange={(e) => this.props.onChange(e.target.value, 'name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="display_name">Contact email</Label>
                        <Input type="text" name="display_name" id="display_name" defaultValue={organizationDetails ? organizationDetails.contact_email : ''} onChange={(e) => this.props.onChange(e.target.value, 'contact_email')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="category_name">Contact phone number</Label>
                        <Input type="text" name="category_name" id="category_name" defaultValue={organizationDetails ? organizationDetails.contact_phone_number : ''} onChange={(e) => this.props.onChange(e.target.value, 'contact_phone_number')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="country">Website</Label>
                        <Input type="text" name="country" id="country" defaultValue={organizationDetails ? organizationDetails.website : ''} onChange={(e) => this.props.onChange(e.target.value, 'website')} />
                    </FormGroup>

                    <Row>
                        <Col>
                            <Label for="image">Organization feed image</Label>
                            <FileUpload ratio={"3.23/1"} onFileUpload={(e) => this.handleFileUpload(e, 'image_media_id')} onFileRemove={() => this.handleFileRemove('image_media_id')} currentImage={this.state.currentImageMedia} />
                            <FormGroup className="d-flex justify-content-between">
                                <MediaSelect user={this.state.user} item={organizationDetails} mediaId={organizationDetails && organizationDetails.image_media_id} itemId={organizationDetails && organizationDetails.id}
                                    onChange={(id, item, index) => { this.props.onChange(id, 'image_media_id'); this.getImage() }} onDelete={(itemId, index) => this.props.onChange(null, 'image_media_id')} />
                                <p className="mini-font">Recommended size 1329x411px</p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row> 
                        <div className="col-md-4">
                            <Label for="image">Logo</Label>
                            <FileUpload ratio={"1/1"} onFileUpload={(e) => this.handleFileUpload(e, 'logo_media_id')} onFileRemove={() => this.handleFileRemove('logo_media_id')} currentImage={this.state.currentLogoImage} />
                            <FormGroup className="d-flex">
                                <MediaSelect user={this.state.user} item={organizationDetails} mediaId={organizationDetails && organizationDetails.logo_media_id} itemId={organizationDetails && organizationDetails.id}
                                    onChange={(id, item, index) => { this.props.onChange(id, 'logo_media_id'); this.getImage() }} onDelete={(itemId, index) => this.props.onChange(null, 'logo_media_id')} />
                            </FormGroup>
                        </div>
                        <div className="col-md-8">
                            <Label for="image">Organization page image</Label>
                            <FileUpload ratio={"3/2"} onFileUpload={(e) => this.handleFileUpload(e, 'image_media_page_id')} onFileRemove={() => this.handleFileRemove('image_media_page_id')} currentImage={this.state.currentImagePageMedia} />
                            <FormGroup className="d-flex justify-content-between">
                                <MediaSelect user={this.state.user} item={organizationDetails} mediaId={organizationDetails && organizationDetails.image_media_page_id} itemId={organizationDetails && organizationDetails.id}
                                    onChange={(id, item, index) => { this.props.onChange(id, 'image_media_page_id'); this.getImage() }} onDelete={(itemId, index) => this.props.onChange(null, 'image_media_page_id')} />
                                <p className="mini-font">Recommended size 1920x1280px</p>
                            </FormGroup>                            
                        </div>
                    </Row>
                    <Row>
                    <Col md="12" className='mt-2'>
                            <FormGroup className="mb-0 pt-1">
                                 <Label for="tags">Tags</Label>
                                 <OrganizationTags
                                    tags={this.props.tags}
                                    pendingAddTags={this.props.pendingAddTags}
                                    pendingDeleteTags={this.props.pendingDeleteTags}
                                    tempSelectedTags={this.props.tempSelectedTags}
                                    onAddTag={this.props.onAddTag}
                                    skipTagOptionIds={EVENT_TAG_IDS}
                                    onDeleteTag={this.props.onDeleteTag} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <FormGroup className="mb-0 pt-1">
                                <Label for="image">Status</Label>
                                <Input
                                    type="select"
                                    name="select"
                                    id="statusFilter"
                                    defaultValue={organizationDetails.status}
                                    onChange={(e) => this.props.onChange(e.target.value, 'status')}>
                                    <option value="published">Published</option>
                                    <option value="draft">Draft</option>
                                </Input>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col sm="6">

                    <FormGroup>
                        <Label for="email">Description</Label>
                        <Input type="text" name="email" id="email" defaultValue={organizationDetails ? organizationDetails.description : ''} onChange={(e) => this.props.onChange(e.target.value, 'description')} /> {' '}
                    </FormGroup>

                    <FormGroup>
                        <Label for="about_us">About us</Label>
                        <CKEditor
                            editor={ClassicEditor}
                            data={organizationDetails && organizationDetails.about_us ? organizationDetails.about_us : ''}
                            onReady={editor => {
                            }}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                this.props.onChange(data, 'about_us')
                            }}
                            onBlur={(event, editor) => {
                            }}
                            onFocus={(event, editor) => {
                            }}
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label for="legal_name">Legal name</Label>
                        <Input type="text" name="legal_name" id="legal_name" defaultValue={organizationDetails.legal_name} onChange={(e) => this.props.onChange(e.target.value, 'legal_name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="organization_number">Organization number</Label>
                        <Input type="text" name="organization_number" id="organization_number" defaultValue={organizationDetails.organization_number} onChange={(e) => this.props.onChange(e.target.value, 'organization_number')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="street_address">Street address</Label>
                        <Input type="text" name="street_address" id="street_address" defaultValue={organizationDetails.street_address} onChange={(e) => this.props.onChange(e.target.value, 'street_address')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input type="text" name="city" id="city" defaultValue={organizationDetails.city} onChange={(e) => this.props.onChange(e.target.value, 'city')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="zip_code">Zip code</Label>
                        <Input type="text" name="zip_code" id="zip_code" defaultValue={organizationDetails.zip_code} onChange={(e) => this.props.onChange(e.target.value, 'zip_code')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="bank_name">Bank name</Label>
                        <Input type="text" name="bank_name" id="bank_name" defaultValue={organizationDetails.bank_name} onChange={(e) => this.props.onChange(e.target.value, 'bank_name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="clearing_number">Sort code (Clearingnummer)</Label>
                        <Input type="text" name="clearing_number" id="clearing_number" defaultValue={organizationDetails.clearing_number} onChange={(e) => this.props.onChange(e.target.value, 'clearing_number')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="account_number">Account number</Label>
                        <Input type="text" name="account_number" id="account_number" defaultValue={organizationDetails.account_number} onChange={(e) => this.props.onChange(e.target.value, 'account_number')} />
                    </FormGroup>
                </Col>
            </Row>
        </Form>
    }

}

export default OrganizationSettings;
