import React, { Component } from 'react';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import MediaSelect from '../../elements/media/MediaSelect';
import FileUpload from '../../elements/media/upload';
import MultiLanguageInput from '../common/MultiLanguageInput';
import { toMultiLanguageData } from '../../../vibe/helpers/language';
import MultiLanguageClassicEditor from '../../elements/form/MultiLanguageClassicEditor';

const descriptionLanguageKeys = {
  primary: 'description', 
  secondary: 'description_en'
}

const aboutUsLanguageKeys = {
  primary: 'about_us', 
  secondary: 'about_us_en'
}

class EditOrganizationDetailsForm extends Component {
  componentDidMount() {}

  render() {
    return this.props && this.props.organizationDetail ? (
      <CardBody>
        <Form>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  defaultValue={this.props.organizationDetail.name}
                  onChange={(e) => this.props.onChange(e.target.value, 'name')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="contact_email">Contact email</Label>
                <Input
                  type="text"
                  name="contact_email"
                  id="contact_email"
                  defaultValue={this.props.organizationDetail.contact_email}
                  onChange={(e) => this.props.onChange(e.target.value, 'contact_email')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="contact_phone_number">Contact phone number</Label>
                <Input
                  type="text"
                  name="contact_phone_number"
                  id="contact_phone_number"
                  defaultValue={this.props.organizationDetail.contact_phone_number}
                  onChange={(e) => this.props.onChange(e.target.value, 'contact_phone_number')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="website">Website</Label>
                <Input
                  type="text"
                  name="website"
                  id="website"
                  defaultValue={this.props.organizationDetail.website}
                  onChange={(e) => this.props.onChange(e.target.value, 'website')}
                />
              </FormGroup>

              <Row>
                  <div className="col-lg-12">
                    <Label for="image">Organization feed image</Label>
                    <FileUpload
                      onFileUpload={(e) => this.props.handleFileUpload(e, 'image_media_id')}
                      onFileRemove={() => this.props.handleFileRemove('image_media_id')}
                      currentImage={this.props.currentImageMedia}
                      ratio={"3.23/1"}
                    />
                    <div className="d-flex justify-content-between">
                      <FormGroup className="d-flex">
                        <MediaSelect
                          files={this.props.files}
                          item={this.props.organizationDetail}
                          mediaId={this.props.organizationDetail.image_media_id}
                          itemId={this.props.organizationDetail.id}
                          onChange={(id, item, index) => {
                            this.props.onChange(id, 'image_media_id');
                            this.props.getImage();
                          }}
                          onDelete={(itemId, index) => this.props.onChange(null, 'image_media_id')}
                        />
                      </FormGroup>
                      <p className="mini-font align-items-end">Recommended size 1329x411px</p>
                    </div>
                  </div>
                </Row>

                <Row>
                <div className="col-lg-4">
                  <Label for="image">Logo</Label>
                    <FileUpload
                      ratio={"1/1"} 
                      onFileUpload={(e) => this.props.handleFileUpload(e, 'logo_media_id')}
                      onFileRemove={() => this.props.handleFileRemove('logo_media_id')}
                      currentImage={this.props.currentLogoImage}
                    />
                    <FormGroup className="d-flex">
                      <MediaSelect
                        files={this.props.files}
                        item={this.props.organizationDetail}
                        mediaId={this.props.organizationDetail.logo_media_id}
                        itemId={this.props.organizationDetail.id}
                        onChange={(id, item, index) => {
                          this.props.onChange(id, 'logo_media_id');
                          this.props.getImage();
                        }}
                        onDelete={(itemId, index) => this.props.onChange(null, 'logo_media_id')}
                      />
                    </FormGroup>
                  </div>

                  <div className="col-lg-8"> 
                    <Label for="image">Organization page image</Label>
                    <FileUpload
                      onFileUpload={(e) => this.props.handleFileUpload(e, 'image_media_page_id')}
                      onFileRemove={() => this.props.handleFileRemove('image_media_page_id')}
                      currentImage={this.props.currentImagePageMedia}
                      ratio={"3/2"}
                    />
                    <div className="d-flex justify-content-between">
                      <FormGroup className="d-flex">
                        <MediaSelect
                          files={this.props.files}
                          item={this.props.organizationDetail}
                          mediaId={this.props.organizationDetail.image_media_page_id}
                          itemId={this.props.organizationDetail.id}
                          onChange={(id, item, index) => {
                            this.props.onChange(id, 'image_media_page_id');
                            this.props.getImage();
                          }}
                          onDelete={(itemId, index) => this.props.onChange(null, 'image_media_page_id')}
                        />
                      </FormGroup>
                      <p className="mini-font">Recommended size 1920x1280px</p>
                    </div>
                  </div>
                </Row>
            </Col>
            <Col sm="6">
              <FormGroup>
                  <MultiLanguageInput label={'Description'} keys={descriptionLanguageKeys} data={toMultiLanguageData(this.props.organizationDetail, descriptionLanguageKeys)} onChange={(data, key) => this.props.onChange(data, key)} />
              </FormGroup>

              <FormGroup>
                  <MultiLanguageClassicEditor label={'About us'} keys={aboutUsLanguageKeys} data={toMultiLanguageData(this.props.organizationDetail, aboutUsLanguageKeys)} onChange={(data, key) => this.props.onChange(data, key)} />
              </FormGroup>
      
            </Col>
          </Row>
        </Form>
      </CardBody>
    ) : null;
  }
}

export default EditOrganizationDetailsForm;
