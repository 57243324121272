export const EDIT = "edit"
export const DELETE = "delete"
export const NEW = "new"
export const UNCHANGED = "unchanged"

export const PUBLISH_STATUS_DRAFT = "draft"
export const PUBLISH_STATUS_ARCHIVED = "archived"
export const PUBLISH_STATUS_PUBLISHED = "published"

export function initAll(items, action) {
    if (!items || items?.length <= 0) {
        return
    }

    for (var item of items) {
        item._action = action
    }
}

export function onDeleteObject(object) {
    const oldAction = object?._action ?? UNCHANGED
    object._action = DELETE
    object._old_action = oldAction
}

export function onAddObject(object) {
    object._action = NEW
}

export function onEditObject(object) {
    if (object?._action === NEW || object?._action === DELETE) {
        return
    }
    object._action = EDIT
}

export function setItem(items, item) {
    const itemIndex = findIndexByIdOrKey(items, item)
    if (itemIndex === -1) {
        items.push(item)
    } else {
        items[itemIndex] = item
    }
}

export function removeItem(items, item, undelete = true) {
    if (item._action === NEW) {
        const itemIndex = findIndexByIdOrKey(items, item)
        if (itemIndex === -1) {
            return // error
        }
        items.splice(itemIndex, 1);
    }

    else if (undelete && item?._action === DELETE) {
        item._action = '' + item._old_action
    } else {
        onDeleteObject(item)
    }
}

export function isNew(item) {
    return itemActionEquals(item, NEW)
}

export function isDelete(item) {
    return itemActionEquals(item, DELETE)
}

export function isDuplicateAndNew(items, key, item) {
    return items.find(some => some?.[key] === item?.[key] && isNew(some))
}

function itemActionEquals(item, action) {
    return item?._action === action
}

function findIndexByIdOrKey(items, item) {
    return items.findIndex((current) => (item.id && current.id === item.id) || (item.key && current.key === item.key))
}