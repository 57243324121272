import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { createPackage, createPackageMembershipSubscription, deletePackageMembershipSubscription, getCurrentUser, getMembershipSubscriptions, getPackageById, getPackageMembershipSubscriptionsByPackageId, getSegments, patchPackage, patchPackageMembershipSubscription } from '../../../vibe/helpers/apiHelper';
import { getCampaigns, addCampaignToTicketPackage, deleteCampaignFromTicketPackage } from '../../../api/campaign/campaign'
import { getMembershipSubscriptionSelectOptions, getPackageSegmentOptions, prettyPrintDateNoTime } from '../../../vibe/helpers/util';
import DateTimePicker from '../../elements/form/DateTimePicker';
import Price from '../../elements/form/Price';
import Select from 'react-select'
import { v4 as uuidv4 } from 'uuid';
import EditableForm from '../common/EditableForm';
import { formatDate } from '../../../vibe/helpers/dateHelper';
import MultiLanguageInput from '../common/MultiLanguageInput';
import { toMultiLanguageData } from '../../../vibe/helpers/language';
import MultiLanguageClassicEditor from '../../elements/form/MultiLanguageClassicEditor';
import MultiLanguageSmallTextArea from '../../elements/form/MultiLanguageSmallTextArea';

const nameLanguageKeys = {
    primary: 'name', 
    secondary: 'name_en'
}

const descriptionLanguageKeys = {
    primary: 'description', 
    secondary: 'description_en'
}

const shortDescriptionLanguageKeys = {
    primary: 'short_description', 
    secondary: 'short_description_en'
}

class EditPackage extends EditableForm {

    constructor(props) {
        super(props);
        this.state = {
            packageMembershipSubscriptions: [],
            newMembershipSubscriptions: [],
            editedMembershipSubscriptions: [],
            deletedMembershipSubscriptions: [],
            membershipSubscriptionOptions: [],
            segmentOptions: []
        }
    }

    getItem() {
        let id = this.props.match.params.id
        this.setState({ newMembershipSubscriptions: [], editedMembershipSubscriptions: [], deletedMembershipSubscriptions: [] })

        getSegments(0, '', (result) => {
            if (result.segments) {

                // union users should not see "Everyone" (null) segment
                getCurrentUser((user) => {
                    const segmentOptions = getPackageSegmentOptions(user, result.segments)
                    this.setState({ segmentOptions: segmentOptions }, () => {
                        this.getPackage(id)
                    })
                }, (error) => {
                    this.onFormError(error)
                })
            }
        }, (error) => {
            this.onFormError(error)
        })

        getMembershipSubscriptions((membershipSubscriptions) => {
            var membershipSubscriptionOptions = getMembershipSubscriptionSelectOptions(membershipSubscriptions)
            this.setState({ membershipSubscriptionOptions: membershipSubscriptionOptions })
        }, (error) => {
            this.onFormError(error)
        })


        // getAllCampaigns 
        getCampaigns(
            (campaigns) => {
                const formattedCampaigns = campaigns.map(campaign => ({
                    value: campaign.id,
                    label: campaign.title
                    }));
                this.setState({ campaigns: formattedCampaigns });
            },
            (error) => {
                console.error('Error fetching campaigns:', error);
            }
        );
    }

    getPackage(id) {
        if (id) {
            getPackageMembershipSubscriptionsByPackageId(id, (packageMembershipSubscriptions) => {
                this.setState({ packageMembershipSubscriptions: packageMembershipSubscriptions })
            }, (error) => {
                this.onFormError(error)
            })

            getPackageById(id, (_package) => {
                this.setState({ packageId: parseInt(id), item: _package, loading: false, error: null, new: false })

                if(_package && _package.campaigns){
                    this.setState({
                        selectedPackageCampaign: _package.campaigns[0],
                        prevSelectedPackageCampaign: _package.campaigns[0]?.id
                    })
                }
            }, (error) => {
                this.setState({ packageId: parseInt(id), error: error, loading: false })
            })
             
        } else {
            this.setState({ item: { active: true, organization_id: 0, currency: 'SEK', price: 0, segment_id: this?.state?.segmentOptions?.[0]?.value }, new: true, loading: false, error: null })
        }
    }

    onChangePackageCampaign(event){
        if (event && event.value > 0) {
            var campaign = this.state.campaigns.find((campaign) => campaign.value === event.value)
            this.setState({
                selectedPackageCampaign: campaign
            })
        } else {
            this.setState({
                selectedPackageCampaign: null
            })
        }
    }
    getSelectedCampaignOption(){
        if (this.state.selectedPackageCampaign) {
            return this.state.campaigns.find((campaign) => campaign.value === this.state.selectedPackageCampaign.id)
        }
        return null
    }

    async savePackageCampaign(_package) {
        let campaign_id;
        if (this.state.selectedPackageCampaign) {
            campaign_id = this.state.selectedPackageCampaign.value || this.state.selectedPackageCampaign.id;
        } else {
            campaign_id = null;
        }
        
        if (this.state.prevSelectedPackageCampaign && this.state.prevSelectedPackageCampaign !== campaign_id) {
            deleteCampaignFromTicketPackage(
                null,
                _package.id, 
                this.state.prevSelectedPackageCampaign, 
                (response) => {
                }, 
                (error) => {
                    console.error("Error delete campaign to the package", error);
                }
            );
        }
        if (campaign_id && this.state.prevSelectedPackageCampaign !== campaign_id) {
            addCampaignToTicketPackage(
                null,
                _package.id, 
                campaign_id, 
                (response) => {
                }, 
                (error) => {
                    console.error("Error adding campaign to the package", error)
                }
            );
        }
    }

    renderForm(_package) {
        return <Form>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <MultiLanguageInput label={'Name'} keys={nameLanguageKeys} data={toMultiLanguageData(_package, nameLanguageKeys)} onChange={(data, key) => this.onChange(data, key)} />
                    </FormGroup>

                    <FormGroup>
                        <MultiLanguageClassicEditor label={'Description'} keys={descriptionLanguageKeys} data={toMultiLanguageData(_package, descriptionLanguageKeys)} onChange={(data, key) => this.onChange(data, key)} />
                    </FormGroup>

                    <FormGroup>
                        <MultiLanguageSmallTextArea label={'Short description'} keys={shortDescriptionLanguageKeys} data={toMultiLanguageData(_package, shortDescriptionLanguageKeys)} onChange={(data, key) => this.onChange(data, key)} />
                    </FormGroup>

                    <FormGroup check>
                        <Label for="active">
                            <Input type="checkbox" name="active" id="active" defaultChecked={_package ? _package.active : false} onChange={(e) => this.onChange(e.target.checked, 'active')} />{' '}
                            Active
                        </Label>
                    </FormGroup>
                </Col>

                <Col sm="6">
                    <FormGroup>
                        <Label for="start_date">Start date</Label>
                        <DateTimePicker name={"start_date"} value={_package.start_date} onChange={this.onChange.bind(this)} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="end_date">End date</Label>
                        <DateTimePicker name={"end_date"} value={_package.end_date} onChange={this.onChange.bind(this)} />
                    </FormGroup>

                    <FormGroup>
                        <Price price={_package.price} currency={_package.currency} title={'Price (including VAT)'} onChange={this.onChange.bind(this)} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="segment">Segment - Who can purchase this package?</Label>
                        <div className="row">
                            <div className='col col-6'>
                                <Select options={this.state.segmentOptions} onChange={(e) => this.onChange(e.value, 'segment_id')} placeholder={"Select segment..."} value={this.state.segmentOptions.filter((option) => option.value === _package.segment_id)} />
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label for="form">Campaign - Connect a Campaign to this package?</Label>
                        <div className="row">
                            <div className="col col-6">
                                <Select isClearable options={this.state.campaigns} onChange={(e) => this.onChangePackageCampaign(e)} value={this.getSelectedCampaignOption()} placeholder="Select campaign..." /> 
                            </div>
                            <div>
                            </div>
                        </div>
                    </FormGroup>


                </Col>
            </Row>

            {this.renderMembershipSubscriptions(_package)}
        </Form>
    }

    renderMembershipSubscriptionDates(membershipSubscription) {
        if (membershipSubscription && (membershipSubscription.purchase_availability_start_date || membershipSubscription.purchase_availability_end_date)) {
            return (
                <div className="col">
                    {membershipSubscription.purchase_availability_start_date && (
                        <div>
                            <Label>Purchase Start Date:
                            {' ' + prettyPrintDateNoTime(membershipSubscription.purchase_availability_start_date)}</Label>
                        </div>
                    )}
                    {membershipSubscription.purchase_availability_end_date && (
                        <div>
                            <Label>Purchase End Date:
                            {' ' +  prettyPrintDateNoTime(membershipSubscription.purchase_availability_end_date)}</Label>
                        </div>
                    )}
                </div>
            );
        }
        return null;
    }

    renderMembershipSubscriptions(_package) {

        var packageMembershipSubscriptions = null
        if (this.state.packageMembershipSubscriptions && this.state.packageMembershipSubscriptions.length > 0) {
            packageMembershipSubscriptions = this.state.packageMembershipSubscriptions.map((packageMembershipSubscription, index) => {

                if (this.state.deletedMembershipSubscriptions.includes(packageMembershipSubscription.id)) {
                    return null
                }

                return <React.Fragment key={'packageMembershipSubscriptions_' + packageMembershipSubscription.id + '_' + index}>
                    <div className="row my-2 w-50">
                        <div className='col'>
                            <Select options={this.state.membershipSubscriptionOptions} onChange={(e) => this.didEditMembershipSubscription(index, packageMembershipSubscription.id, e.value)} placeholder={"Select subscription..."} value={this.state.membershipSubscriptionOptions.filter((option) => option.value === packageMembershipSubscription.membership_subscription_id)} />
                        </div>

                        <div className='col align-self-center'>
                            <Button className='mr-2' color="primary" href={`/membership-subscriptions/edit/${packageMembershipSubscription.membership_subscription_id}`}>Edit</Button>
                            <Button className='' color="danger" onClick={() => this.deleteMembershipSubscription(packageMembershipSubscription.id)}>Delete</Button>
                        </div>
                    </div>

                    {this.state.membershipSubscriptionOptions && (
                        <div className="row my-2 w-50">
                            {this.renderMembershipSubscriptionDates(this.state.membershipSubscriptionOptions.find(option => option.value === packageMembershipSubscription.membership_subscription_id))}
                        </div>
                    )}
                </React.Fragment>
            })
        }

        let newMembershipSubscriptions = this.state.newMembershipSubscriptions.map((newMembershipSubscription, index) => {
            return <React.Fragment key={'newMembershipSubscriptions_' + newMembershipSubscription.key}>
                <div className="row my-2 w-50">
                    <div className='col col-6'>
                        <Select options={this.state.membershipSubscriptionOptions} onChange={(e) => this.didEditNewMembershipSubscription(e.value, index)} placeholder={"Select subscription..."} />
                    </div>
                    <div className='col align-self-center'>
                        <Button className='' color="danger" onClick={() => this.cancelNewMembershipSubscription(index)}>Cancel</Button>
                    </div>
                </div>
            </React.Fragment>
        })

        return <span>
            {packageMembershipSubscriptions}
            {newMembershipSubscriptions}
            <Button className='mb-4 mt-2' color="primary" onClick={() => this.addNewMembershipSubscription()}><i className="fa fa-plus-circle mr-1" />Add subscription</Button>
        </span>

    }

    didEditMembershipSubscription(index, packageMembershipSubscriptionId, membershipSubscriptionId) {

        var packageMembershipSubscriptions = this.state.packageMembershipSubscriptions
        packageMembershipSubscriptions[index].membership_subscription_id = membershipSubscriptionId

        var editedMembershipSubscriptions = this.state.editedMembershipSubscriptions

        var newMembershipSubscription = editedMembershipSubscriptions.find(editedMembershipSubscription => editedMembershipSubscription.id === packageMembershipSubscriptionId)
        if (newMembershipSubscription) {
            newMembershipSubscription.membership_subscription_id = membershipSubscriptionId
        } else {
            var editedMembershipSubscription = {
                id: packageMembershipSubscriptionId,
                membership_subscription_id: membershipSubscriptionId
            }
            editedMembershipSubscriptions.push(editedMembershipSubscription)
        }

        this.setState({ packageMembershipSubscriptions: packageMembershipSubscriptions, editedMembershipSubscriptions: editedMembershipSubscriptions })
    }

    deleteMembershipSubscription(packageMembershipSubscriptionId) {
        var deletedMembershipSubscriptions = this.state.deletedMembershipSubscriptions
        deletedMembershipSubscriptions.push(packageMembershipSubscriptionId)
        this.setState({ deletedMembershipSubscriptions: deletedMembershipSubscriptions })
    }

    addNewMembershipSubscription() {
        var newMembershipSubscriptions = this.state.newMembershipSubscriptions
        newMembershipSubscriptions.push({ key: uuidv4() })
        this.setState({ newMembershipSubscriptions: newMembershipSubscriptions })
    }

    cancelNewMembershipSubscription(index) {
        var newMembershipSubscriptions = this.state.newMembershipSubscriptions
        newMembershipSubscriptions.splice(index, 1);
        this.setState({ newMembershipSubscriptions: newMembershipSubscriptions })
    }

    didEditNewMembershipSubscription(value, index) {
        var newMembershipSubscriptions = this.state.newMembershipSubscriptions
        newMembershipSubscriptions[index].membership_subscription_id = value
        this.setState({ newMembershipSubscriptions })
    }

    async updatePackageMembershipSubscriptions(packageId) {
        await this.createPackageMembershipSubscriptions(packageId, this.state.newMembershipSubscriptions)
        await this.patchPackageMembershipSubscriptions(this.state.editedMembershipSubscriptions)
        await this.deletePackageMembershipSubscriptions(this.state.deletedMembershipSubscriptions)
    }

    async createPackageMembershipSubscriptions(packageId, packageMembershipSubscriptions) {
        for (let i = 0; i < packageMembershipSubscriptions.length; i++) {
            await new Promise(resolve => {
                let data = { package_id: packageId, membership_subscription_id: packageMembershipSubscriptions[i].membership_subscription_id }
                if (!data.membership_subscription_id) {
                    resolve(false)
                } else {
                    createPackageMembershipSubscription(data, () => {
                        resolve(true)
                    }, () => {
                        resolve(false)
                    })
                }
            })
        }
    }

    async patchPackageMembershipSubscriptions(packageMembershipSubscriptions) {
        for (let i = 0; i < packageMembershipSubscriptions.length; i++) {
            await new Promise(resolve => {
                patchPackageMembershipSubscription(packageMembershipSubscriptions[i].id, { membership_subscription_id: packageMembershipSubscriptions[i].membership_subscription_id }, () => {
                    resolve(true)
                }, () => {
                    resolve(false)
                })
            })
        }
    }

    async deletePackageMembershipSubscriptions(packageMembershipSubscriptionIds) {
        for (let i = 0; i < packageMembershipSubscriptionIds.length; i++) {
            await new Promise(resolve => {
                deletePackageMembershipSubscription(packageMembershipSubscriptionIds[i], () => {
                    resolve(true)
                }, () => {
                    resolve(false)
                })
            })
        }
    }

    onSave() {
        this.createOrUpdatePackage(this.state.new, () => {
            this.onFormSuccess()
        }, (error) => {
            this.onFormError(error)
        })
    }

    createOrUpdatePackage(create, onSuccess, onError) {

        if (create) {
            createPackage(this.state.item, async (result) => {
                onSuccess()
                await this.updatePackageMembershipSubscriptions(result.id)
                
                if(result.id){
                    let _package = { id : result.id }
                    this.savePackageCampaign(_package)
                }
                setTimeout(() => {
                    this.props.history.replace('/membership-packages/edit/' + result.id)
                }, 2000)
            }, (error) => {
                onError(error)
            })
        } else {
            var item = this.state.item
            var now = formatDate()
            item.updated_at = now
            delete item.created_at;
            delete item.campaigns;
            patchPackage(item, async () => {
                onSuccess()
                await this.updatePackageMembershipSubscriptions(this.state.packageId)
                this.getItem()
            }, (error) => {
                onError(error)
            })

            if(this.state.item){
                this.savePackageCampaign(this.state.item)
            }
        }
    }

    validate(_package) {
        if (!_package) {
            return { valid: false, message: 'Internal error, try again later' }
        }

        if (_package.price && !_package.currency) {
            return { valid: false, message: 'Can not set price without a currency' }
        }
        return { valid: true }
    }

}
export default EditPackage