import React from 'react';
import { FormGroup, Label } from 'reactstrap';
import Select from 'react-select'


class FormSelect extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const disabled = this.props?.disabled ?? false
        return <FormGroup>
                    <Label for={this.props.label}>{this.props.label}</Label>
                    <div>
                        <Select className='list-item-text-small' options={this.props.options} isDisabled={disabled} onChange={this.props.onChange} placeholder={this.props.placeholder} value={this.props.options.filter((option) => option.value === this.props.selected)}  />
                    </div>
               </FormGroup>
    }

}

export default FormSelect