import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Row, Col, Label } from 'reactstrap';
import DateTimePicker from '../../elements/form/DateTimePicker';
import Select from 'react-select'

const MAX_DYNAMIC_CODES = 1000;
const codeTypeOptions = [
  {value: 'dynamic', label: "Dynamic codes"},
  {value: "static", label: "Static code"}
]
const valueTypeOptions = [
  {value: 'percent', label: "Percentage discount (%)"},
  {value: "fixed", label: "Discount in SEK"}
]
const initialFormData = {
  value_type: 'percent',
  value: '',
  code_type: 'static',
  static_code: '',
  dynamic_code_count: 10,
  valid_from: null,
  valid_to: null,
  limit: 0,
  title: '',
  description: '',
};


const DiscountFormModal = ({ isOpen, toggle, discount, onSave }) => {

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isOpen) {
      if (discount) {
        setFormData({
          ...discount,
          static_code: discount.discountCodes?.[0]?.code || '',
          dynamic_code_count: ( discount.code_type === 'dynamic' && discount.discountCodes && discount.discountCodes.length ) || 10
        });
      } else {
        setFormData(initialFormData);
        setErrors({});
      }
    }
  }, [isOpen, discount]);

  const handleChange = (field, value) => {
    setFormData(prev => ({ ...prev, [field]: value }));
    setErrors(prev => ({ ...prev, [field]: null }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.value || parseFloat(formData.value) <= 0) {
      newErrors.value = 'Value must be greater than 0';
    }
    if (formData.limit < 0) {
      newErrors.limit = 'Limit must be 0 or greater';
    }
    if (formData.code_type === 'static' && !formData.static_code) {
      newErrors.static_code = 'Static code is required';
    }
    if (formData.value_type === 'percent' && formData.value > 100) {
      newErrors.value = 'Discount values must be less than or equal to 100';
    }
    if (!!discount && formData.code_type === 'dynamic' && formData.dynamic_code_count < 1) {
      newErrors.dynamic_code_count = 'Number of dynamic codes must be at least 1';
    }
    if (discount && discount.discountCodes && formData.code_type === 'dynamic' && formData.dynamic_code_count < discount.discountCodes.length) {
      newErrors.dynamic_code_count = 'Number of dynamic codes must be at least ' + discount.discountCodes.length;
    }
    if (formData.code_type === 'dynamic' && formData.dynamic_code_count > MAX_DYNAMIC_CODES) {
      newErrors.dynamic_code_count = `Number of dynamic codes must be less than or equal to ${MAX_DYNAMIC_CODES}`;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      onSave({
        ...formData,
        value: parseFloat(formData.value),
        limit: parseInt(formData.limit),
      });
    }
  };

  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        {discount ? 'Edit Discount' : 'New Discount'}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <FormGroup> 
                <Label for="title">Title</Label>
                <Input
                  placeholder="Discount Title"
                  type="text"
                  value={formData.title}
                  onChange={(e) => handleChange('title', e.target.value)}
                />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Label for="title">Description</Label>
              <Input
                rows="3"
                type="textarea"
                value={formData.description}
                onChange={(e) => handleChange('description', e.target.value)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Value Type</label>

              <Select options={valueTypeOptions} onChange={(e) => handleChange('value_type', e.value)} 
              value={valueTypeOptions.filter((option) => option.value === formData.value_type)} />

            
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Value</label>
              <div className="d-flex align-items-center bg-light rounded">
                <Input
                  type="number"
                  value={formData.value}
                  onChange={(e) => handleChange('value', e.target.value )}
                  min="0"
                  required
                />
                <span className="px-4">{formData.value_type === 'percent' ? '%' : 'SEK'}</span>
              </div>
              {errors.value && <div className="text-danger mini-font">{errors.value}</div>}
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Valid From (Optional) </label>
              <DateTimePicker
                name="start_date"
                value={formData.valid_from}
                onChange={(date) => handleChange('valid_from', date)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Valid To (Optional)</label>
              <DateTimePicker
                name="end_date"
                value={formData.valid_to}
                onChange={(date) => handleChange('valid_to', date)}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Code Type</label>
              <Select isDisabled={discount && !discount.isCloneMode && !!discount} options={codeTypeOptions} onChange={(e) => handleChange('code_type', e.value)} 
              value={codeTypeOptions.filter((option) => option.value === formData.code_type)} />
            </FormGroup>
          </Col>
          {formData.code_type === 'static' && (
            <>
            <Col md="6">
              <FormGroup>
                <label>Static Code</label>
                <div className="d-flex align-items-center">
                  <Input
                    type="text"
                    value={formData.static_code}
                    onChange={(e) => handleChange('static_code', e.target.value)}
                  />
                </div>
                {errors.static_code && <div className="text-danger mini-font">{errors.static_code}</div>}
              </FormGroup>
            </Col>
            <Col md="6">
              <FormGroup>
                <label>Limit Use</label>
                <Input
                  type="number"
                  value={formData.limit}
                  onChange={(e) => handleChange('limit', e.target.value)}
                  min="0"
                />
                <div className="text-info mini-font">
                  Enter 0 for unlimited use (applies to Fixed codes only).
                </div>
                {errors.limit && <div className="text-danger mini-font">{errors.limit}</div>}
              </FormGroup>
            </Col>
            </>
          )}
          {formData.code_type === 'dynamic' && (
            <Col md="6">
              <FormGroup>
                <label>Number of Codes</label>
                <div className="d-flex align-items-center">
                  <Input
                    type="number"
                    value={formData.dynamic_code_count}
                    onChange={(e) => handleChange('dynamic_code_count', e.target.value)}
                    min="1"
                  />
                </div>
                <div className="text-info mini-font">
                  Codes will be generated when you save the campaign.
                </div>
                {errors.dynamic_code_count && <div className="text-danger mini-font">{errors.dynamic_code_count}</div>}
              </FormGroup>
            </Col>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary px-5" onClick={toggle}>Cancel</Button>
        <Button color="primary px-5" onClick={handleSubmit}>Save</Button>
      </ModalFooter>
    </Modal>
  );
};

export default DiscountFormModal;
