import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { createMembershipSubscription, createMembershipSubscriptionVerificationType, deleteMembershipSubscriptionVerificationType, getAllMemberships, getMembershipSubscriptionById, getSubscriptionVerificationTypes, getVerificationTypes, patchMembershipSubscription } from '../../../vibe/helpers/apiHelper';
import { getMembershipSelectOptions, getVerificationTypeOptions, parseErrorMessage } from "../../../vibe/helpers/util";
import Select from 'react-select'
import EditableForm from '../common/EditableForm';
import { durationTypeOptions, DURATION_TYPE_DEFAULT, isDays } from '../../../vibe/helpers/durationType';
import { v4 as uuidv4 } from 'uuid';
import DateTimePicker from '../../elements/form/DateTimePicker';


class EditMembershipSubscription extends EditableForm {

    constructor(props) {
        super(props);
        this.state = {
            membershipOptions: [],
            memberships: [],
            verificationTypeOptions: [],
            newVerificationTypes: [],
            deletedVerificationTypes: [],
            verification_types: [],
            allVerificationTypes: []
        }
    }

    getItem() {

        this.setState({ deletedVerificationTypes: [], newVerificationTypes: [] })

        getAllMemberships('', (memberships) => {
            var membershipOptions = getMembershipSelectOptions(memberships)
            this.setState({ membershipOptions: membershipOptions, memberships: memberships })
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })

        let id = this.props.match.params.id
        if (id) {

            getMembershipSubscriptionById(id, (membershipSubscription) => {
                this.setState({ item: membershipSubscription, loading: false, error: null, new: false })
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage, loading: false })
            })

            getVerificationTypes((verificationTypes) => {
                getSubscriptionVerificationTypes(id, (result) => {
                    let verificationTypeOptions = getVerificationTypeOptions(verificationTypes)
                    this.setState({ verification_types: result, verificationTypeOptions: verificationTypeOptions, allVerificationTypes: verificationTypes })
                }, (error) => {
                    let errorMessage = parseErrorMessage(error)
                    this.setState({ error: errorMessage, loading: false })
                })
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage, loading: false })
            })

        } else {
            getVerificationTypes((verificationTypes) => {
                let verificationTypeOptions = getVerificationTypeOptions(verificationTypes)
                this.setState({ verificationTypeOptions: verificationTypeOptions, allVerificationTypes: verificationTypes })
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage, loading: false })
            })
            this.setState({ item: {}, new: true, loading: false, error: null })
        }
    }

    getMembership(membershipSubscription) {
        return this.state.memberships.find(membership => membership.id === membershipSubscription.membership_id)
    }

    renderDuration(membershipSubscription) {
        if (isDays(membershipSubscription)) {
            return <>
                <Label for="duration_days">Duration (days)</Label>
                <Input className='mr-1' min={0} style={{ width: '15%' }} type="number" name="duration_days" id="duration_days" defaultValue={parseInt(membershipSubscription.duration_days)} onChange={(e) => this.onChange(parseInt(e.target.value), 'duration_days')} />
            </>
        } else {


            let membership = this.getMembership(membershipSubscription)
            if (membership) {
                return <>
                    <Label for="duration_days">Valid to</Label>
                    <div>Valid until {membership.valid_to.substr(0, 10)}</div>
                </>
            } else {
                return <div> </div>
            }
        }
    }

    isPurchaseAvailabilityDateValid(membershipSubscription) {
        if (membershipSubscription.duration_type === 3 && membershipSubscription.purchase_availability_start_date && membershipSubscription.purchase_availability_end_date) {
            return (new Date(membershipSubscription.purchase_availability_end_date) >= new Date(membershipSubscription.purchase_availability_start_date));
        }
        return true;
    }


    renderForm(membershipSubscription) {
        return <Form>
            <Row>
                <Col sm="4">
                    <FormGroup>
                        <Label for="membership">Membership</Label>
                        <Select options={this.state.membershipOptions} onChange={(e) => this.onChange(e.value, 'membership_id')} placeholder={"Select membership..."} value={this.state.membershipOptions.filter((option) => option.value === membershipSubscription.membership_id)} />
                    </FormGroup>

                    <br />
                    {this.renderVerificationTypes(membershipSubscription)}

                </Col>

                <Col sm="4">
                    <FormGroup>
                        <Label for="duration_type">Duration type</Label>
                        <Select options={durationTypeOptions} onChange={(e) => this.onChange(e.value, 'duration_type')} placeholder={"Select duration type..."} value={durationTypeOptions.filter((option) => option.value === membershipSubscription.duration_type)} />

                        <br />

                        {membershipSubscription.duration_type === 3 &&
                            <div className="d-flex">
                                <div className="mr-3">
                                    <Label for="start_date">Purchase Start Date</Label>
                                    <DateTimePicker hideTime={true} name={"purchase_availability_start_date"} value={membershipSubscription.purchase_availability_start_date} onChange={this.onChange.bind(this)} />
                                </div>
                                <div className="ml-3">
                                    <Label for="end_date">Purchase End Date</Label>
                                    <DateTimePicker hideTime={true} name={"purchase_availability_end_date"} value={membershipSubscription.purchase_availability_end_date} onChange={this.onChange.bind(this)} />
                                </div>
                            </div>
                        }

                    </FormGroup>
                </Col>

                <Col>
                    <FormGroup>
                        {this.renderDuration(membershipSubscription)}
                    </FormGroup>
                </Col>

            </Row>

        </Form>
    }

    renderVerificationTypes(membershipSubscription) {
        var verificationTypes = null
        if (this.state.verification_types && this.state.verification_types.length > 0) {
            verificationTypes = this.state.verification_types.map((_verificationType) => {

                let verificationType = this.state.allVerificationTypes.find((v) => {
                    return v.id === _verificationType.verification_type_id
                })

                if (this.state.deletedVerificationTypes.includes(_verificationType.id)) {
                    return null
                }

                return <React.Fragment key={_verificationType.id}>

                    <div className="row my-2">
                        <div className='col-8'>
                            <div>{verificationType.name}</div>
                        </div>
                        <div className='col'>
                            <Button className='' color="danger" onClick={() => this.onDeleteVerificationType(_verificationType)}><i className="mr-1" />Delete</Button>
                        </div>
                    </div>
                </React.Fragment>
            })
        }

        let newVerificationTypes = this.state.newVerificationTypes.map((newVerificationType, index) => {
            return <React.Fragment key={'newVerificationTypes_' + newVerificationType.key}>
                <div className="row my-2">
                    <div className='col col-8'>
                        <Select options={this.state.verificationTypeOptions} onChange={(e) => this.didEditNewVerificationType(e.value, index)} placeholder={"Select verification..."} />
                    </div>
                    <div className='col'>
                        <Button className='' color="danger" onClick={() => this.cancelNewVerificationType(index)}><i className="mr-1" />Cancel</Button>
                    </div>
                </div>
            </React.Fragment>
        })

        return <>
            {verificationTypes}
            {newVerificationTypes}
            <Button className='mb-4 mt-2' color="primary" onClick={() => this.onAddVerificationType()}><i className="fa fa-plus-circle mr-1" />Add verification type</Button>
        </>
    }

    cancelNewVerificationType(index) {
        var newVerificationTypes = this.state.newVerificationTypes
        newVerificationTypes.splice(index, 1);
        this.setState({ newVerificationTypes: newVerificationTypes })
    }

    didEditNewVerificationType(value, index) {
        var newVerificationTypes = this.state.newVerificationTypes
        newVerificationTypes[index].verification_type_id = value
        this.setState({ newVerificationTypes })
    }

    onDeleteVerificationType(verificationType) {
        let deletedVerificationTypes = this.state.deletedVerificationTypes
        deletedVerificationTypes.push(verificationType.id)
        this.setState({ deletedVerificationTypes: deletedVerificationTypes })
    }

    onAddVerificationType() {
        let newVerificationTypes = this.state.newVerificationTypes
        newVerificationTypes.push({ key: uuidv4() })
        this.setState({ newVerificationTypes: newVerificationTypes })
    }

    validate(membershipSubscription) {
        if (!this.isPurchaseAvailabilityDateValid(membershipSubscription)) {
            return { valid: false, message: 'Purchase availability end date must be greater than or equal to purchase availability start date.' };
        }

        if (!membershipSubscription) {
            return { valid: false, message: 'Internal error, try again later' }
        }

        if (!membershipSubscription.duration_type) {
            return { valid: false, message: 'Requires duration type' }
        }

        if (isDays(membershipSubscription) && !membershipSubscription.duration_days) {
            return { valid: false, message: 'Requires duration (days)' }
        }

        return { valid: true }
    }

    onSave() {
        let membershipSubscription = this.state.item

        if (membershipSubscription.duration_type === DURATION_TYPE_DEFAULT) {
            membershipSubscription.duration_days = null
        }

        if (membershipSubscription.duration_type !== 3) {
            membershipSubscription.purchase_availability_start_date = null
            membershipSubscription.purchase_availability_end_date = null
        }

        if (membershipSubscription.duration_type === 3 && membershipSubscription.purchase_availability_start_date === null) {
            // +90 days default to end_date 
            var dateMin = new Date();
            dateMin = dateMin.toISOString();
            membershipSubscription.purchase_availability_start_date = dateMin
        }

        if (membershipSubscription.duration_type === 3 && membershipSubscription.purchase_availability_end_date === null) {
            // +90 days default to end_date 
            var today = new Date();
            var dateMax = new Date();
            dateMax.setDate(today.getDate() + 90);
            dateMax = dateMax.toISOString();
            membershipSubscription.purchase_availability_end_date = dateMax
        }

        delete membershipSubscription.verification_types

        this.createOrUpdateMembershipSubscription(this.state.new, membershipSubscription, () => {
            this.onFormSuccess()

        }, (error) => {
            this.onFormError(error)
        })
    }

    async saveVerificationInfos(item, onSuccess, onError) {
        await this.createAllMembershipSubscriptionVerificationTypes(item)
        await this.deleteAllMembershipSubscriptionVerificationTypes()
        onSuccess()
    }

    async createAllMembershipSubscriptionVerificationTypes(item) {
        for (const newVerificationType of this.state.newVerificationTypes) {
            await new Promise(resolve => {
                createMembershipSubscriptionVerificationType({ verification_type_id: newVerificationType.verification_type_id, membership_subscription_id: item.id }, (result) => {
                    resolve(true)
                }, (error) => {
                    resolve(false)
                })
            })
        }
    }

    async deleteAllMembershipSubscriptionVerificationTypes() {
        for (const deletedVerificationType of this.state.deletedVerificationTypes) {
            await new Promise(resolve => {
                deleteMembershipSubscriptionVerificationType(deletedVerificationType, (result) => {
                    resolve(true)
                }, (error) => {
                    resolve(false)
                })
            })
        }
    }

    createOrUpdateMembershipSubscription(create, membershipSubscription, onSuccess, onError) {
        if (create) {
            createMembershipSubscription(membershipSubscription, async (result) => {

                this.saveVerificationInfos(result, () => {
                    onSuccess()
                    setTimeout(() => {
                        this.props.history.replace('/membership-subscriptions/edit/' + result.id)
                    }, 2000)
                }, (error) => {
                    onError(error)
                })


            }, (error) => {
                onError(error)
            })
        } else {
            patchMembershipSubscription(membershipSubscription, async () => {
                this.saveVerificationInfos(membershipSubscription, () => {
                    onSuccess()
                    this.getItem()
                }, (error) => {
                    onError(error)
                })
            }, (error) => {
                onError(error)
            })
        }
    }

}

export default EditMembershipSubscription