import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { printShort, toReadablePrice } from '../form/Currency';
import { getTicketQuantity, prettyPrintDate } from '../../../vibe/helpers/util';
import { toColorClass, toStatusString } from '../../../vibe/helpers/transactionStatus';
import { Link } from 'react-router-dom';

class MemberTicketTransaction extends Component {

    render() {
        var createdAt = prettyPrintDate(this?.props?.transaction?.created_at)
        var createdAtDate = createdAt?.substring(0,10)
        var createdAtTime = createdAt?.substring(11)
        return <Link className="member-card-link" to={'/transactions/packages/edit/' + this.props.transaction.id} key={this.props.transaction.id}>
            <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">

                <div className="mb-1 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Date</p>
                    <p className="list-item-text mb-0">{createdAtDate}</p>
                    <p className="list-item-text mb-0">{createdAtTime}</p>
                </div>

                <div className="mb-1 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Status</p>
                    <p className={toColorClass(this.props.transaction.status) + " list-item-text mb-0"}>{toStatusString(this.props.transaction.status)}</p>
                </div>

                <div className="mb-1 text-small w-75 w-sm-100">
                    <p className="list-item-heading mb-1">Event name</p>
                    {this.props.ticket.organization_event_occurrences && this.props.ticket.organization_event_occurrences.length > 0 && <p className="list-item-text mb-0">{this.props.ticket.organization_event_occurrences[0].organization_event.title}</p>}
                </div>

                <div className="mb-1 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Ticket name</p>
                    <p className="list-item-text mb-0">{this.props.ticket.name}</p>
                </div>

                <div className="mb-1 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Tickets sold</p>
                    <p className="list-item-text mb-0">{getTicketQuantity(this.props.transaction)}</p>
                </div>

                <div className="mb-1 text-small w-75 w-sm-100">
                    <p className="list-item-heading mb-1">Member</p>
                    <p className="list-item-text mb-0">{this.getMemberName()}</p>
                </div>

                <div className="mb-1 text-small w-50 w-sm-100 mr-5">
                    <p className="list-item-heading mb-1">Total amount</p>
                    <p className="list-item-text mb-0">{printShort(toReadablePrice(this.props.transaction.amount), this.props.transaction.currency)}</p>
                    <p className="list-item-text mb-0">{this.props.transaction.provider}</p>
                </div>
                
            </Card>
        </Link>

    }

    getMemberName() {
        if (!this.props?.member) {
            return ""
        }
        return this.props?.member?.first_name + ' ' + this.props?.member?.last_name
    }

}

export default MemberTicketTransaction;
