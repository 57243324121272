import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { printShort,toReadablePrice } from '../../../elements/form/Currency';
import { prettyPrintDate } from '../../../../vibe/helpers/util';
import MemberFormResult from './form/MemberFormResult';
import * as Icon from 'react-feather';



class EventAttendee extends Component {

    render(){
        const { memberTicket, toggleCard, cardIsOpen, checkLatestValidation,checkAmountOfScans } = this.props;
        const memberHasFormResult = memberTicket.form_member_results   
        
        const ticketTransactionMeta = JSON.parse(memberTicket?.transaction?.ticket_transactions?.[0]?.meta);
        const ticketCount = ticketTransactionMeta?.tickets?.[0]?.count || 0;
        console.log(ticketCount)
        return <>
            <Card className={`card-body ${!memberHasFormResult ? 'card-disabled' : ''}  ${cardIsOpen ? 'card-expanded' : ''}`} onClick={(e) => toggleCard(memberTicket.id)} tabIndex={0}>
                <div className="d-flex flex-column flex-md-row justify-content-between min-width-zero">
                    <div className="mb-0 text-small w-75 w-sm-100">
                        <p className="list-item-heading mb-1">Name</p>
                        <p className="list-item-text ">{memberTicket.member.first_name} {memberTicket.member.last_name}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">PIN</p>
                        <p className="list-item-text ">{memberTicket.member.personal_identification_number}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Ticket</p>
                        <p className="list-item-text ">{memberTicket.tickets.name} ({printShort(toReadablePrice(memberTicket?.transaction?.amount / ticketCount ), memberTicket.tickets.currency)})</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Discount Code</p>
                        <p className="list-item-text ">{memberTicket?.transaction?.discountCodeClaims?.[0]?.code}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Validated</p>
                        {memberTicket.member_ticket_validations ? (
                        <p className={`list-item-text ${checkAmountOfScans(memberTicket) > 1 ? 'text-warning' : 'text-success'}`}>
                            {prettyPrintDate(checkLatestValidation(memberTicket))}
                            <br />
                            {checkAmountOfScans(memberTicket) > 1 ? `Scanned ${checkAmountOfScans(memberTicket)} times` : null}
                        </p>
                        ) : (
                        <p className="list-item-text text-muted">Not scanned</p>
                        )}
                    </div>
                    <div className="mb-0 text-small w-15 w-sm-100 d-flex align-items-center">
                    {memberTicket.form_member_results ? 
                    (
                    <div className=''>{cardIsOpen ? (<Icon.ChevronUp size="20" />) :(<Icon.ChevronDown size="20" />)}</div>
                    ) : (<Icon.ChevronDown size="20" style={{ visibility: "hidden" }} />)
                    }
                </div>
                </div>
                {cardIsOpen && <MemberFormResult memberTicket={memberTicket} />}
            </Card>
        </>
    }
}

export default EventAttendee;

