import React from 'react';
import { Card } from 'reactstrap';
import { getNotificationStatusString } from '../../../vibe/helpers/util';
import Notification from './Notification';

class SimpleNotification extends Notification {

  render() {

    return (
        <Card onClick={this.props.onClick} className="cursor-pointer card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">

          <div className="mb-1 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Scheduled</p>
            <p className="list-item-text mb-0">{this.getScheduleDate(this.props.notification.schedule)}</p>
            </div>

          <div className="mb-1 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Segment</p>
            <p className="list-item-text mb-1">{this.props.notification.segment ? this.props.notification.segment.name : 'Everyone'}</p>
          </div>

          <div className="mb-1 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Status</p>
            <p className="list-item-text mb-1">{getNotificationStatusString(this.props.notification.status)}</p>
          </div>
          
        </Card>
    );
  }

}

export default SimpleNotification;
