import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';


class FormCard extends Component {

  render() {

    return (
      <Link className="member-card-link" from={'/forms'} to={'/form/edit/' + this.props.form.id}>
        <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">

          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Form name</p>
            <p className="list-item-text mb-0">{this.props.form.name}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Organization ID</p>
                <p className="list-item-text mb-0">{this.props.form.organization_id}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Created Date</p>
            <p className="list-item-text mb-0">{new Date(this.props.form.created_at).toISOString().split('T')[0]}</p>
          </div>
          <div className="mb-1 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Updated Date</p>
            <p className="list-item-text mb-0">{new Date(this.props.form.updated_at).toISOString().split('T')[0]}</p>
          </div>
        </Card>
      </Link>
    );
  }

}

export default FormCard;
