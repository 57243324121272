import React, { Component } from 'react';
import { CardBody, Form, FormGroup, Label, Input, Button, Col, Row, UncontrolledAlert, CustomInput } from 'reactstrap';
import { createVerifyStudentUser } from '../../../vibe/helpers/apiHelper';

class ManageUsersNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            sendMail: true,
            success: false,
            error: null,
        };
    }

    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    };

    handleCheckboxChange = () => {
        this.setState((prevState) => ({ sendMail: !prevState.sendMail }));
    };

    createUser = () => {
        const { email, firstName, lastName, sendMail } = this.state;
        let data = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            status: true,
            send_mail: sendMail
        }
        createVerifyStudentUser(data, (result) => {
            this.setState({ success: true, error: null });
            setTimeout(() => {
                window.location.href = '/manage-users';
            }, 3000);
        },
            (error) => {
                this.setState({ error: error.message });
            }
        );
    };
    render() {
        return (

            <CardBody>
                {this.state.error && (
                    <UncontrolledAlert color="danger">
                        {this.state.error}
                    </UncontrolledAlert>
                )}
                {this.state.success && (
                    <UncontrolledAlert color="success">
                        User successfully created!
                    </UncontrolledAlert>
                )}

                <Form id="newUserForm">
                    <Row>
                        <Col sm="6">
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input type="email" name="email" id="email" onChange={this.handleInputChange} required />
                            </FormGroup>
                            <FormGroup>
                                <Label for="firstName">First Name (optional)</Label>
                                <Input type="text" name="firstName" id="firstName" onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastName">Last Name (optional)</Label>
                                <Input type="text" name="lastName" id="lastName" onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup>
                                <CustomInput
                                    type="switch"
                                    id="sendMail"
                                    name="sendMail"
                                    checked={this.state.sendMail}
                                    onChange={this.handleCheckboxChange}
                                    label="Send the new user an email about their account." />
                            </FormGroup>
                            <small className="my-2 block">
                                <strong>Note:</strong> Upon creating the user,
                                {this.state.sendMail ?
                                    " an email will be sent to the user with instructions on setting their account password." :
                                    " no email will be sent to the user."}
                            </small>
                            <Button
                                className="btn-block"
                                color="primary"
                                size="btn btn-success"
                                onClick={this.createUser}>Create New User</Button>
                        </Col>
                    </Row>
                </Form>

            </CardBody >
        )
    }

}

export default ManageUsersNew;
