import React from 'react';
import { Form, FormGroup, Label, Input, Button, UncontrolledAlert } from 'reactstrap';
import { getStudentVerificationType5 } from '../../../vibe/helpers/apiHelper';
import * as Icon from 'react-feather';

import resLogo1 from '../../../assets/images/RES_1.png';
import resLogo2 from '../../../assets/images/RES_2.png';
import resLogo3 from '../../../assets/images/RES_3.png';

class verifyStudent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            personalNumber: '',
            verificationResult: null,
            error: null,
            loading: false,
        }
    }

    componentDidMount() {
    }

    handleInputChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleVerify = (e) => {
        e.preventDefault();

        const cleanedPersonalNumber = this.state.personalNumber.replace(/-/g, '');

        if (!cleanedPersonalNumber.trim()) {
            this.setState({ error: 'Please enter a valid personal number', loading: false });
            return;
        }

        this.setState({ personalNumber: cleanedPersonalNumber, loading: true, verificationResult: null, error: null });

        getStudentVerificationType5(cleanedPersonalNumber,
            (result) => {
                this.setState({ verificationResult: result, loading: false, error: null });
            },
            (err) => {
                this.setState({ error: 'Error fetching student information', loading: false });
            })
    };

    render() {
        let verificationIcon = <Icon.XCircle size={50} />;
        let verificationMessage = 'Not Verified Student';
        let studyPaceColor = '#721c24';
        let studyPaceBackground = '#f5c6cb';
        let resIcon = ''

        if (this.state.verificationResult && this.state.verificationResult?.currentStatus?.status === true && this.state.verificationResult.verificationInfo.study_pace) {
            const studyPace = parseInt(this.state.verificationResult.verificationInfo.study_pace);
            if (studyPace >= 1) {
                verificationIcon = <Icon.CheckCircle size={50} />;
                verificationMessage = 'Verified Student';

                if (studyPace >= 75) {
                    studyPaceColor = '#155724';
                    studyPaceBackground = '#c3e6cb';
                    resIcon = resLogo2
                }
                else if (studyPace >= 50) {
                    studyPaceColor = '#856404';
                    studyPaceBackground = '#ffeeba';
                    resIcon = resLogo1
                }
                else {
                    studyPaceColor = '#b06100';
                    studyPaceBackground = '#ffdcb9';
                    resIcon = resLogo3
                }
            }
        }


        return (
            <>
                <Form onSubmit={this.handleVerify}>
                    <h4>Student Verification</h4>
                    <FormGroup>
                        <Label for="personalNumber">Enter the students personal identity number below</Label>
                        <Input
                            type="text"
                            name="personalNumber"
                            id="personalNumber"
                            bsSize="lg"
                            placeholder="Personal Number"
                            value={this.state.personalNumber}
                            onChange={this.handleInputChange}
                            required
                        />
                        <small className="text-muted">
                            Use a short or long PIN format (e.g., aammddnnnn or aaaammddnnnn) for student verification.
                        </small>
                    </FormGroup>
                    <Button type="submit" className="mb-3 py-3" color="primary" block="true" size="lg" disabled={this.state.loading}>
                        Verify
                    </Button>
                </Form>


                {this.state.verificationResult && (
                    <div id="results">

                        <ul id="verification_list" className="list-group mb-3">
                            <li className="list-group-item bg-gray"><strong>Current Student Status</strong></li>
                            <li className="list-group-item" style={{ color: studyPaceColor, backgroundColor: studyPaceBackground }}>
                                <span>{verificationIcon}</span>
                                <span className="mx-2"><strong>{verificationMessage}</strong></span>
                            </li>
                        </ul>

                        {resIcon &&
                            <ul id="verification_list" className="list-group mb-3">
                                <li className="list-group-item">
                                    <img src={resIcon} alt="Travel icon" />
                                </li>
                            </ul>
                        }

                        {this.state.verificationResult.memberInfo?.firstName && this.state.verificationResult.memberInfo?.gender &&
                          this.state.verificationResult.memberInfo?.dob && this.state.verificationResult.memberInfo?.streetAdress && 
                            <ul id="personal_list" className="list-group mb-3">
                                <li className="list-group-item bg-gray"><strong>Personal Details</strong></li>
                                <li className="list-group-item">
                                    <h5 className="mb-1">Full Name</h5>
                                    <p id="full_name" className="mb-0 text-secondary">
                                        {this.state.verificationResult.memberInfo?.firstName} {this.state.verificationResult.memberInfo?.lastName}
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5 className="mb-1">Gender</h5>
                                    <p id="gender" className="mb-0 text-secondary">
                                        {this.state.verificationResult.memberInfo?.gender}
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5 className="mb-1">DOB</h5>
                                    <p id="dob" className="mb-0 text-secondary">
                                        {this.state.verificationResult.memberInfo?.dob}
                                    </p>
                                </li>
                                <li className="list-group-item">
                                    <h5 className="mb-1">Address</h5>
                                    <p id="address" className="mb-0 text-secondary">
                                        {this.state.verificationResult.memberInfo?.streetAdress}, {this.state.verificationResult.memberInfo?.city}, {this.state.verificationResult.memberInfo?.zipCode}
                                    </p>
                                </li>
                            </ul>
                        }

                        <ul id="study_list" className="list-group mb-4">
                            <li className="list-group-item bg-gray"><strong>Study Details</strong></li>
                            <li id="study_pace_li" className="list-group-item">
                                <h5 className="mb-1">Study Pace</h5>
                                <p id="study_pace" className="mb-0 font-weight-bold">
                                    {this.state.verificationResult.verificationInfo?.study_pace
                                        ? `${this.state.verificationResult.verificationInfo?.study_pace}%`
                                        : '0%'}
                                </p>
                            </li>
                            <li className="list-group-item">
                                <h5 className="mb-1">Valid From</h5>
                                <p id="study_valid_from" className="mb-0 text-secondary">
                                    {this.state.verificationResult.verificationInfo?.valid_from}
                                </p>
                            </li>
                            <li className="list-group-item">
                                <h5 className="mb-1">Expiry Date</h5>
                                <p id="study_valid_to" className="mb-0 text-secondary">
                                    {this.state.verificationResult.verificationInfo?.valid_to}
                                </p>
                            </li>
                        </ul>

                        {this.state.verificationResult.orgNames?.length > 0 &&
                        <ul id="card_list" className="list-group mb-4">
                            <li className="list-group-item bg-gray"><strong>Cards</strong></li>
                            {this.state.verificationResult.orgNames.map((org, index) => (
                                <li key={index} className="list-group-item">
                                    <h5 className="mb-1">{org.orgName}</h5>
                                    <p className="mb-0 text-secondary">{org.cardNumber}</p>
                                    <p className="mb-0 text-secondary">{org.valid_from} - {org.valid_to}</p>
                                </li>
                            ))}
                        </ul>}
                        
                    </div>
                )}

                {this.state.error && (
                    <UncontrolledAlert color="danger">
                        {this.state.error}
                    </UncontrolledAlert>
                )}
            </>
        );
    }
}


export default verifyStudent
