import React, { Component }  from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row, ModalFooter } from 'reactstrap';
import { Loader } from '../../../vibe';
import PayoutItemSlim from './PayoutItemSlim';

class PayoutConfirmModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    onMarkAsComplete() {
        this.props.onCompletePayouts(this.props.payouts)
    }

    toggleModal() {
        this.props.toggle()
    }

    render() {
        return <> 
            <Modal size="lg" isOpen={this.props.opened} toggle={this.toggleModal} className="previewModal">
                    <ModalHeader toggle={this.toggleModal}><strong>{this.props.title}</strong></ModalHeader>
                    <ModalBody className='modal-body-scrollable'>
                        <Form>
                            <Row>
                                <Col>
                                    <p>Mark the following {this.props.payouts.length} Payouts as Complete?</p>
                                </Col>
                            </Row>

                            {this.props.payouts.map((payout) => {
                                return <PayoutItemSlim key={payout.id} payout={payout} />
                            })}
                        </Form>
                    </ModalBody>

                    <ModalFooter className='sticky-footer d-flex justify-content-between'>
                        <Button size="lg" className="px-5 mt-3" color="success" onClick={() => this.onMarkAsComplete()}>Mark as Complete</Button>
                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                    </ModalFooter>
                       
                    {this.state.loading && 
                    <Row>
                        <Col >
                            <Loader type='dots'/>
                        </Col>
                    </Row>} 
            </Modal>
        </>
     }

}

export default PayoutConfirmModal;