import React from 'react';
import { Button, Col, Collapse, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getStripeInfo, getSwishInfo, getTransactionFromProvider, getTransactionsWithTickets } from '../../../vibe/helpers/apiHelper';
import { toTitle } from '../../../vibe/helpers/transactionStatus';
import { formatNumber, parseErrorMessage } from '../../../vibe/helpers/util';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import PaginationComp from '../../../vibe/components/utilities/Pagination/Pagination';
import { formatQuery } from 'react-querybuilder';
import MemberTicketTransaction from '../../elements/transaction/MemberTicketTransaction';
import { isSuperUser } from '../../../vibe/helpers/permissionHelper';
import { getCurrentUser } from '../../../vibe/helpers/apiHelper';
import SearchInput from '../../elements/form/SearchInput';

const pageSize = 10

class TicketTransactions extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            transactions: [],
            totalCount: 0,
            statusFilter: -1,
            search: '',
            currentPage: 1,
            itemsPerPage: 10,
            itemSaved: false,
            itemSaveError: null,
            debug: {open: false, id: '', data: undefined}
        }
    }

    componentDidMount() {
         getCurrentUser((user) => {
            this.setState({ user: user, error: null })
        }, (error) => {
            this.setState({ error: error, user: null })
        })


        this.getTransactions(this.state.statusFilter)
    }

    handleSelect = event => {
        this.setState({ statusFilter: parseInt(event.target.value) });
        this.getTransactions(parseInt(event.target.value))
    }

    onChangeSearch(e) {
        this.setState({search: e.target.value})
    }

    submitSearch(e) {
        e.preventDefault();
        this.setState({currentPage: 1}, () => {
            this.getTransactions(this.state.statusFilter)
        })
    }

    getTransactions(status) {
        getTransactionsWithTickets(status, this.state.itemsPerPage, this.state.currentPage - 1, this.state.search, (transactions, totalCount) => {
            this.setState({transactions: transactions, error: null, loading: false, totalCount: totalCount})
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({transactions: [], error: errorMessage, loading: false, totalCount: 0})
        })
    }

    renderTicketTransactions() {

        if (!this.state.transactions || this.state.transactions.length <= 0) {
            return null
        }
        
        return this.state.transactions.map((transaction) => {
             return <MemberTicketTransaction key={transaction.id} transaction={transaction} member={transaction.ticket_transactions[0].member} ticket={transaction.ticket_transactions[0].tickets} onAction={(success, error) => this.onAction(success, error)} />
        })
    }

    onAction(successMessage, error) {
        this.getTransactions(this.state.statusFilter)
        if (successMessage) {
            this.onFormSuccess(successMessage)
        } else {
            this.onFormError(error)
        }
    }

    renderAlerts() {
        return <MessageUncontrolledAlert success={this.state.itemSaved} error={this.state.itemSaveError} 
            successMessage={this.state.itemSuccessMessage} errorMessage={'Error: ' + this.state.itemSaveError} />
    }

    onFormError(error) {
        var errorMessage = parseErrorMessage(error, true)
        this.setState({itemSaveError: errorMessage})
    }

    onFormSuccess(successMessage) {
        this.setState({itemSaved: true, itemSaveError: null, itemSuccessMessage: successMessage})
        setTimeout(() => this.setState({itemSaved: false}), 5000)
    }

    onClickPagination(e, page) {
        this.setState({ currentPage: page, loading: true })
        var offset = (page - 1) * pageSize
        var status = this.state.statusFilter

        getTransactionsWithTickets(status, this.state.itemsPerPage, offset, this.state.search, (transactions, totalCount) => {
            this.setState({transactions: transactions, error: null, loading: false, totalCount: totalCount})
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({transactions: [], error: errorMessage, loading: false, totalCount: 0})
        })
    }
    
    getLastPage() {
        return Math.ceil(this.state.totalCount / pageSize)
    }

    renderPagination() {
        return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />    
    }

    onClickDebug() {
        let debug = this.state.debug
        debug.open = !debug.open
        this.setState({debug: debug})
    }

    onChangeDebug(key, value) {
        let debug = this.state.debug
        debug[key] = value
        this.setState({debug: debug})
    }

    onClickRunDebug() {
        let paymentReference = this.state.debug.id
        getTransactionFromProvider(paymentReference, (result) => {
            this.onChangeDebug('data', result)
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.onChangeDebug('data', errorMessage)
        })
    }

    onClickDebugInfo() {
        getSwishInfo((info) => {
            this.onChangeDebug('swish_info', info)
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.onChangeDebug('swish_info', errorMessage)
        })

        getStripeInfo((info) => {
            this.onChangeDebug('stripe_info', info)
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.onChangeDebug('stripe_info', errorMessage)
        })
    }

    renderDebug(debug) {
        if (!debug || !debug.open) {
            return null
        }

        return <Modal scrollable={false} isOpen={this.state.debug.open} toggle={() => this.onClickDebug()} style={{ maxWidth: '96%'}} className="previewModal"> 
                    <ModalHeader>Debug</ModalHeader> 
                    <ModalBody>

                        <div className='mb-2'>Fetch transaction data from payment provider</div>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="transaction_id">Payment reference</Label>
                                    <Input type="text" name="transaction_id" id="occtransaction_idasion_name" value={this.state.debug.id} onChange={(e) => this.onChangeDebug('id', e.target.value)} />
                                </FormGroup>
                            </Col>

                            <Col>
                                <Button className='ml-2 mt-4' color="primary" onClick={() => this.onClickRunDebug()} disabled={this.state.debug.id === ''}><i className="mr-1" />Run</Button>
                            </Col>

                            <Col>
                                <Button className='ml-2 mt-4' color="primary" onClick={() => this.onClickDebugInfo()}><i className="mr-1" />Info</Button>
                            </Col>

                            <Col>
                                <pre className="">{formatQuery(this.state.debug.swish_info, 'json')}</pre><span className="queryTag">Swish</span>
                                <pre className="">{formatQuery(this.state.debug.stripe_info, 'json')}</pre><span className="queryTag">Stripe</span>
                            </Col>
                        </Row>

                        <Collapse style={{width: '100%'}} isOpen={true}>
                            <pre className="">{formatQuery(this.state.debug.data, 'json')}</pre><span className="queryTag">JSON</span>
                        </Collapse>

                    </ModalBody>
   
                </Modal>
    }

    render() {
        if (this.state.loading) {
            return <Loader type="dots" />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {   
            return <React.Fragment>
                {this.renderAlerts()}
                {this.renderDebug(this.state.debug)}
                {this.state.user && isSuperUser(this.state.user) && 
                    <Button className='ml-2' style={{position: 'absolute', right: '20px', bottom: '20px'}} color="primary" onClick={() => this.onClickDebug()}><i className="mr-1" />Debug</Button>
                }
                <div className="row mb-3">
                    <div className="col">
                        <h1 className="h4 mb-0">{toTitle(this.state.statusFilter)} Transactions</h1>
                        <small className="text-muted">{formatNumber(this.state.totalCount)} transactions</small>
                    </div>

                    <SearchInput onSubmitSearch={(e) => this.submitSearch(e)} onChangeSearch={(e) => this.onChangeSearch(e)} title={"Search by member ID, PIN, first or last name"} buttonText={'Search'} />

                    <div className="col col-md-3">
                        <FormGroup className="mb-0 pt-1">
                        <Input
                            type="select"
                            name="select"
                            id="statusFilter"
                            defaultValue={ this.state.statusFilter }
                            onChange={this.handleSelect}>
                            <option value={-1}>All Transactions</option>
                            <option value={3}>Succeeded</option>
                            <option value={4}>Completed</option>
                            <option value={2}>Failed</option>
                            <option value={1}>Processing</option>
                            <option value={0}>Initialized</option>
                            <option value={5}>Refund pending</option>
                            <option value={6}>Refund failed</option>
                            <option value={7}>Refund completed</option>
                            <option value={8}>Payment cancelled</option>
                            <option value={9}>Payment declined</option>
                        </Input>
                        </FormGroup>
                    </div>
                </div>
                    {this.renderTicketTransactions()} 
                    {this.state.totalCount > 10 && this.renderPagination()}
                   </React.Fragment>      
        }
        
    }

}
export default TicketTransactions
