import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getNotifications, getCustomers } from '../../../vibe/helpers/apiHelper';
import Notification from '../../elements/notifications/Notification';
import { Link } from "react-router-dom";
import Select from 'react-select'
import DatePicker from '../../elements/form/DatePicker'
import Pager from '../../elements/form/Pager';

const pageSize = 10
const allCustomers = 'All customers'

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentPage: 1,
      customerOptions: [],
      customer: allCustomers,
      schedule: ''
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    getCustomers((customers) => {
      let customerOptions = []
      customerOptions.push({ value: allCustomers, label: allCustomers })
      if (customers && customers.length > 0) {
        customers.forEach((customer) => {
          let customerOption = {
            value: customer,
            label: customer,
          }
          customerOptions.push(customerOption)
        })
      }
      this.setState({ customerOptions: customerOptions })
    }, (error) => {
      console.log("Error getting customers", error)
    })

    var where = '&filter[include][][relation]=segment&filter[order]=schedule%20DESC'
    getNotifications(0, where, (result) => {
      this.setState({ totalCount: result.totalCount, notifications: result.notifications, loading: false, })
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1 })
      console.log("No push notification found")
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage) {
      this.fetchNotificationsWithFilter(this.state.currentPage, false)
    }
  }

  renderPush() {
    if (this.state && this.state.notifications) {
      return this.state.notifications.map((notification) => {
        return <Notification key={notification.id} notification={notification}></Notification>
      })
    }
  }

  onChangePage(page) {
    this.setState({ currentPage: page })
  }

  onSelectCustomer(e) {
    this.setState({ customer: e.value }, () => {
      this.fetchNotificationsWithFilter(1, true)
    })
  }

  onSelectScheduleDate(e) {
    let schedule = ''
    if (e !== 'Invalid date') {
      schedule = e.replace(/\//g, '-')
    }

    this.setState({ schedule: schedule }, () => {
      this.fetchNotificationsWithFilter(1, true)
    })
  }

  getRequestWhere() {
    var where = ''
    if (this.state.customer && this.state.customer !== allCustomers && this.state.schedule) {
      where += '&filter[where][and][0][customer][eq]=' + this.state.customer
      let gt = this.state.schedule + 'T00:00:00.000Z'
      let lt = this.state.schedule + 'T23:59:59.000Z'
      where += '&filter[where][and][1][schedule][gt]=' + gt + '&filter[where][and][2][schedule][lt]=' + lt
    } else if (this.state.customer && this.state.customer !== allCustomers) {
      where = '&filter[where][customer][eq]=' + this.state.customer
    } else if (this.state.schedule) {
      let gt = this.state.schedule + 'T00:00:00.000Z'
      let lt = this.state.schedule + 'T23:59:59.000Z'
      where = '&filter[where][and][0][schedule][gt]=' + gt + '&filter[where][and][1][schedule][lt]=' + lt
    }
    return where
  }

  fetchNotificationsWithFilter(page, reset) {

    var offset = (page - 1) * pageSize

    var where = this.getRequestWhere()
    where += '&filter[include][][relation]=segment&filter[order]=schedule%20DESC'
    getNotifications(offset, where, (result) => {
      if (reset) {
        this.setState({ totalCount: result.totalCount, currentPage: 1, notifications: result.notifications, loading: false })
      } else {
        this.setState({ totalCount: result.totalCount, notifications: result.notifications, loading: false })
      }
    }, (error) => {
      this.setState({ loading: false, totalCount: 0, currentPage: -1 })
      console.log("No push notification found")
    })
  }

  render() {

    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>
            <header className="app-header-page justify-content-end">
              <div className="col-md-3 mw-350">
                <FormGroup className="mb-0 pt-1">
                  <Link
                    className="btn btn-primary block"
                    to="/notification/new">
                    New Push
                  </Link>
                </FormGroup>
              </div>
            </header>

            <div className="row mb-3 align-items-center">
              <div className="col">
                <h1 className="h4 mb-0">Push Notifications</h1>
              </div>

              <div className="col col-md-2">
                <DatePicker handleChange={(e) => this.onSelectScheduleDate(e)} value={this.state.currentDate} />
              </div>

              <div className="col col-md-3">
                <Select options={this.state.customerOptions} defaultValue={this.state.customerOptions.length > 0 ? this.state.customerOptions.filter(option => option.label === allCustomers) : ''} onChange={(e) => this.onSelectCustomer(e)} placeholder={"Search customer"} />
              </div>

            </div>
            <div>
              {this.renderPush()}
              <Pager totalCount={this.state.totalCount} currentPage={this.state.currentPage} onChangePage={(e) => this.onChangePage(e)} />
            </div>
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default Notifications;
