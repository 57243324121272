import React, { Component } from 'react';
import { Card} from 'reactstrap';
import MemberFormResult from '../event/form/MemberFormResult';
import * as Icon from 'react-feather';
import { prettyPrintDate } from '../../../../vibe/helpers/util';

class MemberInterest extends Component {
    
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        
        const memberInterest = this.props.memberInterest
        const {cardIsOpen, toggleCard} = this.props

        if (!memberInterest) {
            return null
        }

        const formMemberResults = memberInterest?.form_member_results?.[0]
        return <>
            <Card className={`card-body ${cardIsOpen ? 'card-expanded' : ''} ${formMemberResults ? 'cursor-pointer' : ''}`} onClick={(e) => toggleCard(memberInterest.id)} >
                <div className="d-flex flex-column flex-md-row justify-content-between min-width-zero">
                    <div className="mb-0 text-small col-w-25 col-w-sm-100 mw-90"> 
                        <p className="list-item-heading mb-1">Name</p>
                        <p className="list-item-text mb-3 mw-90">{memberInterest?.member?.first_name + ' ' + memberInterest?.member?.last_name}</p>
                    </div>
                    <div className="mb-0 text-small col-w-25 w-sm-100">
                        <p className="list-item-heading mb-1">PIN</p>
                        <p className="list-item-text ">{memberInterest?.member?.personal_identification_number}</p>
                    </div>
                    <div className="mb-0 text-small col-w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Created At</p>
                        <p className="list-item-text ">{prettyPrintDate(memberInterest?.created_at)}</p>
                    </div>

                    <div className="mb-0 text-small w-15 w-sm-100 d-flex align-items-center">
                        {formMemberResults ? <div className=''>{cardIsOpen ? (<Icon.ChevronUp size="20" />) :(<Icon.ChevronDown size="20" />)}</div>
                         : <Icon.ChevronDown size="20" style={{ visibility: "hidden" }} />
                        }
                    </div>

                </div>
                {formMemberResults && cardIsOpen && <MemberFormResult memberTicket={memberInterest} /> } 
            </Card>
        </>
    }
}

export default MemberInterest;