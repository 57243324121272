import React from 'react';
import { printShort, toReadablePrice } from '../../elements/form/Currency';
import { Col, Row } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { getOccurrenceDateString } from '../../../vibe/helpers/payoutHelper';

class PayoutPurchaseDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ticketDataArray: [],
        }
    }

    componentDidMount() {
        // TODO DEV-8413 show error/warning if no transactions in this payout? but the payout has amount?
    }

    getTransactionCount() {
        if (this.props.transactionCount) {
            return this.props.transactionCount
        }

        if (this.props.transactions?.length) {
            return this.props.transactions?.length
        }

        return 0
    }

    renderPayoutPurchaseDetails3(transactionDetails) {
        return <div className="col-md-6 col-12">
                <div className="card h-100">
                    <div className="card-body">
                        <h4 className="card-title">{this.props.title ?? 'Transaction Details'}</h4>
                        <Row>
                            <Col md={4} className="mb-2">
                                <p className="list-item-heading mb-0">Transaction Count</p> 
                                {this.getTransactionCount()}
                            </Col>

                            <Col md={4} className="mb-2">
                                <p className="list-item-heading mb-0">Total Items</p> 
                                {transactionDetails?.total_items_count ?? 0} ({transactionDetails?.tickets_count ?? 0} tickets, {transactionDetails?.packages_count ?? 0} packages)
                            </Col>
                        </Row>
                        
                        <hr />

                        <div>
                            {transactionDetails?.event_transaction_details?.map((item, index) => {
                                return <div key={item.id} className="mb-3">
                                    <p className="mb-0"><strong>{item.name}</strong></p>
                                    
                                    {item.organization_event_occurrences.map((occurrence => {
                                        return <div key={occurrence.id}>
                                            <p className="mt-2 mb-0 list-item-heading">{getOccurrenceDateString(occurrence)}</p>
                                            {occurrence.tickets.map((tickets) => {

                                                return <div key={tickets.id} >
                                                        {tickets.amounts_per_item.map((amount) => {
                                                            return <Row key={uuidv4()} className={'list-item-text justify-content-end'} >
                                                                <Col md={4}>
                                                                    {tickets.name}
                                                                </Col>
                                                                <Col md={4}>
                                                                    {amount.count + ' x ' + printShort(toReadablePrice(amount.amount), amount.currency)}
                                                                </Col>
                                                                <Col md={4}>
                                                                    <strong>{printShort(toReadablePrice(amount.total_amount), amount.currency)}</strong>
                                                                </Col>
                                                            </Row>
                                                        })}
                                                    </div>
                                        })}
                                        </div>

                                    }))}
                                    {index === transactionDetails?.event_transaction_details?.length - 1 && transactionDetails?.package_transaction_details?.length <= 0 ? null : <hr />} 
                                    
                                </div>
                            })}

                            {transactionDetails?.package_transaction_details?.map((item, index) => {
                                return <div key={item.id} className="mb-3">
                                {item.amounts_per_item.map((amount) => {
                                    return <Row key={uuidv4()} className={'list-item-text justify-content-end'} >
                                                <Col md={4}>
                                                    {item.name}
                                                </Col>
                                                <Col md={4}>
                                                    {amount.count + ' x ' + printShort(toReadablePrice(amount.amount), amount.currency)}
                                                </Col>
                                                <Col md={4}>
                                                    <strong>{printShort(toReadablePrice(amount.total_amount), amount.currency)}</strong>
                                                </Col>
                                            </Row>
                                })}
                                {index === transactionDetails?.package_transaction_details?.length - 1 ? null : <hr />} 
                            </div>
                            })}

                        </div>

                    </div>
                </div>
        </div>
    }

    // render all items purchased for this payout
    // @deprecated
    /*
    renderPayoutPurchaseDetailsOld(transactions) {
       let payoutMeta = getPayoutMeta(transactions)
    
        return <div className="col-md-6 col-12">
                <div className="card h-100">
                    <div className="card-body">
                        <h4 className="card-title">Transaction Details</h4>
                        <p className="card-text">Transaction Count: {transactions?.length ?? 0}</p>

                        <h4 className="card-title">Items Purchased</h4>
                        <p className="card-text">Total Items: {payoutMeta?.totalItemsCount ?? 0} ({payoutMeta?.ticketsCount ?? 0} tickets, {payoutMeta?.packagesCount ?? 0} packages)</p>

                        <div>
                            {payoutMeta?.packages.map((_package) => {
                                let packageData = packagePayoutData(_package)


                                return <div key={_package.id} className="mb-3">
                                    <p className="list-item-heading mb-0 mb-0">Item Details</p>
                                    <p className="mb-0 list-item-text">{packageData.packageName}</p>
                                    <p className="mb-0 list-item-text">({packageData.pricePerItem})  - <strong>{printShort(toReadablePrice(packageData.amount), packageData.currency)}</strong></p>
                                </div>
                            })}


                            {payoutMeta?.tickets.map((ticket) => {

                                let ticketData = ticketPayoutData(ticket)

                                return <div key={ticket.id} className="mb-3">
                                    <p className="list-item-heading mb-0 mb-0">Item Details</p>
                                    <p className="mb-0 list-item-text"><strong>{ticketData.eventName}</strong></p>
                                    <p className="mb-0 list-item-text">{ticketData.ticketName} ({ticketData.pricePerItem})  -  <strong>{printShort(toReadablePrice(ticketData.amount), ticketData.currency)}</strong></p>
                                </div>
                            })}

                        </div>

                    </div>
                </div>
        </div>
    }
    */

    // getTransactionAmountPerItem(transactions) {
    //     var data = []

    //     for (var transaction of transactions) {
    //         if (transaction.package_transactions && transaction.package_transactions.length > 0) {
    //             let current = data.find(data => data.amount === transaction.amount && data.currency === transaction.currency)
    //             if (current) {
    //                 current.count++
    //             } else {
    //                 data.push({ amount: transaction.amount, currency: transaction.currency, count: 1 })
    //             }
    //         } else if (transaction.ticket_transactions && transaction.ticket_transactions.length > 0) {

    //             let ticketTransaction = transaction.ticket_transactions[0]
    //             let meta = JSON.parse(ticketTransaction.meta)
    //             let ticketMeta = meta.tickets.find(ticketMeta => ticketMeta.id === ticketTransaction.tickets.id)
    //             let count = ticketMeta.count

    //             let pricePerItem = transaction.amount / count

    //             let current = data.find(data => data.amount === pricePerItem && data.currency === transaction.currency)
    //             if (current) {
    //                 current.count = current.count + count
    //             } else {
    //                 data.push({ amount: pricePerItem, currency: transaction.currency, count: count })
    //             }

    //         }
    //     }

    //     return data
    // }

    

    render() {
        return <>
                {/* {this.renderPayoutPurchaseDetailsOld(this.props.transactions)} */}
                {this.renderPayoutPurchaseDetails3(this.props.transactionDetails)}
            </>
    }

}

export default PayoutPurchaseDetails
