import React from 'react';
import { completeTransaction, createVerifiedOrganizationMember, getCurrentUser, getMemberVerifications, getOrganizationById, getPeriod, getTransactionById, getTransactionLogsByTransactionId, getVerifiedOrganizationMembers } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage, prettyPrintDate, renderPackageMembershipSubscriptionsDetailed } from "../../../vibe/helpers/util";
import EditableForm from '../common/EditableForm';
import { printShort, toReadablePrice } from '../../elements/form/Currency';
import TransactionLogs from './TransactionLogs';
import TransactionActions from './TransactionActions';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import {ChevronDown, ChevronUp} from 'react-feather';
import MemberDetails from './MemberDetails';
import MemberVerificationUploads from './MemberVerificationUploads';
import TransactionDetails from './TransactionDetails';
import TransactionMessages from './TransactionMessages';
import TransactionEmails from './TransactionEmails';

class PackageTransactionDetail extends EditableForm {

    constructor(props) {
        super(props);
        this.state = {
            member: null,
            package: null,
            memberVerification: { verification_type: 1 },
            defaultPeriod: {},
            verificationTypeOptions: [],
            verifiedOrganizationMembers: [],
            memberVerifications: [],
            createVerifiedOrganizationMemberSuccess: { show: false },
            loadLogs: false,
            loadOlderVerifications: false,
        }
    }

    getMember(transaction) {
        if (!transaction) {
            return null
        }

        if (transaction.ticket_transactions && transaction.ticket_transactions.length > 0) {
            return transaction.ticket_transactions[0].member
        }

        if (transaction.package_transactions && transaction.package_transactions.length > 0) {
            return transaction.package_transactions[0].member
        }

        return null
    }

    getItem() {

        getCurrentUser((user) => {
            this.setState({ user: user })
        }, (error) => {
            this.setState({ user: null })
        })

        let id = this.props.match.params.id
        if (id) {

            getTransactionById(id, (transaction) => {

                let _package = transaction?.package_transactions?.[0].package
                let packageTransaction = transaction?.package_transactions?.[0]
                let ticket = transaction?.ticket_transactions?.[0].tickets
                let member = this.getMember(transaction)

                let organizationId
                if (_package) {
                    organizationId = _package.organization_id
                } else if (ticket) {
                    organizationId = ticket.organization_event_occurrences[0].organization_event.organization_id
                }

                this.setState({ item: transaction, member: member, package: _package, ticket: ticket, packageTransaction: packageTransaction, loading: false, error: null, organizationId: organizationId })

                // show all verifications for this member
                this.refreshVerifiedOrganizationMembers(organizationId, member)

                getOrganizationById(organizationId, (organization) => {
                    let verificationTypeOptions = [{ value: 1, name: 'Verified in ' + organization.display_name, label: 'Verified in ' + organization.display_name }, { value: 3, name: 'Verified university student', label: 'Verified university student' }]
                    this.setState({ organization: organization, verificationTypeOptions: verificationTypeOptions })
                }, (error) => {
                    console.log("getOrganizationById error")
                })

                // default period for creating new verification
                getPeriod(organizationId, (period) => {
                    let memberVerification = {
                        valid_from: period.valid_from,
                        valid_to: period.valid_to,
                        verification_type: 1
                    }
                    this.setState({ defaultPeriod: period, memberVerification: memberVerification })
                }, (error) => {
                    console.log("getPeriod error")
                })

                // get member verification requests including images
                if (member?.id) {
                    getMemberVerifications(member.id, organizationId, (memberVerifications) => {
                        this.setState({ memberVerifications: memberVerifications })
                    }, (error) => {
                        console.log("error getting memberVerifications", error)
                    })
                }

            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage, loading: false })
            })
        }
    }

    refreshVerifiedOrganizationMembers(organizationId, member) {
        if (!member) {
            return
        }

        getVerifiedOrganizationMembers(organizationId, member, (verifiedOrganizationMembers) => {
            this.setState({ verifiedOrganizationMembers: verifiedOrganizationMembers })
        }, (error) => {
            console.log("getVerifiedOrganizationMembers error")
        })
    }

    renderFormFooter() {
        return <TransactionActions transaction={this.state.item} member={this.state.member} onAction={(success, error) => this.onAction(success, error)} />
    }

    renderAlerts() {
        return <MessageUncontrolledAlert success={this.state.itemSaved} error={this.state.itemSaveError}
            successMessage={this.state.itemSuccessMessage} errorMessage={'Error: ' + this.state.itemSaveError} />
    }

    onFormError(error) {
        var errorMessage = parseErrorMessage(error, true)
        this.setState({ itemSaveError: errorMessage })
    }

    onFormSuccess(successMessage) {
        this.setState({ itemSaved: true, itemSaveError: null, itemSuccessMessage: successMessage })
        setTimeout(() => this.setState({ itemSaved: false }), 5000)
    }

    onAction(successMessage, error) {
        this.getItem()
        if (successMessage) {
            this.onFormSuccess(successMessage)
        } else {
            this.onFormError(error)
        }
    }

    toggleLogs() {
        this.setState(prevState => ({ 
            loadLogs: !prevState.loadLogs 
        }));
    }

    isDisplayLogs() {
        return this.state.loadLogs
    }

    onLoadLogs(transaction) {
        if(!this.isDisplayLogs()) {
            getTransactionLogsByTransactionId(transaction.id, transaction.transaction_id, this.state?.member?.id, (transactionLogs) => {
                this.setState({ transactionLogs: transactionLogs})
                this.toggleLogs()
            }, (error) => {
                    console.log("transactionLog error", error)
            })
        } else {
            this.setState({ transactionLogs: ""})
            this.toggleLogs()
        }
    }
   
    onLoadOlderVerifications() {
        this.setState(prevState => ({ 
            loadOlderVerifications: !prevState.loadOlderVerifications 
    }));}

    onChangeMemberVerification(key, value) {
        let memberVerification = this.state.memberVerification
        memberVerification[key] = value
        this.setState({ memberVerification: memberVerification })
    }

    /**
     * @returns the date part of the iso string (2023-01-01)
     */
    getDateValue(key) {
        if (this.state.memberVerification && this.state.memberVerification[key]) {
            return this.getDateSubstring(this.state.memberVerification[key])
        }
        return ''
    }

    getDateSubstring(date) {
        if (!date) {
            return ''
        }
        return date.substring(0, 10)
    }

    verifyOrganizationMember(member, _package, transaction, done) {
        if (!member) {
            return
        }

        if (!this.state.memberVerification.valid_from || !this.state.memberVerification.valid_to) {
            return
        }

        let memberIdentifier = ''
        if (member.personal_identification_number) {
            memberIdentifier = member.personal_identification_number
        } else if (member.email) {
            memberIdentifier = member.email
        }

        let periodId
        if (this.state.defaultPeriod && this.state.defaultPeriod.valid_from && this.state.defaultPeriod.valid_to) {

            if (
                this.getDateSubstring(this.state.defaultPeriod.valid_from) === this.getDateValue('valid_from') &&
                this.getDateSubstring(this.state.defaultPeriod.valid_to) === this.getDateValue('valid_to')) {

                periodId = this.state.defaultPeriod.id
            }

        }

        let data = {
            member_identifier: memberIdentifier,
            organization_id: _package.organization_id,
            verification_type: this.state.memberVerification.verification_type
        }

        if (periodId) {
            data.period_id = periodId
        } else {
            let validFrom = this.getDateValue('valid_from') + ' 00:00:00'
            const fromDate = new Date(validFrom)
            const from = fromDate.getTime()
            const utcFrom = new Date(from).toISOString();

            let validTo = this.getDateValue('valid_to') + ' 00:00:00'
            const toDate = new Date(validTo)
            const to = toDate.getTime()
            const utcTo = new Date(to).toISOString();

            data.valid_from = utcFrom
            data.valid_to = utcTo
        }

        let verificationTypeOption = this.state.verificationTypeOptions.find((verificationTypeOption) => verificationTypeOption.value === data.verification_type)

        let transactionConfirmText = "Accept transaction " + transaction.transaction_id + " for member with id " + member.id + "? This member will receive all memberships included in this package."

        let confirm = window.confirm("Verify member between " + this.getDateValue('valid_from') + " and " + this.getDateValue('valid_to') + " with verification \"" + verificationTypeOption.label + "\"?\n\n" + transactionConfirmText);
        if (confirm) {
            createVerifiedOrganizationMember(data, (result) => {
                done()
                this.setState({ createVerifiedOrganizationMemberSuccess: { show: true, id: result.id } })
                setTimeout(() => this.setState({ createVerifiedOrganizationMemberSuccess: { show: false } }), 5000)
                this.refreshVerifiedOrganizationMembers(this.state.organizationId, this.state.member)
            }, (error) => {
                this.refreshVerifiedOrganizationMembers(this.state.organizationId, this.state.member)
            })
        }
    }

    onClickVerifyOrganizationMember(member, _package, transaction) {
        this.verifyOrganizationMember(member, _package, transaction, () => {
            completeTransaction(transaction.id, (result) => {
                this.onAction('Transaction ' + transaction.transaction_id + " changed to Completed status", null)
            }, (error) => {
                this.onAction(null, error)
            })
        })
    }
    
    showPendingVerifications(memberVerification) {
        return <MemberVerificationUploads memberVerification={memberVerification}/>
    } 
   
    isWithinTimeLimit(memberVerification, transaction) {
        // Check if verification is within 6 Hours of transaction
        var verificationCreatedAt = new Date(memberVerification.created_at);
        var transactionCreatedAt = new Date(transaction.created_at);
    
        var timeDifferenceInMilliseconds = verificationCreatedAt - transactionCreatedAt;
        var timeDifferenceInMinutes = timeDifferenceInMilliseconds / (1000 * 60);
    
        const SIX_HOURS_IN_MINUTES = 6 * 60;
    
        if (timeDifferenceInMinutes >= -SIX_HOURS_IN_MINUTES && timeDifferenceInMinutes <= SIX_HOURS_IN_MINUTES) {
            return true;
        } else {
            return false;
        }
    }
    

    renderOlderVerifications(verifications) {
        if(!verifications || verifications.length <= 0) {
            return null
        }
        
        return <>
            {this.state.loadOlderVerifications ? <h5 className='icon-delete' style={{marginTop: '20px'}} onClick={() => this.onLoadOlderVerifications()}>Hide all verifications <ChevronUp color="black" className="side-nav-icon chevronUp"/></h5> : <h5 className='icon-delete' style={{marginTop: '20px'}} onClick={() => this.onLoadOlderVerifications()}>Show all verifications <ChevronDown color="black" className="side-nav-icon chevronDown"/></h5>}
            {this.state.loadOlderVerifications && verifications.map((verification, index) => (
            <div key={index}>
                <MemberVerificationUploads memberVerification={verification}/>
            </div>
        ))}    
        </>        
    }

    /**
     * Only for package transactions
     */
    renderMemberVerifications(memberVerifications, transaction) {
        if (!memberVerifications || memberVerifications.length <= 0 || !this.state.package) {
            return null
        }   
     
        let olderVerifications = []; 
        let pendingMemberVerifications = []; 

        memberVerifications.forEach((memberVerification) => { 
            if (!this.isWithinTimeLimit(memberVerification, transaction)) { 
                olderVerifications.push(memberVerification); 
            } else { 
                pendingMemberVerifications.push(<React.Fragment key={memberVerification.id}> 
                    {this.showPendingVerifications(memberVerification)} 
                </React.Fragment>); 
            }}); 
                
        return <>
            {pendingMemberVerifications}
            {/** Uncomment to display older verifications not within 6 hour limit
            {this.renderOlderVerifications(olderVerifications)} 
            */}
            {/* {this.renderVerifiedOrganizationMembers(this.state.verifiedOrganizationMembers)} */}
        </>
    }

    renderVerifiedOrganizationMembers(verifiedOrganizationMembers) {
        if (!verifiedOrganizationMembers || verifiedOrganizationMembers.length <= 0) {
            return null
        }

        return <>
            <h4 className="card-title mt-3">Verifications</h4>
            {verifiedOrganizationMembers.map((verifiedOrganizationMember) => {
                let validFrom
                let validTo
                if (verifiedOrganizationMember.period) {
                    validFrom = this.getDateSubstring(verifiedOrganizationMember.period.valid_from)
                    validTo = this.getDateSubstring(verifiedOrganizationMember.period.valid_to)
                } else {
                    validFrom = this.getDateSubstring(verifiedOrganizationMember.valid_from)
                    validTo = this.getDateSubstring(verifiedOrganizationMember.valid_to)
                }

                let verificationTypeOption = this.state.verificationTypeOptions.find((verificationTypeOption) => verificationTypeOption.value === verifiedOrganizationMember.verification_type)

                let createVerifiedOrganizationMemberSuccessClass = ''
                if (this.state.createVerifiedOrganizationMemberSuccess && this.state.createVerifiedOrganizationMemberSuccess.show) {
                    if (this.state.createVerifiedOrganizationMemberSuccess.id === verifiedOrganizationMember.id) {
                        createVerifiedOrganizationMemberSuccessClass = 'bg-success'
                    }
                }

                let verification = <div key={verifiedOrganizationMember.id} className={"row mb-2 "}>

                    <div className="text-small w-25 w-sm-100 col-3">
                        <p className="list-item-heading mb-0">From</p>
                        <p className="list-item-text mb-3">{validFrom}</p>
                        <p className="list-item-heading mb-0">To</p>
                        <p className="list-item-text mb-3">{validTo}</p>
                    </div>

                    {verificationTypeOption && <div className="text-small w-25 w-sm-100 col-12 mb-3">
                        <p className="list-item-heading mb-0">Verification type</p>
                        <p className="list-item-text mb-3">{verificationTypeOption.label}</p>
                    </div>}

                </div>

                return <div className={createVerifiedOrganizationMemberSuccessClass}>
                    {createVerifiedOrganizationMemberSuccessClass && <div className='my-3'>Verification created</div>}
                    {verification}
                </div>

            })}

        </>
    }

    renderPackage() {
        return <div className="col-md-6 col-12">
            <div className="card h-100">
                <div className="card-body">
                    <h4 className="card-title">Package Details</h4>
                    <p className="list-item-heading mb-0">Package ID</p>
                    <p className="list-item-text mb-3">{this.state.package.id}</p>
                    <p className="list-item-heading mb-0">Name</p>
                    <p className="list-item-text mb-3">{this.state.package.name}</p>
                    <p className="list-item-heading mb-0">Price</p>
                    <p className="list-item-text mb-3">{printShort(toReadablePrice(this.state.package.price), this.state.package.currency)}</p>
                    <p className="list-item-heading mb-0">Memberships</p>
                    {renderPackageMembershipSubscriptionsDetailed(this.state.package, this.state.packageTransaction)}
                </div>
            </div>
        </div>
    }

    renderTickets() {
        return <div className="col-md-6 col-12">
            <div className="card h-100">
                <div className="card-body">
                    <h4 className="card-title">Ticket Details</h4>
                    <p className="list-item-heading mb-0">Event name</p>
                    <p className="list-item-text mb-3">{this.state.ticket.organization_event_occurrences[0].organization_event.title}</p>
                    <p className="list-item-heading mb-0">Occurrence date</p>
                    {this.state.ticket.organization_event_occurrences.map((occurrence) => {
                        return <p key={occurrence.id} className="list-item-text mb-3">{prettyPrintDate(occurrence.start_date)}</p>
                    })}
                    <p className="list-item-heading mb-0">Ticket name</p>
                    <p className="list-item-text mb-3">{this.state.ticket.name}</p>
                    <p className="list-item-heading mb-0">Ticket ID</p>
                    <p className=" list-item-text mb-3">{this.state.ticket.id}</p>
                </div>
            </div>
        </div>
    }

    renderForm(transaction) {
        return <>

            {transaction.transaction_messages && <TransactionMessages transaction={transaction}/>}  

            {this.state.package && this.state.memberVerifications && this.state.memberVerifications.length > 0 &&  
                <div className="row mb-3">
                    <div className="col-md-12 col-12">
                        <div className="card h-100">
                            <div className="card-body">
                                <h4 className="card-title">Member Verification Uploads</h4>
                                {this.renderMemberVerifications(this.state.memberVerifications, transaction)}
                            </div>
                        </div>
                    </div>
                </div>
            }

            <div className="row mb-3">
                <div className="col-md-6 col-12">
                    <div className="card h-100">
                        <div className="card-body">
                            <h4 className="card-title">Transaction Details</h4>
                            <TransactionDetails transaction={transaction}/>
                        </div>
                    </div>
                </div>

                {this.state.package ? this.renderPackage() : this.renderTickets()}
            </div >

            <MemberDetails member={this.state.member}/>

            <TransactionEmails transaction={transaction} user={this.state.user} isTicket={!!this.state.ticket} />

            <div className="">
                <div className="card h-100">
                    <div className="card-body">
                        {this.state.loadLogs ? <h4 className="icon-delete" onClick={() => this.onLoadLogs(transaction)}>Transaction Logs <ChevronUp color="black" className="side-nav-icon chevronUp"/></h4> : <h4 className="icon-delete" onClick={() => this.onLoadLogs(transaction)}>Transaction Logs <ChevronDown color="black" className="side-nav-icon chevronDown"/></h4>}
                        <div><TransactionLogs transactionLogs={this.state.transactionLogs} /></div>
                    </div>
                </div>
            </div>
        </>
    }
}

export default PackageTransactionDetail