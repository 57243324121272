import React, { Component } from 'react';
import {Input, Col, FormGroup, Label, Row, Card, Button, Collapse, CardBody, UncontrolledAlert} from 'reactstrap';
import Select from 'react-select'
import ReactableCard from '../../elements/membership/ReactableCard';
import { downloadMedia, getMediaById, getSegments } from '../../../vibe/helpers/apiHelper';
import { v4 as uuidv4 } from 'uuid';
import { MEMBERSHIP_CARD_WIDTH_DEFAULT, MEMBERSHIP_CARD_HEIGHT_DEFAULT, ATTRIBUTE_KEY_TYPE_IMAGE, ATTRIBUTE_KEY_TYPE_ORGANIZATION, ATTRIBUTE_KEY_TYPE_NAME, 
    ATTRIBUTE_KEY_TYPE_PIN, ATTRIBUTE_KEY_TYPE_VALID_TO, ATTRIBUTE_KEY_TYPE_VALID_TO_STUDY_PERIOD, ATTRIBUTE_KEY_TYPE_SEMESTER, ATTRIBUTE_KEY_TYPE_CARD_CODE, 
    ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT, ATTRIBUTE_KEY_TYPE_CARD_NUMBER, EXAMPLE_TEXT, ATTRIBUTE_KEY_FRONT, ATTRIBUTE_KEY_IMAGE_HEIGHT, ATTRIBUTE_KEY_IMAGE_WIDTH, 
    ATTRIBUTE_KEY_KEEP_ASPECT_RATIO, ATTRIBUTE_KEY_TYPE_TEXT, ATTRIBUTE_KEY_FILE_NAME, ATTRIBUTE_KEY_DATA_FIELD, ATTRIBUTE_KEY_ENABLED, ATTRIBUTE_KEY_X, ATTRIBUTE_KEY_Y, 
    ATTRIBUTE_KEY_TYPE, ATTRIBUTE_KEY_KEY, ATTRIBUTE_KEY_TEXT_ALIGN, ATTRIBUTE_KEY_TEXT_ALIGN_CENTER, ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT, 
    ATTRIBUTE_KEY_BACK,
    ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LAST,
    ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LUND,
    ATTRIBUTE_KEY_TYPE_VALID_FROM,
    ATTRIBUTE_KEY_TYPE_VALID_FROM_STUDY_PERIOD} from '../../elements/membership/MembershipCardConstants';
import { getImageDimensions } from '../../../vibe/helpers/imageHelper';
import { formatQuery } from 'react-querybuilder';
import OrganizationField from '../../elements/membership/OrganizationField';
import TextField from '../../elements/membership/TextField';
import ImageField from '../../elements/membership/ImageField';
import DataField from '../../elements/membership/DataField';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import MediaSelect from '../../elements/media/MediaSelect';
import { getTheme, createTheme, updateTheme, cloneTheme, deleteTheme } from '../../../api/theme/theme';
import DEFAULT_FRONT_IMAGE from '../../../assets/images/FRONT_VT_2024_200.png';
import DEFAULT_BACK_IMAGE from '../../../assets/images/BACK_VT_2021_200.png';
import { parseErrorMessage } from '../../../vibe/helpers/util';

const MEDIA_FORM_ID_FRONT = 'uploadFormFront'
const MEDIA_FORM_ID_BACK = 'uploadFormBack'

const DATA_FIELD_OPTIONS = [
  {label: 'Name', value: ATTRIBUTE_KEY_TYPE_NAME},
  {label: 'Personal Identification number', value: ATTRIBUTE_KEY_TYPE_PIN},
  {label: 'Valid from (Card Expiry)', value: ATTRIBUTE_KEY_TYPE_VALID_FROM},
  {label: 'Valid to (Card Expiry)', value: ATTRIBUTE_KEY_TYPE_VALID_TO},
  {label: 'Valid from (Study Period)', value: ATTRIBUTE_KEY_TYPE_VALID_FROM_STUDY_PERIOD},
  {label: 'Valid to (Study Period)', value: ATTRIBUTE_KEY_TYPE_VALID_TO_STUDY_PERIOD},
  {label: 'Card number', value: ATTRIBUTE_KEY_TYPE_CARD_NUMBER},
  {label: 'Semester', value: ATTRIBUTE_KEY_TYPE_SEMESTER},
  {label: 'Card extra text', value: ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT},
  {label: 'Card code', value: ATTRIBUTE_KEY_TYPE_CARD_CODE},
  {label: 'Organization', value: ATTRIBUTE_KEY_TYPE_ORGANIZATION,},
  {label: 'Card extra last', value: ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LAST},
  {label: 'Card extra Lund', value: ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LUND},
]

const ELEMENT_OPTIONS = [
  {label: 'Text', value: ATTRIBUTE_KEY_TYPE_TEXT},
  {label: 'Image', value: ATTRIBUTE_KEY_TYPE_IMAGE},
  {label: 'Dynamic Text', value: ATTRIBUTE_KEY_DATA_FIELD, additionalOptions: DATA_FIELD_OPTIONS},
]

class Theme extends Component {
  constructor(props) {
    super(props);
    this.state = {
        images: {},
        showCardAttributes: {
            [ATTRIBUTE_KEY_FRONT]: true,
            [ATTRIBUTE_KEY_BACK]: true
        },
        renderFrontMediaUpload: false,
        renderBackMediaUpload: false,
        showJson: false,

        isEditMode: false,
        card_attributes: {
            [ATTRIBUTE_KEY_FRONT]: [],
            [ATTRIBUTE_KEY_BACK]: [] 
        },
        blocks: {
            front: [],
            back: []
        },
        elementVisibility: {},
        selectedElement: null,
        gridVisible: false,
        snapToGrid: false,
        open: {},
        showAddElement: {},
        name: '',
        organization_id: '',
        preview_front_image_media_id: null,
        preview_back_image_media_id: null,
    };
    this.onDragEndElement = this.onDragEndElement.bind(this)
    this.onDragEndBlock = this.onDragEndBlock.bind(this)
    this.onElementClick = this.onElementClick.bind(this)
    this.handleDeleteTheme = this.handleDeleteTheme.bind(this)
}

isBlockVisible = (blockId, key) => {
  const blockIndex = this.state.blocks[key].findIndex(block => block.blockId === blockId);
  if (blockIndex === -1) return;

  const block = this.state.blocks[key].find(block => block.blockId === blockId);
  if (block) {
      return block.elements?.some((element, index) => !this.state.elementVisibility[blockId]?.[index]);
  }
  return true;
};

toggleBlockVisibility = (blockId, key) => {
  const block = this.state.blocks[key].find(block => block.blockId === blockId);
  if (block) {
      block.elements.forEach((element, index) => {
          this.toggleElementVisibility(blockId, index, key);
      });
  }
};

toggleElementVisibility = (blockId, index, side) => {
  this.setState(prevState => ({
      elementVisibility: {
          ...prevState.elementVisibility,
          [blockId]: {
              ...prevState.elementVisibility[blockId],
              [index]: !prevState.elementVisibility[blockId]?.[index]
          }
      },
  }));
};

handleClone = (themeId) => {
    cloneTheme(
      themeId,
      (newTheme) => {
        this.props.history.push(`/themes`);
      },
      (error) => {
        console.error('Error cloning theme:', error);
      }
    );
  };

componentDidMount() {
    const { match } = this.props;
    const isEditMode = match.path.includes('edit');
    const themeId = match.params.id;
    this.setState({ isEditMode }, () => {
      if (isEditMode) {
        this.loadTheme(themeId);
      }else{
        this.setDefaultFrontAndBackImages()
      }
    });
}

setDefaultFrontAndBackImages = () => {
  this.setState({
    frontMedia: DEFAULT_FRONT_IMAGE,
    backMedia: DEFAULT_BACK_IMAGE,
    frontBlob: DEFAULT_FRONT_IMAGE,
    backBlob: DEFAULT_BACK_IMAGE,    
  })
}

loadTheme = (id) => {
  getTheme(
    id,
    (theme) => {
      this.setState({ 
        themeId: id,
        name: theme.name,
        blocks: JSON.parse(theme.attributes),
        preview_front_image_media_id: theme.preview_front_image_media_id,
        preview_back_image_media_id: theme.preview_back_image_media_id
      });

      this.getMediaImage(theme.preview_front_image_media_id, 'frontMedia', 'frontBlob', 'frontError')
      this.getMediaImage(theme.preview_back_image_media_id, 'backMedia', 'backBlob', 'backError') 

      this.downloadImageMedia(ATTRIBUTE_KEY_FRONT)
      this.downloadImageMedia(ATTRIBUTE_KEY_BACK)

      if(!theme.preview_front_image_media_id || !theme.preview_back_image_media_id){
        this.setDefaultFrontAndBackImages()
      }

      getSegments('0', '', (result) => { 

        let segmentOptions = []
        let segments = result.segments
        if (segments && segments.length > 0) {
          segments.forEach((segment) => {
              let segmentOption = {
                  value: segment.id,
                  label: segment.name,
              }
              segmentOptions.push(segmentOption)
          })
        }
 
        this.setState({segments: segmentOptions})
      }, (error) => {
        console.log("Error getting segments", error)
      })

    },
    (error) => {
      console.log('Error loading campaign', error);
    },
  );
}

saveTheme = () => {
    const { isEditMode } = this.state;
    const { match, history } = this.props;
  
    const themeData = {
      name: this.state.name,
      attributes: JSON.stringify(this.state.blocks),
    };
    
    if(this.state.preview_front_image_media_id){
      themeData.preview_front_image_media_id = this.state.preview_front_image_media_id
    }
    if(this.state.preview_back_image_media_id){
      themeData.preview_back_image_media_id = this.state.preview_back_image_media_id
    }
  
    const onSuccess = (response) => {
      if(!isEditMode){
        history.push('/theme/edit/' + response.id);
      }

      this.setState({themeSaved: true})
      setTimeout(() => this.setState({ themeSaved: false }), 5000)
    };
  
    const onError = (error) => {
      console.error('Error saving theme:', error);
      var errorMessage = parseErrorMessage(error)
      this.setState({themeSavedError: errorMessage})
    };
  
    if (isEditMode) {
      const themeId = match.params.id;
      updateTheme(themeId, themeData, onSuccess, onError);
    } else {
      createTheme(themeData, onSuccess, onError);
    }
  };

  handleNameChange = (e) => {
    this.setState({ name: e.target.value });
  };

  downloadImageMedia(side) {
    var self = this;
    const blocks = this.state.blocks[side];

    if (blocks && blocks.length > 0) {
        blocks.forEach(block => {
            block?.elements?.forEach(element => {
                if (element?.[ATTRIBUTE_KEY_TYPE] === ATTRIBUTE_KEY_TYPE_IMAGE && element?.[ATTRIBUTE_KEY_FILE_NAME]) {
                    self.getBlobImage(element[ATTRIBUTE_KEY_FILE_NAME]);
                } else if (element?.[ATTRIBUTE_KEY_TYPE] === ATTRIBUTE_KEY_TYPE_IMAGE) {
                    console.log("Found an image without filename!");
                }
            });
        });
    }
  }


addBlock = (key) => {
    const newBlock = {
      blockId: uuidv4(),
      elements: [],
      settings: {
        alignTop: false,
        spacingY: 10,
        isTravelLogo: false
      }
    };
    this.setState(prevState => ({
      blocks: {
        ...prevState.blocks,
        [key]: [...prevState.blocks[key], newBlock]
      }
    }));
  };

  removeBlock = (key, blockId) => {
    this.setState(prevState => ({
      blocks: {
        ...prevState.blocks,
        [key]: prevState.blocks[key].filter(block => block.blockId !== blockId)
      }
    }));
  };

  toggleBlockCollapse = (blockId) => {
    this.setState(prevState => {
        const isOpen = !prevState.open[blockId];
        return {
            open: {
                ...prevState.open,
                [blockId]: isOpen,
            }
        };
    }, () => {
        this.handleTabOpen(this.state.open[blockId]);
    });
  };

  addElementToBlock = (blockId, key, element) => {
    const newElement = {
      ...element,
      key: uuidv4()
    };
    this.setState(prevState => ({
      blocks: {
        ...prevState.blocks,
        [key]: prevState.blocks[key].map(block => 
          block.blockId === blockId ? { ...block, elements: [...block.elements, newElement] } : block
        )
      },
    }));
  };

  removeElementFromBlock = (blockId, key, elementKey) => {
    this.setState(prevState => ({
      blocks: {
        ...prevState.blocks,
        [key]: prevState.blocks[key].map(block =>
          block.blockId === blockId ? { ...block, elements: block.elements.filter(element => element.key !== elementKey) } : block
        )
      }
    }));
  };


  onDragEndBlock = (result) => {
    const { source, destination } = result;

    if (!destination) return;

    const sourceBlocks = Array.from(this.state.blocks[source.droppableId]);
    const [movedBlock] = sourceBlocks.splice(source.index, 1);
    sourceBlocks.splice(destination.index, 0, movedBlock);

    this.setState(prevState => ({
      blocks: {
        ...prevState.blocks,
        [source.droppableId]: sourceBlocks
      }
    }));
  };

  renderBlockOptions(key) {
    return (
      <React.Fragment>
        <Row className={'mb-3'}>
          <Col>
            <Button className={`d-inline-block ${key}-block-btn`} color="primary" style={{ float: 'left' }} onClick={() => this.addBlock(key)}>
              <i className="fa fa-plus" />&nbsp; {key === ATTRIBUTE_KEY_FRONT ? 'Front' : 'Back'} Block
            </Button>
          </Col>
        </Row>
        <DragDropContext onDragEnd={this.onDragEndBlock}>
          <Droppable droppableId={key} type="block">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {this.state.blocks[key].map((block, index) => (
                  <Draggable isDragDisabled={this.state.isTabOpen} key={block.blockId + '-' +index} draggableId={block.blockId} index={index}>
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div className="theme-block">
                          <div className="text-small bg-white cursor-pointer" title="Block">
                            <Row>
                              <Col onClick={() => this.toggleBlockCollapse(block.blockId)}>
                                <div className="p-3 text-white" style={{ margin: 'auto' }}>
                                <div className="block-title">
                                  <svg className="icon w-20" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"/>
                                  </svg>
                                  Block
                                  </div>
                                </div>
                              </Col>
                              <div className='d-flex align-items-center ml-auto mr-3'>
                                <button title="Hide/Show. data remains visible on the card" className="btn-icon" onClick={() => this.toggleBlockVisibility(block.blockId, key)}>
                                  {this.isBlockVisible(block.blockId, key) ? (
                                      <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                      </svg>
                                  ) : (
                                      <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                      </svg>
                                  )}
                                </button>                         
                                <button className="btn-icon mr-3" onClick={() => this.removeBlock(key, block.blockId)}>
                                  <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                  </svg>
                                </button>
                              </div>
                            </Row>
                          </div>
                          <Collapse isOpen={this.state.open[block.blockId]}>
                            <CardBody>  
                              <div className="block-content">
                                {this.renderCardAttributes(block.blockId, key)}
                              </div>
                            </CardBody>
                          </Collapse>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </React.Fragment>
    );
  }


  onElementClick(blockId, elementIndex, side) {
    const { selectedElement, open } = this.state;
    const isBlockOpen = open[blockId];
    
    // Close any previously opened block if it’s different from the current block
    if (selectedElement && selectedElement.blockId !== blockId) {
        this.toggleBlockCollapse(selectedElement.blockId);
    }

    // Update the selected element
    this.setState({ selectedElement: { blockId, elementIndex, side } }, () => {
        // Open the new block if it’s not already open
        if (!isBlockOpen) {
            this.handleTabElementOpen(!isBlockOpen);
            this.toggleBlockCollapse(blockId);
        }
    });
}

gridToggle() {
  this.setState({ gridVisible: !this.state.gridVisible });
}

SnapToGridToggle(){
  this.setState({ snapToGrid: !this.state.snapToGrid });
}

getMediaImage(mediaId, mediaKey, imageKey, errorKey) {
    if (mediaId) {
      this.setState({[errorKey]: false})

      getMediaById(mediaId, (media) => {
        downloadMedia(media.file_path, (blob) => {
          var objectURL = URL.createObjectURL(blob);
          this.setState({ [imageKey]: objectURL});
        }, (error) => {
          console.log("DownloadMedia error", error)
          this.setState({ [errorKey]: true })
        })        
        this.setState({ [mediaKey]: media});
      }, (error) => {
        console.log("Error fetching media", error)
        this.setState( { [errorKey]: true })
      })
    }
  }

  onChangeCardAttributes(cardAttributes, callback) {
    this.setState({ blocks: cardAttributes, membershipEdited: true }, () => {
        if (callback) {
            callback();
        }
    });
  }  


   toggleAddElement = (blockId, key, value) => {
    if (!value) {
       this.setState({additionalOptions: []})
    }

    this.setState(prevState => ({
      showAddElement: {
        ...prevState.showAddElement,
        [blockId]: {
          ...prevState.showAddElement[blockId],
          [key]: !prevState.showAddElement[blockId]?.[key]
        }
      }
    }));

  };



onAddElement(blockId, elementOptions, key) {
    if (elementOptions.additionalOptions && elementOptions.additionalOptions.length > 0) {
        // if additionalOptions is available, show another select
        this.setState({additionalOptions: elementOptions.additionalOptions})
    } else {
        // no additionalOptions available, add card attribute by type
        this.addCardAttribute(blockId, elementOptions.value, key)
    }
}

addCardAttribute(blockId, type, key) {
    const newElement = {
        type,
        key: uuidv4(),
        font: 'OpenSans',
        fontweight: '400',
        fontsize: '24',
        fontstyle: 'normal',
        x: '0',
        y: '0',
        ...(type === ATTRIBUTE_KEY_TYPE_TEXT && { text: EXAMPLE_TEXT[ATTRIBUTE_KEY_TYPE_TEXT] }),
        ...(type === ATTRIBUTE_KEY_TYPE_IMAGE && { width: '162', height: '72', keepaspectratio: true })
      };

    this.setState(prevState => ({
    blocks: {
        ...prevState.blocks,
        [key]: prevState.blocks[key].map(block =>
        block.blockId === blockId ? { ...block, elements: [...block.elements, newElement] } : block
        )
    }
    }), () => {
      this.toggleAddElement(blockId, key, false);
      this.onChangeCardAttributes(this.state.blocks);
    });

}

onChangeCardArrayAttributes(e, key, index, side, blockId) { 
    const blocks = this.state.blocks;
    const blockIndex = blocks[side].findIndex(block => block.blockId === blockId);

    if (blockIndex !== -1) {
        this.willChangeCardAttributes(blocks, e, key, index, side, blockId);

        if (key === ATTRIBUTE_KEY_ENABLED && !e) {
            // item was disabled, remove it from array
            blocks[side][blockIndex].elements.splice(index, 1);
        } else {
            blocks[side][blockIndex].elements[index][key] = e;
        }       

        this.setState({ blocks }, () => {
             this.didChangeCardAttributes(blocks, key, index, side, blockId, blockIndex);             
        });
    }
    
}


async didChangeCardAttributes(blocks, key, index, side, blockId, blockIndex) {
    // when changing textalign, update new x-position
    if (key === ATTRIBUTE_KEY_TEXT_ALIGN || key === ATTRIBUTE_KEY_X || key === ATTRIBUTE_KEY_Y) {
        // let field = blocks[side][index]
        let field = blocks[side][blockIndex].elements[index]
       
        this.updateStartPosition(field, index, side, blockIndex)
    }
}

async willChangeCardAttributes(blocks, e, key, index, side, blockId) {

    const blockIndex = blocks[side].findIndex(block => block.blockId === blockId);
    if (blockIndex === -1) return;

    const element = blocks[side][blockIndex].elements[index];

    // when enabling keepaspectratio, set new width for the image to match the height with aspect ration
    if (key === ATTRIBUTE_KEY_KEEP_ASPECT_RATIO && e) {
      let height = element[ATTRIBUTE_KEY_IMAGE_HEIGHT];
      this.onChangeImageDimensions(height, ATTRIBUTE_KEY_IMAGE_HEIGHT, index, true, side, blockId);
    }

    // when selecting file, download the image and set correct dimensions
    if (key === ATTRIBUTE_KEY_FILE_NAME) {
      this.getBlobImage(e, (objectURL) => {
          let keepAspectProp = element[ATTRIBUTE_KEY_KEEP_ASPECT_RATIO] ?? true;
          if (keepAspectProp) {
              this.setImageDimensions(objectURL, index, side, blockId);
          }
      });
    }
}

getBlobImage(fileName, onSuccess) {
    downloadMedia(fileName, (blob) => {
        var objectURL = URL.createObjectURL(blob);
        let images = this.state.images
        images[fileName] = objectURL
        this.setState({images: images}, () => {
            if (onSuccess) {
                onSuccess(objectURL)
            }
        })
    }, (error) => {
        console.log("DownloadMedia error", error)
    })
}

async setImageDimensions(src, index, side, blockId) {
    let dimens = await getImageDimensions(src)
    if (dimens && dimens.height) {
        let height = Math.min(dimens.height, 500) // height of image, no more than 500
        this.onChangeImageDimensions(height, ATTRIBUTE_KEY_IMAGE_HEIGHT, index, true, side, blockId)
    }
}

async onChangeImageDimensions(e, key, index, keepAspect, side, blockId) {
  const blockIndex = this.state.blocks[side].findIndex(block => block.blockId === blockId);
  if (blockIndex === -1) return;

  const element = this.state.blocks[side][blockIndex].elements[index];
  let keepAspectProp = element[ATTRIBUTE_KEY_KEEP_ASPECT_RATIO] ?? true;

  if (keepAspectProp || keepAspect) {
      let file = element[ATTRIBUTE_KEY_FILE_NAME];
      if (file) {
          let src = this.state.images?.[file];
          if (src) {
              let dimens = await getImageDimensions(src);
              if (dimens && dimens.width && dimens.height) {
                  let aspectRatio = dimens.width / dimens.height;

                  // when editing width, also change the height to match aspect ratio
                  if (key === ATTRIBUTE_KEY_IMAGE_WIDTH) {
                      let newHeight = (e / aspectRatio).toFixed();
                      this.onChangeCardArrayAttributes(newHeight, ATTRIBUTE_KEY_IMAGE_HEIGHT, index, side, blockId);
                  }

                  // when editing height, also change the width to match aspect ratio
                  if (key === ATTRIBUTE_KEY_IMAGE_HEIGHT) {
                      let newWidth = (e * aspectRatio).toFixed();
                      this.onChangeCardArrayAttributes(newWidth, ATTRIBUTE_KEY_IMAGE_WIDTH, index, side, blockId);
                  }
              }
          }
      }
  }
  this.onChangeCardArrayAttributes(e, key, index, side, blockId);
}

updateStartPositions(side, fields) {
    if (!fields || fields.length <= 0) {
        return
    }

    var self = this
    for (var i = 0; i < fields.length; i++) {
        let field = fields[i]

        self.updateStartPosition(field, field?.elementIndex, side, field?.blockIndex)
    }
}

updateStartPosition(field, index, side, blockIndex) {
    var x = field?.hasOwnProperty(ATTRIBUTE_KEY_X) ? field[ATTRIBUTE_KEY_X] : 0
    var y = field?.hasOwnProperty(ATTRIBUTE_KEY_Y) ? field[ATTRIBUTE_KEY_Y] : 0
    
    // prefix
    var id = `card-attr-${side}-${blockIndex}-${index}`; // Include side in the id
    var element = document.getElementById(id);
    this.transformElement(element, field, x, y, side)
} 

// TODO push element towards the center when changing textAlign and the field goes outside the card area?
transformElement(element, field, x, y, side) {
    if (!element) return { x, y };
    var translatedX = x
    
    if (field?.[ATTRIBUTE_KEY_TEXT_ALIGN]) {
      const width = element.offsetWidth || element.clientWidth;
      if (field[ATTRIBUTE_KEY_TEXT_ALIGN] === ATTRIBUTE_KEY_TEXT_ALIGN_CENTER) {
          translatedX = x - (width / 2);
      } else if (field[ATTRIBUTE_KEY_TEXT_ALIGN] === ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT) {
          translatedX = x - width;
      }
    }

    element.style.transform = 'translate(' + translatedX + 'px, ' + y + 'px)'
    element.setAttribute('data-x', x)
    element.setAttribute('data-y', y)
    element.setAttribute('data-side', side);
 
    return { x: translatedX, y: y }
}

 
renderButtonOptions(blockId, key) {
    return <React.Fragment>
          
              <Row className={'mb-3'}>
                <Col>

                    {this.state.showAddElement[blockId]?.[key] ? 
                        <React.Fragment>
                            <Label for="add_element">Add new element</Label>
                            <Select options={ELEMENT_OPTIONS} onChange={(e) => this.onAddElement(blockId, e, key)} placeholder={"Select element..."} /> 
                        </React.Fragment> 
                        : 
                        <Button className='d-inline-block' color="primary" style={{float: 'left'}} onClick={(e) => this.toggleAddElement(blockId, key, true)}>
                            <i className="fa fa-plus" />&nbsp; {key === ATTRIBUTE_KEY_FRONT ? 'Front' : 'Back'} Element
                        </Button>
                        }

                    {this.state.additionalOptions && this.state.additionalOptions.length > 0 ? <React.Fragment>
                            <Label for="add_field">Add new field</Label>
                            <Select options={DATA_FIELD_OPTIONS} onChange={(e) => this.onAddElement(blockId, e, key)} placeholder={"Select field..."} /> 
                        </React.Fragment> : null}

                    {this.state.showAddElement[blockId]?.[key] ? 
                        <Button className='d-inline-block mr-2 mt-2' color="danger" onClick={(e) => this.toggleAddElement(blockId, key, false)}>Cancel</Button> : null}
                </Col>
            </Row>
  </React.Fragment>
}


toggleCardAttributes = (side) => {
    this.setState((prevState) => ({
        showCardAttributes: {
            ...prevState.showCardAttributes,
            [side]: !prevState.showCardAttributes[side]
        }
    }));
};

renderCardAttributes(blockId, key) {
  const block = this.state.blocks[key].find(block => block.blockId === blockId);
  const settings = block?.settings || { alignTop: false, spacingY: 25, isTravelLogo: false };

    return <div className={'mt-0 elementsArea'}>
        <Row>
          <Col className="col-8">
            {this.renderButtonOptions(blockId, key)} 
          </Col>
          <Col className="d-flex justify-content-end col-4">
                <div className="  d-flex align-items-start">
                    <div className="settings-group d-flex align-items-center">

                        {/* Travel logo Toggle */}
                        <button title="Is travel logo" 
                          className={`btn-icon mr-1  ${settings.isTravelLogo ? 'active' : ''}`} 
                          onClick={() => this.handleBlockSettingsChange(blockId, key, 'isTravelLogo', !settings.isTravelLogo)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 100 50">
                            <text x="10" y="50" fill="currentColor" font-size="75" font-family="Arial, sans-serif" font-weight="bold">SL</text>
                          </svg> 
                        </button> 

                        {/* Align Top Toggle */}
                        <button title="Align elements to top" 
                          className={`btn-icon mr-1  ${settings.alignTop ? 'active' : ''}`} 
                          onClick={() => this.handleBlockSettingsChange(blockId, key, 'alignTop', !settings.alignTop)}>
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon" viewBox="0 0 20 20">
                            <path fill="currentColor" d="M5.5 3A2.5 2.5 0 0 0 3 5.5v9A2.5 2.5 0 0 0 5.5 17h9a2.5 2.5 0 0 0 2.5-2.5v-9A2.5 2.5 0 0 0 14.5 3zM4 5.5A1.5 1.5 0 0 1 5.5 4h9A1.5 1.5 0 0 1 16 5.5v9a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 4 14.5zm2.5.5a.5.5 0 0 0 0 1h7a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1z"/>
                          </svg> 
                        </button>      

                        {/* Spacing Input */}
                        <div className="setting-item">
                            <div className="d-flex align-items-center spacing-control">
                                <div className="input-group input-group-sm mini-font" style={{ width: '100px' }}>
                                    <input
                                        type="number"
                                        className="form-control form-control-sm h-auto"
                                        title="Spacing in px between each element"
                                        value={settings.spacingY}
                                        onChange={(e) => this.handleBlockSettingsChange(blockId, key, 'spacingY', parseInt(e.target.value) || 0)}
                                        min="0"
                                        max="100"
                                        disabled={!settings.alignTop}
                                    />
                                    <span title="Spacing in px between each element" className="input-group-text mini-font">px</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        {this.state.showCardAttributes[key] ? this.renderDraggableFields(blockId, key) : null}
    </div>
}

handleBlockSettingsChange = (blockId, key, setting, value) => {
  this.setState(prevState => ({
      blocks: {
          ...prevState.blocks,
          [key]: prevState.blocks[key].map(block => 
              block.blockId === blockId 
                  ? { 
                      ...block, 
                      settings: {
                          ...block.settings,
                          [setting]: value
                      }
                  } 
                  : block
          )
      }
  }));
};

renderJSONView() {
    return <React.Fragment>
        <Row>
            <Col className='mb-5'>
            <Button style={{width: '50%', float: 'right'}} color="primary" onClick={() => this.toggleShowJson()}>
                <i className="fa fa-code"></i>&nbsp;{this.state.showJson? 'Hide ' :'Show ' } JSON
            </Button>
            </Col>
        </Row>

        <Row>
            <Collapse style={{width: '100%'}} isOpen={this.state.showJson}>
                <pre className="preCode_json">{formatQuery(this.state.blocks, 'json')}</pre><span className="queryTag">JSON</span>
            </Collapse>
        </Row>

    </React.Fragment>
}

toggleShowJson() {
    this.setState({showJson: !this.state.showJson})
}


onDragEndElement = (result, side, blockId) => {
  const { source, destination } = result;

  if (!destination) return;

  const block = this.state.blocks[side].find(block => block.blockId === blockId);

  if (block) {
    const elements = Array.from(block.elements);
    const [movedElement] = elements.splice(source.index, 1);
    elements.splice(destination.index, 0, movedElement);

    this.setState(prevState => ({
      blocks: {
        ...prevState.blocks,
        [side]: prevState.blocks[side].map(block =>
          block.blockId === blockId ? { ...block, elements } : block
        )
      }
    }));
  }
};


renderDraggableFields(blockId, key) {
    return <DragDropContext onDragEnd={(result) =>this.onDragEndElement(result, key, blockId)}>
        <Droppable droppableId={`card-attrs-droppable-${key}`}>
            {(provided, snapshot) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                        {this.renderFields(blockId, key)}
                        {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
}


getField(field, index, side, blockId) {
    const commonProps = {
        field,
        index,
        segments: this.state.segments,
        onChangeCardArrayAttributes: this.onChangeCardArrayAttributes.bind(this),
        getFieldAttribute: this.getFieldAttribute.bind(this),
        handleTabElementOpen: this.handleTabElementOpen,
        side,
        blockId,
        toggleElementVisibility: this.toggleElementVisibility,
        isVisible: !this.state.elementVisibility[blockId]?.[index],
        isSelected: this.state.selectedElement?.blockId === blockId && this.state.selectedElement?.elementIndex === index
    };

    switch (field[ATTRIBUTE_KEY_TYPE]) {

        case ATTRIBUTE_KEY_TYPE_ORGANIZATION:
            return <OrganizationField {...commonProps} />

        case ATTRIBUTE_KEY_TYPE_TEXT:
            return <TextField {...commonProps} />

        case ATTRIBUTE_KEY_TYPE_IMAGE:
            return <ImageField 
                    {...commonProps}
                    files={this.props.files}
                    onChangeImageDimensions={this.onChangeImageDimensions.bind(this)}
                />

        default:
            return <DataField {...commonProps} />
    }
}

handleTabOpen = (isOpen) => {
    this.setState({ isTabOpen: isOpen });
};

handleTabElementOpen = (isOpen) => {
    this.setState({ isTabElementOpen: isOpen });
};

renderFields(blockId, key) {
    const block = this.state.blocks[key].find(block => block.blockId === blockId);
    if (!block) return null;
    const fields = block.elements;

    //  let fields = this.state.card_attributes && this.state.card_attributes?.[key]
    if (fields && fields.length > 0) {
        return fields.map((field, index) => {

            if (!field || !field?.[ATTRIBUTE_KEY_TYPE]) {
                console.log("Invalid field type!", field)
                return null
            }

            var element = this.getField(field, index, key, blockId)

            return <Draggable isDragDisabled={this.state.isTabElementOpen} 
                              draggableId={field?.[ATTRIBUTE_KEY_TYPE] + '-attr-' + field?.[ATTRIBUTE_KEY_KEY]} 
                              index={index} 
                              key={field?.[ATTRIBUTE_KEY_TYPE] + '-attr-' + field?.[ATTRIBUTE_KEY_KEY]}>
                        {(provided, snapshot) => (
                            <div ref={provided.innerRef} 
                                {...provided.draggableProps} 
                                {...provided.dragHandleProps}>
                                {element}
                            </div>
                        )}
                    </Draggable>
        })
    }
}

getFieldAttribute(field, attribute, defaultValue) {
    return field?.[attribute] ?? defaultValue
}

 
handleMediaSelect = (mediaId, formId) => {
    if(formId === MEDIA_FORM_ID_FRONT){
        this.setState({ preview_front_image_media_id: mediaId }, 
            () => {
                this.getMediaImage(mediaId, 'frontMedia', 'frontBlob', 'frontError');
            }
          );
    }
    if(formId === MEDIA_FORM_ID_BACK){
        this.setState({ preview_back_image_media_id: mediaId }, 
            () => {
                this.getMediaImage(mediaId, 'backMedia', 'backBlob', 'backError');
            }
          );
    }
};
 

renderMediaUploadForm(formId, renderUpload, membershipMediaKey, showCancel) {
    return <React.Fragment>
        <div>
        <div className={'mx-5 mt-5 mini-font'}>Recommended size {MEMBERSHIP_CARD_WIDTH_DEFAULT}x{MEMBERSHIP_CARD_HEIGHT_DEFAULT}px</div>
        <MediaSelect files={this.state.files} item={this.state.organization_id} index={0} itemId={this.state.organization_id}
                            onChange={(id, item, index) => { this.handleMediaSelect(id, formId) }}  />
        </div>
        {showCancel ? <Button className='d-inline-block mt-5' color="primary" onClick={(e) => this.toggleMediaUpload(renderUpload)}>Cancel</Button> : null}
        
    </React.Fragment>
}

toggleMediaUpload(renderUpload) {
    let state = this.state
    state[renderUpload] = !state[renderUpload]
    this.setState(state)
}

renderImage(mediaId, media, imageUrl, side, formId, uploadTitle, error, renderUpload, membershipMediaKey) {
 
    if (mediaId || !this.state.isEditMode || this.state.themeId) {
        if (error) {
            return <div className={'mb-5 mt-5'} style={{textAlign: 'center'}}>
                <div className={'mb-2'} style={{textAlign: 'center'}}>
                    {this.state[renderUpload] ? this.renderMediaUploadForm(formId, renderUpload, membershipMediaKey, true) : <Button className='d-inline-block mt-1' color="primary" onClick={(e) => this.toggleMediaUpload(renderUpload)}>Change image</Button>}
                </div>
                <Card className={'mb-3'} style={{width: MEMBERSHIP_CARD_WIDTH_DEFAULT + 'px', height: MEMBERSHIP_CARD_HEIGHT_DEFAULT + 'px', margin: 'auto'}}>
                    <div style={{margin: 'auto'}}>Card error</div>    
                </Card> 
            </div>
        } else if (media && imageUrl) {
            return <div className={'mb-5 mt-5'} >
                <div className={'mb-1 bold-600'} style={{textAlign: 'center'}}>{uploadTitle}</div>
                <div className={'mb-2 list-item-text'} style={{textAlign: 'center'}}>{media.file_name}</div>
                <div className={'mb-2'} style={{textAlign: 'center'}}>
                    {this.state[renderUpload] ? this.renderMediaUploadForm(formId, renderUpload, membershipMediaKey, true) : <Button className='d-inline-block mt-1' color="primary" onClick={(e) => this.toggleMediaUpload(renderUpload)}>Change image</Button>}
                </div>

                <ReactableCard 
                    transformElement={this.transformElement.bind(this)} 
                    updateStartPositions={this.updateStartPositions.bind(this, side )}  
                    imageUrl={imageUrl} 
                    alt={uploadTitle} 
                    images={this.state.images} 
                    fields={this.state.blocks[side].flatMap( (block,bIndex) => 
                      block.elements?.map((element, index) => ({ 
                          ...element, 
                          blockId: block.blockId, 
                          elementIndex: index,
                          blockIndex: bIndex,
                          isVisible: !this.state.elementVisibility[block.blockId]?.[index] 
                      }))
                    )}         
                    side={side} 
                    onChangeCardArrayAttributes={this.onChangeCardArrayAttributes.bind(this)} 
                    onElementClick={this.onElementClick}
                    gridVisible={this.state.gridVisible}
                    snapToGrid={this.state.snapToGrid}
                /> 

            </div>
        } else {
            return <Card className={'mb-3'} style={{width: MEMBERSHIP_CARD_WIDTH_DEFAULT + 'px', height: MEMBERSHIP_CARD_HEIGHT_DEFAULT + 'px', margin: 'auto'}}>
                <div style={{margin: 'auto'}}>
                  <span className="spinner-border spinner-border-md" role="status" aria-hidden="true"></span>
                </div>
            </Card> 
        }
    } else {
        return <div className={'mb-5 mt-5'} style={{textAlign: 'center'}}>
            <div className={'mb-2 bold-600'}>{uploadTitle}</div>
            <Card style={{width: MEMBERSHIP_CARD_WIDTH_DEFAULT + 'px', height: MEMBERSHIP_CARD_HEIGHT_DEFAULT + 'px', margin: 'auto'}}>
                {this.renderMediaUploadForm(formId, renderUpload, membershipMediaKey, false)}
            </Card>
        </div>
    }
}


renderAlerts() {
  if (this.state) {
      if (this.state.themeSaved) {
          return <UncontrolledAlert color="success">
              Theme saved!
          </UncontrolledAlert>
      } else if (this.state.themeSavedError) {
          return <UncontrolledAlert color="danger">
              Error saving theme, error: {this.state.themeSavedError}
          </UncontrolledAlert>
      }
  }
}

handleDeleteTheme(){
  if (window.confirm('Are you sure you want to delete this theme?')) {
    const { history } = this.props;

    const onSuccess = (response) => {
      history.push('/themes');
    };

    const onError = (error) => {
      console.error('Error deleting theme:', error);
      var errorMessage = parseErrorMessage(error)
      this.setState({themeSavedError: errorMessage})
    };

    deleteTheme(this.state.themeId, onSuccess, onError);
  }
}

render() {
  const { isEditMode, themeId } = this.state;

  return <React.Fragment>

    <header className="app-header-page justify-content-end">
      {isEditMode && (
        <Button className="ml-3" color="danger" outline={true} onClick={this.handleDeleteTheme}>
          <i className="fa fa-trash"></i>&nbsp;Delete
        </Button>
      )}
      <Button className="ml-3" color="primary" outline={true} onClick={() => this.handleClone(themeId)}>
          Clone
      </Button>
      <Button className="ml-3" color="success" onClick={this.saveTheme}>
        <i className="fa fa-check"></i>&nbsp;{isEditMode ? 'Save' : 'Create'}
      </Button>
    </header> 

    {this.renderAlerts()}

  <Card>
    <div className="container-fluid">
      
      <React.Fragment>
          <Row>
              <Col sm="8" className='pt-4'>
                  <FormGroup>
                      <Label for="name">Name</Label>
                      <Input type="text" name="name" id="name"
                        defaultValue={this.state.name || ''}
                        onChange={this.handleNameChange} 
                        required/>
                  </FormGroup>

                  {this.renderImage(
                      this.state.preview_front_image_media_id, 
                      this.state.frontMedia, 
                      this.state.frontBlob, 
                      ATTRIBUTE_KEY_FRONT, 
                      MEDIA_FORM_ID_FRONT, 
                      'Preview Front image', 
                      this.state.frontError, 
                      'renderFrontMediaUpload', 
                      'front_image_media_id')}
                  
                  <br/>
                  <hr className='m-0'/>
                  <br/>

                  {this.renderImage(
                    this.state.preview_back_image_media_id, 
                    this.state.backMedia, 
                    this.state.backBlob,
                    ATTRIBUTE_KEY_BACK, 
                    MEDIA_FORM_ID_BACK, 
                    'Preview Back image', 
                    this.state.backError, 
                    'renderBackMediaUpload', 
                    'back_image_media_id')}
              </Col>

              <Col sm="4" className='bg-light pt-5 border border-light'>
                <div className='sidebar-sticky'>
                    <div className='bg-white mb-3 block'>
                      <button title="Toggle Grid" className={`btn-icon ${this.state.gridVisible ? 'active' : ''}`} onClick={() => this.gridToggle() }>
                        <svg className="icon w-20" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
                            <rect width="18" height="18" x="3" y="3" rx="2"/><path d="M3 12h18m-9-9v18"/>
                          </g>
                        </svg>
                      </button>

                      <button title="Toggle Snap to Grid" className={`btn-icon ml-1 ${this.state.snapToGrid ? 'active' : ''}`} onClick={() => this.SnapToGridToggle() }>
                        <svg className="icon w-20" fill="none" stroke="currentColor" viewBox="0 0 2048 2048">
                          <path fill="currentColor" d="M384 0v128H256V0zM128 256v128H0V256zm0-256v128H0V0zm0 512v128H0V512zm768 256V640h128v128zm0-768v128H768V0zm128 384v128H896V384zM1408 0v128h-128V0zm256 0v128h-128V0zM0 1920v-128h128v128zM1024 256H896V128h128zM1152 0v128h-128V0zM640 0v128H512V0zm1152 640V512h128v128zM512 1920v-128h128v128zM768 896v128H640V896zm1024 0V768h128v1152H768v-128h128V896zm0 896v-768h-768v768zm0-1792h128v128h-128zm0 384V256h128v128zM0 1664v-128h128v128zm512-768v128H384V896zM0 1152v-128h128v128zm0 256v-128h128v128zm0-640h128v128H0zm256 128v128H128V896zm0 1024v-128h128v128z"/>
                        </svg>
                      </button>
                    </div>

                   

                    {this.renderBlockOptions(ATTRIBUTE_KEY_FRONT)}
                    {this.renderBlockOptions(ATTRIBUTE_KEY_BACK)}

                    <div className="mt-5">
                        {this.renderJSONView()}
                    </div>
                  </div>
              </Col>
          </Row>

      </React.Fragment>

  </div>
  </Card>

</React.Fragment>
}

}


export default Theme;
