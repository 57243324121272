import React, { Component } from 'react';
import {
    Button,  CardBody, Form, FormGroup, Input, Label,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { deleteMember } from '../../../vibe/helpers/apiHelper';
class MemberSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedback_message: '',
            delete_confirmation: '',
            isError: {
                feedback_message: '',
                delete_confirmation: '',
            },
            modal: false
        }

        this.toggle = this.toggle.bind(this);
    }

    componentDidMount() {

    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onDeleteMember() {
        deleteMember(this.props.member.id, this.state.feedback_message, () => {
            this.setState({ memberDeleteSuccess: 'Success deleting member!' })
            this.props.history.push('/members')
        }, (error) => {
            this.setState({ memberDeleteError: 'Error deleting member! ' + error })
        })
    }

    onChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let isError = { ...this.state.isError };

        switch (name) {
            case "feedback_message":
                isError.feedback_message =
                    value.length === 0 ? "The message feedback is required" : "";
                break;
            case "delete_confirmation":
                isError.delete_confirmation = 'DELETE'
                    ? ""
                    : "Delete confirmation is invalid";
                break;
            default:
                break;
        }

        this.setState({
            isError,
            [name]: value
        })
    }
    render() {
        const { isError, delete_confirmation, feedback_message } = this.state;

        return <CardBody className='py-4'>
            <h5 className="bold-600">Delete Member</h5>
            <Form>
                <FormGroup>
                    <Input invalid={isError.feedback_message.length !== 0} type="textarea" name="feedback_message" id="feedback_message" placeholder="Enter a feedback message!"
                        onChange={this.onChange} />
                    {isError.feedback_message.length > 0 && (
                        <span className="invalid-feedback">The feedback message is required!</span>
                    )}
                </FormGroup>
                <FormGroup>
                    <Label for="delete_confirmation">
                        In order to permanently delete the member you must enter "DELETE"
                    on the input below and click Delete.</Label>
                    <Input type="text" name="delete_confirmation" id="delete_confirmation" placeholder="DELETE" autoComplete="off" required
                        onChange={this.onChange}
                    />
                </FormGroup>
                <Button color="danger" onClick={this.toggle} disabled={feedback_message.length === 0 || delete_confirmation !== 'DELETE'}><i className="fa fa-trash"></i>&nbsp;Delete</Button>
            </Form>
            <Modal isOpen={this.state.modal} toggle={this.toggle} className="deleteModal">
                <ModalHeader toggle={this.toggle}></ModalHeader>
                <ModalBody>
                    <h4 className="text-center text-danger mb-5">Delete Member with ID {this.props.member && this.props.member.id}</h4>

                    <p className="text-muted pt-4"><strong>Warning this cannot be undone & You will not be able to recover it!</strong></p>
                    <ul className="text-muted list-item-text">
                        <li>All Membership for this member will be deleted.</li>
                        <li>All Sudent data will be deleted.</li>
                        <li>All Plastic card orders data will be deleted.</li>
                        <li>All Transactions log related to this member will be deleted.</li>
                    </ul>

                    <p className="text-muted">Notice you will be redirected to all members page after click on delete!</p>
                    <p className="text-center text-danger">{
                        this.state.memberDeleteError && this.state.memberDeleteError
                    }</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => this.onDeleteMember()}>Yes, Delete it</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </CardBody>
    }
}


export default MemberSettings;
