import React from 'react';
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';

export const CUSTOM_RANGE_ID = 8

class DateIntervalPicker extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            startDate: new Date(),
            endDate: null,
            isOpen: false,
            selectedOption: -1,
            pendingSelectedOption: -1
        }
        this.toggleView = this.toggleView.bind(this)

        if (this.props?.selectOption && this.props?.options?.length > 0) {
            const option = this.props.options.find((dateOption) => dateOption.id === this.props.selectOption)
            if (option) {
                var formattedDates = {from: option.from, to: option.to}
                this.props.onChange(formattedDates, option.label)
                this.state.startDate = option.from
                this.state.endDate = option.to
                this.state.pendingStartDate = option.from
                this.state.pendingEndDate = option.to
                this.state.selectedOption = option.id
                this.state.pendingSelectedOption = option.id
            }
        }

        const today = new Date();
        const maxDate = this.formatDate(today)
        this.state.maxDate = maxDate
    }

    isCustomRange(option) {
        return option?.id === CUSTOM_RANGE_ID
    }

    initState() {
        const option = this.props.options.find((dateOption) => dateOption.id === this.state.selectedOption)
        if (option) {
            if (this.isCustomRange(option)) {
                this.setState({pendingStartDate: this.state.startDate, pendingEndDate: this.state.endDate})
            } else {
                this.setState({startDate: option.from, endDate: option.to, pendingStartDate: option.from, pendingEndDate: option.to, pendingSelectedOption: option.id})
            }
        }
    }

    formatDate(date) {
        if (typeof date !== 'string') {
            const offset = date.getTimezoneOffset()
            date = new Date(date.getTime() - (offset * 60 * 1000))
            date = date.toISOString()
        }
        date = date.split('T')[0]
        return date
    }

    onChange(dates) {
        this.selectCustomRange()
        const [start, end] = dates;
        this.setState({pendingStartDate: start, pendingEndDate: end})
    }

    onCancelClick() {
        this.onClose()
    }

    applyButtonEnabled() {
        return this.state.pendingSelectedOption === CUSTOM_RANGE_ID && this.state.pendingStartDate && this.state.pendingEndDate
    }

    onApplyClick() {
        this.setState({selectedOption: this.state.pendingSelectedOption, startDate: this.state.pendingStartDate, endDate: this.state.pendingEndDate}, () => {
            this.applyDateRange()
        })
    }

    applyDateRange() {
        var formattedDates = {from: null, to: null}

        if (this.state.startDate) {
            formattedDates.from = this.formatDate(this.state.startDate)
        }

        if (this.state.endDate) {
            formattedDates.to = this.formatDate(this.state.endDate)
        }
        this.props.onChange(formattedDates, null)
        this.onClose()
    }

    onOpen() {
        this.initState()
        this.setState({isOpen: true})
    }

    onClose() {
        this.setState({isOpen: false})
    }

    render() {
        return <FormGroup>
            <Label for={this.props.label}>{this.props.label}</Label>
            {this.renderDatePickerWithModal()}
        </FormGroup>
    }

    selectCustomRange() {
        this.setState({pendingSelectedOption: CUSTOM_RANGE_ID})
    }

    onOptionClick(option) {
        if (this.isCustomRange(option)) {
            this.selectCustomRange()
            this.setState({pendingStartDate: this.state.startDate, pendingEndDate: this.state.endDate})
            return
        }

        var formattedDates = {from: option.from, to: option.to}

        if (option.from) {
            formattedDates.from = this.formatDate(option.from)
        }

        if (option.to) {
            formattedDates.to = this.formatDate(option.to)
        }

        this.props.onChange(formattedDates, option.label)
        this.setState({startDate: option.from, endDate: option.to, selectedOption: option.id, isOpen: false})
    }

    getDatePicker(inline, pending) {
        return <span className='date-interval-picker-date-wrapper'>
            <DatePicker monthsShown={2} maxDate={this.state.maxDate} showWeekNumbers inline={inline} showIcon={true} onCalendarClose={() => this.onClose()} onCalendarOpen={() => this.onOpen()} dateFormat={this.props.dateFormat ?? "yyyy-MM-dd"} selectsRange={true} selected={this.getStartDate(pending)} startDate={this.getStartDate(pending)} endDate={this.getEndDate(pending)} onChange={(dates) => this.onChange(dates)} />
        </span>
    }

    getStartDate(pending) {
        if (pending) {
            return this.state.pendingStartDate
        }
        return this.state.startDate
    }

    getEndDate(pending) {
        if (pending) {
            return this.state.pendingEndDate
        }
        return this.state.endDate
    }

    renderDatePickerWithModal() {
       if (this.state.isOpen) {
           return <>
            {this.getDatePicker(false, false)}
            <Modal size="lg" fade={false} isOpen={this.state.isOpen} toggle={this.toggleView} className="viewModal">
                <ModalHeader toggle={this.toggleView}>Date interval</ModalHeader>
                <ModalBody>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ marginRight: '40px' }}>
                        {this.props.options.map((option, index) => (
                            <div key={index} className='date-interval-picker-date-option list-item-text-small mb-2' style={{ background: option.id === this.state.pendingSelectedOption ? '#216ba5': '#f0f0f0', color: option.id === this.state.pendingSelectedOption ? 'white': 'black', marginBottom: '5px', cursor: 'pointer', padding: '5px 40px 5px 5px' }} onClick={() => this.onOptionClick(option)}>
                                {option.label}
                            </div>
                        ))}
                        </div>

                        <div style={{ flex: 1 }}>
                            {this.getDatePicker(true, true)}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" disabled={!this.applyButtonEnabled()} onClick={(e) => this.onApplyClick()}>Apply</Button>
                    <Button color="secondary" onClick={(e) => this.onCancelClick()}>Cancel</Button>
                </ModalFooter>
            </Modal></>
       }


       return this.getDatePicker(false, false)
    }

    toggleView() {
        this.setState(prevState => ({
            isOpen: !prevState.isOpen
        }));
    }

}

export default DateIntervalPicker