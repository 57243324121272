import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import { Doughnut, Bar, Line } from 'react-chartjs-2';
import { getMembersCount, getMembersCountLast6Months, getDeviceTokenCount, deviceTokenCountLast6Months, getCurrentUser } from '../../vibe/helpers/apiHelper';
import { getAccessToken } from '../../views/pages/login/Token.js';
import { isSuperUser } from '../../vibe/helpers/permissionHelper.js';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      membersCount: '',
      memberCountMale: '',
      memberCountFemale: '',
      androidCount: false,
      iosCount: false
    }
  }

  componentDidMount() {
    this.onMembersCount()
    getCurrentUser((user) => {
      this.setState({ user: user },
        () => {
          this.fetchUserData();
        })
      // if (isSuperUser(user)) {
      if (isSuperUser(user)) {
        this.getDeviceTokenCount()
      }
      // }
    }, (error) => {

    })
  }

  fetchUserData = async () => {
    try {
      const user = this.state.user;
      if (user) {
        switch (user.role) {
          case 'VerifyStudent':
            this.props.history.push('/verify-student');
            break;
          case 'AdminVerifyStudent':
            this.props.history.push('/verify-student');
            break;
          case 'Scanner':
            this.props.history.push('/ticket-scanner');
            break;
          case 'Financial':
            this.props.history.push('/payouts/all');
            break;
          default:
        }
      }
    } catch (error) {
    }
  };


  async getDeviceTokenCount() {
    getDeviceTokenCount('Android', (data) => {
      this.setState({ deviceTokenCountAndroid: data.count, androidCount: true })
    }, (error) => {
      this.setState({ deviceTokenCountAndroid: error.message })
    })

    getDeviceTokenCount('iOS', (data) => {
      this.setState({ deviceTokenCountIOS: data.count, iosCount: true })
    }, (error) => {
      this.setState({ deviceTokenCountIOS: error.message })
    })

    deviceTokenCountLast6Months((data) => {
      var months = [];
      var totals = [];
      var years = [];
      data.forEach((value) => {
        months.push(value.month)
        years.push(value.year)
        totals.push(value.total)
      });
      this.setState({ deviceTokenCount6Months: months, deviceTokenCount6MonthsTotal: totals, deviceTokenCount6Years: years })
    }, (error) => {
      //this.setState({ memberCount6Months: error.message, memberCount6MonthsTotal: error.message , memberCount6Years : error.message})
    })

  }

  async onMembersCount() {
    var accessToken = await getAccessToken()
    if (accessToken) {
      getMembersCount('', (data) => {
        this.setState({ membersCount: this.formatNumber(data.count) })
      }, (error) => {
        this.setState({ membersCount: error.message })
      })


      var today = new Date()
      var priorDate = new Date().setDate(today.getDate() - 30)

      getMembersCount('?where={"created_at": {"gt": ' + priorDate + '  }}', (data) => {
        this.setState({ membersCountLastMonth: this.formatNumber(data.count) })
      }, (error) => {
        this.setState({ membersCountLastMonth: error.message })
      })

      getMembersCount('?where={"status": {"eq": "A"}}', (data) => {
        this.setState({ memberCountActive: this.formatNumber(data.count) })
      }, (error) => {
        this.setState({ memberCountActive: error.message })
      })

      getMembersCount('?where={"status": {"eq": "N"}}', (data) => {
        this.setState({ memberCountInactive: this.formatNumber(data.count) })
      }, (error) => {
        this.setState({ memberCountInactive: error.message })
      })

      getMembersCount('?where={"gender": {"eq": "M"}}', (data) => {
        this.setState({ memberCountMale: data.count })
      }, (error) => {
        this.setState({ memberCountMale: error.message })
      })
      getMembersCount('?where={"gender": {"eq": "K"}}', (data) => {
        this.setState({ memberCountFemale: data.count })
      }, (error) => {
        this.setState({ memberCountFemale: error.message })
      })

      getMembersCountLast6Months((data) => {
        var months = [];
        var totals = [];
        var years = [];
        data.forEach((value) => {
          months.push(value.month)
          years.push(value.year)
          totals.push(value.total)
        });
        this.setState({ memberCount6Months: months, memberCount6MonthsTotal: totals, memberCount6Years: years })
      }, (error) => {
        //this.setState({ memberCount6Months: error.message, memberCount6MonthsTotal: error.message , memberCount6Years : error.message})
      })
    } else {
      // Recall same function if no token
      setTimeout(() => {
        this.onMembersCount()
      }, 50);
    }
  }

  formatNumber(number) {
    if (number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }

  welcomeMsg() {
    var today = new Date()
    var curHr = today.getHours()

    if (curHr < 12) {
      return 'Good morning!'
    } else if (curHr < 18) {
      return 'Good afternoon!'
    } else {
      return 'Good evening!'
    }
  }

  renderPushNotificationData(chartColors) {

    if (!isSuperUser(this.state.user)) {
      return null
    }

    // if (isSuperUser(this.state.user)) {
    var self = this
    const deviceTokenDonutData = {
      labels: ['iOS', 'Android'],
      datasets: [
        {
          data: [this.state.deviceTokenCountIOS, this.state.deviceTokenCountAndroid],
          backgroundColor: [
            chartColors.blue,
            chartColors.purple
          ],
          hoverBackgroundColor: [
            chartColors.blueShade,
            chartColors.purpleShade
          ]
        }
      ]
    };

    const deviceTokenLineChartData = {
      data: {
        labels: this.state.deviceTokenCount6Months,
        datasets: [
          {
            barPercentage: 0.70,
            maxBarThickness: 70,
            label: '# of Device tokens',
            data: this.state.deviceTokenCount6MonthsTotal,
            backgroundColor: 'rgb(255, 99, 132)',
            borderColor: 'rgba(255, 99, 132, 0.2)',
            fill: false,
            borderWidth: 2
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: false
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function (item, data) {
              return self.state.deviceTokenCount6Months[item[0].index] + ' ' + self.state.deviceTokenCount6Years[item[0].index]
            }
          },
        }
      }
    }

    return <React.Fragment>
      <div className="mb-2">Push notifications</div>

      <Row>
        <Col md={8} sm={12}>
          <Card>
            <CardHeader>Device Token Growth</CardHeader>
            <CardBody>
              <Line
                data={deviceTokenLineChartData.data}
                options={deviceTokenLineChartData.options}
              />
            </CardBody>
          </Card>
        </Col>
        <Col md={4} sm={12}>
          <Card>
            <CardHeader>Device Type</CardHeader>
            <CardBody>
              {this.state.androidCount && this.state.iosCount ?
                <div>
                  Total: {parseInt(this.state.deviceTokenCountIOS ?? 0) + parseInt(this.state.deviceTokenCountAndroid ?? 0)}
                </div> : null
              }
              <Doughnut
                data={deviceTokenDonutData}
                height={334}
                legend={{ display: true }}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>

    // } else {
    //   return null
    // }
  }

  render() {
    var self = this
    const chartColors = {
      blue: 'rgb(68, 159, 238)',
      purple: 'rgb(153, 102, 255)',

      blueShade: 'rgb(23, 139, 234)',
      purpleShade: 'rgb(113, 75, 191)',
    };

    const donutData = {
      labels: ['Male', 'Female'],
      datasets: [
        {
          data: [this.state.memberCountMale, this.state.memberCountFemale],
          backgroundColor: [
            chartColors.blue,
            chartColors.purple
          ],
          hoverBackgroundColor: [
            chartColors.blueShade,
            chartColors.purpleShade
          ]
        }
      ]
    };

    const bar = {
      data: {
        labels: this.state.memberCount6Months,
        datasets: [
          {
            barPercentage: 0.70,
            maxBarThickness: 70,
            label: '# of Members',
            data: this.state.memberCount6MonthsTotal,
            borderColor: [
              'rgba(255, 99, 132, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(255, 206, 86, 1)',
              'rgba(75, 192, 192, 1)',
              'rgba(153, 102, 255, 1)',
              'rgba(255, 159, 64, 1)',
            ],
            backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(255, 206, 86, 0.2)',
              'rgba(75, 192, 192, 0.2)',
              'rgba(153, 102, 255, 0.2)',
              'rgba(255, 159, 64, 0.2)',
            ],
            borderWidth: 2
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        legend: {
          display: false
        },
        tooltips: {
          mode: 'label',
          callbacks: {
            title: function (item, data) {
              return self.state.memberCount6Months[item[0].index] + ' ' + self.state.memberCount6Years[item[0].index]
            }
          },
        }
      }
    }

    return (
      <div>
        <div className="m-b">
          <h1 className="h4 mb-0">{this.welcomeMsg()}</h1>
        </div>

        <Row>
          <Col md={4} xs={12}>
            <Card>
              <CardHeader>
                Total Members{' '}
                <Link to="/members">
                  <Button size="sm" className="pull-right">
                    View
                  </Button>
                </Link>
              </CardHeader>
              <CardBody>
                <h2 className="m-b-20 inline-block bold-600">
                  <span>{this.state.membersCount && this.state.membersCount}</span>
                </h2>{' '}
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-success mr-2 bold-600">
                    {this.state.membersCountLastMonth && this.state.membersCountLastMonth}
                  </span>
                  <span className="text-nowrap">new since last 30 days</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} xs={12}>
            <Card>
              <CardHeader>
                Active Members{' '}
                <Link to="/members?status=A">
                  <Button size="sm" className="pull-right">
                    View
                  </Button>
                </Link>
              </CardHeader>
              <CardBody>
                <h2 className="m-b-20 inline-block bold-600">
                  <span>{this.state.memberCountActive && this.state.memberCountActive}</span>
                </h2>{' '}
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-success mr-2 bold-600">
                    {this.state.memberCountActive && this.state.membersCount &&
                      (this.state.memberCountActive.replace(/,/g, '') / this.state.membersCount.replace(/,/g, '') * 100).toFixed(2) + '%'
                    }
                  </span>
                  <span className="text-nowrap">of total members</span>
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col md={4} xs={12}>
            <Card>
              <CardHeader>
                Inactive Members{' '}
                <Link to="/members?status=N">
                  <Button size="sm" className="pull-right">
                    View
                  </Button>
                </Link>
              </CardHeader>
              <CardBody>
                <h2 className="inline-block bold-600">
                  <span>{this.state.memberCountInactive && this.state.memberCountInactive}</span>
                </h2>
                <p className="mt-3 mb-0 text-muted text-sm">
                  <span className="text-danger mr-2 bold-600">
                    {this.state.memberCountInactive && this.state.membersCount &&
                      (this.state.memberCountInactive.replace(/,/g, '') / this.state.membersCount.replace(/,/g, '') * 100).toFixed(2) + '%'
                    }
                  </span>
                  <span className="text-nowrap">of total members</span>
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col md={8} sm={12}>
            <Card>
              <CardHeader>Member Growth</CardHeader>
              <CardBody>
                <Bar
                  data={bar.data}
                  options={bar.options}
                />
              </CardBody>
            </Card>
          </Col>
          <Col md={4} sm={12}>
            <Card>
              <CardHeader>Gender</CardHeader>
              <CardBody>
                <Doughnut
                  data={donutData}
                  height={334}
                  legend={{ display: true }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>


        {this.renderPushNotificationData(chartColors)}

      </div>
    );


  }
}

export default Dashboard;