import React, { Component } from 'react';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

class EditOrganizationPaymentDetailsForm extends Component {
  componentDidMount() {}

  render() {
    return this.props && this.props.organizationDetail ? (
      <CardBody>
        <Form>
          <Row>
            <Col sm="6">
              <FormGroup>
                <Label for="legal_name">Legal name</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="legal_name"
                  id="legal_name"
                  defaultValue={this.props.organizationDetail.legal_name}
                  onChange={(e) => this.props.onChange(e.target.value, 'legal_name')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="organization_number">Organization number</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="organization_number"
                  id="organization_number"
                  defaultValue={this.props.organizationDetail.organization_number}
                  onChange={(e) => this.props.onChange(e.target.value, 'organization_number')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="bank_name">Bank name</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="bank_name"
                  id="bank_name"
                  defaultValue={this.props.organizationDetail.bank_name}
                  onChange={(e) => this.props.onChange(e.target.value, 'bank_name')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="clearing_number">Sort code (Clearingnummer)</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="clearing_number"
                  id="clearing_number"
                  defaultValue={this.props.organizationDetail.clearing_number}
                  onChange={(e) => this.props.onChange(e.target.value, 'clearing_number')}
                />
              </FormGroup>
            </Col>


            <Col sm="6">
              <FormGroup>
                <Label for="account_number">Account number</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="account_number"
                  id="account_number"
                  defaultValue={this.props.organizationDetail.account_number}
                  onChange={(e) => this.props.onChange(e.target.value, 'account_number')}
                />
              </FormGroup>


              <FormGroup>
                <Label for="street_address">Street address</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="street_address"
                  id="street_address"
                  defaultValue={this.props.organizationDetail.street_address}
                  onChange={(e) => this.props.onChange(e.target.value, 'street_address')}
                />
              </FormGroup>

              <FormGroup>
                <Label for="city">City</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="city"
                  id="city"
                  defaultValue={this.props.organizationDetail.city}
                  onChange={(e) => this.props.onChange(e.target.value, 'city')}
                />
              </FormGroup>
              <FormGroup>
                <Label for="zip_code">Zip code</Label>
                <Input
                  disabled={this.props.organizationDetail.status === 'published'}
                  type="text"
                  name="zip_code"
                  id="zip_code"
                  defaultValue={this.props.organizationDetail.zip_code}
                  onChange={(e) => this.props.onChange(e.target.value, 'zip_code')}
                />
              </FormGroup>
            </Col>

            <Col>
            {this.props.organizationDetail.status === 'published' && (
                <p className="mini-font">
                  Please reach out to your contact person at Studentkortet STUK.CO to change this info
                </p>
            )}
            </Col>

          </Row>
        </Form>
      </CardBody>
    ) : null;
  }
}

export default EditOrganizationPaymentDetailsForm;
