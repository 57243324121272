import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getAllForms } from '../../../vibe/helpers/apiHelper';
import FormCard from '../../elements/forms/FormCard';
import { Link } from "react-router-dom";

class Forms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentPage: 1,
      forms:[]
    }
  }

  componentDidMount() {
    this.setState({ loading: true })
    
    getAllForms((result) => {
      this.setState({
        forms: result, 
        loading: false,
      });
    },
    (error) => {
      this.setState({ loading: false });
      console.error('No forms found!', error);
    }
    );
}

  componentDidUpdate(prevProps, prevState) {
  }

  renderAllForms() {
    if (this.state.forms && this.state.forms.length > 0) {
      return this.state.forms.map((form) => <FormCard key={form.id} form={form}></FormCard>);
    }
    return null
  }

  onChangePage(page) {
    this.setState({ currentPage: page })
  }
  
  render() {

    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>
            <header className="app-header-page justify-content-end">
              <div className="col-md-3 mw-350">
                <FormGroup className="mb-0 pt-1">
                  <Link
                    className="btn btn-primary block"
                    to="/form/new">
                    New Form
                  </Link>
                </FormGroup>
              </div>
            </header>

            <div className="row mb-3 align-items-center">
                <div className="col">
                    <h1 className="h4 mb-0">Forms</h1>
                    <small className="text-muted">{this.state.forms.length} forms</small>
                </div>
            </div>

            <div>
              {this.renderAllForms()}
            </div>
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default Forms;
