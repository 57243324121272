export function occurrenceCanBeDeleted(occurrence) {
    if (occurrence?.tickets && Array.isArray(occurrence.tickets)) {
        for (let ticket of occurrence.tickets) {
            if (hasSoldTickets(ticket)) {
                return false
            }
        }
    }
    return true
}

export function eventCanBeDeleted(occurrences) {
    if (occurrences?.length > 0) {
        for (const occurrence of occurrences) {
            if (!occurrenceCanBeDeleted(occurrence)) {
                return false
            }
        }
    }
    return true
}

export function ticketCanBeDeleted(ticket) {
    return !hasSoldTickets(ticket)
}

/**
 * 
 */
export function sortByDate(items, order = 'DESC') {
    items.sort((a, b) => {
        if (order === 'ASC') {
            return new Date(a.start_date) - new Date(b.start_date);
        }
        return new Date(b.start_date) - new Date(a.start_date);
    })
}

export function getTicketNameError(name) {
    if (!name) {
        return 'Name is required'
    }

    if (name.length > 50) {
        return 'Max 50 characters'
    } else {

        // only allow characters a-ä, 0-9, !?(),.-:; and space
        // '/' is not allowed!
        const regex = /^[a-zA-Z0-9!?(),.\-:;äöåÄÖÅ ]*$/;
        if (regex.test(name)) {
            return null
        } else {
            return 'Only charaters a-ö, 0-9, !?(),.-:; and space'
        }
        
    }
}

function hasSoldTickets(ticket) {
    const sold = ticket?.ticket_statistics?.sold ?? 0
    return sold > 0
}