import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';


class Member extends Component {

  render() {
    return (
      <Link className="member-card-link" from={'/members'} to={'/member/edit/' + this.props.member.id}>
        <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Full Name</p>
            <p className="list-item-text mb-0">{this.props.member.first_name} {this.props.member.last_name}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Member ID</p>
            <p className="list-item-text mb-0">{this.props.member.id}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">PIN</p>
            <p className="list-item-text mb-0">{this.props.member.personal_identification_number}</p>
          </div>
          {/* <div className="mb-1 text-small w-75 w-sm-100">
            <p className="list-item-heading mb-1">Email Address</p>
            <p className="list-item-text mb-1">{this.props.member.email}</p>
          </div> */}
          {/* <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Date Of Birth</p>
            <p className="list-item-text mb-0">{new Date(this.props.member.date_of_birth).toISOString().split('T')[0]}</p>
          </div> */}
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Email</p>
            <p className="list-item-text mb-0">{this.props.member.email}</p>
          </div>
          <div className="mb-1 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Gender</p>
            <p className="list-item-text mb-0">{this.props.member.gender === 'M' ? 'Male' : 'Female'}</p>
          </div>
        </Card>
      </Link>
    );
  }

}

export default Member;
