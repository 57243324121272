import TimePicker from 'rc-time-picker';
import React from 'react';
import { Input } from 'reactstrap';
import DateTimePicker from './DateTimePicker';

export default class SimpleDateTimePicker extends DateTimePicker {

    render() {

        return <div className='d-flex'>
            <Input className="all-caps" type="date" name={this.props.name} id={this.props.name} value={this.formatDate(this.props.value)} onChange={(e) => this.onChangeDate(e.target.value, this.props.name)} />
            {!this.props.hideTime &&
                <TimePicker disabled={this.props.value ? false : true} className='mt-1 ml-2' showSecond={false} onChange={(e) => this.onChangeTime(e, this.props.name)} placeholder="HH:MM" value={this.getTimeMoment(this.props.value)} allowEmpty={false} inputReadOnly />
            }
        </div>
    }

}