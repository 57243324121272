import { NEW, } from '../../../../elements/form/FormAction';
import { hasOption } from '../../../../../vibe/helpers/optionHelper';
import Options from '../../../common/Options';
import { v4 as uuidv4 } from 'uuid';
import { KEY_BUTTON_BG_COLOR, KEY_BUTTON_TEXT_COLOR } from './OccurrenceOptions';

class AllOccurrenceOptions extends Options {

    onAddDefaultValues(options) {
        if (!hasOption(options, KEY_BUTTON_BG_COLOR)) {
            options.push({id: uuidv4(), _action: NEW, key: KEY_BUTTON_BG_COLOR, value: '#0D6EFD'})
        } 

        if (!hasOption(options, KEY_BUTTON_TEXT_COLOR)) {
            options.push({id: uuidv4(), _action: NEW, key: KEY_BUTTON_TEXT_COLOR, value: '#ffffff'})
        } 
    }

}

export default AllOccurrenceOptions