import React from 'react';
import { toColorClass, toStatusString } from '../../../vibe/helpers/transactionStatus';
import { hasValue, prettyPrintDate } from '../../../vibe/helpers/util';
import { toReadablePrice } from '../../elements/form/Currency';
import TransactionLog from './TransactionLog';

class TransactionLogs extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (!this.props.transactionLogs || this.props.transactionLogs.length <= 0) {
            return null
        }

        let elementStyles = {
            refund_date: {
                render: (value) => {return prettyPrintDate(value)}
            },
            status: {
                render: (value) => {return this.renderStatus(value)}
            },
            amount: {
                render: (value) => {return this.renderAmount(value)}
            },
            meta: {
                render: (value) => {return JSON.stringify(value)}
            },
            price: {
                render: (value) => {return this.renderAmount(value)}
            }
        }
        return this.props.transactionLogs.map((transactionLog, index) => {
            return <TransactionLog key={transactionLog.id} transactionLog={transactionLog} transactionLogs={this.props.transactionLogs} index={index} 
                ignoreKeys={["created_at", "updated_at", "organization_chain"]}
                elementStyles={elementStyles}/>
        })
    }

    /**
     * payment providers amount is in smallest currency unit, 
     * render a span with parentheses in color for the normal amount
     * 
     * 10000 -> 100.00
     */
    renderAmount(amount) {
        if (!hasValue(amount)) {
            return null
        }
        
        return <><span>{toReadablePrice(amount)}</span></>
    }

    /**
     * int status to string value with color
     */
    renderStatus(status) {
        if (hasValue(status)) {
            return <span className={toColorClass(status)}>{toStatusString(status)}</span>
        }
        return null
    }

}

export default TransactionLogs