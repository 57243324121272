import React from 'react';
import { Input } from 'reactstrap';
import MultiLanguageWrapper from './MultiLanguageWrapper';

class MultiLanguageInput extends React.Component { 

    render() {
        return (
          <MultiLanguageWrapper
            label={this.props.label}
            data={this.props.data}
            keys={this.props.keys}
            onChange={this.props.onChange}
            isInvalid={this.props.isInvalid}>
            {({ content, onChange, isInvalid }) => (
              <Input
                invalid={isInvalid}
                type="text"
                value={content}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          </MultiLanguageWrapper>
        );
    }

}

export default MultiLanguageInput