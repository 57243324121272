import React, { Component } from 'react';
import { ATTRIBUTE_KEY_ENABLED, ATTRIBUTE_KEY_TITLE, ATTRIBUTE_KEY_X, ATTRIBUTE_KEY_Y, ATTRIBUTE_KEY_FONT, ATTRIBUTE_KEY_FONT_SIZE, ATTRIBUTE_KEY_FONT_WEIGHT, ATTRIBUTE_KEY_PREFIX, ATTRIBUTE_KEY_TEXT_COLOR, ATTRIBUTE_KEY_FONT_WEIGHTS, getFontOptions, getFonts, TEXT_COLOR_DEFAULT, ATTRIBUTE_KEY_FONT_STYLES, ATTRIBUTE_KEY_FONT_STYLE, ATTRIBUTE_KEY_SEGMENT, ATTRIBUTE_KEY_TYPE, ATTRIBUTE_KEY_TEXT_ALIGN, ATTRIBUTE_KEY_TEXT_ALIGN_DEFAULT, ATTRIBUTE_KEY_TEXT_ALIGN_LEFT, ATTRIBUTE_KEY_TEXT_ALIGN_CENTER, ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT } from "./MembershipCardConstants";
import {Input, Col, FormGroup, Label, Card, CardBody, Row, Collapse} from 'reactstrap';
import Select from 'react-select'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

class DataField extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            open: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({ open: this.props.isSelected });
        }
    }
    
    toggle() {
        this.setState({open: !this.state.open});

        if (this.props.handleTabOpen && typeof this.props.handleTabOpen === 'function') {
            this.props.handleTabOpen(!this.state.open);
        } 
    }

    handleCoordinateChange = (axis, value) => {
        const numValue = parseInt(value) || 0;
        
        this.props.onChangeCardArrayAttributes(
            numValue,
            axis === 'x' ? ATTRIBUTE_KEY_X : ATTRIBUTE_KEY_Y,
            this.props.index,
            this.props.side,
            this.props.blockId
        );
    }

    /**
     * "card_extra_text" -> "Card Extra Text"
     */
    toTitleCase(str) {
        if (!str) {
            return str
        }
        return str.replaceAll('_', ' ').split(" ").map(s => s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()).join(" ")
    }

    handleTitleChange(e){
        this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_TITLE, this.props.index, this.props.side, this.props.blockId);
    }

    getIcon(type) {
        switch(type){
            case 'name':
                return <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"/>
                </svg>
            case 'personal_identification_number':
                return <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="black" d="M5 15h1.5v-2H9q.425 0 .713-.288T10 12v-2q0-.425-.288-.712T9 9H5zm6.25 0h1.5V9h-1.5zM14 15h1.25v-3.5L17.8 15H19V9h-1.25v3.5L15.25 9H14zm-7.5-3.5v-1h2v1zM4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm0-2h16V6H4zm0 0V6z"/>
                </svg>
            case 'valid_to':
                return <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                </svg>
            case 'valid_to_study_period':
                return <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"/>
                </svg>
            case 'card_number':
                return <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="black" d="M22 3H2c-1.09.04-1.96.91-2 2v14c.04 1.09.91 1.96 2 2h20c1.09-.04 1.96-.91 2-2V5a2.074 2.074 0 0 0-2-2m0 16H2V5h20zm-8-2v-1.25c0-1.66-3.34-2.5-5-2.5s-5 .84-5 2.5V17zM9 7a2.5 2.5 0 0 0-2.5 2.5A2.5 2.5 0 0 0 9 12a2.5 2.5 0 0 0 2.5-2.5A2.5 2.5 0 0 0 9 7m5 0v1h6V7zm0 2v1h6V9zm0 2v1h4v-1z"/>
                </svg>
            case 'semester':
                return <svg className="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path fill="black" d="M6.05 17.775q-.5-.275-.775-.737T5 16v-4.8L2.6 9.875q-.275-.15-.4-.375T2.075 9t.125-.5t.4-.375l8.45-4.6q.225-.125.463-.188T12 3.275t.488.063t.462.187l9.525 5.2q.25.125.388.363T23 9.6V16q0 .425-.288.713T22 17t-.712-.288T21 16v-5.9l-2 1.1V16q0 .575-.275 1.038t-.775.737l-5 2.7q-.225.125-.462.188t-.488.062t-.488-.062t-.462-.188zM12 12.7L18.85 9L12 5.3L5.15 9zm0 6.025l5-2.7V12.25l-4.025 2.225q-.225.125-.475.188t-.5.062t-.5-.062t-.475-.188L7 12.25v3.775zm0-3"/>
                </svg>
            case 'card_extra_text':
                return <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"/>
                </svg>
            case 'card_code':
                return <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path fill="black" d="M11.78 10.22a.75.75 0 0 0-1.06 1.06l.72.72l-.72.72a.75.75 0 1 0 1.06 1.06l.72-.72l.72.72a.75.75 0 0 0 1.06-1.062L13.561 12l.72-.72a.75.75 0 1 0-1.061-1.06l-.72.72zm-6.56 0a.75.75 0 0 1 1.06 0l.72.72l.72-.72a.75.75 0 1 1 1.06 1.06l-.719.72l.72.718A.75.75 0 1 1 7.72 13.78L7 13.06l-.72.72a.75.75 0 0 1-1.06-1.06l.72-.72l-.72-.72a.75.75 0 0 1 0-1.06M16.5 12.5a.75.75 0 0 0 0 1.5h1.75a.75.75 0 0 0 0-1.5zM5.25 5A3.25 3.25 0 0 0 2 8.25v7.5A3.25 3.25 0 0 0 5.25 19h13.5A3.25 3.25 0 0 0 22 15.75v-7.5A3.25 3.25 0 0 0 18.75 5zM3.5 8.25c0-.967.783-1.75 1.75-1.75h13.5c.967 0 1.75.783 1.75 1.75v7.5a1.75 1.75 0 0 1-1.75 1.75H5.25a1.75 1.75 0 0 1-1.75-1.75z"/>
                </svg>
            case 'organization':
                return <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"/>
                </svg>
            default:
                break;
        }
    }
    
    render() {
            return <Card className={`${this.state.open ? 'clear elementborder highlight' : 'clear elementborder'}`}>

                <div className={`text-small bg-white cursor-pointer`} >
                    <Row>
                        <Col onClick={(e) => this.toggle(e)} title={this.toTitleCase(this.props.field?.[ATTRIBUTE_KEY_TYPE])}>
                            <div className="p-3 bold-600 text-black" style={{margin: 'auto'}}>
                                <div className="block-title">
                                    { this.getIcon(this.props.field?.[ATTRIBUTE_KEY_TYPE]) }
                                    <Input
                                        type="text"
                                        value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TITLE, this.toTitleCase(this.props.field?.[ATTRIBUTE_KEY_TYPE]) )}
                                        onChange={(e)=> this.handleTitleChange(e)}
                                        className="p-1 form-control-sm text-black"
                                        style={{margin: 'auto', background: 'transparent', border: 'none', boxShadow: 'none'}}
                                    />
                                </div>
                            </div>
                        </Col>
                        <div className='d-flex align-items-center ml-auto mr-3'>
                            <button title="Hide/Show. data remains visible on the card" className="btn-icon" onClick={(e) => this.props.toggleElementVisibility(this.props.blockId, this.props.index, this.props.side)}>
                                {this.props.isVisible ? (
                                <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                                </svg>
                                ) : (
                                <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                                </svg>
                                )}
                            </button>
                            <button className="btn-icon mr-3" onClick={(e) => this.props.onChangeCardArrayAttributes(e.target.checked, ATTRIBUTE_KEY_ENABLED, this.props.index, this.props.side, this.props.blockId)}>
                                <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                                </svg>
                            </button>
                        </div>
                      
                    </Row>
                </div>

            <Collapse isOpen={this.state.open}>
                <CardBody>

                    <FormGroup>

                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-prefix"}>
                            Text Prefix
                            <Input type="text" name={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-prefix"} id={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-prefix"} defaultValue={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_PREFIX, '')} onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_PREFIX, this.props.index, this.props.side, this.props.blockId)} />{' '}
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TEXT_ALIGN} style={{width: 'auto'}}>
                        Align
                        <ToggleButtonGroup
                            className='d-block'
                            onChange={(e, newAlignment) => this.props.onChangeCardArrayAttributes(newAlignment, ATTRIBUTE_KEY_TEXT_ALIGN, this.props.index, this.props.side, this.props.blockId)}
                            value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TEXT_ALIGN, ATTRIBUTE_KEY_TEXT_ALIGN_DEFAULT)} exclusive>
                            <ToggleButton value={ATTRIBUTE_KEY_TEXT_ALIGN_LEFT}>
                                <i className="fa fa-align-left" />
                            </ToggleButton>
                            <ToggleButton value={ATTRIBUTE_KEY_TEXT_ALIGN_CENTER}>
                                <i className="fa fa-align-center" />
                            </ToggleButton>
                            <ToggleButton value={ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT}>
                                <i className="fa fa-align-right" />
                            </ToggleButton>
                        </ToggleButtonGroup>
                        </Label>


                        <Label for="x" className="coordinate-label" style={{width: 'auto'}}>X: 
                            <Input
                                type="number"
                                step="any"
                                value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_X, '0')}
                                onChange={(e) => this.handleCoordinateChange('x', e.target.value)}
                                className="form-control-sm coordinate-field"
                                style={{width: '80px'}}
                            />
                        </Label>
                    
                    
                        <Label for="y" className="coordinate-label" style={{width: 'auto'}}>Y: 
                            <Input
                                type="number"
                                step="any"
                                value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_Y, '0')}
                                onChange={(e) => this.handleCoordinateChange('y', e.target.value)}
                                className="form-control-sm coordinate-field"
                                style={{width: '80px'}}
                            />
                        </Label>
                        
                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-font"} style={{width: '34%'}}>
                            Font
                            <Select menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                options={getFonts()} 
                                value={getFonts().filter(font => font.value === this.props.field?.[ATTRIBUTE_KEY_FONT])}
                                onChange={(e) => this.props.onChangeCardArrayAttributes(e.value, ATTRIBUTE_KEY_FONT, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select font..."} /> 
                        </Label>

                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-font-size"} style={{width: '33%'}}>
                            Font size (px)
                            <Input type="number" name={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-font-size"} id={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-font-size"} style={{minWidth: '70px'}} defaultValue={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT_SIZE, '12')} onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_FONT_SIZE, this.props.index, this.props.side, this.props.blockId)}  />
                        </Label>

                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-color"} style={{width: '33%'}}>
                            Text Color
                            <Input type="text" name={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-color"} id={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-color"} style={{minWidth: '100px'}} defaultValue={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TEXT_COLOR, TEXT_COLOR_DEFAULT)} onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_TEXT_COLOR, this.props.index, this.props.side, this.props.blockId)}  />
                        </Label>

                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-font-weight"} style={{width: '34%'}}>
                            Font Weight
                            <Select menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                options={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_WEIGHTS)} 
                                value={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_WEIGHTS).filter(weight => weight.value === this.props.field?.[ATTRIBUTE_KEY_FONT_WEIGHT])}
                                onChange={(e) => this.props.onChangeCardArrayAttributes(e.value, ATTRIBUTE_KEY_FONT_WEIGHT, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select font weight..."} /> 
                        </Label>

                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-font-style"} style={{width: '66%'}}>
                            Font Style
                            <Select menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}} 
                                options={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_STYLES)} 
                                value={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_STYLES).filter(style => style.value === this.props.field?.[ATTRIBUTE_KEY_FONT_STYLE])}
                                onChange={(e) => this.props.onChangeCardArrayAttributes(e.value, ATTRIBUTE_KEY_FONT_STYLE, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select font style..."} /> 
                        </Label>

                        <Label for={this.props.field?.[ATTRIBUTE_KEY_TYPE] + "-segment"} style={{width: '100%'}}>
                            Segment
                            <Select isClearable={true}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                value={this.props.segments && this.props.segments.length > 0 ? this.props.segments.filter(segment => segment.value === this.props.field?.[ATTRIBUTE_KEY_SEGMENT] ) : ''}
                                options={this.props.segments} onChange={(e) => this.props.onChangeCardArrayAttributes(e?.value, ATTRIBUTE_KEY_SEGMENT, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select segment..."} /> 
                        </Label>
                        

                    </FormGroup>

                </CardBody>
            </Collapse>

        </Card>
    }

}

export default DataField