/*
const handleKeyAccessibility = e => {
  const TABKEYCODE = 9;
  const ENTERKEYCODE = 13;
  if (e.keyCode === TABKEYCODE || ENTERKEYCODE) {
    document.querySelector('body').classList.add('keyboardActive');
  }
};
*/

const handleKeyAccessibility = e => {
  const TABKEYCODE = 9;
  const ENTERKEYCODE = 13;
  const SPACEBARKEYCODE = 32;
  if (e.keyCode === TABKEYCODE || e.keyCode === ENTERKEYCODE) {
    document.querySelector('body').classList.add('keyboardActive');
  }

  if(document.querySelector('body').className === 'keyboardActive'){
  if (e.keyCode === SPACEBARKEYCODE) { 
    e.preventDefault(); 
    const focusedElement = document.activeElement;

    if (focusedElement) {
      focusedElement.click();
    }
  }
}
};


export const handleClickAccessibility = e => {
  if (e.detail !== 0) { // Determines if event is mouse click or keyboard "click"
    document.querySelector('body').classList.remove('keyboardActive');
  }
};

export default handleKeyAccessibility;
