import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';


class Segment extends Component {

  render() {
    return (
      <Link className="member-card-link" from={'/segments'} to={'/segment/edit/' + this.props.segment.id}>
        <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Name</p>
            <p className="list-item-text mb-0">{this.props.segment.name}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Organization ID</p>
            <p className="list-item-text mb-0">{this.props.segment?.organization_id}</p>
          </div>          
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Organization Name</p>
            <p className="list-item-text mb-0">{this.props.segment?.organization?.display_name}</p>
          </div>          
          {/* <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Created at</p>
            <p className="list-item-text mb-0">{new Date(this.props.segment.created_at).toLocaleString()}</p>
          </div> */}
          <div className="mb-1 text-small w-75 w-sm-100">
            <p className="list-item-heading mb-1">Last Update</p>
            <p className="list-item-text mb-1">{new Date(this.props.segment.updated_at).toLocaleString()}</p>
          </div>
        </Card>
      </Link>
    );
  }

}

export default Segment;
