import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody } from 'reactstrap';
import { formatDateYYYYmmDD } from '../../../vibe/helpers/dateHelper';



class PlasticCard extends Component {


    getPlasticCardClass() {
        if (this.props.deleteCard) {
            return 'py-4 bg-danger'
        } else {
            return 'py-4'
        }
    }

    render() {
        return <CardBody className="py-4">
            <div className="row mb-3">
                <div className="col">
                    <h1 className="h4 mb-0 pb-4">Plastic Card</h1>
                </div>
            </div>
            {
                this.props && this.props.plasticCard && !this.props.plasticCard.error ?
                    <Card className="b-a">
                        <CardHeader className="b-b">
                            <div className="row mb-3">
                                <div className="col">
                                    {this.props.plasticCard.org2_id &&<div className="list-item-text mb-1 bold-600">{this.props.plasticCard.org2_id} | {this.props.plasticCard.org2_name}</div>}
                                    {this.props.plasticCard.org3_id &&<div className="list-item-text mb-1 bold-600">{this.props.plasticCard.org3_id} | {this.props.plasticCard.org3_name}</div>}
                                    {this.props.plasticCard.org4_id &&<div className="list-item-text mb-1 bold-600">{this.props.plasticCard.org4_id} | {this.props.plasticCard.org4_name}</div>}
                                </div>
                                <div className="col">
                                    <p className="text-muted mb-0 float-right">
                                        {/* {this.props.editedMemberMembership && this.props.editedMemberMembership.id === membershipAndOrganizations.memberMembership.id ?
                                            <Button className='d-inline-block mr-2' color="primary" onClick={() => this.props.cancelEditMemberMembership()}>Cancel</Button>
                                            :
                                            <Button className='d-inline-block mr-2' color="primary" onClick={() => this.props.editMemberMembership(membershipAndOrganizations.memberMembership)}>Edit</Button>
                                        } */}
                                        <Button className='btn-inline-block' color="danger" onClick={() => this.props.onDeletePlasticCard(this.props.plasticCard.id)} >Delete</Button> 
                                    </p>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody className={'bg-gray no-radius-b-l d-flex flex-column flex-lg-row justify-content-between min-width-zero  ' + this.getPlasticCardClass()}>

                                <div className="mb-1 text-small w-25 w-sm-100 text-center">
                                    <p className="list-item-heading mb-1">Id</p>
                                    <p className="list-item-text mb-1 bold-600">{this.props.plasticCard.id}</p>
                                </div>

                                <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                    <p className="list-item-heading mb-1">Study Pace</p>
                                    <p className="list-item-text mb-1 bold-600">{this.props.plasticCard.study_pace}</p>
                                </div>
                          
                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Semester</p>
                                <p className="list-item-text mb-1 bold-600">{this.props.plasticCard.semester}</p>
                            </div>
                            
                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Created At</p>
                                <p className="list-item-text mb-1 bold-600">{this.props.plasticCard.created_at ? formatDateYYYYmmDD(this.props.plasticCard.created_at) : ''}</p> 
                            </div>
                
                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Valid from</p>
                                <p className="list-item-text mb-1 bold-600">{formatDateYYYYmmDD(this.props.plasticCard.valid_from)}</p>
                            </div>
                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Valid to</p>
                                <p className="list-item-text mb-1 bold-600">{formatDateYYYYmmDD(this.props.plasticCard.valid_to)}</p> 
                            </div>

                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Card code</p>
                                <p className="list-item-text mb-1 bold-600">{this.props.plasticCard.card_code}</p>
                            </div>
                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Card text</p>
                                <p className="list-item-text mb-1 bold-600">{this.props.plasticCard.card_extra_text}</p>
                            </div>
                    
                        </CardBody>
                    </Card>

            : <div>
                    <h5>No plastic card order found!</h5>
                    <p className="text-muted">Looks like you haven't made your order yet.</p>
                </div>
            }

        </CardBody>
    }

}

export default PlasticCard;
