import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';

class Membership extends Component {

  render() {
    return (
      <Link className="member-card-link" from={'/memberships'} to={'/membership/edit/' + this.props.membership.id}>
        <Card className={"card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"}>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Name</p>
            <p className="list-item-text mb-0">{this.props.membership.name}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Organization ID</p>
            <p className="list-item-text mb-0">{this.props.membership.organization_id}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Semester</p>
            <p className="list-item-text mb-0">{this.props.membership.semester}</p>
          </div>
        </Card>
      </Link>
    );
  }

}

export default Membership;
