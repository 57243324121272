import React, { Component } from 'react';
import { Button, Row, Col } from 'reactstrap';
import * as Icon from 'react-feather';


class FormItemActions extends Component { 
    render() {
        const { formItem, formItemIndex } = this.props
        return <>
        <Col className='d-flex justify-content-between mb-1 mt-3'>
            {formItem.type !== 'text' && 
                <div className='mx-2 mb-2' style={{ flex: '1' }}>
                    <Button color="link" onClick={() => this.props.addFormOption(formItemIndex, formItem)}>
                        <Icon.PlusCircle size="20" color="#606469" />
                    </Button>              
                    <span className="mx-3">or</span>
                    <Button size='md' color='link' style={{ fontSize: '1rem', fontWeight: 'inherit' }} onClick={() => this.props.addOtherField(formItemIndex)}>Add 'Other'</Button>
            </div>}
            <div style={{ flex: '1' }}>
                    <Row className="mx-2 mb-2 justify-content-end"> 
                            <div className="border-right mt-1">
                                    <small>Required</small>
                                    <label className="switch" >
                                    <input type="checkbox" onChange={(e) => this.props.editFormItem(formItem, 'required', e.target.checked)} checked={formItem.required} />
                                    <span className="slider round"></span>
                                    </label>
                            </div>
                            <Button color="link" onClick={() => this.props.removeFormItem(formItem,formItemIndex)}>
                                    <Icon.Trash2 size="20" color="#606469" />
                            </Button>
                    </Row>
            </div>
        </Col>
        </>
    }

}

export default FormItemActions;