import React, { Component } from 'react';
import { ATTRIBUTE_KEY_KEEP_ASPECT_RATIO, ATTRIBUTE_KEY_TITLE, ATTRIBUTE_KEY_X, ATTRIBUTE_KEY_Y, ATTRIBUTE_KEY_TYPE_IMAGE, ATTRIBUTE_KEY_SEGMENT, ATTRIBUTE_KEY_ENABLED, ATTRIBUTE_KEY_FILE_NAME, ATTRIBUTE_KEY_IMAGE_HEIGHT, ATTRIBUTE_KEY_IMAGE_WIDTH } from "./MembershipCardConstants";
import {Input, Col, FormGroup, Label, Collapse, CardBody, Card, Row} from 'reactstrap';
import Select from 'react-select';
import MediaSelect from '../../elements/media/MediaSelect';
import { downloadMedia,getMediaById } from '../../../vibe/helpers/apiHelper';
 
class ImageField extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            open: false, 
            lastInput: '', 
            fileCache: {}, 
            blobUrls: "",
        };
    }

    componentDidMount(){
        if(this.props.field?.[ATTRIBUTE_KEY_FILE_NAME]){
            this.getBlobImage(this.props.field?.[ATTRIBUTE_KEY_FILE_NAME])
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({ open: this.props.isSelected });
        }
    }

    handleCoordinateChange = (axis, value) => {
        const numValue = parseInt(value) || 0;
        
        this.props.onChangeCardArrayAttributes(
            numValue,
            axis === 'x' ? ATTRIBUTE_KEY_X : ATTRIBUTE_KEY_Y,
            this.props.index,
            this.props.side,
            this.props.blockId
        );
    }

    // TODO move this cache to parent component..
    setCache(key, file) {
        let fileCache = this.state.fileCache
        fileCache[key] = file
        this.setState({fileCache: fileCache})
    }

    getCache(key) {
        return this.state.fileCache[key]
    }

    onInputChange(e) {
        this.setState({lastInput: e})
    }

    toggle() {
        this.setState({open: !this.state.open});
        
        if (this.props.handleTabElementOpen && typeof this.props.handleTabElementOpen === 'function') {
            this.props.handleTabElementOpen(!this.state.open);
        }  
    }


    handleMediaSelect = (id) => {
        getMediaById(id, (media) => {
            this.props.onChangeCardArrayAttributes(media.file_path, ATTRIBUTE_KEY_FILE_NAME, this.props.index, this.props.side, this.props.blockId)
            this.getBlobImage(media.file_path)
          }, (error) => {
            console.log("Error fetching media", error)
          })
    };

    handleTitleChange(e){
        this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_TITLE, this.props.index, this.props.side, this.props.blockId);
    }


    getBlobImage(fileName) {
        if (!fileName) return null;
        
        downloadMedia(fileName, (blob) => {
            const objectURL = URL.createObjectURL(blob);
            this.setState({blobUrls: objectURL});
        }, (error) => {
            console.log("DownloadMedia error", error);
            return null;
        });
    }

    render() {
        return <Card className={`${this.state.open ? 'clear elementborder highlight' : 'clear elementborder'}`}>

            <div className="text-small bg-white cursor-pointer" title="Image">
                <Row>
                    <Col onClick={(e) => this.toggle(e)} >
                        <div className="p-3 bold-600 text-black" style={{margin: 'auto'}}>
                            <div className="block-title">
                                <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"/>
                                </svg>
                            <Input
                                type="text"
                                value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TITLE, 'Image')}
                                onChange={(e)=> this.handleTitleChange(e)}
                                className="p-1 form-control-sm text-black"
                                style={{margin: 'auto', background: 'transparent', border: 'none', boxShadow: 'none'}}
                            />
                            </div>
                        </div>
                    </Col>
                    <div className='d-flex align-items-center ml-auto mr-3'>
                        <button title="Hide/Show. data remains visible on the card" className="btn-icon" onClick={(e) => this.props.toggleElementVisibility(this.props.blockId, this.props.index, this.props.side)}>
                            {this.props.isVisible ? (
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                            </svg>
                            ) : (
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                            </svg>
                            )}
                        </button>
                        <button className="btn-icon mr-3" onClick={(e) => this.props.onChangeCardArrayAttributes(e.target.checked, ATTRIBUTE_KEY_ENABLED, this.props.index, this.props.side, this.props.blockId)}>
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                        </button>
                    </div>
                </Row>
            </div>

            <Collapse isOpen={this.state.open}>
                <CardBody>

                <Label for={ATTRIBUTE_KEY_TYPE_IMAGE + "-filename"} style={{width: '50%'}}>
                            
                    {this.state.blobUrls && 
                        <img src={this.state.blobUrls} alt="thumb" width={80} className='mr-3 border p-1'/>
                    }

                    <MediaSelect files={this.state.files} item={this.state.organization_id} index={0} itemId={this.state.organization_id}
                            onChange={(id, item, index) => { this.handleMediaSelect(id)  }}
                        />          
                    
                </Label>

                <Label for="x" className="coordinate-label" style={{width: 'auto'}}>X: 
                    <Input
                        type="number"
                        step="any"
                        value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_X, '0')}
                        onChange={(e) => this.handleCoordinateChange('x', e.target.value)}
                        className="form-control-sm coordinate-field"
                        style={{width: '80px'}}
                    />
                </Label>
            
            
                <Label for="y" className="coordinate-label" style={{width: 'auto'}}>Y: 
                    <Input
                        type="number"
                        step="any"
                        value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_Y, '0')}
                        onChange={(e) => this.handleCoordinateChange('y', e.target.value)}
                        className="form-control-sm coordinate-field"
                        style={{width: '80px'}}
                    />
                </Label>
                        

                <FormGroup>
                    <Label for={ATTRIBUTE_KEY_TYPE_IMAGE + "-width"} style={{width: '50%'}}>
                        Width (px)
                        <Input type="number" name={ATTRIBUTE_KEY_TYPE_IMAGE + "-width"} id={ATTRIBUTE_KEY_TYPE_IMAGE + "-width"} style={{minWidth: '80px'}} value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_IMAGE_WIDTH, '83')} onChange={(e) => this.props.onChangeImageDimensions(e.target.value, ATTRIBUTE_KEY_IMAGE_WIDTH, this.props.index, false, this.props.side, this.props.blockId)}  />
                    </Label>

                    <Label for={ATTRIBUTE_KEY_TYPE_IMAGE + "-height"} style={{width: '50%'}}>
                        Height (px)
                        <Input type="number" name={ATTRIBUTE_KEY_TYPE_IMAGE + "-height"} id={ATTRIBUTE_KEY_TYPE_IMAGE + "-height"} style={{minWidth: '80px'}}  value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_IMAGE_HEIGHT, '37')} onChange={(e) => this.props.onChangeImageDimensions(e.target.value, ATTRIBUTE_KEY_IMAGE_HEIGHT, this.props.index, false, this.props.side, this.props.blockId)}  />
                    </Label>

                    <Label for={ATTRIBUTE_KEY_TYPE_IMAGE + "-segment"} style={{width: '100%'}}>
                        Segment
                        <Select menuPortalTarget={document.body} isClearable={true}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                            value={this.props.segments && this.props.segments.length > 0 ? this.props.segments.filter(segment => segment.value === this.props.field?.[ATTRIBUTE_KEY_SEGMENT] ) : ''}
                            options={this.props.segments} onChange={(e) => this.props.onChangeCardArrayAttributes(e?.value, ATTRIBUTE_KEY_SEGMENT, this.props.index, this.props.side, this.props.blockId)} 
                            placeholder={"Select segment..."} /> 
                    </Label>

                </FormGroup>

                <FormGroup check>
                    <Input type="checkbox" 
                        name={ATTRIBUTE_KEY_TYPE_IMAGE + "-" + this.props.side + '-' + this.props.index + '-' + this.props.blockId} 
                        id={ATTRIBUTE_KEY_TYPE_IMAGE  + '-' +  this.props.side + '-' + this.props.index + '-' + this.props.blockId} 
                        checked={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_KEEP_ASPECT_RATIO, false)} 
                        onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.checked, ATTRIBUTE_KEY_KEEP_ASPECT_RATIO, this.props.index, this.props.side, this.props.blockId)} />{' '}
                    <Label for={ATTRIBUTE_KEY_TYPE_IMAGE + "-" + this.props.side + '-' + this.props.index + '-' + this.props.blockId}>
                         Keep Aspect Ratio
                    </Label>
                </FormGroup>

                </CardBody>
            </Collapse>

        </Card>
    }

}

export default ImageField