import React from 'react';
import 'react-day-picker/lib/style.css';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
const fromMonth = new Date(currentYear, currentMonth);
 
function generateArrayOfYears() {
  var max = new Date().getFullYear()
  var min = max - 100
  var years = []

  for (var i = max; i >= min; i--) {
    years.push(i)
  }

  return years
}

function YearMonthForm({ date, localeUtils, onChange }) {
  const months = localeUtils.getMonths();

  const years = generateArrayOfYears()

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <div className="DayPicker-Caption">    
      <select name="month" id="month" onChange={handleChange} value={date.getMonth()} >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select name="year" id="year"  onChange={handleChange} value={date.getFullYear()} >  
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
}

export default class DatePick extends React.Component {
  constructor(props) {
    super(props);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.handleDayClick = this.handleDayClick.bind(this);

    const dateValue = this.props.value ? new Date(this.props.value) : null

    var date_regex = /^\d{4}\/\d{2}\/\d{2}$/

    this.state = {
      selectedDay: dateValue,
      month: fromMonth,
      ignoreYear: date_regex.test(this.props.value) || !this.props.value ? false : true,
    };
  }

  handleYearMonthChange(month) {  
    this.setState({ month });
  }

  handleDayClick(day, { selected }) {
    this.setState({
      selectedDay: selected ? undefined : day,
    });
    
    if(day){
      this.props.handleChange( formatDate(day, (this.state.ignoreYear ? "MM/DD" : "YYYY/MM/DD") ));
    }else{
      this.props.handleChange( formatDate(null) );
    }
  }

  handleIgnoreYear(){
    this.setState(
      prevState => ({ 
          ignoreYear: !prevState.ignoreYear 
      }),
      ()=>this.props.handleChange( formatDate(this.state.selectedDay, (this.state.ignoreYear ? "MM/DD" : "YYYY/MM/DD")  ) )
    )
  }

  render() {
    const captionElement = props => {
      return <YearMonthForm date={props.date} localeUtils={props.localeUtils} onChange={this.handleYearMonthChange} />
    }

    const disabled = this.props?.disabled ?? false
    
    return (
      <React.Fragment>
        <DayPickerInput  
          keepFocus={false}
          format={this.state.ignoreYear ? "MM/DD" : "YYYY/MM/DD" }
          formatDate={formatDate}
          parseDate={parseDate}  
          value={this.props.value && this.state.selectedDay} 
          selectedDays={this.state.selectedDay}   
          placeholder={this.state.ignoreYear ? "MM/DD" : "YYYY/MM/DD"}
          onDayChange={this.handleDayClick}
          inputProps={
            { required: this.props.source === 'segment' ? true : false, disabled: disabled, className: disabled ? 'disabled-input' : '' }
          } 
          dayPickerProps={{
            month: this.state.month,
            captionElement: captionElement,
            todayButton: "Today",
            disabledDays: [
              this.props.source === 'push' &&
              {
                before: new Date(),
              },
            ]         
          }}
        />
        {this.state.selectedDay && this.props.source === 'segment' &&
          <>
            <input type="checkbox" id="ignoreYear" name="ignoreYear" onChange={ ()=> this.handleIgnoreYear() } checked={this.state.ignoreYear} />
            <label style={{fontSize:'14px'}}>&nbsp;Ignore Year</label>
          </>
        }
      </React.Fragment>
    );
  }
}
