import React, { Component } from 'react';
import { Button, Card} from 'reactstrap';
import * as Icon from 'react-feather';

class GuestAttendees extends Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedGuest: [],
      opened: false,
    }
    this.toggleModal = this.toggleModal.bind(this)
  }

    isGuestPendingDelete (guestId)  {
      if(!this.props.pendingDeleteGuests || !guestId ) {
        return null
      }
      return this.props.pendingDeleteGuests.some((guest) => guest.id === guestId);
    }

    isGuestSelected (guestId) {
      if(this.state.selectedGuest) {
        return this.state.selectedGuest.some((guest) => guest.id === guestId)
      }
    }

    onGuestClick (guest) {
      if(guest.validated) {
        return null
      }

      let selectedGuest = []
      selectedGuest.push(guest)
      if(this.state.selectedGuest.some((existingGuest) => existingGuest.id === guest.id)) {
        this.setState({selectedGuest: []})
      } else {
        this.setState({selectedGuest: selectedGuest})
      }
    }

    onGuestValidateClick = (guestId) => {
      this.props.onGuestValidate(guestId);
    }

    renderGuests() {
      if(!this.props.guestsAttendees) {
        return null
      }
      let guestsAttendees = this.props.guestsAttendees
        
      if(guestsAttendees && guestsAttendees.length > 0) {
          return guestsAttendees.map((guest) => {
            return <React.Fragment key={guest.id}>
              {this.renderGuest(guest)}
              </React.Fragment>
            })
        } else {
          return <p>No guests for this occurrence</p>
        }
    } 

    renderPendingAddGuests() {
        if(!this.props.pendingAddGuests && this.props.pendingAddGuests.length <= 0) {
          return null
        }
        
        return this.props.pendingAddGuests.map((pendingGuest, index) => {
          return <React.Fragment key={index}>
            {this.renderGuest(pendingGuest, true)}
          </React.Fragment>
        })
    }

    toggleModal() {
      this.setState(prevState => ({
          opened: !prevState.opened,
      }))
  }

    isOpen() {
      return this.state.opened
    }

   
    renderSelectedGuestActions(guest) {
      if(!guest) {
        return null
      }

      if(guest.validated) {
        return <p className='text-success mt-2 mb-0'>VALIDATED</p>
      } else if(this.isGuestSelected(guest.id)) {
        return <>
          <div className='d-flex align-items-center'>
            <Button disabled={guest.validated} className='mt-2' color='primary' style={{maxHeight:'50%'}} onClick={() => this.onGuestValidateClick(guest)}>Validate</Button>
          </div>
      </>
      } 
    }

    renderGuest(guest, isPending) {
        const defaultBorderStyle = '1px solid rgba(0, 0, 0, 0.125)'
        const actionsBorderStyle = isPending ? "1px solid green" : this.isGuestPendingDelete(guest.id) ? "1px solid red" : defaultBorderStyle;
        const selectedGuestBorderStyle = this.isGuestSelected(guest.id) ? '1px solid #0b1628' : "1px solid rgba(0, 0, 0, 0.125)" 

        if(this.props.onlyDisplayTab) {
          return <>
            <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero" onClick={() => this.onGuestClick(guest)} style={guest.validated ? {border: defaultBorderStyle, opacity:'70%', background: '#b5b5b5'} : {border: selectedGuestBorderStyle}}>
              <div>
              <div className="mb-0 text-small">
                <p className="list-item-heading mb-0">Name</p>
                <p className="list-item-text mb-3">{guest.name}</p>
              </div>
              <div className="mb-0 text-small">
                <p className="list-item-heading mb-0">Comment</p>
                <p className="list-item-text mb-0"><small style={{wordBreak: "break-word"}}>{guest.comment}</small></p>
              </div>
              </div>
               {this.renderSelectedGuestActions(guest)}
              </Card>
          </>
        }
        
        return <>
        <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero" style={{border: actionsBorderStyle}}>
               <div className="mb-0 text-small col-w-25 col-w-sm-100 mw-90"> 
                <p className="list-item-heading mb-1">Name</p>
                <p className="list-item-text mb-3 mw-90">{guest.name}</p>
              </div>
              <div className="mb-0 text-small col-w-50 col-w-sm-100">
                <p className="list-item-heading mb-1 mw-90">Comment</p>
                <p className="list-item-text mb-0 mw-90"><small>{guest.comment}</small></p>
              </div>
              <div className="icon-opacity mb-0 text-small w-15 w-sm-100 d-flex align-items-center icon-top-right" onClick={() => this.props.deleteGuest(guest.id)}><Icon.X className='icon-margin' strokeWidth={4} size={16}/></div>
          </Card>
          </>
    }

    render() {
        return <>
          {this.renderGuests()}
          {this.props.pendingAddGuests && this.props.pendingAddGuests.length > 0 && this.renderPendingAddGuests()}
        </>
    }
}

export default GuestAttendees;