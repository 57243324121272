import React, { Component } from 'react';
import { Button, CardBody, FormGroup, Input, Label, UncontrolledAlert } from 'reactstrap';

import { formatDateYYYYmmDD } from '../../../vibe/helpers/dateHelper';

const VERIFIED = 'Student is verified and eligible for travel discount'
const NOT_VERIFIED = 'Student is not verified'
const NOT_VERIFIED_EXPIRED = 'Student is not verified (date period not active)'
const VERIFIED_NO_TRAVEL_DISCOUNT = 'Student is verified but not eligible for travel discount'

class VerifiedStudentInfo extends Component {


    getVerifiedStudentInfoStatusClass(verifiedStudentInfo) {

            if (!verifiedStudentInfo) {
                return NOT_VERIFIED
            }

            var hasValidDates = this.hasValidDates(verifiedStudentInfo)

            if (verifiedStudentInfo.study_pace === '0') {
                return NOT_VERIFIED
            } else if (!hasValidDates) {
                return NOT_VERIFIED_EXPIRED
            } else if (verifiedStudentInfo.study_pace && parseInt(verifiedStudentInfo.study_pace) >= 75) {
                return VERIFIED + ' from ' + formatDateYYYYmmDD(verifiedStudentInfo.valid_from) + ' until ' + formatDateYYYYmmDD(verifiedStudentInfo.valid_to)
            } else {
                return VERIFIED_NO_TRAVEL_DISCOUNT + ' from ' + formatDateYYYYmmDD(verifiedStudentInfo.valid_from) + ' until ' + formatDateYYYYmmDD(verifiedStudentInfo.valid_to)
            }
    }

    hasValidDates(verifiedStudentInfo) {
        if (!verifiedStudentInfo) {
            return false
        }

        var now = Date.parse(formatDateYYYYmmDD(Date()))
        var from = Date.parse(this.props.verifiedStudentInfo.valid_from)
        var to = Date.parse(this.props.verifiedStudentInfo.valid_to)

        if (verifiedStudentInfo.valid_from && verifiedStudentInfo.valid_to) {
            return from <= now && to >= now
        } else if (verifiedStudentInfo.valid_from) {
            return from <= now
        } else if (verifiedStudentInfo.valid_to) {
            return to >= now
        } 

        return false
    }

    getDateValue(key) {
        if (this.props.verifiedStudentInfo && this.props.verifiedStudentInfo[key]) {
            return this.props.verifiedStudentInfo[key].substring(0, 10)
        }
        return ''
    }

    render() {

        if (!this.props.didFetchVerifiedStudentInfo) {
            return null
        }

        return <CardBody className="py-4">
            <React.Fragment>
                <div className="row mb-3">
                    <div className="col">
                        <h1 className="h4 mb-0">Study Pace</h1>
                    </div>

                    <div className="col">
                        <Button disabled={!this.props.didEditVerifiedStudentInfo} className="mb-0 float-right" onClick={() => this.props.undoVerify()}><i className="fa fa-undo"></i>&nbsp;UNDO</Button>
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className='col'>
                         <FormGroup>
                            <Label for="valid_from">Valid from</Label>
                            <div className='d-flex'>
                                <Input style={{width: '100%'}} type="date" value={this.getDateValue('valid_from')} onChange={(e) => this.props.updateVerifiedStudentInfo('valid_from', e.target.value + 'T00:00:00.000Z')} name={'valid_from'} id={'valid_from'}  />
                            </div>
                        </FormGroup>
                    </div>

                    <div className='col'>
                        <FormGroup>
                            <Label for="valid_to">Valid to</Label>
                            <div className='d-flex'>
                                <Input style={{width: '100%'}} type="date" value={this.getDateValue('valid_to')} onChange={(e) => this.props.updateVerifiedStudentInfo('valid_to', e.target.value + 'T00:00:00.000Z')} name={'valid_to'} id={'valid_to'}  />
                            </div>
                        </FormGroup>
                    </div>
                        
                </div>


                <div className={!this.props.verifiedStudentInfo || this.props.verifiedStudentInfo.study_pace === '0' || !this.hasValidDates(this.props.verifiedStudentInfo)  ? 'studyPace_info notVerified' : parseInt(this.props.verifiedStudentInfo.study_pace) >= 75 ? 'studyPace_info verified' : 'studyPace_info verifiedNotEligible'}>
                    {this.props && this.getVerifiedStudentInfoStatusClass(this.props.verifiedStudentInfo)}
                </div>
                <div className="row text-center mt-4 mb-4">
                    <div className="col"><div className={this.props.verifiedStudentInfo.study_pace === '0' ? 'active b-red percent' : 'percent'}>0%</div></div>
                    <div className="col"><div className={this.props.verifiedStudentInfo.study_pace === '25' ? 'active b-orange percent' : 'percent'}>25%</div></div>
                    <div className="col"><div className={this.props.verifiedStudentInfo.study_pace === '50' ? 'active b-orange percent' : 'percent'}>50%</div></div>
                    <div className="col"><div className={this.props.verifiedStudentInfo.study_pace === '75' ? 'active b-green percent' : 'percent'}>75%</div></div>
                    <div className="col"><div className={this.props.verifiedStudentInfo.study_pace === '100' ? 'active b-green percent' : 'percent'}>100%</div></div>
                </div>

                <div className="range-slider">
                    <input type="range" value={this.props.verifiedStudentInfo.study_pace} className="range-slider__range" min="0" max="100" step="25" onChange={(e) => this.props.updateVerifiedStudentInfo('study_pace', e.target.value)}  style={{ background: "linear-gradient(90deg, " + (parseInt(this.props.verifiedStudentInfo.study_pace) >= 75 ? 'rgb(26, 188, 156)' : 'rgb(255,180,0)') + this.props.verifiedStudentInfo.study_pace + "%, rgb(215, 220, 223)" + this.props.verifiedStudentInfo.study_pace + "%)" }} />
                </div>
            </React.Fragment>

            {this.props.didEditVerifiedStudentInfo ?
                <div className="row">
                    <div className="col">
                        <UncontrolledAlert color="gray" className="mt-5">
                            <small>Changes have been made, for them to be taken into effect you must save.</small>
                        </UncontrolledAlert>
                    </div>
                </div>
                : null}

        </CardBody>
    }

}

export default VerifiedStudentInfo;
