import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getMembershipSubscriptionValidDuration } from '../../../vibe/helpers/util';

class MembershipSubscription extends Component {

    render() {
        return <Link className="member-card-link" to={'/membership-subscriptions/edit/' + this.props.membershipSubscription.id} key={this.props.membershipSubscription.id}>
                    <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <div className="mb-0 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Membership</p>
                            <p className="list-item-text mb-0">{this.props.membershipSubscription.membership.name}</p>
                        </div>
                        <div className="mb-0 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Valid</p>
                            <p className="list-item-text mb-0">{getMembershipSubscriptionValidDuration(this.props.membershipSubscription)}</p>
                        </div>
                    </Card>
                </Link>
    }

}

export default MembershipSubscription;
