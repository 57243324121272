import React from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Label, Row, Tooltip } from 'reactstrap';
import EditableForm, { getSuccessButtonText } from '../../common/EditableForm';
import { initAll, NEW, onEditObject, PUBLISH_STATUS_ARCHIVED, PUBLISH_STATUS_DRAFT, PUBLISH_STATUS_PUBLISHED, UNCHANGED } from '../../../elements/form/FormAction';
import DateTimePicker from '../../../elements/form/DateTimePicker';
import Select from 'react-select'
import * as Icon from 'react-feather';
import { getTicketSegmentOptions, parseErrorMessage, superPrettyPrintDateTime } from '../../../../vibe/helpers/util';
import { addFormToInterest, createInterest, deleteFormFromInterest, getAllForms, getCurrentUser, getInterestById, getInterestFormMemberResult, getSegments, promisifyOrFalse, updateInterestById } from '../../../../vibe/helpers/apiHelper';
import { v4 as uuidv4 } from 'uuid';
import MultiLanguageInput from '../../common/MultiLanguageInput';
import { toMultiLanguageData } from '../../../../vibe/helpers/language';
import MultiLanguageClassicEditor from '../../../elements/form/MultiLanguageClassicEditor';
import MultiLanguageSmallTextArea from '../../../elements/form/MultiLanguageSmallTextArea';
import { clone } from '../../../../vibe/helpers/cloneUtil';
import MemberInterests from './MemberInterests';

const nameLanguageKeys = {
    primary: 'name', 
    secondary: 'name_en'
}

const descriptionLanguageKeys = {
    primary: 'description', 
    secondary: 'description_en'
}

const shortDescriptionLanguageKeys = {
    primary: 'short_description', 
    secondary: 'short_description_en'
}

class EditInterest extends EditableForm { 

    constructor(props) {
        super(props);
        this.state = {
            interest: null,
            formHasMemberResults: false,
            validation_error: {},
            segmentOptions: [],
            selectedInterestForm: null,
            memberInterestsModalOpen: false
        }
        this.toggleMemberInterestsModal = this.toggleMemberInterestsModal.bind(this)
    }

    toggleMemberInterestsModal() {
        this.setState(prevState => ({
            memberInterestsModalOpen: !prevState.memberInterestsModalOpen
        }))
      }

    isMemberInterestsModalOpen() {
        return this.state.memberInterestsModalOpen
    }

    getItem() {
        const id = this.props.match.params.id
        
        getSegments(0, '', (result) => {
            if (result.segments) {

                // union users should not see "Everyone" (null) segment
                getCurrentUser((user) => {
                    const segments = result.segments.sort((a, b) => a.id - b.id);
                    const segmentOptions = getTicketSegmentOptions(user, segments)
                    this.setState({ segmentOptions: segmentOptions, id: id }, () => {
                        this.getInterest(id)
                    })
                }, (error) => {
                    const errorMessage = parseErrorMessage(error)
                    this.setState({error: errorMessage, loading: false})
                })

            }
        }, (error) => {
            const errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })

        getAllForms((forms) => {
            let formOptions = []
            forms.forEach((form) => {
                formOptions.push({value: form.id, label: form.name})
            })
            this.setState({formOptions: formOptions})
        }, (error) => {
            console.log(error)
        })
        
    }

    setInterestForm(interestForm) {
        this.hasFormMemberResult(interestForm, (result) => {
            this.setState({formHasMemberResults: result})
        })
    }

    async hasFormMemberResult(interestForm, onComplete) {

        if (!interestForm?.id) {
            onComplete(false)
            return
        }

        getInterestFormMemberResult(interestForm.id, (formMemberResult) => {
            if (formMemberResult?.length) {
                onComplete(true)
            } else {
                onComplete(false)
            }
        }, (error)=> {
            onComplete(false)
        })
    }

    getInterest(id) {

        this.setState({selectedInterestForm: null})

        if (id) {
            getInterestById(id, (interest) => {
                interest._action = UNCHANGED
                initAll(interest?.organization_event_occurrences, UNCHANGED)

                initAll(interest?.interest_forms, UNCHANGED)

                const interestForm = interest?.interest_forms?.[0]
                this.setInterestForm(interestForm)

                this.setState({ topLoader: false, item: interest, loading: false, error: null, selectedInterestForm: interestForm })
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage })
            })
        } else {
            this.setState({ item: { organization_id: 0, title: null, segment_id: this?.state?.segmentOptions?.[0]?.value, _action: NEW }, organizationEventOccurrences: [], loading: false, error: null })
        }
    }

    getValidationError(key) {
        var validationErrors = this.state.validation_error
        return validationErrors[key]
    }

    onChangeInterest(value, key) {
        var interest = this.state.item
        interest[key] = value
        onEditObject(interest)
        this.setState({ item: interest, isInputChanged: true })
    }

    getFormOptions() {
        return this.state?.formOptions ?? [] 
    }

    getSegmentOptions() {
        return this.state?.segmentOptions ?? []
    }

    getSelectedFormOption() {
        if (!this.state?.selectedInterestForm) {
            return null
        }

        return this.getFormOptions().find((form) => form.value === this.state.selectedInterestForm.form_id)
    }

    setSelectedInterestForm(interestForm) {
        this.setState({selectedInterestForm: interestForm})
    }

    onChangeInterestForm(selectedFormId) {
        if (selectedFormId > 0) {
            var form = this.getFormOptions().find((formOption) => formOption.value === selectedFormId)
            var newInterestForm = {
                form_id: selectedFormId,
                form: {...form},
                _action: NEW,
                key: uuidv4()
            }
            this.setSelectedInterestForm(newInterestForm)
        } else {
            this.setSelectedInterestForm(null)
        }
        this.setState({interestFormChanged: true})
    }

    renderFormFooter() {

        return <>

            {this.state?.item?.id && this.state?.item?.status === PUBLISH_STATUS_PUBLISHED && <Button id="deep_link_button" className="mr-3" color="secondary" outline={true} onClick={() => this.onCopyLink()}>
                <Icon.Link size="12" />&nbsp;Link</Button>}
            {this.state.copied ? <Tooltip placement={'top'} isOpen={true} target={'deep_link_button'}>{this.state.copyText}</Tooltip> : null}

            {this.state?.item && this.state.item?._action !== NEW && <Button className="" color="secondary" outline={true} onClick={() => this.onClone()}>
                <i className="fa fa-copy"></i>&nbsp;Clone</Button>}

            <Button className="ml-3" color="danger" outline={true} onClick={() => this.onDelete()}>
                <i className="fa fa-trash"></i>&nbsp;Delete</Button>

            <Button className="ml-3" color="primary" outline={true} onClick={() => this.onClickSave(PUBLISH_STATUS_DRAFT)}>
                <i className="fa fa-check"></i>&nbsp;{!this.state.item.id || (this.state?.item?.status === PUBLISH_STATUS_DRAFT) ? 'Save draft' : 'Switch to draft'}</Button>

            <Button className="ml-3" color="success" onClick={() => this.onClickSave(PUBLISH_STATUS_PUBLISHED)}><i className="fa fa-check"></i>&nbsp;
                {getSuccessButtonText(this.state.item)}</Button>
        </>
    }

    onCopyLink() {
        const organizationId = this.state.item?.organization_id
        const interestId = this.state?.item?.id

        const link = "https://ob.addreax.com/" + organizationId + "/interests/" + interestId
        navigator.clipboard.writeText(link)
        .then(() => {
            this.setState({copied: true, copyText: 'Copied to clipboard!'})
            setTimeout(() => this.setState({copied: false, copyText: null}), 2500);
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
    }

    async onClone() {
        const { item } = this.state;

        const clonedInterest = clone(item, ["name", "name_en", "status", "start_date", "end_date", "segment_id", "description", "description_en", "short_description", "short_description_en", "organization_id"], () => {return {name: `${item.name} (Clone)`}})

        createInterest(clonedInterest, async (interest) => {
            if (!interest) {
                this.setState({error: "Unable to clone interest", loading: false})
                return
            }

            const interestForms = item?.interest_forms ?? []
            for (const interestForm of interestForms) {
                await promisifyOrFalse(addFormToInterest, interest?.id, interestForm.form_id)
            }

            this.props.history.push('/interests');
        }, (error) => {
            const errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })
    }

    async onDelete() {
        const confirm = window.confirm("Are you sure you want to delete this interest?")
        if (!confirm) {
            return
        }
        
        const interest = this.state?.item
        if (!interest) {
            return
        }

        if (interest?._action === NEW) {
            this.props.history.push('/interests');
        }

        const data = {
            status: PUBLISH_STATUS_ARCHIVED
        }
        updateInterestById(this.state.item.id, data, () => {
            setTimeout(() => {
                this.props.history.replace('/interests')
            }, 1000)
        }, (error) => {
            this.onFormError(error)
        })
    }

    onSave(status) {
        this.saveInterest(status, () => {
            this.onFormSuccess()
            this.setState({loading: false})
        }, (error) => {
            this.setState({loading: false})
            this.onFormError(error)
        })
    }

    async onInterestSaved(id, action, onSuccess) {
        await this.saveForms(id)
        onSuccess()

        if (action === NEW) {
            this.props.history.replace('/interests/edit/' + id)
        } else {
            this.getItem()
        }
    }

    async deleteAllInterestForms(interest) {
        if (!interest?.interest_forms || interest?.interest_forms.length <= 0) {
            return
        }

        for (const interestForm of interest.interest_forms) {
            await promisifyOrFalse(deleteFormFromInterest, interestForm.id)
        }
    }

    async saveForms(id) {
        if (!this.state.selectedInterestForm) {
            const interest = {...this.state.item}
            await this.deleteAllInterestForms(interest)
            return
        }

        if (this.state?.selectedInterestForm?._action === UNCHANGED) {
            return 
        }

        if (this.state.selectedInterestForm?._action === NEW) {
            await promisifyOrFalse(addFormToInterest, id, this.state.selectedInterestForm.form_id)
            const interest = {...this.state.item}
            await this.deleteAllInterestForms(interest)
        }
    }

    async saveInterest(status, onSuccess, onError) {

        this.setState({topLoader: true})

        var interest = {...this.state.item}

        if (interest?._action === NEW) {
            const data = clone(interest, ["organization_id", "name", "name_en", "description", "description_en", "short_description", "start_date", "end_date", "short_description_en", "segment_id"], () => {return {status: status}})
            createInterest(data, async (result) => {
                await this.onInterestSaved(result?.id, interest?._action, onSuccess)
            }, (error) => {
                onError(error)
            })
        } else {
            const data = clone(interest, ["organization_id", "name", "name_en", "description", "description_en", "short_description", "start_date", "end_date", "short_description_en", "segment_id"], () => {return {status: status}})
            updateInterestById(interest.id, data, async () => {
                await this.onInterestSaved(interest.id, interest?._action, onSuccess)
            }, (error) => {
                onError(error)
            })
        } 
    }

    renderForm(interest) {

        
        return <Form>


                    <Row>
                        <Col sm="6" className='mb-5'>
                            <FormGroup>
                                <MultiLanguageInput label={'Name'} keys={nameLanguageKeys} data={toMultiLanguageData(interest, nameLanguageKeys)} onChange={(data, key) => this.onChangeInterest(data, key)} />
                            </FormGroup>

                            <FormGroup>
                                <MultiLanguageClassicEditor label={'Description'} keys={descriptionLanguageKeys} data={toMultiLanguageData(interest, descriptionLanguageKeys)} onChange={(data, key) => this.onChangeInterest(data, key)} />
                            </FormGroup>

                            <FormGroup>
                                <MultiLanguageSmallTextArea label={'Short description'} keys={shortDescriptionLanguageKeys} data={toMultiLanguageData(interest, shortDescriptionLanguageKeys)} onChange={(data, key) => this.onChangeInterest(data, key)} />
                            </FormGroup>
                        </Col>

                        <Col sm="6">
                        
                            <FormGroup>
                                <Label for="interest_start_date">Release start</Label>
                                <DateTimePicker name={"start_date"} value={interest.start_date} onChange={this.onChangeInterest.bind(this)} />
                                {this.getValidationError('start_date') &&
                                    <div className="mini-font text-danger">{this.getValidationError('start_date')}</div>
                                }
                            </FormGroup>

                            <FormGroup>
                                <Label for="interest_end_date">Release end</Label>
                                <DateTimePicker name={"end_date"} value={interest.end_date} onChange={this.onChangeInterest.bind(this)} />
                                {this.getValidationError('end_date') &&
                                    <div className="mini-font text-danger">{this.getValidationError('end_date')}</div>
                                }
                            </FormGroup>

                            <FormGroup>
                                <Label for="segment">Segment - Who can register to this interest?</Label>
                                <div className="row">
                                    <div className='col col-6'>
                                        <Select options={this.getSegmentOptions()} onChange={(e) => this.onChangeInterest(e.value, 'segment_id')} placeholder={"Select segment..."} value={this.getSegmentOptions().filter((option) => option.value === interest.segment_id)} />
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label for="form">Form - Connect a form to this registration of interest?</Label>
                                <div className="row">
                                    <div className="col col-6">
                                        <Select options={this.getFormOptions()} onChange={(e) => this.onChangeInterestForm(e.value)} value={this.getSelectedFormOption()} placeholder="Select form..." isDisabled={this.state.formHasMemberResults} />
                                    </div>
                                    <div>
                                        {(!this.state.formHasMemberResults && this.state.selectedInterestForm) && <Button color="link" className="pl-2" onClick={(e) => this.onChangeInterestForm(-1)} disabled={this.state.formHasMemberResults}><Icon.X size="20" color="#606469" /></Button>}
                                    </div>
                                </div>
                            </FormGroup>

                            <FormGroup>
                                {this.renderMemberInterestCard(interest)}
                            </FormGroup>

                            <FormGroup>
                                {this.renderConnectedEvent(interest)}
                            </FormGroup>

                        </Col>

                    </Row>
                </Form>
    }

    onClickViewMemberInterests() {
        this.toggleMemberInterestsModal()
    }

    renderMemberInterestCard(interest) {

        return <div className="mt-5">
            <label>Registrations of interest</label>

                <Card>

                    <CardBody>
                        <div className="row d-flex align-items-center">
                            <div className='col-6'>
                                {<><div className='mt-3'><small><strong>Registrations of interest</strong></small></div>
                                    <p className="mini-font mb-1">{interest?.member_interests?.length ?? 0} members interested</p>
                                </>}
                            </div>

                            <div className='col-6 pr-1 ml-auto' style={{maxWidth: "220px"}}>
                                <div>
                                    <Button className='btn-block mr-2 occurrence-list-item-action-button--large' color="primary" onClick={() => this.onClickViewMemberInterests()}><i className="mr-1" />View</Button>
                                </div>
                            </div>

                        </div>
                    </CardBody>

                </Card>

                {this.state.memberInterestsModalOpen && this.renderMemberInterests(interest)}

            </div>
    }

    renderMemberInterests(interest) {
        if (!interest) {
            return null
        }

        return <>
            <MemberInterests interest={interest} toggle={this.toggleMemberInterestsModal} isOpen={this.isMemberInterestsModalOpen()}/>
        </>
    }

    renderConnectedEvent(interest) {

        return <div className="mb-5">
              <label>Connected Events</label>

              {!interest?.organization_event_occurrences?.length ? (
                <p className="mini-font">No events connected!</p>
              ) : (
                interest.organization_event_occurrences.map((occurrence) => {
                    
                  const event = occurrence?.organization_event
                  const eventId = event?.id

                  return (
                    
                    <Card key={occurrence.id} className="mb-3"> 
                        <CardBody>
                            <p>
                                <strong>
                                <a href={`/organization-events/edit/${eventId}`}>
                                    {event?.title}
                                </a>
                                </strong>
                            </p>
                            <div className='mb-3'>
                                <p className='list-item-heading mb-1'>
                                {superPrettyPrintDateTime(occurrence?.start_date)} - {superPrettyPrintDateTime(occurrence?.end_date)}
                                </p>
                          </div>
                      </CardBody>
                    </Card>
                  );
                })
              )}

            </div>
    }

}

export default EditInterest