// base keys
export const CARD_ATTRIBUTES = "card_attributes"
export const ATTRIBUTE_KEY_FRONT = "front"
export const ATTRIBUTE_KEY_BACK = "back"
export const ATTRIBUTE_KEY_CARD_WIDTH = "width"
export const ATTRIBUTE_KEY_CARD_HEIGHT = "height"

// field types
export const ATTRIBUTE_KEY_TYPE_TEXT = "text"
export const ATTRIBUTE_KEY_TYPE_NAME = "name"
export const ATTRIBUTE_KEY_TYPE_PIN = "personal_identification_number"
export const ATTRIBUTE_KEY_TYPE_VALID_FROM = "valid_from"
export const ATTRIBUTE_KEY_TYPE_VALID_TO = "valid_to"
export const ATTRIBUTE_KEY_TYPE_VALID_FROM_STUDY_PERIOD = "valid_from_study_period"
export const ATTRIBUTE_KEY_TYPE_VALID_TO_STUDY_PERIOD = "valid_to_study_period"
export const ATTRIBUTE_KEY_TYPE_CARD_NUMBER = "card_number"
export const ATTRIBUTE_KEY_TYPE_SEMESTER = "semester"
export const ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT = "card_extra_text"
export const ATTRIBUTE_KEY_TYPE_CARD_CODE = "card_code"
export const ATTRIBUTE_KEY_TYPE_ORGANIZATION = "organization"
export const ATTRIBUTE_KEY_TYPE_IMAGE = "image"
export const ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LAST = "card_extra_text_last"
export const ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LUND = "card_extra_text_lund"

// attribute keys
export const ATTRIBUTE_KEY_X = "x"
export const ATTRIBUTE_KEY_Y = "y"
export const ATTRIBUTE_KEY_TYPE = "type"
export const ATTRIBUTE_KEY_TITLE = "title"
export const ATTRIBUTE_KEY_TEXT = "text"
export const ATTRIBUTE_KEY_SEGMENT = "segment"
export const ATTRIBUTE_KEY_KEEP_ASPECT_RATIO = "keepaspectratio"
export const ATTRIBUTE_KEY_IMAGE_WIDTH = "width"
export const ATTRIBUTE_KEY_IMAGE_HEIGHT = "height"
export const ATTRIBUTE_KEY_FILE_NAME = "filename"
export const ATTRIBUTE_KEY_PREFIX = "prefix"
export const ATTRIBUTE_KEY_FONT = "font"
export const ATTRIBUTE_KEY_FONT_SIZE = "fontsize"
export const ATTRIBUTE_KEY_TEXT_COLOR = "textcolor"
export const ATTRIBUTE_KEY_FONT_WEIGHT = "fontweight"
export const ATTRIBUTE_KEY_FONT_STYLE = "fontstyle"
export const ATTRIBUTE_KEY_ORGANIZATION_LEVEL = "level"
export const ATTRIBUTE_KEY_TEXT_ALIGN = "textalign"
export const ATTRIBUTE_KEY_TEXT_ALIGN_LEFT = "left"
export const ATTRIBUTE_KEY_TEXT_ALIGN_CENTER = "center"
export const ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT = "right"

// internal
export const ATTRIBUTE_KEY_DATA_FIELD = "datafield"
export const ATTRIBUTE_KEY_ENABLED = "enabled"
export const ATTRIBUTE_KEY_KEY = "key"
export const ATTRIBUTE_KEY_FONT_WEIGHTS = "weights"
export const ATTRIBUTE_KEY_FONT_STYLES = "styles"
export const ATTRIBUTE_KEY_FONT_CSS = "css"

// defaults
export const MEMBERSHIP_CARD_WIDTH_DEFAULT = 1013
export const MEMBERSHIP_CARD_HEIGHT_DEFAULT = 638
export const TEXT_COLOR_DEFAULT = '#212529'
export const ATTRIBUTE_KEY_TEXT_ALIGN_DEFAULT = ATTRIBUTE_KEY_TEXT_ALIGN_LEFT

export const FONT_OPTIONS = {
    OpenSans: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['300', '400', '500', '600', '700', '800'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal', 'italic'], [ATTRIBUTE_KEY_FONT_CSS]: "'Open Sans', sans-serif"},
    Roboto: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['100', '300', '400', '500', '700', '900'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal', 'italic'], [ATTRIBUTE_KEY_FONT_CSS]: "'Roboto', sans-serif"},
    Mohave: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['300', '400', '500', '600', '700'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal', 'italic'], [ATTRIBUTE_KEY_FONT_CSS]: "'Mohave', sans-serif"},
    Lato: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['100', '300', '400', '700', '900'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal', 'italic'], [ATTRIBUTE_KEY_FONT_CSS]: "'Lato', sans-serif"},
    Dongle: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['300', '400', '700'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal'], [ATTRIBUTE_KEY_FONT_CSS]: "'Dongle', sans-serif"},
    Poppins: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['100', '200', '300', '400', '500', '600', '700', '800', '900'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal', 'italic'], [ATTRIBUTE_KEY_FONT_CSS]: "'Poppins', sans-serif"},
    Oswald: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['200', '300', '400', '500', '600', '700'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal'], [ATTRIBUTE_KEY_FONT_CSS]: "'Oswald', sans-serif"},
    NotoSans: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['400', '700'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal', 'italic'], [ATTRIBUTE_KEY_FONT_CSS]: "'Noto Sans', sans-serif"},
    DancingScript: {[ATTRIBUTE_KEY_FONT_WEIGHTS]: ['400', '500', '600', '700'], [ATTRIBUTE_KEY_FONT_STYLES]: ['normal'], [ATTRIBUTE_KEY_FONT_CSS]: "'Dancing Script', cursive"}
}

export const EXAMPLE_TEXT = {
    [ATTRIBUTE_KEY_TYPE_NAME]: 'Stina Studentsson',
    [ATTRIBUTE_KEY_TYPE_PIN]: '890225-3864',
    [ATTRIBUTE_KEY_TYPE_CARD_NUMBER]: '6032 9213 7586 4769',
    [ATTRIBUTE_KEY_TYPE_SEMESTER]: 'HT 2021',
    [ATTRIBUTE_KEY_TYPE_VALID_TO]: '2022-02-15',
    [ATTRIBUTE_KEY_TYPE_VALID_TO_STUDY_PERIOD]: '2022-09-19',
    [ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT]: 'Card extra text',
    [ATTRIBUTE_KEY_TYPE_CARD_CODE]: '957381',
    [ATTRIBUTE_KEY_TYPE_TEXT]: 'Text...',
    [ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LAST] : 'www.nationsguiden.se',
    [ATTRIBUTE_KEY_TYPE_CARD_EXTRA_TEXT_LUND] : 'Aktiv SM Boende SM'
}

export function getFonts() {
    var fonts = []
    Object.keys(FONT_OPTIONS).forEach((font) => {
        let fontOption = {
            value: font,
            label: font
        }
        fonts.push(fontOption)
    })
    return fonts
}

export function getFontOptions(font, key) {
    let items = FONT_OPTIONS[font]?.[key] ?? []
    let options = []
    for (var i = 0; i < items.length; i++) {
        let option = {
            label: items[i],
            value: items[i]
        }
        options.push(option)
    }
    return options
}

export const ORGANIZATION_LEVEL_OPTIONS = [
    {value: 1, label: 'Level 1'}, 
    {value: 2, label: 'Level 2'}, 
    {value: 3, label: 'Level 3'},
    {value: 4, label: 'Level 4'},
    {value: 5, label: 'Level 5'},
    {value: 6, label: 'Level 6'},
    {value: 7, label: 'Level 7'},
]