import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

export default class ZoomableImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isZooming: false,
            didClickZoomedImage: false            
        }
        this.toggleImage = this.toggleImage.bind(this);

    }

    toggleImage(e = null) {
        this.setState(prevState => ({
            isZooming: !prevState.isZooming
        }));
    }

    onClick(e) {
        this.setState({isZooming: !this.state.isZooming})
    }

    onClickZoomedImage(e) {
        this.setState({didClickZoomedImage: !this.state.didClickZoomedImage})
    }

    render() {
        let maxHeight = '100%'
        if (this.state.didClickZoomedImage) {
            maxHeight = undefined   
        }

        let modal
        if (this.state.isZooming) {
            modal = <Modal scrollable={true} style={{ maxWidth: '96%'}} isOpen={this.state.isZooming} toggle={this.toggleImage} className="previewModal">
                        <ModalHeader toggle={this.toggleImage}>Media library</ModalHeader>
                        <ModalBody>
                            <img style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', maxHeight: maxHeight}} onClick={(e) => this.onClickZoomedImage(e)} alt="uploaded media"  src={this.props.imageUrl} />
                        </ModalBody>
                    </Modal>
        }
        let image = <img onClick={(e) => this.onClick(e)} style={{cursor: 'pointer', maxHeight: (this.props.maxHeight ? this.props.maxHeight : 75) + 'px'}} alt="uploaded media"  src={this.props.imageUrl} /> 

        return <>
                {modal}
                {image}
            </>
         
    }

}
