import React, { Component } from 'react';
import { ATTRIBUTE_KEY_ENABLED, ATTRIBUTE_KEY_TITLE, ATTRIBUTE_KEY_FONT, ATTRIBUTE_KEY_FONT_SIZE, ATTRIBUTE_KEY_FONT_STYLE, ATTRIBUTE_KEY_FONT_STYLES, ATTRIBUTE_KEY_FONT_WEIGHT, ATTRIBUTE_KEY_FONT_WEIGHTS, ATTRIBUTE_KEY_SEGMENT, ATTRIBUTE_KEY_TEXT, ATTRIBUTE_KEY_TEXT_COLOR, ATTRIBUTE_KEY_TYPE_TEXT, EXAMPLE_TEXT, getFontOptions, getFonts, TEXT_COLOR_DEFAULT } from "./MembershipCardConstants";
import {Input, Col, FormGroup, Label, Card, Collapse, CardBody, Row} from 'reactstrap';
import Select from 'react-select'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ATTRIBUTE_KEY_TEXT_ALIGN, ATTRIBUTE_KEY_TEXT_ALIGN_DEFAULT, ATTRIBUTE_KEY_TEXT_ALIGN_LEFT, ATTRIBUTE_KEY_TEXT_ALIGN_CENTER, 
    ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT, ATTRIBUTE_KEY_X, ATTRIBUTE_KEY_Y } from './MembershipCardConstants';

class TextField extends Component {

    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { 
            open: false,
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isSelected !== this.props.isSelected) {
            this.setState({ open: this.props.isSelected });
        }
    }

    toggle() {
        this.setState({open: !this.state.open});

        if (this.props.handleTabElementOpen && typeof this.props.handleTabElementOpen === 'function') {
            this.props.handleTabElementOpen(!this.state.open);
        } 
    }

    handleCoordinateChange = (axis, value) => {
        const numValue = parseInt(value) || 0;
    
        this.props.onChangeCardArrayAttributes(
            numValue,
            axis === 'x' ? ATTRIBUTE_KEY_X : ATTRIBUTE_KEY_Y,
            this.props.index,
            this.props.side,
            this.props.blockId
        );
    }
    
    handleTitleChange(e){
        this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_TITLE, this.props.index, this.props.side, this.props.blockId);
    }

    render() {
        
        return <Card className={`${this.state.open ? 'clear elementborder highlight' : 'clear elementborder'}`}>

            <div className=" text-small bg-white cursor-pointer">
                <Row>
                    <Col onClick={(e) => this.toggle(e)} title="Text">
                         
                            <div className="p-3 bold-600 text-black" style={{margin: 'auto'}}>
                                <div className="block-title">
                                <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7"/>
                                </svg>
                                <Input
                                    type="text"
                                    value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TITLE, 'Text' )}
                                    onChange={(e)=> this.handleTitleChange(e)}
                                    className="p-1 text-black form-control-sm"
                                    style={{margin: 'auto', background: 'transparent', border: 'none', boxShadow: 'none'}}
                                />
                                </div>
                            </div>
                    </Col>
                    <div className='d-flex align-items-center ml-auto mr-3'>
                        <button title="Hide/Show. data remains visible on the card" className="btn-icon" onClick={(e) => this.props.toggleElementVisibility(this.props.blockId, this.props.index, this.props.side)}>
                            {this.props.isVisible ? (
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"/>
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"/>
                            </svg>
                            ) : (
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"/>
                            </svg>
                            )}
                        </button>
                        <button className="btn-icon mr-3" onClick={(e) => this.props.onChangeCardArrayAttributes(e.target.checked, ATTRIBUTE_KEY_ENABLED, this.props.index, this.props.side, this.props.blockId)}>
                            <svg className="icon" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                            </svg>
                        </button>
                    </div>
                </Row>
            </div>

            <Collapse isOpen={this.state.open}>
                <CardBody>

                    <FormGroup>
                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-text"}>
                            Text
                            <Input type="text" name={ATTRIBUTE_KEY_TYPE_TEXT + "-text"} id={ATTRIBUTE_KEY_TYPE_TEXT + "-text"} defaultValue={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TEXT, EXAMPLE_TEXT[ATTRIBUTE_KEY_TYPE_TEXT])} onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_TEXT, this.props.index, this.props.side, this.props.blockId)} />{' '}
                        </Label>

                         
                        <Label for={ATTRIBUTE_KEY_TEXT_ALIGN} style={{width: 'auto'}}>
                            Align
                            <ToggleButtonGroup
                                className='d-block'
                                onChange={(e, newAlignment) => this.props.onChangeCardArrayAttributes(newAlignment, ATTRIBUTE_KEY_TEXT_ALIGN, this.props.index, this.props.side, this.props.blockId)}
                                value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TEXT_ALIGN, ATTRIBUTE_KEY_TEXT_ALIGN_DEFAULT)} exclusive>                         
                            
                                <ToggleButton value={ATTRIBUTE_KEY_TEXT_ALIGN_LEFT}>
                                    <i className="fa fa-align-left" />
                                </ToggleButton>
                                <ToggleButton value={ATTRIBUTE_KEY_TEXT_ALIGN_CENTER}>
                                    <i className="fa fa-align-center" />
                                </ToggleButton>
                                <ToggleButton value={ATTRIBUTE_KEY_TEXT_ALIGN_RIGHT}>
                                    <i className="fa fa-align-right" />
                                </ToggleButton>
                                
                            </ToggleButtonGroup>
                        </Label> 

                        <Label for="x" className="coordinate-label" style={{width: 'auto'}}>X: 
                            <Input
                                type="number"
                                step="any"
                                value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_X, '0')}
                                onChange={(e) => this.handleCoordinateChange('x', e.target.value)}
                                className="form-control-sm coordinate-field"
                                style={{width: '80px'}}
                            />
                        </Label>
                    
                    
                        <Label for="y" className="coordinate-label" style={{width: 'auto'}}>Y: 
                            <Input
                                type="number"
                                step="any"
                                value={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_Y, '0')}
                                onChange={(e) => this.handleCoordinateChange('y', e.target.value)}
                                className="form-control-sm coordinate-field"
                                style={{width: '80px'}}
                            />
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-font"} style={{width: '34%'}}>
                            Font
                            <Select menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                options={getFonts()} 
                                value={getFonts().filter(font => font.value === this.props.field?.[ATTRIBUTE_KEY_FONT])}
                                onChange={(e) => this.props.onChangeCardArrayAttributes(e.value, ATTRIBUTE_KEY_FONT, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select font..."} /> 
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-font-size"} style={{width: '33%'}}>  
                            Font size (px)
                            <Input type="number" name={ATTRIBUTE_KEY_TYPE_TEXT + "-font-size"} id={ATTRIBUTE_KEY_TYPE_TEXT + "-font-size"} style={{minWidth: '70px'}} defaultValue={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT_SIZE, '12')} onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_FONT_SIZE, this.props.index, this.props.side, this.props.blockId)}  />
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-color"} style={{width: '33%'}}>
                            Text Color
                            <Input type="color" name={ATTRIBUTE_KEY_TYPE_TEXT + "-color"} id={ATTRIBUTE_KEY_TYPE_TEXT + "-color"} style={{minWidth: '100px'}}  defaultValue={this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_TEXT_COLOR, TEXT_COLOR_DEFAULT)} onChange={(e) => this.props.onChangeCardArrayAttributes(e.target.value, ATTRIBUTE_KEY_TEXT_COLOR, this.props.index, this.props.side, this.props.blockId)}  />
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-font-weight"} style={{width: '34%'}}>
                            Font Weight
                            <Select menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                options={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_WEIGHTS)} 
                                value={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_WEIGHTS).filter(weight => weight.value === this.props.field?.[ATTRIBUTE_KEY_FONT_WEIGHT])}
                                onChange={(e) => this.props.onChangeCardArrayAttributes(e.value, ATTRIBUTE_KEY_FONT_WEIGHT, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select font weight..."} /> 
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-font-style"} style={{width: '66%'}}>
                            Font Style
                            <Select menuPortalTarget={document.body}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                options={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_STYLES)} 
                                value={getFontOptions(this.props.getFieldAttribute(this.props.field, ATTRIBUTE_KEY_FONT, 'OpenSans'), ATTRIBUTE_KEY_FONT_STYLES).filter(style => style.value === this.props.field?.[ATTRIBUTE_KEY_FONT_STYLE])}
                                onChange={(e) => this.props.onChangeCardArrayAttributes(e.value, ATTRIBUTE_KEY_FONT_STYLE, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select font style..."} /> 
                        </Label>

                        <Label for={ATTRIBUTE_KEY_TYPE_TEXT + "-segment"} style={{width: '100%'}}>
                            Segment
                            <Select menuPortalTarget={document.body} isClearable={true}
                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 })}}
                                value={this.props.segments && this.props.segments.length > 0 ? this.props.segments.filter(segment => segment.value === this.props.field?.[ATTRIBUTE_KEY_SEGMENT] ) : ''}
                                options={this.props.segments} onChange={(e) => this.props.onChangeCardArrayAttributes(e?.value, ATTRIBUTE_KEY_SEGMENT, this.props.index, this.props.side, this.props.blockId)} 
                                placeholder={"Select segment..."} /> 
                        </Label>


                    </FormGroup>

                </CardBody>
            </Collapse>

        </Card>
    }

}

export default TextField