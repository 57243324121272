import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { printShort, toReadablePrice } from '../form/Currency';

class Package extends Component {

    render() {
        return <Link className="member-card-link" to={'/membership-packages/edit/' + this.props.package.id} key={this.props.package.id}>
                    <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                        <div className="mb-0 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Name</p>
                            <p className="list-item-text mb-0">{this.props.package.name}</p>
                        </div>
                        <div className="mb-0 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Price</p>
                            <p className="list-item-text mb-0">{printShort(toReadablePrice(this.props.package.price), this.props.package.currency)}</p>
                        </div>
                        <div className="mb-1 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Status</p>
                            <p className="list-item-text mb-0">{this.props.package.active ? 'active' : 'inactive'}</p>
                        </div>           

                    </Card>
                </Link>
    }

}

export default Package;
