import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

export default class MessageUncontrolledAlert extends React.Component {

    render() {
        if (this.props.success) {
            return <UncontrolledAlert color="success">
                {this.props.successMessage}
            </UncontrolledAlert>
        } else if (this.props.error) {
            return <UncontrolledAlert color="danger">
                {this.props.errorMessage}
            </UncontrolledAlert>
        } else {
            return null
        }
    }

}