import React from 'react';
import { capitalizeFirstLetter } from '../../../vibe/helpers/util';

class BillingItemHeader extends React.Component { 

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <div>
                    {this.getText()}
               </div>
    }

    getItemDiv(packageText, ticketText) {
        if (packageText) {
            return <>for <b>{packageText}</b></>
        }

        if (ticketText) {
            return <>for <b>{ticketText}</b></>
        }

        return ''
    }   

    getText() {
        var packageText = ''
        if (this.props.package_id && this.props.package_id > 0) {
            packageText = '"' + this.props.packageOptions.find((_package) => _package.value === this.props.package_id).label + '"'
        }

        var ticketText = ''
        if (this.props.event_id && this.props.event_id > 0) {
            ticketText = '"' + this.props.eventOptions.find((event) => event.value === this.props.event_id).label + '"'
        }
        
        if (this.props.ticket_id && this.props.ticket_id > 0) {
            ticketText = '"' + this.props.ticketOptions.find((ticket) => ticket.value === this.props.ticket_id).label + '"'
        }

        let prefix = capitalizeFirstLetter(this.props.scope)

        if (this.props.optionName) {
            return <div>{prefix} purchases {this.getItemDiv(packageText, ticketText)} {this.props.optionName}</div>
        } else if (this.props.from && this.props.to) {
            return <div>{prefix} purchases {this.getItemDiv(packageText, ticketText)} between <b>{this.props.from}</b> and <b>{this.props.to}</b></div>
        } else if (this.props.from) {
            return <div>{prefix} purchases {this.getItemDiv(packageText, ticketText)} after <b>{this.props.from}</b></div>
        } else if (this.props.to) {
            return <div>{prefix} purchases {this.getItemDiv(packageText, ticketText)} before <b>{this.props.to}</b></div>
        } else {
            return <div>{prefix} purchases {this.getItemDiv(packageText, ticketText)}</div>
        }
    }

}

export default BillingItemHeader