import React, { Component }  from 'react';
import { Button, Card, CardBody, CardHeader, Tooltip } from "reactstrap";
import { superPrettyPrintDateTime } from '../../../../../vibe/helpers/util';
import * as Icon from 'react-feather';
import { DELETE, EDIT, isNew, NEW } from '../../../../elements/form/FormAction';
import { occurrenceCanBeDeleted } from '../../../../../vibe/helpers/eventHelper';
import { getSuccessButtonText } from '../../../common/EditableForm';

class OccurrenceListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            copied: false
        }
    }

    componentDidMount() {

    }

    onClickDeepLink(occurrence) {
        this.onCopyDeepLink(occurrence)
    }

    onCopyDeepLink(occurrence) {
        navigator.clipboard.writeText(occurrence.deep_link)
        .then(() => {
            this.setState({copied: true, copyText: 'Copied to clipboard!'})
            setTimeout(() => this.setState({copied: false, copyText: null}), 2500);
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
    }

    render() {
        const occurrence = this.props.occurrence
        if (!occurrence) {
            return null
        }

        var tickets = [...occurrence?.tickets ?? []]
        var interests = [...occurrence?.interests ?? []]

        const canBeDeleted = occurrenceCanBeDeleted(occurrence)

        return <React.Fragment>

                <Card className={occurrence?._action === DELETE ? 'bg-danger' : ''}>

                    {(occurrence?._action === EDIT || occurrence?._action === NEW) && <CardHeader>
                        <div className="text-danger"> Unsaved changes. Click '{getSuccessButtonText(this.props.event)}' to save.</div>
                        {occurrence.isClone ? <div><small><i>Clone</i></small></div> : null}
                    </CardHeader>}

                    <CardBody>
                        <div className="row d-flex align-items-center">
                            <div className='col-6'>
                                <small className='bold-700'>{superPrettyPrintDateTime(occurrence.start_date)} - {superPrettyPrintDateTime(occurrence.end_date)}</small>
                                {tickets?.length > 0 && <div className='mt-3'><small><strong>Tickets</strong></small></div>}
                                {tickets?.map(ticket => {

                                    const totalCount = ticket?.ticket_statistics?.count ?? 0
                                    const totalSold = ticket?.ticket_statistics?.sold ?? 0
                                    const reservered = ticket?.ticket_statistics?.reserved ?? 0
                                    
                                    return <div key={ticket.id ?? ticket.key}><small>{ticket.name}</small>
                                        <p className="mini-font mb-1">{(totalSold - reservered)}/{totalCount} tickets sold</p>
                                        {reservered && reservered > 0 ? 
                                        <>
                                            <p className="mini-font mb-3">{(reservered)} tickets reserved*</p>
                                            <p className="mini-font">*Tickets are reserved for 10 minutes or until the purchase is complete.</p>
                                        </> : null}
                                        </div>
                                })}

                                {occurrence?.organization_event_occurrence_guests?.length > 0 && <><div className='mt-3'><small><strong>Guest list</strong></small></div>
                                    <p className="mini-font mb-1">{occurrence?.organization_event_occurrence_guests?.length} guests</p>
                                </>}

                                {occurrence?.interests?.[0] && <><div className='mt-3'><small><strong>Registrations of interest</strong></small></div>
                                    <p className="mini-font mb-1">{occurrence?.interests?.[0]?.member_interests?.length ?? 0} members interested</p>
                                </>}

                            </div>

                            <div className='col-6 pr-1 ml-auto' style={{maxWidth: "220px"}}>

                                <div className='mb-2 occurrence-list-item-action-button--wrapper'>
                                    <Button color="primary" className='mr-2 button-tooltip' onClick={() => this.props.onCloneOccurrence(occurrence, tickets, interests)}><Icon.Copy size="16" /><span className={'button-tooltiptext'}>Clone</span></Button>
                                    <Button color="primary" className='mr-2 button-tooltip' disabled={isNew(occurrence)} onClick={() => this.props.onClickNotify(this.props.event, occurrence)}><Icon.Bell size="16" /><span className={'button-tooltiptext'}>Notifications</span></Button>
                                    <Button color="primary" className='mr-2 button-tooltip' disabled={isNew(occurrence)} onClick={() => this.props.toggleEventAttendeesModal(occurrence)}><Icon.Users size="16" /><span className={'button-tooltiptext'}>Attendees</span></Button>
                                    <Button id='deep_link_button' color="primary" className='mr-2 button-tooltip' disabled={!occurrence.deep_link} onClick={() => this.onClickDeepLink(occurrence)}><Icon.Link size="16" /><span className={'button-tooltiptext'}>Copy deep link</span></Button>
                                    {this.state.copied ? <Tooltip placement={'top'} isOpen={true} target={'deep_link_button'}>{this.state.copyText}</Tooltip> : null}
                                </div>
                                <div>
                                    <Button className='btn-block mr-2 occurrence-list-item-action-button--large' color="primary" onClick={() => this.props.onClickEditOccurrence(occurrence)}><i className="mr-1" />Edit</Button>
                                    <Button className='btn-block mr-2 occurrence-list-item-action-button--large' title={canBeDeleted ? null :  "The occurrence can not be deleted because it contains a completed transaction"} disabled={!canBeDeleted} color="danger" onClick={() => this.props.onDeleteOccurrence(occurrence)}><i className="mr-1" />{occurrence?._action === 'delete' ? 'Undelete' : 'Delete'}</Button>
                                </div>
                            </div>

                        </div>
                    </CardBody>

                </Card>

            </React.Fragment>
    }

}

export default OccurrenceListItem;