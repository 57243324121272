import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Card, FormGroup } from 'reactstrap';
import { getCampaigns } from '../../../api/campaign/campaign'

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaigns: [],
    };
  }

  componentDidMount() {
    this.loadCampaigns();
  }

  loadCampaigns = () => {
    getCampaigns(
      (campaigns) => {
        this.setState({ campaigns });
      },
      (error) => {
        console.error('Error fetching campaigns:', error);
      }
    );
  };

  capitalize(str) {
    if (str) {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    }
    return '';
  }

  render() {
    const { campaigns } = this.state;

    return (
      <React.Fragment>
  
        <header className="app-header-page justify-content-end">
            <div className="col col-md-3 mw-350">
                <FormGroup className="mb-0 pt-1">
                    <Link className="btn btn-primary block" to={"/campaign/new"}>
                        New Campaign
                    </Link>
                </FormGroup>
            </div>
        </header>

        <div className="row mb-3 align-items-center">
            <div className="col">
                <h1 className="h4 mb-0">Campaigns</h1>
                <small className="text-muted">{campaigns.length} Campaigns</small>
            </div>
        </div>
       
        {campaigns.map((campaign) => (
          <Link className="member-card-link" to={`/campaign/edit/${campaign.id}`} key={campaign.id}>
            <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
              <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Title</p>
                <p className="list-item-text mb-0">{campaign.title}</p>
              </div>
              <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Organization ID</p>
                <p className="list-item-text mb-0">{campaign.organization_id}</p>
              </div>
              <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Created Date</p>
                <p className="list-item-text mb-0">{new Date(campaign.created_at).toISOString().split('T')[0]}</p>
              </div>
              <div className="mb-1 text-small w-25 w-sm-100">
                <p className="list-item-heading mb-1">Updated Date</p>
                <p className="list-item-text mb-0">{new Date(campaign.updated_at).toISOString().split('T')[0]}</p>
              </div>
            </Card>
          </Link>
        ))}
         
      </React.Fragment>
    );
  }
}

export default Campaigns;
