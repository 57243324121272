import React, { Component } from 'react';
import { Card} from 'reactstrap';
import { printShort, toReadablePrice } from '../form/Currency';
import { prettyPrintDate,  } from '../../../vibe/helpers/util';
import { toColorClass, toStatusString } from '../../../vibe/helpers/transactionStatus';
import { Link } from 'react-router-dom';
import TransactionActions from '../../pages/transaction/TransactionActions';

class MemberPackageTransaction extends Component {

    render() {
        var createdAt = prettyPrintDate(this?.props?.transaction?.created_at)
        var createdAtDate = createdAt?.substring(0,10)
        var createdAtTime = createdAt?.substring(11)
        return <Link className="member-card-link" to={'/transactions/packages/edit/' + this.props.transaction.id} key={this.props.transaction.id}>
            <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">

                <div className="mb-1 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1 mw-90">Date</p>
                    <p className="list-item-text mb-0 mw-90">{createdAtDate}</p>
                    <p className="list-item-text mb-0 mw-90">{createdAtTime}</p>
                </div>

                <div className="mb-1 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Status</p>
                    <p className={toColorClass(this.props.transaction.status) + " list-item-text mb-0 mw-90"}>{toStatusString(this.props.transaction.status)}</p>
                </div>

                <div className="mb-1 text-small w-75 w-sm-100">
                    <p className="list-item-heading mb-1 mw-90">Package</p>
                    <p className="list-item-text mb-0 mw-90">{this.props.package.name}</p>
                </div>
                
                <div className="mb-1 text-small w-75 w-sm-100">
                    <p className="list-item-heading mb-1 mw-90">Member</p>
                    <p className="list-item-text mb-0 mw-90">{this.getMemberName()}</p>
                </div>

                <div className="mb-1 text-small w-50 w-sm-100 mr-0">
                    <p className="list-item-heading mb-1 mw-90">Total amount</p>
                    <p className="list-item-text mb-0 mw-90">{printShort(toReadablePrice(this.props.transaction.amount), this.props.transaction.currency)}</p>
                    <p className="list-item-text mb-0 mw-90">{this.props.transaction.provider}</p>
                </div>

                {this.props.onAction && <div className="mb-1 text-small w-15-percent w-sm-100 align-items-center d-flex">
                    <TransactionActions transaction={this.props.transaction} member={this.props.member} onAction={(success, error) => this.props.onAction(success, error)} />
                </div>}
                
            </Card>
        </Link>

    }

    getMemberName() {
        if (!this.props?.member) {
            return ""
        }
        return this.props?.member?.first_name + ' ' + this.props?.member?.last_name
    }

}

export default MemberPackageTransaction;
