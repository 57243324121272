import { Config } from "../../config"
import { _delete, get, patch, post } from "../../vibe/helpers/apiHelper"

export async function getCampaigns(onSuccess, onError) {
    var filter = {
        order: 'created_at DESC'
    };
    var encodedFilter = encodeURIComponent(JSON.stringify(filter));
    var url = `${Config.api()}/campaigns?filter=${encodedFilter}`;
    get(url, onSuccess, onError)
}

export async function createCampaign(title, onSuccess, onError) {
    var url = Config.api() + '/campaigns'
    let data = title
    post(url,data, onSuccess, onError)
}

export async function createDiscount(campaignId, discounts, onSuccess, onError) {
    const url = `${Config.api()}/campaigns/${campaignId}/discounts`;
    let data = discounts
    post(url,data, onSuccess, onError)
}

export async function getCampaign(id, onSuccess, onError) {
    let filter = {
        include: [
            {
                relation: 'tickets',
                scope: {
                    include: [{
                        relation: 'organization_event_occurrences',
                        scope: {
                            include: [
                                {
                                    relation: 'organization_event'
                                }
                            ]
                        }
                    }]
                },
            },
            {
                relation: 'packages',
            }
        ]
    }

    var encodedFilter = encodeURIComponent(JSON.stringify(filter));

    var url = `${Config.api()}/campaigns/${id}?filter=${encodedFilter}`;
    get(url, onSuccess, onError)
}

export async function getDiscounts(id, onSuccess, onError) {
    var filter = {
        include: ['discountCodes'],
        order: ['created_at DESC']
    };
    var encodedFilter = encodeURIComponent(JSON.stringify(filter));
    var url = `${Config.api()}/campaigns/${id}/discounts?filter=${encodedFilter}`;
    get(url, onSuccess, onError)
}

export async function updateCampaign(id, data, onSuccess, onError) {
const url = `${Config.api()}/campaigns/${id}`;
patch(url, data, onSuccess, onError)
}

export async function updateDiscount(campaignId, discountId, data, onSuccess, onError) {
const url = `${Config.api()}/campaigns/${campaignId}/discounts?where=${encodeURIComponent(JSON.stringify({ id: discountId }))}`;
patch(url, data, onSuccess, onError)
}

export async function deleteDiscount(campaignId, discountId, onSuccess, onError) {
    const url = `${Config.api()}/campaigns/${campaignId}/discounts?where=${encodeURIComponent(JSON.stringify({ id: discountId }))}`;
    _delete(url, onSuccess, onError)
}

export async function deleteCampaign(campaignId, onSuccess, onError) {
    const url = `${Config.api()}/campaigns/${campaignId}`;
    _delete(url, onSuccess, onError)
}

export async function createDiscountCodes(discountId, discountCodes, onSuccess, onError) {
    const url = `${Config.api()}/discounts/${discountId}/discount-codes`;
    let data = discountCodes
    post(url, data, onSuccess, onError)
}

export async function updateDiscountCode(discountId, discountCodeId, data, onSuccess, onError) {
    const url = `${Config.api()}/discounts/${discountId}/discount-codes?where=${encodeURIComponent(JSON.stringify({ id: discountCodeId }))}`;
    patch(url, data, onSuccess, onError)
}

export async function addCampaignToTicketPackage(ticketId = null, packageId = null, campaignId, onSuccess, onError) {
    const url = `${Config.api()}/campaigns/${campaignId}/campaign-products`;
    
    let data = {
        campaign_id: campaignId
    }
    if(ticketId){
        data.tickets_id = ticketId
    }
    if(packageId){
        data.package_id = packageId
    }

    post(url,data, onSuccess, onError)
}

export async function deleteCampaignFromTicketPackage(ticketId = null, packageId = null, campaignId, onSuccess, onError) {
    const data = ticketId ? {tickets_id: ticketId} : {package_id: packageId}
    const url = `${Config.api()}/campaigns/${campaignId}/campaign-products?where=${encodeURIComponent(JSON.stringify( data ))}`;
    _delete(url, onSuccess, onError)
}


export async function getClaimedDiscountCodesByDiscount(id, onSuccess, onError) {
    var filter = {
        where: {
            type: 2,
        },
    };
    var encodedFilter = encodeURIComponent(JSON.stringify(filter));
    var url = `${Config.api()}/discount-codes/${id}/discount-code-claims?filter=${encodedFilter}`;
    get(url, onSuccess, onError)
}