import React, { Component } from 'react';
import { Button, PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';

const buttonStyle = {
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    padding: 0,
}

class SimpleHelpPopover extends Component {

    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
            key: uuidv4()
        };
    }

    togglePopover() {
        this.setState({popoverOpen: !this.state.popoverOpen})
    }

    render() {

        const target = "popover_icon_" + this.state.key

        return (
            <>
                <Button
                    id={target}
                    color={this.props.color ?? "info"}
                    style={buttonStyle}
                    className='mr-2 mt-1'
                    onClick={() => this.togglePopover()}>
                    ?
                </Button>

                <UncontrolledPopover
                    placement={this.props.placement ?? "right"}
                    isOpen={!!this.state?.popoverOpen}
                    target={target}
                    trigger={this.props.trigger ?? "legacy"}
                    toggle={() => this.togglePopover()}>

                    {this.props.title && <PopoverHeader>{this.props.title}</PopoverHeader>} 

                    <PopoverBody>
                        {this.props.children}
                    </PopoverBody>

                </UncontrolledPopover>

            </>

        )

    }
}

export default SimpleHelpPopover;