import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import * as Icon from 'react-feather';


class FormItemCheckbox extends Component { 
    
    render() {
        const { formItem, option, } = this.props
        return <>
        <div className="input-group mb-3">
                      <div className="d-flex align-items-center pr-2">
                      <Icon.Square size="20" color="#BDBDBD" /> 
                      </div>
                            <Input
                                className="form-control rounded mr-2"
                                type={'text'}
                                value={option.value}
                                onChange={(e) => this.props.editFormOption(option, formItem, 'value', e.target.value)}
                            />
                            <div className="d-flex align-items-center ">
                            <Button color="link" className="pl-1" onClick={() => this.props.removeOption(formItem, option)}>
                              <Icon.X size="20" color="#606469" />
                            </Button>
                          </div>
                    </div>
        </>
    }

}

export default FormItemCheckbox;