import React from 'react';
import { Loader } from '../../../vibe';
import { getMembershipSubscriptions } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import ListHeader from '../../elements/form/ListHeader';
import MembershipSubscription from '../../elements/subscription/MembershipSubscription';

const HEADER_LINKS = [
    {title: 'New Subscription', link: '/membership-subscriptions/new/'}
]

class MembershipSubscriptions extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            membershipSubscriptions: []
        }
    }

    componentDidMount() {
        getMembershipSubscriptions((membershipSubscriptions) => {
            this.setState({membershipSubscriptions: membershipSubscriptions, loading: false})
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })
    }

    renderMembershipSubscriptions() {
        if (!this.state.membershipSubscriptions || this.state.membershipSubscriptions.length <= 0) {
            return null
        }

        return this.state.membershipSubscriptions.map((membershipSubscription) => {
            return <MembershipSubscription key={membershipSubscription.id} membershipSubscription={membershipSubscription}  />
        })
    }

    render() {
        if (this.state.loading) {
            return <Loader type="dots" />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {   
            return <React.Fragment>
                        <ListHeader title={'Subscriptions'} links={HEADER_LINKS} />
                        {this.renderMembershipSubscriptions()} 
                   </React.Fragment>      
        }
    }

}
export default MembershipSubscriptions
