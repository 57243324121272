import React from 'react';
import { Link } from 'react-router-dom';
import { FormGroup } from 'reactstrap';

export default class ListHeader extends React.Component {


    render() {
        return <div className="row mb-3 align-items-center">
                <div className="col">
                    <h1 className="h4 mb-0">{this.props.title}</h1>
                </div>

                {this.props.links && this.props.links.map((link) => {
                    return <div className="col col-md-3" key={link.link}>
                                <FormGroup className="mb-0 pt-1">
                                    <Link className="btn btn-primary block" to={link.link}>
                                        {link.title}
                                    </Link>
                                </FormGroup>
                           </div>
                })}
            </div>
    }

}