import React from 'react';
import { FormGroup, UncontrolledAlert } from 'reactstrap';
import { getVerifyStudentUsers } from '../../../vibe/helpers/apiHelper';
import ManageUsersTemplate from './manageUsersTemplate'
import { Loader } from '../../../vibe';
import { Link } from 'react-router-dom';

class ManageUsers extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            loading: true
        }
    }

    componentDidMount() {

        getVerifyStudentUsers(
            (result) => {
                this.setState({ users: result, loading: false, error: null });
            },
            (err) => {
                this.setState({ error: 'Error fetching users information', loading: false });
            })
    }

    handleToggleError = (errorMessage) => {
        this.setState({ error: errorMessage });
    };

    render() {

        this.state.users && this.state.users.sort((a, b) => {
            return a.status === b.status ? 0 : a.status ? -1 : 1;
        });

        return (
            <>
                <header className="app-header-page justify-content-end">
                    <div className="col-md-3 mw-350">
                        <FormGroup className="mb-0 pt-1">
                            <Link className="btn btn-primary block" to={"/manage-user/new"}>
                                Create user
                            </Link>
                        </FormGroup>
                    </div>
                </header>

                {this.state.error && (
                    <UncontrolledAlert color="danger">
                        {this.state.error}
                    </UncontrolledAlert>
                )}

                {this.state.loading && <Loader type="dots" />}
                {!this.state.loading && this.state.users.length === 0 && <p>No users found.</p>}

                {this.state.users.map((user) => (
                    <ManageUsersTemplate key={user.id} user={user} onError={this.handleToggleError} />
                ))}
            </>
        );
    }
}


export default ManageUsers
