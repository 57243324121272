// pdfGenerator.js
import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

const getPdfBlob = async (payoutMeta, packageData, ticketData) => {
    // Load the existing PDF file
    const existingPdfBytes = await fetch('/statement.pdf').then((response) =>
        response.arrayBuffer()
    );

    // Create a PDF document
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    pdfDoc.registerFontkit(fontkit);
    const fontBytes = await fetch('/Poppins-Light.ttf').then((response) =>
        response.arrayBuffer()
    );
    const poppinsFont = await pdfDoc.embedFont(fontBytes);

    // Add a new page to the document
    let [page] = pdfDoc.getPages();
    const { width, height } = page.getSize();
    let currentPageRows = 0;

    // ================================
    // Define table headers and data
    // Define table headers and data
    const tableHeaders = ['Item name', 'Item type', 'Quantity', 'Price per unit', 'Total amount'];

    // Define table column widths
    const columnWidths = [300, 60, 50, 60, 60];

    // Set font and text size
    const fontSize = 8;

    // Set initial y-coordinate for the table
    let y = height - 260;


    page.drawText(payoutMeta.statementId.toString(), {
        x: 130,
        y: y + 120,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });
    page.drawText(payoutMeta.DatePaid.split('T')[0], {
        x: 130,
        y: y + 100,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });
    page.drawText(payoutMeta.period, {
        x: 130,
        y: y + 83,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });
    page.drawText(payoutMeta.totalTx.toString(), {
        x: 130,
        y: y + 67,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });
    page.drawText('Completed', {
        x: 130,
        y: y + 49,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });

    page.drawText(payoutMeta.union.legal_name.toString(), {
        x: 360,
        y: y + 80,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });
    page.drawText('Org NO. ' + payoutMeta.union.organization_number.toString(), {
        x: 360,
        y: y + 65,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });
    page.drawText(payoutMeta.union.street_address.toString() + ', ' + payoutMeta.union.zip_code + ', ' + payoutMeta.union.city, {
        x: 360,
        y: y + 48,
        size: 10,
        font: poppinsFont,
        color: rgb(0, 0, 0), // Black color
    });


    // Set the background color for the table header
    // Draw table headers with background color and borders
    for (let i = 0; i < tableHeaders.length; i++) {
        const x = 30 + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);

        // Draw a background rectangle for the header cell
        page.drawRectangle({
            x,
            y,
            width: columnWidths[i],
            height: 25, // Adjust the height as needed
            color: rgb(241 / 255, 243 / 255, 244 / 255),
            borderColor: rgb(0, 0, 0), // Border color (black)
            borderWidth: 0.2, // Border width
        });

        // Draw the header text on top of the background
        page.drawText(tableHeaders[i], {
            x: x + 5, // Adjust the x-coordinate for text padding
            y: y + 10, // Adjust the y-coordinate for text centering
            size: fontSize,
            font: poppinsFont,
            color: rgb(0, 0, 0), // Black color
        });
    }

    // Draw table data with borders
    if (ticketData.length > 0) {
        for (let ticketIndex = 0; ticketIndex < ticketData.length; ticketIndex++) {
            const ticket = ticketData[ticketIndex];
            y -= 25; // Adjust the y-coordinate for the next row
            const rowData = [
                ticket.eventName + ' (' + ticket.ticketName + ')',
                'Event Ticket',
                `${ticket.quantity}`,
                `${ticket.unitPrice} ${ticket.currency}`,
                `${ticket.amountFormatted} ${ticket.currency}`,
            ];
            for (let col = 0; col < rowData.length; col++) {
                const x = 30 + columnWidths.slice(0, col).reduce((a, b) => a + b, 0);

                // Draw a border around the cell
                page.drawRectangle({
                    x,
                    y,
                    width: columnWidths[col],
                    height: 25, // Adjust the height as needed
                    borderColor: rgb(0, 0, 0), // Border color (black)
                    borderWidth: 0.2, // Border width
                });

                // Draw the cell text
                page.drawText(rowData[col], {
                    x: x + 5, // Adjust the x-coordinate for text padding
                    y: y + 10, // Adjust the y-coordinate for text centering
                    size: fontSize,
                    font: poppinsFont,
                    color: rgb(0, 0, 0), // Black color,
                });
            }

            currentPageRows++;

            // Check if it's time to move to a new page
            if (currentPageRows > 12) {
                page = pdfDoc.addPage([width, height]);
                currentPageRows = 0;
                y = height - 80;
            }
        }
    }

    if (packageData.length > 0) {
        for (let packageIndex = 0; packageIndex < packageData.length; packageIndex++) {
            const packData = packageData[packageIndex];
            y -= 25; // Adjust the y-coordinate for the next row
            const rowData = [
                packData.packageName,
                'Membership',
                `${packData.quantity}`,
                `${packData.unitPrice} ${packData.currency}`,
                `${packData.amountFormatted} ${packData.currency}`,
            ];
            for (let col = 0; col < rowData.length; col++) {
                const x = 30 + columnWidths.slice(0, col).reduce((a, b) => a + b, 0);

                // Draw a border around the cell
                page.drawRectangle({
                    x,
                    y,
                    width: columnWidths[col],
                    height: 25, // Adjust the height as needed
                    borderColor: rgb(0, 0, 0), // Border color (black)
                    borderWidth: 0.2, // Border width
                });

                // Draw the cell text
                page.drawText(rowData[col], {
                    x: x + 5, // Adjust the x-coordinate for text padding
                    y: y + 10, // Adjust the y-coordinate for text centering
                    size: fontSize,
                    font: poppinsFont,
                    color: rgb(0, 0, 0), // Black color,
                });
            }

            currentPageRows++;

            // Check if it's time to move to a new page
            if (currentPageRows > 12) {
                page = pdfDoc.addPage([width, height]);
                currentPageRows = 0;
                y = height - 80; // Adjust the initial y-coordinate for the new page
            }

        }
    }


    // Draw Table Total, VAT, Total inc. VAT, Fee, Net
    const tableData = [
        { label: 'Total', value: payoutMeta.total + ' SEK' },
        { label: 'VAT', value: '0' },
        { label: 'Total inc. VAT', value: payoutMeta.total + ' SEK' },
        { label: 'Fee', value: payoutMeta.fee + ' SEK' },
        { label: 'Net', value: payoutMeta.net + ' SEK' },
    ];

    // Define border width
    const borderWidth = 0.2;

    // Set the x-coordinate for the right-aligned table
    const tableX = 420; // Adjust this value as needed

    // Loop through the table data and draw each row
    for (const row of tableData) {
        // Draw the label and border for the label cell

        page.drawRectangle({
            x: tableX, // Adjust the x-coordinate for the label cell
            y: y - 50,
            width: 70, // Adjust the width as needed
            height: 30, // Adjust the height as needed
            color: rgb(241 / 255, 243 / 255, 244 / 255),
            borderColor: rgb(0, 0, 0), // Border color (black)
            borderWidth,
        });
        page.drawText(row.label, {
            x: tableX + 5, // Adjust the x-coordinate for the label
            y: y - 40,
            size: fontSize,
            font: poppinsFont, // Assuming you have 'poppinsFont' defined
            color: rgb(0, 0, 0), // Black color,
        });

        // Draw the value and border for the value cell
        page.drawRectangle({
            x: tableX + 70, // Adjust the x-coordinate for the value cell
            y: y - 50,
            width: 70, // Adjust the width as needed
            height: 30, // Adjust the height as needed
            borderColor: rgb(0, 0, 0), // Border color (black)
            borderWidth,
        });
        page.drawText(row.value, {
            x: tableX + 70 + 5, // Adjust the x-coordinate for the value
            y: y - 40,
            size: fontSize,
            font: poppinsFont, // Assuming you have 'poppinsFont' defined
            color: rgb(0, 0, 0), // Black color,
        });


        // Adjust the y-coordinate for the next row
        y -= 30; // Adjust the spacing between rows as needed
    }
    //=================================

    // Serialize the edited PDF to bytes
    const editedPdfBytes = await pdfDoc.save();

    // Create a blob from the edited PDF bytes
    const pdfBlob = new Blob([editedPdfBytes], { type: 'application/pdf' });
    return pdfBlob
}

const generatePDF = async (payoutMeta, packageData, ticketData) => {

    try {
        const pdfBlob = await getPdfBlob(payoutMeta, packageData, ticketData)

        // Create a URL for the blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Trigger the download
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'statement.pdf'; // Provide a default filename
        link.click();
    } catch (error) {
        console.error('Error editing PDF:', error);
    }
};


export { generatePDF, getPdfBlob };
