import React from 'react';
import { Collapse, Input, Button, UncontrolledAlert, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import QueryBuilder, { formatQuery, defaultValueProcessor } from 'react-querybuilder';
import { Loader } from '../../../vibe';
import 'react-querybuilder/dist/query-builder.scss';
import valueEditor from "./valueEditor";
import { addSegment, getSegmentById, updateSegmentById, deleteSegmentById, getSegmentCalc, getSegmentCalcByMemberStatus } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';


const controlClassnames = {
  addGroup: 'btn btn-primary btn-sm mr-2',
  addRule: 'btn btn-info btn-sm mr-2',
  removeGroup: 'ml-auto btn btn-danger',
  removeRule: 'ml-auto btn btn-danger',
  combinators: 'jfBlxm',
  fields: 'jfBlxm',
  operators: 'jfBlxm',
  value: 'jfBlxm'
};


// Value Processor - Get values for SQL and JSON
const valueProcessor = (field, operator, value) => {

  if (value.length > 0) {
    // treeselect
    if (field === 'studyField' || field === 'city' || field === 'org' || field === 'semester' || field === 'membership') {
      if (value.length > 0) {
        value = value.map((v) => v.value).join(',')
      } else if (value[0] !== undefined) {
        value = value[0]
      }
    }
    if (field === 'study_pace') {
      return value
    }
  } else {
    value = ''
  }
  
  return defaultValueProcessor(field, operator, value);
};


class SegmentNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      querySql: [],
      queryJson: [],
      queryJsonView: [],
      isOpen: false,
      fetchErr: false,
      segmentSaved: false,
      calcMatch: null,
      isCalculating: false,
      didEdit: false,
      segmentSaveError: false,
      deleteModal: false,
      organization_id: [200],

    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this)
  }


  segmentCalc(id) {
    this.setState({ isCalculating: true })
    getSegmentCalc(id, (data) => {
      if (data) {
        this.setState({ calcMatch: data[0].count, isCalculating: false })
      }
    }, (error) => {
      this.setState({ isCalculating: false })
    })

    getSegmentCalcByMemberStatus(id, 'A', (data) => {
      if (data) {
        this.setState({ calcActiveMatch: data[0].count })
      }
    }, (error) => {

    })

  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.segmentSaved) {
      return
    }

    const form = event.target
    let name = form.querySelector('input[name="title"]').value

    // Validation for city, studyField, org, semester if empty
    const regex = /(city|studyField|org|semester|membership)\s(in|not in)\s(\(''\))/g
    const found = this.state.querySql.match(regex);
    if (found) {
      this.setState({ checkEmpty: found })
      return;
    } else {
      this.setState({ checkEmpty: false })
    }

    // Check if no rule block!
    if (this.state.queryJsonView.rules.length === 0) {
      this.setState({ segmentSaveError: 'You have to add at least one Rule Block!' })
      setTimeout(() => this.setState({ segmentSaveError: false }), 5000)
      return
    }

    // reset calc
    this.setState({ calcMatch: null, calcActiveMatch: null })

    // if ID exist, lets update
    if (this.props.segmentId) {
      var data = {
        id: parseInt(this.props.segmentId),
        name: name,
        sql_data: this.state.querySql,
        json_data: this.state.queryJson,
        organization_id: this.state.organization_id
      }

      updateSegmentById(data, (data) => {
        this.setState({ segmentSaved: true, didEdit: false })
        setTimeout(() => {
          this.setState({ segmentSaved: false })
        }, 5000)
      }, (error) => {
        this.setState({ segmentSaveError: error })
        setTimeout(() => this.setState({ segmentSaveError: false }), 5000)
      })
    } else {

      // Save segment!
      addSegment(name, this.state.querySql, this.state.queryJson, this.state.organization_id, (data) => {
        if (data) {
          this.setState({ segmentSaved: true, didEdit: false })
          setTimeout(() => {
            this.props.history.replace('/segment/edit/' + data.id)
          }, 2000)
        }
      }, (error) => {
        this.setState({ segmentSaveError: error.error.message })
        setTimeout(() => this.setState({ segmentSaveError: false }), 5000)
      })
    }

  }

  handleQueryChange = (query) => {
    this.setState({
      didEdit: this.state.querySql && this.state.querySql.length > 0 ? true : false,
      querySql: formatQuery(query, { format: 'sql', valueProcessor }),
      queryJson: formatQuery(query, { format: 'json_without_ids', valueProcessor }),
      queryJsonView: query
    })
  }

  handleQueryUnionChange = (query) => {
    if(this.props.segmentId){
      // On Update, only update for the first organization
      if (query.rules && query.rules.length > 0 && query.rules[0].value.length > 0) {
        this.setState({ organization_id: query.rules[0].value[0].value })
      }
    }else{
      // On New, take all organization ids so we create segment for each
      if (query.rules && query.rules.length > 0 && query.rules[0].value.length > 0) {
        let values = query.rules[0].value.map( (v)=> v.value).join(',') 
        this.setState({ organization_id: values })
      }
    }
  }

  renderAlerts() {

    if (this.state) {
      if (this.state.segmentSaved) {
        return <UncontrolledAlert color="success">
          Segment {this.props.segmentId ? 'updated! ' : 'saved!'}
        </UncontrolledAlert>
      } else if (this.state.segmentSaveError) {
        return <UncontrolledAlert color="danger">
          Error saving segment, {this.state.segmentSaveError}
        </UncontrolledAlert>
      }
    }
  }

  componentDidMount() {
    if (this.props.segmentId !== undefined) {
      this.fetchQuery()
    } else {
      this.setState({
        preparedQueries: '',
        unionQuery: {
          rules: [{ field: "org", operator: "in", value: [200] }],
          combinator: "and",
          not: "false"
        }
      })
    }
  }

  toggleDelete(e = null) {
    this.setState(prevState => ({
      deleteModal: !prevState.deleteModal
    }));
  }

  onDeleteClick() {
    this.setState({ deleteModal: true })
  }

  deleteSegment() {
    this.setState({ deleteModal: false })
    if (this.props.segmentId) {
      deleteSegmentById(this.props.segmentId, () => {
        this.props.history.push('/segments')
      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({ segmentSaveError: errorMessage })
      })
    } else {
      this.props.history.push('/segments')
    }
  }

  // Fetch data for edit!
  fetchQuery() {
    getSegmentById(this.props.segmentId, (data) => {
      if (data) {
        const dataJson = JSON.parse(data.json_data)
        var preparedQueries = {
          rules: dataJson.rules,
          combinator: dataJson.combinator,
          not: dataJson.not
        };

        this.setState({
          preparedQueries: preparedQueries,
          title: data.name,
          querySql: data.sql_data,
          queryJson: data.json_data,
          queryJsonView: dataJson,
          organization_id: data.organization_id
        })

        if (data.organization_id) {
          var unionQuery = {
            rules: [{ field: "org", operator: "in", value: [data.organization_id] }],
            combinator: "and",
            not: "false"
          };
          this.setState({ unionQuery })
        }

      }
    }, (error) => {
      this.setState({ fetchErr: true })
    })
  }

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  render() {
    const fields = [
      {
        name: 'gender', label: 'Gender', valueEditorType: 'radio',
        values: [
          { name: 'M', label: 'Male' },
          { name: 'K', label: 'Female' },
        ],
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
        ]
      },
      {
        name: 'age',
        label: 'Age',
        inputType: 'number',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' },
        ]
      },
      {
        name: 'study_pace',
        label: 'Study pace',
        inputType: 'number',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' },
        ]
      },
      {
        name: 'study_pace_period', label: 'Study pace period', valueEditorType: 'radio',
        values: [
          { name: 'active', label: 'Active' },
          { name: 'not_active', label: 'Not active' },
        ],
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
        ]
      },
      {
        name: 'birthdate',
        label: 'Birth date',
        valueEditorType: 'datepicker',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' },
        ]
      },
      {
        name: 'created_at',
        label: 'Became member',
        valueEditorType: 'datepicker',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' },
        ]
      },
      {
        name: 'zip_code',
        label: 'Zip code',
        inputType: 'number',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' },
          { name: 'contains', label: 'contains' },
          { name: 'beginsWith', label: 'begins with' },
          { name: 'endsWith', label: 'ends with' },
          { name: 'doesNotContain', label: 'does not contain' },
          { name: 'doesNotBeginWith', label: 'does not begin with' },
          { name: 'doesNotEndWith', label: 'does not end with' },
        ]
      },
      {
        name: 'card_extra_text',
        label: 'Card extra text',
        inputType: 'text',
        operators: [
          { name: 'contains', label: 'contains' },
          { name: 'beginsWith', label: 'begins with' },
          { name: 'doesNotContain', label: 'does not contain' },
          { name: 'matchesRegex', label: 'matches regex' },
        ]
      },
      {
        name: 'city', label: 'City',
        values: [],
        defaultValue: '',
        valueEditorType: "treeselect",
        checkEmpty: this.state.checkEmpty,
        operators: [
          { name: 'in', label: 'in' },
          { name: 'notIn', label: 'not in' },
        ]
      },
      {
        name: 'studyField', label: 'Study orientation',
        values: [],
        defaultValue: '',
        valueEditorType: "treeselect",
        checkEmpty: this.state.checkEmpty,
        operators: [
          { name: 'in', label: 'in' },
          { name: 'notIn', label: 'not in' },
        ]
      },
      {
        name: 'org', label: 'School',
        values: [],
        defaultValue: '',
        valueEditorType: "treeselect",
        checkEmpty: this.state.checkEmpty,
        operators: [
          { name: 'in', label: 'in' },
          { name: 'notIn', label: 'not in' },
        ]
      },
      {
        name: 'semester', label: 'Graduation semester',
        values: [],
        defaultValue: '',
        valueEditorType: "treeselect",
        checkEmpty: this.state.checkEmpty,
        operators: [
          { name: 'in', label: 'in' },
          { name: 'notIn', label: 'not in' },
        ]
      },
      {
        name: 'membership', label: 'Membership',
        values: [],
        defaultValue: null,
        valueEditorType: "treeselect",
        checkEmpty: this.state.checkEmpty,
        operators: [
          { name: 'in', label: 'in' },
          { name: 'notIn', label: 'not in' },
        ]
      },

      // TODO should not be hardcoded..
      {
        name: 'app_id', label: 'App id', valueEditorType: 'radio',
        values: [
          { name: 'com.addreax.stuk', label: 'com.addreax.stuk' },
          { name: 'se.studentkortet.android', label: 'se.studentkortet.android' },
          { name: 'se.studentkortet.Studentkortet', label: 'se.studentkortet.Studentkortet' },
        ],
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
        ]
      },
      {
        name: 'device_type', label: 'Device type', valueEditorType: 'radio',
        values: [
          { name: 'iOS', label: 'iOS' },
          { name: 'Android', label: 'Android' },
        ],
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
        ]
      },
      {
        name: 'nation_id',
        label: 'Nation id' ,
        inputType: 'number',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' }        
        ],
      },
      {
        name: 'nation_count',
        label: 'Nation count' ,
        inputType: 'number',
        operators: [
          { name: '=', label: '=' },
          { name: '!=', label: '!=' },
          { name: '<', label: '<' },
          { name: '>', label: '>' },
          { name: '<=', label: '<=' },
          { name: '>=', label: '>=' }        
        ],
      }
    ];


    const trans = {
      removeRule: {
        label: <i className="fa fa-trash" />,
        title: "Remove rule",
      },
      removeGroup: {
        label: <i className="fa fa-trash" />,
        title: "Remove group",
      },
      addRule: {
        label: <div><i className="fa fa-plus"></i>&nbsp;Add rule block</div>,
        title: "Add rule",
      },
      addGroup: {
        label: <div><i className="fa fa-plus"></i>&nbsp;Add Group</div>,
        title: "Add group",
      },
    }


    const fieldsUnion = [
      {
        name: 'org', label: 'Organization',
        values: [],
        defaultValue: '',
        valueEditorType: "treeselect",
        checkEmpty: this.state.checkEmpty,
        operators: [
          { name: 'in', label: 'in' },
        ]
      }
    ];

    if (this.state.fetchErr) {
      return 'Segment does not exist or you do not have permissions to view it!'
    }

    return (
      <React.Fragment>
        <header className="app-header-page justify-content-end">
          <div>
            {this.props.segmentId &&
              <Button
                outline={true}
                color="danger"
                size="btn"
                onClick={() => this.onDeleteClick()}>
                <i className="fa fa-trash"></i>&nbsp;Delete
              </Button>}
            <Button
              className="ml-3"
              color="primary"
              size="btn btn-success"
              type="submit"
              form="segmentForm"
            >
              <i className="fa fa-check"></i>&nbsp;{this.props.segmentId ? 'Update ' : 'Save '} segment
            </Button>
          </div>
        </header>

        {this.renderAlerts()}
        <div className="row mb-3">
          <div className="col">
            <h1 className="h4 mb-0">{this.props.segmentId ? 'Update ' : 'New '} Segment</h1>
          </div>
        </div>

        <div className="card">
          <div className="card-body">

            <form id="segmentForm" onSubmit={this.handleSubmit}>
              <div className="form-group">
                <Input type="text" name="title" id="title" defaultValue={this.state.title} placeholder="Segment title..." autoComplete="off" required />
              </div>

              <QueryBuilder
                query={this.state.preparedQueries}
                fields={fields}
                onQueryChange={(q) => this.handleQueryChange(q)}
                controlClassnames={controlClassnames}
                controlElements={{ valueEditor: valueEditor }}
                showNotToggle={true}
                resetOnFieldChange={true}
                translations={trans}
              />
            </form>

            <div className="row mb-3 mt-3">
              <div className="col">
                <Button color="primary" onClick={this.toggle}>
                  <i className="fa fa-code"></i>&nbsp;{this.state.isOpen ? 'Hide ' : 'Show '} Query
                </Button>
              </div>
              <div className="col col-md-4">
                <Button color="primary" block onClick={() => this.segmentCalc(this.props.segmentId)} disabled={!this.props.segmentId}>
                  <i className="fa fa-calculator"></i>&nbsp; Calculate
                </Button>
                {this.state.isCalculating ? <Loader type="dots" small /> : null}
                {this.state.didEdit && <small className="text-muted">Update segment before clicking on Calculate.</small>}
              </div>
            </div>

            <div className="row">
              <Collapse isOpen={this.state.isOpen} className="col-8">
                <pre className="preCode_sql">{this.state.querySql}</pre><span className="queryTag">SQL Query</span>
                <pre className="preCode_json">{JSON.stringify(JSON.parse(formatQuery(this.state.queryJsonView, 'json_without_ids')), undefined, 2)}</pre><span className="queryTag">JSON Query</span>
              </Collapse>

              {this.state.calcMatch !== null &&
                <div className={this.state.isOpen ? 'col-4 text-center' : 'col-4 offset-8 text-center'}>
                  <pre className="preCode_sql">
                    <div className="h2">{this.state.calcMatch}</div>
                    <h5>Members matched</h5>

                    <div className="h5 mt-4">({this.state.calcActiveMatch} active members)</div>
                  </pre>
                </div>
              }
            </div>

          </div>
        </div>



        <div className="row mb-3">
          <div className="col">
            <h2 className="h5 mb-0">{this.props.segmentId ? 'Update ' : 'Select '} connected organization</h2>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <form id="segmentForm" onSubmit={this.handleSubmit}>
              <QueryBuilder
                query={this.state.unionQuery}
                fields={fieldsUnion}
                onQueryChange={(q) => this.handleQueryUnionChange(q)}
                controlClassnames={controlClassnames}
                controlElements={{
                  valueEditor: valueEditor,
                  addGroupAction: () => null,
                  addRuleAction: () => null,
                  combinatorSelector: () => null,
                  removeRuleAction: () => null,
                }}
                showNotToggle={false}
                resetOnFieldChange={true}
                translations={trans}
              />
            </form>
          </div>
          <div className="card-footer">
            <p className="mini-font">Note: <br/>
              - Only use more than one organization when creating a new segment. <br />
              When updating a segment only the first organization will be updated.
            </p>

            <p className="mini-font">
            <b>nation id</b>: Uppsala nation id.<br/>
            <b>nation count</b>: Uppsala nation count.
            </p>
          </div>
        </div>

        <Modal isOpen={this.state.deleteModal} toggle={this.toggleDelete} className="deleteModal">
          <ModalHeader toggle={this.toggleDelete}>Delete segment</ModalHeader>
          <ModalBody>
            <h5>Are you sure you want to delete this segment? This action can not be undone</h5>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" className="px-5" onClick={() => this.deleteSegment()}>Delete</Button>{' '}
            <Button color="secondary" className="px-5" onClick={this.toggleDelete}>Cancel</Button>
          </ModalFooter>
        </Modal>

      </React.Fragment>
    );
  }
}
export default SegmentNew
