import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SidebarNav, PageContent, PageAlert, Page } from '../vibe';
import Logo from '../assets/images/logo_white.svg';
import Logo_icon from '../assets/images/logo_icon.svg';
import nav from '../_nav';
import routes from '../views';
import ContextProviders from '../vibe/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../vibe/helpers/handleTabAccessibility';
import { getRefreshToken } from '../views/pages/login/Token';
import { getCurrentUser } from '../vibe/helpers/apiHelper';

const MOBILE_SIZE = 992;

export default class DashboardLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE
    };

    this.handleClick = this.handleClick.bind(this);
  }


  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
    document.addEventListener('mousedown', this.handleClick, false);

    getCurrentUser((user) => {
      this.setState({ user: user })
    }, (error) => {
      console.log('Error get Current user!')
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick(e) {
    if (this.node.contains(e.target) || e.target.closest('.sidebar-toggle')) {
      this.setState({ clickInsideSidebar: true })
    } 
  }

  toggleSideCollapse = () => {
    if (this.state.clickInsideSidebar) {
      this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
    }
  };

  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed || this.state.isMobile ? 'side-menu-collapsed' : '';

    if (!getRefreshToken()) {
      return <Redirect to="/login" />;
    }

    return (
      <ContextProviders>
        <div className={`app ${sidebarCollapsedClass}`}>
          <PageAlert />
          <div className="app-body">
            <div className="sidebar-wrap" ref={node => { this.node = node; }}>
              <SidebarNav
                user={this.state.user} 
                nav={nav}
                logo={Logo}
                logo_icon={Logo_icon}
                isSidebarCollapsed={sidebarCollapsed}
                toggleSidebar={this.toggleSideCollapse}
                {...this.props}
              />
            </div>
            <Page>
              <PageContent>
                <Switch>
                  {routes.map((page, key) => (
                    <Route path={page.path} component={page.component} key={key} />
                  ))}
                  <Redirect from="/" to="/home" />
                </Switch>
              </PageContent>
            </Page>
          </div>
        </div>
      </ContextProviders>
    );
  }
}
