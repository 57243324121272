import React, { Component }  from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { parseErrorMessage, superPrettyPrintDate } from '../../../../../vibe/helpers/util';
import { getOrganizationEventOccurrenceNotifications } from '../../../../../vibe/helpers/apiHelper';
import EditOccurrenceNotification from './EditOccurrenceNotification';
import { Loader } from '../../../../../vibe';
import SimpleNotification from '../../../../elements/notifications/SimpleNotification';
import { getSwedenDateNow, toSwedenDate } from '../../../../../vibe/helpers/dateHelper';

const MAX_NOTIFICATIONS_PER_OCCURRENCE = 3

class OccurrenceNotifications extends Component {

    constructor(props) {
        super(props);
        this.state = {

        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.init()
    }

    init() {
        this.setState({error: null, loading: true})
        getOrganizationEventOccurrenceNotifications(this.props.occurrence.id, (result) => {
            this.setState({occurrence: result, loading: false})
        }, (error) => {
            const errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })
    }

    toggleModal() {
        this.props.toggle()
    }

    toggleNewNotificationModal() {
        if (this.state.showNewOccurrenceNotificationModal) {
            this.setState({showNewOccurrenceNotificationModal: false, notificationToEdit: null})
            this.init()
        }
    }

    onNewNotification() {
        this.setState({showNewOccurrenceNotificationModal: true, notificationToEdit: null})
    }

    renderNotifications() {
        if (this.state.error) {
            return <p>An error occurred: {this.state.error}</p>
        }

        if (this.state.loading) {
            return <Loader type="dots" />
        }

        if (!this.state?.occurrence?.notifications?.length) {
            return <p>No notifications for this occurrence</p>
        }

        return this.state?.occurrence?.notifications?.map((notification) => {
            return <SimpleNotification onClick={() => {this.onClickNotification(notification)}} key={notification.id} notification={notification} />
        })
    }

    onClickNotification(notification) {
        this.setState({showNewOccurrenceNotificationModal: true, notificationToEdit: notification})
    }

    occurrenceDateHasPassed(occurrence) {
        if (occurrence?.end_date) {
            const now = getSwedenDateNow()
            const endDate = toSwedenDate(occurrence.end_date)
            if (endDate.valueOf() < now.valueOf()) {
              return true
            }
        }
        return false
    }

    hasMaxNotifications(occurrence) {
        return occurrence?.notifications?.length >= MAX_NOTIFICATIONS_PER_OCCURRENCE
    }

    canCreateNewNotification(occurrence) {
        if (this.hasMaxNotifications(occurrence) || this.occurrenceDateHasPassed(occurrence)) {
            return false
        }
        return true
    }

    renderNewNotificationButton(occurrence) {
        if (this.hasMaxNotifications(occurrence)) {
            return <Button size='lg' className={'button-tooltip px-5 mt-3'} color="primary" disabled={true} onClick={() => this.onNewNotification()}>{"New notification"}
                <span className={'button-tooltiptext'}>Limit reached (max {MAX_NOTIFICATIONS_PER_OCCURRENCE} per occurrence)</span>
            </Button>
        }

        if (this.occurrenceDateHasPassed(occurrence)) {
            return <Button size='lg' className={'button-tooltip px-5 mt-3'} color="primary" disabled={true} onClick={() => this.onNewNotification()}>{"New notification"}
                <span className={'button-tooltiptext'}>The occurrence has ended</span>
            </Button>
        }

        return <Button size="lg" className="px-5 mt-3" color="primary" onClick={() => this.onNewNotification()}>{"New notification"}</Button>
    }

    render() {
        return <> 
            {this.state.showNewOccurrenceNotificationModal && <EditOccurrenceNotification occurrence={this.props.occurrence} notification={this.state.notificationToEdit} segmentOptions={this.props.segmentOptions} onSaveNotification={this.props.onSaveNotification} onDeleteNotification={this.props.onDeleteNotification} toggle={this.toggleNewNotificationModal.bind(this)} />} 

            <Modal scrollable={false} size='lg' isOpen={true} toggle={this.toggleModal} className="previewModal">
                    <ModalHeader toggle={this.toggleModal}><strong>{"Notifications"}</strong></ModalHeader>

                    <ModalBody>

                        <div><small><strong>{this.props.event.title}</strong></small></div>
                        <div><small>{superPrettyPrintDate(this.props.occurrence.start_date)} - {superPrettyPrintDate(this.props.occurrence.end_date)}</small></div>

                        <div className='mt-4'>
                            {this.renderNotifications()}
                        </div>
                        
                    </ModalBody>

                    <ModalFooter className='d-flex justify-content-end'>
                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                        {this.renderNewNotificationButton(this.state.occurrence)}
                    </ModalFooter>
                       
            </Modal>
        </>
    }

}

export default OccurrenceNotifications;