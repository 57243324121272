import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getNotificationSendingData, getNotificationStatusString } from '../../../vibe/helpers/util';
import { toLocaleString } from '../../../vibe/helpers/dateHelper';

class Notification extends Component {

  getScheduleDate(schedule) {
    if (schedule) {
      return toLocaleString(schedule)
    }
    return "No schedule"
  }

  render() {

    return (
      <Link className="member-card-link" from={'/notifications'} to={'/notification/edit/' + this.props.notification.id}>
        <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">

          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Scheduled</p>
            <p className="list-item-text mb-0">{this.getScheduleDate(this.props.notification.schedule)}</p>
          </div>

          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Customer</p>
            <p className="list-item-text mb-0">{this.props.notification.customer}</p>
          </div>

          <div className="mb-1 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Estimated Reach</p>
            <p className="list-item-text mb-1">{this.props.notification.total_members}</p>
          </div>

          <div className="mb-1 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Segment</p>
            <p className="list-item-text mb-1">{this.props.notification.segment ? this.props.notification.segment.name : 'Everyone'}</p>
          </div>

          <div className="mb-1 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Status</p>
            <p className="list-item-text mb-1">{getNotificationStatusString(this.props.notification.status)}</p>
          </div>

          {this.props.notification.status === 2 &&
            <div className="mb-1 text-small w-50 w-sm-100">

              <div>
                <p className="list-item-heading mb-1">Progress</p>
                <p className="list-item-text mb-1">{getNotificationSendingData(this.props.notification)}</p>
              </div>
            </div>
          }
          
        </Card>
      </Link>
    );
  }

}

export default Notification;
