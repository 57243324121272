export function cloneAll(itemsToClone, keys, getAdditionalProperties) {
    const clones = []

    if (itemsToClone?.length > 0) {
        for (const item of itemsToClone) {
            const cloned = clone(item, keys, getAdditionalProperties)
            clones.push(cloned)
        }
    }
    return clones
}

export function clone(itemToClone, keys, getAdditionalProperties) {
    const clone = {}

    keys.forEach(key => {
        if (itemToClone.hasOwnProperty(key)) {
            clone[key] = itemToClone[key];
        }
    })

    Object.assign(clone, getAdditionalProperties())
    return clone
}