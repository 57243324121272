import React from 'react';
import { Link } from 'react-router-dom';
import { Card, FormGroup } from 'reactstrap';
import { getOrganizationProducts } from '../../../../vibe/helpers/apiHelper';
import { getCurrentUser } from '../../../../vibe/helpers/apiHelper';
import PaginationComp from '../../../../vibe/components/utilities/Pagination/Pagination';
import { Loader } from '../../../../vibe';

const pageSize = 10

class OrganizationProducts extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({ user: user })
            
            var where = '&filter[where][archive][neq]=true'
            var skip = 0

            let organizationId = this.state.user.organization_id // this.props.match.params.organizationId
            getOrganizationProducts(organizationId,skip, where, (result) => {
                this.setState({organizationId: organizationId, organizationProducts: result.products, totalCount: result.totalCount, currentPage: 1, loading: false, error: null})
            }, (error) => {
                this.setState({error: error, loading: false})
            })
        })
    }

    onClickPagination(e, page) {
        this.setState({ currentPage: page, loading: true })
        var offset = (page - 1) * pageSize
    
        var where = ''
        let organizationId = this.state.user.organization_id

        getOrganizationProducts(organizationId, offset, where, (result) => {
          this.setState({ totalCount: result.totalCount, organizationProducts: result.products, loading: false })
        }, (error) => {
          this.setState({ loading: false, totalCount: 0, currentPage: -1 })
          console.log(error)
        })
    }

    renderProducts() {
        return this.state.organizationProducts.map((organizationProduct) => {
            return <Link className="member-card-link" to={'/organization-products/edit/' + organizationProduct.id} key={organizationProduct.id}>
                <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Title</p>
                        <p className="list-item-text mb-0">{organizationProduct.title}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Description</p>
                        <p className="list-item-text mb-0">{organizationProduct.description}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Price</p>
                        <p className="list-item-text mb-0">{organizationProduct.price && organizationProduct.price + ' ' + organizationProduct.currency}</p>
                    </div>                    
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Created Date</p>
                        <p className="list-item-text mb-0">{new Date(organizationProduct.created_at).toISOString().split('T')[0]}</p>
                    </div>
                    <div className="mb-1 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Updated Date</p>
                        <p className="list-item-text mb-0">{new Date(organizationProduct.updated_at).toISOString().split('T')[0]}</p>
                    </div>                       
                </Card>
            </Link>
        }) 
    }

    getLastPage() {
        return Math.ceil(this.state.totalCount / pageSize)
    }

    renderPagination() {
        return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />    
    }

    formatNumber(number){
        if(number){
          return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")    
        }
    }

    render() {
        if (this.state.loading) {
            return <Loader type="dots" />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {   
            return <React.Fragment>
 
                <div className="row mb-3 align-items-center">
                    <div className="col">
                        <h1 className="h4 mb-0">Products</h1>
                        <small className="text-muted">{this.formatNumber(this.state.totalCount)} products</small>
                    </div>

                    <div className="col col-md-3">
                        <FormGroup className="mb-0 pt-1">
                            <Link className="btn btn-primary block" to={"/organization-products/new/" + this.state.organizationId}>
                                New Product
                            </Link>
                        </FormGroup>
                    </div>
                </div>

                {this.renderProducts()} 
                {this.state.totalCount > 10 && this.renderPagination()}

            </React.Fragment>      
        }
    }

}
export default OrganizationProducts
