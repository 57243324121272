import React from 'react';
import { Button} from 'reactstrap';
import TransactionActionsModal from './TransactionActionsModal';

const REFUND_PERIOD_DAYS = 14

class TransactionActions extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            action: '',
            item: {},
            loading: false,
            opened: false
        }
    }

    toggleModal(action) {
        this.setState(prevState => ({
            opened: !prevState.opened,
            action: action
        }))
    }

    renderModal(transaction, member) {

        if (!transaction || !member || !this.state.opened || !this.state.action) {
            return null;
        }
        
        return <TransactionActionsModal transaction={this.props.transaction} member={this.props.member} toggle={this.toggleModal.bind(this)} opened={this.state.opened} action={this.state.action} onAction={this.props.onAction}/>                    
    }

    onDenyClick(e) {
        e.preventDefault()
        this.toggleModal('Refund')
    }

    onAcceptClick(e) {
        e.preventDefault()
        this.toggleModal('Accept')
    }

    canRefund(transaction) {
        var today = new Date();
        var createdOn = new Date(transaction.created_at);

        var msInDay = 24 * 60 * 60 * 1000;
        createdOn.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0)
        var diff = (+today - +createdOn) / msInDay

        var disabled = diff > REFUND_PERIOD_DAYS
        return !disabled
    }

    isTicketTransaction(transaction) {
        return transaction?.ticket_transactions && transaction?.ticket_transactions?.length > 0
    }

    render() {
        var acceptButton, refundButton
        if ([3].includes(this.props.transaction.status)) {
            acceptButton = <Button className='mx-0 my-0 mb-1 d-inline-block mr-2' disabled={this.isTicketTransaction(this.props.transaction)} onClick={(e) => this.onAcceptClick(e)} color="success">Accept</Button>
        }

        if ([3, 4, 6].includes(this.props.transaction.status)) {

            var canRefund = this.canRefund(this.props.transaction)

            if (canRefund) {
                refundButton = <Button className={'d-inline-block mb-1 mr-2'} onClick={(e) => this.onDenyClick(e)} color="danger">Refund</Button>
            } else {
                refundButton = <Button className={'button-tooltip mb-1 d-inline-block mr-2'} disabled={true} onClick={(e) => this.onDenyClick(e)} color="danger">Refund
                    <span className={'button-tooltiptext'}>Can not refund ({REFUND_PERIOD_DAYS} days has passed since purchase)</span>
                </Button>
            }

        }

        return <>
        <div>
            {acceptButton}
            {refundButton}
            {this.renderModal(this.props.transaction, this.props.member)}
        </div>
        </>
    }

}

export default TransactionActions