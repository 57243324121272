import React, { Component } from 'react';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { formatDateTime } from '../../../vibe/helpers/dateHelper';
import { containsOnlyDigits } from '../../../vibe/helpers/util';
import { isSuperUser } from '../../../vibe/helpers/permissionHelper';

class MemberForm extends Component {

    componentDidMount(){
        
    }

    render() {
        return this.props && this.props.member ? <CardBody>
            <Form>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for="first_name">First name</Label>
                            <Input type="text" name="first_name" id="first_name" defaultValue={this.props.member.first_name} onChange={(e) => this.props.onChange(e, 'first_name')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="last_name">Last name</Label>
                            <Input type="text" name="last_name" id="last_name" defaultValue={this.props.member.last_name} onChange={(e) => this.props.onChange(e, 'last_name')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="personal_identification_number">Personal identification number</Label>
                            <Input type="text" name="personal_identification_number" id="personal_identification_number" defaultValue={this.props.member.personal_identification_number} disabled={containsOnlyDigits(this.props.pin)} onChange={(e) => this.props.onChange(e, 'personal_identification_number')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="member_id">Member Id</Label>
                            <Input type="text" name="member_id" id="member_id" defaultValue={this.props.member.id} disabled />
                        </FormGroup>

                        <FormGroup>
                            <Label for="created_at">Created at</Label>
                            <Input type="text" name="created_at" id="created_at" defaultValue={formatDateTime(this.props.member.created_at)} disabled />
                        </FormGroup>

                        <FormGroup>
                            <Label for="status">Status</Label>
                            <Input type="text" name="status" id="status" defaultValue={this.props.member.status} disabled />
                        </FormGroup>
                        
                        {isSuperUser(this.props.user) ?
                            <FormGroup>
                                <Label for="status">Organization</Label>
                                <Input type="text" name="organization_id" id="organization_id" defaultValue={this.props.member.organization_id} disabled />
                            </FormGroup>
                            : null}
                        
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="text" name="email" id="email" defaultValue={this.props.member.email} onChange={(e) => this.props.onChange(e, 'email')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="mobile_number">Mobile number</Label>
                            <Input type="text" name="mobile_number" id="mobile_number" defaultValue={this.props.member.mobile_number} onChange={(e) => this.props.onChange(e, 'mobile_number')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="street_address">Street address</Label>
                            <Input type="text" name="street_address" id="street_address" defaultValue={this.props.member.street_address} onChange={(e) => this.props.onChange(e, 'street_address')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="co_address">C/O</Label>
                            <Input type="text" name="co_address" id="co_address" defaultValue={this.props.member.co_address} onChange={(e) => this.props.onChange(e, 'co_address')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="city">City</Label>
                            <Input type="text" name="city" id="city" defaultValue={this.props.member.city} onChange={(e) => this.props.onChange(e, 'city')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="zip_code">Zip code</Label>
                            <Input type="text" name="zip_code" id="zip_code" defaultValue={this.props.member.zip_code} onChange={(e) => this.props.onChange(e, 'zip_code')} />
                        </FormGroup>

                        {isSuperUser(this.props.user) ?
                            <FormGroup>
                                <Label for="status">Country</Label>
                                <Input type="text" name="country" id="country" defaultValue={this.props.member.country} onChange={(e) => this.props.onChange(e, 'country')} />
                            </FormGroup>
                            : null}
                        
                    </Col>
                </Row>
            </Form>

        </CardBody> : null
    }

}

export default MemberForm;
