export const Config = {
    app: {
        name: 'Addreax-ms-panel'
    },
    server: {
        dev: 'https://dev.api.stuk.co',
        prod: 'https://api.stuk.co',
        local: 'http://localhost:3000'
    },
    api() {
        if (window.location.hostname === 'localhost') {
            return this.server.local
        } else if (window.location.hostname === 'dev.ms.addreax.com') {
            return this.server.dev
        } else if (window.location.hostname === 'ms.addreax.com') {
            return this.server.prod
        }
    }
};
