import { Config } from "../../config"
import { _delete, get, patch, post } from "../../vibe/helpers/apiHelper"

export async function getThemes(onSuccess, onError) {
    var filter = {
        order: 'created_at DESC'
    };
    var encodedFilter = encodeURIComponent(JSON.stringify(filter));
    var url = `${Config.api()}/membership-themes?filter=${encodedFilter}`;
    get(url, onSuccess, onError)
}

export async function createTheme(data, onSuccess, onError) {
    var url = Config.api() + '/membership-themes'
    post(url,data, onSuccess, onError)
}

export async function getTheme(id, onSuccess, onError) {
    var url = `${Config.api()}/membership-themes/${id}`;
    get(url, onSuccess, onError)
}

export async function updateTheme(id, data, onSuccess, onError) {
    const url = `${Config.api()}/membership-themes/${id}`;
    patch(url, data, onSuccess, onError)
}

export async function deleteTheme(id, onSuccess, onError) {
    const url = `${Config.api()}/membership-themes/${id}`;
    _delete(url, onSuccess, onError)
}



export async function cloneTheme(themeId, onSuccess, onError) {
    getTheme(themeId, (themeData) => {
        const { id, created_at, name, 
                updated_at, organization_id,
                ...rest } = themeData;

        const data = {
            name: themeData.name + " (Clone)",
            ...rest
        }
        createTheme(data, onSuccess, onError);
    }, onError);
}