import React from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { addMemberMembership, addMemberParentMembership, createInactiveMember, fetchVerifiedStudentInfo, memberExists, upsertVerifiedStudentInfo } from '../../../vibe/helpers/apiHelper';
import { shortedPersonalIdentificationNumber } from '../../../vibe/helpers/util';
import VerifiedStudentInfo from '../../elements/members/VerifiedStudentInfo';
import { STEP_CONFIRM_EXISTING_MEMBER, STEP_INIT, STEP_LOADING, STEP_MESSAGE, STEP_NEW_MEMBER } from './NewMember';
import Select from 'react-select'

class NewMemberSe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        studyPace: 0,
        step: STEP_INIT,
        notify_member: false,
        didFetchVerifiedStudentInfo: false,
        verifiedStudentInfo: null,
        verifiedStudentInfoError: null,
        didEditVerifiedStudentInfo: false,
        membership_id: 0
    }
  }

  memberSavedCallback = (memberId) => {
    this.setState({step: STEP_MESSAGE, message: 'Member saved! Redirecting...'})
    this.props.onSuccess(memberId)
  }

  onChange(value, key) {
    console.log({value, key})
    this.setState({[key]: value})
  }

  onNextClick(currentStep) {

    if (currentStep === STEP_INIT) {
        var data = {
            personal_identification_number: this.state.personal_identification_number
        }

        memberExists(data, (result) => {

            if (result && result.result) {
                if (result.belongs_to_organization) {
                    this.setState({step: STEP_MESSAGE, message: 'Member already exists in your organization, redirecting...'})
                    this.props.onSuccess(result.member.id)
                } else {
                    this.setState({member_id: result.member.id, first_name: result.member.first_name, last_name: result.member.last_name, step: STEP_CONFIRM_EXISTING_MEMBER})
                }
            } else {
                let personalIdentificationNumber = result.member.personal_identification_number
                fetchVerifiedStudentInfo(personalIdentificationNumber, (verifiedStudentInfo) => {
                    this.setState({step: STEP_NEW_MEMBER, personal_identification_number: personalIdentificationNumber, didFetchVerifiedStudentInfo: true, verifiedStudentInfo: verifiedStudentInfo, verifiedStudentInfoError: null, studyPace: verifiedStudentInfo ? verifiedStudentInfo.study_pace : 0})
                }, (error) => {
                    this.setState({step: STEP_NEW_MEMBER, personal_identification_number: personalIdentificationNumber, didFetchVerifiedStudentInfo: true, verifiedStudentInfoError: error, verifiedStudentInfo: {study_pace: '0'}, studyPace: 0})
                })
            }

        }, (error) => {
            this.setState({step: STEP_MESSAGE, message: 'Error checking member exists'})
        })

    } else if (currentStep === STEP_NEW_MEMBER) {
        this.setState({step: STEP_MESSAGE, message: 'Saving member...'})

        let data = {
            personal_identification_number: this.state.personal_identification_number,
            first_name: this.state.first_name, 
            last_name: this.state.last_name, 
            street_address: this.state.street_address, 
            co_address: this.state.co_address,
            zip_code: this.state.zip_code,
            city: this.state.city,
            email: this.state.email,
            mobile_number: this.state.mobile_number
        }
        createInactiveMember(data, this.state.notify_member, (createMemberResult) => {

            this.addMembership(this.state.membership_id, createMemberResult.id, () => {

                var personalIdentificationNumberShort = shortedPersonalIdentificationNumber(this.state.personal_identification_number)
    
                this.verifyStudent(personalIdentificationNumberShort, this.state.verifiedStudentInfo, () => this.memberSavedCallback(createMemberResult.id), (error) => {
                    this.setState({step: STEP_MESSAGE, message: 'Error saving verified info'})
                })
            })

        }, (error) => {
            this.setState({step: STEP_MESSAGE, message: 'Error saving member'})
        })

    } else if (currentStep === STEP_CONFIRM_EXISTING_MEMBER) {
        this.addMembership(this.state.membership_id, this.state.member_id, () => this.memberSavedCallback(this.state.member_id))
    } else {
        this.setState({step: currentStep + 1})
    }

  }

  addMembership(membershipId, memberId, onSuccess) {

    var membershipCallback = (result) => {
        if (result) {
            onSuccess()
        } else {
            this.setState({step: STEP_MESSAGE, message: 'Error saving membership'})
        }
    }

    var onError = (error) => {
        this.setState({step: STEP_MESSAGE, message: 'Error saving membership'})
    }

    if (membershipId) {
        addMemberMembership(memberId, membershipId, membershipCallback, onError)
    } else {
        addMemberParentMembership(memberId, membershipCallback, onError)
    }

  }

  onSelectMembership(membershipId) {
    this.setState({membership_id: membershipId})
  }

  renderStep(step) {

    switch (step) {

        case STEP_LOADING:
            return 'Loading...'

        case STEP_INIT:
            return <>
                      <Card>
                        <CardBody>
                                <Row>
                                    <Col sm="4">
                                        
                                        <FormGroup>
                                            <Label for="personal_identification_number">Personal identification number</Label>
                                            <Input type="text" name="personal_identification_number" id="personal_identification_number" onChange={(e) => this.onChange(e.target.value, 'personal_identification_number')} />
                                        </FormGroup>

                                    </Col>
                                </Row>
                        </CardBody>
                      </Card>
                      <Button className='mx-0 my-0 d-inline-block mr-2' onClick={(e) => this.onNextClick(step)} color="success">Next</Button>
                    </>

        case STEP_CONFIRM_EXISTING_MEMBER:
            // confirmation if member already exists
            return <>
                    <p>{this.state.first_name}{' '}{this.state.last_name}{' - '}{this.state.personal_identification_number}</p>
                    <p>This member already exists in our system. Add to your organization?</p>

                    <Row className='mt-4'>
                        <Col sm='6'>
                            <FormGroup className='mt-3'>
                                <Label for="membership">Membership</Label>
                                <Select options={this.props.memberships} onChange={(e) => this.onSelectMembership(e.value)} placeholder={"Select membership..."} /> 
                            </FormGroup> 
                        </Col>
                    </Row>

                    <Button className='mx-0 my-0 d-inline-block mr-2' onClick={(e) => this.onNextClick(step)} color="success">Next</Button>
                   </>

        case STEP_MESSAGE:
            return <>
                    {this.state.message}
                  </>
    
        case STEP_NEW_MEMBER:
            return <>
                      <Card>
                        <CardBody>

                            <Row>
                                <Col sm="6">

                                    <FormGroup>
                                        <Label for="create_member_personal_identification_number">Personal identification number</Label>
                                        <Input type="text" name="create_member_personal_identification_number" id="create_member_personal_identification_number" disabled value={this.state.personal_identification_number} />
                                    </FormGroup>

                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="first_name">First name</Label>
                                            <Input type="text" name="first_name" id="first_name" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'first_name')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="last_name">Last name</Label>
                                            <Input type="text" name="last_name" id="last_name" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'last_name')} />
                                        </div>
                                    </FormGroup>

                                </Col>

                                <Col sm="6">
                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="street_address">Street address</Label>
                                            <Input type="text" name="street_address" id="street_address" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'street_address')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="co_address">C/O</Label>
                                            <Input type="text" name="co_address" id="co_address" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'co_address')} />
                                        </div>
                                    </FormGroup>

                                    <FormGroup className='d-flex'>
                                        <div className='mr-2 w-50'>
                                            <Label for="zip_code">Zip code</Label>
                                            <Input type="text" name="zip_code" id="zip_code" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'zip_code')} />
                                        </div>
                                        <div className='w-50'>
                                            <Label for="city">Postal city</Label>
                                            <Input type="text" name="city" id="city" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'city')} />
                                        </div>
                                    </FormGroup>

                                    

                                </Col>
                            </Row>

                            <Row>
                                <Col sm='6'>
                                    <FormGroup className='mt-3'>
                                        <Label for="email">Email</Label>
                                        <Input type="text" name="email" id="email" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'email')} />
                                     </FormGroup>
                                </Col>

                                <Col sm='6'>
                                    <FormGroup className='mt-3'>
                                        <Label for="mobile_number">Mobile number</Label>
                                        <Input type="text" name="mobile_number" id="mobile_number" defaultValue={''} onChange={(e) => this.onChange(e.target.value, 'mobile_number')} />
                                    </FormGroup> 
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <VerifiedStudentInfo
                                            verifiedStudentInfoError={this.state.verifiedStudentInfoError} 
                                            didFetchVerifiedStudentInfo={this.state.didFetchVerifiedStudentInfo} 
                                            verifiedStudentInfo={this.state.verifiedStudentInfo} 
                                            didEditVerifiedStudentInfo={this.state.didEditVerifiedStudentInfo} 
                                            studyPace={this.state.studyPace} 
                                            undoVerify={this.undoVerify.bind(this)}

                                            updateVerifiedStudentInfo={this.updateVerifiedStudentInfo.bind(this)}

                                            updateRangeValue={this.updateRangeValue.bind(this)} 
                                            editVerification={this.editVerification.bind(this)} />
                                </Col>
                            </Row>

                            {/* TODO add notify member checkbox, stuk.se/activation not working... */}
                            {/* <Row className='mt-4'>
                                <Col>
                                    <FormGroup check>
                                        <Label for="notify_member">
                                            <Input type="checkbox" name="notify_member" id="notify_member" defaultChecked={this.state.notify_member} onChange={(e) => this.onChange(e.target.checked, 'notify_member')} />{' '}
                                            Notify member
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row> */}

                            <Row className='mt-4'>
                                <Col sm='6'>
                                    <FormGroup className='mt-3'>
                                        <Label for="membership">Membership</Label>
                                        <Select options={this.props.memberships} onChange={(e) => this.onSelectMembership(e.value)} placeholder={"Select membership..."} /> 
                                    </FormGroup> 
                                </Col>
                            </Row>

                        </CardBody>
                        <span className='mb-5'></span>
                      </Card>
                      <Button className='mx-0 my-0 d-inline-block mr-2 mb-5' onClick={(e) => this.onNextClick(step)} color="success">Save</Button>
                  </>

        default:
            return null
    }
  }

  render() {
    return this.renderStep(this.state.step)
  }

  updateVerifiedStudentInfo(key, value) {
    let verifiedStudentInfo = this.state.verifiedStudentInfo
    verifiedStudentInfo[key] = value
    this.setState({verifiedStudentInfo: verifiedStudentInfo})
    this.editVerification()
  }

  verifyStudent(personalIdentificationNumber, verifiedStudentInfo, onSuccess, onError) {
    if (!this.state.didEditVerifiedStudentInfo) {
        onSuccess()
        return
    } 

    let data = {
        personal_identification_number_short: personalIdentificationNumber,
        study_pace: verifiedStudentInfo.study_pace,
        valid_from: verifiedStudentInfo.valid_from,
        valid_to: verifiedStudentInfo.valid_to
    }
    upsertVerifiedStudentInfo(data, (result) => {
        onSuccess()
    }, (error) => {
        onError(error)
    })
  }

  editVerification() {
    this.setState({ didEditVerifiedStudentInfo: true })
  }

  updateRangeValue = e => {
    this.setState({ studyPace: e.target.value })
    this.editVerification()
  }

  undoVerify() {
    this.setState({ didEditVerifiedStudentInfo: false })
    if (this.state.verifiedStudentInfo && this.state.verifiedStudentInfo.study_pace) {
        this.setState({ studyPace: this.state.verifiedStudentInfo.study_pace })
    } else {
        this.setState({ studyPace: 0 })
    }
}

}

export default NewMemberSe;