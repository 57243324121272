import React from 'react';
import PIN from 'personnummer';
import { ReactSpreadsheetImport } from "react-spreadsheet-import";
import { importMember } from '../../../vibe/helpers/apiHelper';
import { toastMessage, toastMessageLoading, toastClose } from './toastMessage';

class ImporterClass extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async onSubmit(data) {
        var tid = await toastMessageLoading('Import in progress!', 'Please do not refresh the page', 'warning')

        importMember(data.validData,
            (result) => {
                toastMessage(result, 'success')
                toastClose(tid)
                this.props.getBatches()
            },
            (error) => {
                if (error && error.message) {
                    toastMessage(error.message, 'error')
                }
                toastClose(tid)
            })
    }

    isEmail = (email) => {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
    }

    replaceAt = (str, index, replacement) => {
        return str.substring(0, index) + replacement + str.substring(index + 1);
    }

    startsWithNumber = (str) => {
        return /^\d/.test(str);
    }

    render() {

        const fields = [
            {
                // Visible in table header and when matching columns.
                label: "PIN | EMAIL",
                // This is the key used for this field when we call onSubmit.
                key: "member_identifier",
                // Allows for better automatic column matching. Optional.
                alternateMatches: ["Personnummer", "Personal Number", "pin", "PIN", "email", "mail", "E-Post"],
                // Used when editing and validating information.
                fieldType: {
                    // There are 3 types - "input" / "checkbox" / "select".
                    type: "input",
                },
                // Used in the first step to provide an example of what data is expected in this field. Optional.
                example: "197307064514 or example@mail.com",
                // Can have multiple validations that are visible in Validation Step table.
                validations: [
                    {
                        // Can be "required" / "unique" / "regex"
                        rule: "required",
                        errorMessage: "Personal Number is required",
                        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                        level: "error",
                    },
                ],
            },
            {
                // Visible in table header and when matching columns.
                label: "Semester",
                // This is the key used for this field when we call onSubmit.
                key: "semester",
                // Allows for better automatic column matching. Optional.
                alternateMatches: ["Semester", "SEMESTER"],
                // Used when editing and validating information.
                fieldType: {
                    // There are 3 types - "input" / "checkbox" / "select".
                    type: "input",
                },
                // Used in the first step to provide an example of what data is expected in this field. Optional.
                example: "VT 2023 or HT 2023",
                // Can have multiple validations that are visible in Validation Step table.
                validations: [
                    {
                        // Can be "required" / "unique" / "regex"
                        rule: "required",
                        errorMessage: "Semester is required",
                        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
                        level: "error",
                    },
                ],
            }
        ]

        return <ReactSpreadsheetImport
            rowHook={(data, addError) => {
                // Validation
                var pinCheck
                if (data.member_identifier && this.startsWithNumber(data.member_identifier) && data.member_identifier.length > 4) {
                    if (isNaN(data.member_identifier[data.member_identifier.length - 4])) {
                        pinCheck = this.replaceAt(data.member_identifier, data.member_identifier.length - 4, "1");
                    } else {
                        pinCheck = data.member_identifier
                    }
                    if (data.member_identifier && !data.member_identifier.includes('@') && !PIN.valid(pinCheck)) {
                        addError("member_identifier", { message: "Is not a valid PIN!", level: "error" })
                    }
                } else if (data.member_identifier && data.member_identifier.includes('@') && !this.isEmail(data.member_identifier)) {
                    addError("member_identifier", { message: "Is not a valid email!", level: "error" })
                } else if (data.member_identifier && !data.member_identifier.includes('@')) {
                    addError("member_identifier", { message: "Please match the requested format!", level: "error" })
                } else if (!data.member_identifier) {
                    addError("member_identifier", { message: "Please match the requested format!", level: "error" })
                }

                // Transformation
                return { ...data }
            }}
            allowInvalidSubmit={false}
            isOpen={this.props.isOpen}
            onClose={this.props.onClose}
            onSubmit={this.onSubmit}
            fields={fields}
            customTheme={{       
                fonts: {
                    heading: 'Open Sans, sans-serif',
                    body: 'Open Sans, sans-serif',
                }
            }}
        />
    }

}
export default ImporterClass
