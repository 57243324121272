import React from 'react';
import { Input } from 'reactstrap';
import { removeFirstAndLastChar } from '../../../vibe/helpers/util';

export default class ArrayInput extends React.Component {

    /**
     * ["Small","Medium","Large"] -> Small,Medium,Large
     */
    stringify(value) {

        if (!value) {
            return ""
        }

        var string = removeFirstAndLastChar(value)

        var array = string.split(",")

        return array.map(item => {
            let trimmed = item.trim()
            return removeFirstAndLastChar(trimmed)
        })
    }

    toStringArray(value) {
        var array = value.split(",")
        var stringArray = array.map(item => {
            return "\"" + item.trim() + "\""
        })

        stringArray = "[" + stringArray + "]"

        return stringArray
    }

    onChange(value, key) {
        let stringArray = this.toStringArray(value)
        this.props.onChange(stringArray, key)
    }

    render() {
        return <Input type="text" name={this.props.name} id={this.props.name} defaultValue={this.stringify(this.props.value)} onChange={(e) => this.onChange(e.target.value, this.props.name)} />
    }

}