import React, { Component } from 'react';
import { Button, CardBody } from 'reactstrap';
import {UncontrolledAlert} from 'reactstrap';

const VERIFIED = 'Student is verified'
const NOT_VERIFIED = 'Student is not verified'
const VERIFIED_NO_TRAVEL_DISCOUNT = 'Student is verified only for 30 days'

class VerifiedStudentInfo extends Component {


    getVerifiedStudentInfoStatusClass(verifiedStudentInfo) {
        if (this.props.studyStatus === 'N') {
            return NOT_VERIFIED
        } else if (this.props.studyStatus === 'A' ) {
            return VERIFIED
        } else {
            return VERIFIED_NO_TRAVEL_DISCOUNT
        }
    }

    render() {
        var studyStatusValue
        if (this.props.studyStatus  === 'A') {
            studyStatusValue = 100
        } else if (this.props.studyStatus  === 'T') {
            studyStatusValue = 50
         }else {
            studyStatusValue = 0
        }

        return <CardBody className="py-4">
            <React.Fragment>
                <div className="row mb-3">
                    <div className="col">
                        <h1 className="h4 mb-0">Student Verify</h1>
                    </div>
                    <div className="col">
                        <Button disabled={!this.props.didEditVerifiedStudentInfo} className="mb-0 float-right" onClick={() => this.props.undoVerifyInter()}><i className="fa fa-undo"></i>&nbsp;UNDO</Button>
                    </div>
                </div>

                <div className={this.props.studyStatus === 'N' ? 'studyPace_info notVerified' : this.props.studyStatus === 'A' ? 'studyPace_info verified' : 'studyPace_info verifiedNotEligible'}>
                    {this.props && this.getVerifiedStudentInfoStatusClass(this.props.verifiedStudentInfo)}
                </div>
                <div className="row text-center mt-4 mb-4">
                    <div className="col"><div className={this.props.studyStatus === 'N' ? 'active b-red percent' : 'percent'}>Not Active (N)</div></div>
                    <div className="col"><div className={this.props.studyStatus === 'T' ? 'active b-orange percent' : 'percent'}>Temporary Active (T)</div></div>
                    <div className="col"><div className={this.props.studyStatus === 'A' ? 'active b-green percent' : 'percent'}>Verified Student (A)</div></div>
                </div>

                <div className="range-slider">
                    <input type="range" className="range-slider__range" value={studyStatusValue} min={0} max={100} step={50} onChange={this.props.updateRangeValueInter} style={{ background: "linear-gradient(90deg, " + (studyStatusValue >= 75 ? 'rgb(26, 188, 156)' : 'rgb(255,180,0)') + studyStatusValue + "%, rgb(215, 220, 223)" + studyStatusValue + "%)" }} />
                </div>
            </React.Fragment>

            {this.props.didEditVerifiedStudentInfo ?
                <div className="row">
                    <div className="col">
                        <UncontrolledAlert color="gray" className="mt-5">
                            <small>Changes have been made, for them to be taken into effect you must save.</small>
                        </UncontrolledAlert>
                    </div>
                </div>
                : null}

        </CardBody>
    }

}

export default VerifiedStudentInfo;
