import { getGraduationSemesters, getEducations, organizationsTree, getMemberships } from '../../../vibe/helpers/apiHelper';
import React, { useState, useRef, useEffect } from 'react';
import DatePicker from '../../elements/form/DatePicker'
import regions from './sweden_regions.json'
import { TreeSelect } from 'antd';
import 'antd/dist/antd.css';
import { ORGANIZATION_LEVELS_DEPTH_DEFAULT } from '../../../vibe/helpers/organizationHelper';

const ValueEditor = ({ field, operator, value, handleOnChange, title, className, type, inputType, values, fieldData }) => {
  const isMountedVal = useRef(1);
  const [graduationSemesters, setGraduationSemesters] = useState(0);
  const [memberships, setMemberships] = useState(0);
  const [org, setOrg] = useState(0);
  const [educations, setEducations] = useState(0);

  useEffect(() => {
    isMountedVal.current = 1
    return () => { isMountedVal.current = 0 }
  })

  if (operator === "null" || operator === "notNull") {
    return null;
  }


  if (fieldData.name === 'org') {
    if (!org) {
      organizationsTree([200], ORGANIZATION_LEVELS_DEPTH_DEFAULT, (orgdata) => {

        const data = orgdata[0]
        const result = parseOrganizations(data)

        if (isMountedVal.current) {
          setOrg(result)
        }
      }, (error) => {
        console.log("No graduation semesters found", error)
      })
    }

    if (org) {
      values = org
    }
  }


  if (fieldData.name === 'studyField') {
    if (!educations) {
      getEducations((data) => {

        let result = data.map((element, x) => {

          let children = element.educationDetails.map((ele, i) => {
            return {
              label: ele.name,
              value: ele.id,
            }
          })

          return {
            label: element.name,
            value: 'education__' + element.id,
            children: children
          }
        })
        if (isMountedVal.current) {
          setEducations(result)
        }
      }, (error) => {
        console.log("No educations found", error)
      })
    }
    if (educations) {
      values = educations
    }
  }

  if (fieldData.name === 'semester') {
    if (!graduationSemesters) {
      getGraduationSemesters((gdata) => {
        if (isMountedVal.current) {
          setGraduationSemesters(gdata)
        }
      }, (error) => {
        console.log("No graduation semesters found", error)
      })
    }
    if (graduationSemesters) {
      let result = graduationSemesters.map((element) => {
        return { label: element.semester + ' ' + element.year, value: element.id }
      })
      values = result
    }
  }

  if (fieldData.name === 'membership') {
    if (!memberships) {
      getMemberships('', (memberships) => {
        if (isMountedVal.current) {
          setMemberships(memberships)
        }
      }, (error) => {
        console.log("Error fetching memberships", error)
      })
    }
    if (memberships) {
      let result = memberships.map((element) => {
        return { label: element.name, value: element.id }
      })
      values = result
    }
  }

  if (fieldData.name === 'city') {

    let result = Object.keys(regions).map((key) => {
      let children = regions[key].map((element, i) => {
        return { label: element, value: element }
      })

      return {
        label: key, value: key,
        children: children
      }
    })

    values = result
  }



  const placeHolderText = (fieldData && fieldData.placeholder) || "";

  const parseOrganizations = (organization) => {
    return organization.organizations.map((o) => ({
          label: o.name,
          value: o.id,
          children: o?.organizations?.length >= 0 ? parseOrganizations(o) : undefined
        }))
  }

  const isNumberKey = (evt) => {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return false;
    return true;
  }

  const handleDatePickerChange = (day) => {
    handleOnChange(day)
  }

  if (values && fieldData && type === 'treeselect') {

    let validation
    if (fieldData.checkEmpty) {
      validation = fieldData.checkEmpty.some(ele => ele.includes(fieldData.name));
    }
    
    return (
      <div className={validation && (!value || value.length === 0) ? 'w-50 inline-block ant-form-item-has-error' : 'w-50 inline-block'} style={{ marginRight: '10px', verticalAlign: 'top' }}>
        <TreeSelect
          treeNodeFilterProp="label"
          treeData={values}
          value={value || []}
          onChange={(value) => {
            handleOnChange(value)
          }}
          treeCheckable={true}
          //showCheckedStrategy={fieldData.name === 'org' ? 'SHOW_PARENT' : 'SHOW_CHILD'}
          placeholder={'Select...'}
          style={{ width: '100%' }}
          allowClear={true}
          treeCheckStrictly={true}
        />
        {validation && (!value || value.length === 0) &&
          <div className="ant-form-item-explain ant-form-item-explain-connected">
            <div role="alert" className="ant-form-item-explain-error" style={{ color: 'red', fontSize: '13px' }}>{fieldData.label} is required</div>
          </div>
        }
      </div>
    );
  }

  switch (type) {
    case "select":
      return (
        <select
          className={className}
          title={title}
          onChange={(e) => handleOnChange(e.target.value)}
        // value={value}
        >
          {values &&
            values.map((v) => (
              <option key={v.name} value={v.name}>
                {v.label}
              </option>
            ))}
        </select>
      );

    case "checkbox":
      return (
        <input
          type="checkbox"
          className={className}
          title={title}
          onChange={(e) => handleOnChange(e.target.checked)}
          checked={!!value}
          disabled={fieldData?.disabled}
        />
      );

    case "radio":
      return (
        <span className={className} title={title}>
          {values &&
            values.map((v) => (
              <label key={v.name}>
                <input
                  type="radio"
                  value={v.name}
                  checked={value === v.name}
                  onChange={(e) => handleOnChange(e.target.value)}
                />
                {v.label}
              </label>
            ))}
        </span>
      );

    case "datepicker":
      return (
        <div className="inline-block datePicker">
          <DatePicker handleChange={handleDatePickerChange.bind(this)} value={value} source='segment' />
        </div>
      );

      case "text":
        return (
          <input
            type={"text"}
            placeholder={placeHolderText}
            value={value}
            title={title}
            className={className}
            onChange={(e) => handleOnChange(e.target.value)}
            disabled={fieldData?.disabled}
            required
          />
        );

    default:
      return (
        <input
          type={inputType || "text"}
          placeholder={placeHolderText}
          value={value}
          title={title}
          className={className}
          onChange={(e) => handleOnChange(e.target.value)}
          disabled={fieldData?.disabled}
          min="1"
          onKeyPress={(event) => { !isNumberKey(event) && event.preventDefault() }}
          required
        />
      );
  }
}



ValueEditor.displayName = "valueEditor";

export default ValueEditor;
