import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';

const pdfGeneratorFeeLedger = async (payouts, startDate, endDate) => {

    try {
        // Load the existing PDF file
        const existingPdfBytes = await fetch('/fee-statement.pdf').then((response) =>
            response.arrayBuffer()
        );

        // Create a PDF document
        const pdfDoc = await PDFDocument.load(existingPdfBytes);

        pdfDoc.registerFontkit(fontkit);
        const fontBytes = await fetch('/Poppins-Light.ttf').then((response) =>
            response.arrayBuffer()
        );
        const poppinsFont = await pdfDoc.embedFont(fontBytes);

        // Add a new page to the document
        let [page] = pdfDoc.getPages();
        const { width, height } = page.getSize();
        let currentPageRows = 0;

        // ================================
        // Define table headers and data
        // Define table headers and data
        const tableHeaders = ['Organization name', 'From', 'To', 'Amount', 'Fee'];

        // Define table column widths
        const columnWidths = [250, 70, 70, 70, 70];

        // Set font and text size
        const fontSize = 8;

        // Set initial y-coordinate for the table
        let y = height - 260;



        page.drawText(startDate.toString().substring(0, 10) + '  -  ', {
            x: 120,
            y: y + 120,
            size: 10,
            font: poppinsFont,
            color: rgb(0, 0, 0), // Black color
        });
        page.drawText(endDate.toString().substring(0, 10), {
            x: 190,
            y: y + 120,
            size: 10,
            font: poppinsFont,
            color: rgb(0, 0, 0), // Black color
        });
        page.drawText(payouts.length.toString(), {
            x: 120,
            y: y + 100,
            size: 10,
            font: poppinsFont,
            color: rgb(0, 0, 0), // Black color
        });
        

        // Set the background color for the table header
        // Draw table headers with background color and borders
        for (let i = 0; i < tableHeaders.length; i++) {
            const x = 30 + columnWidths.slice(0, i).reduce((a, b) => a + b, 0);

            // Draw a background rectangle for the header cell
            page.drawRectangle({
                x,
                y,
                width: columnWidths[i],
                height: 25, // Adjust the height as needed
                color: rgb(241 / 255, 243 / 255, 244 / 255),
                borderColor: rgb(0, 0, 0), // Border color (black)
                borderWidth: 0.2, // Border width
            });

            // Draw the header text on top of the background
            page.drawText(tableHeaders[i], {
                x: x + 5, // Adjust the x-coordinate for text padding
                y: y + 10, // Adjust the y-coordinate for text centering
                size: fontSize,
                font: poppinsFont,
                color: rgb(0, 0, 0), // Black color
            });
        }

        // Draw table data with borders
        let totalFee = 0;
        let totalAmount = 0;
        if (payouts.length > 0) {
            for (let index = 0; index < payouts.length; index++) {
                const payout = payouts[index];
                totalFee += payout.fee_amount;
                totalAmount += payout.amount;

                y -= 25; // Adjust the y-coordinate for the next row
                const rowData = [
                    payout.organization.display_name,
                    payout.from_date.substring(0, 10),
                    payout.to_date.substring(0, 10),
                    parseFloat(payout.amount / 100).toFixed(2).toString(),
                    parseFloat(payout.fee_amount / 100).toFixed(2).toString(),
                ];
                for (let col = 0; col < rowData.length; col++) {
                    const x = 30 + columnWidths.slice(0, col).reduce((a, b) => a + b, 0);

                    // Draw a border around the cell
                    page.drawRectangle({
                        x,
                        y,
                        width: columnWidths[col],
                        height: 25, // Adjust the height as needed
                        borderColor: rgb(0, 0, 0), // Border color (black)
                        borderWidth: 0.2, // Border width
                    });

                    // Draw the cell text
                    page.drawText(rowData[col], {
                        x: x + 5, // Adjust the x-coordinate for text padding
                        y: y + 10, // Adjust the y-coordinate for text centering
                        size: fontSize,
                        font: poppinsFont,
                        color: rgb(0, 0, 0), // Black color,
                    });
                }

                currentPageRows++;

                // Check if it's time to move to a new page
                if (currentPageRows > 12) {
                    page = pdfDoc.addPage([width, height]);
                    currentPageRows = 0;
                    y = height - 80;
                }
            }
        }

     
        let totalAmountValue =  parseFloat(totalAmount / 100).toFixed(2)
        let totalFeeValue = parseFloat(totalFee / 100).toFixed(2)
        let totalFeeIncVat = parseFloat(totalFee * 0.80 / 100).toFixed(2)
        let vat = (totalFeeValue - totalFeeIncVat).toFixed(2)

        // Draw Table Total, VAT, Total inc. VAT, Fee, Net
        const tableData = [
            { label: 'Total Amount', value: totalAmountValue + ' SEK' },
            { label: 'Total Fee inc. VAT', value: totalFeeValue + ' SEK' },
            { label: 'Total fee excl. VAT', value:  totalFeeIncVat + ' SEK' },   
            { label: 'VAT', value: vat + ' SEK' }
        ];


        // Define border width
        const borderWidth = 0.2;

        // Set the x-coordinate for the right-aligned table
        const tableX = 380; // Adjust this value as needed

        // Loop through the table data and draw each row
        for (const row of tableData) {
            // Draw the label and border for the label cell

            page.drawRectangle({
                x: tableX, // Adjust the x-coordinate for the label cell
                y: y - 50,
                width: 90, // Adjust the width as needed
                height: 30, // Adjust the height as needed
                color: rgb(241 / 255, 243 / 255, 244 / 255),
                borderColor: rgb(0, 0, 0), // Border color (black)
                borderWidth,
            });
            page.drawText(row.label, {
                x: tableX + 5, // Adjust the x-coordinate for the label
                y: y - 40,
                size: fontSize,
                font: poppinsFont, // Assuming you have 'poppinsFont' defined
                color: rgb(0, 0, 0), // Black color,
            });

            // Draw the value and border for the value cell
            page.drawRectangle({
                x: tableX + 90, // Adjust the x-coordinate for the value cell
                y: y - 50,
                width: 90, // Adjust the width as needed
                height: 30, // Adjust the height as needed
                borderColor: rgb(0, 0, 0), // Border color (black)
                borderWidth,
            });
            page.drawText(row.value, {
                x: tableX + 90 + 5, // Adjust the x-coordinate for the value
                y: y - 40,
                size: fontSize,
                font: poppinsFont, // Assuming you have 'poppinsFont' defined
                color: rgb(0, 0, 0), // Black color,
            });


            // Adjust the y-coordinate for the next row
            y -= 30; // Adjust the spacing between rows as needed
        }
        //=================================

        // Serialize the edited PDF to bytes
        const editedPdfBytes = await pdfDoc.save();

        // Create a blob from the edited PDF bytes
        const pdfBlob = new Blob([editedPdfBytes], { type: 'application/pdf' });

        // Create a URL for the blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Trigger the download
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'fee-statement.pdf'; // Provide a default filename
        link.click();
    } catch (error) {
        console.error('Error editing PDF:', error);
    }
};





export { pdfGeneratorFeeLedger };
