import React from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { createOrganizationPost, getOrganizationPost, patchOrganizationPost, deleteOrganizationPost } from '../../../../vibe/helpers/apiHelper';
import { parseErrorMessage, prettyPrintDate } from '../../../../vibe/helpers/util';
import MessageUncontrolledAlert from '../../../elements/form/MessageUncontrolledAlert';
import MediaSelect from '../../../elements/media/MediaSelect';
import { getCurrentUser } from '../../../../vibe/helpers/apiHelper';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

class EditOrganizationPost extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            // files: [],
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({ user: user })
            this.getPost()
        }, (error) => {
            console.log('Error get Current user!')
        })
    }

    getPost() {
        let id = this.props.match.params.id
        if (id) {
            getOrganizationPost(id, (organizationPost) => {
                if (organizationPost.error && organizationPost.error.statusCode === 404) {
                    this.props.history.replace('/organization-posts')
                }
                this.setState({ organizationPost: organizationPost, loading: false, error: null })
            }, (error) => {
                this.setState({ error: error })
            })
        } else {
            // let organizationId = this.state.user.organization_id // this.props.match.params.organizationId
            this.setState({ new: true, organizationPost: { title: null }, loading: false, error: null })
        }
    }

    render() {
        if (this.state.loading) {
            return null
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else if (this.state.organizationPost) {
            return this.renderForm(this.state.organizationPost)
        } else {
            return null
        }
    }

    onDelete(postId) {
        deleteOrganizationPost(postId, (result) => {
            this.props.history.replace('/organization-posts')
        }, (error) => {
            console.log('Error deleting post!')
        })
    }

    renderForm(organizationPost) {
        return <React.Fragment>
            {this.renderAlerts()}
            <Form>
                <Row>
                    <Col sm="6">

                        <FormGroup>
                            <Label for="title">Title</Label>
                            <Input type="text" name="title" id="title" defaultValue={organizationPost.title} onChange={(e) => this.onChange(e.target.value, 'title')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="created_at">Created at</Label>
                            <Input type="text" name="created_at" id="created_at" defaultValue={prettyPrintDate(organizationPost.created_at)} disabled />
                        </FormGroup>

                        <FormGroup>
                            <Label for="updated_at">Updated at</Label>
                            <Input type="text" name="updated_at" id="updated_at" defaultValue={prettyPrintDate(organizationPost.updated_at)} disabled />
                        </FormGroup>

                        <FormGroup>
                            <Label for="description">Description</Label>
                            <Input type="text" name="description" id="description" defaultValue={organizationPost.description} onChange={(e) => this.onChange(e.target.value, 'description')} />
                        </FormGroup>

                        <Label for="image">Image</Label>
                        <FormGroup className="d-flex align-items-center">
                            <MediaSelect files={this.state.files} item={organizationPost} index={0} mediaId={organizationPost.media_id} itemId={organizationPost.id}
                                onChange={(id, item, index) => this.onChange(id, 'media_id')} onDelete={(itemId, index) => this.onChange(null, 'media_id')} />
                        </FormGroup>

                    </Col>

                    <Col sm="6">
                        <FormGroup>
                            <Label for="content">Content</Label>
                            <CKEditor
                                editor={ClassicEditor}
                                data={organizationPost.content}
                                onReady={editor => {
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    this.onChange(data, 'content')
                                }}
                                onBlur={(event, editor) => {
                                }}
                                onFocus={(event, editor) => {
                                }}
                            />


                        </FormGroup>
                    </Col>

                </Row>
            </Form>

            <Button className="mt-4" color="success" onClick={() => this.onClickSaveOrganizationPost('published')}><i className="fa fa-check"></i>&nbsp;
                {this.state.new || this.state.organizationPost.status === 'draft' ? 'Publish' : 'Update'}</Button>{' '}


            {!this.state.new && <Button className="mt-4" color="danger" onClick={() => { if (window.confirm('Are you sure you wish to delete this post?')) this.onDelete(organizationPost.id) }}>
                <i className="fa fa-check"></i>&nbsp;Delete</Button>}{' '}

            <Button className="mt-4" color="primary" onClick={() => this.onClickSaveOrganizationPost('draft')}>
                <i className="fa fa-check"></i>&nbsp;{this.state.new || this.state.organizationPost.status === 'draft' ? 'Save draft' : 'Switch to draft'}</Button>

        </React.Fragment>
    }

    renderAlerts() {
        return <MessageUncontrolledAlert success={this.state.organizationPostSaved} error={this.state.organizationPostSaveError}
            successMessage={'Post saved!'} errorMessage={'Error saving post, error: ' + this.state.organizationPostSaveError} />
    }

    onChange(value, key) {
        var organizationPost = this.state.organizationPost
        organizationPost[key] = value
        this.setState({ organizationPost: organizationPost }, () => {
            // console.log("organizationPost", organizationPost)
        })
        this.setState({ organizationPostEdited: true })
    }

    onClickSaveOrganizationPost(status) {
        // Set the status
        this.setState(state => state.organizationPost.status = status)

        this.createOrUpdatePost(this.state.new, () => {
            this.setState({ organizationPostSaved: true, organizationPostEdited: false })
            setTimeout(() => this.setState({ organizationPostSaved: false }), 5000)
        }, (error) => {
            var errorMessage = parseErrorMessage(error, true)
            this.setState({ organizationPostSaveError: errorMessage })
        })
    }

    createOrUpdatePost(create, onSuccess, onError) {
        if (create) {
            createOrganizationPost(this.state.organizationPost, (result) => {
                onSuccess()
                setTimeout(() => {
                    this.props.history.replace('/organization-posts/edit/' + result.id)
                }, 2000)
            }, (error) => {
                onError(error)
            })
        } else {
            patchOrganizationPost(this.state.organizationPost, () => {
                onSuccess()
                this.getPost()
            }, (error) => {
                onError(error)
            })
        }
    }

}
export default EditOrganizationPost
