import React, { Component } from 'react';
import { Button, Col, Input } from 'reactstrap';
import * as Icon from 'react-feather';


class FormOptionText extends Component { 
    
    render() {
        const { formItem, option, } = this.props
        return <>
         <div className="input-group mb-3">
                        <div className="d-flex align-items-center pr-2">
                            <Icon.AlignLeft size="20" color="#606469" />
                        </div>
                        <Col style={{padding: '0'}} className='mr-2'>
                        <Input
                            className="form-control mr-2"
                            type={'text'}
                            name="title"
                            id="title"
                            value={option.value}
                            placeholder='Title'
                            onChange={(e) => this.props.editFormOption(option, formItem, 'value', e.target.value)}
                        />
                        <Input
                            className="form-control mr-2"
                            placeholder={option.placeholder || "Write placeholder text here..."}
                            type={'text'}
                            name="placeholder"
                            id="placeholder"
                            defaultValue={""}
                            onChange={(e) => this.props.editFormOption(option, formItem, 'placeholder', e.target.value)}
                            />
                        </Col>
                            <div className="d-flex align-items-center ">
                            <Button color="link" className="pl-1" onClick={() => this.props.removeOption(formItem, option)}>
                              <Icon.X size="20" color="#606469" />
                            </Button>
                          </div>
            </div>
        </>
    }

}

export default FormOptionText;