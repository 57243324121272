import React, { Component }  from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DateTimePicker from '../../../../elements/form/DateTimePicker';
import { v4 as uuidv4 } from 'uuid';
import EditTicket from '../ticket/EditTicket';
import TicketListItem from '../ticket/TicketListItem';
import { DELETE, NEW, onAddObject, onEditObject, removeItem, setItem } from '../../../../elements/form/FormAction';
import { ticketCanBeDeleted } from '../../../../../vibe/helpers/eventHelper';
import OccurrenceOptions, { BUTTON_TEXT_OPTIONS, KEY_BUTTON_BG_COLOR, KEY_BUTTON_TEXT, KEY_BUTTON_TEXT_COLOR } from './OccurrenceOptions';
import { getCurrentUser } from '../../../../../vibe/helpers/apiHelper';
import { isSuperUser } from '../../../../../vibe/helpers/permissionHelper';
import AllOccurrenceOptions from './AllOccurrenceOptions';
import InterestListItem from '../../interest/InterestListItem';
import EditEventInterest from '../../interest/EditEventInterest';

const buttonTextOptionData = {
    [KEY_BUTTON_TEXT]: {visible: true, editable: true, show_key_name: false, description: 'Button text', input: {type: 'select', options: BUTTON_TEXT_OPTIONS}}
}

const allOptionsData = {
    [KEY_BUTTON_TEXT]: {visible: false},
    [KEY_BUTTON_BG_COLOR]: {can_edit_key: false},
    [KEY_BUTTON_TEXT_COLOR]: {can_edit_key: false},
}

class EditOccurrence extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            isInputChanged: false,
            editTicket: null,
            validation_error: {}
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    addNewClonedTickets(tickets) {
        if (tickets?.length > 0) {
            for (var ticket of tickets) {
               ticket.ticket_forms = this.getNewClonedTicketForms(ticket)
               ticket.campaign_id = ticket?.campaigns?.[0]?.id
               this.onAddTicket(ticket)
            }
        }
    }
    
    addNewClonedInterests(interests) {
        if (interests?.length > 0) {
            for (var interest of interests) {
                this.onAddInterest(interest)
            }
        }
    }

    getNewClonedTicketForms(ticket) {
        const clonedTicketForms = []

        if (!ticket?.ticket_forms || ticket.ticket_forms.length <= 0) {
            return clonedTicketForms
        }

        for (const ticketForm of ticket.ticket_forms) {
            clonedTicketForms.push({
                form_id: ticketForm.form_id,
                form: {...ticketForm.form},
                _action: NEW,
                key: uuidv4()
            })
        }

        return clonedTicketForms
    }

    addNewClonedOptions(options) {
        const clonedOptions = []

        if (options?.length > 0) {
            for (var option of options) {
                var clone = {...option}
                onAddObject(clone)
                clone.id = uuidv4()
                clonedOptions.push(clone)
            }
        }

        const occurrence = this.state.occurrence
        occurrence.options = clonedOptions
        this.setState({occurrence: occurrence, isInputChanged: true})
    }

    componentDidMount() {
        if (this.props.clonedOccurrence && this.props.clonedTickets && this.props.clonedInterests) {

            var clonedOccurrence = this.props.clonedOccurrence
            const tickets = this.props.clonedTickets
            const interests = this.props.clonedInterests

            this.setState({occurrence: {...clonedOccurrence}}, () => {
                this.addNewClonedTickets(tickets)
                this.addNewClonedInterests(interests)
                this.addNewClonedOptions(clonedOccurrence?.options)
            })
        } else if (this.props.occurrence) {
            this.setState({occurrence: {...this.props.occurrence}})
        } else {
            let lastOccurrence = this.getLastOccurrence(this.props.event)
            var occurrence = {
                name: null,
                start_date: null,
                end_date: null,
                street_address: lastOccurrence?.street_address || null,
                zip_code: lastOccurrence?.zip_code || null,
                city: lastOccurrence?.city || null,
                location: lastOccurrence?.location || null,
                key: uuidv4(),
                _action: NEW
            }
            this.setState({occurrence: occurrence})
        }

        getCurrentUser((user) => {
            this.setState({user: user})
        })
    }

    getLastOccurrence(organizationEvent) {
        const eventOccurrences = organizationEvent?.organization_event_occurrences
        if (!eventOccurrences || eventOccurrences.length <= 0 ) {
            return null
        }
        return eventOccurrences[0]
    }

    lastOccurrenceHasLocation (organizationEvent) {
        const occurrence = this.getLastOccurrence(organizationEvent)
        if (!occurrence) {
            return false
        }

        if (occurrence.street_address || occurrence.zip_code || occurrence.city || occurrence.location) {
            return true
        }
        return false
    }

    onChangeOccurrence(value, key) {
        var occurrence = this.state.occurrence
        occurrence[key] = value
        onEditObject(occurrence)
        this.setState({ occurrence: occurrence, isInputChanged: true }) 
    }

    isOccurrenceValid() {
        return Object.keys(this.state.validation_error).length === 0
    }

    onSaveClick() {
        var validationErrors = this.state.validation_error
        if (!this.state.occurrence.start_date) {
            const startDateError = 'Start date is required'
            validationErrors['start_date'] = startDateError
        } else {
            delete validationErrors['start_date']
        }

        this.setState({validation_error: validationErrors}, () => {
            if (this.isOccurrenceValid()) {
                this.props.onAddOccurrence(this.state.occurrence)
                this.setState({isOpen: false}, () => {
                    this.toggleModal()
                })
            }
        })
    }

    onClickEditTicket(ticket) {
        this.setState({showTicketsModal: true, editTicket: ticket})
    }

    onClickEditInterest(interest) {
        this.setState({showInterestModal: true, editInterest: interest})
    }

    renderTickets(occurrence) {

        let tickets = occurrence && occurrence.tickets && occurrence.tickets.length > 0 && occurrence.tickets.map((tickets) => {
            return <TicketListItem key={tickets.id ?? tickets.key} ticket={tickets} onClickEditTicket={(ticket) => this.onClickEditTicket(ticket)} onDeleteTicket={(ticket) => this.onDeleteTicket(ticket)} />
        })

        let interests = occurrence && occurrence.interests && occurrence.interests.length > 0 && occurrence.interests.map((interest) => {
            return <InterestListItem key={interest.id ?? interest.key} interest={interest} onClickEditInterest={(interest) => this.onClickEditInterest(interest)} onDeleteInterest={(interest) => this.onDeleteInterest(interest)} />
        })

        const disableAddButtons = occurrence?.interests?.length > 0
        const disabledInterestAddButton = disableAddButtons || occurrence?.tickets?.length > 0

        return <Col>
            {tickets && <p><strong>Tickets</strong></p>}
            {interests && <p><strong>Registrations of interest</strong></p>}

            <Button disabled={disableAddButtons} title={disableAddButtons ? '' : null} className='mb-4' color="primary" onClick={() => this.onClickEditTicket(null)}><i className="fa fa-plus-circle mr-1" />Add ticket</Button>
            
            <Button disabled={disabledInterestAddButton} title={disabledInterestAddButton ? '' : null} className='mb-4 ml-2' color="primary" onClick={() => this.onClickEditInterest(null)}><i className="fa fa-plus-circle mr-1" />Add registration of interest</Button>
            
            {tickets}
            {interests}
            {this.state.showTicketsModal && <EditTicket ticket={this.state?.editTicket} campaigns={this.props.campaigns} formOptions={this.props.formOptions} segmentOptions={this.props.segmentOptions} occurrence={occurrence} onAddTicket={(ticket, occurrence) => this.onAddTicket(ticket)} toggle={this.toggleTicketsModal.bind(this)} />} 
            {this.state.showInterestModal && <EditEventInterest interest={this.state?.editInterest} interests={this.props.interests} formOptions={this.props.formOptions} segmentOptions={this.props.segmentOptions} occurrence={occurrence} onAddInterest={(interest, occurrence) => this.onAddInterest(interest)} toggle={this.toggleInterestModal.bind(this)} />} 
        </Col>
    }

    onDeleteTicket(ticket) {
        if (!ticketCanBeDeleted(ticket)) {
            window.alert("The ticket \"" + ticket.name + "\" can not be deleted because it contains completed transactions")
            return
        }

        const occurrence = this.state.occurrence
        removeItem(occurrence.tickets, ticket)
        this.setState({occurrence: occurrence})
    }

    onDeleteInterest(interest) {
        // interest can always be deleted, we just remove the connection to occurrence, the member_interest will still be in db
        const occurrence = this.state.occurrence
        removeItem(occurrence.interests, interest)
        this.setState({occurrence: occurrence})
    }

    onAddOption(option) {
        const occurrence = this.state.occurrence
        if (!occurrence?.options) {
            occurrence.options = []
        } 
        setItem(occurrence.options, option)
        this.setState({occurrence: occurrence, isInputChanged: true})
    }

    onAddTicket(ticket) {
        const occurrence = this.state.occurrence
        if (!occurrence?.tickets) {
            occurrence.tickets = []
        } 
            
        setItem(occurrence.tickets, ticket)
        this.setState({occurrence: occurrence, isInputChanged: true})
    }

    onAddInterest(newInterest) {
        const occurrence = this.state.occurrence
        if (!occurrence?.interests) {
            occurrence.interests = []
        } 
            
        if (!newInterest) {
            for (var interest of occurrence.interests) {
                interest._action = DELETE
            }
        } else {
            const exists = occurrence.interests.find((interest) => interest.id === newInterest.id)

            // delete all old interests
            for (var oldInterests of occurrence.interests) {
                oldInterests._action = DELETE
            }

            if (!exists) {
                newInterest._action = NEW
                occurrence.interests.push(newInterest)
            }
        }

        this.setState({occurrence: occurrence, isInputChanged: true})
    }

    toggleModal() {
        if (this.state.isOpen && this.state.isInputChanged) {
            let answer = window.confirm("Discard changes?");  
            if (!answer) {
                return
            }
        } 
        this.props.toggle()
    }

    toggleTicketsModal() {
        if (this.state.showTicketsModal) {
            this.setState({showTicketsModal: false, editTicket: null})
        }
    }

    toggleInterestModal() {
        if (this.state.showInterestModal) {
            this.setState({showInterestModal: false, editInterest: null})
        }
    }

    renderOccurrenceButtonTextOptions(occurrence) {
        return <OccurrenceOptions options={occurrence?.options} onAddOption={(option) => this.onAddOption(option)} edit={false} add={false} visible={false} data={buttonTextOptionData} />
    }

    renderAllOccurrenceOptions(occurrence) {
        if (!this.state.user) {
            return null
        }

        if (isSuperUser(this.state.user)) {
            return <FormGroup>
                <Label for="occurrence_option">Options</Label>
                <AllOccurrenceOptions options={occurrence?.options} onAddOption={(option) => this.onAddOption(option)} data={allOptionsData} />
            </FormGroup>
        }

        return null
    }

    render() {
        const occurrence = this.state.occurrence
        if (!occurrence) {
            return null
        }

        const title = this.props.occurrence ? 'Edit occurrence' : 'New occurrence'

        return <React.Fragment>
                <Modal scrollable={true} size="xl" isOpen={this.state.isOpen} toggle={this.toggleModal} className="previewModal">
                    <ModalHeader toggle={this.toggleModal}>
                        <strong>{title}</strong>
                    </ModalHeader>
                    <ModalBody>

                        <Form>
                            <Row>
                                <Col sm="9">
                                    <FormGroup>
                                        <Label for="occurrence_start_date">Start date</Label>
                                        <DateTimePicker name={"start_date"} value={occurrence.start_date} onChange={this.onChangeOccurrence.bind(this)} />
                                        {this.state.check && !occurrence.start_date &&
                                            <div className="mini-font text-danger">Start date is required</div>
                                        }
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="occurrence_end_date">End date</Label>
                                        <DateTimePicker name={"end_date"} value={occurrence.end_date} onChange={this.onChangeOccurrence.bind(this)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label for="occurrence_street_address">Street address</Label>
                                        <Input type="text" name="occurrence_street_address" id="occurrence_street_address" defaultValue={occurrence.street_address} onChange={(e) => this.onChangeOccurrence(e.target.value, 'street_address')} />
                                    </FormGroup>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="occurrence_zip_code">Zip code</Label>
                                                <Input type="text" name="occurrence_zip_code" id="occurrence_zip_code" defaultValue={occurrence.zip_code} onChange={(e) => this.onChangeOccurrence(e.target.value, 'zip_code')} />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="occurrence_city">City</Label>
                                                <Input type="text" name="occurrence_city" id="occurrence_city" defaultValue={occurrence.city} onChange={(e) => this.onChangeOccurrence(e.target.value, 'city')} />
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="occurrence_location">Location name (optional)</Label>
                                                <Input type="text" name="occurrence_location" id="occurrence_location" defaultValue={occurrence.location} onChange={(e) => this.onChangeOccurrence(e.target.value, 'location')} />
                                            </FormGroup>
                                            {this.lastOccurrenceHasLocation(this.props.event) && !this.state.isInputChanged && !this.props.occurrence ? <p className='mini-font'>Suggested location values based on previous occurrence location</p> : null} 
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="occurrence_button_text_option">Button text</Label>
                                                {this.renderOccurrenceButtonTextOptions(occurrence)}
                                            </FormGroup>
                                        </Col>

                                    </Row>

                                    {/* only superuser */}
                                    {this.renderAllOccurrenceOptions(occurrence)}

                                </Col>

                            </Row>

                            <Row className='mt-3'>
                                {this.renderTickets(occurrence)}
                            </Row>

                        </Form>
                    </ModalBody>

                    <ModalFooter className='d-flex justify-content-end'>
                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                        <Button size="lg" disabled={false} className="px-5 mt-3" color="primary" onClick={() => this.onSaveClick()}>Save</Button>{' '}
                    </ModalFooter>

                </Modal>

            </React.Fragment>

    }

}

export default EditOccurrence;