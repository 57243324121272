import React, { Component } from 'react';

class Privacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="container">
        <div className="col-md-12 pt-5 pb-5">
          <h3>PRIVACY AND DATA PROTECTION POLICY</h3>

          <div>
            <p><span className="s1"><b>PRIVACY AND DATA PROTECTION POLICY </b></span></p>
            <p><span className="s1">This privacy and data protection policy forms part of the Agreement between us and the User and applies to the Services provided by us through each of our Platforms and used by the User and anywhere else we interact with you. </span></p>
            <p><span className="s2">1. </span><span className="s1"><b>Introduction </b></span></p>
            <p><span className="s1">1.1. &nbsp;When you apply for membership and register with us and/or when you visit </span><span className="s3">our </span><span className="s1">Platforms, </span><span className="s3">you are deemed to have entered into the Agreement (of which this policy forms part) and you will have provided </span><span className="s1">us with personal information and have given us consent to processing your personal data (including your name, contact details, and device information) as described in this policy. </span><span className="s4"><br/></span></p>
            <p><span className="s1">1.2. &nbsp;You are also consenting to the processing of your location data (including details of your current location disclosed by GPS or other technology, so that location-enable services are activated to serve relevant offers and perks and improve our Services). </span><span className="s4"><br/></span></p>
            <p><span className="s6">1.3. &nbsp;</span><span className="s1">You may at any time withdraw your consent to us processing your information by contacting us but that will not affect the lawfulness of any processing carried out before you withdraw your consent. You may also change your location settings via your device settings. </span><span className="s7"><br/></span></p>
            <p><span className="s1">1.4. &nbsp;We will collect, process and store your personal data in accordance with the provisions of the Data Protection Act 2018 which implements the General Data Protection Regulation 2016. </span><span className="s4"><br/></span></p>
            <p><span className="s1">1.5. &nbsp;This policy applies to any kind of personal information we collect from a member and/or a user through our Services and Platforms or any other contact and communications you may have with us that is connected to our Services (including contacting our Customer Service Team). </span><span className="s4"><br/></span></p>
            <p><span className="s1">1.6. &nbsp;Your privacy is important to us and this policy explains what personal data about you we collect and process, how we use and protect the personal information we have collected and how you can exercise your privacy rights. </span><span className="s4"><br/></span></p>
            <p><span className="s1">1.7. &nbsp;This policy will be updated from time to time to comply with applicable legislation. Our Platforms will have a link to the Agreement containing the latest version of this policy. </span><span className="s4"><br/></span></p>
            <p><span className="s2">2. </span><span className="s1"><b>Your Personal data – what do we collect and why? </b></span></p>
            <p><span className="s1">2.1. &nbsp;Personal data is any information that directly or indirectly identifies you. The personal information that we collect about you broadly falls into the categories set out in more detail below in clause 2.3. </span><span className="s4"><br/></span></p>
            <p><span className="s1">2.2. &nbsp;In order to provide the Service in question to you, we will </span><span className="s3">need to collect and process your </span><span className="s1">personal data. These are necessary for </span><span className="s3">the full functioning of the </span><span className="s1">Service and to provide you with access to personalised offers and products and services. </span><span className="s4"><br/></span></p>
            <p><span className="s1">2.3. &nbsp;Examples of the personal information we collect and why include: </span></p>
            <p><span className="s2">2.3.1. </span><span className="s1"><b>Information that you provide</b></span><span className="s2">: </span></p>
            <p><span className="s1">Certain parts of the Platform may ask you to provide personal information such as your name, email address, telephone number, personal ID number, if you are in higher education, your student status, course or degeree and your student ID and your place of study. For example, we ask you to provide your contact details in order to register an account with us, to receive messages from us (or our advertiser partners), to get information about products, services and promotional offers, competitions, sweepstakes, polls and surveys, experiences and opportunities, and/or to submit enquiries to us (e.g. by interacting with our Customer Service team). You may provide information about your preferences, values, and beliefs when completing surveys, or entering competitions. </span></p>
            <p><span className="s1">We will use the personal information that you provide to verify your identity, send relevant opportunities and information to you, improve our services, restrict age- appropriate products and services, and to help our partners send relevant information about their programmes, offers, products, and services. </span></p>
            <p><span className="s6">For more information on </span><span className="s1">internet-based advertising solutions, please see clause 2.3.3 (Information we obtain from third party sources) below. </span></p>
            <p><span className="s1">2.3.2. </span><span className="s7"><b>Information that w</b></span><span className="s4"><b>e collect automatically</b></span><span className="s3">:<br/></span><span className="s1">When you avail yourself of the Service and visit the Platform, we collect information </span><span className="s1">automatically from your device. </span></p>
            <p><span className="s1">The information we collect automatically includes information like your IP address, device type and software characteristics, unique device identification information, browser type, geographic location (e.g. country or city-level location), GPS (if enabled) and other technical information. We also collect information about how you and your device interact with the Platform, including pages you access and links you click, your purchase behaviour, engagement and interactions with perks, offers, features, polls, surveys and other content. Through our Apps, we may find out your location; remember that you can turn this feature off through your device’s settings. If you choose to receive push notifications when downloading or updating App preferences, you will receive content in that way. </span></p>
            <p><span className="s1">Collecting this information enables us to verify your identity, provide our services to you and our partners, and to get paid. The information we collect positions us to better prevent fraud and enforce our rights, your rights and third party rights. It also helps us to understand the users of the Platform, such as where they come from and what content is of interest to them. We use this information for analytics purposes, to improve the quality of our Service and Platform, and to target and display content and services that are specifically suited to you. We may also use it to target different competitions and surveys based on detail, like which university you attend. Some information is collected via the use of cookies, web beacons and similar tracking technologies. See our Cookies Policy in Schedule 3 for more information on how and why we use cookies and similar technologies. </span></p>
            <p><span className="s2">2.3.3.</span><span className="s6"><b>Information that w</b></span><span className="s1"><b>e obtain from third party sources </b></span></p>
            <p><span className="s1">From time to time, we may receive personal information about you from partners, suppliers and third party sources (including social media service providers, cookies and pixels). </span></p>
            <p><span className="s1">This information includes your online interaction, and is used to provide services, and identify you as a member or a prospective customer of a partner, to monitor and analyse trends and usage, to learn more about our members, to be as relevant as we can, to improve the quality of our services and to tailor your experience and recommend products and services that we think you’ll be interested in. We will ask that these third parties are permitted or required to disclose your personal information to us. More details are set out in our Cookies Policy in Schedule 3. </span></p>
            <p><span className="s1">We may use third party data sources to enhance the information we hold about you, only where these third parties are permitted or required to disclose your personal information to us. We help our partners display interest-based advertising using information you make available to us when you interact with our sites, content, or services. Interest-based ads, also sometimes referred to as personalised or targeted ads, are displayed to you based on information from activities such as purchasing through our Platform, use of devices, apps or software, visiting sites that contain Addreax or partner content or ads or cookies, or interacting with our tools. We offer you choices about receiving interest-based ads from us. You can choose not to receive interest-based ads from us by opting out on the Platform or here. You will still see ads, but they will not be personalised, and therefore may not be relevant to you. </span></p>
            <p><span className="s8">3. </span><span className="s1"><b>Sharing of personal information and disclosing your information – to whom may we disclose? </b></span></p>
            <p><span className="s1">3.1. &nbsp;When you use the Service, you may be asked to share personal information with others, and you may be asked to accept their terms and privacy policies (for example, with one of our clients or partners from whom you make a purchase. Please note that we are not responsible for personal data that you have shared with others. </span><span className="s4"><br/></span></p>
            <p><span className="s1">3.2. &nbsp;We will not share or disclose your data other than on the terms of this policy. We may disclose your personal information to the following categories of recipients:<br/></span></p>
            <p><span className="s1">3.2.1. Our group companies; </span><span className="s4"><br/></span></p>
            <p><span className="s1">3.2.2. Our agents and our contractors to enable them to work for us (always with confidentiality restrictions); </span><span className="s4"><br/></span></p>
            <p><span className="s1">3.2.3. Our advisers (including lawyers, accountants and information security experts so that they can carry out their services); </span><span className="s4"><br/></span></p>
            <p><span className="s1">3.2.4. Our partners (although we usually only provide aggregated information, unless there’s a competition, or something similar); </span><span className="s4"><br/></span></p>
            <p><span className="s1">3.2.5. Our third party service providers who provide data processing services to us (for example, to support the delivery of, provide functionality on, or help to provide the Services or Platforms, enhance the security of our Platforms; or to perform analytics in order to improve the quality of our services and enhance your experience), or who otherwise process personal information for purposes that are described in this policy </span><span className="s1">or notified to you when we collect your personal information. The majority of our service providers operate within Europe, however sometimes they may send your personal information outside of Europe. Some examples of the service providers we use and why are set out below: </span></p>
            <p><span className="s1">3.2.5.1. Google assists us with our security (reCAPTCHA), our analytics, and helps us to set advertisements that reflect your interests; and </span></p>
            <p><span className="s1">3.2.5.2. Facebook helps us to assess how effective our advertising is and also helps us to set advertisements that reflect your interests. </span></p>
            <p><span className="s1">3.2.6. to any competent law enforcement body, regulatory, government agency, court or other third party where we believe disclosure is necessary (i) as a matter of applicable law or regulation, (ii) to exercise, establish or defend our legal rights, or (iii) to protect your interests or those of any other person; </span></p>
            <p><span className="s1">3.2.7. to third party agencies and advertisers (to provide advertising services); </span></p>
            <p><span className="s1">3.2.8. to an actual or potential buyer, investor (and its agents and advisers) or authority in connection with any proposed restructure, public offer, purchase, merger or acquisition of any part of our business; and </span></p>
            <p><span className="s1">3.2.9. to any other person with your consent to the disclosure. </span></p>
            <p><span className="s1">4. </span><span className="s7"><b>Purpose and legal basis for processing personal information </b></span></p>
            <p><span className="s1">4.1. &nbsp;We are the data controller for the processing of your personal data provided by you when you join us and use the Service and access the Platform. </span><span className="s4"><br/></span></p>
            <p><span className="s1">4.2. &nbsp;We will primarily use your personal data to provide the Service and to continuously improve it. To fulfil this, we will process personal data for the purposes described in the table below. </span><span className="s4"><br/></span></p>
            <p><span className="s1">4.3. &nbsp;Our legal basis for collecting and using the personal information described above will depend on the personal information concerned and the specific context in which we collect and use it. </span><span className="s4"><br/></span></p>
            <p><span className="s1">4.4. &nbsp;We will collect personal information from you for the following lawful basis: where we have yourconsentto do so, where we need the personal information to perform a contract with you, or where the processing is in the legitimate interests of us, you or third party and not overridden by your data protection interests or fundamental rights and freedoms. In some cases, we may also have a legal obligation to collect personal information from you or may otherwise need the personal information to protect your vital interests or those of another person. If we ask you to provide personal information to comply with a legal requirement or to perform a contract with you, we may not be able to enter into or perform the contract or comply with our legal obligations if you do not provide that information. An example of this is when we ask you to provide us with your university or college email address or student ID as we can’t verify that you’re a student or in higher education without it. We need the information to provide the service. </span><span className="s4"><br/></span></p>
            <p><span className="s1">4.5. &nbsp;Similarly, if we collect and use your personal information in reliance on legitimate interests we will do so in the interests of providing direct marketing, to prevent and detect </span><span className="s1">fraud, for organisational reasons, to improve our services, for network and information security purposes, to ensure we comply with the law and comply with your individual rights, to ensure we process any requests you make, to provide personalised messages, to retain evidence of our compliance and to defend Addreax against claims or fraud, for monitoring of performance, to improve our use of AI, for web analytics, to host data in the cloud, to carry out limited international transfers (our business is across a number of countries), for the purposes of an acquisition or legal restructuring, to update member details and preferences, and for logistics. </span></p>
            <p><span className="s1">4.6. Some examples of the information we collect, the purposes for that collection and the lawful basis on which the processing is based include:</span></p>
            <figure className="wp-block-image"><a href="https://alumni.co/wp-content/uploads/2020/11/skarmavbild-2020-11-05-kl-125040.png"><img decoding="async" src="https://alumni.co/wp-content/uploads/2020/11/skarmavbild-2020-11-05-kl-125040.png" alt="" className="wp-image-107955" /></a></figure>
            <figure className="wp-block-image"><a href="https://alumni.co/wp-content/uploads/2020/11/skarmavbild-2020-11-05-kl-125058.png"><img decoding="async" src="https://alumni.co/wp-content/uploads/2020/11/skarmavbild-2020-11-05-kl-125058.png" alt="" className="wp-image-107957" /></a></figure>
            <p><br/></p>
            <p><span className="s1">4.7. If any other legitimate interests are relevant we will make that clear at the time. Please have a look at our Cookie Policy in Schedule 3 if you are concerned about the “how and why” of the information collected through our use of cookies. If you have questions or need further information concerning the legal basis on which we collect and use your personal information, please contact us using the contact details provided below. </span></p>
            <p><span className="s1"><b>5. How we keep your personal information secure </b></span></p>
            <p><span className="s1">5.1. &nbsp;We use appropriate technical and organisational measures such as encryption, physical security, access restrictions to our application to protect the personal information that we collect and process about you. The measures we use are designed to provide a level of security appropriate to the risk of processing your personal information. </span><span className="s7"><br/></span></p>
            <p><span className="s1">5.2. &nbsp;Where you have chosen a password that allows you to access certain parts of the Platform, you are responsible for keeping this password confidential. We advise you not to share </span>your account log-in details, including your password, with anyone. We will not be liable for any unauthorised transactions entered into using your name and password.</p>
            <p><span className="s1">5.3. The transmission of information via the internet e.g. by email is not completely secure. Although we will take steps to protect your information, we cannot guarantee the security of your data transmitted to the Platform. </span></p>
            <p><b></b><span className="s1"><b>6. Data retention </b></span></p>
            <p><b></b><span className="s1">6.1. &nbsp;We retain personal information we collect from you where we have an ongoing legitimate business need to do so (for example, to provide you with a service you have requested or to comply with applicable legal, tax or accounting requirements).<br/></span></p>
            <p><b></b><span className="s1">6.2. &nbsp;When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymise it or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible. We will not keep personal information that identified you for longer than 2 years after your membership has expired.<br/></span></p>
            <p><b></b><span className="s1"><b>7. International data transfers / treatment outside the EU/EEA </b></span></p>
            <p><b></b><span className="s1">7.1. &nbsp;Your personal information may be transferred to, and processed in, countries other than the country in which you are resident. These countries may have data protection laws that are different from the laws of your country.<br/></span></p>
            <p><b></b><span className="s1">7.2. &nbsp;Our Website servers, group companies (and staff) and third party service providers and partners operate around the world. This means that when we collect your personal information it may be processed in any of these countries.<br/></span></p>
            <p><b></b><span className="s3">7.3. &nbsp;</span><span className="s1">In particular, personal data processed in the IT systems we use to provide </span><span className="s3">the </span><span className="s1">Service </span><span className="s3">may be shared with IT providers outside </span><span className="s1">the EU/EEA. This is done because the processing is necessary to satisfy our </span><span className="s3">legitimate interest in </span><span className="s1">providing quality and reliable services with secure technology. </span><span className="s3"><br/></span></p>
            <p><b></b><span className="s3">7.4. &nbsp;However, we have taken appropriate safeguards to require that your personal information will remain protected. </span><span className="s1">If processing takes place outside the EU/EEA or in a State that is not deemed to have an adequate regulatory framework that protects your personal data, we will ensure an appropriate level of protection for your personal data. </span><span className="s3"><br/></span></p>
            <p><b></b><span className="s1">7.5. &nbsp;These safeguards are either (a) signing model clauses with the third party </span><span className="s6">(through the application of the Commission’s standard contractual clauses and Article 46(2) of the General Data Protection Regulation); </span><span className="s1">or (b) ensuring the third party is on the ‘Privacy Shield’ list; or (c) working with third parties in countries deemed to have adequate data protection laws.<br/></span></p>
            <p><b></b><span className="s1">7.6. &nbsp;Additionally, if we share personal information with a third party acting as our data processor, we remain responsible for how that information is processed. We will ensure we have a contract in place which sets out our liability clearly.<br/></span></p>
            <p><b></b><span className="s1"><b>8. </b></span><span className="s1"><b>Automated decision making </b></span></p>
            <p><span className="s1">8.1. &nbsp;Our use of your personal information (including engagement behaviour) may result in automated decisions being taken, for example, which advertisements or content to show you. It is not our intention that these decisions legally affect you or similarly significantly affect you. </span><span className="s7"><br/></span></p>
            <p><span className="s1">8.2. &nbsp;Automated decisions mean that a decision concerning you is made automatically on the basis of a computer determination (using software algorithms), without our human review. When we make an automated decision about you, you have the right to contest the decision, to express your point of view, and to require a human review of the decision. You can exercise this right by contact us using the contact details provided below. </span><span className="s7"><br/></span></p>
            <p><span className="s1">9. </span><span className="s7"><b>Your data protection rights</b></span></p>
            <p><b></b><span className="s1">9.1. You have the following data protection rights: </span></p>
            <p><span className="s11">9.1.1. </span><span className="s1"><b>Right to access, rectification or deletion </b></span></p>
            <p><span className="s3">9.1.1.1. </span><span className="s1">We want to be open and transparent about how we process your personal data. If you wish to gain insight into our </span><span className="s3">processing of your </span><span className="s1">personal data, please request an extract showing what personal data we </span><span className="s3">have </span><span className="s1">about you. If any personal data about you is incomplete or inaccurate, you have the right to request that it be corrected or deleted. </span></p>
            <p><span className="s1">9.1.1.2. If you wish to access, correct, update or request deletion of your personal information, you can do so at any time by contacting us using the contact details provided below. Please note, that if you request us to delete your data your information and all interaction information will be deleted. You must uninstall our App (if you have been using this on your phone) to remove any residual data stored on your device. </span></p>
            <p><span className="s1">9.1.1.3. </span><span className="s7"><b>Access </b></span><span className="s1">If we receive a request from you for access, we may ask for additional information to ensure what information you wish to access and that we disclose the information to the right person. </span></p>
            <p><span className="s1">9.1.1.4. We will respond to your request without undue delay and within one month. You have the right to receive such information free of charge once a year, but we may charge you a small administration fee. </span></p>
            <p><span className="s1">9.1.1.5. </span><span className="s7"><b>Rectification </b></span><span className="s1">You always have the right to request that your personal data be corrected if the data is incorrect. Within the scope of the stated purpose, you also have the right to supplement any incomplete personal data. </span></p>
            <p><span className="s3">9.1.1.6. </span><span className="s1">Upon correction, we </span><span className="s3">notify </span><span className="s1">the recipients who have received your personal data from us </span><span className="s3">that you have requested correction of </span><span className="s1">the data, unless it is impossible or involves a disproportionate effort for us to do so. </span></p>
            <p><span className="s3">9.1.1.7. </span><span className="s7"><b>Deletion </b></span><span className="s1">You have the right to request the deletion of personal data that we process if: </span></p>
            <p><span className="s1">(i) the data is no longer necessary for the purposes for which they have been collected or processed;&nbsp;</span></p>
            <p><span className="s1">(ii) &nbsp;you object to a balance of interests that we have made and there is no legitimate interest in </span><span className="s3">those of </span><span className="s1">us </span><span className="s3">who </span><span className="s1">weigh heavier; </span><span className="s4"><br/></span></p>
            <p><span className="s1">(iii) &nbsp;you object to processing for direct marketing purposes; </span><span className="s4"><br/></span></p>
            <p><span className="s1">(iv) &nbsp;the personal data has not been processed in accordance with applicable regulations; or </span><span className="s4"><br/></span></p>
            <p><span className="s1">(v) &nbsp;the personal data must be deleted in order to fulfil a legal obligation to which we are subject. </span><span className="s4"><br/></span></p>
            <p><span className="s3">9.1.1.8. </span><span className="s1">However, despite your request for deletion of personal data, we </span><span className="s3">have </span><span className="s1">the right to continue the processing and not comply with your request if the processing is necessary for us: </span></p>
            <p><span className="s1">(i) &nbsp;to fulfil a legal obligation to which we are subject; or </span><span className="s4"><br/></span></p>
            <p><span className="s1">(ii) &nbsp;to be able to establish, enforce or defend legal claims. </span><span className="s4"><br/></span></p>
            <p><span className="s3">9.1.1.9. </span><span className="s1">We will notify the recipients who have received your personal data from us </span><span className="s3">that you have requested deletion of </span><span className="s1">the data, unless it is impossible or involves a disproportionate effort for us. </span></p>
            <p><span className="s11">9.1.2. </span><span className="s1"><b>Right to restriction and portability </b></span></p>
            <p><span className="s1">9.1.2.1. You can object to the processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information. Again, you can exercise these rights by contacting us using the contact details provided below. </span></p>
            <p><span className="s3">9.1.2.2. </span><span className="s7"><b>Restriction </b></span><span className="s1">You have the right to require that our processing of your personal data be restricted if: </span></p>
            <p><span className="s1">(i) &nbsp;you dispute the accuracy of the personal data, for a period of time that allows us to verify the accuracy of the personal data; </span><span className="s4"><br/></span></p>
            <p><span className="s1">(ii) &nbsp;the processing is illegal and you object to the deletion of personal data and instead request a restriction on their use; </span><span className="s4"><br/></span></p>
            <p><span className="s1">(iii) &nbsp;we no longer need the personal data for the purposes of the processing, but you need the data in order to establish, enforce or defend legal claims; </span><span className="s4"><br/></span></p>
            <p><span className="s1">(iv) &nbsp;you have objected to processing in accordance with Article 21(1) of GDPR pending verification of whether the legitimate reasons of the controller outweigh the legitimate grounds of the data subject; </span><span className="s4"><br/></span></p>
            <p><span className="s1">(v) &nbsp;you have objected to a balance of interests that we have made as a legal basis for a purpose. You can then request limited processing for the time we </span><span className="s3">need to </span><span className="s1">check if our </span><span className="s3">legitimate interests outweigh your interests in having the </span><span className="s1">data deleted. </span><span className="s4"><br/></span></p>
            <p><span className="s3">9.1.2.3. </span><span className="s1">If the processing has been restricted under any of the above situations, we </span><span className="s3">may </span><span className="s1">only process the data in addition to the data retention itself in order to establish, enforce or defend legal claims, to protect someone else’s rights or if you have given your consent. </span></p>
            <p><span className="s3">9.1.2.4. </span><span className="s1">We will notify the recipients who have received your information from us </span><span className="s3">that you have requested limitation of processing of </span><span className="s1">the data, unless it is impossible or involves a disproportionate effort for us. </span></p>
            <p><span className="s3">9.1.2.5. </span><span className="s1">We will inform you in advance if the restriction on personal data processing ends. </span></p>
            <p><span className="s3">9.1.2.6. </span><span className="s7"><b>Portability </b></span><span className="s1">If our right to process your personal data is based on the fulfilment of commitments in a contract with you, you have the right to request that the data relating to you that you have provided to us be transferred to another data controller (so-called data porting). It is a prerequisite is that the portability can be done automated and that it is technically possible. </span></p>
            <p><span className="s11">9.1.3. </span><span className="s1"><b>Right to object on balance of interests </b></span></p>
            <p><span className="s1">You may object to us processing personal data about you on the basis of a balance of interests. In such cases, </span><span className="s3">we will cease processing unless </span><span className="s1">we </span><span className="s3">can demonstrate a compelling legitimate reason for the processing in question </span><span className="s1">that outweighs your interests, rights or freedoms. Otherwise, we </span><span className="s3">may only process the data in order </span><span className="s1">to establish, exercise or defend legal claims. </span></p>
            <p><span className="s11">9.1.4. </span><span className="s1"><b>Right to decline direct marketing (including analysis performed for purposes) </b></span></p>
            <p><span className="s3">9.1.4.1. </span><span className="s1">You may object to your personal data being processed for direct marketing purposes. The objection also covers the analysis of personal data (so-called profiling) carried out for direct marketing purposes. </span></p>
            <p><span className="s1">9.1.4.2. You have the right to opt-out of email and SMS marketing communications we send you at any time. You can exercise this right by clicking on the “unsubscribe” or “opt-out” link in the marketing emails and SMS messages we send you or through your account settings. Please note that you cannot unsubscribe from certain communication, from us, such as messages relating to your account transactions, non-promotional messages, business relationships or system updates or system issues. </span></p>
            <p><span className="s1">9.1.4.3. Please note, that to opt out of receiving mobile push notifications from Addreax, you can use your mobile device’s settings functionality to turn them off. </span></p>
            <p><span className="s3">9.1.4.4. </span><span className="s1">If you object to direct marketing, we will cease processing your personal data for that purpose and cease any direct marketing action. Please note that this may mean that we are no longer able </span><span className="s3">to provide all aspects of the </span><span className="s1">Service. </span></p>
            <p><span className="s3">9.1.4.5.</span><span className="s1">You have the option to decline mailings and personal offers in some channels. For example, you can choose to receive offers only from us by email, but not SMS. In this case, you should not object to the personal data processing for marketing purposes in general as we </span><span className="s3">will have difficulty assessing which marketing is relevant </span><span className="s1">to you. </span></p>
            <p><span className="s3">9.1.4.6. </span><span className="s1">If this is not impossible or otherwise involves a disproportionate effort for us, we will notify the recipients who have received your information from us </span><span className="s3">that you have requested that the data be not processed for direct </span><span className="s1">marketing purposes. </span></p>
            <p><span className="s2">9.1.5. </span><span className="s1"><b>Right to withdraw consent </b></span></p>
            <p><span className="s1">If we have collected and process your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent. </span></p>
            <p><span className="s2">9.1.6. </span><span className="s1"><b>Right to complain </b></span></p>
            <p><span className="s1">You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority. (Contact details for data protection authorities in the European Economic Area, Switzerland and certain non-European countries (including the US and Canada) are available here.) </span></p>
            <p><span className="s1">9.2. We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. Where we process your information based on our legitimate interests, you have the right to object to that processing, subject to certain exceptions. It is important to note that these rights are not automatic rights and may not apply in all instances. </span></p>
            <p><span className="s2">10. </span><span className="s1"><b>Children </b></span></p>
            <p><span className="s1">The Services provided by Addreax are not intended for use by children under 15 years old. We do not knowingly collect information from children under 15 years old. If you learn that your minor child has provided us with personal information without your consent, please contact us on </span><a href="mailto:support@addreax.com"><span className="s12">support@addreax.com</span></a><span className="s1">. </span></p>
            <p><span className="s11">11. </span><span className="s1"><b>Cookies and similar tracking technology </b></span></p>
            <p><span className="s1">11.1 &nbsp;We use cookies and similar tracking technologies (collectively, ”</span><span className="s7"><b>Cookies</b></span><span className="s1">”) to recognise you when you visit the Website or App of our Service. </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.2 &nbsp;A cookie is a small file that we request to be saved in your browser and stored on your computer or mobile device. Each cookie contains a small amount of information that is required, for example, to improve functionality of our Website or App so the User can access different functions of our Website or that is used to ”remember” the User’s actions or preferences over time. </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.3 &nbsp;We use two types of cookies on our Websites:<br/></span></p>
            <p><span className="s1">11.3.1 &nbsp;session cookies: these are stored only temporarily until the User leaves the Website; and </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.3.2 &nbsp;permanent cookies: these are stored on your computer or mobile device for a longer period of time (the cookie has an expiration date) or until the User removes them himself. </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.4 &nbsp;Cookies are provided either by ourselves (so-called ”</span><span className="s7"><b>First-party cookies</b></span><span className="s1">”) or by third parties (so-called ”</span><span className="s7"><b>Third-party cookies</b></span><span className="s1">”). </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.5 &nbsp;First-party are cookies that we have placed on the Website or App and these contain information about the User and keep the User logged on. We do not share this information with third parties. </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.6 &nbsp;Third-party cookies are cookies placed by our partners, including providers of marketing materials, analytics tools and social media who may place cookies on your computer or mobile device when you visit </span><span className="s3">the </span><span className="s1">Website </span><span className="s3">or App in order to deliver </span><span className="s1">their services. We are not in a position to control the content or use of these third-party cookies. </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.7 &nbsp;The User can always block cookies and/or delete cookies that have already been saved on the User’s computer or mobile device by accessing the settings in their browser. </span><span className="s4"><br/></span></p>
            <p><span className="s1">11.8 &nbsp;If the User does not accept the use of cookies or if the User chooses to block and/or delete cookies, the functionality of the Website </span><span className="s3">will </span><span className="s1">deteriorate, i.e. the User will not be able to use all the features of the Website which we offer. </span><span className="s4"><br/></span></p>
            <p><span className="s6">11.9 &nbsp;</span><span className="s1">For more information about the types of Cookies we use, why and how you can control Cookies, please see our separate Cookies Policy in Schedule 3. </span><span className="s7"><br/></span></p>
            <p><span className="s1"><b>12. Updates to this policy </b></span></p>
            <p><span className="s1">We may update this policy from time to time in response to changing legal, technical or business developments. When we update our policy, we will take appropriate measures to inform you, consistent with the significance of the changes we make. Please check back regularly for updates. </span></p>
            <p><span className="s1"><b>13. Data Controller and Data Protection Officer </b></span></p>
            <p><span className="s6">13.1 &nbsp;</span><span className="s1">For the purpose of the General Data Protection Regulation, the data controller is Addreax Group Limited, a private limited liability company incorporated and registered in England and Wales with company number 12013604 and registered address at 42 Lytton Road, Barnet, Hertfordshire EN5 5BY, United Kingdom. </span><span className="s7"><br/></span></p>
            <p><span className="s6">13.2 &nbsp;</span><span className="s1">The Data Protection Officer can be contacted at: <a href="mailto:DPO@addreax.com">DPO@addreax.com </a></span><span className="s7"><br/></span></p>
            <p><span className="s1"><b>14. How to contact us / Questions </b></span></p>
            <p><span className="s6">14.1 &nbsp;</span><span className="s1">For any questions about this policy or complaints regarding the processing of your personal data, please contact us by email at </span><a href="mailto:DPO@addreax.com"><span className="s12">DPO@addreax.com</span></a><span className="s1">. </span><span className="s7"><br/></span></p>
            <p><span className="s6">14.2 &nbsp;</span><span className="s1">If you have general questions about your account or how to contact Customer Service for assistance, please contact our online help centre at </span><a href="mailto:support@addreax.com"><span className="s12">support@addreax.com</span></a><span className="s1">.<br/></span></p>
            <p><span className="s6">14.3 </span><span className="s1">Please note that if you contact us, we may need to authenticate your identity before fulfilling your request and will talk you through the process. We use this authentication process because the security of your information is important to us and we want to be extra careful. </span></p>
            <p><span className="s1"><b>15. Disagreement with policy </b></span></p>
            <p><span className="s1">If you disagree with our policy as set out herein you should cancel your account and stop using our Services.</span></p>
          </div>
        </div>
      </div>
    )
  }
}

export default Privacy;
