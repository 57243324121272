import React, { Component } from 'react';

class Terms extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      <div className="container">
        <div className="col-md-12 pt-5 pb-5">
          <h3>USER AGREEMENT</h3>

          <div><p className="p1"><span className="s1"><b>USER AGREEMENT </b></span></p>
            <p className="p2"><span className="s1">This user agreement (hereinafter referred to as ”</span><span className="s2"><b>Agreement</b></span><span className="s1">”) is made between: </span></p>
            <p className="p2"><span className="s1">(1) &nbsp;Addreax Group Limited (hereinafter referred to as ”</span><span className="s2"><b>Addreax</b></span><span className="s1">”, ”</span><span className="s2"><b>we</b></span><span className="s1">”, ”</span><span className="s2"><b>us</b></span><span className="s1">” or ”</span><span className="s2"><b>our</b></span><span className="s1">”), a private limited liability company incorporated and registered in England and Wales with company number 12013604 and registered address at 42 Lytton Road, Barnet, Hertfordshire EN5 5BY, United Kingdom; and </span><span className="s3"><br />
            </span></p>
            <p className="p2"><span className="s1">(2) &nbsp;The individual person being the user of our Services as defined below (hereinafter referred </span><span className="s4">to as the </span><span className="s1">”</span><span className="s2"><b>User</b></span><span className="s1">”, ”</span><span className="s2"><b>you</b></span><span className="s1">” or ”</span><span className="s2"><b>your</b></span><span className="s1">”</span><span className="s4">), </span><span className="s1">and includes additionally the General Terms and Conditions set out in Schedule 1, the <a href="https://www.studentkortet.se/integritetspolicy">Privacy and Data Protection Policy</a> set out in Schedule 2 and the <a href="https://www.studentkortet.se/om-cookies/">Cookies Policy</a> set out in Schedule 3 which shall form part of this Agreement. </span></p>
            <p className="p1"><span className="s1"><b>ABOUT US AND OUR SERVICES </b></span></p>
            <p className="p2"><span className="s1">Addreax is a martech (marketing technology) company and the parent and holding company for a number of companies (together constituting the Addreax Group) which owns and operates various verticals, channels and businesses, including loyalty programmes, and provides e-commerce and internet marketing services on behalf of our clients to our members and users</span><span className="s4">. </span></p>
            <p className="p2"><span className="s1">Our verticals, channels and businesses, including loyalty programmes, currently consist of the following services (hereinafter referred </span><span className="s4">to as </span><span className="s1">”</span><span className="s2"><b>Services</b></span><span className="s1">”): </span></p>
            <p className="p2"><span className="s1">a) <strong>STUK.CO</strong> (the Student card). This is our service aimed at all registered students in Sweden, recent graduates (up to 24 months after end of studies) and others of the generation z. We cooperate with the Swedish Student Loans and Grants Office (Centrala studiestödsnämnden) and various student unions and, on behalf of these, we provide students with student ID cards. Additionally, we work to give Sweden’s students access to student discounts and other products that can be useful during their studies. By signing up for a Student or Graduate card, our members are entitled to receive various student discounts and offers from our partners. STUK.CO offers its members product offers and discounts from retailers and service providers through its online web portal </span><a href="http://www.stuk.co"><span className="s5">www.stuk.co </span></a><span className="s1">or </span><span className="s5"><a href="http://www.studentkortet.se">www.studentkortet.se</a> </span><span className="s1">and related App on the basis of affiliate cost per order type sales as well as direct sales; </span></p>
            <p className="p2"><span className="s1">b) </span><span className="s2"><b>ALUMNI.CO </b></span><span className="s1">(the Alumni card). This is our follow-on service for millennials, former students and Graduates who after 24 months of ending their studies no longer qualify for membership of STUK.CO. Those will be automatically registered in our Alumni card service where we will continue to give our members loyalty offers and discounts from retailers and service providers through our online web portal </span><a href="http://www.alumni.co"><span className="s5">www.alumni.co </span></a><span className="s1">and related App on the basis of affiliate cost per order type sales as well as direct sales; </span></p>
            <p className="p2"><span className="s1">c) </span><span className="s2"><b>Metro Mode. </b></span><span className="s1">This is our Swedish online fashion and lifestyle channel which through its website </span><a href="http://www.metromode.se"><span className="s5">www.metromode.se </span></a><span className="s1">offers its users relevant articles and content as well as product offers and discounts from retailers and service providers in sectors including clothing, furniture and home products as well as beauty, make-up and cosmetics; </span></p>
            <p className="p2"><span className="s1">d) </span><span className="s2"><b>Addreax MS </b></span><span className="s1">This is our comprehensive event management platform designed to streamline the planning, promotion, and execution of events. Whether you are organizing a small meetup or a large conference, Addreax MS provides a range of tools to help manage every aspect of your event. Key features include: Event Creation and Management, Promotional Tools, Attendee Engagement, Analytics and Reporting and Integration Capabilities.  </span></p>
            <p className="p2"><span className="s1">Please read this Agreement including our General Terms and Conditions set out in Schedule 1, our Privacy and Data Protection Policy set out in Schedule 2 and our Cookies Policy set in Schedule 3 carefully before you start using any of our Services, including the Apps and websites referred to above and any of our related social media channels (each hereinafter referred to as respectively ”</span><span className="s2"><b>App</b></span><span className="s1">”, ”</span><span className="s2"><b>Website</b></span><span className="s1">” and ”</span><span className="s2"><b>Social Media Channel</b></span><span className="s1">” and together, in relation to a Service, the ”</span><span className="s2"><b>Platform</b></span><span className="s1">”</span><span className="s4">)</span><span className="s1">. </span></p>
            <p className="p1"><span className="s1"><b>FORMATION OF AGREEMENT </b></span></p>
            <p className="p2"><span className="s1">By using any of our Services and accessing the relevant Platform, the User: (1) agrees to accept this Agreement and our General Terms and Conditions, our Privacy and Data Protection Policy and our Cookies Policy and to abide by the terms, conditions and disclaimers contained therein; and (2) enters into an agreement with Addreax consisting of this Agreement and the General Terms and Conditions set out in Schedule 1, the Privacy and Data Protection Policy set out in Schedule 2 and the Cookies Policy set out in Schedule 3 (each as amended from time to time) which shall form part of this Agreement and which together shall constitute the entire agreement between Addreax and the User for the provision of our Services and the use by the User of the relevant Platform or Platforms, superseding any prior agreements between Addreax and the User. </span></p>
            <p className="p2"><span className="s1">We recommend that you print a copy of this Agreement for future reference. </span></p>
            <p className="p1"><span className="s1"><b>CHANGES TO THE AGREEMENT </b></span></p>
            <p className="p2"><span className="s1">We shall have the right to revise this Agreement (including, for the avoidance of doubt, the aforementioned General Terms and Conditions, Privacy and Data Protection Policy and Cookies Policy) at any time by amending this page. We will endeavour to inform the User of any changes we make so that the User is given the opportunity to accept the new terms and conditions and/or policies of the Agreement through our Platform, but we cannot guarantee that all changes will be notified the User. You are therefore expected to check this page from time to time to take notice of any changes we make, as they are binding on you. </span></p>
            <p className="p3"><span className="s1"><span className="Apple-converted-space">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></span></p>
            <p className="p1"><span className="s1"><b>LAW AND JURISDICTION </b></span></p>
            <p className="p2"><span className="s1">This Agreement between Addreax and the User and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the laws of England and Wales. </span></p>
            <p className="p2"><span className="s1">Addreax and the User each irrevocably agrees that, for the sole benefit of Addreax that, subject as provided below, the courts of England and Wales shall have exclusive jurisdiction over any dispute or claim (including non-contractual disputes or claims) arising out of or in connection with this Agreement or its subject matter or formation. Nothing in this clause shall limit the right of Addreax to take proceedings against the User in any other court of competent jurisdiction, nor shall the taking of proceedings in any one or more jurisdictions preclude the taking of proceedings in any other jurisdictions, whether concurrently or not, to the extent permitted by the law of such other jurisdiction. </span></p>
            <p className="p1"><span className="s1"><b>Last Updated: 29 October 2020 </b></span></p>
            <p className="p4"><span className="s1"><b>SCHEDULE 1 </b></span></p>
            <p className="p4"><span className="s1"><b>GENERAL TERMS AND CONDITIONS</b></span><b> </b></p>
            <p className="p2"><span className="s1">These general terms and conditions form part of the Agreement between us and the User and apply to the Services provided by us through each of our Platforms and used by the User and anywhere else we interact with you. </span></p>
            <p><span className="s1"><b>1. Accessing our Services </b></span></p>
            <p className="p6"><span className="s1">1.1. &nbsp;Access to our Websites, Apps and Social Media Channels is permitted on a temporary basis, and we reserve the right to withdraw or amend the Services we provide through our Platforms without notice. </span></p>
            <p className="p6"><span className="s1">1.2. &nbsp;We shall not be liable if for any reason whatsoever any of our Platforms (or any of the constituent parts consisting of the Websites, Apps or Social Media Channels) is unavailable or does not function properly or is otherwise disrupted at any time or for any period. </span></p>
            <p className="p6"><span className="s1">1.3. &nbsp;From time to time, we may restrict or close access to some parts of our Services. We do not guarantee that that any part of our Platforms, or any of their content, will always be available, or will be available without interruption. </span></p>
            <p className="p6"><span className="s1">1.4. &nbsp;You are responsible for making all arrangements necessary for you to have the necessary or required access to our Services. You are also responsible for ensuring that all persons who access our Services through your internet or data connection are aware of this Agreement, including the General Terms and Conditions, the Privacy and Data Protection Policy and the Cookies Policy, and that they are bound by, and comply with, it. </span></p>
            <p><span className="s1"><b>2. User login, passwords and account details </b></span></p>
            <p className="p6"><span className="s1">2.1. &nbsp;Some of our Services, for example those which provide a loyalty programme, require the User to have a membership and to set up a user account and password in order to access certain offers through the relevant Platform. </span></p>
            <p className="p6"><span className="s1">2.2. &nbsp;If you choose, or you are provided with, a user identification code, password or any other piece of information as part of our security procedures and the registration process, you must treat such information as confidential, and you must not disclose it to any third party. </span></p>
            <p className="p6"><span className="s1">2.3. &nbsp;You are responsible for maintaining the confidentiality of your login, password and account details. </span></p>
            <p className="p6"><span className="s1">2.4. &nbsp;The User is solely responsible for all activities on a Platform that occur under your login, password or account. </span></p>
            <p className="p6"><span className="s1">2.5. &nbsp;If any unauthorized access to the User’s account information with us is suspected, the User shall immediately notify us thereof. </span></p>
            <p className="p6"><span className="s1">2.6. &nbsp;We have the right to disable any user identification code, password or account, whether chosen by you or allocated by us, at any time, if in our opinion you have failed to comply with any of the provisions of this Agreement.<br />
            </span></p>
            <p><b></b><span className="s1"><b>3. General rules of usage </b></span></p>
            <p className="p6"><span className="s1">3.1. &nbsp;The User may not copy, modify, distribute or publish content from any of our Platforms (or any of the constituent parts consisting of the Websites, Apps or Social Media Channels) without permission from us except for his own personal use. </span></p>
            <p className="p6"><span className="s1">3.2. &nbsp;If we suspect that the User:<br />
            </span></p>
            <p className="p6"><span className="s1">3.2.1. has provided us with incorrect, wrongful or false information at the time of concluding our Agreement or at any time during the term of our Agreement; and/or<br />
            </span></p>
            <p className="p6"><span className="s1">3.2.2. intends to collect and disseminate discount codes; and/or<br />
            </span></p>
            <p className="p6"><span className="s1">3.2.3. no longer agree to any of the terms and conditions of this Agreement; and/or 3.2.4. violates any of the terms and conditions of the Agreement, we reserve the right to immediately and without prior notice suspend the User from all our Services and Platforms and delete the User’s member account and profile. </span></p>
            <p className="p6"><span className="s1">3.3. &nbsp;The User accepts through this Agreement that we may send you promotions or offers from the applicable Services from time to time by providing you with emails, SMS, push notifications, newsletters, native articles, and other relevant mailings via one or more media. </span></p>
            <p className="p6"><span className="s1">3.4. &nbsp;You may at any time cancel your membership of a Service and the usage of a related Platform via your account (My Profile) or via contact with our member service (email address: </span><a href="mailto:support@addreax.com"><span className="s5">support@addreax.com</span></a><span className="s1">). In such case we will delete you from the relevant Service database and you will no longer be allowed access to that Service and Platform. Any subsequent access to such Services or Platform will constitute a new agreement between us for the provision of the relevant Service on the terms and conditions of this Agreement. </span></p>
            <p className="p6"><span className="s1">3.5. &nbsp;The User may also unsubscribe from receiving emails and SMS notifications from a Service via unsubscribe links on the respective mailings and/or SMS. This will not constitute a cancellation of your membership of a Service (see clause 3.4 above for how to cancel your membership) and you may continue to use the relevant Platform for that Service provided always that we shall be entitled to reactivate sending you emails and SMS notifications from the next following half year day, being 1 January or 1 July, subsequent to the date on which you unsubscribe unless you have cancelled your membership of the relevant Service prior to such half-year day. </span></p>
            <p className="p6"><span className="s1">3.6. &nbsp;The User is aware that s/he may incur data or connection charges to the User’s telephone operator or internet service provider when using our services and we shall not be liable to the User for any such charges. </span></p>
            <p className="p5"><span className="s8">4. </span><span className="s1"><b>Content, links and third party content</b></span></p>
            <p className="p6"><span className="s1">4.1. The material provided on our Platforms (or via any email or SMS or push or other form<br />
              of notification) is provided to our members and users for information purposes only and should not be construed as advice or recommendation provided by Addreax for specific or particular goods and services. </span></p>
            <p className="p2"><span className="s1">4.2. &nbsp;Addreax does not guarantee the accuracy of the information provided by an advertiser of by us on behalf of an advertiser and any action that you undertake on the basis of information presented through our Services and Platforms (including via any email or SMS or push or other form of notification) is your own responsibility and at your own risk and we therefore recommend that you seek to verify all information provided before making any purchasing decisions and placing an order. </span></p>
            <p className="p2"><span className="s1">4.3. &nbsp;Although we aim to update the content of our Platforms (and any of the constituent parts consisting of the Websites, Apps or Social Media Channels) on a regular basis, we cannot guarantee that promotional offers displayed or provided through our Services (including our Platforms and via any email or SMS or push or other form of notification) are fully accurate and exhaustive. Some of the material displayed or provided through our Services (including our Platforms and via any email or SMS or push or other form of notification) may therefore be incorrect or out of date at any given time. </span></p>
            <p className="p2"><span className="s1">4.4. &nbsp;In the event of any difference between the information and data appearing on or provided through </span><span className="s4">our </span><span className="s1">Platforms (and any email or SMS or push or other form of notification) and that appearing on a third party’s website, the latter shall prevail unless the third party agrees otherwise. </span></p>
            <p className="p2"><span className="s1">4.5. &nbsp;In line with our aim to update the content of </span><span className="s4">our </span><span className="s1">Websites, Apps or Social Media Channels regularly we may change the content at any time in order to update offers, special promotions and expired offers. It is not possible to notify you of all such changes, but where possible, practicable and/or relevant based on your profile, notice through email, SMS, push notifications etc will be given to you. </span></p>
            <p className="p2"><span className="s1">4.6. &nbsp;As part of our Services to you we may provide links to other websites or display third party’s promotional offers or discounts. You might find yourself prompted to view other websites or third party’s promotional offers or discounts whilst using our Services. We are not responsible for the content of any other website or pages linked in our Platforms and have not verified information on them. Information displayed through our Services is provided without any guarantees, conditions or warranties as to its accuracy. If you follow links to other websites or pages, you do so at your own risk and we are not liable and responsible for any damages which may arise from your actions in this regard. </span></p>
            <p className="p2"><span className="s1">4.7. &nbsp;Where our Services contain or provide links to other websites and resources provided by third parties, these links are provided for your information only. We have no control over the contents of the third parties’ sites or resources therefore we accept no responsibility for the goods or services advertised by the third parties through our Services or for any loss or damage that may arise from or in connection with use of or reliance on them. </span></p>
            <p className="p2"><span className="s1">4.8. &nbsp;You acknowledge that it is your responsibility to evaluate the accuracy and completeness of any information provided by third parties through our Services. Use of the content (for whatever purpose) is at your sole risk. </span><span className="s3"><br />
            </span></p>
            <p className="p2"><span className="s1">4.9. &nbsp;You acknowledge that we will not be a party to any transaction or contract with any third party that you may enter into and we have no liability in relation to the products, offers and discounts displayed or provided through our Services. You agree that we cannot be held responsible for any damage or loss that occurred or may occur as a result of your entering into an agreement with any of the companies listed with us or marketed through any of our Services and you agree that you will not involve us in any dispute between you and third party. </span></p>
            <p><span className="s1"><b>5. Updates to our Services / autoenrollment to Services </b></span></p>
            <p className="p6"><span className="s1">5.1. &nbsp;We may add new verticals, channels and businesses, including loyalty programmes, to our Services at any time. </span></p>
            <p className="p6"><span className="s1">5.2. &nbsp;As an existing or past member or user of one or more of our Services, you consent that we may automatically enrol you in any new, as well as existing, verticals, channels, businesses or loyalty programmes forming part of our Services and for which you qualify. </span></p>
            <p className="p6"><span className="s1">5.3. &nbsp;As part of such automatic enrollment to one or more of our Services, we may provide you with newsletters, SMS, push notifications and other relevant mailings via one or more media. </span></p>
            <p><span className="s1"><b>6. Intellectual property rights </b></span></p>
            <p className="p6"><span className="s1">6.1. &nbsp;Your use of our Services and the contents displayed on our Platforms grants you no rights in relation to the intellectual property including, without limitation, copyright, trade marks, logos, graphics, photographs, animations, videos and text in our Services and Platforms and their content. </span></p>
            <p className="p6"><span className="s1">6.2. &nbsp;We (and where relevant, our third party clients) are the owner or the licensee of all intellectual property rights in our Services and Platforms and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved. </span></p>
            <p className="p6"><span className="s1">6.3. &nbsp;You may print off one copy, and may download extracts, of any page(s) from our Platforms for your personal reference and you may draw the attention of others to material posted on our Platforms. </span></p>
            <p className="p6"><span className="s1">6.4. &nbsp;You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. </span></p>
            <p className="p6"><span className="s1">6.5. &nbsp;Our status (and that of any identified contributors) as the authors of material on our Platforms must always be acknowledged. </span></p>
            <p className="p6"><span className="s1">6.6. &nbsp;You must not use any part of the materials on our Platforms for commercial purposes without obtaining a licence to do so from us or our licensors. </span></p>
            <p className="p6"><span className="s1">6.7. &nbsp;If you print off, copy or download any part of our Platforms in breach of this Agreement, your right to use our Services will cease immediately and you must, at our option, return or destroy any copies of the materials you have made. </span></p>
            <p className="p6"><span className="s1">7. </span><span className="s2"><b>Competitions</b></span></p>
            <p className="p6"><span className="s1">7.1. From time to time we arrange or promote competitions for members and users of our </span><span className="s1">Services and Platforms. </span></p>
            <p className="p2"><span className="s1">7.2. &nbsp;By participating in competitions through our Platforms, competitions promoted by us </span><span className="s4">or otherwise organised by or in </span><span className="s1">cooperation with us, the User accepts </span><span className="s4">the terms of </span><span className="s1">the competition. </span></p>
            <p className="p2"><span className="s1">7.3. &nbsp;We never publish your name, image and/or any reason you give for taking part or text submitted by you at the end of the competition unless consent for this exists between us </span><span className="s4">and </span><span className="s1">the User. </span></p>
            <p className="p2"><span className="s1">7.4. &nbsp;We are not responsible for any loss or damage to competition prizes sent by post or courier. </span></p>
            <p className="p2"><span className="s1">7.5. &nbsp;Any applicable tax on prizes will be paid by the User. </span><span className="s3"><br />
            </span></p>
            <p className="p1"><span className="s8">8. </span><span className="s1"><b>Processing of Personal Data </b></span></p>
            <p className="p2"><span className="s1">8.1. &nbsp;We collect and process information about you in accordance and compliance with the Data Protection Act 2018 which is the United Kingdom’s implementation of the </span><span className="s4">General Data Protection </span><span className="s1">Regulation (”</span><span className="s2"><b>GDPR</b></span><span className="s1">”). </span></p>
            <p className="p2"><span className="s1">8.2. &nbsp;Our Privacy and Data Protection Policy set out in Schedule 2 sets out how we implement your rights and our obligations under the Data Protection Act 2018 / GDPR. </span></p>
            <p className="p2"><span className="s1">8.3. &nbsp;Our Services are web, app and/or social media based services, utilising emails, SMS, push notifications, newsletters, native articles and other relevant delivery methods through which members and users will be informed of offers, promotions and discounts provided by our clients. Such delivery methods are an essential part of our committed service to you. To comply with GDPR the option to cancel a Service is available (see clause 3.4 above for more detail) and the option to unsubscribe is embedded in every email and SMS message we send (see clause 3.5 above for more detail). </span><span className="s3"><br />
            </span></p>
            <p className="p1"><span className="s8">9. </span><span className="s1"><b>Viruses </b></span></p>
            <p className="p2"><span className="s1">9.1. &nbsp;While we take every possible precaution to ensure that our Websites are free from viruses, we cannot guarantee that our Websites or any third party website linked to them are free from viruses. The User is responsible for having in place their own virus protection software. </span></p>
            <p className="p2"><span className="s1">9.2. &nbsp;We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs, data or other proprietary material due to your use of our Services or to your downloading of any material posted on it, or on any website linked to it. </span></p>
            <p className="p2"><span className="s1">10. </span><span className="s2"><b>Feedback or questions<br />
            </b></span><span className="s1">If you have any concerns or questions or would like to provide feedback please contact: </span><a href="mailto:support@addreax.com"><span className="s1">support@addreax.com</span></a><span className="s9">.</span></p>
          </div>

        </div>
      </div>
    )
  }
}

export default Terms;
