import React from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { printShort, toReadablePrice } from '../../elements/form/Currency';

class BillingItem extends React.Component { 

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return <Card>
                    <CardHeader>{this.props.header}</CardHeader>
                    <CardBody>
                        <div>Count: {this.props.count}</div>
                        <br></br>
                        {this.props.amount.length <= 0 ? <div>Payout amount: 0</div> : null}
                        {this.props.amount.map(amount => {
                            return <div key={amount.currency}>
                                    {this.props.includeFees ? <div>Sales amount: {printShort(toReadablePrice(amount.amount_without_fees), amount.currency)}</div> : null}
                                    {this.props.includeFees ? <div>Fee amount: {printShort(toReadablePrice(amount.fee_amount), amount.currency)}</div> : null}
                                    <div>Payout amount: {printShort(toReadablePrice(amount.amount), amount.currency)}</div>
                                    <br></br>
                                </div>
                        }) }
                    </CardBody>
                </Card>
    }

}

export default BillingItem