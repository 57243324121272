import React, { Component } from 'react';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

class UserForm extends Component {

    componentDidMount(){
        
    }

    render() {
        return this.props && this.props.user ? <CardBody>
            <Form>
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for="first_name">First name</Label>
                            <Input type="text" name="first_name" id="first_name" defaultValue={this.props.user.first_name} onChange={(e) => this.props.onChange(e, 'first_name')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="last_name">Last name</Label>
                            <Input type="text" name="last_name" id="last_name" defaultValue={this.props.user.last_name} onChange={(e) => this.props.onChange(e, 'last_name')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="organization_name">Organization name</Label>
                            <Input type="text" name="organization_name" id="organization_name" defaultValue={this.props.user.organization_name}  disabled />
                        </FormGroup>
                    </Col>
                    <Col sm="6">
                        <FormGroup>
                            <Label for="email">Email</Label>
                            <Input type="text" name="email" id="email" defaultValue={this.props.user.email} disabled/>
                        </FormGroup>

                        <FormGroup>
                            <Label for="user_id">User id</Label>
                            <Input type="text" name="user_id" id="user_id" defaultValue={this.props.user.id} disabled />
                        </FormGroup>

                        <FormGroup>
                            <Label for="status">Status</Label>
                            <Input type="text" name="status" id="status" defaultValue={this.props.user.status ? 'Active': 'Inactive'} disabled />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>

        </CardBody> : null
    }

}

export default UserForm;
