import React from 'react';
import {Input, Button, FormText, UncontrolledAlert, Label, Row, Col, Card, CardHeader, CardBody, Modal, ModalBody, ModalHeader, ModalFooter} from 'reactstrap';
import 'react-querybuilder/dist/query-builder.scss';
import { addNotification, getNotificationById, deleteNotificationById, getNotificationMemberCount, getSegmentTokenCount, updateNotificationById, getCustomers } from '../../../vibe/helpers/apiHelper';
import DatePicker from '../../elements/form/DatePicker'
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import moment from 'moment'
import { getSegments, sendPushTest} from '../../../vibe/helpers/apiHelper';
import 'antd/dist/antd.css';
import Select from "antd/lib/select";
import { Link } from 'react-router-dom';

import { getNotificationSendingData, parseErrorMessage } from '../../../vibe/helpers/util';

class NotificationNew extends React.Component {  
  constructor(props) {
    super(props);
    
    this.state = {
      notificationSaveError: false,
      notificationSaved: false,
      fetchErr: false,
      selectedDay: null,
      currentTime: null,
      currentDate: null,
      selectedSegment: 0,
      segmentOptions: [],
      currentPage: 1,
      modal: false,
      deleteModal: false,
      formData: null,
      customerOptions: [],
      showCustomerDropdown: false,
      customer: ''
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDayChange = this.handleDayChange.bind(this);
    this.onChange = this.onChange.bind(this)
    this.toggle = this.toggle.bind(this)
    this.toggleDelete = this.toggleDelete.bind(this)
    this.handleSubmitConfirm = this.handleSubmitConfirm.bind(this)
    this.handleSubmitSend = this.handleSubmitSend.bind(this)
    this.handleFieldsChange = this.handleFieldsChange.bind(this)
    this.preparePushTest = this.preparePushTest.bind(this)
  }


  handleSubmit(event){
    event.preventDefault();
    if( this.state.selectedDay && this.state.selectedDay !== 'Invalid date' && this.state.currentTime ){
      this.setState({formData: event}, () => this.toggle() )
    }else {
      this.handleSubmitSend(event)
    }
  }

  handleSubmitConfirm(){
    this.handleSubmitSend(this.state.formData)
    this.toggle()      
  } 

  handleSubmitSend(event){ 
    event.preventDefault();

    if (this.state.notificationSaved) {
      return
    }

    let customer = event.target[0].value ? event.target[0].value : null
    let title = event.target[1].value
    let text = event.target[2].value
    let destination = event.target[3].value
    let segment_id = this.state.selectedSegment === 0 ? null : this.state.selectedSegment

    let schedule
    if(this.state.currentTime && this.state.selectedDay){
      //schedule = new Date(this.state.selectedDay + ' ' + this.state.currentTime.format('HH:mm:ss') + ".000Z" );

      // a hack to fix date in firefox
      schedule = '' + this.state.selectedDay + 'T' + this.state.currentTime.format('HH:mm:ss') + ".000Z"
      schedule = schedule.replace(/\//g, '-')
    } 

    // if ID exist, lets update
    if(this.props.notificationId){
      var data = {
        id: parseInt(this.props.notificationId),
        title: title,
        text: text, 
        destination: destination, 
        customer: customer,
        schedule: schedule, 
        segment_id: segment_id
      } 
  
      updateNotificationById(data,  (data) => {   
          this.setState({notificationSaved: true})
          setTimeout(() => {
              this.setState({ notificationSaved: false }) 
            }, 5000)
      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({notificationSaveError: errorMessage })
        setTimeout(() => this.setState({ notificationSaveError: false }), 5000)       
      })  

    }else{
      // Save notification!
      addNotification(title, text, destination, customer, schedule, segment_id,  (data) => { 
        if(data){
          this.setState({notificationSaved: true})
          setTimeout(() => {
            this.props.history.replace('/notification/edit/'+data.id)
          }, 2000)
        }
      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({notificationSaveError: errorMessage })
        setTimeout(() => this.setState({ notificationSaveError: false }), 5000)       
      })       
    }
  }



  renderAlerts() {

    if (this.state) {
        if (this.state.notificationSaved) {
            return <UncontrolledAlert color="success">
                Push Notification {this.props.notificationId ? 'updated! ': 'saved!'}
            </UncontrolledAlert>
        } else if (this.state.notificationSaveError) {
            return <UncontrolledAlert color="danger">
                Error saving Push Notification, {this.state.notificationSaveError}
            </UncontrolledAlert>
        }
    }
  }

  componentDidMount() {

    getCustomers((customers) => {
      let customerOptions = []
      if (customers && customers.length > 0) {
        customers.forEach((customer) => {
            let customerOption = {
                value: customer,
                label: customer,
            }
            customerOptions.push(customerOption)
        })
      }
      this.setState({customerOptions: customerOptions})
    }, (error) => {
      console.log("Error getting customers", error)
    }) 

    if(this.props.notificationId !== undefined){
      this.fetchQuery()
      getNotificationMemberCount(this.props.notificationId, (count) => {
        this.setState({memberCount: count})
      }, (error) => {
        this.setState({memberCount: 'error'})
      })
    } else {
      this.fetchSegments(0)
      getSegmentTokenCount(this.state.selectedSegment, (count) => {
        this.setState({memberCount: count})
      }, (error) => {
        this.setState({memberCount: 'error'})
      })
    }
  }

fetchSegments(offset){
  var where = ''
  getSegments(offset, where, (result) => {
    var totalCount = result.totalCount  
    if(result){
      var options = []
      if(this.state.currentPage === 1){
        options.push({
          value: 0,
          label: 'Everyone'
        })
      }

      for (let i = 0;  i < result.segments.length; i++) {
        options.push({
          value: result.segments[i].id,
          label: result.segments[i].name,
        })
      }
      this.setState(prevState => { return { segmentOptions: prevState.segmentOptions.concat(options), totalCount: totalCount  }} )
    }
  }, (error) => {
    this.setState({ loading: false, totalCount: 0, currentPage: -1 })
    console.log("No push notification found")
  })
}

 // Fetch data for edit!
 fetchQuery() {

    var where = '?filter[include][][relation]=segment'
    getNotificationById(this.props.notificationId, where, (data) => {   
      if(data){
        var date = data.schedule && new Date(data.schedule).toISOString().split('T')[0];
        var time = data.schedule && new Date(data.schedule).toISOString().split('T')[1].split('.')[0];

        this.setState({
            notification: data,
            title: data.title, 
            text: data.text,
            customer: data.customer,
            destination: data.destination,             
            selectedSegment: data.segment_id ? data.segment_id : 0, 
            segment: data.segment,
            currentTime: time && moment(time, 'HHmmss'),     
            currentDate: date && date.replace(/-/g,'/'),
            selectedDay: date,
            status: data.status,
            total: data.total_members,
            success: data.success,
            errors: data.errors
        })
      }

      if( (this.state.status === 0 || this.state.status === 1) )  {
        this.fetchSegments(0)
      }

    }, (error) => {
      this.setState({fetchErr: true})   
    }) 
 }


 handleDayChange(day) {
  this.setState({ selectedDay: day });
 }

 onChange(value) {
  // console.log(value && value.format(format));   
  // const format = 'H:mm';
  this.setState({currentTime: value})
 }

loadMoreSegments(){
  this.setState({ currentPage: this.state.currentPage + 1 });
  var offset = this.state.currentPage * 10
  this.fetchSegments(offset)
}

handleSelectChange(value){
  this.setState({selectedSegment: value})
  getSegmentTokenCount(value, (count) => {
    this.setState({memberCount: count})
  }, (error) => {
    this.setState({memberCount: 'error'})
  })
}


beforeTodayDate(){
  const now = new Date()
  const nowDate = now.toISOString().split('T')[0]
  return moment(this.state.selectedDay).format('YYYY-MM-DD') !==  nowDate
}

getDisabledHours = () => {
  if(this.beforeTodayDate()){
    return
  }

  var hours = [];
  for(var i =0; i < moment().hour(); i++){
    hours.push(i);
  }
  return hours;
}

getDisabledMinutes = (selectedHour) => {
  if(this.beforeTodayDate()){
    return
  }
  
  var minutes= [];
  if (selectedHour === moment().hour()){
    for(var i =0; i < moment().minute() + 5; i++){
        minutes.push(i);
    }
  }
  return minutes;
}

toggle(e = null) {
  this.setState(prevState => ({
      modal: !prevState.modal
  }));
}

toggleDelete(e = null) {
  this.setState(prevState => ({
      deleteModal: !prevState.deleteModal
  }));
}

preparePushTest(){
  if(this.state.pin && this.state.title && this.state.destination && this.state.text ){
    let push = {
      title: this.state.title,
      destination: this.state.destination,
      text: this.state.text,
      pin: this.state.pin
    }

    sendPushTest(push, (result) => {
      this.setState({testResultMsg: result.message, testResult: true})
      setTimeout(() => {
        this.setState({ testResult: false }) 
      }, 5000)
    }, (error) => {

    })

  }
}

handleFieldsChange(e){
  const value = e.target.value;
  this.setState({
    ...this.state,
    [e.target.name]: value
  })
}

onSuggestionClick(customer) {
  this.setState({showCustomerDropdown: false, customer: customer})
}

toggleCustomerDropdown(show) {
  this.setState({showCustomerDropdown: show})
}

onDeleteClick() {
  this.setState({deleteModal: true})
}

deleteNotification() {
  this.setState({deleteModal: false})
  if (this.props.notificationId) {
    deleteNotificationById(this.props.notificationId, () => {
      this.props.history.push('/notifications')
    }, (error) => {
      var errorMessage = parseErrorMessage(error)
      this.setState({notificationSaveError: errorMessage })
    })
  } else {
    this.props.history.push('/notifications')
  }
}

render() {

    if(this.state.fetchErr){
      return 'Notification does not exist or you do not have permissions to view it!'
    }

    const customerStyle = {
      position: 'absolute',
      backgroundColor: 'white',
      cursor: 'pointer',
      padding: '0 16px 0 16px',
      marginTop: '4px',
      zIndex: 1
   }

   if (this.state.showCustomerDropdown) {
      customerStyle.maxHeight = '350px'
      customerStyle.overflowY = 'auto' 
   }

    return (
      <React.Fragment> 
        {this.renderAlerts()}
        <div className="row mb-3">
            <div className="col">
              <h1 className="h4 mb-0">{this.state.status === 0 || this.state.status === 1 ? this.props.notificationId ? 'Update ' : 'New ' : ''} Push Notification</h1>
            </div>
        </div>


        { (this.state.status === 0 || this.state.status === 1 ) || !this.props.notificationId ? 
              <React.Fragment>
                <Card>
                  <CardBody>
                    <div className="row">
                      <div className="col-12">
                        <p className="muted">
                            Push notifications will automatically be sent to all members in your selected and computed segment at the date and time for this booking.<br/>
                            The push message will not be saved or sent without at least a title and a destination configured below.
                        </p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-7 col-12">
                        <form onSubmit={this.handleSubmit}>
                      
                          <div className="form-group mb-4">
                            <Input type="text" name="customer" id="customer" onBlur={() => this.toggleCustomerDropdown(false)} onFocus={() => this.toggleCustomerDropdown(true)} value={this.state.customer} placeholder="Customer" autoComplete="off" onChange={this.handleFieldsChange} />
                            <Card style={customerStyle}> 
                            { 
                              this.state.showCustomerDropdown && this.state.customerOptions.map((item, index) => (
                                (!this.state.customer || item.value.toLowerCase().includes(this.state.customer.toLowerCase()) ) ? 
                                <div className={'pt-1 pb-1  select-item'} key={index + item.value} onMouseDown={() => this.onSuggestionClick(item.value)}>{item.value}</div>
                                : null
                              ))
                            } 
                            </Card> 

                            <FormText color="muted">
                              Optional. Will not be included in the push message sent to the devices.
                            </FormText>
                          </div>

                          <div className="form-group">
                            <Input type="text" name="title" id="title" defaultValue={this.state.title} placeholder="Title" autoComplete="off" onChange={this.handleFieldsChange} required/>
                            <FormText color="muted">
                              Notification title - will be shown to the user
                            </FormText>
                          </div>
                          <div className="form-group">
                            <Input type="textarea" name="text" id="text" placeholder="Text" defaultValue={this.state.text} onChange={this.handleFieldsChange}  required />
                            <FormText color="muted">
                              Notification text - will be shown to the user. Maximum length of the text depends on the users device and preferences. Keep it short.
                            </FormText>
                          </div>
                          <div className="form-group">
                            <Input type="text" name="destination" id="destination" defaultValue={this.state.destination} placeholder="Destination" autoComplete="off" onChange={this.handleFieldsChange}  required/>
                            <FormText color="muted">
                            The destination within the app where the user will be redirected when clicking the notification.
                            </FormText>
                          </div>

                          <div className="form-group">
                            <Label>Schedule</Label>
                            <div className="inline-block datePicker">
                              
                            {this.props.notificationId && this.state.currentDate ?
                              <DatePicker handleChange={this.handleDayChange} value={this.state.currentDate} source='push' />
                              : <DatePicker handleChange={this.handleDayChange} source='push' /> 
                            }
                                
                              <TimePicker showSecond={false} onChange={this.onChange} placeholder="HH:MM" allowEmpty={false}  value={this.state.currentTime} 
                                  disabledHours={this.getDisabledHours}  disabledMinutes={this.getDisabledMinutes} inputReadOnly	
                              />
                            </div>
                            <FormText color="muted">
                              You have to schedule date and time to be able to send push. Leave it empty will only save push.
                            </FormText>
                          </div>

                          <div className="form-group">
                            <Label>Segment</Label>
                            <div className="inline-block datePicker">
                              <Select
                                showSearch="true"
                                placeholder="Select Segment..."
                                style={{ width: 300 }}
                                options={this.state.segmentOptions}   
                                value={this.state.selectedSegment}
                                defaultValue={!this.props.notificationId && this.state.selectedSegment}                     
                                dropdownRender={menu => (
                                    <React.Fragment>
                                        {menu}
                                        { this.state.segmentOptions.length < this.state.totalCount && 
                                        <Button className="ant-select-item ant-select-item-option" block onClick={() => this.loadMoreSegments()}>
                                            Load more...
                                        </Button>}
                                    </React.Fragment>
                                )}

                                filterOption={(input, option) =>
                                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }

                                onChange={(value) => {
                                  console.log(value)
                                  this.handleSelectChange(value)
                                }}                                                 
                              />
                            </div>

                            {this.state && this.state.memberCount !== null ? 
                            <FormText color="black">
                              Members with device tokens in segment: {this.state.memberCount}
                            </FormText> : null}

                          </div>

                            <Button
                              className="mt-3 mb-5 ml-0"
                              color="primary"
                              size={this.state.selectedDay && this.state.selectedDay !== 'Invalid date' && this.state.currentTime ? "btn btn-warning" : "btn btn-success"}
                              type="submit" 
                              block
                            >
                              <i className="fa fa-check"></i>&nbsp;{this.state.selectedDay && this.state.selectedDay !== 'Invalid date' && this.state.currentTime ? 'Send ' : 'Save '} Push Notification
                            </Button>
                            <Button
                              className="ml-0"
                              color="danger"
                              size="btn"
                              block
                              onClick={() => this.onDeleteClick()}
                              >
                              <i className="fa fa-trash"></i>&nbsp;Delete
                            </Button>                  
                        </form>
                      </div>

                      <div className="col-md-5 col-12" style={{backgroundColor: '#eee', border:'1px #c7c7c7 solid',borderRadius:'5px', paddingTop:'15px'}}>
                        <h5>Test push message</h5>
                        <div className="form-group">
                          <FormText color="muted">
                            You can test this message by manually sending to selected PINs. Use commas as separators for multiple push notification.
                          </FormText>
                        </div>
                        <div className="form-group">
                          <Input type="text" name="pin" id="pushtest"  placeholder="YYYYMMDDNNNN" autoComplete="off" required onChange={this.handleFieldsChange} />
                          <Button
                            className="mt-3 mb-5 ml-0"
                            color="primary"
                            size="btn"
                            type="submit" 
                            block
                            onClick={this.preparePushTest}
                          >
                          <i className="fa fa-check"></i>&nbsp;Send
                        </Button>
                        </div>
                        {this.state.testResultMsg && this.state.testResult && 
                            <UncontrolledAlert color="info">
                            {this.state.testResultMsg}
                            </UncontrolledAlert>
                          }
                      </div>
                    </div>
                </CardBody>
                </Card>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className="deleteModal">
                  <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
                  <ModalBody>
                      <h5>Are you sure you want to send the message?</h5>                      
                  </ModalBody>
                  <ModalFooter>
                      <Button color="primary" className="px-5" onClick={this.handleSubmitConfirm}>Yes</Button>{' '}
                      <Button color="secondary" className="px-5" onClick={this.toggle}>Cancel</Button>
                  </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.deleteModal} toggle={this.toggleDelete} className="deleteModal">
                  <ModalHeader toggle={this.toggleDelete}>Delete notification</ModalHeader>
                  <ModalBody>
                      <h5>Are you sure you want to delete this notification? This action can not be undone</h5>                      
                  </ModalBody>
                  <ModalFooter>
                      <Button color="primary" className="px-5" onClick={() => this.deleteNotification()}>Delete</Button>{' '}
                      <Button color="secondary" className="px-5" onClick={this.toggleDelete}>Cancel</Button>
                  </ModalFooter>
                </Modal>

              </React.Fragment>
              : 
              <React.Fragment>

                <Row>
                  <Col md={4} xs={12}>
                    <Card>
                      <CardHeader>
                        Total Members
                      </CardHeader>
                      <CardBody>
                        <h2 className="m-b-20 inline-block bold-600">
                          <span>{this.state.total && this.state.total.toLocaleString()}</span>
                        </h2>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4} xs={12}>
                    <Card>
                      <CardHeader>
                        Send Success
                      </CardHeader>
                      <CardBody>
                        <h2 className="m-b-20 inline-block bold-600">
                          <span>{this.state.success && this.state.success.toLocaleString()}</span>                  
                        </h2>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4} xs={12}>
                    <Card>
                      <CardHeader>
                        Send Errors
                      </CardHeader>
                      <CardBody>
                        <h2 className="inline-block bold-600">
                          <span>{ this.state.errors && this.state.errors.toLocaleString()}</span>                  
                        </h2>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Card>
                  <CardBody>
                      <h1 className="h4 mb-0 pb-4">Details</h1>
                      <dl>
                        <dt>Title</dt>
                        <dd className="mb-3 text-muted">{this.state.title}</dd>

                        <dt>Text</dt>
                        <dd className="mb-3 text-muted">{this.state.text}</dd>

                        <dt>Destination</dt>
                        <dd className="mb-3 text-muted">{this.state.destination}</dd>

                        <dt>Schedule</dt>
                        <dd className="mb-3 text-muted">{this.state.currentDate && this.state.currentTime && this.state.currentDate + ' ' +  moment(this.state.currentTime).format('HH:mm') }</dd>

                        <dt>Segment</dt>
                        {this.state.segment ? <Link className="" to={'/segment/edit/' + this.state.segment.id}>
                          <dd className="mb-3">{this.state.segment.name}</dd>
                        </Link> : <dd className="mb-3 text-muted">{'Everyone'}</dd>}

                        <dt>Status</dt>
                        <dd className="mb-3 text-muted">{this.state.status === 2 ? <div className="text-info">Sending {getNotificationSendingData(this.state.notification)}</div> : <div className="text-success">Sent</div>}</dd>
                      </dl>
                  </CardBody>
                </Card>
            </React.Fragment>
             
          }

      </React.Fragment>                
    );
  }
}
export default NotificationNew
