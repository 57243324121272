import React from 'react';
import { Button } from 'reactstrap';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import { Loader } from '../../../vibe';
import { PUBLISH_STATUS_DRAFT, PUBLISH_STATUS_PUBLISHED } from '../../elements/form/FormAction';

export function getSuccessButtonText(item) {
    if (!item.id || item?.status === PUBLISH_STATUS_DRAFT) {
        return 'Publish'
    }
    return 'Update'
}

/**
 * Super class for editable forms. Implement following methods in sub class:
 *   - getItem()
 *   - renderForm(item)
 *   - onSave()
 *   - validate() - only if form validation is necessary
 * 
 *   Holds the object in state as "item"
 *    other state variables:
 *     - loading, error, itemSaveError, itemEdited, itemSaved
 *   
 *   examples: see EditPackage.js
 */
class EditableForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            itemEdited: false,
            itemSaveError: null,
            itemSaved: false,
            error: null,
            item: null,
            onSave: false,
        }
    }

    componentDidMount() {
        this.getItem()
    }

    getItem() {

    }

    render() {
        if (this.state.loading) {
            return <Loader type={"dots"} />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else if (this.state.item) {
            return this.renderPage(this.state.item)
        } else {
            return null
        }
    }

    renderForm(item) {

    }

    onFormError(error) {
        var errorMessage = parseErrorMessage(error, true)
        this.setState({ itemSaveError: errorMessage })
    }

    onFormSuccess() {
        this.setState({ itemSaved: true, itemEdited: false, itemSaveError: null })
        setTimeout(() => this.setState({ itemSaved: false }), 5000)
    }

    onClickSave(status) {
        this.setState({ onSave: true });

        let validate = this.validate(this.state.item)
        if (!validate.valid) {
            this.onFormError(validate.message)
            return
        }
        this.onSave(status)

    }

    validate(item) {
        for (const fieldName in item) {
            const fieldValue = item[fieldName];

            if (fieldValue === '' && this.fieldIsRequired(fieldName)) {
                return {
                    valid: false,
                    message: `Field "${fieldName}" is required.`,
                };
            }
        }

        return { valid: true }
    }

    fieldIsRequired(fieldName) {
        const requiredFields = ['title', 'description'];
        return requiredFields.includes(fieldName);
    }

    isFieldInvalid(fieldName) {
        if (this.state.onSave) {
            const { item } = this.state;
            const fieldValue = item[fieldName];

            return fieldValue === '' && this.fieldIsRequired(fieldName);
        }
    }

    onSave() {

    }

    onChange(value, key) {
        var item = this.state.item
        item[key] = value
        this.setState({ item: item, itemEdited: true })
    }

    renderAlerts() {
        return <MessageUncontrolledAlert success={this.state.itemSaved} error={this.state.itemSaveError}
            successMessage={'Saved successfully!'} errorMessage={'Error: ' + this.state.itemSaveError} />
    }

    renderFormFooter() {
        return <>
            {!!this.state.item.id && <Button title={this.state.eventDelButtonDisabled ? 'The event can not be deleted because it contains a occurrence with a completed transaction' : null} disabled={this.state.eventDelButtonDisabled} className="" color="danger" outline={true} onClick={() => this.onDelete()}>
                <i className="fa fa-trash"></i>&nbsp;Delete</Button>}

            {/* {this.hasDeleteButton() ? <Button disabled="true" className="mx-3" color="danger" onClick={() => this.onDelete()}><i className="fa fa-trash"></i>&nbsp;Delete</Button> : null} */}

            <Button className="ml-3" color="primary" outline={true} onClick={() => this.onClickSave(PUBLISH_STATUS_DRAFT)}>
                <i className="fa fa-check"></i>&nbsp;{!this.state.item.id || (this.state?.item?.status === PUBLISH_STATUS_DRAFT) ? 'Save draft' : 'Switch to draft'}</Button>

            <Button className="ml-3" color="success" onClick={() => this.onClickSave(PUBLISH_STATUS_PUBLISHED)}><i className="fa fa-check"></i>&nbsp;
                {getSuccessButtonText(this.state.item)}</Button>

        </>
    }

    onDelete() {

    }

    hasDeleteButton() {
        return false
    }

    renderPage(item) {
        return <React.Fragment>
            {this.renderAlerts()}

            <header className="app-header-page justify-content-end">
                <div>
                    {this.renderFormFooter(item)}
                </div>
            </header>


            {this.renderForm(item)}
        </React.Fragment>
    }

}

export default EditableForm