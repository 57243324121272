import React from 'react';
import { FormGroup } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getMemberships, getMembershipsWithTotalCount, toEncodedFilterString } from '../../../vibe/helpers/apiHelper';
import { Link } from "react-router-dom";
import Select from 'react-select'
import { getMembershipSelectOptions } from '../../../vibe/helpers/util';
import Membership from '../../elements/membership/Membership';
import Paginator from '../../elements/common/Paginator';

class Memberships extends Paginator {
  componentDidMount() {
    this.setState({ loading: true, options: [] }, () => {
      this.getMemberships(0)
      this.getMembershipSelectOptions()
    })
  }

  getMembershipSelectOptions() {
    getMemberships('', (memberships) => {
      var options = getMembershipSelectOptions(memberships)
      this.setState({ options: options })
    }, (error) => {

    })
  }

  onSelectMembership(e) {
    this.props.history.push('/membership/edit/' + e.value)
  }

  renderMemberships() {
    if (this.state && this.state.memberships) {
      return this.state.memberships.map((membership) => {
        return <Membership key={membership.id} membership={membership} />
      })
    }
  }

  fetchNextPage(offset) {
    this.setState({loading: true}, () => {
      this.getMemberships(offset)
    })
  }

  getMemberships(offset) {
    let filterObject = {
      limit: this.state.pageSize,
      order: ["id DESC"],
      skip: offset
    }

    let filter = toEncodedFilterString(filterObject)

    getMembershipsWithTotalCount(filter, (memberships, count) => {
      this.setState({ memberships: memberships, totalCount: count, loading: false })
    }, (error) => {
      this.setState({ error: error, totalCount: -1, currentPage: -1, loading: false })
    })
  }

  render() {

    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>
            <header className="app-header-page justify-content-end">
              <div className="col-md-3 mw-350">
                <FormGroup className="mb-0">
                  <Link className="btn btn-primary block" to="/membership/new">
                    New Membership
                  </Link>
                </FormGroup>
              </div>
            </header>

            <div className="row mb-3">

              <div className="col mr-auto">
                <h1 className="h4 mb-0">Memberships</h1>
                {this.state.totalCount > 0 ? <small className="text-muted">{this.state.totalCount} memberships</small> : null} 
              </div>

              <div className="col">
                <Select options={this.state.options} onChange={(e) => this.onSelectMembership(e)} placeholder={"Search membership..."} />
              </div>
            </div>

            <div>
              {this.renderMemberships()}
              {this.renderPagination()}
            </div>
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default Memberships;
