export const DURATION_TYPE_DEFAULT = 1
export const DURATION_TYPE_DAYS_IMMEDIATELY = 2
export const DURATION_TYPE_DAYS_FUTURE = 3

export const durationTypeOptions = [
    {value: DURATION_TYPE_DEFAULT, label: 'Default (Membership validity)'}, 
    {value: DURATION_TYPE_DAYS_IMMEDIATELY, label: 'Duration in days, starts when membership is added to member'}, 
    {value: DURATION_TYPE_DAYS_FUTURE, label: 'Duration in days, user select start date'}
]

const DURATION_DAYS = [DURATION_TYPE_DAYS_IMMEDIATELY, DURATION_TYPE_DAYS_FUTURE]

export function isDefault(membershipSubscription) {
    if (!membershipSubscription || !membershipSubscription.duration_type) {
        return true
    }

    return membershipSubscription.duration_type === DURATION_TYPE_DEFAULT
}

export function isDays(membershipSubscription) {
    return membershipSubscription && DURATION_DAYS.includes(membershipSubscription.duration_type)
}