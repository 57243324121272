import React, { Component } from 'react';
import SidebarToggleButton from './components/ToggleSidebarButton';
import { UncontrolledAlert, Navbar, Nav, Button } from 'reactstrap';
import { matchPath } from 'react-router-dom';
import { searchMember } from '../../../vibe/helpers/apiHelper';
import * as Icon from 'react-feather';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }
  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getPageTitle = () => {
    let name;
    this.props.routes.map(prop => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false
        })
      ) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };


  searchMember = e => {
    e.preventDefault()
    var searchInput = this.refs.searchInput.value
    searchMember(searchInput, (members) => {
      if (members && members.id) {
        const { history } = this.props
        history.push('/member/edit/' + members.id)
      } else {
        this.setState({ error: 'not found' }) // TODO show that there are no results here
      }
    }, (error) => {
      this.setState({ error: error }); // TODO show that there are no results here
    })
  }

  onDismiss = e => {
    this.setState({ error: '' })
  }

  render() {
    return (
      <header className="app-header">
        <div className="top-nav">
          <Navbar color="faded" light expand="md">
            <SidebarToggleButton
              toggleSidebar={this.props.toggleSidebar}
              isSidebarCollapsed={this.props.isSidebarCollapsed}
              desktop={false}
            />

            {
              this.getPageTitle() === 'Members' || this.getPageTitle() === 'Dashboard' ?
                this.state.error ?
                  <UncontrolledAlert toggle={this.onDismiss} className="mb-0" color="info">
                    No results found!
                  </UncontrolledAlert>
                  :
                  <form className="form-inline searchForm" onSubmit={this.searchMember}>
                    <Button type='submit' className="d-none d-sm-block" color="link" outline={false}>
                      <Icon.Search size="20" color="#999999" />
                    </Button>
                    <input className="form-control mr-sm-1" type="search" placeholder="Search by PIN, member ID or email..." aria-label="Search" ref="searchInput" required />
                  </form>
                : ''
            }

            <Nav className="ml-auto" navbar>
              {this.props.children}
            </Nav>
          </Navbar>
        </div>
      </header>
    );
  }
}