import React, { Component }  from 'react';
import { Button, Col, Form, Modal, ModalBody, ModalHeader, Row, ModalFooter, Label } from 'reactstrap';
import { completeTransaction, createTransactionMessage, refundTransaction } from '../../../vibe/helpers/apiHelper';
import { printShort, toReadablePrice } from '../../elements/form/Currency';
import { Loader } from '../../../vibe';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const TYPE_REFUND = 1

class TransactionActionsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            item: {},
        }
        this.toggleModal = this.toggleModal.bind(this);
       
    }

    onChange(value, key) {
        var item = this.state.item
        item[key] = value
        this.setState({ item: item })
    }

    toggleModal() {
        this.props.toggle()
    }

    refund (transactionId) {
        refundTransaction(transactionId,() => {
            this.props.onAction('Transaction ' + transactionId + " issued for refund",null);
            this.setState(prevState => ({
                loading: !prevState.loading,
            }));
            this.toggleModal(); 
        }, (error) => {
            this.props.onAction(null, error)
            this.toggleModal()
            console.log(error);
        }
    );
    }

    onSaveRefund(transaction, message) {
        this.setState(prevState => ({
            loading: !prevState.loading,
        }));
    
        if (message) {
            createTransactionMessage(transaction.id, TYPE_REFUND, message, () => {
                    this.refund(transaction.id)
                }, (error) => {
                    console.log(error)
            });
        } else {
            this.refund(transaction.id)
        }
    }
 
    onSaveAccept(transaction) {
        this.setState(prevState => ({
            loading: !prevState.loading,
        }))
        completeTransaction(transaction.id, () => {
            this.props.onAction('Transaction ' + transaction.id + " changed to Completed status", null)
            this.setState(prevState => ({
                loading: !prevState.loading,
            }))
            this.toggleModal()
        }, (error) => {
            this.props.onAction(null, error)
            this.toggleModal()
        })
    }

    isPackageTransaction(transaction) {
        return transaction?.package_transactions && transaction?.package_transactions?.length > 0
    }

    renderTransactionMessageInput() {
        return <div style={{paddingLeft: "1rem"}}>
        <Label for="message">Add a comment? (will be included in the refund email)</Label>
        <CKEditor
            editor={ClassicEditor}
            data={''}
            onReady={editor => {
            }}
            onChange={(event, editor) => {
                const data = editor.getData();
                this.onChange(data, 'message')
            }}
           /> 
        </div> 
    }
      
    renderTransactionActionsModal(transaction, member) {
     
        var acceptTransactionText = (
            <span>
                Accept <strong>Transaction ID: {transaction.id}</strong> for member <strong>{member.first_name} {member.last_name}</strong>?<br/><br/> This member will receive all memberships included in this package.
            </span>
        );

        // Are you sure you want to proceed with the refund for member.first_name member.last_name in the amount of [Total Amount]? 
        // This action is irreversible and will return the funds to the customer. Please review the refund details carefully before confirming.
        var refundTransactionText = (
            <span>
                Are you sure you want to proceed with the refund for <strong>{member.first_name} {member.last_name}</strong> in the amount of <strong>{printShort(toReadablePrice(transaction.amount), transaction.currency)}</strong>?<br/><br/> This action is irreversible and will return the funds to the customer. Please review the refund details carefully before confirming.
            </span>
        )

        var modalTitle = '';
       
        if (this.props.action === 'Accept') {
            modalTitle = "Accept transaction";
        } else {
            modalTitle = 'Refund transaction';
        }
       
        return <> 
            <Modal isOpen={this.props.opened} toggle={this.toggleModal} className="previewModal">
                    <ModalHeader toggle={this.toggleModal}><strong>{modalTitle}</strong></ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row>
                                <Col>
                                    <p>{this.props.action === 'Accept' ? acceptTransactionText : refundTransactionText}</p>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>

                    {this.props.action === 'Accept' && <>
                    <ModalFooter className='d-flex justify-content-between'>
                        <Button size="lg" className="px-5 mt-3" color="success" onClick={() => this.onSaveAccept(transaction)}>Accept</Button>
                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                    </ModalFooter>
                    </>}

                        {this.props.action === 'Refund' && this.isPackageTransaction(transaction) ? this.renderTransactionMessageInput() : null }
                        {this.props.action === 'Refund' &&  <>
                        <ModalFooter className='d-flex justify-content-between'>
                            <Button size="lg" className="px-5 mt-4" color="danger" onClick={() => this.onSaveRefund(transaction, this.state.item.message)}>Refund</Button>
                            <Button size="lg" className="px-5 mt-4" onClick={() => this.toggleModal()}>Cancel</Button>
                        </ModalFooter> 
                        </>}
                       
                         {this.state.loading && 
                            <Row>
                                <Col >
                                    <Loader type='dots'/>
                                </Col>
                            </Row>} 
            </Modal>
        </>
    }
        
    render() {
       return this.renderTransactionActionsModal(this.props.transaction, this.props.member)
    }

    
}


export default TransactionActionsModal;