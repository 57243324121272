import React from 'react';
import { Col } from 'reactstrap';
import { getCurrentUser, getOrganizationEventsWithTickets, getPackages, getTransactionDetails } from '../../../vibe/helpers/apiHelper';
import { getEventOptions, getPackageOptions, getTicketOptions, insertFirstOption, parseErrorMessage } from '../../../vibe/helpers/util';
import ListHeader from '../../elements/form/ListHeader';
import FormSelect from '../common/FormSelect';
import PayoutPurchaseDetails from '../payout/PayoutPurchaseDetails';
import { printShort, toReadablePrice } from '../../elements/form/Currency';
import BillingItemHeader from '../billing/BillingItemHeader';
import { Loader } from '../../../vibe';
import DateIntervalPicker, { CUSTOM_RANGE_ID } from '../common/DateIntervalPicker';

class SalesAnalytics extends React.Component { 

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            package_id: 0,
            ticket_id: 0,
            event_id: 0,
            from: null,
            to: null,
            error: null,
            billingResults: [],
            scope: 'all',
            packageOptions: [],
            ticketOptions: [],
            eventOptions: [],
            dateOptions: this.getDateOptions()
        }
    }

    componentDidMount() {

        getPackages((packages) => {
            let packageOptions = getPackageOptions(packages)
            insertFirstOption(0, 'All packages', packageOptions)
            this.setState({packageOptions: packageOptions})
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage})
        })

        getCurrentUser((user) => {

            this.setState({organizationId: user.organization_id})

            getOrganizationEventsWithTickets(user.organization_id, (organizationEvents) => {

                var eventOptions = getEventOptions(organizationEvents)
                insertFirstOption(0, 'All events', eventOptions)

                var ticketOptions = getTicketOptions(organizationEvents, false)
                insertFirstOption(0, 'All tickets', ticketOptions)
                this.setState({ticketOptions: ticketOptions, eventOptions: eventOptions})
            }, (error) => {
                console.log("error", error)
            })
        })
    }

    getTransactionDetails() {
        this.setState({loading: true})

        const packageId = this.state.package_id || 0
        const ticketId = this.state.ticket_id || 0
        const eventId = this.state.event_id || 0
        const from = this.state.from
        const to = this.state.to

        getTransactionDetails(from, to, packageId, eventId, ticketId, (transactionDetails) => {
            this.setState({transactionDetails: transactionDetails?.sold, refunds: transactionDetails?.refunded, loading: false})
        }, (error) => {
            this.setState({loading: false})
        })
    }

    formatDateOption(date) {
        const offset = date.getTimezoneOffset()
        date = new Date(date.getTime() - (offset * 60 * 1000))
        date = date.toISOString().split('T')[0]
        return date
    }

    getDateOptions() {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        const previous7Days = new Date();
        previous7Days.setDate(today.getDate() - 7);

        const previous30Days = new Date();
        previous30Days.setDate(today.getDate() - 30);

        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

        const firstDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastDayOfPreviousMonth = new Date(today.getFullYear(), today.getMonth(), 0);

        const firstDayOfYear = new Date(today.getFullYear(), 0, 1);

        const options = [
            {
                id: 1,
                label: 'Today',
                from: this.formatDateOption(today),
                to: this.formatDateOption(today)
            },
            {
                id: 2,
                label: 'Yesterday',
                from: this.formatDateOption(yesterday),
                to: this.formatDateOption(yesterday)
            },
            {
                id: 3,
                label: 'Last 7 days',
                from: this.formatDateOption(previous7Days),
                to: this.formatDateOption(today)
            },
            {
                id: 4,
                label: 'Last 30 days',
                from: this.formatDateOption(previous30Days),
                to: this.formatDateOption(today)
            },
            {
                id: 5,
                label: 'This month',
                from: this.formatDateOption(firstDayOfMonth),
                to: this.formatDateOption(today)
            },
            {
                id: 6,
                label: 'Last month',
                from: this.formatDateOption(firstDayOfPreviousMonth),
                to: this.formatDateOption(lastDayOfPreviousMonth)
            },
            {
                id: 7,
                label: 'Year to date',
                from: this.formatDateOption(firstDayOfYear),
                to: this.formatDateOption(today)
            },
            {
                id: CUSTOM_RANGE_ID,
                label: 'Custom range',
                from: null,
                to: null 
            }
        ];

        return options
    }

    onDateChange(dates, optionName) {
        const {from, to} = dates
        let newState = {from: from, to: to, optionName: optionName}
        this.setState(newState, () => {
            this.getTransactionDetails()
        })
    }

    onChange(key, value) {
        let newState = {[key]: value}

        // when selecting a package, unselect ticket and when selecting a ticket, unselect package
        // also set scope to the filter selected
        if (key === 'package_id') {
            newState.ticket_id = -1
            newState.event_id = -1
        }

        if (key === 'ticket_id') {
            newState.package_id = -1
        }

        if (key === 'event_id') {
            newState.package_id = -1
            newState.ticket_id = 0
        }

        this.setState(newState, () => {
            this.getTransactionDetails()
        })
    }

    renderTransactionDetails() {
        if (!this.state.transactionDetails) {
            return null
        }

        return <>
            <PayoutPurchaseDetails title={'Items sold'} transactionCount={this.state.transactionDetails?.transaction_count} transactionDetails={this.state.transactionDetails} />

            <div className="col-md-6 col-12">
                <div className="card h-100">
                    <div className="card-body">
                        <h4 className="card-title">Financial Summary</h4>
                        <div className="mb-2"><p className="list-item-heading mb-0">Total Sales Amount</p> {printShort(toReadablePrice(this.state.transactionDetails.amount_without_fees), "SEK")}</div>
                        <div className="mb-2"><p className="list-item-heading mb-0">Fee Amount</p> {printShort(toReadablePrice(this.state.transactionDetails.fee_amount), "SEK")}</div>
                        <div className="mb-2 bold-600"><p className="list-item-heading mb-0">Payout Amount</p> {printShort(toReadablePrice(this.state.transactionDetails.amount), "SEK")}</div>
                    </div>
                </div>
            </div>

            <PayoutPurchaseDetails title={'Items refunded'} transactionCount={this.state.refunds?.transaction_count} transactionDetails={this.state.refunds} />
        </>
    }

    renderContent() {
        if (this.state.loading) {
            return <Loader type="dots" />
        }

        return <div className="row justify-content-start align-items-start">
            {this.renderTransactionDetails()}
        </div>
    }

    render() {

        return <>
            <ListHeader title={'Sales Analytics'} links={''} />

            <div className='row mb-3'>
                    <div className='col col-2'>
                        <DateIntervalPicker options={this.state.dateOptions} selectOption={3} label={'Date interval'} onChange={(dates, optionName) => this.onDateChange(dates, optionName)} />
                    </div>

                    <div className='col col-2'>
                        <FormSelect label={'Package'} options={this.state.packageOptions} onChange={(e) => this.onChange('package_id', e.value)} placeholder={"Select package..."} selected={this.state.package_id} />
                    </div>

                    <div className='col col-2'>
                        <FormSelect label={'Event'} options={this.state.eventOptions} onChange={(e) => this.onChange('event_id', e.value)} placeholder={"Select event..."} selected={this.state.event_id} />
                    </div>
            </div>

            <div className='row mb-3'>
                <Col>
                    <BillingItemHeader scope={this.state.scope} packageOptions={this.state.packageOptions} optionName={this.state.optionName} eventOptions={this.state.eventOptions} ticketOptions={this.state.ticketOptions} ticket_id={this.state.ticket_id} event_id={this.state.event_id} package_id={this.state.package_id} from={this.state.from} to={this.state.to} />
                </Col>
            </div>

            {this.renderContent()}

        </>
    }

}

export default SalesAnalytics