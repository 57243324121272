import React, { Component } from 'react';
import {Input, Col, Form, FormGroup, Label, Row} from 'reactstrap';

class OrganizationForm extends Component {

    render() {
        return <Form>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label for="id">ID</Label>
                        <Input type="text" name="id" id="id" defaultValue={this.props.organization ? this.props.organization.id : ''} disabled />
                    </FormGroup>

                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" name="name" id="name" defaultValue={this.props.organization ? this.props.organization.name : ''} onChange={(e) => this.props.onChange(e.target.value, 'name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="display_name">Display name</Label>
                        <Input type="text" name="display_name" id="display_name" defaultValue={this.props.organization ? this.props.organization.display_name : ''} onChange={(e) => this.props.onChange(e.target.value, 'display_name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="category_name">Category name</Label>
                        <Input type="text" name="category_name" id="category_name" defaultValue={this.props.organization ? this.props.organization.category_name : ''} onChange={(e) => this.props.onChange(e.target.value, 'category_name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="country">Country</Label>
                        <Input type="text" name="country" id="country" defaultValue={this.props.organization ? this.props.organization.country : ''} onChange={(e) => this.props.onChange(e.target.value, 'country')} />
                    </FormGroup>

                    <FormGroup check>
                        <Label for="root">
                            <Input type="checkbox" name="root" id="root" defaultChecked={this.props.organization ? this.props.organization.root : false} onChange={(e) => this.props.onChange(e.target.checked, 'root')} />{' '}
                            Root
                        </Label>
                    </FormGroup>

                    {/* TODO special stuk only fields */}
                    <FormGroup check>
                        <Label for="partner">
                            <Input type="checkbox" name="partner" id="partner" defaultChecked={this.props.organization ? this.props.organization.partner : false} onChange={(e) => this.props.onChange(e.target.checked, 'partner')} />{' '}
                            Partner
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Label for="appliable">
                            <Input type="checkbox" name="appliable" id="appliable" defaultChecked={this.props.organization ? this.props.organization.appliable : false} onChange={(e) => this.props.onChange(e.target.checked, 'appliable')} />{' '}
                            Appliable
                        </Label>
                    </FormGroup>

                    <FormGroup check>
                        <Label for="school">
                            <Input type="checkbox" name="school" id="school" defaultChecked={this.props.organization ? this.props.organization.school : false} onChange={(e) => this.props.onChange(e.target.checked, 'school')} />{' '}
                            School
                        </Label>
                    </FormGroup>
                    
                </Col>
                <Col sm="6">

                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="text" name="email" id="email" defaultValue={this.props.organization ? this.props.organization.email : ''} onChange={(e) => this.props.onChange(e.target.value, 'email')} /> {' '}
                    </FormGroup>

                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input type="text" name="city" id="city" defaultValue={this.props.organization ? this.props.organization.city : ''} onChange={(e) => this.props.onChange(e.target.value, 'city')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="street_address">Street address</Label>
                        <Input type="text" name="street_address" id="street_address" defaultValue={this.props.organization ? this.props.organization.street_address : ''} onChange={(e) => this.props.onChange(e.target.value, 'street_address')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="name">Zip code</Label>
                        <Input type="text" name="zip_code" id="zip_code" defaultValue={this.props.organization ? this.props.organization.zip_code : ''} onChange={(e) => this.props.onChange(e.target.value, 'zip_code')} />
                    </FormGroup>

                    
                </Col>
            </Row>
        </Form>
    }

}

export default OrganizationForm;
