import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody, Form, FormGroup, Input, Label, Row, Col } from 'reactstrap';
import { formatDateYYYYmmDD } from '../../../vibe/helpers/dateHelper';
import { isSuperUser } from '../../../vibe/helpers/permissionHelper';
import SimpleDateTimePicker from '../form/SimpleDateTimePicker';

class Memberships extends Component {

    componentDidMount() {

    }

    getMembershipStatusClass(membershipsAndOrganizations) {
        if (this.props.memberMembershipsToDelete && this.props.memberMembershipsToDelete.includes(membershipsAndOrganizations.memberMembership.id)) {
            return 'py-4 bg-danger'
        } else if (membershipsAndOrganizations.isEdited) {
            return 'py-4 bg-warning'
        } else {
            return 'py-4'
        }
    }

    getSelectedValue(key, fallbackKey) {
        if (!this.props.selectedMembership) {
            return null
        }

        if (this.props.selectedMembership[key]) {
            return this.props.selectedMembership[key]
        }

        return this.props.selectedMembership[fallbackKey]
    }

    render() {
        return <CardBody className='py-4'>
            {
                this.props && this.props.showMembershipForm ?
                    <div>
                        <Card className="card-body b-a">
                            <Form>
                                {
                                    this.props.organizations && this.props.organizations.map((organizations, index) => {
                                        return <FormGroup key={index}>
                                            <Label for="org">Select organization</Label>
                                            <Input type="select" name="select" id="org" onChange={(e) => this.props.onSelectOrganization(e, index)}>
                                                <option key={-(index + 1)} value={-1}>Organization (level {index + 1})</option>
                                                {
                                                    organizations && organizations.map((organization) => {
                                                        return <option key={organization.id} value={organization.id}>{organization.id} | {organization.name}</option>
                                                    })
                                                }
                                            </Input>
                                        </FormGroup>
                                    })
                                }


                                <FormGroup>
                                    <Label for="membership">Select membership</Label>
                                    <Input type="select" name="select" id="membership" onChange={(e) => this.props.onSelectMembership(e.target.value)}>
                                        {
                                            this.props.membershipOptions && this.props.membershipOptions.length > 0 && this.props.membershipOptions.map((membership) => {
                                                return <option key={"membership_" + membership.id} value={membership.id}>{membership.id} | {membership.name}</option>
                                            })
                                        }
                                    </Input>
                                </FormGroup>

                                {this.props.selectedMembership ? <div>
                                    <Row>
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label for="membership_valid_from">Valid from</Label>
                                                <SimpleDateTimePicker name={"_valid_from"} value={this.getSelectedValue('_valid_from', 'valid_from')} onChange={(value, key) => this.props.onEditSelectedMembership(key, value)} />
                                            </FormGroup>
                                        </Col>
                                    
                                        <Col sm="3">
                                            <FormGroup>
                                                <Label for="membership_valid_to">Valid to</Label>
                                                <SimpleDateTimePicker name={"_valid_to"} value={this.getSelectedValue('_valid_to', 'valid_to')} onChange={(value, key) => this.props.onEditSelectedMembership(key, value)} />
                                            </FormGroup>
                                        </Col>
                                    
                                    </Row>
                                </div> : null}

                            </Form>
                            <Button className='mb-4' color="primary" onClick={() => this.props.addMembership()}>Add membership</Button>
                        </Card>
                    </div>
                    : <Button className='mb-4' color="primary" onClick={() => this.props.toggleMembershipForm()}>Add new membership</Button>
            }


            {
                this.props && this.props.pendingMemberships && this.props.pendingMemberships.map((pendingMembership) => {
                    return <CardBody key={pendingMembership.key} className='card-body b-a align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero card py-4 bg-warning'>

                        {isSuperUser(this.props.user) ?
                            <div className="mb-1 text-small w-25 w-sm-100">
                                <p className="list-item-heading mb-1">Id</p>
                                <p className="list-item-text mb-1">{pendingMembership.membership.id}</p>
                            </div>
                            : null}

                        <div className="mb-1 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Name</p>
                            <p className="list-item-text mb-1">{pendingMembership.membership.name}</p>
                        </div>

                        <div className="mb-1 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Semester</p>
                            <p className="list-item-text mb-1">{pendingMembership.membership.semester}</p>
                        </div>
                        <div className="mb-1 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Valid from</p>
                            <p className="list-item-text mb-1">{formatDateYYYYmmDD(pendingMembership.membership.valid_from)}</p>
                        </div>
                        <div className="mb-1 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Valid to</p>
                            <p className="list-item-text mb-1">{formatDateYYYYmmDD(pendingMembership.membership.valid_to)}</p>
                        </div>
                        <div className="mb-1 text-small w-50 w-sm-100">
                            <p className="list-item-heading mb-1">Organizations</p> {pendingMembership.organizations && pendingMembership.organizations.map(org => {
                                return <p key={org} className="list-item-text mb-1">{org}</p>
                            })}
                        </div>
                        <Button className='mt-3' color="danger" onClick={() => this.props.deletePendingMembership(pendingMembership.key)}>Delete</Button>
                    </CardBody>
                })
            }

            {
                this.props && this.props.membershipsAndOrganizations && this.props.membershipsAndOrganizations.map((membershipAndOrganizations, i) => {
                    
                    var organizationIds = membershipAndOrganizations.organizations.map(function (organization) {
                        return organization.id + " "
                    })
                    return <Card className="b-a" key={i}>
                        <CardHeader className="b-b">
                            <div className="row mb-3">
                                <div className="col">
                                    {membershipAndOrganizations.organizations && membershipAndOrganizations.organizations.map((organization) => {
                                        return <div className="list-item-text mb-1 bold-600" key={organization.id}>{organization.id} | {organization.name}</div>
                                    })}
                                </div>
                                <div className="col">
                                    <p className="text-muted mb-0 float-right">
                                        {this.props.editedMemberMembership && this.props.editedMemberMembership.id === membershipAndOrganizations.memberMembership.id ?
                                            <Button className='d-inline-block mr-2' color="primary" onClick={() => this.props.cancelEditMemberMembership()}>Cancel</Button>
                                            :
                                            <Button className='d-inline-block mr-2' color="primary" onClick={() => this.props.editMemberMembership(membershipAndOrganizations)}>Edit</Button>
                                        }
                                        <Button className='btn-inline-block' color="danger" onClick={() => this.props.deleteMembership(membershipAndOrganizations.memberMembership.id)}>Delete</Button>
                                    </p>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody key={membershipAndOrganizations.membership.id + "_" + organizationIds} className={'bg-gray no-radius-b-l d-flex flex-column flex-lg-row justify-content-between min-width-zero  ' + this.getMembershipStatusClass(membershipAndOrganizations)}>

                            {isSuperUser(this.props.user) ?
                                <div className="mb-1 text-small w-25 w-sm-100 text-center">
                                    <p className="list-item-heading mb-1">Id</p>
                                    <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.membership.id}</p>
                                </div>
                                : null}

                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Name</p>
                                <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.membership.name}</p>
                            </div>
                             
                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Semester</p>
                                <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.membership.semester}</p>
                            </div>

                            <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                <p className="list-item-heading mb-1">Updated At</p>
                                <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.memberMembership.updated_at ? formatDateYYYYmmDD(membershipAndOrganizations.memberMembership.updated_at) : ''}</p>
                            </div>

                            {this.props.editedMemberMembership && this.props.editedMemberMembership.id === membershipAndOrganizations.memberMembership.id ?
                                <React.Fragment>
                                    <FormGroup>
                                        <Label for="membership_valid_from">Valid from</Label>
                                        <SimpleDateTimePicker name={"_valid_from"} value={membershipAndOrganizations.memberMembership.valid_from} onChange={(value, key) => this.props.onChangeMemberMembership({target: {value: value}}, 'valid_from', this.props.membershipsAndOrganizations, membershipAndOrganizations)}  />
                                    </FormGroup>

                                    <FormGroup>
                                        <Label for="membership_valid_to">Valid to</Label>
                                        <SimpleDateTimePicker name={"_valid_to"} value={membershipAndOrganizations.memberMembership.valid_to} onChange={(value, key) => this.props.onChangeMemberMembership({target: {value: value}}, 'valid_to', this.props.membershipsAndOrganizations, membershipAndOrganizations)} />
                                    </FormGroup>
                                    

                                    <FormGroup className="mb-1 text-small w-75 w-sm-100 text-center">
                                        <Label className="list-item-heading mb-1" for="card_code">Card code</Label>
                                        <Input className="w-75" type="text" name="card_code" id="card_code" defaultValue={membershipAndOrganizations.memberMembership.card_code} onChange={(e) => this.props.onChangeMemberMembership(e, 'card_code', this.props.membershipsAndOrganizations, membershipAndOrganizations)} />
                                    </FormGroup>

                                    <FormGroup className="mb-1 text-small w-75 w-sm-100 text-center">
                                        <Label className="list-item-heading mb-1" for="card_extra_text">Card text</Label>
                                        <Input className="w-75" type="text" name="card_extra_text" id="card_extra_text" defaultValue={membershipAndOrganizations.memberMembership.card_extra_text} onChange={(e) => this.props.onChangeMemberMembership(e, 'card_extra_text', this.props.membershipsAndOrganizations, membershipAndOrganizations)} />
                                    </FormGroup>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                        <p className="list-item-heading mb-1">Valid from</p>
                                        <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.memberMembership.valid_from ? formatDateYYYYmmDD(membershipAndOrganizations.memberMembership.valid_from) : formatDateYYYYmmDD(membershipAndOrganizations.membership.valid_from)}</p>
                                    </div>
                                    <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                        <p className="list-item-heading mb-1">Valid to</p>
                                        <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.memberMembership.valid_to ? formatDateYYYYmmDD(membershipAndOrganizations.memberMembership.valid_to) : formatDateYYYYmmDD(membershipAndOrganizations.membership.valid_to)}</p>
                                    </div>

                                    <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                        <p className="list-item-heading mb-1">Card code</p>
                                        <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.memberMembership.card_code}</p>
                                    </div>
                                    <div className="mb-1 text-small w-50 w-sm-100 text-center">
                                        <p className="list-item-heading mb-1">Card text</p>
                                        <p className="list-item-text mb-1 bold-600">{membershipAndOrganizations.memberMembership.card_extra_text}</p>
                                    </div>
                                </React.Fragment>
                            }

                        </CardBody>
                    </Card>
                })
            }
        </CardBody>
    }

}

export default Memberships;
