import React, { Component } from 'react';
import * as Icon from 'react-feather';

const MOBILE_SIZE = 992;

export default class SidebarToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: window.innerWidth <= MOBILE_SIZE,
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  render() {
    const { isSidebarCollapsed, toggleSidebar } = this.props;

    const chevronClassName = isSidebarCollapsed ? 'is-collapsed' : 'is-not-collapsed';
    const screenReaderLabel = isSidebarCollapsed ? 'Expand Sidebar Navigation' : 'Collapse Sidebar Navigation';
    const arrowIcon = isSidebarCollapsed ? <Icon.Menu color="black" className="side-nav-icon" /> : <Icon.Menu color="black" className="side-nav-icon" />
    const arrowIconDesktop = !this.state.isMobile && isSidebarCollapsed ? <Icon.ChevronRight color="white" className="side-nav-icon chevronRight" /> : <Icon.ChevronLeft color="white" className="side-nav-icon chevronLeft" />

    return (
      this.props.desktop ?
        <li className="nav-item">
          <span onClick={toggleSidebar} className={`sidebar-toggle sidebar-button ${chevronClassName}`} aria-label={screenReaderLabel}>
            {arrowIconDesktop}<span className="nav-item-label">Minimize menu</span>
          </span>
        </li>
        :
        <span onClick={toggleSidebar} className={`d-block d-lg-none sidebar-toggle icon ${chevronClassName}`} aria-label={screenReaderLabel}>
          {arrowIcon}
        </span>
    )
  }
}
