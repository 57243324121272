import React, { Component } from 'react';

class SmallTextArea extends Component {
    constructor(props) {
        super(props);
        this.textAreaRef = React.createRef();
    }

    componentDidMount() {
        this.setSmallTextAreaHeight();
    }

    componentDidUpdate() {
        this.setSmallTextAreaHeight();
    }

    setSmallTextAreaHeight() {
        if (this.textAreaRef.current) {
            const node = this.textAreaRef.current;
            node.style.height = 'auto';
            const newHeight = node.scrollHeight;
            node.style.height = newHeight > 0 ? `${newHeight}px` : '60px';
        }
    }

    render() {
        const { defaultValue, onChange } = this.props;
        return (
            <textarea
                ref={this.textAreaRef}
                className="col-12 small-text-area"
                rows={1}
                defaultValue={defaultValue}
                onChange={onChange}
            />
        );
    }
}

export default SmallTextArea;
