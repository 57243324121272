import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPayoutStatus, prettyPrintDate } from '../../../vibe/helpers/util';
import { printShort, toReadablePrice } from '../../elements/form/Currency';

class PayoutItem extends Component {

  render() {

    var editUrl = '/view'
    if (this.props.edit) {
      editUrl = '/edit'
    } 

    return (
      <Link className="member-card-link" from={'/payouts'} to={'/payouts/organization/' + this.props.organizationId + '/payout/' + this.props.payout.id + editUrl}>
        <Card className={"cursor-pointer card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"}>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Created at</p>
            <p className="list-item-text mb-0 bold-600">{prettyPrintDate(this.props.payout.created_at)}</p>
          </div>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Notes</p>
            <p className="list-item-text mb-0 bold-600">{this.props.payout.notes}</p>
          </div>  
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Transaction count</p>
            <p className="list-item-text mb-0 bold-600">{this.props.payout.transactions && this.props.payout.transactions.length}</p>
          </div>
          {this.props.showAmount && <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Amount</p>
            <p className="list-item-text mb-0 bold-600">{printShort(toReadablePrice(this.props.payout.amount), this.props.payout.currency)}</p>
          </div>}
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Status</p>
            <p className={"list-item-text mb-0 bold-600 " + (this.props.payout.status === 1 ? 'text-success' : 'text-muted')}>{getPayoutStatus(this.props.payout.status)}</p>
          </div>       
        </Card>
      </Link>
    );
  }

}

export default PayoutItem;
