export const SYMBOL = 'symbol'
export const SHORT_FORMAT = 'short_format'
export const EXPLICIT_FORMAT = 'explicit_format'
export const CODE = 'code'

export const SEK = 'SEK'
export const POUND = 'GBP'
export const EURO = 'EUR'

/**
 * 
 * Short format: £12.50
 * Explicit format: £12.50 GBP
 * 
 * reference: https://polaris.shopify.com/foundations/formatting-localized-currency
 */
export const CURRENCIES = {
    [SEK]: {[CODE]: 'SEK', [SYMBOL]: 'kr', [SHORT_FORMAT]: (value) => `${value} ${CURRENCIES[SEK][SYMBOL]}`, [EXPLICIT_FORMAT]: (value) => `${value} ${CURRENCIES[SEK][SYMBOL]} ${CURRENCIES[SEK][CODE]}`},
    // [POUND]: {[CODE]: 'GBP', [SYMBOL]: '£', [SHORT_FORMAT]: (value) => `${CURRENCIES[POUND][SYMBOL]}${value}`, [EXPLICIT_FORMAT]: (value) => `${CURRENCIES[POUND][SYMBOL]}${value} ${CURRENCIES[POUND][CODE]}`},
    // [EURO]: {[CODE]: 'EUR', [SYMBOL]: '€', [SHORT_FORMAT]: (value) => `${CURRENCIES[EURO][SYMBOL]}${value}`, [EXPLICIT_FORMAT]: (value) => `${CURRENCIES[EURO][SYMBOL]}${value} ${CURRENCIES[EURO][CODE]}`},
}

export function printExplicit(price, currency) {
    return CURRENCIES?.[currency]?.[EXPLICIT_FORMAT]?.(price)
}

export function printShort(price, currency) {
    return CURRENCIES?.[currency]?.[SHORT_FORMAT]?.(price)
}

export function toReadablePrice(value) {
    return parseFloat(value / 100).toFixed(2)
}

export function getSelectOptions() {
    var options = []
    for (const [key, value] of Object.entries(CURRENCIES)) {
        let option = {
            value: value[CODE],
            label: value[CODE],
            name: key
        }
        options.push(option)
    }
    return options
}