import React, { Component }  from 'react';
import { Button, Input, InputGroup, InputGroupAddon } from "reactstrap";
import Select from 'react-select'
import { onAddObject, onEditObject } from '../../elements/form/FormAction';
import { v4 as uuidv4 } from 'uuid';

/**
 * usage: 
 * <Options options={myOptions} onAddOption={(option) => this.onAddOption(option)} data={<custom_data>} />
 * 
 * where data is an object with custom logic for each field, example: 
 * {
 *   "bouncer_button_text": {visible: true, editable: true, show_key_name: false, description: 'Button text', input: {type: 'select', options: BUTTON_TEXT_OPTIONS}}
 * }
 * 
 */
class Options extends Component {

    constructor(props) {
        super(props);
        this.state = {
            options: []
        }
    }

    componentDidMount() {
        var options = this.props.options
        if (!options) {
            options = []
        }
        this.onAddDefaultValues(options)
        this.setState({options: options, data: this.props.data ?? {}})
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.options !== prevProps.options) {
          this.setState({options: this.props.options})
        }
      }

    onChangeOption(option, value) {
        option.value = value
        onEditObject(option)
        this.props.onAddOption(option)
    }

    onChangeOptionKey(option, key) {
        option.key = key
        onEditObject(option)
        this.props.onAddOption(option)
    }

    onAddOption() {
        const option = {
            id: uuidv4(),
            key: 'new_key_' + this.state.options.length,
            value: 'value'
        }
        onAddObject(option)
        this.props.onAddOption(option)
    }

    renderOption(option) {
        if (!option?.key) {
            return null
        }

        const data = this.state.data
        const description = data?.[option.key]?.description ?? option.key
        const editable = data?.[option.key]?.editable ?? this.props?.edit ?? true
        const visible = data?.[option.key]?.visible ?? this.isRowVisible()
        
        if (!visible) {
            return null
        }

        return this.optionRow(option, description, data, editable)
    }

    optionRow(option, key, data, editable) {

        const type = data?.[option.key]?.input?.type ?? 'text'
        const showKeyName = data?.[option.key]?.show_key_name ?? true

        var input
        if (type === 'select') {
            const options = data?.[option.key]?.input?.options
            input = <div style={{width: '250px'}}><Select disabled={!editable} options={options} defaultValue={options.filter(o => o.label === option.value)} onChange={editable ? (e) => this.onChangeOption(option, e.label) : undefined} placeholder={key}  /></div>
        } else if (type === 'text') {
            input = <Input type="text" disabled={!editable} name={key} id={key} defaultValue={option?.value} onChange={editable ? (e) => this.onChangeOption(option, e.target.value) : undefined} />
        }

        const canEditKey = data?.[option.key]?.can_edit_key ?? this.canEditKey()

        return <InputGroup key={option?.id ?? option?._id}>
            {showKeyName && <InputGroupAddon addonType="prepend">
                <Input style={{width: '300px'}} type="text" name={'key_' + key} id={'key_' + key} defaultValue={key} disabled={!canEditKey} onChange={editable ? (e) => this.onChangeOptionKey(option, e.target.value) : undefined} />
            </InputGroupAddon>} 
            {input}
        </InputGroup>
    }

    render() {
        return <>
            {this.canAddKey() && <Button className='mb-4 mt-2' color="primary" onClick={() => this.onAddOption(null)}><i className="fa fa-plus-circle mr-1" />Add</Button>}

            {this.state.options.map((option) => {
                return this.renderOption(option)
            })}
        </>
    }

    canAddKey() {
        return this.props?.add !== false
    }

    canEditKey() {
        return this.props?.edit !== false
    }

    isRowVisible() {
        return this.props?.visible !== false
    }

    onAddDefaultValues(options) {
        
    }

}

export default Options