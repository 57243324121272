import React, { Component }  from 'react';
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Select from 'react-select'
import * as Icon from 'react-feather';
import { UNCHANGED } from '../../../elements/form/FormAction';

class EditEventInterest extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            isInputChanged: false,
            interest: null, // selected interest option!
            interests: [] // all interests to patch/post etc
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    getInterestOptions(interests) {
        let interestOptions = []
        if (interests?.length > 0) {
            interests.forEach((interest) => {
                interestOptions.push({value: interest.id, label: interest.name})
            })

            // if an interest is archived - 
            if (this.props?.interest) {
                const otherInterest = interestOptions.find((interestOption) => interestOption.value === this.props.interest?.id)
                if (!otherInterest) {
                    interestOptions.push({value: this.props.interest.id, label: this.props.interest.name})
                }
            }
        }
        return interestOptions
    }

    getInterest(interestId) {
        if (!interestId) {
            return null
        }

        var interests = [...this.props.interests]
        
        // if an interest is archived - 
        if (this.props?.interest) {
            const otherInterest = interests.find((interest) => interest.id === this.props.interest?.id)
            if (!otherInterest) {
                interests.push(this.props.interest)
            }
        }

        return interests.find((interest) => interest.id === interestId)
    }

    componentDidMount() {
        if (this.props.interest) {
            var interest = this.getInterest(this.props.interest.id)
            interest._action = UNCHANGED

            const interestOptions = this.getInterestOptions(this.props.interests)
            this.setState({interest: interest, interestOptions: interestOptions})
        } else {
            const interestOptions = this.getInterestOptions(this.props.interests)
            this.setState({interest: null, interestOptions: interestOptions})
        }
    }
    
    onAddInterest() {
        const interest = this.state.interest
        this.props.onAddInterest(interest, this.props.occurrence)

        this.setState({isOpen: false}, () => {
            this.toggleModal()
        })
    }

    toggleModal() {
        if (this.state.isOpen && this.state.isInputChanged) {
            let answer = window.confirm("Discard changes?");  
            if (!answer) {
                return
            }
        } 
        this.props.toggle()
    }

    render() {
        const title = this.props.interest ? 'Edit registration of interest' : 'Create new registration of interest'

        return <> 
            <Modal scrollable={false} size='lg' isOpen={this.state.isOpen} toggle={this.toggleModal} className="previewModal">
                    <ModalHeader toggle={this.toggleModal}><strong>{title}</strong></ModalHeader>

                    <ModalBody>
                        <Form>
                            {this.renderInterestSelect()}
                        </Form>
                    </ModalBody>

                    <ModalFooter className='d-flex justify-content-end'>
                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                        <Button size="lg" className="px-5 mt-3" color="primary" onClick={() => this.onAddInterest()}>{'Save'}</Button>
                    </ModalFooter>
                       
            </Modal>
        </>
    }

    onChangeInterest(value) {
        const interest = this.getInterest(value)
        this.setState({ interest: interest, isInputChanged: true })
    }

    renderInterestSelect() {
        const interestOptions = this.state?.interestOptions ?? []
        return <FormGroup>
                    <Label for="interest">Select interest</Label>
                    <div className="row">
                        <div className='col col-6'>
                            <Select options={interestOptions} onChange={(e) => this.onChangeInterest(e.value)} placeholder={"Select interest..."} value={interestOptions.filter((option) => option.value === this.state?.interest?.id)} />
                        </div>
                        <div>
                            {<Button color="link" className="pl-2" onClick={(e) => this.onChangeInterest(null)} disabled={!this.state?.interest}><Icon.X size="20" color="#606469" /></Button>}
                        </div>
                    </div>
                </FormGroup>
    }

}

export default EditEventInterest;