import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAccessToken = () => {
    return cookies.get('accessToken')
}

export const getRefreshToken = () => {
    return cookies.get('refreshToken')
}

export const removeTokens = () => {
    cookies.remove('refreshToken')
    cookies.remove('accessToken')
}