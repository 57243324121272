import React from 'react';
import { Loader } from '../../../vibe';
import { getPackages } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import ListHeader from '../../elements/form/ListHeader';
import Package from '../../elements/package/Package';

const HEADER_LINKS = [
    {title: 'New Package', link: '/membership-packages/new/'}
]

class Packages extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            packages: []
        }
    }

    componentDidMount() {
        getPackages((packages) => {
            this.setState({packages: packages, loading: false})
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage, loading: false})
        })
    }

    renderPackages() {

        if (!this.state.packages || this.state.packages.length <= 0) {
            return null
        }

        return this.state.packages.map((_package) => {
            return <Package key={_package.id} package={_package}  />
        })
    }

    render() {
        if (this.state.loading) {
            return <Loader type="dots" />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {   
            return <React.Fragment>
                        <ListHeader title={'Packages'} links={HEADER_LINKS} />
                        {this.renderPackages()} 
                   </React.Fragment>      
        }
    }

}
export default Packages
