import React from 'react';
import { Button, Card, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import ImporterClass from '../members-importer/importer'
import { getBatches, deleteBatch, deleteVerifiedOrganizationMembers } from '../../../vibe/helpers/apiHelper';
import PaginationComp from '../../../vibe/components/utilities/Pagination/Pagination';
import { Loader } from '../../../vibe';

const pageSize = 10

class ImporterPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            loading: true,
            currentPage: 1,
            previewModal: false,
        }
        this.getAllBatch = this.getAllBatch.bind(this)
        this.togglePreview = this.togglePreview.bind(this)

    }

    setOpen = () => {
        this.setState({
            isOpen: true
        })
    }
    onClose = () => {
        this.setState({
            isOpen: false
        })
    }

    componentDidMount() {
        this.getAllBatch()
    }

    togglePreview(e = null) {
        this.setState(prevState => ({
            previewModal: !prevState.previewModal
        }));
    }

    onPreviewClick(batch) {
        this.setState({ previewModal: true, batchPreview: batch, searchValue: '' })
    }

    getAllBatch() {
        var limit = pageSize
        var offset = 0

        getBatches(limit, offset, (result, totalCount) => {
            this.setState({ batches: result, error: null, loading: false, totalCount: totalCount })
        }, (error) => {
            console.log('ERR', error)
        })
    }

    deleteBatchById(e, batch){
        if(e && e.stopPropagation) e.stopPropagation();   
        let answer = window.confirm("Are you sure you want to delete this batch and all entires? This action cannot be undone.");  

        if(answer === true){
            deleteBatch(batch.id, 
                (result)=>{
                    this.getAllBatch()
                }, 
                (error)=>{
                    console.log(error)
                }
            )
        }
    }

    deleteVerifiedMembers(verifiedMember){
        let answer = window.confirm("Are you sure you want to delete this verified member? This action cannot be undone.");  
        if(answer === true){
        
            deleteVerifiedOrganizationMembers( verifiedMember.id,
                (result)=>{
                    var batchPreview = this.state.batchPreview
                    if (batchPreview?.verifiedOrganizationMembers) {
                        batchPreview.verifiedOrganizationMembers = batchPreview.verifiedOrganizationMembers.filter(item => item.id !== verifiedMember.id)
                        this.setState({batchPreview: batchPreview})
                    }
                    this.getAllBatch()
                },
                (error)=>{
                    console.log(error)
                }
            )
        }
    }

    onClickPagination(e, page) {
        this.setState({ currentPage: page, loading: true })
        var offset = (page - 1) * pageSize

        var limit = pageSize

        getBatches(limit, offset, (result, totalCount) => {
            this.setState({ totalCount: totalCount, batches: result, loading: false })
        }, (error) => {
            this.setState({ loading: false, totalCount: 0, currentPage: -1 })
            console.log(error)
        })
    }

    getLastPage() {
        return Math.ceil(this.state.totalCount / pageSize)
    }

    renderPagination() {
        return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />
    }

    renderBatches() {
        return this.state.batches && this.state.batches.length > 0 && this.state.batches.map((batch) => {

            return <Card key={batch.id} className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"
                onClick={(e) => this.onPreviewClick(batch)}>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">ID</p>
                    <p className="list-item-text mb-0">{batch.id}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">File Name</p>
                    <p className="list-item-text mb-0">{batch.file_name}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Date</p>
                    <p className="list-item-text mb-0">{new Date(batch.created_at).toISOString().split('T')[0]}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Success</p>
                    <p className="list-item-text mb-0">{batch.success}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Failed</p>
                    <p className="list-item-text mb-0">{batch.failures}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Skipped</p>
                    <p className="list-item-text mb-0">{batch.skip}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1">Total</p>
                    <p className="list-item-text mb-0">{batch.total_members}</p>
                </div>
                <div className="mb-0 text-small w-50 w-sm-100">
                    <p className="list-item-heading mb-1"></p>
                    <p className="list-item-text mb-0"><Button color="link" className="px-5" onClick={(e) => this.deleteBatchById(e, batch)}>Delete</Button></p>
                </div>
            </Card>
        })
    }

    renderVerifiedOrganizationMembers(batch) {
        return (
            batch.verifiedOrganizationMembers && batch.verifiedOrganizationMembers.length > 0 ? 
            batch.verifiedOrganizationMembers.filter((element) => element.member_identifier.match(new RegExp(this.state.searchValue, "i")))
                .map((element) => {
                    return <Card key={element.id} className="align-self-center d-flex flex-column flex-lg-row justify-content-start min-width-zero">
                        <div className="mb-0 text-small w-50 w-sm-100 py-2">
                            <p className="list-item-heading mb-1">ID</p>
                            <p className="list-item-text mb-0">{element.id}</p>
                        </div>
                        <div className="mb-0 text-small w-50 w-sm-100 py-2">
                            <p className="list-item-heading mb-1">Member Identifier</p>
                            <p className="list-item-text mb-0">{element.member_identifier}</p>
                        </div>
                        <div className="mb-0 text-small w-50 w-sm-100 text-right py-2">
                            <p className="list-item-heading mb-1"></p>
                            <p className="list-item-text mb-0"><Button color="link" className="px-5" onClick={(e) => this.deleteVerifiedMembers(element)}>Delete</Button></p>
                        </div>                       
                    </Card>
                })
            : 'There are no success data to view!'
        )
    }

    modalSearch(value) {
        this.setState({ searchValue: value })
    }

    render() {


        return (
            <>
                <div className="row mb-3 d-flex justify-content-end">
                    <div className="col col-md-3 text-right">
                        <Button color="primary" className="px-5" onClick={this.setOpen}>Import List</Button>
                    </div>
                </div>
                <ImporterClass isOpen={this.state.isOpen} onClose={this.onClose} getBatches={this.getAllBatch} />


                <Modal scrollable centered size="xl" isOpen={this.state.previewModal} toggle={this.togglePreview} className="previewModal">
                    <ModalHeader toggle={this.togglePreview}>
                        Imported Batch #{this.state.batchPreview && this.state.batchPreview.id}
                    </ModalHeader>
                    <div className="text-right modal-search col-4">
                        <input className="form-control mr-sm-1" type="search" placeholder="Search..." aria-label="Search" ref="searchInput" onChange={(e) => this.modalSearch(e.target.value)} />
                    </div>
                    <ModalBody>
                        {
                            this.state.batchPreview &&
                            this.renderVerifiedOrganizationMembers(this.state.batchPreview)
                        }

                    </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                </Modal>

                {this.state.loading ?
                    <Loader type="dots" />
                    :
                    this.renderBatches()
                }

                {this.state.totalCount > 10 && this.renderPagination()}
            </>
        )
    }

}
export default ImporterPage
