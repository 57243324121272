import React, { Component } from 'react';
import { printShort, toReadablePrice } from '../../../../elements/form/Currency';

class MemberFormResult extends Component {

  
  renderAllFormOptionResults(optionResultsByItemId) {
    // Render the form result 4 items per row in the expanded card
    const keys = Object.keys(optionResultsByItemId);

    const itemsPerRow = 4

    let result = [];
    for (let i = 0; i < keys.length; i += itemsPerRow) {
        let chunk = keys.slice(i, i + itemsPerRow);
        result.push(chunk);
    }
    return (
      <>
        {result.map((items, index) => {
          return (
            <div key={"form_result_" + index} className="d-flex flex-column flex-md-row min-width-zero mb-4">
              {items.map((item, itemIndex) => {
                const itemClassName = `mb-2 text-small w-25 w-sm-100`;
                return (
                  <div key={item} className={itemClassName}>
                    {this.renderFormOptionResults(optionResultsByItemId[item])}
                  </div>
                );
              })}
            </div>
          );
        })}
      </>
    );
  }

 renderFormOptionResults(formOptionResults) {
  if (!formOptionResults || formOptionResults.length <= 0) {
    return null;
  }


  return (
      <div key={"form_item_" + formOptionResults?.[0]?.id} className="mb-2 text-small w-75 w-sm-100">
        <p className="list-item-heading mb-1">{formOptionResults?.[0]?.form_item?.title}</p>
        {formOptionResults.map((formOptionResult, index) => (
          <React.Fragment key={index}>
            {this.renderOptionResult(formOptionResult)}
          </React.Fragment>
        ))}
      </div>
  );
  
  }

  renderOptionResult(formOptionResult) {
    if (formOptionResult.form_option.type === 'text') { 
      var prefix = ''
      return <p className="list-item-text mb-2">{prefix + formOptionResult.value_text}</p>
    } else {
      return (
      formOptionResult.form_option.price ? 
        <p className="list-item-text mt-2 mb-1">
          {formOptionResult.form_option.value} ({printShort(toReadablePrice(formOptionResult.form_option.price), formOptionResult.form_option.currency)})
        </p> 
        : 
        <p className="list-item-text mb-1">
          {formOptionResult.form_option.value}
        </p>
    );
    }
  }

  renderAttendeeFormResult(memberTicket){
    const formMemberResult = memberTicket?.form_member_results?.[0]

    if (!formMemberResult?.form_option_results || formMemberResult.form_option_results.length <= 0) {
      return null
    }

    let optionResultsByItemId = {
   
    }
    for (const formOptionResult of formMemberResult.form_option_results) {

      const formItemId = formOptionResult.form_item_id
      const key = "form_member_result_" + formItemId

      if (!optionResultsByItemId[key]) {
        optionResultsByItemId[key] = [formOptionResult]
      } else {
        optionResultsByItemId[key].push(formOptionResult)
      }
    }
    
    return this.renderAllFormOptionResults(optionResultsByItemId)
  }

  render() {
    return(
        <> 
            <hr/>
            <div>{this.renderAttendeeFormResult(this.props.memberTicket)}</div>
        </>
        )
    }
}

export default MemberFormResult;