import React, { Component }  from 'react';
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { Loader } from "../../../../../vibe";
import Price from '../../../../elements/form/Price';
import DateTimePicker from '../../../../elements/form/DateTimePicker';
import Select from 'react-select'
import * as Icon from 'react-feather';
import { v4 as uuidv4 } from 'uuid';
import { getFormMemberResult } from '../../../../../vibe/helpers/apiHelper';
import { isDelete, isDuplicateAndNew, NEW, onEditObject, removeItem, UNCHANGED } from '../../../../elements/form/FormAction';
import { getTicketNameError } from '../../../../../vibe/helpers/eventHelper';

class EditTicket extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: true,
            isInputChanged: false,
            loading: false,
            formHasMemberResults: false,
            selectedTicketForm: null,
            selectedTicketCampaign : null,
            prevSelectedTicketCampaign: null,
            ticketFormChanged: false,
            validation_error: {}
        }
        this.toggleModal = this.toggleModal.bind(this);
    }

    getLastNonDeletedTicketForm(ticket) {
        if (ticket?.ticket_forms && ticket.ticket_forms.length > 0) {
            const lastIndex = ticket.ticket_forms.length - 1
            for (var i = lastIndex; i >= 0; i--) {
                const ticketForm = ticket.ticket_forms[i]
                if (!isDelete(ticketForm)) {
                    return ticketForm
                }
            }
        }
        return null
    }

    componentDidMount() {
        if (this.props.ticket) {
            const ticket = {...this.props.ticket}

            this.setState({ticket: ticket})

            // select last item in ticket_forms that is not deleted
            const ticketForm = this.getLastNonDeletedTicketForm(ticket)
            if (ticketForm) {
                this.setSelectedTicketForm({...ticketForm})
                this.hasFormMemberResult(ticketForm, (result) => {
                    this.setState({formHasMemberResults: result})
                })
            }

            if(ticket.campaigns){
                this.setState({
                    selectedTicketCampaign: ticket.campaigns[0],
                    prevSelectedTicketCampaign: ticket.campaigns[0].id
                })
            }

        } else {
            const newTicket = {
                name: null,
                count: 1,
                max_count_per_member: 5,
                start_date: null,
                end_date: null,
                key: uuidv4(),
                price: 0,
                organization_event_occurrence_id: this.props.occurrence.id,
                occurrence_key: this.props.occurrence.key,
                currency: 'SEK',
                segment_id: this.getSegmentOptions()?.[0]?.value,
                type: 1,
                _action: NEW
            }
            this.setState({ticket: newTicket})
        }
    }

    async hasFormMemberResult(ticketForm, onComplete) {

        if (!ticketForm?.id) {
            onComplete(false)
            return
        }

        getFormMemberResult(ticketForm.id, (formMemberResult) => {
            if (formMemberResult?.length) {
                onComplete(true)
            } else {
                onComplete(false)
            }
        }, (error)=> {
            onComplete(false)
        })
    }

    getFormOptions() {
        return this.props?.formOptions ?? [] 
    }

    getCampaigns(){
       return this.props?.campaigns ?? []
    } 

    getSegmentOptions() {
        return this.props?.segmentOptions ?? []
    }

    isTicketValid() {
        return Object.keys(this.state.validation_error).length === 0
    }
    
    onAddTicket() {

        var validationErrors = this.state.validation_error

        const ticketNameError = getTicketNameError(this.state.ticket.name)
        if (ticketNameError) {
            validationErrors['name'] = ticketNameError
        } else {
            delete validationErrors['name']
        }

        if (!this.state.ticket.start_date) {
            const startDateError = 'Release start is required'
            validationErrors['start_date'] = startDateError
        } else {
            delete validationErrors['start_date']
        }

        if (!this.state.ticket.end_date) {
            const endDateError = 'Release end is required'
            validationErrors['end_date'] = endDateError
        } else {
            delete validationErrors['end_date']
        }

        this.setState({validation_error: validationErrors}, () => {
            if (this.isTicketValid()) {

                var ticket = this.state.ticket
                if (!ticket?.ticket_statistics) {
                    ticket.ticket_statistics = {}
                    ticket.ticket_statistics.count = ticket.count
                }

                if (!ticket?.ticket_forms) {
                    ticket.ticket_forms = []
                }

                const selectedTicketForm = this.state.selectedTicketForm

                if (this.state.ticketFormChanged) {
                    for (var ticketFormToRemove of ticket.ticket_forms) {
                        removeItem(ticket.ticket_forms, ticketFormToRemove, false)
                    }
    
                    if (selectedTicketForm) {
                        ticket.ticket_forms.push({...selectedTicketForm})
                    } 
                }

                // Attach campaign if selected, or nullify if removed
                if(this.state.prevSelectedTicketCampaign){
                    ticket.prev_campaign_id = this.state.prevSelectedTicketCampaign;
                }
                if (this.state.selectedTicketCampaign) {
                    ticket.campaign_id = this.state.selectedTicketCampaign.value;
                } else {
                    ticket.campaign_id = null;  // Handle case where campaign is removed
                }

                // if a ticket form is both delete and new, just make it unchanged
                for (var ticketForm of ticket.ticket_forms) {
                    const duplicateAndNew = isDuplicateAndNew(ticket.ticket_forms, 'form_id', ticketForm)
                    if (isDelete(ticketForm) && duplicateAndNew) {
                        ticketForm._action = UNCHANGED
                        removeItem(ticket.ticket_forms, duplicateAndNew, false)
                    }
                }
                
                this.setState({ticket: ticket}, () => {
                    this.props.onAddTicket(this.state.ticket, this.props.occurrence)
                })

                this.setState({isOpen: false}, () => {
                    this.toggleModal()
                })
            }
        })
    }

    toggleModal() {
        if (this.state.isOpen && this.state.isInputChanged) {
            let answer = window.confirm("Discard changes?");  
            if (!answer) {
                return
            }
        } 
        this.props.toggle()
    }

    render() {
        const title = this.props.ticket ? 'Edit ticket' : 'Create new ticket'
        const positiveButtonText = 'Save'
        
        // TODO size='lg' -> mw 800
        return <> 
            <Modal scrollable={true} size='lg' isOpen={this.state.isOpen} toggle={this.toggleModal} className="previewModal">
                    <ModalHeader toggle={this.toggleModal}><strong>{title}</strong></ModalHeader>

                    <ModalBody>
                        <Form>
                            {this.renderTicketForm(this.state.ticket, this.props.occurrence)}
                        </Form>
                    </ModalBody>

                    <ModalFooter className='d-flex justify-content-end'>
                        <Button size="lg" className="px-5 mt-3" onClick={() => this.toggleModal()}>Cancel</Button> 
                        <Button size="lg" className="px-5 mt-3" color="primary" onClick={() => this.onAddTicket()}>{positiveButtonText}</Button>
                    </ModalFooter>
                       
                    {this.state.loading && 
                    <Row>
                        <Col >
                            <Loader type='dots'/>
                        </Col>
                    </Row>} 
            </Modal>
        </>
    }

    onChangeTicketForm(selectedFormId) {
        if (selectedFormId > 0) {
            var form = this.props.formOptions.find((formOption) => formOption.value === selectedFormId)
            var newTicketForm = {
                form_id: selectedFormId,
                form: {...form},
                _action: NEW,
                key: uuidv4()
            }
            this.setSelectedTicketForm(newTicketForm)
        } else {
            this.setSelectedTicketForm(null)
        }
        this.setState({ticketFormChanged: true})
    }

    onChangeTicketCampaign(selectedCampaignId){
        if (selectedCampaignId > 0) {
            var campaign = this.getCampaigns().find((campaign) => campaign.value === selectedCampaignId)
            this.setState({
                selectedTicketCampaign: campaign
            })
        } else {
            this.setState({
                selectedTicketCampaign: null
            })
        }
    }

    setSelectedTicketForm(ticketForm) {
        this.setState({selectedTicketForm: ticketForm})
    }

    onChangeTickets(value, key) {
        var ticket = this.state.ticket
        ticket[key] = value
        onEditObject(ticket)
        this.setState({ ticket: ticket, isInputChanged: true })
    }

    getValidationError(key) {
        var validationErrors = this.state.validation_error
        return validationErrors[key]
    }

    renderTicketForm(ticket, occurrence) {
        if (!ticket) {
            return null
        }

        return <React.Fragment>

            <Row>
                <Col sm="9">

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="tickets_name">Name</Label>
                                <Input className={this.getValidationError('name') ? "is-invalid" : ""} type="text" name="tickets_name" id="tickets_name" defaultValue={ticket.name} onChange={(e) => this.onChangeTickets(e.target.value, 'name')} />
                                {this.getValidationError('name') && <p className='mini-font text-danger'>{this.getValidationError('name')}</p>}
                            </FormGroup>
                        </Col>

                        <Col md={6}>
                            <FormGroup>
                                <Price title={'Price per ticket'} price={ticket.price} currency={ticket.currency} onChange={this.onChangeTickets.bind(this)} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="tickets_count">Quantity</Label>
                                <Input type="number" min={1} name="tickets_count" id="tickets_count" defaultValue={ticket.count} onChange={(e) => this.onChangeTickets(parseInt(e.target.value), 'count')} />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="tickets_max_count_per_member">Max limit per member</Label>
                                <Input type="number" min={1} name="max_count_per_member" id="max_count_per_member" value={ticket.max_count_per_member ? ticket.max_count_per_member : ''} onChange={(e) => this.onChangeTickets(parseInt(e.target.value), 'max_count_per_member')} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <FormGroup>
                        <Label for="tickets_start_date">Release start</Label>
                        <DateTimePicker name={"start_date"} value={ticket.start_date} onChange={this.onChangeTickets.bind(this)} />
                        {this.getValidationError('start_date') &&
                            <div className="mini-font text-danger">{this.getValidationError('start_date')}</div>
                        }
                    </FormGroup>

                    <FormGroup>
                        <Label for="tickets_end_date">Release end</Label>
                        <DateTimePicker name={"end_date"} value={ticket.end_date} onChange={this.onChangeTickets.bind(this)} />
                        {this.getValidationError('end_date') &&
                            <div className="mini-font text-danger">{this.getValidationError('end_date')}</div>
                        }
                    </FormGroup>

                    <FormGroup>
                        <Label for="segment">Segment - Who can purchase this ticket?</Label>

                        <div className="row">
                            <div className='col col-6'>
                                <Select options={this.getSegmentOptions()} onChange={(e) => this.onChangeTickets(e.value, 'segment_id')} placeholder={"Select segment..."} value={this.getSegmentOptions().filter((option) => option.value === ticket.segment_id)} />
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="form">Form - Connect a form to this ticket?</Label>
                        <div className="row">
                            <div className="col col-6">
                                <Select options={this.getFormOptions()} onChange={(e) => this.onChangeTicketForm(e.value)} value={this.getSelectedFormOption()} placeholder="Select form..." isDisabled={this.state.formHasMemberResults} />
                            </div>
                            <div>
                                {(!this.state.formHasMemberResults && this.state.selectedTicketForm) && <Button color="link" className="pl-2" onClick={(e) => this.onChangeTicketForm(-1)} disabled={this.state.formHasMemberResults}><Icon.X size="20" color="#606469" /></Button>}
                            </div>
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label for="form">Campaign - Connect a campaign to this ticket?</Label>
                        <div className="row">
                            <div className="col col-6">
                                <Select options={this.getCampaigns()} onChange={(e) => this.onChangeTicketCampaign(e.value)} value={this.getSelectedCampaignOption()}placeholder="Select campaign..." />
                            </div>
                            <div>
                            {(this.state.selectedTicketCampaign) &&  <Button color="link" className="pl-2" onClick={(e) => this.onChangeTicketCampaign(-1)} ><Icon.X size="20" color="#606469" /></Button>}
                            </div>
                        </div>
                    </FormGroup>

                </Col>
            </Row>

        </React.Fragment>
    }

    getSelectedFormOption() {
        if (this.state.selectedTicketForm) {
            return this.getFormOptions().find((form) => form.value === this.state.selectedTicketForm.form_id)
        }
        return null
    }


    getSelectedCampaignOption() {
        if (this.state.selectedTicketCampaign) {
            return this.getCampaigns().find((campaign) => campaign.value === this.state.selectedTicketCampaign.id)
        }
        return null
    }

}

export default EditTicket;