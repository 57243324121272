import React from 'react';
import { Button } from 'reactstrap';
import {
  createOrganizationDetails,
  getOrganizationDetails,
  patchOrganizationDetails,
  getMediaById,
  downloadMedia,
  postMediaDragDrop,
} from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import { getCurrentUser } from '../../../vibe/helpers/apiHelper';

import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Card } from 'reactstrap';
import EditOrganizationDetailsForm from './EditOrganizationDetailsForm';
import EditOrganizationPaymentDetailsForm from './EditOrganizationPaymentDetailsForm';

class EditOrganizationDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeTab: '1',
    };
    this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    getCurrentUser(
      (user) => {
        this.setState({ user: user });
        this.fetchOrganizationDetails();
      },
      (error) => {
        console.log('Error get Current user!');
      }
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  fetchOrganizationDetails() {
    let organizationDetailId = this.state.user.organization_id; // this.props.match.params.id
    if (organizationDetailId) {
      getOrganizationDetails(
        organizationDetailId,
        (organizationDetail) => {
          if (organizationDetail?.error) {
            var errorMessage = parseErrorMessage(organizationDetail?.error);
            this.setState({ error: errorMessage, loading: false });
            return;
          }

          if (!organizationDetail) {
            this.setState({
              new: true,
              organizationDetail: { organization_id: this.state.user.organization_id },
              loading: false,
              error: null,
            });
          } else {
            let stuk_url = 'https://www.studentkortet.se/union/' + this.slugify(organizationDetail.name);
            this.setState({ organizationDetail: organizationDetail, stuk_url: stuk_url, loading: false }, () => {
              this.getImage();
            });
          }
        },
        (error) => {
          var errorMessage = parseErrorMessage(error);
          this.setState({ error: errorMessage, loading: false });
        }
      );
    } else {
      this.setState({
        new: true,
        organizationDetail: { organization_id: this.state.user.organization_id },
        loading: false,
        error: null,
      });
    }
  }

  slugify(str) {
    //replace all special characters | symbols with a space
    str = str.replace(/[`~!@#$%^&*()_\-+=[\]{};:'"\\|/,.<>?\s]/g, ' ').toLowerCase();

    // trim spaces at start and end of string
    str = str.replace(/^\s+|\s+$/gm, '');

    // replace space with dash/hyphen
    str = str.replace(/\s+/g, '-');

    return str;
  }

  render() {
    if (this.state.loading) {
      return null;
    } else if (this.state.error) {
      return <div className="mx-3">Error: {this.state.error}</div>;
    } else if (this.state.organizationDetail) {
      return this.renderForm(this.state.organizationDetail);
    } else {
      return null;
    }
  }

  renderAlerts() {
    return (
      <MessageUncontrolledAlert
        success={this.state.organizationDetailsSaved}
        error={this.state.organizationDetailsSaveError}
        successMessage={'Organization details saved!'}
        errorMessage={'Error saving organization details, error: ' + this.state.organizationDetailsSaveError}
      />
    );
  }

  onChange(value, key) {
    var organizationDetail = this.state.organizationDetail;
    organizationDetail[key] = value;
    this.setState({ organizationDetail: organizationDetail });
    this.setState({ organizationDetailEdited: true });
  }

  onClickSaveOrganizationDetails() {
    this.createOrUpdateOrganizationDetails(
      this.state.new,
      () => {
        this.setState({ organizationDetailsSaved: true, organizationDetailEdited: false });
        setTimeout(() => this.setState({ organizationDetailsSaved: false }), 5000);
      },
      (error) => {
        var errorMessage = parseErrorMessage(error);
        this.setState({ organizationDetailsSaveError: errorMessage });
      }
    );
  }

  createOrUpdateOrganizationDetails(create, onSuccess, onError) {
    if (create) {
      createOrganizationDetails(
        this.state.organizationDetail,
        (result) => {
          onSuccess();
          setTimeout(() => {
            this.props.history.replace('/organization-details/edit/' + result.id);
          }, 1000);
          this.setState({ new: false, organizationDetail: result });
        },
        (error) => {
          onError(error);
        }
      );
    } else {
      patchOrganizationDetails(
        this.state.organizationDetail,
        () => {
          onSuccess();
          this.fetchOrganizationDetails();
        },
        (error) => {
          onError(error);
        }
      );
    }
  }

  handleFileRemove = (imageKey) => {
    this.onChange(null, imageKey);
  };
  handleFileUpload = (uploadedFiles, imageKey) => {
    let orgId = '0';
    // Handle the uploaded files here
    postMediaDragDrop(
      uploadedFiles,
      orgId,
      (media) => {
        this.onChange(media[0].id, imageKey);
      },
      (error) => {
        var errorMessage = parseErrorMessage(error);
        this.setState({ filesUploadError: errorMessage });
        console.log('Post file error', error);
      }
    );
  };
  getImage() {
    let getLogoId = this.state.organizationDetail.logo_media_id;
    let getImageId = this.state.organizationDetail.image_media_id;
    let getImagePageId = this.state.organizationDetail.image_media_page_id;

    if (getLogoId) {
      getMediaById(
        getLogoId,
        (media) => {
          downloadMedia(
            media.file_path,
            (blob) => {
              var objectURL = URL.createObjectURL(blob);
              let image = [
                {
                  preview: objectURL,
                  name: media.file_name,
                  size: media.size,
                  path: media.file_path,
                },
              ];
              this.setState({ currentLogoImage: image });
            },
            (error) => {
              console.log('Error getting image option file');
            }
          );
        },
        (error) => {
          console.log('Error fetching media', error);
        }
      );
    }
    if (getImageId) {
      getMediaById(
        getImageId,
        (media) => {
          downloadMedia(
            media.file_path,
            (blob) => {
              var objectURL = URL.createObjectURL(blob);
              let image = [
                {
                  preview: objectURL,
                  name: media.file_name,
                  size: media.size,
                  path: media.file_path,
                },
              ];
              this.setState({ currentImageMedia: image });
            },
            (error) => {
              console.log('Error getting image option file');
            }
          );
        },
        (error) => {
          console.log('Error fetching media', error);
        }
      );
    }
    if (getImagePageId) {
      getMediaById(
        getImagePageId,
        (media) => {
          downloadMedia(
            media.file_path,
            (blob) => {
              var objectURL = URL.createObjectURL(blob);
              let image = [
                {
                  preview: objectURL,
                  name: media.file_name,
                  size: media.size,
                  path: media.file_path,
                },
              ];
              this.setState({ currentImagePageMedia: image });
            },
            (error) => {
              console.log('Error getting image option file');
            }
          );
        },
        (error) => {
          console.log('Error fetching media', error);
        }
      );
    }
  }

  renderForm(organizationDetail) {
    return (
      <React.Fragment>
        <header className="app-header-page justify-content-end">
          <div>
            <Button
              className="ml-3"
              color="primary"
              size="btn btn-success"
              type="submit"
              form="orgDetailsForm"
              onClick={() => this.onClickSaveOrganizationDetails()}
            >
              <i className="fa fa-check"></i>Save
            </Button>
          </div>
        </header>

        {this.renderAlerts()}
        <Card>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: this.state.activeTab === '1' })}
                  onClick={() => {
                    this.toggle('1');
                  }}
                >
                  Organization
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames({ active: this.state.activeTab === '2' })}
                  onClick={() => {
                    this.toggle('2');
                  }}
                >
                  Payment
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <EditOrganizationDetailsForm
                  organizationDetail={this.state.organizationDetail}
                  getImage={this.getImage.bind(this)}
                  onChange={this.onChange.bind(this)}
                  handleFileUpload={this.handleFileUpload.bind(this)}
                  handleFileRemove={this.handleFileRemove.bind(this)}
                  currentLogoImage={this.state.currentLogoImage}
                  currentImageMedia={this.state.currentImageMedia}
                  currentImagePageMedia={this.state.currentImagePageMedia}
                />
              </TabPane>
              <TabPane tabId="2">
                <EditOrganizationPaymentDetailsForm
                  organizationDetail={this.state.organizationDetail}
                  onChange={this.onChange.bind(this)}
                />
              </TabPane>
            </TabContent>
          </div>
        </Card>
      </React.Fragment>
    );
  }
}
export default EditOrganizationDetails;
