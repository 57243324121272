import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Modal, ModalBody, ModalFooter, Row, UncontrolledAlert } from 'reactstrap';
import Select from 'react-select'
import * as Icon from 'react-feather';
import { v4 as uuidv4 } from 'uuid';

import { createForm, createFormFormItems, createFormItemFormOptions, createFormItems, createFormOption, deleteForm, deleteFormItem, deleteFormOption, getForm, updateFormById, updateFormItem, updateFormOption } from '../../../vibe/helpers/apiHelper';
import FormItemMultipleChoice from './FormItemMultipleChoice';
import FormItemCheckbox from './FormItemCheckbox';
import FormOptionText from './FormOptionText';
import FormItemText from './FormItemText';
import FormItemActions from './FormItemActions';

const FORM_ITEM_TYPES = [
    { value: 'multiple_choice', label: <><Icon.Circle size="20" color="#606469" /> Multiple choice</>, },
    { value: 'checkbox', label: <><Icon.CheckSquare size="20" color="#606469" /> Checkbox</>, },
    { value: 'text', label: <><Icon.AlignLeft size="20" color="#606469" /> Short text</>, },
]

const FORM_STATUS_ERROR = 'error'
const FORM_STATUS_SUCCESS = 'success'
const FORM_STATUS_CHANGE = 'change'

const FORM_ITEM_TYPE_MULTIPLE_CHOICE = 'multiple_choice'
const FORM_ITEM_TYPE_CHECKBOX = 'checkbox'
const FORM_ITEM_TYPE_TEXT = 'text'

const ACTION_CREATE = 'create'
const ACTION_UPDATE = 'update'
const ACTION_DELETE = 'delete'


class EditForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
          formTitle: null,
          formType: FORM_ITEM_TYPE_MULTIPLE_CHOICE,
          error: null,
          form: null,
          editedForm: [],
          formItems: [],
          formStatus: null,
          formStatusMessage: null,
          modalOpen: false
        };
    }

    componentDidMount() {
      const id = this.props.match.params.id
      if(id) {
        this.getForm(id)
      } else {
        this.addFormItem()
        this.setState({form: {_action: ACTION_CREATE, name: ''}})
      }
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.state.formStatus !== prevState.formStatus) {
          if (this.state.formStatus === FORM_STATUS_SUCCESS) {
              setTimeout(() => { this.setState({ formStatus: null }) }, 3000);
          }
          if(this.state.formStatus === FORM_STATUS_ERROR) {
            setTimeout(() => { this.setState({ formStatus: null }) }, 6000);
          }
    }
    }

    getForm(id) {
      getForm(id, (form) => {
          if(form) {
            this.setState({formItems: form.form_items ?? [], form: form, formTitle: form.name})
          } 
      }, (error) => {
        console.log("getForm error", error)
      })
    }

   
    async createOrUpdateForm() {
      const form = this.state.form

      if(!form.name) {
        this.setState({formStatusMessage: 'Form name is required.'})
        return 
      }

      const data = {
        name: form.name
      }

      if(!form._action) {
        return {form: form, created: false} 
      }

      return await new Promise((resolve, reject) => {

        if (form._action === ACTION_CREATE) {
          createForm(data, (form) => {
            console.log("createForm success")
            resolve({form: form, created: true})
          }, (error) => {
            console.log("createForm error", error)
            resolve(false)
          })
        }
  
        if (form._action === ACTION_UPDATE) {
          updateFormById(form.id, data, () => {
            console.log("updateFormById succes", )
            resolve({form: form, created: false})
          }, (error) => {
            console.log("updateFormById error", error)
            resolve(false)
          })
        }
      })
      
    }
    
    async onSave() {
      const formItems = this.state.formItems

      const createOrUpdateFormResult = await this.createOrUpdateForm()

      if (!createOrUpdateFormResult) {
        this.setState({formStatus: FORM_STATUS_ERROR})
        return 
      }
      const form = createOrUpdateFormResult.form
      const created = createOrUpdateFormResult.created

      if(form.name === '' || !form.name) {
        return this.setState({formStatus: FORM_STATUS_ERROR})
      }

      const formId = form.id

      //  create form items
      const formItemsToCreate = formItems.filter(formItem => formItem._action === ACTION_CREATE)
      for (const formItemToCreate of formItemsToCreate) {

        await new Promise((resolve, reject) => {
          const data = {
            title: formItemToCreate.title,
            type: formItemToCreate.type,
            required: formItemToCreate.required || false
          }
          createFormItems(data, (result) => {
            console.log("createFormItems success")
  
            const formItemId = result.id
            formItemToCreate.id = formItemId
  
            createFormFormItems({form_id: formId, form_item_id: formItemId}, (formFormItem) => {
              console.log("createFormFormItems success")
              resolve(true)
  
            }, (error) => {
              console.log("createFormFormItems error", error)
              resolve(false)
            })
  
          }, (error) => {
            console.log("createFormItems error", error)
            resolve(false)
          })
        })

        
      }

      // update form items
      const formItemsToUpdate = formItems.filter(formItem => formItem._action === ACTION_UPDATE)
      for (const formItemToUpdate of formItemsToUpdate) {
        const data = {
          title: formItemToUpdate.title,
          type: formItemToUpdate.type,
          required: formItemToUpdate.required || false
        }
        await new Promise((resolve, reject) => {
          updateFormItem(formItemToUpdate.id, data, (result) => {
            console.log("updateFormItem success")
            resolve(true)
          }, (error) => {
            console.log("updateFormItem error", error)
            this.setState({formStatus: FORM_STATUS_ERROR})
            resolve(false)
          })
        })
      }

      const formItemsToDelete = formItems.filter(formItem => formItem._action === ACTION_DELETE)
      for (const formItem of formItemsToDelete) {
        await new Promise((resolve, reject) => {
          deleteFormItem(formItem.id, (deleteFormItem) => {
            resolve(true)
          }, (error) => {
            this.setState({formStatus: FORM_STATUS_ERROR})
            console.log("deleteFormItems error",error)
            resolve(false)
          })
        })
        }

      // create form_options
      for (const formItem of formItems) {
        if (!formItem.form_options || formItem.form_options.length <= 0) {
          continue
        }

        const formOptionsToCreate = formItem.form_options.filter(formOption => formOption._action === 'create')
        for (const formOption of formOptionsToCreate) {

          const data = {
            type: formOption.type,
            value: formOption.value,
            placeholder: formOption.placeholder
          }

          await new Promise ((resolve, reject) => {
            createFormOption(data, (result) => {
              console.log("createFormOption success")
              const formOptionId = result.id
              createFormItemFormOptions({form_item_id: formItem.id, form_option_id: formOptionId}, (formItemFormOption) => {
                console.log("createFormItemFormOptions success")
                resolve(true)
              }, (error) => {
                console.log("createFormItemFormOptions error", error)
                this.setState({formStatus: FORM_STATUS_ERROR})
                resolve(false)
              })
            }, (error) => {
              console.log("createFormOption error", error)
              this.setState({formStatus: FORM_STATUS_ERROR})
              resolve(false)
            })
            
          })
        }
        

        // update form_options
        const formOptionsToUpdate = formItem.form_options.filter(formOption => formOption._action === ACTION_UPDATE)
        
        for (const formOption of formOptionsToUpdate) {
          var data = {
            type: formOption.type,
          }

          if (formOption.placeholder) {
            data.placeholder = formOption.placeholder
          }

          if (formOption.value) {
            data.value = formOption.value
          }

          const updateData = {...data}

          await new Promise((resolve, reject) => {
            updateFormOption(formOption.id, updateData, (updateFormOption) => {
              console.log("updateFormOption success")
              resolve(true)
            }, (error) => {
              console.log("updateFormOption error", error)
              this.setState({formStatus: FORM_STATUS_ERROR})
              resolve(false)
            })
          })
        }

        
        // delete form_options
        const formOptionsToDelete = formItem.form_options.filter(formOption => formOption._action === ACTION_DELETE)
  
        for (const formOption of formOptionsToDelete) {
          await new Promise((resolve, reject) => {
            deleteFormOption(formOption.id, (deleteFormOption) => {
              console.log("deleteFormOption success")
              resolve(true)
            }, (error) => {
              console.log("deleteFormOption error",error)
              this.setState({formStatus: FORM_STATUS_ERROR})
              resolve(false)
            })
          })
          }
        
      }

      if(created) {
        this.props.history.push('/form/edit/' + formId)
        return
      }

      this.getForm(formId)
      this.setState({formStatus: FORM_STATUS_SUCCESS})
    }

    onToggleDeleteModal() {
      this.setState((prevState) => ({ modalOpen: !prevState.modalOpen }));
    }

    deleteFormClick() {
      const formId = this.state.form?.id
      if(!formId) {
        return this.props.history.push('/forms');
      }
     
      deleteForm(formId, () => {
        this.props.history.push('/forms');
      }, (error) => {
        console.log("Error delete form", error)
      })
    }

    renderAlerts() {
      if(!this.state.formStatus) {
        return null
      }

          if (this.state.formStatus === FORM_STATUS_SUCCESS) {
            return <UncontrolledAlert color="success">Form saved!</UncontrolledAlert>
          }  
          
          if (this.state.formStatus === FORM_STATUS_CHANGE) {
            return <UncontrolledAlert color="info">Changes has been made. Save the form to confirm your changes.</UncontrolledAlert>
          }
          
          if (this.state.formStatus === FORM_STATUS_ERROR) {
            return <UncontrolledAlert color="danger">{this.state.formStatusMessage ? 'Error saving form. ' + this.state.formStatusMessage : 'Error saving form.'}</UncontrolledAlert>
          }
    }
      
    removeFormItem = (formItem, index) => {
        this.setState((prevState) => {
          const updatedFormItems = [...this.state.formItems];
          var formItemToUpdate = updatedFormItems.find(item => item.id === formItem.id)
          
          if(formItemToUpdate._action === ACTION_CREATE) { // remove directly if formItem is created but not yet saved 
            updatedFormItems.splice(index, 1)
          } else {
            formItemToUpdate._action = ACTION_DELETE
          }
          
          return {formItems: updatedFormItems, formStatus: FORM_STATUS_CHANGE}
        });
    };

    addOtherField = (formItemIndex) => {
      let editedFormItem;
        this.setState((prevState) => {
          const updatedFormItems = [...prevState.formItems];
          const formItem = updatedFormItems[formItemIndex];

            const newFormOption = { value: '', type: FORM_ITEM_TYPE_TEXT, _action: ACTION_CREATE }
            editedFormItem = {
              action: ACTION_CREATE,
              data: newFormOption,
              type: 'form_option',
              form_item_id: formItem.id
            }
            if (formItem.form_options) {
              formItem.form_options.push(newFormOption);
            } else {
              formItem.form_options = [newFormOption];
            }

          return { formItems: updatedFormItems, editedForm: [...this.state.editedForm, editedFormItem], formStatus: FORM_STATUS_CHANGE};
        });
      };

    removeOption(formItem, formOption) {
        this.setState((prevState) => {
            const updatedFormItems = [...this.state.formItems];
            var formItemToUpdate = updatedFormItems.find(item => item.id === formItem.id)
            var formOptionToUpdate = formItemToUpdate.form_options?.find(item => item.id === formOption.id)

            var formOptionToUpdateIndex = formItemToUpdate.form_options.findIndex(item => item.id === formOption.id)

            if(formOptionToUpdate._action === ACTION_CREATE) { // remove option directly if option is created but not yet saved 
              formItemToUpdate.form_options.splice(formOptionToUpdateIndex, 1)
            } else {
              formOptionToUpdate._action = ACTION_DELETE
            }
            
            return {formItems: updatedFormItems, formStatus: FORM_STATUS_CHANGE}
            
        })
    }

    editFormOption = (formOption, formItem, key, value) => {
      const updatedFormItems = [...this.state.formItems];
      var formItemToUpdate = updatedFormItems.find(item => item.id === formItem.id)
      var formOptionToUpdate = formItemToUpdate.form_options.find(item => item.id === formOption.id)
      
      formOptionToUpdate[key] = value
      
      if (key !== '_action' && formOptionToUpdate._action !== ACTION_CREATE) {
        formOptionToUpdate._action = ACTION_UPDATE
      }

      this.setState({formItems: updatedFormItems, formStatus: FORM_STATUS_CHANGE})
    }

    editFormItem = (formItem, key, value) => {
      const updatedFormItems = [...this.state.formItems];
      var formItemToUpdate = updatedFormItems.find(item => item.id === formItem.id)

      const oldValue = formItemToUpdate[key]
      formItemToUpdate[key] = value

      if(key === 'type') {
        if(value === FORM_ITEM_TYPE_TEXT) { 
          if(!formItemToUpdate.form_options) { 
            formItemToUpdate.form_options = []
          }

          for(const formOption of formItemToUpdate.form_options) {
            this.removeOption(formItemToUpdate, formOption)
          }

          formItemToUpdate.form_options.unshift({ placeholder: 'Write placeholder text here...', type: FORM_ITEM_TYPE_TEXT, _action: ACTION_CREATE})
        } else {
          if(oldValue === FORM_ITEM_TYPE_TEXT && value !== oldValue) {
            if(!formItemToUpdate.form_options) {
              formItemToUpdate.form_options = []
            }

            for(const formOption of formItemToUpdate.form_options) {
                this.removeOption(formItemToUpdate, formOption)
            }
          } 

          if(oldValue === FORM_ITEM_TYPE_CHECKBOX && value === FORM_ITEM_TYPE_MULTIPLE_CHOICE) {
            if(!formItemToUpdate.form_options) {
              formItemToUpdate.form_options = []
            }

            for(var formOption of formItemToUpdate.form_options) {
              if(formOption.type === oldValue) {
                this.editFormOption(formOption, formItemToUpdate, 'type', value)
              } 
            }
          }

          if(oldValue === FORM_ITEM_TYPE_MULTIPLE_CHOICE && value === FORM_ITEM_TYPE_CHECKBOX) {
            if(!formItemToUpdate.form_options) {
              formItemToUpdate.form_options = []
            }

            for(const formOption of formItemToUpdate.form_options) {
              if(formOption.type === oldValue) {
                this.editFormOption(formOption, formItemToUpdate, 'type', value)
              } 
          }
          }
        }
      }

      if (formItemToUpdate._action !== ACTION_CREATE) {
        formItemToUpdate._action = ACTION_UPDATE
      }
      this.setState({formItems: updatedFormItems, formStatus: FORM_STATUS_CHANGE})
    }

    addFormItem = () => {
      const updatedFormItems = [...this.state.formItems];

      const id = uuidv4()

      const newFormItem = {
        _action: ACTION_CREATE,
        type: FORM_ITEM_TYPE_MULTIPLE_CHOICE,
        title: 'Title',
        form_options: [],
        id: id
      };

      updatedFormItems.push(newFormItem)
      this.setState({formItems: updatedFormItems})
    }

    addFormOption = (formItemIndex, formItem) => {

      const updatedFormItems = [...this.state.formItems];
      const updatedFormItem = updatedFormItems[formItemIndex];

      const id = uuidv4()

      if(!updatedFormItem.form_options) {
        updatedFormItem.form_options = []
      }

      var newFormOption = {
        _action: ACTION_CREATE,
        value: `Option ${updatedFormItem.form_options.length + 1}`,
        type: formItem.type,
        form_item_id: updatedFormItem.id,
        id: id
      }

      updatedFormItem.form_options.push(newFormOption)

      this.setState({formItems: updatedFormItems, formStatus: FORM_STATUS_CHANGE})
    }

    renderFormItems() {
        return (
            <>
                {this.state.formItems && this.state.formItems.map((formItem, formItemIndex) => (
                  formItem && formItem._action !== ACTION_DELETE &&
                  <Card key={formItemIndex}>
                  <Row>
                      <Col sm="12" className="mx-1">
                          <FormGroup className='mt-4 mb-4'>
                              <Row className='col-12'>
                                  <Col className='col-6'>
                                      <Input
                                          placeholder="Title"
                                          type="text"
                                          name="formItemTitle"
                                          id="formItemTitle"
                                          value={formItem?.title || formItem.value}
                                          onChange={(e) => this.editFormItem(formItem, 'title', e.target.value)}
                                      />
                                  </Col>
                                  <Col className='col-4' style={{maxWidth: '250px'}}>
                                  <Select 
                                          defaultValue={FORM_ITEM_TYPES.find(option => option.value === formItem.type)}
                                          options={FORM_ITEM_TYPES}
                                          onChange={(selectedOption) => this.editFormItem(formItem, 'type', selectedOption.value)} />

                                  </Col>
                              </Row>
                          </FormGroup>
                          {this.setFormItemContent(formItem)}
                      </Col>
                  </Row>
                  <FormItemActions formItem={formItem} formItemIndex={formItemIndex} addFormOption={this.addFormOption.bind(this)} editFormItem={this.editFormItem.bind(this)} removeFormItem={this.removeFormItem.bind(this)} addOtherField={this.addOtherField.bind(this)}/>
              </Card>
              ))}
            </>
        );
    }
    
   
    setFormItemContent(formItem) {
      if (formItem.type === FORM_ITEM_TYPE_TEXT) {
        return <FormItemText formItem={formItem} editFormOption={this.editFormOption.bind(this)} />
      }
      
      const formOptions = formItem.form_options
      return formOptions && formOptions.map((option, optionIndex) => {
            if (option._action === ACTION_DELETE) {
              return null;
            }

            return (
              <div className='mx-4 col-6' key={optionIndex}>
                <div>
                    {option.type === FORM_ITEM_TYPE_MULTIPLE_CHOICE && ( <> 
                      <FormItemMultipleChoice formItem={formItem} option={option} editFormOption={this.editFormOption.bind(this)} removeOption={this.removeOption.bind(this)}/>
                    </>)}

                    {option.type === FORM_ITEM_TYPE_CHECKBOX && ( <> 
                      <FormItemCheckbox formItem={formItem} option={option} editFormOption={this.editFormOption.bind(this)} removeOption={this.removeOption.bind(this)}/>
                    </>)}

                    {/* if a multiple_choice or checkbox formItem has an option with type text (from 'Add Other') */}
                    {option.type === FORM_ITEM_TYPE_TEXT && ( <>
                      <FormOptionText formItem={formItem} option={option} editFormOption={this.editFormOption.bind(this)} removeOption={this.removeOption.bind(this)} />
                    </>)} 
                </div>
              </div>
            ) 
      })
    }
  
    getFormValue(key) {
      const form = this.state.editedForm?.find((entry) => entry?.type === "form");
      if (form && form?.data?.[key]) {
        return form[key]
      }

      return this.state?.form?.[key]
    }

    onChangeForm(key, value, id) {
      var form = this.state.form
      form[key] = value

      if(form._action !== ACTION_CREATE) {
        form._action = ACTION_UPDATE
      }
      this.setState({form: form})
    }

    renderModal() {  
      const formId = this.state.form?.id
      
      let modalText = ''
      if(formId) {
        modalText = `Are you sure you want to delete the form "${this.state.form?.name}"? This action cannot be undone.`
      } else {
        modalText = 'Delete form? This action cannot be undone.'
      }
      
      return <> 
          <Modal size='xs' isOpen={this.state.modalOpen} toggle={() => this.onToggleDeleteModal()} className="previewModal">
                <ModalBody>
                    <Form> 
                      <strong><p>{modalText}</p></strong> 
                    </Form>
                </ModalBody>
                <ModalFooter className='d-flex justify-content-between'>
                    <Button size="lg" className="px-5 mt-4" color="danger" onClick={() => this.deleteFormClick()}>Delete</Button>
                    <Button size="lg" className="px-5 mt-4" onClick={() => this.onToggleDeleteModal()}>Cancel</Button>
                </ModalFooter> 
          </Modal>
      </>
  }

    render() {       
        return (
        <>
          <header className="app-header-page justify-content-end">
          <div>
            <Button outline={true} color="danger" size="btn" onClick={() => this.onToggleDeleteModal()}>
                <i className="fa fa-trash"></i>&nbsp;Delete
              </Button>
            <Button className="ml-3" color="primary" size="btn btn-success" onClick={() => {this.onSave()}}>
              <i className="fa fa-check"></i>&nbsp;Save
            </Button>
          </div>
          </header>
        {this.renderAlerts()}
        {this.renderModal()}
        <Card>
          <CardBody>
            <Input placeholder="Form Title" bsSize="lg" type="text" name={"formTitle"} id="formTitle" defaultValue={this.getFormValue('name')}
              onChange={(e) => { this.onChangeForm('name', e.target.value, this.state.form?.id)}} required />
          </CardBody>
        </Card>
        {this.renderFormItems()}
        <Button className='mb-4 mt-2' color="primary" onClick={this.addFormItem}><i className="fa fa-plus-circle mr-1" /> Add</Button>
        </>
        )
    }
}

export default EditForm;