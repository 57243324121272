import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

class Pager extends React.Component {

    constructor(props) {
        super(props);
        this.state = { pager: {} };
    }

    getPager(totalItems, currentPage, pageSize) {
        // default to first page
        currentPage = currentPage || 1;
  
        // default page size is 10
        pageSize = pageSize || 10;
  
        // calculate total pages
        var totalPages = Math.ceil(totalItems / pageSize);
  
        var startPage, endPage;
        if (totalPages <= 10) {
            // less than 10 total pages so show all
            startPage = 1;
            endPage = totalPages;
        } else {
            // more than 10 total pages so calculate start and end pages
            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 5;
                endPage = currentPage + 4;
            }
        }
  
        // calculate start and end item indexes
        var startIndex = (currentPage - 1) * pageSize;
        var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
  
        // create an array of pages to ng-repeat in the pager control
        var pages = [...Array((endPage + 1) - startPage).keys()].map(i => startPage + i);
  
        // return object with all pager properties required by the view
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            startPage: startPage,
            endPage: endPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
  
    render() {

        var pager = this.getPager(this.props.totalCount, this.props.currentPage)
        if (!pager.pages || pager.pages.length <= 1) {
            // don't display pager if there is only 1 page
            return null;
        }
  
        return (
            <Pagination>
                <PaginationItem className={this.props.currentPage === 1 ? 'disabled' : ''}>
                    <PaginationLink onClick={() => this.props.onChangePage(1)}>First</PaginationLink>
                </PaginationItem>
                <PaginationItem className={this.props.currentPage === 1 ? 'disabled' : ''}>
                    <PaginationLink onClick={() => this.props.onChangePage(this.props.currentPage - 1)}>Previous</PaginationLink>
                </PaginationItem>
  
                {pager.pages.map((page, index) =>
                    <PaginationItem key={index} className={this.props.currentPage === page ? 'active' : ''}>
                        <PaginationLink onClick={() => this.props.onChangePage(page)}>{page}</PaginationLink>
                    </PaginationItem>
                )}
                
                <PaginationItem className={this.props.currentPage === pager.totalPages ? 'disabled' : ''}>
                    <PaginationLink onClick={() => this.props.onChangePage(this.props.currentPage + 1)}>Next</PaginationLink>
                </PaginationItem>
                <PaginationItem className={this.props.currentPage === pager.totalPages ? 'disabled' : ''}>
                    <PaginationLink onClick={() => this.props.onChangePage(pager.totalPages)}>Last</PaginationLink>
                </PaginationItem>
            </Pagination>
        );
    }
  }

export default Pager;
