import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import { capitalizeFirstLetter } from '../../../vibe/helpers/util';
import FieldLanguageToggle from './FieldLanguageToggle';
import { KEY_PRIMARY, KEY_SECONDARY } from '../../../vibe/helpers/language';

/**
 * example props:
 * 
 * keys: {
 *   primary: 'title',
 *   secondary: 'title_en'
 * }
 * 
 * data: {
 *   primary: 'Titel',
 *   secondary: 'Title'
 * }
 * 
 */
class MultiLanguageWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLanguage: KEY_PRIMARY,
      content: {
        [KEY_PRIMARY]: props.data?.[KEY_PRIMARY] ?? '',
        [KEY_SECONDARY]: props.data?.[KEY_SECONDARY] ?? '',
      },
    };
  }

  toggleLanguage = (lang) => {
    this.setState({ activeLanguage: lang });
  };

  handleContentChange = (value) => {
    const { activeLanguage, content } = this.state;

    const updatedContent = {
      ...content,
      [activeLanguage]: value,
    };

    this.setState({ content: updatedContent });

    const key = activeLanguage === KEY_PRIMARY ? this.props.keys[KEY_PRIMARY] : this.props.keys[KEY_SECONDARY];

    this.props.onChange(updatedContent[activeLanguage], key);
  };

  render() {
    const { activeLanguage, content } = this.state;
    const { children, keys, isInvalid, label } = this.props;

    return (
      <>

        <Row>
            <Col xs="6">
                <Label for={keys[KEY_PRIMARY]}>{label ?? capitalizeFirstLetter(keys[KEY_PRIMARY])}</Label>
            </Col>

            <Col xs="6" style={{textAlign: 'right'}}>
                <FieldLanguageToggle activeLanguage={activeLanguage} primary={'Sv'} secondary={'En'} toggle={this.toggleLanguage} />
            </Col>
        </Row>

        {children({
          activeLanguage,
          content: content[activeLanguage],
          onChange: this.handleContentChange,
          isInvalid,
        })}
      </>
    );
  }
}

export default MultiLanguageWrapper;