import React, { Component } from 'react';
import { downloadBlob, getMemberInterestsByInterestAsCsv } from '../../../../vibe/helpers/apiHelper';
import { Card, TabContent, TabPane, Nav, NavItem, NavLink, Label, Button } from 'reactstrap';
import classnames from 'classnames';
import MemberInterest from '../interest/MemberInterest';
import FormStatistics from '../event/form/FormStatistics';

class MemberInterestsWithFormResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
      openedInterests: [],

      totalResultsCount: 0,
      showStatistics: false,
      formOptionFilter: [],

      activeTab: '3',
      opened: false,
      memberInterests: [],
      item: {},
      isMemberInterestsModalOpen: this.props.isOpen,
      downloadCsvMessage: ''
    }

    this.toggleInterestCard = this.toggleInterestCard.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    //const memberInterests = this.props.interest?.member_interests
    const memberInterests = this.props?.memberInterests ?? []
    this.setState({memberInterests: memberInterests})
  }

  componentDidUpdate(prevProps, prevState) {
    this.toggleAllCards(prevState)
  }

  toggleAllCards (prevState) {

    // Toggle isOpen state on all cards when filter is changed
    const { formOptionFilter } = this.state;
    if (prevState.formOptionFilter !== formOptionFilter) {
      if (formOptionFilter.length === 0) {
        this.setState({ openedInterests: [] });
      } else {
        let isOpenIds = this.state.openedInterests.slice();
        const matchingMemberInterests = this.getMatchingMemberInterests();
  
        matchingMemberInterests.forEach(memberInterest => {
          const memberInterestId = memberInterest.id;
          const matchesFilter = this.showMemberInterest(memberInterest);
          if (matchesFilter && !isOpenIds.includes(memberInterestId)) {
            isOpenIds.push(memberInterestId);
          } else if (!matchesFilter && isOpenIds.includes(memberInterestId)) {
            isOpenIds = isOpenIds.filter(id => id !== memberInterestId);
          }
        });
  
        this.setState({ openedInterests: isOpenIds });
      }
    }
  }
  
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  
  toggleModal() {
    this.setState(prevState => ({
        opened: !prevState.opened,
    }))
  }
  
  isOpen() {
    return this.state.opened
  }

  onChange(value, key) {
    var item = this.state.item
    item[key] = value
    this.setState({ item: item })
  }

  countMemberInterests() {
    if (this.state.memberInterests) {
      return this.state.memberInterests.length
    }
  }

  async downloadCsv() {

    const interest = this.state?.interest
    if (!interest) {
        return
    }

    const memberInterestsBlob = await new Promise((resolve, reject) => {
        getMemberInterestsByInterestAsCsv(interest?.id, (blob) => {
        resolve(blob)
      }, (error) => {
        resolve(false)
      })
    })

    const memberInterestsFileName = 'interests_' + interest?.id + '.csv'

    var files = []
    if (memberInterestsBlob?.size > 0) {
      files.push({name: memberInterestsFileName, blob: memberInterestsBlob})
    }

    if (files.length === 1) {
      const file = files?.[0]
      downloadBlob(file.blob, file.name)
      this.setDownloadCsvMessage(file.name + ' downloaded!')
    } else {
      this.setDownloadCsvMessage('No atteendees, guests or interests found. No file was downloaded')
    }

  }

  setDownloadCsvMessage(message) {
    this.setState({downloadCsvMessage: message})
    setTimeout(() => {
      this.setState({downloadCsvMessage: ''})
    }, 4000);
  }

  toggleInterestCard = (id) => {
    let opens = this.state.openedInterests

    if (opens.includes(id)) {
      opens = opens.filter( open => open !== id)
    } else {
      opens.push(id)
    }

    this.setState({openedInterests: opens})
  };

  interestIsOpen = (memberInterest) => {
    const { openedInterests } = this.state;
    const cardIsOpen = openedInterests.includes(memberInterest.id)
    return cardIsOpen
  }

  getMatchingMemberInterests() {
    if (!this.state.memberInterests || this.state.memberInterests.length <= 0) {
      return []
    }

    return this.state.memberInterests.filter((memberInterest) => {
      return this.showMemberInterest(memberInterest)
    })
  }

  showMemberInterest(memberInterest) {
    const { formOptionFilter } = this.state;
  
    if (formOptionFilter.length === 0) {
        return true
    }
  
    const matchesFilter = formOptionFilter.every(filter => {
      const titleToCheck = filter.form_item.title;
      const answerToCheck = filter.value;
  
      const matchingQuestion = memberInterest.form_member_results?.[0]?.form_option_results?.some(result =>
        result.form_item.title === titleToCheck
      );
  
      if (matchingQuestion) {
        if (filter.form_item.type === "text" && !answerToCheck) {
          return true;
        } else if (answerToCheck) {
          return memberInterest.form_member_results?.[0]?.form_option_results?.some(result =>
            result.form_item.title === titleToCheck && result.form_option.value === answerToCheck
          );
        }
      }
  
      return false;
    });

    return matchesFilter
  }

  updateTotalResultsCount = () => {
    let totalResultsCount = 0;
  
    this.state.memberInterests.forEach(memberInterest => {
      if (memberInterest.form_member_results) {
        totalResultsCount += 1;
      }
    });
    return totalResultsCount
};

toggleStatisticsDisplay = () => {
    this.setState(prevState => ({
      showStatistics: !prevState.showStatistics 
    }));
  };

  getFormAnswersCount(memberInterests){
    let formAnswersCount = 0

    if (memberInterests) {
      for (const memberInterest of memberInterests) {
        if (memberInterest.form_member_results && memberInterest.form_member_results?.[0].form_option_results?.length > 0) {
          formAnswersCount++
        }
      }
    }
    return formAnswersCount;
  }

  renderFormStatistics() {
    let matchingMemberInterests = this.getMatchingMemberInterests()
    let totalResultsCount = this.updateTotalResultsCount()

    return <>
    {this.getFormAnswersCount(matchingMemberInterests) > 0 ? (<>
      <Button className='mb-4 mt-1 btn btn-primary' color='primary' onClick={this.toggleStatisticsDisplay}>
        {this.state.showStatistics ? 'Show less' : 'Show form results'}
      </Button> </> ) : null }

        {this.state.showStatistics && ( 
            <FormStatistics
              totalResultsCount={totalResultsCount}
              matchingMemberTickets={matchingMemberInterests}
              filterFormOption={this.filterFormOption}
              getFormAnswersCount={this.getFormAnswersCount}
            />
          )}

          {this.getFormAnswersCount(matchingMemberInterests) > 0 ? (<>
            <div className='mt-3' id="attendees-result">{matchingMemberInterests.length} members {this.displayFormFilter()}</div>
          {this.state.formOptionFilter.length > 0 ? (<button className='mb-4 mt-2 btn btn-primary' onClick={this.clearFormFilter}>Clear Filter</button>) : null}
          </> ) : null }
    </>
  }

  filterFormOption = (formOption, selectedAnswer) => {
    let filters = this.state.formOptionFilter;
      
    const existingFilterIndex = filters.findIndex(filter =>
      filter.form_item.title === formOption.form_item.title && (filter.value === selectedAnswer || !selectedAnswer) 
    );
  
    if (existingFilterIndex !== -1) {
      filters.splice(existingFilterIndex, 1); 
    } else {
      filters = []
      filters.push({
        form_item: formOption.form_item,
        value: selectedAnswer
      });
    }
  
    //this.scrollToAttendees();
    this.setState({ formOptionFilter: filters });
  
    return filters;
  }

  clearFormFilter = () => {
    this.setState({ formOptionFilter: [], isOpen: []});
  }

  displayFormFilter() {
    const currentFilter = this.state.formOptionFilter;
    let currentFilterText = `- current filter: `

    if (currentFilter.length > 0) {
      const filterTitles = currentFilter.map((filter) => filter.form_item.title);
      const filterOptions = currentFilter.map((filter) => filter.value);
      
      if (currentFilter[0].form_item.type === "text") {
        return filterTitles.length > 0 ? currentFilterText += `"${filterTitles.join(', ')}"` : '';
      }
        return filterTitles.length > 0 ? currentFilterText +=  `"${filterTitles.join(', ')} = ${filterOptions}"` : '';
    }
  }

  renderTabs() {
    
    return <>
        <Card>
            <div>
                <Nav tabs style={{background:'none'}}>
                <NavItem>
                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => {this.toggle('3')}}>
                    Registrations of interest
                    </NavLink>
                </NavItem>
                </Nav>
                <div style={{float: 'right', textAlign: 'end'}}>
                  <Label className='mt-1' style={{cursor: 'pointer'}} onClick={() => this.downloadCsv()}>Download CSV</Label>
                  {this.state.downloadCsvMessage && <div className='mini-font'>{this.state.downloadCsvMessage}</div>}
                </div>

            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="3">
                    {this.state.activeTab === '3' && ( <>
                        <Label className='mt-1'>{this.countMemberInterests()} members</Label>
                        <div className='d-flex  mt-1' style={{flexDirection: 'column'}}>
                            <div>
                                {this.renderFormStatistics()}
                            </div>
                        </div>
                        {this.renderMemberInterests()}
                    </>)}
                </TabPane>
            </TabContent>
            </div>
        </Card>
    </>
    
  }

  renderMemberInterests() {
  
    let memberInterests = this.getMatchingMemberInterests()

    // Sort memberInterests alphabetically by first_name
    const sortedMemberInterests = memberInterests.sort((a, b) => {
      const firstNameA = a.member.first_name.toUpperCase();
      const firstNameB = b.member.first_name.toUpperCase();
      
      if (firstNameA < firstNameB) {
        return -1;
      }
      if (firstNameA > firstNameB) {
        return 1;
      }
      return 0;
    });
    
    return sortedMemberInterests.map((memberInterest) => (
        <MemberInterest key={memberInterest.id} memberInterest={memberInterest} toggleCard={this.toggleInterestCard} cardIsOpen={this.interestIsOpen(memberInterest)}  />
    ));
  }

  render() {
    return <>
      <Label className='mt-1'>{this.countMemberInterests()} members</Label>
        <div className='d-flex  mt-1' style={{flexDirection: 'column'}}>
            <div>
                {this.renderFormStatistics()}
            </div>
        </div>
        {this.renderMemberInterests()}
    </>
    }
  }
  
export default MemberInterestsWithFormResults