import { downloadMedia, getMediaById } from "./apiHelper";

export async function getMediaImage(mediaId, onSuccess, onError) {
    if (!mediaId) {
        onError()
        return
    }

    getMediaById(mediaId, (media) => {
        downloadMedia(media.file_path, (blob) => {
            var objectURL = URL.createObjectURL(blob);
            let image = [
                {
                    preview: objectURL,
                    name: media.file_name,
                    size: media.size,
                    path: media.file_path,
                }
            ]
            onSuccess(image)
        }, (error) => {
            onError('Error getting image option file')
        })
    }, (error) => {
        onError('Error fetching media')
    })
    
}