import React, { Component } from 'react';
import { Button, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import { getPayoutStatus, prettyPrintDate } from '../../../vibe/helpers/util';
import { printShort, toReadablePrice } from '../../elements/form/Currency';

// should only be used for Financial role
class PayoutItemDetailed extends Component {

    onClickMarkAsComplete(e) {
        e.preventDefault()
        this.props.onCompletePayout(this.props.payout)
    }

    onClickDownloadPdf(e) {
        e.preventDefault()
        this.props.onDownloadPdf(this.props.payout)
    }

    isPayoutComplete() {
        return this.props.payout.status === 1
    }

    render() {

        return (
        <Link className="member-card-link" from={'/payouts'} to={'/payouts/organization/' + this.props.payout.organization.id + '/payout/' + this.props.payout.id + '/edit'}>
            <Card className={"cursor-pointer card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"}>
            <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Organization</p>
                <p className="list-item-text mb-0 bold-600">{this.props.payout.organization.display_name}</p>
                <p className="list-item-text mb-0 bold-600">{"ID: " + this.props.payout.organization.id}</p>
            </div> 
            <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Created at</p>
                <p className="list-item-text mb-0 bold-600">{prettyPrintDate(this.props.payout.created_at)}</p>
            </div>
            <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Notes</p>
                <p className="list-item-text mb-0 bold-600">{this.props.payout.notes}</p>
            </div>   
            <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Transaction count</p>
                <p className="list-item-text mb-0 bold-600">{this.props.payout.transactions && this.props.payout.transactions.length}</p>
            </div>
            <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Amount</p>
                <p className="list-item-text mb-0 bold-600">{printShort(toReadablePrice(this.props.payout.amount), this.props.payout.currency)}</p>
            </div>
            <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Status</p>
                <p className={"list-item-text mb-0 bold-600 " + (this.isPayoutComplete() ? 'text-success' : 'text-muted')}>{getPayoutStatus(this.props.payout.status)}</p>
            </div> 

            <Button className='mx-0 my-0 mb-1 d-inline-block mr-2' disabled={this.isPayoutComplete()} onClick={(e) => this.onClickMarkAsComplete(e)} color="success">Mark as Complete</Button>
            <Button className='mx-0 my-0 mb-1 d-inline-block mr-2' onClick={(e) => this.onClickDownloadPdf(e)} color="primary">Download PDF</Button>      
      
            </Card>
        </Link>
        );
    }

}

export default PayoutItemDetailed;
