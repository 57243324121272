export const KEY_PRIMARY = "primary"
export const KEY_SECONDARY = "secondary"

/**
 * example:
 * keys: {
 *   primary: 'title', 
 *   secondary: 'title_en'
 * }
 * 
 * returns 
 * {
 *   primary: item.title,
 *   secondary: item.title_en
 * }
 * 
 */
export function toMultiLanguageData(item, keys) {
    return {
        [KEY_PRIMARY]: item?.[keys[KEY_PRIMARY]],
        [KEY_SECONDARY]: item?.[keys[KEY_SECONDARY]]
    }
}