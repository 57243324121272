import React, { Component } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { SignUpUser } from '../../../vibe/helpers/apiHelper';
import * as Icon from 'react-feather';
import { Link } from "react-router-dom";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_email: '',
      user_password: '',
      user_confim_password: '',
      isError: {
        user_email: '',
        user_password: '',
        user_confim_password: '',
        passwordMatch: '',
      },
      forSubmit: false,
      errorMessage: '',
      register: '',
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ forSubmit: true })

    var validate = this.validateOnSubmit()
    if (validate) {
      this.onLoginClick()
    }

  }

  validateOnSubmit() {
    let isError = { ...this.state.isError };

    if (this.state.user_email.length === 0) {
      isError.user_email = 'Email is required';
    }
    if (this.state.user_password.length === 0) {
      isError.user_password = 'Password is required';
    }
    if (this.state.user_confim_password.length === 0) {
      isError.user_confim_password = 'Password is required';
    }
    if (this.state.user_confim_password !== this.state.user_password) {
      isError.passwordMatch = 'Password does\'t match.';
    } else {
      isError.passwordMatch = ''
    }

    this.setState({
      isError,
    })

    return isError.user_email.length === 0 && isError.user_password.length === 0 && isError.user_confim_password.length === 0 && isError.passwordMatch.length === 0
  }

  onLoginClick() {
    SignUpUser(this.state.user_email, this.state.user_password, (token) => {
      this.setState({ register: 'Account successfully created!. We will contact you once your account is active.' })
    }, (error) => {
      this.setState({ errorMessage: error.message })
      //console.log(error);
    })
  }



  onChange = e => {
    e.preventDefault();
    const regExp = RegExp(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/
    )

    this.setState({ errorMessage: '' })

    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "user_email":
        isError.user_email = regExp.test(value)
          ? ""
          : "Email address is invalid";
        break;
      case "user_password":
        isError.user_password =
          value.length < 8 ? "Password is required or less than 8 char" : "";
        isError.passwordMatch =
          value !== this.state.user_confim_password ? "Password does't match." : "";
        break;
      case "user_confim_password":
        isError.user_confim_password =
          value.length < 8 ? "Password is required or less than 8 char" : "";
        isError.passwordMatch =
          value !== this.state.user_password ? "Password does't match." : "";
        break;
      default:
        break;
    }

    this.setState({
      isError,
      [name]: value
    })
  }



  render() {
    const { isError, user_email, user_password, forSubmit, user_confim_password } = this.state;

    return (
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg"><span className="r"></span><span className="r s"></span><span className="r s"></span><span className="r"></span></div>
          <Card>
            <CardBody>
              <Form onSubmit={(e) => this.submitForm(e)} className='mx-auto' noValidate>
                <div className="text-center">
                  <div className="mb-4"><div className="auth-icon"><Icon.UserPlus color="white" /></div></div>
                  <h5 className="mb-4">Sign up</h5>
                </div>
                <FormGroup>
                  <Label for="user_email">Email</Label>
                  <Input
                    type="text" name="user_email" id="user_email" autoComplete="off"
                    // className={isError.user_email.length > 0 && "is-invalid form-control"}
                    valid={isError.user_email.length === 0 && user_email.length > 0}
                    invalid={(isError.user_email.length > 0 || user_email.length === 0 || this.state.errorMessage.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_email.length > 0 && (
                    <span className="invalid-feedback">{isError.user_email}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="user_password">Password</Label>
                  <Input type="password" name="user_password" id="user_password" autoComplete="off"
                    valid={isError.user_password.length === 0 && user_password.length > 0}
                    invalid={(isError.user_password.length > 0 || user_password.length === 0 || this.state.errorMessage.length > 0 || isError.passwordMatch.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_password.length > 0 && (
                    <span className="invalid-feedback">{isError.user_password}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="user_confim_password">Confirm Password</Label>
                  <Input type="password" name="user_confim_password" id="user_confim_password" autoComplete="off"
                    valid={isError.user_confim_password.length === 0 && user_confim_password.length > 0}
                    invalid={(isError.user_confim_password.length > 0 || user_confim_password.length === 0 || this.state.errorMessage.length > 0 || isError.passwordMatch.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_confim_password.length > 0 && (
                    <span className="invalid-feedback">{isError.user_confim_password}</span>
                  )}
                </FormGroup>
                <Button className={this.state.errorMessage || isError.passwordMatch ? 'is-invalid btn-block mb-4' : 'is-valid btn-block mb-4'} color="primary" type="submit">Sign up</Button>
                {this.state.errorMessage && (
                  <span className="invalid-feedback mb-4">{this.state.errorMessage}</span>
                )}
                {isError.passwordMatch && (
                  <span className="invalid-feedback mb-4">{isError.passwordMatch}</span>
                )}
                {this.state.register && (
                  <span className="valid-feedback">{this.state.register}</span>
                )}

                <div className="privacy-link pt-2 pb-3 mini-xs-font">
                  By signing up, you agree to our <Link to="/terms">Terms of Services</Link> and <Link to="/privacy-policy">Privacy Policy</Link>
                </div>
                <p className="mb-0 text-muted">Already have an account? <Link to="/login">Login</Link></p>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default Signup;
