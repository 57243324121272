import React, { Component }  from 'react';
import { Button, Card } from "reactstrap";
import { prettyPrintDate } from '../../../../../vibe/helpers/util';
import { printShort, toReadablePrice } from '../../../../elements/form/Currency';
import { ticketCanBeDeleted } from '../../../../../vibe/helpers/eventHelper';
import { isDelete } from '../../../../elements/form/FormAction';

class TicketListItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            
        }
    }

    componentDidMount() {
    }

    render() {
        const tickets = this.props.ticket
        if (!tickets) {
            return null
        }

        const totalCount = tickets?.ticket_statistics?.count ?? 0
        const totalSold = tickets?.ticket_statistics?.sold ?? 0

        return <React.Fragment key={tickets.id}>

                <Card style={{ border: '1px solid rgba(0, 0, 0, 0.125)' }} className={"card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero " + (isDelete(tickets) ? 'bg-danger' : '')}>

                    <div className="mb-0 text-small w-25 w-sm-100 pr-2">
                        <p className="list-item-heading mb-1">Name</p>
                        <p className="list-item-text mb-0">{tickets.name}</p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Release start</p>
                        <p className="list-item-text mb-0">{prettyPrintDate(tickets.start_date)} </p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Release end</p>
                        <p className="list-item-text mb-0">{prettyPrintDate(tickets.end_date)} </p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Count</p>
                        <p className="list-item-text mb-0">Total: {totalCount}</p>
                        <p className="list-item-text mb-0">Sold: {totalSold}</p>
                        <p className="list-item-text mb-0">Available: {Math.max((totalCount - totalSold), 0)}</p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Price</p>
                        <p className="list-item-text mb-0">{printShort(toReadablePrice(tickets.price), tickets.currency)}</p>
                    </div>

                    <div className="mb-0 text-small w-25 w-sm-100">
                        <Button className="btn-block" color="primary" onClick={() => this.props.onClickEditTicket(tickets)}><i className="mr-1" />Edit</Button>
                        <Button title={totalSold > 0 ? 'The ticket can not be deleted because it contains a completed transactions' : null} disabled={!ticketCanBeDeleted(tickets)} className="btn-block" color="danger" onClick={() => this.props.onDeleteTicket(tickets)}><i className="mr-1" />{isDelete(tickets) ? 'Undelete' : 'Delete'}</Button>
                    </div>

                </Card>

            </React.Fragment>
    }

}

export default TicketListItem;