import React, { Component } from 'react';
import { FormGroup } from 'reactstrap';
import { Loader } from '../../../vibe';
import { getRootOrganizations, getOrganizations } from '../../../vibe/helpers/apiHelper';
import Organization from '../../elements/organization/Organization';
import { Link } from "react-router-dom";
import Select from 'react-select'
import { getOrganizationSelectOptions } from '../../../vibe/helpers/util';
import { ORGANIZATION_LEVELS_DEPTH_DEFAULT } from '../../../vibe/helpers/organizationHelper';

class Organizations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    this.setState({ loading: true })

    getRootOrganizations(ORGANIZATION_LEVELS_DEPTH_DEFAULT, (result) => {

      getOrganizations((organizations) => {
        var options = getOrganizationSelectOptions(organizations)
        this.setState({ loading: false, organizations: result, options: options })
      }, (error) => {
        if (error.message) {
          this.setState({ loading: false, error: error.message })
        } else {
          this.setState({ loading: false, error: error })
        }
      })

    }, (error) => {
      if (error.message) {
        this.setState({ loading: false, error: error.message })
      } else {
        this.setState({ loading: false, error: error })
      }
    })
  }

  renderOrganizations() {
    if (this.state && this.state.organizations) {
      return this.state.organizations.map((organization) => {
        return <Organization key={organization.id} organization={organization} root={true}></Organization>
      })
    }
  }

  onSelectOrganization(e) {
    this.props.history.push('/organization/edit/' + e.value)
  }

  render() {

    if (this.state) {
      if (this.state.loading) {
        return <Loader type="dots" />
      } else if (this.state.error) {
        return <div>{this.state.error.statusCode} {this.state.error.name} {this.state.error.message}</div>
      } else {
        return (
          <React.Fragment>

            <header className="app-header-page justify-content-end">
              <div className="col-md-3 mw-350">
                <FormGroup className="mb-0">
                  <Link className="btn btn-primary block" to="/organization/new">
                    New Organization
                  </Link>
                </FormGroup>
              </div>
            </header>

            <div className="row align-items-center mb-3">
              <div className="col mr-auto">
                <h1 className="h4 mb-0">Root organizations</h1>
              </div>

              <div className="col col-xl-4 col-lg-5 mw-350">
                <Select options={this.state.options} onChange={(e) => this.onSelectOrganization(e)} placeholder={"Search..."} />
              </div>
            </div>

            <div>
              {this.renderOrganizations()}
            </div>
          </React.Fragment>
        )
      }
    } else {
      return null
    }

  }
}

export default Organizations;
