import React from 'react';
import { Link } from 'react-router-dom';
import { getCurrentUser, getInterestsWithResults } from '../../../../vibe/helpers/apiHelper';
import { Loader } from '../../../../vibe';
import Interest from './Interest';
import PaginationComp from '../../../../vibe/components/utilities/Pagination/Pagination';
import { Button, FormGroup, Tooltip } from 'reactstrap';
import * as Icon from 'react-feather';

const pageSize = 10

class Interests extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({user: user, search: '', currentPage: 1}, () => {
                this.getInterests(0)
            })
        })
    }
    
    getInterests(offset) {
        getInterestsWithResults(offset, pageSize, ['updated_at DESC'], (interests, totalCount) => {
            this.setState({ interests: interests, totalCount: totalCount, loading: false, error: null })
        }, (error) => {
            this.setState({ totalCount: 0, currentPage: -1, error: error, loading: false })
        })
    }

    onClickPagination(e, page) {
        this.setState({ currentPage: page })
        var offset = (page - 1) * pageSize
        this.getInterests(offset, this.state.search)
    }

    renderInterests() {
        return this.state.interests.map((interest) => {
            return <Interest key={interest.id} interest={interest} />
        })
    }

    getLastPage() {
        return Math.ceil(this.state.totalCount / pageSize)
    }

    renderPagination() {
        return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />
    }

    formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }

    onCopyLink() {
        const organizationId = this.state.user?.organization_id

        const link = "https://ob.addreax.com/" + organizationId + "/interests"
        navigator.clipboard.writeText(link)
        .then(() => {
            this.setState({copied: true, copyText: 'Copied to clipboard!'})
            setTimeout(() => this.setState({copied: false, copyText: null}), 2500);
          })
          .catch(err => {
            console.error('Failed to copy text: ', err);
          });
    }

    render() {
        if (this.state.loading) {
            return <Loader type="dots" />
        } else if (this.state.error) {
            return <div className='mx-3'>Error: {this.state.error}</div>
        } else {
            return <React.Fragment>
                <header className="app-header-page justify-content-end">
                    <FormGroup className="mb-0 pt-1">
                        {<Button id="deep_link_button" className="mr-3" color="secondary" outline={true} onClick={() => this.onCopyLink()}>
                            <Icon.Link size="12" />&nbsp;Link</Button>}
                        {this.state.copied ? <Tooltip placement={'top'} isOpen={true} target={'deep_link_button'}>{this.state.copyText}</Tooltip> : null}
                    </FormGroup>

                    <div className="col col-md-3 mw-350">
                        <FormGroup className="mb-0 pt-1">
                            <Link className="btn btn-primary block" to={"/interests/new/"}>
                                New Interest
                            </Link>
                        </FormGroup>
                    </div>
                </header>

                <div className="row mb-3 align-items-center">
                    <div className="col">
                        <h1 className="h4 mb-0">Interests</h1>
                        <small className="text-muted">{this.formatNumber(this.state.totalCount)} interests</small>
                    </div>

                </div>

                {this.renderInterests()}
                {this.state.totalCount > pageSize && this.renderPagination()}

            </React.Fragment>
        }
    }

}
export default Interests
