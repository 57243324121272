import React, { Component } from 'react';
import { prettyPrintDate } from '../../../vibe/helpers/util';
import { toColorClass, toStatusString } from '../../../vibe/helpers/transactionStatus';
import { toReadablePrice } from '../../elements/form/Currency';


class TransactionDetails extends Component {


  render() {
    let {transaction} = this.props
    return (<>
        <div className="card-text">
            <p className="list-item-heading mb-0">Transaction ID</p>
            <p className=" list-item-text mb-3">{transaction.id}</p>
            <p className="list-item-heading mb-0">Payment provider</p>
            <p className="list-item-text mb-3">{transaction.provider}</p>
            <p className="list-item-heading mb-0">Payment provider ID</p>
            <p className="list-item-text mb-3">{transaction.transaction_id}</p>
            <p className="list-item-heading mb-0">Status</p>
            <p className={toColorClass(transaction.status) + " list-item-text mb-3"}>{toStatusString(transaction.status)}</p>
            <p className="list-item-heading mb-0">Purchase date</p>
            <p className="list-item-text mb-3">{prettyPrintDate(transaction.created_at)}</p>
            <p className="list-item-heading mb-0">Updated at</p>
            <p className="list-item-text mb-3">{prettyPrintDate(transaction.updated_at)}</p>
            <p className="list-item-heading mb-0">Amount</p>
            <p className="list-item-text mb-3">{toReadablePrice(transaction.amount) + ' ' + transaction.currency}</p>
            
            {(transaction.original_amount !== transaction.amount) && 
              <>
                <p className="list-item-heading mb-0">Original amount</p>
                <p className="list-item-text mb-3">{toReadablePrice(transaction.original_amount) + ' ' + transaction.currency}</p>
              </>
            }
            {transaction?.discountCodeClaims && 
              <>
                <p className="list-item-heading mb-0">Discount</p>
                <p className="list-item-text mb-3">
                    {transaction?.discountCodeClaims?.[0]?.value_type === 'fixed' ? 
                      toReadablePrice(transaction?.discountCodeClaims?.[0]?.value) + ' SEK'
                    :  toReadablePrice(transaction?.discountCodeClaims?.[0]?.value) + '%' }
                </p>

                <p className="list-item-heading mb-0">Discount code</p>
                <p className="list-item-text mb-3">
                    {transaction?.discountCodeClaims?.[0]?.code}
                </p>
              </>
            }
            
        </div>
        </>
    );
  }

}

export default TransactionDetails;
