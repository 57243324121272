import React from 'react';
import { Col } from 'reactstrap';
import { getCurrentUser, getPayouts } from '../../../vibe/helpers/apiHelper';
import ListHeader from '../../elements/form/ListHeader';
import PayoutItem from '../payout/PayoutItem';

class Billing extends React.Component { 

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            error: null,
            payouts: [],
        }
    }

    componentDidMount() {
        getCurrentUser((user) => {
            let filterObject = {
                where: {
                    and: [
                        {status: 1},
                        {organization_id: user.organization_id}
                    ]
                },
                include: [
                    {
                        relation: 'transactions'
                    }
                ],
                order: ['created_at DESC']
            }
            getPayouts(filterObject, (payouts) => {
                this.setState({payouts: payouts, organizationId: user.organization_id})
            }, (error) => {
                console.log("error", error)
            })
        })
    }

    renderPayouts(payouts) {

        if (!payouts || payouts.length <= 0 || !this.state.organizationId) {
            return null
        }

        return this.state.payouts.map((payout) => {
            return <PayoutItem organizationId={this.state.organizationId} edit={false} key={payout.id} payout={payout} clickable={false} showAmount={true} />
        })

    }

    render() {
        return <>
            <ListHeader title={'Payouts'} links={''} />

            <div className='row mb-3'>
                <Col>
                    {this.renderPayouts(this.state.payouts)}
                </Col>
            </div> 

        </>
    }

}

export default Billing