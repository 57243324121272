import React from 'react';
import { Button, Form, Input } from 'reactstrap';

export default class SearchInput extends React.Component {

    render() {
        return <div className="col-md-4">
            <Form onSubmit={this.props.onSubmitSearch}>
            <div className="form-row align-items-center justify-content-start">
                <div className="my-1">
                    
                {/** 
                 Fake hidden input field needed to stop Chrome autofilling the search input with email value (if saved email/password in browser) 
                on component mount
                **/}
                <input type="text" 
                    autoComplete="off" 
                    value="" 
                    style={{display: 'none', opacity: 0, position: 'absolute', left: '-100000px'}} 
                    readOnly={true}>
                </input>

                <Input
                    type="text"
                    placeholder={this.props.placeholder ?? 'Search...'}
                    name="search"
                    id="search"
                    className="mr-1"
                    onChange={this.props.onChangeSearch}
                    title={this.props.title ?? 'Search'}
                />
                </div>
                <Button className="ml-1" type="submit" onClick={this.props.onSubmitSearch} color="primary">
                    {this.props.buttonText ?? 'Go!'}
                </Button>
            </div>
            </Form>
        </div>
    }

}