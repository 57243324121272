import React from 'react';
import { getMemberships, getUserCountryByOrganization } from '../../../vibe/helpers/apiHelper';
import { getMembershipSelectOptions } from '../../../vibe/helpers/util';
import NewMemberInt from './NewMemberInt';
import NewMemberSe from './NewMemberSe';

export const STEP_LOADING = 0
export const STEP_INIT = 1
export const STEP_CONFIRM_EXISTING_MEMBER = 2
export const STEP_MESSAGE = 3
export const STEP_NEW_MEMBER = 4

class NewMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        country: null,
        memberships: [],
        loading: true,
        error: null
    }
  }

  onSuccess(memberId) {
    setTimeout(() => {
        this.props.history.replace('/member/edit/' + memberId)
    }, 2000)
  }

  componentDidMount() {

    getUserCountryByOrganization((country) => {

      getMemberships('', (memberships) => {
        var options = getMembershipSelectOptions(memberships)
        options.push({value: 0, label: 'No membership', name: 'No membership'})
        this.setState({memberships: options, loading: false, country: country})
      }, (error) => {
        this.setState({error: error, loading: false})
      })

    }, (error) => {
        this.setState({error: 'Error getting organizations', loading: false})
    })

  }

  render() {

    if (this.state.error) {
      return "Error: " + this.state.error
    }

    if (this.state.loading) {
      return "Loading..."
    }

    if (this.state.country === 'SE') {
        return <NewMemberSe memberships={this.state.memberships} onSuccess={(memberId) => this.onSuccess(memberId)} />
    } else {
        return <NewMemberInt memberships={this.state.memberships} onSuccess={(memberId) => this.onSuccess(memberId)} />
    }
  }

}

export default NewMember;