const statuses = {
    0: {name: 'INITIALIZED', color_class: 'text-muted', title: 'Initialized'},
    1: {name: 'PROCESSING', color_class: 'text-warning', title: 'Processing'},
    2: {name: 'PAYMENT FAILED', color_class: 'text-danger', title: 'Failed'},
    3: {name: 'PAYMENT SUCCEEDED', color_class: 'text-info', title: 'Succeeded'},
    4: {name: 'COMPLETED', color_class: 'text-success', title: 'Completed'},
    5: {name: 'REFUND PENDING', color_class: 'text-muted', title: 'Refund pending'},
    6: {name: 'REFUND FAILED', color_class: 'text-danger', title: 'Refund failed'},
    7: {name: 'REFUND COMPLETED', color_class: 'text-success', title: 'Refund completed'},
    8: {name: 'PAYMENT CANCELLED', color_class: 'text-muted', title: 'Payment cancelled'},
    9: {name: 'PAYMENT DECLINED', color_class: 'text-muted', title: 'Payment declined'},
    10: {name: 'PAYMENT PROCESSING SUCCESS', color_class: 'text-gold', title: 'Payment processing success'},
}

export function toStatusString(status) {
    return statuses[status].name
}

export function toColorClass(status) {
    return statuses[status].color_class
}

export function toTitle(status) {
    if (status < 0) return "All"
    return statuses[status].title
}