import React, { Component } from 'react';
import {Card} from 'reactstrap';
import interact from 'interactjs'
import { ATTRIBUTE_KEY_FILE_NAME, ATTRIBUTE_KEY_FONT, ATTRIBUTE_KEY_FONT_CSS, ATTRIBUTE_KEY_FONT_SIZE, ATTRIBUTE_KEY_FONT_STYLE, ATTRIBUTE_KEY_FONT_WEIGHT, ATTRIBUTE_KEY_IMAGE_HEIGHT, ATTRIBUTE_KEY_IMAGE_WIDTH, ATTRIBUTE_KEY_KEY, ATTRIBUTE_KEY_ORGANIZATION_LEVEL, ATTRIBUTE_KEY_PREFIX, ATTRIBUTE_KEY_TEXT, ATTRIBUTE_KEY_TEXT_COLOR, ATTRIBUTE_KEY_TYPE, ATTRIBUTE_KEY_TYPE_IMAGE, ATTRIBUTE_KEY_TYPE_ORGANIZATION, ATTRIBUTE_KEY_TYPE_TEXT, ATTRIBUTE_KEY_X, ATTRIBUTE_KEY_Y, EXAMPLE_TEXT, FONT_OPTIONS, MEMBERSHIP_CARD_HEIGHT_DEFAULT, MEMBERSHIP_CARD_WIDTH_DEFAULT } from './MembershipCardConstants';


class ReactableCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isDragging : false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.snapToGrid !== this.props.snapToGrid) {
            this.initializeInteract();
        }
    }

    componentDidMount(){
        this.initializeInteract();
    }

    initializeInteract() {
        var self = this
        this.dragMoveListener = this.dragMoveListener.bind(this)
        window.dragMoveListener = this.dragMoveListener

        const gridSize = 16;
        const grid = interact.snappers.grid({ x: gridSize, y: gridSize });
         
        const modifiers = [
            interact.modifiers.restrictRect({
                restriction: 'parent',
                endOnly: true
            })
        ];

        if (this.props.snapToGrid) {
            modifiers.push(
                interact.modifiers.snap({
                    targets: [grid],
                    range: gridSize,
                    relativePoints: [{ x: 0, y: 0 },{ x: 0, y: 0 },{ x: 0, y: 0 },{ x: 0, y: 0 }]
                })
            );
        }


        interact(`.draggable-${this.props.side}`)
            .draggable({
                // enable inertial throwing
                inertia: true,

                // keep the element within the area of it's parent
                modifiers: modifiers,

                // enable autoScroll
                autoScroll: true,

                listeners: {
                // call this function on every dragmove event
                move: this.dragMoveListener,

                // call this function on every dragend event
                end (event)  {
                    var target = event.target;
                    var x = (parseFloat(target.getAttribute('data-x')) || 0)
                    var y = (parseFloat(target.getAttribute('data-y')) || 0)

                    // let index = target.id.replace(`card-attr-${self.props.side}-`, '')
                    let blockId = target.getAttribute('data-blockId');
                    const elementIndex = target.getAttribute('data-element-index');

                    self.props.onChangeCardArrayAttributes(x.toFixed(1), ATTRIBUTE_KEY_X, parseInt(elementIndex), self.props.side, blockId)
                    self.props.onChangeCardArrayAttributes(y.toFixed(1), ATTRIBUTE_KEY_Y, parseInt(elementIndex), self.props.side, blockId)

                    // hide tooltip
                    var element = document.getElementById(`card-attr-tooltip-${self.props.side}`);
                    element.style.visibility = 'hidden'

                    setTimeout(() => {
                        self.setState({ isDragging: false }); // Reset dragging state                        
                    }, 5);

                }

                }
            })
        
        this.props.updateStartPositions(this.props.fields, this.props.side)
    }

    getImageSrc(field) {
        if (field?.[ATTRIBUTE_KEY_FILE_NAME]) {
            return this.props.images[field[ATTRIBUTE_KEY_FILE_NAME]]
        }
        return null
    }

    // toggleFields(e, hover) {
    //     if (!this.props.fields || this.props.fields.length <= 0) {
    //         return
    //     }

    //     for (var i = 0; i < this.props.fields.length; i++) {
    //         var id = `card-attr-${this.props.side}-${this.props.fields[i].blockIndex}-${this.props.fields[i].elementIndex}`;
             
    //         var element = document.getElementById(id);
    //         if (element) {
    //             if (hover) {
    //                 element.style.boxShadow = '0 0 0 1px #ccc'
    //                 element.style.border = '1px dashed #ccc'
    //                 element.style.margin = '-1px'
    //             } else {
    //                 element.style.boxShadow = null
    //                 element.style.border = null
    //                 element.style.margin = null
    //             }
    //         }
    //     }
    // }

    getExampleText(key) {
        let text = EXAMPLE_TEXT[key]
        if (text) {
            return text
        }
        return key
    }

    getTextStyle(field) {
        var style = { position: 'absolute'}
        if (field?.[ATTRIBUTE_KEY_FONT]) {
            let css = FONT_OPTIONS?.[field?.[ATTRIBUTE_KEY_FONT]]?.[ATTRIBUTE_KEY_FONT_CSS]
            if (css) {
                style.fontFamily = css
            }
        }
        if (field?.[ATTRIBUTE_KEY_FONT_WEIGHT]) {
            style.fontWeight = field[ATTRIBUTE_KEY_FONT_WEIGHT]
        }
        if (field?.[ATTRIBUTE_KEY_FONT_STYLE]) {
            style.fontStyle = field[ATTRIBUTE_KEY_FONT_STYLE]
        }
        if (field?.[ATTRIBUTE_KEY_FONT_SIZE]) {
            style.fontSize = '' + field[ATTRIBUTE_KEY_FONT_SIZE] + 'px'
        }
        if (field?.[ATTRIBUTE_KEY_TEXT_COLOR]) {
            style.color = field[ATTRIBUTE_KEY_TEXT_COLOR]
        }
        return style
    }

    getText(field) {
        if (!field || !field?.[ATTRIBUTE_KEY_TYPE]) {
            return 'ERROR FIELD!'
        }
        
        switch (field[ATTRIBUTE_KEY_TYPE]) {

            case ATTRIBUTE_KEY_TYPE_ORGANIZATION:
                return field?.[ATTRIBUTE_KEY_ORGANIZATION_LEVEL] 
                    ? 'Organization level ' + field?.[ATTRIBUTE_KEY_ORGANIZATION_LEVEL] 
                    : 'Organization'

            case ATTRIBUTE_KEY_TYPE_TEXT:
                return field?.[ATTRIBUTE_KEY_TEXT]

            default:
                var text = field?.[ATTRIBUTE_KEY_PREFIX] ?? ''
                text += this.getExampleText(field[ATTRIBUTE_KEY_TYPE])
                return text
        }
    }

    handleClick = (field) => {
 
        if (!this.state.isDragging) {
            this.props.onElementClick(field.blockId, field.elementIndex, this.props.side);
        }
    }

    renderFields(fields) {
      
        return fields.map((field) => {    
                if (!field?.blockId) {
                    return null;
                }
                 
                const commonProps = {
                    'data-blockid': field.blockId,
                    'data-element-index': field.elementIndex,
                    key: `field-${field[ATTRIBUTE_KEY_KEY]}`,
                    id: `card-attr-${this.props.side}-${field.blockIndex}-${field.elementIndex}`,
                    className: `draggable-${this.props.side}`,
                    'data-x': field[ATTRIBUTE_KEY_X] || 0,
                    'data-y': field[ATTRIBUTE_KEY_Y] || 0,
                    style: {
                        display: !field?.isVisible && 'none',
                        position: 'absolute',
                    },
                    onClick: () => this.handleClick(field)
                };

                if (field[ATTRIBUTE_KEY_TYPE] === ATTRIBUTE_KEY_TYPE_IMAGE) {
                    return (
                        <img
                            {...commonProps}
                            src={this.getImageSrc(field)}
                            alt=""
                            style={{
                                ...commonProps.style,
                                width: `${field[ATTRIBUTE_KEY_IMAGE_WIDTH]}px`,
                                height: `${field[ATTRIBUTE_KEY_IMAGE_HEIGHT]}px`
                            }}
                        />
                    );
                }

                return (
                    <div
                        {...commonProps}
                        style={{
                            ...commonProps.style,
                            ...this.getTextStyle(field)
                        }}
                    >
                        {this.getText(field)}
                    </div>
                );
             
        });
    }


    dragMoveListener (event) {
        this.setState({ isDragging: true });
 
        var target = event.target
        // keep the dragged position in the data-x/data-y attributes
        var x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx
        var y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy

        const elementIndex = target.getAttribute('data-element-index');
        const blockId = target.getAttribute('data-blockid');

        // Find the correct field based on blockId and elementIndex
        const field = this.props.fields.find(b => 
            b.blockId === blockId && b.elementIndex === parseInt(elementIndex)
        );

        if (field) {
            const transformation = this.props.transformElement(target, field, x, y, this.props.side);

            // Update tooltip
            const tooltip = document.getElementById(`card-attr-tooltip-${this.props.side}`);
            tooltip.textContent = `x: ${x.toFixed(1)}, y: ${y.toFixed(1)}`;
            tooltip.style.visibility = 'visible';
            
            const offsetX = ((target.offsetWidth - 150) / 2) - 2;
            const offsetY = -45;
            tooltip.style.transform = `translate(${transformation.x + offsetX}px, ${y + offsetY}px)`;
        }

      }

      render() {

        return <Card 
                // onMouseEnter={(e) => this.toggleFields(e, true)} 
                // onMouseLeave={(e) => this.toggleFields(e, false)} 
                style={{width: MEMBERSHIP_CARD_WIDTH_DEFAULT + 'px', height: MEMBERSHIP_CARD_HEIGHT_DEFAULT + 'px', margin: 'auto'}}
                >
            
            {this.props.gridVisible && 
            <div className="grid-overlay"></div>}

            {this.renderFields(this.props.fields)}

            {/* tooltip */}
            <span 
                id={`card-attr-tooltip-${this.props.side}`} 
                style={{width: '150px', position: 'absolute', backgroundColor: 'rgba(0,0,0,0.5)', color: '#fff', textAlign: 'center', borderRadius: '6px', padding: '5px 0', visibility: 'hidden'}}>                  
            </span>

            {/* background image */}
            <img 
                className={`${this.props.side}-card`} 
                alt={this.props.uploadTitle} 
                style={{width: MEMBERSHIP_CARD_WIDTH_DEFAULT + 'px', height: MEMBERSHIP_CARD_HEIGHT_DEFAULT + 'px'}} src={this.props.imageUrl}>
            </img>
        </Card> 
    }
}

export default ReactableCard;
