import React from 'react';
import SmallTextArea from './SmallTextArea';

/**
 * Uses value on the textarea instead of defaultValue
 */
class SmallTextAreaValued extends SmallTextArea {

    render() {
        const { value, onChange } = this.props;
        return (
            <textarea
                ref={this.textAreaRef}
                className="col-12 small-text-area"
                rows={1}
                value={value}
                onChange={onChange}
            />
        );
    }
}

export default SmallTextAreaValued;
