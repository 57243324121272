import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getThemes } from '../../../api/theme/theme'
import { Card, FormGroup } from 'reactstrap';

class Campaigns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
    };
  }

  componentDidMount() {
    this.loadThemes();
  }

  loadThemes = () => {
    getThemes(
      (themes) => {
        this.setState({ themes });
      },
      (error) => {
        console.error('Error fetching themes:', error);
      }
    );
  };

  capitalize(str) {
    if (str) {
      const lower = str.toLowerCase();
      return str.charAt(0).toUpperCase() + lower.slice(1);
    }
    return '';
  }

  render() {
    const { themes } = this.state;

    return (
      <React.Fragment>
  
        <header className="app-header-page justify-content-end">
            <div className="col col-md-3 mw-350">
                <FormGroup className="mb-0 pt-1">
                    <Link className="btn btn-primary block" to={"/theme/new"}>
                        New Theme
                    </Link>
                </FormGroup>
            </div>
        </header>

        <div className="row mb-3 align-items-center">
            <div className="col">
                <h1 className="h4 mb-0">Themes</h1>
                <small className="text-muted">{themes.length} Themes</small>
            </div>
        </div>
       
        {themes.map((theme) => (
          <Link className="member-card-link" to={`/theme/edit/${theme.id}`} key={theme.id}>
            <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
              <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Title</p>
                <p className="list-item-text mb-0">{theme.name}</p>
              </div>
              <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Organization ID</p>
                <p className="list-item-text mb-0">{theme.organization_id}</p>
              </div>
              <div className="mb-0 text-small w-50 w-sm-100">
                <p className="list-item-heading mb-1">Created Date</p>
                <p className="list-item-text mb-0">{new Date(theme.created_at).toISOString().split('T')[0]}</p>
              </div>
              <div className="mb-1 text-small w-25 w-sm-100">
                <p className="list-item-heading mb-1">Updated Date</p>
                <p className="list-item-text mb-0">{new Date(theme.updated_at).toISOString().split('T')[0]}</p>
              </div>
            </Card>
          </Link>
        ))}
         
      </React.Fragment>
    );
  }
}

export default Campaigns;
