import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import fileSvg from '../../../assets/images/file.svg'
import closeSvg from '../../../assets/images/close.svg'

const errorMessages = {
    "too-many-files": 'Too many images! You can only upload one image.',
    "file-invalid-type": 'Unsupported file format. Only .jpeg, .jpg and .png is accepted.',
    "file-too-large": 'Image size is too large! Maximum size is 2 MB.',
    "file-already-uploaded": 'File with the same name has already been uploaded.',
}
var printedErrorTypes = [];
let baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#ced4da',
    borderStyle: 'dashed',
    backgroundColor: '#FFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    width: '400px',
    height: '200px',
};

let focusedStyle = {
    borderColor: '#2196f3'
};

let acceptStyle = {
    borderColor: '#00e676'
};

let rejectStyle = {
    borderColor: '#ff1744'
};

let thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
};

let thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    boxSizing: 'border-box',
    position: 'relative',
};

let thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

let img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
};


let closeStyle = {
    right: '5px',
    position: 'absolute',
    backgroundColor: '#fff',
    top: '5px',
    borderRadius: '50%'
}

let headerStyle = {
    color: '#282828',
    fontWeight: '600',
    marginBottom: '0'
}
let subHeaderStyle = {
    color: '#5F6266',
    marginBottom: '0',
    fontSize: '10px'
}

let uploadIcon = {
    borderRadius: '50%',
    padding: '8px',
    background: '#E4E6EB',
    width: '35px',
    height: '35px',
    display: 'flex',
    marginBottom: '10px',
}


const FileUpload = ({ onFileUpload, onFileRemove, currentImage, clearFiles = false, fileLimit = 1, width = null, height = null, ratio = null}) => {
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);
    
    if (width !== undefined) {
        thumb = {
            ...thumb,
            height: `${height}px`,
            aspectRatio: `calc(${ratio})`,
            width: '100%',
        };
        baseStyle = {
            ...baseStyle,
            width: `${width}px`,
            height: `${height}px`,
            aspectRatio: `calc(${ratio})`,
        };

        thumbsContainer = {
            ...thumbsContainer,
            aspectRatio: `calc(${ratio})`
        }
        thumbInner = {
            ...thumbInner,
            width: '100%',
            aspectRatio: `calc(${ratio})`
        }
    }
    function lengthValidator(file) {
       
         const isFileAlreadyUploaded = files.some((existingFile) => existingFile.name === file.name);
         if (isFileAlreadyUploaded) {
         return {
                 code: "file-already-uploaded",
                 message: "Image with the same name has already been uploaded.",
             };
         }
         
        if (files.length >= fileLimit) {
            return {
                code: "too-many-files",
                message: `Too many images! You can only upload one image.`,

            };
        }

        return null
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setRejectedFiles(rejectedFiles);

        } else {
            if (!clearFiles) {
                // Append the new files to the existing files array
                setFiles((prevFiles) => [
                    ...prevFiles,
                    ...acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    ),
                ]);
            }
            
            printedErrorTypes = [];
            setRejectedFiles(rejectedFiles);
            onFileUpload(acceptedFiles);
        }
    }, [onFileUpload, clearFiles]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        maxFiles: fileLimit,
        multiple: true,
        validator: lengthValidator,
        maxSize: 2000000,  // 2 mb
        accept: {
            'image/*': ['.png', '.jpg', '.jpeg']
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const generateFileId = (file) => file.name + file.size;

    const removeFileById = (fileIdToRemove) => {
        const fileToRemove = files.find((file) => generateFileId(file) === fileIdToRemove);

        if (fileToRemove) {
            const updatedFiles = files.filter((file) => generateFileId(file) !== fileIdToRemove);
            updatedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
            setFiles(updatedFiles);

            if (onFileRemove) {
                onFileRemove(fileToRemove);
            }
        }
    };


    const thumbs = files.map((file, index) => (
        <div style={thumb} key={generateFileId(file)}>
            <div style={thumbInner}>
                <img
                    style={closeStyle}
                    className="close"
                    src={closeSvg}
                    width={25}
                    onClick={() => removeFileById(generateFileId(file))} // Attach the click handler
                    alt="" />
                <img
                    className="prevImage"
                    src={file.preview}
                    style={img}
                    onLoad={() => URL.revokeObjectURL(file.preview)}
                    alt="Preview"
                />
            </div>
        </div>
    ));


    useEffect(() => {
        // Update the files state when currentImage changes
        if (currentImage && currentImage.length > 0) {
            setFiles(currentImage);
        }
    }, [currentImage]);

    useEffect(() => {
        // Revoke data URIs to avoid memory leaks
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    printedErrorTypes = [];

    return (
        <>
            {
                files.length === 0 &&
                <div {...getRootProps({ style })} className="dropzone">
                    <input {...getInputProps()} />
                    <div style={uploadIcon}><img src={fileSvg} alt="" width={20} /></div>
                    <p style={headerStyle}>Upload image</p>
                    <p style={subHeaderStyle}>or drag and drop</p>
                </div>
            }

            {rejectedFiles && rejectedFiles.length > 0 && rejectedFiles.map(({ file, errors }) => (
                errors.map((e, index) => {
                    if (!printedErrorTypes.includes(e.code)) {
                        printedErrorTypes.push(e.code);
                        return (
                            <div className="mt-2 mb-1 text-center alert alert-danger text-dark" key={'error-' + index}>
                                {errorMessages[e.code] ?
                                    errorMessages[e.code] :
                                    'Something went wrong, please try again!'}
                            </div>
                        );
                    }
                    return null;
                })
            ))}

            {thumbs && thumbs.length > 0 && <aside style={thumbsContainer}>{thumbs}</aside>}
        </>
    );
};

export default FileUpload;
