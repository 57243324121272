import React from 'react';
import { Button, FormGroup, Input, Label } from 'reactstrap';
import { Loader } from '../../../vibe';
import { completePayoutById, deletePayoutById, getPayoutById, getPayoutTransactionDetails } from '../../../vibe/helpers/apiHelper';
import { getPayoutStatus, parseErrorMessage } from '../../../vibe/helpers/util';
import { printShort, toReadablePrice } from '../../elements/form/Currency';
import ListHeader from '../../elements/form/ListHeader';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import PayoutPurchaseDetails from './PayoutPurchaseDetails';
import { generateAndDownloadPDF } from '../../../vibe/helpers/payoutHelper';

class EditPayoutDetails extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pendingTransactions: [],
            pendingTransactionsCount: 0,
            totalAmountPending: [],
            transactionDetails: null
        }
    }

    componentDidMount() {
        let id = parseInt(this.props.match.params.id)
        let payoutId = parseInt(this.props.match.params.payoutId)
        this.setState({ organizationId: id, payoutId: payoutId })

        this.getPayout(payoutId)
    }

    getPayout(payoutId) {
        getPayoutById(payoutId, (payout) => {
            getPayoutTransactionDetails(payoutId, (transactionDetails) => {
                this.setState({ payout: payout, loading: false, transactionDetails: transactionDetails, error: null })
            }, (error) => {
                let errorMessage = parseErrorMessage(error)
                this.setState({ error: errorMessage, loading: false })
                console.log("getPayoutTransactionDetails error", error)
            })
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })

        
    }

    onCompletePayout() {
        completePayoutById(this.state.payoutId, this.state.payout.notes, () => {
            this.setState({ payoutMarkedComplete: true, payoutMarkedCompleteMessage: 'Payout successfully marked as Complete' })
            setTimeout(() => this.setState({ payoutMarkedComplete: false }), 5000)

            this.getPayout(this.state.payoutId)

        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({ error: errorMessage, loading: false })
        })
    }

    onChange(value, key) {
        let payout = this.state.payout
        payout[key] = value
        this.setState({ payout: payout })
    }

    renderAction(payout) {
        if (!payout) {
            return null
        }

        if (payout.status === 0) {
            return <div className='mb-3'>
                <Label for="status">Status</Label>
                <div>{getPayoutStatus(payout.status)}</div>
                <div>Pay {printShort(toReadablePrice(payout.amount), payout.currency)} to {payout.organization.display_name} and then mark as Complete</div>
            </div>
        } else {
            return <div className='mb-3'>
                <Label for="status">Status</Label>
                <div>{getPayoutStatus(payout.status)}</div>
            </div>
        }

    }

    renderAccountDetails(payout) {

        if (!payout?.organization?.organization_details?.[0]) {
            return null
        }

        // TODO payout should contain the details instead
        let organizationDetails = payout?.organization?.organization_details?.[0]

        return <div className="row justify-content-start align-items-start">
            <div className="col-md-6 col-12">
                <div className="card h-100">
                    <div className="card-body">
                        <h4 className="card-title">Account details ({payout?.organization?.display_name})</h4>

                        <p className="list-item-heading mb-0 mb-0">Legal name</p>
                        <p className="mb-3 list-item-text">{organizationDetails.legal_name}</p>

                        <p className="list-item-heading mb-0 mb-0">Organization number</p>
                        <p className="mb-3 list-item-text">{organizationDetails.organization_number}</p>

                        <p className="list-item-heading mb-0 mb-0">Bank name</p>
                        <p className="mb-3 list-item-text">{organizationDetails.bank_name}</p>

                        <p className="list-item-heading mb-0 mb-0">Clearing number</p>
                        <p className="mb-3 list-item-text">{organizationDetails.clearing_number}</p>

                        <p className="list-item-heading mb-0 mb-0">Account number</p>
                        <p className="mb-0 list-item-text">{organizationDetails.account_number}</p>

                    </div>
                </div>

                <div className="row align-items-start">
                    <div className="col-md-12">
                        <FormGroup>
                            <Label for="notes">Notes</Label>
                            <Input type="text" name="notes" id="notes" value={this.state.payout.notes ? this.state.payout.notes : ''} onChange={(e) => this.onChange(e.target.value, 'notes')} />
                        </FormGroup>
                        <div className="card h-100">
                            <div className="card-body">
                                <h4 className="card-title">Financial Summary</h4>
                                <div className="mb-2"><p className="list-item-heading mb-0">Total Sales Amount</p>
                                    {printShort(toReadablePrice(this.state.payout.amount_without_fees), this.state.payout.currency)}
                                </div>
                                <div className="mb-2"><p className="list-item-heading mb-0">Fee Amount</p>
                                    {printShort(toReadablePrice(this.state.payout.fee_amount), this.state.payout.currency)}
                                </div>
                                <div className="mb-2 bold-600"><p className="list-item-heading mb-0">Payout Amount</p>
                                    {printShort(toReadablePrice(this.state.payout.amount), this.state.payout.currency)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <PayoutPurchaseDetails transactions={this.state.payout.transactions} transactionDetails={this.state.transactionDetails} />

        </div>
    }

    onClickDeletePayout(id) {

        let answer = window.confirm("Are you sure you want to delete this payout? This action cannot be undone");
        if (!answer) {
            return
        }

        deletePayoutById(id, (result) => {
            this.props.history.push('/payouts/all')
        }, (error) => {
            console.log("deletePayoutById error")
        })
    }

    render() {

        if (this.state.error) {
            return <>Error: {this.state.error}</>
        }

        if (this.state.loading) {
            return <Loader type="dots" />
        }

        return <>

            <header className="app-header-page justify-content-end">
                <div className="">
                    <FormGroup className="mb-0 pt-1">
                        <Button className="mx-2" color="success" onClick={() => this.onCompletePayout()} disabled={this.state.payout.status === 1}><i className="fa fa-check"></i>&nbsp;Mark as complete</Button>
                        <Button className='mx-2' color="danger" onClick={() => this.onClickDeletePayout(this.state.payout.id)} disabled={this.state.payout.status === 1}><i className="mr-1" />Delete</Button>
                        <Button className='mx-2' color="primary" onClick={() => generateAndDownloadPDF(this.state.payout, this.state.transactionDetails?.organization_details, this.state.transactionDetails)}>
                            Download PDF
                        </Button>
                    </FormGroup>
                </div>
            </header>

            <MessageUncontrolledAlert success={this.state.payoutMarkedComplete}
                successMessage={this.state.payoutMarkedCompleteMessage} />

            <ListHeader title={'Payout details'} links={''} />

            {this.renderAction(this.state.payout)}

            {this.renderAccountDetails(this.state.payout)}

        </>
    }

}

export default EditPayoutDetails
