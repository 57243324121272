import React, { Component } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { resetPasswordUserRequest } from '../../../vibe/helpers/apiHelper';
import * as Icon from 'react-feather';
import { Link } from "react-router-dom";

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_email: '',
      isError: {
        user_email: '',
      },
      forSubmit: false,
      errorMessage: '',
      resetMessage: '',
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ forSubmit: true })

    var validate = this.validateOnSubmit()
    if (validate) {
      this.onLoginClick()
    }

  }

  validateOnSubmit() {
    let isError = { ...this.state.isError };

    if (this.state.user_email.length === 0) {
      isError.user_email = 'Email is required';
    }

    this.setState({
      isError,
    })

    return isError.user_email.length === 0
  }

  onLoginClick() {
    resetPasswordUserRequest(this.state.user_email, () => {
      this.setState({ resetMessage: 'We have sent you a password recovery email.' })
    }, (error) => {
      this.setState({ errorMessage: error.message })
      //console.log(error);
    })
  }



  onChange = e => {
    e.preventDefault();
    const regExp = RegExp(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/
    )

    this.setState({ errorMessage: '' })

    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "user_email":
        isError.user_email = regExp.test(value)
          ? ""
          : "Email address is invalid";
        break;
      default:
        break;
    }

    this.setState({
      isError,
      [name]: value
    })
  }



  render() {
    const { isError, user_email, forSubmit } = this.state;
 
    return (
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg"><span className="r"></span><span className="r s"></span><span className="r s"></span><span className="r"></span></div>
          <Card>
            <CardBody>
              <Form onSubmit={(e) => this.submitForm(e)} className='mx-auto' noValidate>

                {this.state.resetMessage ?
                  <h5 className="text-center resetMessage">{this.state.resetMessage}</h5>
                  :
                  <>
                    <div className="text-center">
                      <div className="mb-4"><div className="auth-icon"><Icon.RefreshCw color="white" /></div></div>
                      <h5 className="mb-4">Forgot Password</h5>
                    </div>
                    <p className="text-center"><small>Enter your email address to retrieve your password</small></p>
                    <FormGroup>
                      <Label for="user_email">Email</Label>
                      <Input
                        type="text" name="user_email" id="user_email" autoComplete="off"
                        valid={isError.user_email.length === 0 && user_email.length > 0}
                        invalid={(isError.user_email.length > 0 || user_email.length === 0 || this.state.errorMessage !== null) && forSubmit}
                        onChange={this.onChange} />
                      {isError.user_email.length > 0 && (
                        <span className="invalid-feedback">{isError.user_email}</span>
                      )}
                    </FormGroup>

                    <Button className={this.state.errorMessage || isError.passwordMatch ? 'is-invalid btn-block mb-4' : 'is-valid btn-block mb-4'} color="primary" type="submit">Retrieve Password</Button>
                    {this.state.errorMessage && (
                      <span className="invalid-feedback mb-4">{this.state.errorMessage}</span>
                    )}
                  </>
                }

                <p className="mb-0 text-muted"><Link to="/login">Back to login page</Link></p>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  }
}

export default Signup;
