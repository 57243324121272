import React, { Component } from 'react';
import { Loader } from '../../../vibe';
import { getCurrentUser } from '../../../vibe/helpers/apiHelper';
import EditMembershipForm from '../../pages/membership/EditMembershipForm';

class EditMembership extends Component {

    componentDidMount() {

        // TODO DEV-0004 is this call needed?
        getCurrentUser((user) => {
            this.setState({ user: user, error: null })
        }, (error) => {
            this.setState({ error: error, user: null })
        })
    }

    render() {
        if (this.state && this.state.user) {
            return <EditMembershipForm history={this.props.history} membershipId={this.props.match.params.id} user={this.state.user} />
        } if (this.state && this.state.error) {
            return <div>{this.state.error}</div>
        } else {
            return <Loader type="dots" />
        }
    }

}

export default EditMembership;
