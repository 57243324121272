import React, { Component } from 'react';
import { Loader } from '../../../vibe';
import { getCurrentUser } from '../../../vibe/helpers/apiHelper';
import EditSegmentForm  from '../../pages/segment/SegmentNew.js'

class EditSegment extends Component {

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({ user: user, error: null })
        }, (error) => {
            this.setState({ error: error, user: null })
        })
    }

    render() {
        if (this.state && this.state.user) {
            return <EditSegmentForm history={this.props.history} segmentId={this.props.match.params.id} user={this.state.user} />
        } if (this.state && this.state.error) {
            return <div>{this.state.error}</div>
        } else {
            return <Loader type="dots" />
        }
    }

}

export default EditSegment;
