import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { printShort, toReadablePrice } from '../../elements/form/Currency';

class PayoutItemSlim extends Component {

  render() {

    return (
        <Card style={{padding: '8px 16px'}} className={"align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero"}>
          <div className="mb-0 text-small w-50 w-sm-100">
            <p className="list-item-heading mb-1">Organization</p>
            <p className="list-item-text mb-0 bold-600">{this.props.payout.organization.display_name} {"(ID: " + this.props.payout.organization.id + ")"}</p>
          </div> 
          <div className="mb-0 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Notes</p>
            <p className="list-item-text mb-0 bold-600">{this.props.payout.notes}</p>
          </div>  
          <div className="mb-0 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1">Amount</p>
            <p className="list-item-text mb-0 bold-600">{printShort(toReadablePrice(this.props.payout.amount), this.props.payout.currency)}</p>
          </div>
        </Card>
    )
  }

}

export default PayoutItemSlim;
