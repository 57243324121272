import React, { Component } from 'react';
import { CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

class UserFormChangePassword extends Component {

    componentDidMount(){
        
    }

    render() {

    return this.props && this.props.user ? <CardBody>
            <Form id="changePassword"> 
                <Row>
                    <Col sm="6">
                        <FormGroup>
                            <Label for="current_password">Current password</Label>
                            <Input type="password" name="current_password" id="current_password" defaultValue=""  onChange={(e) => this.props.onChangePassword(e, 'current_password')} />
                        </FormGroup>

                        <FormGroup>
                            <Label for="new_password">New password</Label>
                            <Input type="password" name="new_password" id="new_password" defaultValue="" onChange={(e) => this.props.onChangePassword(e, 'new_password')} 
                            valid={this.props.isError.new_password.length === 0 && this.props.new_password.length > 0 && !this.props.forSubmit} 
                            invalid={(this.props.isError.new_password.length > 0 ) } />
                            {this.props.isError.new_password.length > 0 && (
                                <span className="invalid-feedback">{this.props.isError.new_password}</span>
                            )}                              
                        </FormGroup>

                        <FormGroup>
                            <Label for="confirm_new_password">Confirm new password</Label>
                            <Input type="password" name="confirm_new_password" id="confirm_new_password" defaultValue="" onChange={(e) => this.props.onChangePassword(e, 'confirm_new_password')}  
                            valid={this.props.isError.confirm_new_password.length === 0 && this.props.confirm_new_password.length > 0 && this.props.new_password.length > 0  && !this.props.forSubmit } 
                            invalid={( ( this.props.isError.confirm_new_password.length > 0 || this.props.isError.passwordMatch.length > 0 ) && this.props.confirm_new_password.length > 0 ) } />
                            {this.props.isError.confirm_new_password.length > 0 && (
                                <span className="invalid-feedback">{this.props.isError.confirm_new_password}</span>
                            )}  

                            {this.props.confirm_new_password.length > 0 && this.props.new_password.length > 0 && this.props.isError.passwordMatch && (
                            <span className="invalid-feedback mb-4">{this.props.isError.passwordMatch}</span>
                            )}                                  
                                                
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <small>Make sure it's at least 15 characters OR at least 8 characters including a number and a lowercase letter.</small>
                    </Col>
                </Row>
            </Form>

        </CardBody> : null
    }

}

export default UserFormChangePassword;
