import React, { Component } from 'react';
import { Loader } from '../../../vibe';
import { getCurrentUser } from '../../../vibe/helpers/apiHelper';
import { TOP_SK_ORGANIZATION_ID } from '../../../vibe/helpers/organizationHelper';
import EditMemberForm from './EditMemberForm';

class EditMember extends Component {

    componentDidMount() {
        getCurrentUser((user) => {
            this.setState({ user: user, error: null })
        }, (error) => {
            this.setState({ error: error, user: null })
        })
    }

    render() {
        if (this.state && this.state.user) {
            return <EditMemberForm history={this.props.history} organizationId={this.state.user.organization_id} parentId={TOP_SK_ORGANIZATION_ID} memberId={this.props.match.params.id} user={this.state.user} />
        } if (this.state && this.state.error) {
            return <div>{this.state.error}</div>
        } else {
            return <Loader type="dots" />
        }
    }

}

export default EditMember;
