import React from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { capitalizeFirstLetter } from '../../../../vibe/helpers/util';

class Interest extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const interest = this?.props?.interest

        if (!interest) {
            return null
        }

        return <Link className="member-card-link" to={'/interests/edit/' + interest.id} key={interest.id}>
                <Card className="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero">
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Name</p>
                        <p className="list-item-text mb-0">{interest.name}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Status</p>
                        <p className="list-item-text mb-0">{capitalizeFirstLetter(interest.status)}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Members interested</p>
                        <p className="list-item-text mb-0">{interest?.member_interests?.length ?? 0}</p>
                    </div>
                    <div className="mb-0 text-small w-50 w-sm-100">
                        <p className="list-item-heading mb-1">Created Date</p>
                        <p className="list-item-text mb-0">{new Date(interest.created_at).toISOString().split('T')[0]}</p>
                    </div>

                    {/* DEV-0590 add later */}
                    {/* <div className="mb-1 text-small w-25 w-sm-100">
                        <p className="list-item-heading mb-1">Updated Date</p>
                        <p className="list-item-text mb-0">{new Date(interest.updated_at).toISOString().split('T')[0]}</p>
                    </div>  */}
                </Card>
            </Link>
    }

}
export default Interest
