import React, { Component } from 'react';
import { CardBody } from 'reactstrap';
import { prettyPrintDate } from '../../../vibe/helpers/util';
import ZoomableImage from '../../elements/media/ZoomableImage';


class MemberVerificationUploads extends Component {

  render() {
    return (<>
        <CardBody className='card-body d-flex flex-column flex-lg-row justify-content-between min-width-zero'>
          <div className="mb-0 text-small w-25 w-sm-100">
            <p className="list-item-heading mb-1 mw-90">Created at</p>
            <p className="list-item-text mb-3 mw-90">{prettyPrintDate(this.props.memberVerification.created_at)}</p>
          </div>
          <div className="mb-0 text-small w-75 w-sm-100 ">
            <p className="list-item-heading mb-1 mw-90">Text</p>
            <p className="list-item-text mb-3 mw-90">{this.props.memberVerification.text}</p>
          </div>
          {this.props.memberVerification ?
          <div className="text-small w-75 w-sm-100 mb-3">
          <p className="list-item-heading mb-0">Images {'(' + (this.props.memberVerification.media ? this.props.memberVerification.media.length : 0) + ')'}</p>
            <div className='d-flex'>
            {this.props.memberVerification.media && this.props.memberVerification.media.map((media, index) => {
                    return <span className='mr-1' key={'member_verification_media_' + index}><ZoomableImage key={'memberVerification_' + this.props.memberVerification.id + '_image_' + index} imageUrl={media.image_url} /></span>
            })}
            </div>
          </div> : null}
          </CardBody>
        <hr style={{marginTop: "0", marginBottom: "0"}}/>
        </>
    );
  }

}

export default MemberVerificationUploads;
