import React, { Component } from 'react';
import { Label, Button, Card, CardBody, Col, Row, UncontrolledAlert, Input, FormGroup, Tooltip } from 'reactstrap';
import { deleteDiscount, deleteCampaign, createCampaign, createDiscount, updateCampaign, updateDiscount, getCampaign, getDiscounts,
  createDiscountCodes, updateDiscountCode, getClaimedDiscountCodesByDiscount } from '../../../api/campaign/campaign'
import DiscountFormModal from './DiscountFormModal';
import { superPrettyPrintDateTime, prettyPrintDate, prettyPrintDateNoTime } from '../../../vibe/helpers/util';
import * as Icon from 'react-feather';
import { getDuplicateStaticCodes } from '../../../vibe/helpers/campaignHelper';

class CampaignForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignTitle: '',
      campaignBody: '',
      discounts: [],
      discountsToRemove: [],
      campaignSaved: false,
      saveError: false,
      user: null,
      isEditMode: false,
      campaignId: null,
      unsavedChanges: false,
      isModalOpen: false,
      currentDiscountIndex: null,
      codeDuplicates: null,
      tickets: [],
      packages: [],
    };
  }

  componentDidMount() {
    const { match } = this.props;
    const isEditMode = match.path.includes('edit');
    const campaignId = match.params.id;
    this.setState({ isEditMode, campaignId }, () => {
      if (isEditMode && campaignId) {
        this.loadCampaign(campaignId);
      }
    });
  }

  loadCampaign = (id) => {
    this.setState({codeDuplicates: null})
    getCampaign(
      id,
      (campaign) => {
        getDiscounts(id, (discounts) => {
          const codeDuplicates = getDuplicateStaticCodes(discounts)
          this.setState({codeDuplicates: codeDuplicates})

          const discountPromises = discounts.map((discount) => {
            return new Promise((resolve) => {
              if (discount.code_type === 'dynamic' && discount.discountCodes) {
                // Dynamic: Check each code if it's claimed
                const dynamicPromises = discount.discountCodes.map((code) => {
                  return new Promise((resolveDynamic) => {
                    getClaimedDiscountCodesByDiscount(
                      code.id,
                      (claimedDiscountCodes) => {
                        const totalClaimed = claimedDiscountCodes.length > 0;
                        code.claimedAt = totalClaimed ? claimedDiscountCodes[0].created_at : null;
                        resolveDynamic(totalClaimed ? 1 : 0); // Return 1 if claimed, 0 otherwise
                      },
                      (error) => {
                        console.error('Error fetching claimed discount codes:', error);
                        resolveDynamic(0); // If error, assume not claimed
                      }
                    );
                  });
                });
  
                Promise.all(dynamicPromises).then((claimedCounts) => {
                  const totalClaimed = claimedCounts.reduce((acc, count) => acc + count, 0); // Sum of all claimed codes
                  resolve({
                    ...discount,
                    static_code: '',
                    value: this.toReadableUnit(discount.value),
                    totalClaimed, // Total claimed for dynamic
                  });
                });
              } else {
                
                // Static: Only check the first code
                getClaimedDiscountCodesByDiscount(
                  discount.discountCodes[0].id,
                  (claimedDiscountCodes) => {
                    const totalClaimed = claimedDiscountCodes.length;
                    resolve({
                      ...discount,
                      static_code: discount.discountCodes?.[0]?.code || '',
                      value: this.toReadableUnit(discount.value),
                      totalClaimed, // Add total claimed count
                    });
                  },
                  (error) => {
                    console.error('Error fetching claimed discount codes:', error);
                    resolve({
                      ...discount,
                      static_code: discount.discountCodes?.[0]?.code || '',
                      value: this.toReadableUnit(discount.value),
                      totalClaimed: 0, // Default to 0 if there's an error
                    });
                  }
                );
              }
            });
          });
  
          Promise.all(discountPromises).then((updatedDiscounts) => {
            this.setState({
              campaignTitle: campaign.title,
              campaignBody: campaign.description,
              discounts: updatedDiscounts || [],
              tickets: campaign.tickets,
              packages:  campaign.packages
            }, () => {

            });
          });
        },
        (error) => {
          console.error('Error fetching discounts:', error);
          this.setState({ saveError: 'Failed to load campaign data.' });
        });
      },
      (error) => {
        console.error('Error fetching campaign:', error);
        this.setState({ saveError: 'Failed to load campaign data.' });
      }
    );
  };


  handleCampaignTitleChange = (e) => {
    this.setState({ campaignTitle: e.target.value, unsavedChanges: true });
  };

  handleCampaignBodyChange = (e) => {
    this.setState({ campaignBody: e.target.value, unsavedChanges: true });
  };

  toggleModal = (index = null) => {
    this.setState((prevState) => ({
      isModalOpen: !prevState.isModalOpen,
      currentDiscountIndex: index,
      currentDiscount: index !== null ? prevState.discounts[index] : null, // Set current discount if editing
    }));

  };

  handleSaveDiscount = (discount, callback) => {
    this.setState((prevState) => {
      const discounts = [...prevState.discounts];
      if (prevState.currentDiscountIndex !== null) {
        discounts[prevState.currentDiscountIndex] = discount;
      } else {
        discounts.unshift(discount);
      }

      return { discounts, isModalOpen: false, unsavedChanges: true };
    }, () => {
      if (callback) callback(discount);
    });
  };

  saveCampaign = () => {
    const { campaignTitle, campaignBody, discountsToRemove, isEditMode, campaignId } = this.state;

    if (!campaignTitle) {
      this.setState({ saveError: 'Campaign title is required' });
      return;
    }

    const campaignData = { title: campaignTitle, description: campaignBody };

    const saveCampaignCallback = (newCampaignId) => {
      this.saveDiscounts(newCampaignId);
      this.removeDiscounts(newCampaignId, discountsToRemove);
      this.setState({ campaignSaved: true, unsavedChanges: false, saveError: false });
    };

    if (isEditMode && campaignId) {
      updateCampaign(
        campaignId,
        campaignData,
        () => {
          saveCampaignCallback(campaignId);
        },
        (error) => {
          console.error('Error updating campaign:', error);
          this.setState({ saveError: error.message });
        }
      );
    } else {
      createCampaign(
        campaignData,
        (result) => {
          saveCampaignCallback(result.id);
          this.props.history.push(`/campaign/edit/${result.id}`);
        },
        (error) => {
          console.error('Error creating campaign:', error);
          this.setState({ saveError: error.message });
        }
      );
    }
  };


  toStoredUnit = (value) => {
    return value * 100
  }

  toReadableUnit = (value) => {
      return parseFloat(value / 100).toFixed(2)
  }

  saveDiscounts = (campaignId) => {
    const { discounts } = this.state;
    const savePromises = discounts.map(discount => {

      const discountData = {
        campaign_id: Number(campaignId),
        value_type: discount.value_type,
        value: this.toStoredUnit(discount.value),
        code_type: discount.code_type,
        valid_from: discount.valid_from ? new Date(discount.valid_from).toISOString() : null,
        valid_to: discount.valid_to ? new Date(discount.valid_to).toISOString() : null,
        limit: Number(discount.limit),
        title: discount.title,
        description: discount.description,  
      };

      if (discount.id) {
        return updateDiscount(campaignId, discount.id, discountData,
          () => {
            this.updateDiscountCodes(discount.id, discount)
          },
          (error) => console.error('Error updating discount:', error)
        );
      } else {
        return createDiscount(campaignId, discountData,
          (result) => {
            this.createDiscountCodes(result.id, discount)
          },
          (error) => console.error('Error creating discount:', error)
        );
      }
    });

    Promise.all(savePromises)
      .then(() => {
        this.setState({ campaignSaved: true, unsavedChanges: false, saveError: false });
      })
      .catch(error => {
        console.error('Error saving discounts:', error);
        this.setState({ saveError: 'Failed to save some discounts or codes' });
      });
  };

  updateDiscountCodes = (discountId, discount) => {
    if (discount.code_type === 'static') {
      return this.updateStaticCode(discountId, discount);
    } else if(discount.code_type === 'dynamic') {
      return this.createExtendDynamicCodes(discountId, discount);
    }
    return Promise.resolve();
  };

  updateStaticCode = (discountId, discount) => {
    const dataCode = {
      code: discount.static_code,
    };
    return updateDiscountCode(discountId, discount.discountCodes[0].id, dataCode,
      (result)=>{
        this.loadCampaign(this.state.campaignId); // Fetch Campaign to update UI
      }, 
      (error)=>{
        console.log('Error saving static codes!', error);
      }
    );
  }

  createDiscountCodes = (discountId, discount) => {
    if (discount.code_type === 'static') {
      return this.createStaticCode(discountId, discount.static_code);
    } else if (discount.code_type === 'dynamic') {
      return this.createExtendDynamicCodes(discountId, discount);
    }
    return Promise.resolve();
  };

  createStaticCode = (discountId, staticCode) => {
    const dataCode = {
      code: staticCode,
      discount_id: discountId
    };
    return createDiscountCodes(discountId, [dataCode],
      (result)=>{
        this.loadCampaign(this.state.campaignId); // Fetch Campaign to update UI
      }, 
      (error)=>{
        console.log('Error saving static codes!', error);
      }
    );
  };
  
  generateSecureRandomCode(length) {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const charsetLength = charset.length;
    let result = '';
    const randomValues = new Uint32Array(length);
  
    window.crypto.getRandomValues(randomValues);
  
    for (let i = 0; i < length; i++) {
      result += charset[randomValues[i] % charsetLength];
    }
  
    return result;
  }
  
  createExtendDynamicCodes = (discountId, discount) => {

    const existingCodesCount = discount && discount.discountCodes ? discount.discountCodes.length : 0;
    discount.dynamic_code_count = discount.dynamic_code_count - existingCodesCount
    const codes = Array.from({ length: discount.dynamic_code_count }, () =>
      // Math.random().toString(36).substring(2, 10).toUpperCase()
      this.generateSecureRandomCode(8)
    );
    const dataCodes = codes.map(code => ({ code, discount_id: discountId }));

    return createDiscountCodes(discountId, dataCodes,
      (result) => {
        console.log('Success creating dynamic codes!', result);
        this.loadCampaign(this.state.campaignId); // Fetch Campaign to update UI
      },
      (error) => {
        console.log('Error creating dynamic codes!', error);
        return Promise.reject(error);
      }
    );
  };

  removeDiscounts = (campaignId, discountsToRemove) => {
    discountsToRemove.forEach((discount) => {
      if (discount.id) {
        deleteDiscount(campaignId, discount.id,
          () => console.log('Discount deleted successfully'),
          (error) => console.error('Error deleting discount:', error));
      }
    });
  };

  removeDiscount = (index) => {
    this.setState(prevState => {
      const discountToRemove = prevState.discounts[index];
      const updatedDiscounts = [...prevState.discounts];
      updatedDiscounts[index] = {
        ...updatedDiscounts[index],
        isDeleted: !updatedDiscounts[index].isDeleted
      };
      return { 
        discounts: updatedDiscounts,
        discountsToRemove: [...prevState.discountsToRemove, discountToRemove],
        unsavedChanges: true
      };
    });
  };

  handleDeleteCampaign = () => {
    let confirm = window.confirm("Are you sure you want to delete this campaign?")
    if (!confirm) {
        return
    }

    const { campaignId } = this.state;
    if (campaignId) {
      deleteCampaign(
        campaignId,
        () => {
          this.props.history.push('/campaigns');
        },
        (error) => {
          console.error('Error deleting campaign:', error);
          this.setState({ saveError: 'Failed to delete campaign.' });
        }
      );
    }
  };

  renderAlerts() {

    var result = null

    if (this.state?.codeDuplicates?.length > 0) {
      let duplicates = this.state.codeDuplicates.map(item => `"${item}"`).join(", ");
      result = <UncontrolledAlert color="warning">Warning - The following static codes exists multiple discounts in this campaign: {duplicates}. Please ensure all codes are unique.</UncontrolledAlert>;
    }

    if (this.state.campaignSaved) {
      return <>
          <UncontrolledAlert color="success">Campaign saved successfully!</UncontrolledAlert>
          {result}
        </>
    } else if (this.state.saveError) {
      return <UncontrolledAlert color="danger">Error saving campaign: {this.state.saveError}</UncontrolledAlert>;
    }

    if (this.state.unsavedChanges) {
      return <UncontrolledAlert color="warning">Unsaved changes. Click 'Save Campaign' to save.</UncontrolledAlert>;
    }
    
    return <>
       {result}
      </>
  }

  cloneDiscount = (index) => {
    const discountToClone = this.state.discounts[index];
     const clonedDiscount = {
      ...discountToClone,
      id: null,
      discountCodes: null,
      title: `${discountToClone.title} (Copy)`,
      isCloneMode: true,
      totalClaimed: 0
    };

    // Set the cloned discount data and open the modal
    this.setState({
      currentDiscountIndex: null, 
      isModalOpen: true,
      currentDiscount: clonedDiscount, 
    });
  };
  

  onCopyStaticCode(discount, index) {
    navigator.clipboard.writeText(discount.discountCodes[0].code)
      .then(() => {
        this.setState({ copied: true, copiedDiscountIndex: index, copyText: 'Copied to clipboard!' });
        setTimeout(() => this.setState({ copied: false, copiedDiscountIndex: null, copyText: null }), 2500);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  }
  

  render() {
   
    const { campaignTitle, campaignBody, discounts, isModalOpen, isEditMode, currentDiscount, tickets, packages } = this.state;
    
    const groupedEvents = tickets?.reduce((acc, ticket) => {
      const eventOccurrence = ticket?.organization_event_occurrences[0];
      const eventId = eventOccurrence?.organization_event.id;
      const occurrenceId = eventOccurrence?.id;
      const eventTitle = eventOccurrence?.organization_event.title;
    
      if (eventId) {
        // Initialize the event group if it doesn't exist
        if (!acc[eventId]) {
          acc[eventId] = {
            eventTitle: eventTitle,
            occurrences: {},
          };
        }
    
        // Initialize the occurrence group if it doesn't exist
        if (!acc[eventId].occurrences[occurrenceId]) {
          acc[eventId].occurrences[occurrenceId] = {
            occurrenceDate: {
              startDate: eventOccurrence?.start_date,
              endDate: eventOccurrence?.end_date,
            },
            tickets: [],
          };
        }
    
        // Add the ticket to the appropriate occurrence group
        acc[eventId].occurrences[occurrenceId].tickets.push(ticket);
      }
      return acc;
    }, {});
 


    return (
      <React.Fragment>
        <header className="app-header-page justify-content-end">
          {isEditMode && (
            <Button className="ml-3" color="danger" outline={true} onClick={this.handleDeleteCampaign}>
              <i className="fa fa-trash"></i>&nbsp;Delete
            </Button>
          )}
          <Button className="ml-3" color="primary" onClick={this.saveCampaign}>
            <i className="fa fa-check"></i>&nbsp;{isEditMode ? 'Save' : 'Create'} Campaign
          </Button>
        </header>
        {this.renderAlerts()}
        <Row>
          <Col className="col-12 col-md-7">
            <FormGroup> 
              <Label for="title">Title</Label>
              <Input
                placeholder="Campaign Title"
                type="text"
                value={campaignTitle}
                onChange={this.handleCampaignTitleChange}
              />
            </FormGroup>
             <FormGroup>
                <Label for="title">Description</Label>
                <Input
                  rows="7"
                  type="textarea"
                  id="campaignBody"
                  value={campaignBody}
                  onChange={this.handleCampaignBodyChange}
                />
            </FormGroup>
            <Button className="mb-2 mt-4" color="primary" onClick={() => this.toggleModal(null)}>
              <i className="fa fa-plus-circle mr-1" /> Add Discount
            </Button>
            {discounts.map((discount, index) => {
              let totalOf = '';
              if (discount.discountCodes && discount.code_type === 'dynamic') {
                totalOf = `/ ${discount.discountCodes.length}`;
              }
              else if (discount.code_type === 'static') {
                if (discount.limit) {
                  totalOf = `/ ${discount.limit}`;
                } else {
                  totalOf = '';
                }
              }

              const inactive =  discount.valid_from === "1970-01-01T00:00:00.000Z" && discount.valid_to === "1970-01-01T00:00:00.000Z"

              return(
                <Card key={index} className={`mt-1 ${discount.isClone || inactive ? 'bg-gray' : ''} ${discount.isDeleted ? 'bg-danger' : ''}`}>
                  <CardBody>
                    <Row className='align-items-center'>
                      <Col md="6">
                        <small className='bold-700'>{discount.title || ''}</small>
                        
                        <div>
                          <small>
                            {inactive && 'INACTIVE'}
                            {!inactive && discount.valid_from ? superPrettyPrintDateTime(discount.valid_from) : ''}  
                            {!inactive && discount.valid_to ? ' - ' + superPrettyPrintDateTime(discount.valid_to) : ''}
                          </small>
                        </div>

                        <div className="mt-3 mini-font">Discount: {discount.value}{discount.value_type === 'percent' ? '%' : ' SEK'}</div>

                        <div className='mini-font'>Code type: <span className="text-capitalize">{discount.code_type}</span></div>
                        <div className='mini-font'>{discount.totalClaimed} {totalOf} { !discount.limit && discount.totalClaimed === 1 ? "code" : "codes" } claimed</div>
                      </Col>
                    

                      <Col md="6">

                        <div className='mb-2 d-flex '>
                            <Button color="primary" className='button-tooltip flex-grow-1' onClick={() => this.cloneDiscount(index)}><Icon.Copy size="16" /><span className={'button-tooltiptext'}>Clone</span></Button>
                            {discount.code_type === 'dynamic' && discount.discountCodes && discount.discountCodes.length > 0 && (
                              <Button color="primary" className='ml-2 button-tooltip flex-grow-1' onClick={() => this.downloadCodes(discount)}>
                                  <Icon.Download size="16" /><span className={'button-tooltiptext px-2'}>Download Codes</span>
                              </Button>
                            )}
                            {discount.code_type === 'static' && discount.discountCodes && discount.discountCodes.length > 0 && (
                              <Button id={`static_link_button_${index}`} color="primary" className='ml-2 button-tooltip flex-grow-1' onClick={() => this.onCopyStaticCode(discount, index)}>
                                <Icon.Clipboard size="16" /><span className={'button-tooltiptext'}>Copy Code</span>
                              </Button>
                            )}
                            {this.state.copied && this.state.copiedDiscountIndex === index ? (
                                <Tooltip placement={'top'} isOpen={true} target={`static_link_button_${index}`}>{this.state.copyText}</Tooltip>
                              ) : null}
                        </div>
                        <Button className='btn-block' color="primary" onClick={() => this.toggleModal(index)}>Edit</Button>
                        <Button className='btn-block' color="danger" onClick={() => this.removeDiscount(index)}>
                          {discount.isDeleted ? "Undelete" : "Delete"}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                )
              }
            )}

          </Col>
          <Col className="col-12 col-md-5">
            <div className="mb-5">
              <label>Connected Tickets</label>

              {!tickets || tickets.length === 0 ? (
                <p className="mini-font">No tickets connected!</p>
              ) : (
                Object.keys(groupedEvents).map((eventId) => {
                  const { eventTitle, occurrences } = groupedEvents[eventId];

                  return (
                    
                    <Card key={eventId} className="mb-3"> 
                    <CardBody>
                      <p>
                        <strong>
                          <a href={`/organization-events/edit/${eventId}`}>
                            {eventTitle}
                          </a>
                        </strong>
                      </p>
                      {Object.keys(occurrences).map((occurrenceId) => {
                        const { occurrenceDate, tickets } = occurrences[occurrenceId];

                        return (
                          <div key={occurrenceId} className='mb-3'>
                            <p className='list-item-heading mb-1'>
                              {superPrettyPrintDateTime(occurrenceDate.startDate)} - {superPrettyPrintDateTime(occurrenceDate.endDate)}
                            </p>
                            {tickets.map((ticket) => (
                              <p key={ticket.id} className="mb-1 list-item-text">{ticket?.name}</p>
                            ))}                             
                          </div>
                        );
                      })}
                      </CardBody>
                    </Card>
                  );
                })
              )}

            </div>
            <div>
              <label>Connected Packages</label>
              {!packages || packages.length === 0 ? (
                <p className="mini-font">No packages connected!</p>
              ) : (
                packages?.map((_package) => (
                  <Card key={_package.id} className="mb-3">
                    <CardBody>
                      <p>
                        <strong>
                        <a href={`/membership-packages/edit/${_package.id}`}>
                          {_package.name}
                        </a>
                        </strong>
                      </p>
                    </CardBody>
                  </Card>
                ))
              )}
            </div>
        </Col>
        </Row>

        <DiscountFormModal
          isOpen={isModalOpen}
          toggle={this.toggleModal}
          discount={currentDiscount}
          onSave={this.handleSaveDiscount}
        />
      </React.Fragment>
    );
  }

  downloadCodes = (discount) => {
    if (discount.discountCodes && discount.discountCodes.length > 0) {
      // Define CSV header
      const csvContent = "data:text/csv;charset=utf-8," 
        + "Code,Claimed, Campaign title, Discount title,Valid from,Valid to\n"  // Header row
  
        // Map discount codes and filter out null/undefined values
        + discount.discountCodes.map(code => {
            const campaignTitle = this.state.campaignTitle || '';
            const codeValue = code.code || '';  // Fallback to empty string if null/undefined
            const titleValue = discount.title || '';  // Fallback to empty string
            const validFrom = prettyPrintDateNoTime(discount.valid_from) || '';  // Fallback to empty string
            const validTo = prettyPrintDateNoTime(discount.valid_to) || '';  // Fallback to empty string
            const claimedDate = code.claimedAt ? prettyPrintDate(code.claimedAt) : '';

            return `${codeValue},${claimedDate},${campaignTitle},${titleValue},${validFrom},${validTo}`;
          }).join("\n");
  
      const encodedUri = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", `discount_codes_${discount.id}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  
  
}


export default CampaignForm;
