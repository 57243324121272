import React from 'react';
import { Button, Card, UncontrolledAlert, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import {
  patchOrganization, createOrganization, getAllOrganizationParents, getChildOrganizations, getOrganizations,
  getOrganizationRelation, createAllPendingChildRelations, createAllPendingParentRelations, deleteAllPendingDeleteRelations, patchOrganizationDetails, createOrganizationDetails, createOrganizationDetailTag, deleteOrganizationDetailTag, getOrganizationByIdWithTags
} from '../../../vibe/helpers/apiHelper';
import classnames from 'classnames';
import OrganizationRelations from '../../elements/organization/OrganizationRelations';
import { getOrganizationSelectOptions, parseErrorMessage } from '../../../vibe/helpers/util';
import OrganizationForm from '../../elements/organization/OrganizationForm';
import OrganizationSettings from '../../elements/organization/OrganizationSettings';

class EditOrganizationForm extends React.Component {
  constructor(props) {
    super(props);
    this.toggleTab = this.toggleTab.bind(this);

    this.state = {
      activeTab: '1',
      saveError: false,
      organizationEdited: false,
      organizationSaved: false,
      organizationRelationsEdited: false,
      warnings: [],
      pendingDeleteOrganizationRelations: [],
      childOrganizations: [],
      showNewParentForm: false,
      pendingNewParentRelations: [],
      currentNewParentRelation: '',
      showNewChildForm: false,
      pendingNewChildRelations: [],
      currentNewChildRelation: '',
      organizationDetails: {},
      organizationDetailsEdited: false,
      pendingAddTags: [],
      pendingDeleteTags: [],
      tempSelectedTags: [],
      selectedTag: null,
      tags: []
    };
  
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  

  componentDidMount() {
    this.fetchOrganization()
  }

  async saveOrganizationDetailTags () {
    let organizationDetails = this.state.organizationDetails
    if(this.state.pendingAddTags.length > 0){
    for(let i = 0; i <  this.state.pendingAddTags.length; i++) {
        const tag = this.state.pendingAddTags[i]
        await new Promise(resolve => {
            createOrganizationDetailTag(organizationDetails.id,tag.id, (result) => {
                resolve(true)
            }, (error) => {
                resolve(false)
            })
            
        })
    }
    }  
    this.setState({
        pendingAddTags: [],
        tempSelectedTags: [],
    })
  }

  async deleteOrganizationDetailTags () {
    if(this.state.pendingDeleteTags.length > 0){
        for (let i = 0; i < this.state.pendingDeleteTags.length; i++) {
            let deletedTagsId = this.state.pendingDeleteTags[i].id

            await new Promise(resolve => {
              deleteOrganizationDetailTag(deletedTagsId, (result) => {
                    resolve(true)
                }, (error) => {
                    resolve(false)
                })
            })
        }
        this.setState({
            pendingDeleteTags: [],
            tempSelectedTags: [],
            showAddTagButton: true
       })
    }
}

  /**
   * Render warning alerts on top of page if we find any logical errors within this organization
   */
  checkWarnings() {
    var warnings = []

    if (this.state.organization.root && this.state.parentOrganizationRelations.length > 0) {
      warnings.push('This organization is root, but have ' + this.state.parentOrganizationRelations.length + ' parent organization(s)')
    }
    if (!this.state.organization.root && this.state.parentOrganizationRelations.length <= 0) {
      warnings.push('This organization does not have any parent organizations')
    }

    this.setState({ warnings: warnings })
  }

  /**
   * Get all organization select options for adding relations
   */
  getAllOrganizations() {
    getOrganizations((organizations) => {
      var options = getOrganizationSelectOptions(organizations)
      this.setState({ options: options, showNewParentForm: false, pendingNewParentRelations: [], currentNewParentRelation: '', showNewChildForm: false, pendingNewChildRelations: [], currentNewChildRelation: '' })
    }, (error) => {
      this.setState({ organizationRelationError: 'Error getting organizations' })
    })
  }

  /**
   * Fetch all parent and child organizationRelations for this organization
   * 
   * @param organizationId 
   */
  fetchOrganizationRelations(organizationId) {
    getAllOrganizationParents(organizationId, (organizationRelations) => {
      this.setState({ parentOrganizationRelations: organizationRelations })
    }, (error) => {
      this.setState({ parentOrganizationRelations: [] })
    })

    this.setState({ childOrganizations: [] })
    getChildOrganizations(organizationId, (childOrganizations) => {
      if (childOrganizations && childOrganizations.length > 0 && childOrganizations[0].organizations) {

        for (var i = 0; i < childOrganizations[0].organizations.length; i++) {
          var childOrganization = childOrganizations[0].organizations[i]

          getOrganizationRelation(organizationId, childOrganization.id, childOrganization, (childRelations, organization) => {
            for (var j = 0; j < childRelations.length; j++) {
              var childRelation = childRelations[j]
              var stateChildOrganizations = this.state.childOrganizations
              childRelation.organization = organization

              stateChildOrganizations.push(childRelation)
              this.setState({ childOrganizations: stateChildOrganizations })
              this.checkWarnings()
            }
          }, (error) => {
            this.setState({ childOrganizations: [] })
          })
        }

      } else {
        this.setState({ childOrganizations: [] })
      }

    }, (error) => {
      this.setState({ childOrganizations: [] })
    })
  }

  /**
   * Fetch this organization, set an empty organization if no id available (creating a new organization)
   */
  fetchOrganization() {
    var organizationId = this.props.organizationId

    if (organizationId) {
      getOrganizationByIdWithTags(organizationId, (organization) => {
        this.getAllOrganizations()
        this.fetchOrganizationRelations(organizationId)


        const updatedOrganization = { ...organization };
        let organizationDetails
        if (updatedOrganization.organization_details && updatedOrganization.organization_details.length > 0) {
          organizationDetails = updatedOrganization.organization_details[0]
          delete updatedOrganization.organization_details;
        } else {
          organizationDetails = {}
        }
        
        this.setState({ organizationId: organizationId, organization: updatedOrganization, organizationDetails: organizationDetails, isRootOrganization: organization.root, new: false, pendingDeleteOrganizationRelations: [], tags: organizationDetails?.organization_detail_tags ?? [] })

      }, (error) => {
        this.setState({ error: error })
      })
    } else {
      let organization = {
        root: false,
        partner: false,
        appliable: false,
        school: false
      }
      this.setState({ new: true, organization: organization })
    }

  }

  /**
   * Show confirm alerts when toggling the root property
   * 
   * @returns save
   */
  confirmSave() {
    var save = true
    if (this.state.isRootOrganization !== this.state.organization.root && !this.state.new) {
      if (this.state.isRootOrganization) {
        save = window.confirm("Are you sure you want to remove root for this organization?")
      } else {
        save = window.confirm("Are you sure you want to set this organization as root?")
      }
    }
    return save
  }

  onSaveOrganization() {

    var save = this.confirmSave()
    if (!save) {
      return
    }

    this.setState({ organizationSaveError: null, organizationSaved: false })


    if (this.state.new) {
      createOrganization(this.state.organization, (organization) => {
        this.props.history.replace('/organization/edit/' + organization.id)
      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({ organizationSaveError: errorMessage })
      })
    } else {
      patchOrganization(this.state.organization, () => {

        this.createAllPendingRelations(() => {
          // reload page
          // Set the id for organization_id in orgnizationDetails and change null to ''
          const updatedOrganizationDetails = {
            ...this.state.organizationDetails,
            organization_id: this.state.organization.id,
            description: this.state.organizationDetails.description !== null ? this.state.organizationDetails.description : '',
            contact_phone_number: this.state.organizationDetails.contact_phone_number !== null ? this.state.organizationDetails.contact_phone_number : '',
            website: this.state.organizationDetails.website !== null ? this.state.organizationDetails.website : '',
            about_us: this.state.organizationDetails.about_us !== null ? this.state.organizationDetails.about_us : '',
            contact_email: this.state.organizationDetails.contact_email !== null ? this.state.organizationDetails.contact_email : '',
          };

          delete updatedOrganizationDetails.organization_detail_tags

          if (updatedOrganizationDetails && !updatedOrganizationDetails.id) {
            createOrganizationDetails(updatedOrganizationDetails, async (result) => {
              await this.saveOrganizationDetailTags()
              this.fetchOrganization()
              this.setState({ organizationSaved: true, organizationEdited: false, organizationRelationsEdited: false, organizationDetailsEdited: false })
              setTimeout(() => this.setState({ organizationSaved: false }), 5000)

            }, (error) => {
            })
          }
          else {
            patchOrganizationDetails(updatedOrganizationDetails, async () => {
              await this.saveOrganizationDetailTags()
              await this.deleteOrganizationDetailTags()
              this.fetchOrganization()
              this.setState({ organizationSaved: true, organizationEdited: false, organizationRelationsEdited: false, organizationDetailsEdited: false })
              setTimeout(() => this.setState({ organizationSaved: false }), 5000)
            }, (error) => {
            })
          }
        })

      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({ organizationSaveError: errorMessage })
      })
    }

  }

  /**
   * Create all new pending parent relations, pending child relations, pending delete relations from state
   */
  createAllPendingRelations(onSuccess) {
    createAllPendingParentRelations(this.state.pendingNewParentRelations, this.state.organization.id, () => {

      createAllPendingChildRelations(this.state.pendingNewChildRelations, this.state.organization.id, () => {

        deleteAllPendingDeleteRelations(this.state.pendingDeleteOrganizationRelations, () => {
          onSuccess()
        }, (error) => {
          this.setState({ organizationSaveError: error, pendingDeleteOrganizationRelations: [] })
        })

      }, (error) => {
        this.setState({ organizationSaveError: error, pendingNewChildRelations: [] })
      })

    }, (error) => {
      this.setState({ organizationSaveError: error, pendingNewParentRelations: [] })
    })
  }

  onChange(value, key) {
    var organization = this.state.organization
    organization[key] = value

    this.setState(organization)
    this.setState({ organizationEdited: true })
  }

  onChangeOrganizationDetails(value, key) {
    var organizationDetails = this.state.organizationDetails
    organizationDetails[key] = value

    this.setState(organizationDetails)
    this.setState({ organizationDetailsEdited: true })
  }

  updateState(key, value) {
    var state = this.state
    state[key] = value
    this.setState(state)
  }

  renderAlerts() {
    if (this.state) {
      if (this.state.organizationSaved) {
        return <UncontrolledAlert color="success">
          Organization saved!
        </UncontrolledAlert>
      } else if (this.state.organizationSaveError) {
        return <UncontrolledAlert color="danger">
          Error saving organization, error: {this.state.organizationSaveError}
        </UncontrolledAlert>
      } else if (this.state.warnings.length > 0) {
        return this.state.warnings.map((warning, i) => {
          return (
            <UncontrolledAlert key={i} color="warning">
              {warning}
            </UncontrolledAlert>
          )
        })
      }
    }
  }

  onClickNewParentRelationAdd() {
    if (this.state.currentNewParentRelation) {
      var pendingParentRelations = this.state.pendingNewParentRelations
      pendingParentRelations.push(this.state.currentNewParentRelation)
      this.setState({ showNewParentForm: false, pendingNewParentRelations: pendingParentRelations, currentNewParentRelation: '', organizationRelationsEdited: true })
    }
  }

  onParentOrgSelectChange(e) {
    let currentNewParentRelation = {
      id: e.value,
      name: e.name
    }
    this.setState({ currentNewParentRelation: currentNewParentRelation })
  }

  onClickNewChildRelationAdd() {
    if (this.state.currentNewChildRelation) {
      var pendingChildRelations = this.state.pendingNewChildRelations
      pendingChildRelations.push(this.state.currentNewChildRelation)
      this.setState({ showNewChildForm: false, pendingNewChildRelations: pendingChildRelations, currentNewChildRelation: '', organizationRelationsEdited: true })
    }
  }

  onChildOrgSelectChange(e) {
    let currentNewChildRelation = {
      id: e.value,
      name: e.name
    }
    this.setState({ currentNewChildRelation: currentNewChildRelation })
  }

  onDeleteOrganizationRelation(organizationRelationId) {
    var pendingDeleteOrganizationRelations = this.state.pendingDeleteOrganizationRelations
    if (!pendingDeleteOrganizationRelations.includes(organizationRelationId)) {
      pendingDeleteOrganizationRelations.push(organizationRelationId)
    }
    this.setState({ pendingDeleteOrganizationRelations: pendingDeleteOrganizationRelations, organizationRelationsEdited: true })
  }

  onAddTag = (organizationDetailTag) => {
    this.setState((prevState) => ({
      pendingAddTags: [...prevState.pendingAddTags, organizationDetailTag]
    }))
  }

  onDeleteTag = (organizationDetailTag) => {
    const isTagPendingAdd = this.state.pendingAddTags.some(pendingTag => pendingTag.id === organizationDetailTag.id);
    const isTagAlreadyAdded = this.state.pendingDeleteTags.some(existingTag => existingTag.id === organizationDetailTag.id);
  
    if (isTagPendingAdd) {
      const updatedPendingAddTags = this.state.pendingAddTags.filter(pendingTag => pendingTag.id !== organizationDetailTag.id);
      this.setState({
          pendingAddTags: updatedPendingAddTags,
      });
  } else if (!isTagAlreadyAdded) {
      this.setState(prevState => ({
          pendingDeleteTags: [...prevState.pendingDeleteTags, organizationDetailTag],
      }));
  } else {
      // Cancel deletion on click if the tag is already pending to be deleted
      const updatedPendingDeleteTags = this.state.pendingDeleteTags.filter(existingTag => existingTag.id !== organizationDetailTag.id);
      this.setState({
          pendingDeleteTags: updatedPendingDeleteTags,
      });
  }
  }
 
  render() {
    if (this.state && this.state.error) {
      return <div className='mx-3'>Error: {this.state.error}</div>
    } else {
      return (
        <React.Fragment>
          {this.renderAlerts()}
          {this.state && (this.state.organization || this.state.new) ?
            <Card>
              <div>
                {!this.state.new &&
                  <Nav tabs>
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggleTab('1') }}>
                        Organization {this.state.organizationEdited ? ' *' : ''}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggleTab('2') }}>
                        Relations {this.state.organizationRelationsEdited ? ' *' : ''}
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggleTab('3') }}>
                        Settings {this.state.organizationDetailsEdited ? ' *' : ''}
                      </NavLink>
                    </NavItem>

                  </Nav>
                }

                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <OrganizationForm organization={this.state.organization} onChange={this.onChange.bind(this)} />
                  </TabPane>

                  <TabPane tabId="2">
                    <OrganizationRelations parentOrganizationRelations={this.state.parentOrganizationRelations} childOrganizations={this.state.childOrganizations} organizationRelationError={this.state.organizationRelationError}
                      options={this.state.options} pendingDeleteOrganizationRelations={this.state.pendingDeleteOrganizationRelations}
                      showNewParentForm={this.state.showNewParentForm} pendingNewParentRelations={this.state.pendingNewParentRelations} currentNewParentRelation={this.state.currentNewParentRelation}
                      showNewChildForm={this.state.showNewChildForm} pendingNewChildRelations={this.state.pendingNewChildRelations} currentNewChildRelation={this.state.currentNewChildRelation}
                      onDeleteOrganizationRelation={this.onDeleteOrganizationRelation.bind(this)}
                      onClickNewParentRelationAdd={this.onClickNewParentRelationAdd.bind(this)}
                      onParentOrgSelectChange={this.onParentOrgSelectChange.bind(this)}
                      onClickNewChildRelationAdd={this.onClickNewChildRelationAdd.bind(this)}
                      onChildOrgSelectChange={this.onChildOrgSelectChange.bind(this)}
                      updateState={this.updateState.bind(this)} />
                  </TabPane>

                  <TabPane tabId="3">
                    <OrganizationSettings   
                        tags={this.state.tags}
                        pendingAddTags={this.state.pendingAddTags}
                        pendingDeleteTags={this.state.pendingDeleteTags}
                        tempSelectedTags={this.state.tempSelectedTags}
                        onAddTag={this.onAddTag}
                        onDeleteTag={this.onDeleteTag} 
                        organizationDetails={this.state.organizationDetails} 
                        onChange={this.onChangeOrganizationDetails.bind(this)} />
                        </TabPane>
                </TabContent>

              </div>
              <Button color="success" onClick={() => this.onSaveOrganization()}><i className="fa fa-check"></i>&nbsp;Save</Button>{' '}
            </Card>
            : null}

        </React.Fragment>
      );
    }

  }

}

export default EditOrganizationForm
