import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

export default class PaginationComp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }


  render() {
    var middleItems = [this.props.currentPage, this.props.currentPage + 1, this.props.currentPage + 2]

    if(this.props.lastPage > 4){
        if (this.props.currentPage - 1 > 0) {
        middleItems.unshift(this.props.currentPage - 1)
        } else {
        middleItems.push(this.props.currentPage + 3)
        }
    
        if (this.props.currentPage - 2 > 0) {
            middleItems.unshift(this.props.currentPage - 2)
        } else {
            middleItems.push(this.props.currentPage + 4)
        }
    }

    if (this.props.currentPage === this.props.lastPage - 1) {
      middleItems.pop()
    }

    if (this.props.currentPage === this.props.lastPage ) {
      middleItems.pop()
      middleItems.pop()
    }

    return <Pagination aria-label="Page navigation example">

      <PaginationItem disabled={this.props.currentPage === 1}>
        <PaginationLink first  onClick={e => this.props.onClickPagination(e, 1)}></PaginationLink>
      </PaginationItem>

      <PaginationItem disabled={this.props.currentPage === 1}>
        <PaginationLink previous  onClick={e => this.props.onClickPagination(e, this.props.currentPage - 1)} />
      </PaginationItem>

      {           
        middleItems.map((i, item) => {
          return <PaginationItem key={i} active={this.props.currentPage === i}>
            <PaginationLink onClick={e => this.props.onClickPagination(e, i)}>{i}</PaginationLink>
          </PaginationItem>
        })
      }

      <PaginationItem disabled={this.props.currentPage === this.props.lastPage }>
        <PaginationLink next  onClick={e => this.props.onClickPagination(e, this.props.currentPage + 1)} />
      </PaginationItem>

      <PaginationItem disabled={this.props.currentPage === this.props.lastPage }>
        <PaginationLink last  onClick={e => this.props.onClickPagination(e, this.props.lastPage )}></PaginationLink>
      </PaginationItem>

    </Pagination>
  }
}