import React, { Component } from 'react';
import { deleteMediaById, downloadMedia, getMedia, postMediaDragDrop } from '../../../vibe/helpers/apiHelper';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, UncontrolledAlert, FormGroup, Form, Label,Col } from 'reactstrap';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import placeholderImage from '../../../assets/images/placeholder.jpeg';
import LazyImage from '../../elements/membership/LazyImage';
import FileUpload from '../../elements/media/upload'

const UPLOAD_FORM_ID = "uploadForm"

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      filesNames: [],
      files: [],
      modalOpen: false,
      previewModal: false,
      mediaToDelete: {},
      loadMoreIndex: 0,
      totalMedia: 0,
      pendingMedia: []
    }
    this.togglePreview = this.togglePreview.bind(this)
  }

  downloadAllFileImages(medias) {
    var self = this
    medias.forEach((media) => {
      downloadMedia(media.file_path, (blob) => {
        var objectURL = URL.createObjectURL(blob);
        let files = self.state.files
        let newFile = {
          fileName: media.file_name,
          mediaId: media.id,
          file: objectURL,
          filePath: media.file_path,
        }
        files.push(newFile)
        const sortedFiles = files.sort((a, b) => b.mediaId - a.mediaId)
        self.setState({ files: sortedFiles })
      }, (error) => {
        console.log("DownloadMedia error", error)
      })
    })
  }

  componentDidMount() {
    this.fetchMedia()
    this.setupUploadForm()
  }

  fetchMedia() {
    this.setState({ files: [], fileNames: [] })
    var skip = 0
    getMedia( skip ,(medias) => {
      this.downloadAllFileImages(medias.media)
      this.setState({
        totalMedia: medias.totalCount
      })
    }, (error) => {
      console.log("Get files error", error)
    })
  }


  loadMore(){
    this.setState((prevState) => ({ 
        loadMoreIndex: prevState.loadMoreIndex + 1 
    }), ()=> this.fetchMore() );
  }
  fetchMore() {
    var skip = this.state.loadMoreIndex * 20
    getMedia( skip ,(medias) => {
      this.downloadAllFileImages(medias.media)
    }, (error) => {
      console.log("Get files error", error)
    })
  }
  


  renderAlerts() {
    if (this.state) {
      if (this.state.filesUploaded) {
        return <UncontrolledAlert color="success">
          {this.state.uploadedFileNames.length} file{this.state.uploadedFileNames.length > 1 ? 's' : ''} ({this.state.uploadedFileNames.join(', ')}) successfully uploaded!
        </UncontrolledAlert>
      } else if (this.state.fileDeleted) {
        return <UncontrolledAlert color="success">
          1 file successfully deleted!
        </UncontrolledAlert>
      } else if (this.state.filesUploadError) {
        return <UncontrolledAlert color="danger">
          Error uploading files: {this.state.filesUploadError}
        </UncontrolledAlert>
      } else if (this.state.fileDeleteError) {
        return <UncontrolledAlert color="danger">
          Error deleting file: {this.state.fileDeleteError}
        </UncontrolledAlert>
      }
    }
  }

  setupUploadForm() {
    const formElem = document.getElementById(UPLOAD_FORM_ID);
    formElem.onsubmit = async e => {
      e.preventDefault();

      if(this.state.pendingMedia.length <=0){
        return
      }
        
      let orgId = '0'

       // Handle the uploaded files here
      postMediaDragDrop(this.state.pendingMedia, orgId, (media) => {
        this.setState({pendingMedia: []})
        this.fetchMedia()

      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({ filesUploadError: errorMessage })
        console.log("Post file error", error)
      })
    };
  }

handleFileUpload = (uploadedFiles, imageKey) => {
    var pendingMedia = this.state.pendingMedia

          for(var uploadedFile of uploadedFiles){
                    Object.assign(uploadedFile, {
                            preview: URL.createObjectURL(uploadedFile),
                        })
                        }

    pendingMedia = pendingMedia.concat(uploadedFiles)
    this.setState({pendingMedia: pendingMedia})


  }

  render() {
    return <div>

      {this.renderAlerts()}

      <h1 className="h4 mb-1 ml-2">Upload media</h1>
      <Form inline id={UPLOAD_FORM_ID}>
        <Col>
            <Label for="image">Image</Label>
            <FileUpload height={200} onFileUpload={(e) => this.handleFileUpload(e, 'image_media_id')} onFileRemove={() => this.handleFileRemove('image_media_id')} clearFiles={true} currentImage={null} fileLimit={10} />
        </Col>
        <FormGroup>
            <Button disabled={this.state.pendingMedia.length === 0} type="submit" className="ml-2" color="success">Save</Button>
        </FormGroup>        
      </Form>
      

      <FormGroup className={'mt-5 mb-5'}>
        {this.state.pendingMedia.length !== 0 ? this.renderPendingFiles() : null}
      </FormGroup>
      
      <FormGroup className={'mt-5 mb-5'}>
        {this.renderFiles()}
      </FormGroup>
      <Button disabled={parseInt(this.state.totalMedia) === this.state.files.length} className="px-5" type="Button" color="primary" size="lg" onClick={()=> {this.loadMore()}}>Load More...</Button>

      <Modal isOpen={this.state.modalOpen} className="deleteModal">
        <ModalHeader>Delete media</ModalHeader>
        <ModalBody>
          <h5>Are you sure you want to delete <b>{this.state.mediaToDelete.fileName}</b>? This action can not be undone</h5>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" className="px-5" onClick={() => this.deleteMedia(this.state.mediaToDelete.mediaId)}>Delete</Button>{' '}
          <Button color="secondary" className="px-5" onClick={() => this.cancelDelete()}>Cancel</Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={this.state.previewModal} toggle={this.togglePreview} className="previewModal">
        <ModalHeader toggle={this.togglePreview}>Media</ModalHeader>
        <ModalBody>
            <img src={this.state.mediaPreview && this.state.mediaPreview.file} alt={'mediaPreview'}/>
            <p className="my-3"><strong>File Name:</strong> {this.state.mediaPreview && this.state.mediaPreview.fileName}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" className="px-5" onClick={(e) => this.onDeleteClick(this.state.mediaPreview)}>Delete</Button>{' '}
          <Button color="secondary" className="px-5" onClick={this.togglePreview}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>

  }

  cancelDelete() {
    this.setState({ modalOpen: false, mediaToDelete: {} })
  }

  onDeleteClick(e) {
    this.setState({ modalOpen: true, mediaToDelete: e })
  }

  deleteMedia(id) {
    this.cancelDelete()
    deleteMediaById(id, () => {
      this.setState({ fileDeleted: true })
      setTimeout(() => this.setState({ fileDeleted: false }), 5000)
      this.togglePreview()
      this.fetchMedia()
    }, (error) => {
      var errorMessage = parseErrorMessage(error)
      this.setState({ fileDeleteError: errorMessage })
      console.log("Error deleting media")
    })
  }

  onPreviewClick(file) {
    this.setState({previewModal: true, mediaPreview: file})
  }
  togglePreview(e = null) {
    this.setState(prevState => ({
      previewModal: !prevState.previewModal
    }));
  }

  renderFiles() {
    return (
      <div className={'mt-5 mb-5'}>
      <h1 className={'h4 mb-1 ml-2'}>All Media</h1>
      <Col>
      <div className={'mb-5 m-l-n-lg m-r-n-xl row'}>
      {this.state.files.map((file, index) => (
       <div className={'m-2'} key={file.mediaId}>
        <div className="attachment-preview" onClick={(e)=> this.onPreviewClick(file)}>
          <div className="thumbnail">
            <div className="centered">
              <LazyImage unloadedSrc={placeholderImage} fileName={file.filePath} alt={'Media file ' + index} />
            </div>
          </div>
        </div>
      </div>
    ))}
    </div>
    </Col>
    </div>
    )
  }

  renderPendingFiles() {
  return (
    <div className={'mt-5 mb-5'}>
      <h1 className={'h4 mb-1 ml-2'}>Pending Media</h1>
      <Col>
      <div className="row">
            <UncontrolledAlert color="gray" className={'ml-2 mt-2'}>
              <small>Changes have been made, for them to be taken into effect you must save.</small>
            </UncontrolledAlert>
      </div>
      <div className={'mb-5 m-l-n-lg m-r-n-xl row'}>
        {this.state.pendingMedia.map((file, index) => (
          <div className={'m-2'} key={file.preview}>
            <div className="attachment-preview" onClick={(e) => this.onPreviewClick(file)}>
              <div className="thumbnail">
                <div className="centered">
                  <img src={file.preview} alt={'Media file ' + index} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      
      </Col>
    </div>
  );
}
}

export default Media;
