import React from 'react';
import { Button, Col } from 'reactstrap';
import { Loader } from '../../../vibe';
import { createPayout, getBillingsByOrganization, getOrganizationById, getPayoutsByOrganizationId, getPendingTransactions } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import ListHeader from '../../elements/form/ListHeader';
import MessageUncontrolledAlert from '../../elements/form/MessageUncontrolledAlert';
import MemberPackageTransaction from '../../elements/transaction/MemberPackageTransaction';
import MemberTicketTransaction from '../../elements/transaction/MemberTicketTransaction';
import BillingItem from '../billing/BillingItem';
import PayoutItem from './PayoutItem';
import Select from 'react-select'

const swedishTimezone = 'Europe/Stockholm';

export const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

class Payouts extends React.Component { 

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            pendingTransactions: [],
            pendingTransactionsCount: 0,
            totalAmountPending: [],
            month: null,
            year: null,
            organization: null,
            months: []
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id
        let organizationId = parseInt(id)
        this.setState({organizationId: organizationId})

        let months = []
        this.setState({months: months})

        getOrganizationById(organizationId, (organization) => {
            this.setState({organization: organization})
        }, (error) => {  
        })

        getPendingTransactions(organizationId, (transactions) => {
            
            // first add 12 months
            var date = new Date();
            let currentMonthToHandle = date.getMonth() - 2
            let currentYear = date.getFullYear()
            for (var i = 0; i < 12; i++) {
                if (currentMonthToHandle < 0) {
                    currentMonthToHandle = 11
                    currentYear--;
                }
                let label = MONTHS[currentMonthToHandle] + ' ' + currentYear + ' '
                months.push({value: MONTHS[currentMonthToHandle] + ' ' + currentYear, label: label, count: 0, year: currentYear, month: currentMonthToHandle})
                currentMonthToHandle--;
            }


            // add months for all pending transactions
            if (transactions && transactions.length > 0) {
                for (var transaction of transactions) {

                    let createdAtMonth = transaction.created_at.substring(5, 7)
                    let monthInt = parseInt(createdAtMonth) - 1

                    let createdAtYear = transaction.created_at.substring(0, 4)
                    let yearInt = parseInt(createdAtYear)

                    var added = false
                    for (var month of months) {
                        if (month.month === monthInt && month.year === yearInt) {
                            month.count = month.count + 1
                            month.label = month.label.split(' ')[0] + ' ' + month.label.split(' ')[1] + ' (' + month.count + ' pending purchases)'
                            added = true
                        }
                    }

                    if (!added) {
                        let label = MONTHS[monthInt] + ' ' + yearInt
                        months.push({value: label, label: label + ' (' + 1 + ' pending purchases)', count: 1, year: yearInt, month: monthInt})
                    }

                    this.setState({months: months})
                }
            }

            this.setState({month: months[0].month, year: months[0].year}, () => {
                const {from, to} = this.getMonthPeriod(months[0].year, months[0].month)
                this.getBillings(organizationId, from, to)
            })
             
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({error: errorMessage})
        })

        this.getPayouts(organizationId)
    }
    
    getBillings(organizationId, from, to) {
        getBillingsByOrganization(organizationId, from, to, (billingTransactions) => {

            if (billingTransactions && billingTransactions.length > 0) {
                let billingResult = billingTransactions.find((billingResult) => billingResult.scope === 'all')
                this.setState({loading: false, pendingTransactions: billingResult.pending.transactions, totalAmountPending: billingResult.pending.total_amount, pendingTransactionsCount: billingResult.pending.count})
            }
        }, (error) => {
            let errorMessage = parseErrorMessage(error)
            this.setState({loading: false, error: errorMessage})
        })
    }

    getPayouts(organizationId) {
        getPayoutsByOrganizationId(organizationId, (payouts) => {
            this.setState({payouts: payouts})
        }, (error) => {
            
        }) 
    }

    renderPayouts() {
        if (!this.state.payouts || this.state.payouts.length <= 0) {
            return null
        }

        return this.state.payouts.map((payout) => {
            return <PayoutItem key={payout.id} edit={true} payout={payout} organizationId={this.state.organizationId} clickable={true} showAmount={true}  />
        })
    }

    renderBillingTransactions() {
        return <>
                {this.renderTransactions(this.state.pendingTransactions, 'Pending')}
              </>
    }

    renderTransactions(transactions, title) {
        let items
        if (transactions && transactions.length > 0) {
            items = transactions.map((transaction) => {
                if (transaction.ticket_transactions && transaction.ticket_transactions.length > 0) {
                    return <MemberTicketTransaction key={transaction.id} transaction={transaction} member={transaction.ticket_transactions[0].member} ticket={transaction.ticket_transactions[0].tickets} />
                } else if (transaction.package_transactions && transaction.package_transactions.length > 0) {
                    return <MemberPackageTransaction key={transaction.id} transaction={transaction} member={transaction.package_transactions[0].member} package={transaction.package_transactions[0].package} />
                } 
                return null 
            })
        }

        if (items) {
            return <>
                <div className='mb-2'>{title}</div>
                {items}
            </>
        }
    }

    async onClickCreatePayouts(monthOption) {

        const {from, to} = this.getMonthPeriod(this.state.year, this.state.month)
        const payoutfromDate = from + 'T00:00:00.000Z'
        const payoutToDate = to + 'T23:59:59.000Z'

        let count = 0
        let error

        for (var totalAmountPending of this.state.totalAmountPending) {

            let transactionIds = []
            if (totalAmountPending.transactions && totalAmountPending.transactions.length > 0) {
                transactionIds = totalAmountPending.transactions.map(pendingTransaction => pendingTransaction.id)
            }
            
            let res = await this.promisifyPayout(this.state.organizationId, transactionIds, monthOption, payoutfromDate, payoutToDate)
            if (res && res.result) {
                count++
            } else {
                error = res.data
            }

            if (count > 0) {
                this.setState({payoutsSaved: true, payoutsSaveMessage: '' + count + ' payouts successfully saved'})
                setTimeout(() => this.setState({ payoutsSaved: false }), 5000)
            }
            
        }

        if (error) {
            let errorMessage = parseErrorMessage(error)
            this.setState({payoutsSaveError: errorMessage})
        }

        this.getBillings(this.state.organizationId, from, to)

        this.getPayouts(this.state.organizationId)
    }

    async promisifyPayout(organizationId, transactionIds, monthOption, from, to) {
        return new Promise((resolve, reject) => {
            createPayout(organizationId, transactionIds, monthOption.value, from, to, (result) => {
                resolve({result: true, data: result})
            }, (error) => {
                resolve({result: false, data: error})
            })
        })
    }

    renderAlerts() {
        // can show both error and success at the same time
        return <>
            <MessageUncontrolledAlert success={this.state.payoutsSaved}
                    successMessage={this.state.payoutsSaveMessage} />

            {this.state.payoutsSaveError && <MessageUncontrolledAlert success={false} error={this.state.payoutsSaveError} 
                    errorMessage={this.state.payoutsSaveError} />}
        </>
    }

    onChange(key, year, month) {
        this.setState({year: year, month: month})
        const {from, to} = this.getMonthPeriod(year, month)
        this.getBillings(this.state.organizationId, from, to)
    }

    getMonthPeriod(year, month) {
        var firstDay = new Date(year, month, 1);
        var lastDay = new Date(year, month + 1, 0);
        let from = firstDay.toLocaleString('sv-SE', { timeZone: swedishTimezone }).split(" ")[0]

        // when fetching billings, we add 'T23:59:59.000Z' to the end date
        let to = lastDay.toLocaleString('sv-SE', { timeZone: swedishTimezone }).split(" ")[0]
        return {from, to}
    }

    renderMonthSelect() {
        const filteredMonths = this.state.months.filter((month) => month.count > 0);

        if (filteredMonths.length > 0) {
            return (
                <Select 
                    options={filteredMonths} 
                    onChange={(e) => this.onChange('month', e.year, e.month)} 
                    placeholder={'Select month...'} 
                    value={filteredMonths.filter((option) => option.year === this.state.year && option.month === this.state.month)}  
                />
            );
        } else {
            return <div className="card"><div className="card-body">No Pending</div></div>;
        }    
    }

    render() {

        if (this.state.error) {
            return <>Error: {this.state.error}</>
        }

        if (this.state.loading) {
            return <Loader type="dots" />
        }

        let monthOption = this.state.months.find((option) => option.month === this.state.month && option.year === this.state.year)

        return <>
                {this.renderAlerts()}

                <ListHeader title={'Payouts ' + (this.state.organization ? '(' + this.state.organization.display_name + ')' : '')} links={''} />

                <Col className='mb-3'>
                    {this.renderMonthSelect()}
                </Col>

                <Col>
                    <BillingItem header={'Pending purchases for ' + monthOption.value} amount={this.state.totalAmountPending} count={this.state.pendingTransactionsCount} includeFees={true} />
                </Col>

                <Col>
                    <Button className="mt-4" color="success" disabled={this.state.pendingTransactionsCount <= 0} onClick={() => this.onClickCreatePayouts(monthOption)}><i className="fa fa-check"></i>&nbsp;Create payout for {monthOption.value}</Button>
                </Col>

                <div className='row mb-3 mt-5'>
                    <Col>
                        <div className='mb-2'><h4>All Payouts</h4></div>
                        {this.renderPayouts()}
                    </Col>
                </div> 

            </>
    }

}

export default Payouts
