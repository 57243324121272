import { Component } from "react";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label, Row } from 'reactstrap';
import { getOrganizationTags } from "../../../../vibe/helpers/apiHelper";
import { getTagOptions } from "../../../../vibe/helpers/util";
import { DELETE, isDelete, NEW } from "../../../elements/form/FormAction";
import { v4 as uuidv4 } from 'uuid';

class OrganizationTags extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            tagOptions: [],
            tempSelectedTags: [],
            isAddButtonDisabled: false,
            showAddTagButton: true,
            showAddTagSection:false
        }
    }
    
    componentDidMount() {
        const skipTagOptions = this?.props?.skipTagOptionIds ?? []
        const includeTagOptions = this?.props?.includeTagOptionIds ?? []

        getOrganizationTags(['value ASC'],(result) => {
            var tagOptions = getTagOptions(result)
            tagOptions = tagOptions.filter((option) => !skipTagOptions.includes(option.value))
            if (includeTagOptions.length > 0) {
                tagOptions = tagOptions.filter((option) => includeTagOptions.includes(option.value))
            }
            this.setState({tagOptions: tagOptions})            
        }, (error)=> {
            console.log(error)
        }) 
    }

    toggleTagForm = () => {
        this.setState((prevState) => ({
            showAddTagSection: !prevState.showAddTagSection,
            showAddTagButton: !prevState.showAddTagButton,
            isAddButtonDisabled: true
        }));
    };

    onClickAddTag = () => {
        this.setState({showTagDropDown: true})
    }

    toggleDropDown = () => {
        this.setState({showTagDropDown: !this.state.showTagDropDown})
    }

    handleTagSelect = (selectedOption, tags) => {

        const selectedTag = {
            id: selectedOption.value,
            value: selectedOption.label,
        };
    
        this.setState({tempSelectedTags: [selectedTag]}, () => {
            this.handleAddTags()
        })

        const selectedTagId = selectedTag.id
    
        const isTagAlreadyAdded = tags ? tags.some(tag => tag.organization_tag.id === selectedTagId) : false;
        
        const isTagPendingAdd = tags.some(tag => tag.id === selectedTagId && tag?._action === NEW);
    
        if (isTagAlreadyAdded || isTagPendingAdd) {
            this.setState({
                isAddButtonDisabled: true,
            });
        }
      };

    handleAddTags = () => {
        const selectedTag = this.state.tempSelectedTags[0]

        if (selectedTag) {
            const isTagAlreadyAdded = this.props.tags.some((tag) => tag.organization_tag_id === selectedTag.id);
            if (!isTagAlreadyAdded) {
                this.setState((prevState) => ({
                    showTagForm: false,
                    tempSelectedTags: [],
                    showAddTagSection: false,
                    showAddTagButton: true
                }), () => {
                  const data = {
                    key: uuidv4(),
                    organization_tag_id: selectedTag.id,
                    organization_tag: {
                        ...selectedTag
                    }
                  }
                  this.props.onAddTag(data)
                });
            } else {
                const tagToDelete = this.props.tags.find((tag) => tag.organization_tag_id === selectedTag.id);
                if (tagToDelete) {
                    this.props.onDeleteTag(tagToDelete)
                }
            }
        }
    }

    deleteTag(tag) {
        if (!tag) {
            return
        }
        this.props.onDeleteTag(tag)
    }

    isTagPendingDelete = (tag) => {
        return tag?._action === DELETE
    }

    isTagPendingAdd = (tag) => {
        return tag?._action === NEW
    }

    renderTags = (tags) => {
        if (!tags || tags.length <= 0) {
            return null
        }

        return tags.map((tag, index) => {
            if (!tag?.organization_tag?.value) {
                return null
            }

            if (isDelete(tag)) {
                return null
            }

            return <span key={index} className='mr-2 mb-2'>
                    <div
                        className={`d-flex badge-tag badge-pill badge-primary px-3 py-1`}>
                        {tag?.organization_tag?.value}
                    </div>
                </span>
            
        })
    }

    onChangeTag(e, tagOption) {
        e.preventDefault()
        this.handleTagSelect(tagOption, this.props.tags)
    }

    tagIsSelected(tag) {
        const tags = this.props.tags
        if (!tags || tags.length <= 0) {
            return false
        }

        for (const stateTag of tags) {
            if (stateTag?.organization_tag?.id === tag.value && !isDelete(stateTag)) {
                return true
            }
        }
        return false
    }

    renderTagDropDown() {

        return <>
            <Dropdown className="mb-4 mt-2" isOpen={this.state.showTagDropDown} toggle={this.toggleDropDown}>
                <DropdownToggle color="primary">
                    <i className="fa fa-plus-circle mr-1" />
                    Select tags
                </DropdownToggle>
                <DropdownMenu>
                    {this.state.tagOptions.map((tag, index) => (
                    <DropdownItem key={index} toggle={false} onClick={(e) => this.onChangeTag(e, tag)}>
                        <FormGroup check>
                            <Label check>
                                <Input
                                    type="checkbox"
                                    checked={this.tagIsSelected(tag)}
                                    onChange={(e) => this.onChangeTag(e, tag)} />
                                    {tag.label}
                                </Label>
                        </FormGroup>
                    </DropdownItem>
                    ))}
                </DropdownMenu>
            </Dropdown>
        </>
    }

    render() {
        const { tags } = this.props;
        return (
            <>
                {this.renderTagDropDown()}
                <div className='col-12 mb-2'>
                    <Row className='justify-content-evenly'>
                        {tags && this.renderTags(tags)}
                    </Row>
                </div>
            </>
        )
    }
}

export default OrganizationTags;
