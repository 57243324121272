import React, { Component } from 'react';
import {Input, Col, Form, FormGroup, Label, Row} from 'reactstrap';
import Select from 'react-select'
import SmallTextArea from '../form/SmallTextArea';
import { prettyPrintDate } from '../../../vibe/helpers/util';
import FileUpload from '../../elements/media/upload'

import { postMediaDragDrop } from '../../../vibe/helpers/apiHelper';
import { parseErrorMessage } from '../../../vibe/helpers/util';
import {  onEditObject } from '../../elements/form/FormAction';
import { getMediaImage } from '../../../vibe/helpers/mediaHelper';
import MediaSelect from '../../elements/media/MediaSelect';

class MembershipForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            images: {},
        };
    }

    componentDidMount() {
        this.getCardImages()
    }

    getCardImages() {
        getMediaImage(this.props.membership.front_image_media_id, (image) => {
            this.setState({ currentFrontImage: image })
        }, (error) => {
            console.log(error)
        })

        getMediaImage(this.props.membership.back_image_media_id, (image) => {
            this.setState({ currentBackImage: image })
        }, (error) => {
            console.log(error)
        })
    }

    handleFileRemove = (imageKey) => {
        this.props.onChange(null, imageKey)
    }
    
    handleFileUpload = (uploadedFiles, imageKey) => {
        // Handle the uploaded files here
        let orgId = '0'

        postMediaDragDrop(uploadedFiles, orgId, (media) => {
            this.props.onChange(media[0].id, imageKey)
        }, (error) => {
            var errorMessage = parseErrorMessage(error)
            this.setState({ filesUploadError: errorMessage })
            console.log("Post file error", error)
        })
    }


    render() {
        return <React.Fragment>
            <Form>
            <Row>
                <Col sm="6">
                    <FormGroup>
                        <Label for="id">ID</Label>
                        <Input type="text" name="id" id="id" defaultValue={this.props.membership ? this.props.membership.id : ''} disabled />
                    </FormGroup>

                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input type="text" name="name" id="name" defaultValue={this.props.membership ? this.props.membership.name : ''} onChange={(e) => this.props.onChange(e.target.value, 'name')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="valid_from">Valid from</Label>
                        <Input type="date" name="valid_from" id="valid_from" defaultValue={this.props.membership && this.props.membership.valid_from ? this.props.membership.valid_from.substr(0,10) : ''} onChange={(e) => this.props.onChangeDate(e.target.value, 'valid_from')}  />
                    </FormGroup>

                    <FormGroup>
                        <Label for="valid_to">Valid to</Label>
                        <Input type="date" name="valid_to" id="valid_to" defaultValue={this.props.membership && this.props.membership.valid_to ? this.props.membership.valid_to.substr(0,10) : ''} onChange={(e) => this.props.onChangeDate(e.target.value, 'valid_to')}  />
                    </FormGroup>

                    <FormGroup>
                        <Label for="front_image">Front Image (To Be Deleted)</Label>
                        <Input type="text" name="front_image" id="front_image" defaultValue={this.props.membership ? this.props.membership.front_image : ''} onChange={(e) => this.props.onChange(e.target.value, 'front_image')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="back_image">Back Image (To Be Deleted)</Label>
                        <Input type="text" name="back_image" id="back_image" defaultValue={this.props.membership ? this.props.membership.back_image : ''} onChange={(e) => this.props.onChange(e.target.value, 'back_image')} />
                    </FormGroup>

                    <FormGroup>
                        <Label for="card_number_prefix">Card number prefix</Label>
                        <Input type="text" name="card_number_prefix" id="card_number_prefix" defaultValue={this.props.membership ? this.props.membership.card_number_prefix : ''} onChange={(e) => this.props.onChange(e.target.value, 'card_number_prefix')}  />
                    </FormGroup>

                    <FormGroup>
                        <Label for="membership_theme_id">Theme</Label>
                        <Select isClearable={true} options={this.props.themeOptions} onChange={(e) => this.props.onChange(e?.value, "membership_theme_id")} placeholder={"Select theme..."} 
                            value = {
                                this.props.membership  && this.props.themeOptions ? this.props.themeOptions.filter(theme => theme.value === this.props.membership.membership_theme_id) : '' 
                            } /> 
                    </FormGroup>

                    {/* TODO special stuk only fields */}
                    <FormGroup check className='d-flex align-items-center'>
                            <Input type="checkbox" name="has_extra_text" id="has_extra_text" defaultChecked={this.props.membership ? this.props.membership.has_extra_text : false} onChange={(e) => this.props.onChange(e.target.checked, 'has_extra_text')} />{' '}
                            <Label for="has_extra_text" className='mt-2'>
                                Has extra text
                            </Label>
                    </FormGroup>

                    <FormGroup check className='d-flex align-items-center'>
                            <Input type="checkbox" name="has_card_code" id="has_card_code" defaultChecked={this.props.membership ? this.props.membership.has_card_code : false} onChange={(e) => this.props.onChange(e.target.checked, 'has_card_code')} />{' '}
                            <Label for="has_card_code" className='mt-2'>
                                Has card code
                            </Label>
                    </FormGroup>

                </Col>
                <Col sm="6">
                    <FormGroup>
                        <Label for="created_at">Created at</Label>
                        <Input type="text" name="created_at" id="created_at" defaultValue={this.props.membership ? prettyPrintDate(this.props.membership.created_at) : ''} disabled />
                    </FormGroup>

                    <FormGroup>
                        <Label for="organization_id">Organization</Label>
                        <Select options={this.props.organizationOptions} onChange={(e) => this.props.onChange(e.value, "organization_id")} placeholder={"Select organization..."} 
                            value = {
                                this.props.membership  && this.props.organizationOptions ? this.props.organizationOptions.filter(option => option.value === this.props.membership.organization_id) : '' 
                            } /> 
                    </FormGroup>

                    <FormGroup>
                        <Label for="semester">Semester</Label>
                        <Input type="text" name="semester" id="semester" defaultValue={this.props.membership ? this.props.membership.semester : ''} onChange={(e) => this.props.onChange(e.target.value, 'semester')}  />
                    </FormGroup>


                    <FormGroup>
                        <Label for="extra_attributes">Extra Attributes</Label>
                        <SmallTextArea defaultValue={this.props.membership ? this.props.membership.extra_attributes : ''} onChange={(e) => this.props.onChange(e.target.value, 'extra_attributes')} />
                    </FormGroup>               


                    <Row>
                        <Col>
                            <Label for="image">Front image</Label>
                            <FileUpload 
                                ratio={"1.5877742/1"} 
                                onFileUpload={(e) => this.handleFileUpload(e, 'front_image_media_id')} 
                                onFileRemove={() => this.handleFileRemove('front_image_media_id')} 
                                currentImage={this.state.currentFrontImage} 
                            />
                            <FormGroup className="d-flex justify-content-between">
                                <MediaSelect 
                                    files={this.state.files} 
                                    item={this.props.membership.organization_id} 
                                    index={0} 
                                    mediaId={this.props.membership.front_image_media_id} 
                                    itemId={this.props.membership.organization_id}
                                    onChange={(id, item, index) => { this.props.onChange(id, 'front_image_media_id'); this.getCardImages() }} 
                                    onDelete={(itemId, index) => this.onChange(null, 'front_image_media_id')} 
                                />
                                <p className="mini-font">Recommended size 1013x638px</p>                            
                            </FormGroup>
                        </Col>        
                        <Col>
                            <Label for="image">Back image</Label>
                            <FileUpload 
                                ratio={"1.5877742/1"} 
                                onFileUpload={(e) => this.handleFileUpload(e, 'back_image_media_id')} 
                                onFileRemove={() => this.handleFileRemove('back_image_media_id')} 
                                currentImage={this.state.currentBackImage} 
                            />
                            <FormGroup className="d-flex justify-content-between">
                                <MediaSelect 
                                    files={this.state.files} 
                                    item={this.props.membership.organization_id} 
                                    index={0} 
                                    mediaId={this.props.membership.front_image_media_id} 
                                    itemId={this.props.membership.organization_id}
                                    onChange={(id, item, index) => { this.props.onChange(id, 'back_image_media_id'); this.getCardImages() }} 
                                    onDelete={(itemId, index) => this.onChange(null, 'back_image_media_id')} 
                                />
                                <p className="mini-font">Recommended size 1013x638px</p>                            
                            </FormGroup>
                        </Col>
                    </Row>        
                </Col>
                 
            </Row>
        </Form>

        </React.Fragment>
    }
}

export default MembershipForm;
