import React, { Component } from 'react';
import PaginationComp from '../../../vibe/components/utilities/Pagination/Pagination';

const pageSizeDefault = 10

export default class Paginator extends Component {

    constructor(props) {
        super(props);
        this.state = {
          currentPage: 1,
          totalCount: -1,
          pageSize: pageSizeDefault
        }
    }

    renderPagination() {
        if (!this.state.currentPage) {
            return null
        }
        return <PaginationComp currentPage={this.state.currentPage} lastPage={this.getLastPage()} onClickPagination={(e, page) => this.onClickPagination(e, page)} />
    }
    
    getLastPage() {
        return Math.ceil(this.state.totalCount / this.state.pageSize)
    }

    onClickPagination(e, page) {
        this.setState({ currentPage: page })
        var offset = (page - 1) * this.state.pageSize
        this.fetchNextPage(offset)
    }

    fetchNextPage(offset) {

    }

}