import React from 'react';
import {Button, Card, UncontrolledAlert} from 'reactstrap';
import { fetchMembership, getOrganizations, createMembership, patchMembership } from '../../../vibe/helpers/apiHelper';
import { getOrganizationSelectOptions, parseErrorMessage } from '../../../vibe/helpers/util';
import MembershipForm from '../../elements/membership/MembershipForm';
import { getThemes } from '../../../api/theme/theme'


class EditMembershipForm extends React.Component {  

  componentDidMount() {
    this.fetchMembership()
  }

  fetchMembership() {

    this.getMembership((membership) => {

      this.setState({ loading: false, membership: membership} )

      getOrganizations((organizations) => {
          var organizationOptions = getOrganizationSelectOptions(organizations)
          this.setState({organizationOptions: organizationOptions})
      }, (error) => {
          if (error.message) {
              this.setState({loading: false, error: error.message})
            } else {
              this.setState({loading: false, error: error})
          }
      }) 

      this.loadThemes()
    })
  }

  getMembership(onSuccess) {
    var membershipId = this.props.membershipId
    if (membershipId) {
        fetchMembership(membershipId, (membership) => {
            onSuccess(membership)
            this.setState({new: false})
      }, (error) => {
        var errorMessage = parseErrorMessage(error)
        this.setState({error: errorMessage})
      })
    } else {
      let membership = {
        has_extra_text: false,
        has_card_code: false,
        allow_physical_card: false,
        auto_order_physical_card: false
      }
      onSuccess(membership)
      this.setState({new: true})
    }
  }

  onChange(value, key) {
    var membership = this.state.membership
    membership[key] = value ?? null;
    this.setState({membership: membership})

    this.setState({ membershipEdited: true })
  }

  onChangeDate(value, key) {
    var membership = this.state.membership
    let date = `${value}T00:00:00.000Z`
    membership[key] = date
    this.setState({membership: membership, membershipEdited: true})
  }

  onClickSaveMembership() {
    var membership = this.state.membership
    
    this.setState({membership: membership}, () => {
      this.onSaveMembership()
    }) 
  }

  onSaveMembership() {

    this.setState({membershipSaveError: null, membershipSaved: false})

    if (this.state.new) {
        createMembership(this.state.membership, (membership) => {
              this.props.history.replace('/membership/edit/' + membership.id)
        }, (error) => {
            var errorMessage = parseErrorMessage(error)
            this.setState({membershipSaveError: errorMessage})
        }) 
    } else {

        const membershipData = this.state.membership
        delete membershipData.updated_at;
        delete membershipData.created_at;

        patchMembership(membershipData, () => {
              this.fetchMembership()
              this.setState({membershipSaved: true, membershipEdited: false})
              setTimeout(() => this.setState({ membershipSaved: false }), 5000)
        }, (error) => {
            var errorMessage = parseErrorMessage(error)
            this.setState({membershipSaveError: errorMessage})
        })
    }

  }

  renderAlerts() {
    if (this.state) {
        if (this.state.membershipSaved) {
            return <UncontrolledAlert color="success">
                Membership saved!
            </UncontrolledAlert>
        } else if (this.state.membershipSaveError) {
            return <UncontrolledAlert color="danger">
                Error saving membership, error: {this.state.membershipSaveError}
            </UncontrolledAlert>
        }
    }
  }


  loadThemes = () => {
    getThemes(
      (themes) => {
        var options = []
        if (themes && themes.length > 0) {
          themes.forEach((theme) => {
                let themeOption = {
                    value: theme.id,
                    label: theme.name,
                    name: theme.name
                }
                options.push(themeOption)
            })
        }
        this.setState({ themeOptions: options });
      },
      (error) => {
        console.error('Error fetching themes:', error);
      }
    );
  };
  

  handleMembershipClone() {
    const { membership } = this.state;

    const clonedMembership = {
        ...membership,
        name: `${membership.name} (Clone)`,
        id: undefined,
        created_at: undefined,
        updated_at: undefined,
    };

    createMembership(clonedMembership, (newMembership) => {
        this.props.history.push('/memberships');
    }, (error) => {
        var errorMessage = parseErrorMessage(error);
        this.setState({ membershipSaveError: errorMessage });
    });
}


  render() {
    if (this.state && this.state.error) { 
      return <div className='mx-3'>Error: {this.state.error}</div>
    } else if (this.state && this.state.membership) {
        return (
            <React.Fragment>
                <header className="app-header-page justify-content-end">
                 
                  <Button className="ml-3" color="primary" outline={true} onClick={() => this.handleMembershipClone() }>
                      Clone
                  </Button>
                  <Button color="success" className="ml-3" onClick={() => this.onClickSaveMembership()}>
                    <i className="fa fa-check"></i>&nbsp;Save
                  </Button>
                </header> 
                
                {this.renderAlerts()}
                <Card>
                    <div className="tab-content">
                        <MembershipForm 
                          onChangeDate={this.onChangeDate.bind(this)} 
                          membership={this.state.membership} 
                          organizationOptions={this.state.organizationOptions} 
                          onChange={this.onChange.bind(this)} 
                          themeOptions={this.state.themeOptions}
                        />
                    </div>
                </Card>
            </React.Fragment>
        )
    } else {
        return null
    }

  }
  
}

export default EditMembershipForm
