import React from 'react';
import CurrencySelect from './CurrencySelect';
import { Input, Label } from 'reactstrap';

export default class Price extends React.Component {
    
    onChange(value) {
        value = this.toStoredUnit(value)
        value = Math.max(value, 0)
        this.props.onChange(value, 'price')
    }

    toStoredUnit(value) {
        return value * 100
    }

    // from the stored amount in smallest currency unit (15550) to readable cost (155,50)
    toReadableUnit(value) {
        return parseFloat(value / 100).toFixed(2)
    }

    // format value when field lose focus
    onBlur(e) {
        e.target.value = this.toReadableUnit(this.props.price)
    }

    render() {
        return <React.Fragment>
                    <Label for="price">{this.props.title ? this.props.title : 'Price'}</Label>
                    <div className='d-flex'>       
                        <Input className='mr-1' min={0} onBlur={(e) => this.onBlur(e)} style={{width: '150px'}} type="number" name="price" id="price" defaultValue={this.toReadableUnit(this.props.price)} onChange={(e) => this.onChange(e.target.value)} />
                        <CurrencySelect currency={this.props.currency} onChange={this.props.onChange} />
                    </div> 
                </React.Fragment>
    }

}
