import React, { Component } from 'react';
import { Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import * as Icon from 'react-feather';

class Organization extends Component {

  renderChildOrganizations(organizations) {
    if (organizations && organizations.length > 0) {
      return organizations.map((organization) => {
        return <Organization key={organization.id} organization={organization} root={false}></Organization>
      })
    }
  }

  render() {
    var subOrgCount = this.props.organization.organizations ? this.props.organization.organizations.length : 0
    var classes = this.props.root ? '' : ' mx-3'
    var cursorClass = subOrgCount > 0 ? ' cursor-pointer' : ''

    return (
      <div>
        <Link className="member-card-link" from={'/organizations'} to={'/organization/edit/' + this.props.organization.id}>
          <Card className={cursorClass + " card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-center" + classes}>
            <div className="mb-0 text-small w-50 w-sm-100">
              <p className="list-item-heading mb-1">Name</p>
              <p className="list-item-text mb-0">{this.props.organization.name}</p>
            </div>
            <div className="mb-0 text-small w-50 w-sm-100">
              <p className="list-item-heading mb-1">Organization ID</p>
              <p className="list-item-text mb-0">{this.props.organization.id}</p>
            </div>
            <div className="mb-0 text-small w-50 w-sm-100">
              <p className="list-item-heading mb-1">Child organizations</p>
              <p className="list-item-text mb-0">{subOrgCount}</p>
            </div>
            { 
              <div className="member-card-link w-20" onClick={(e) => { e.preventDefault(); e.stopPropagation(); this.setState({ isExpanded: this.state ? !this.state.isExpanded : true }) }}>
                {this.state && this.state.isExpanded && subOrgCount > 0  ?
                  <Icon.ChevronUp size="20" color={subOrgCount > 0 ? "#000" : "#eeeeee"}  />
                  : 
                  <Icon.ChevronDown size="20" color={subOrgCount > 0 ? "#000" : "#eeeeee"} />
                }
              </div>}
          </Card>
        </Link>
        <div className={classes} >
          {this.state && this.state.isExpanded && this.renderChildOrganizations(this.props.organization.organizations)}
        </div>
      </div>
    );
  }

}

export default Organization;
