import React, { Component } from 'react';
import {Col, Card, CardHeader, CardBody, Row} from 'reactstrap'
import { Doughnut } from 'react-chartjs-2';


class FormStatistics extends Component {

  handleDoughnutClick = (elements, event, data, resultItem, filterFormOption) => {
    if (event.type === "click" && elements.length) {
      const index = elements[0]._index;
      const dataset = data.datasets[0];
      const titles = data.labelsTitle || [];
    
      if (dataset && dataset.data && dataset.data[index] !== undefined) {
        const labelsTitle = titles[index];
        filterFormOption(resultItem, labelsTitle);
      }
    }
  };

  
  chartConfig(resultItem) {

    const isXsDevice = window.innerWidth < 576; 

    const chartOptions = {
      responsive: true,
        legend: {
         display: true,
         // Filter label text onClick
         onClick: (event, legendItem) => {
          const lastColonIndex = legendItem.text.lastIndexOf(':');
          const labelTitle = lastColonIndex !== -1 ? legendItem.text.slice(0, lastColonIndex) : legendItem.text;
          this.props.filterFormOption(resultItem, labelTitle);
        },
         rtl: true,
         position: 'left',
         labels: {
          fontSize: 14,
          pointStyle: "circle",
          usePointStyle: true,
          boxWidth: !isXsDevice ? 10 : 12, // change size for the circles in labels
          generateLabels: (chart) => {
            const data = chart.data;
            if (data.labelsTitle.length && data.datasets.length) {              
              return data.labelsTitle.map((label, index) => {
                const dataset = data.datasets[0];
                const value = dataset.data[index];
                
                return {
                  text: `${label}: ${value}`, 
                  originalText: `${label}`, 
                  labelClass: 'legend-label',
                  fillStyle: dataset.backgroundColor ? dataset.backgroundColor[index] : 'rgba(0,0,0,0)', // Color on the small circle in the label
                  lineWidth: dataset.borderWidth ? dataset.borderWidth : 0, 
                  hidden: isNaN(value) || value <= 0,
                  index: index,
                };
              });
            }
            return [];
          },
         },
         onHover: (event, legendItem) => {
          event.target.style.cursor = legendItem ? 'pointer' : 'default';
        },
      },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              // Text on chart slice on hover
              const labelTitle = data.labelsTitle[tooltipItem.index];
              const count = data.datasets[0].data[tooltipItem.index];
              return `${labelTitle}: ${count}`;
            },
          },
        },
    };

    return chartOptions;
  }
  

  setDoughnutData = (formOptions) => {
    console.log(formOptions)

    // Color options for the slices on the doughnut chart
    const backgroundColors = [
        'rgb(207, 94, 31)',
        'rgb(139, 114, 251)',
        'rgb(48, 205, 142)',
        'rgb(85, 70, 199)',
        'rgb(255, 147, 63)',
        'rgb(29, 69, 164)',
        'rgb(15, 127, 105)',
        'rgb(255, 0, 128)',
      ]

    // Color for the slices on the doughnut chart on hover (darker variants of the backgroundColors)
    const hoverBackgroundColors = [
        'rgb(167, 54, 0)',  
        'rgb(99, 74, 182)',  
        'rgb(8, 165, 92)',   
        'rgb(45, 20, 130)',
        'rgb(215, 107, 18)', 
        'rgb(23, 55, 131)',
        'rgb(12, 102, 84)',
        'rgb(230, 0, 100)',
    ]
    
    const doughnutData = {
      labels: [],
      labelsTitle: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          hoverBorderColor: "grey",
        }
      ],
    };

    formOptions.forEach((option, index) => {
      doughnutData.labels.push(`${option.value}: ${option.count}`);
      doughnutData.labelsTitle.push(option.value);
      doughnutData.datasets[0].data.push(option.count);

      const moreOptionsThanColors = formOptions.length > backgroundColors.length;
      const randomColorIndex = Math.floor(Math.random() * backgroundColors.length);

      // Random colors for doughnuts with more options than available colors
      const backgroundColor = moreOptionsThanColors ? backgroundColors[randomColorIndex] : backgroundColors[index % backgroundColors.length];

      // Set the random index on hoverBackgroundColor so the hover color is correct too
      const hoverBackgroundColor = moreOptionsThanColors ? hoverBackgroundColors[randomColorIndex] : hoverBackgroundColors[index % hoverBackgroundColors.length];

      doughnutData.datasets[0].backgroundColor.push(backgroundColor);
      doughnutData.datasets[0].hoverBackgroundColor.push(hoverBackgroundColor);
      
      
    });
      
    doughnutData.labels = doughnutData.labels.map(label => label.replace(/ : /g, ' '));

    return doughnutData;
  };


  renderFormResult(matchingMemberTickets) {
    const result = [];
               
    matchingMemberTickets.forEach((matchingMemberTicket) => {
      if(matchingMemberTicket.form_member_results) {

        if (!matchingMemberTicket?.form_member_results?.[0].form_option_results) {
          return null
        }

        matchingMemberTicket.form_member_results[0].form_option_results.forEach((memberResult) => {
          const existingFormItemIndex = result.findIndex(item => item.form_item.id === memberResult.form_item.id);
      
          if (existingFormItemIndex === -1) {
            result.push({
              form_item: memberResult.form_item,
              form_options: [{ ...memberResult.form_option, count: 1 }],
            });
          } else {
              const existingFormOptionIndex = result[existingFormItemIndex].form_options.findIndex(option => option.id === memberResult.form_option.id);
      
              if (existingFormOptionIndex === -1) {
                result[existingFormItemIndex].form_options.push({ ...memberResult.form_option, count: 1 });
              } else {
                result[existingFormItemIndex].form_options[existingFormOptionIndex].count++;
              }
            }
          });
        } else {
          return null;
        }
    });
      // Push the question card with type "text" to the end of the results for layout at rendering 
      result.sort((a, b) => {
        if (a.form_item.type === "text" && b.form_item.type !== "text") {
          return 1;
        } else if (a.form_item.type !== "text" && b.form_item.type === "text") {
          return -1;
        }
          return 0;
      });
      console.log(result)
      return(this.renderFormQuestionResult(result))
  }

  
  renderTotalAnswers(matchingMemberTicket) {
    
    return (
      <p className="ml-3 mb-4 m-b-20 inline-block bold-600">
        <span>Form answers: {this.props.getFormAnswersCount(matchingMemberTicket)}</span>                  
      </p>
    )
  }


  renderDoughnutChart = (resultItem, doughnutData) => {
    return (
      <>
      <div className='d-flex'>
        <Doughnut
          data={doughnutData}
          options={this.chartConfig(resultItem)}
          getElementAtEvent={(elements, event) => {
            this.handleDoughnutClick(elements, event, doughnutData, resultItem, this.props.filterFormOption);
          }}
        />
      </div>
      </>
    );
  };


  renderChartByQuestionType(resultItem) { 
    if (!resultItem) {
      return 0;
    }
  
    if (resultItem.form_item.type === "multiple_choice" && resultItem.form_options) {
      const multipleChoiceDonutData = this.setDoughnutData(resultItem.form_options);
      return this.renderDoughnutChart(resultItem, multipleChoiceDonutData);
    }
  
    if (resultItem.form_item.type === "checkbox" && resultItem.form_options) {
      const checkboxDonutData = this.setDoughnutData(resultItem.form_options);
      return this.renderDoughnutChart(resultItem, checkboxDonutData);
    }
  
    if (resultItem.form_item.type === "text") {
      return (
        <span onClick={() => this.props.filterFormOption(resultItem)} className="hover-pointer">
          <p>{resultItem?.form_options?.[0]?.count ?? 0} answers</p>
        </span>
      );
    }
  
    return resultItem?.form_options?.[0]?.count ?? 0;
  }
       
  renderFormQuestionResult(result) {  
    return (
      result.map((resultItem, index) => (
        <Col xl={6} lg={6} md={12} xs={12} key={index}>
          <Card>
            <CardHeader>
              {resultItem.form_item.title}
            </CardHeader>
            <CardBody>
              {this.renderChartByQuestionType(resultItem)}
            </CardBody>
          </Card>
        </Col>
      ))
    );
  }

  render() {
    return (
      <>         
        <Row>
          {this.renderTotalAnswers(this.props.matchingMemberTickets)}
        </Row>
        <Row className='mr-2'>
          {this.renderFormResult(this.props.matchingMemberTickets)}
        </Row> 
      </>
    )
  }
}

export default FormStatistics;