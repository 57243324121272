import React, { Component } from 'react';
import { Button, Card, CardBody, Form, FormGroup, Input, Label } from 'reactstrap';
import { logInUser, saveToken, getCurrentUser } from '../../../vibe/helpers/apiHelper';
import * as Icon from 'react-feather';
import { Redirect } from "react-router-dom";
import { removeTokens, getRefreshToken } from './Token';
import { Link } from "react-router-dom";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resetPasswordSuccessMessage: null,
      user_email: '',
      user_password: '',
      isError: {
        user_email: '',
        user_password: '',
      },
      forSubmit: false,
      errorMessage: '',
    }
  }

  submitForm(e) {
    e.preventDefault();
    this.setState({ forSubmit: true })

    var validate = this.validateOnSubmit()
    if (validate) {
      this.onLoginClick()
    }

  }

  validateOnSubmit() {
    let isError = { ...this.state.isError };

    if (this.state.user_email.length === 0) {
      isError.user_email = 'Email is required';
    }
    if (this.state.user_password.length === 0) {
      isError.user_password = 'Password is required';
    }
    this.setState({
      isError,
    })
    return isError.user_email.length === 0 && isError.user_password.length === 0
  }

  onLoginClick() {
    logInUser(this.state.user_email, this.state.user_password, (tokenObject) => {
      if (tokenObject.error) {
        this.setState({ errorMessage: tokenObject.error.message })
        return;
      }
      saveToken(tokenObject);
      const { history } = this.props

      getCurrentUser((user) => {
        if (user.role === 'Scanner') {
          history.push('/ticket-scanner')
        } else if (user.role === 'VerifyStudent' || user.role === 'AdminVerifyStudent') {
          history.push('/verify-student')
        } else if (user.role === 'PackageTransaction') {
          history.push('/transactions/packages')
        } else if (user.role === 'Financial') {
          history.push('/payouts/all')
        } else {
          history.push('/home')
        }
      })

    }, (error) => {
      this.setState({ errorMessage: error.message })
      console.log(error);
    })
  }

  onChange = e => {
    e.preventDefault();
    const regExp = RegExp(
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/
    )

    this.setState({ errorMessage: '' })

    const { name, value } = e.target;
    let isError = { ...this.state.isError };

    switch (name) {
      case "user_email":
        isError.user_email = regExp.test(value)
          ? ""
          : "Email address is invalid";
        break;
      case "user_password":
        isError.user_password =
          value.length < 8 ? "Password is required" : "";
        break;
      default:
        break;
    }

    this.setState({
      isError,
      [name]: value
    })
  }

  componentDidMount() {
    // Check for success message in location state
    const locationState = this.props.location.state || {};
    const successMessage = locationState.successMessage || '';

    // If success message exists, display it
    if (successMessage) {
      this.setState({ resetPasswordSuccessMessage: successMessage });
      this.props.history.replace('/login', {});      
    }
  }

  render() {
    // Redirect to home if already logged-in
    if (getRefreshToken()) {
      return <Redirect to="/home" />;
    } else {
      removeTokens()
    }

    const { isError, user_email, user_password, forSubmit } = this.state;

    return (
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg"><span className="r"></span><span className="r s"></span><span className="r s"></span><span className="r"></span></div>
          <Card>
            <CardBody>
              <Form onSubmit={(e) => this.submitForm(e)} className='mx-auto' noValidate>
                <div className="text-center">
                  <div className="mb-4"><div className="auth-icon"><Icon.Unlock color="white" /></div></div>
                  <h5 className="mb-4">Login to Your Account</h5>
                </div>
                {this.state.resetPasswordSuccessMessage && (
                  <div className="alert alert-success mb-4">{this.state.resetPasswordSuccessMessage}</div>
                )}
                <FormGroup>
                  <Label for="user_email">Email</Label>
                  <Input
                    type="text" name="user_email" id="user_email"
                    valid={isError.user_email.length === 0 && user_email.length > 0}
                    invalid={(isError.user_email.length > 0 || user_email.length === 0 || this.state.errorMessage.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_email.length > 0 && (
                    <span className="invalid-feedback">{isError.user_email}</span>
                  )}
                </FormGroup>
                <FormGroup>
                  <Label for="user_password">Password</Label>
                  <Input type="password" name="user_password" id="user_password"
                    valid={isError.user_password.length === 0 && user_password.length > 0}
                    invalid={(isError.user_password.length > 0 || user_password.length === 0 || this.state.errorMessage.length > 0) && forSubmit}
                    onChange={this.onChange} />
                  {isError.user_password.length > 0 && (
                    <span className="invalid-feedback">{isError.user_password}</span>
                  )}
                </FormGroup>
                <Button className={this.state.errorMessage ? 'is-invalid btn-block mb-4' : 'btn-block mb-4'} color="primary" type="submit">Log in</Button>
                {this.state.errorMessage && (
                  <span className="invalid-feedback mb-4">{this.state.errorMessage}</span>
                )}

                {/* TODO add later */}
                <p className="mb-2 text-muted"><a href="/forgot-password"> Forgot password?  </a></p>
                <p className="mb-0 text-muted">Don't have an account yet? <Link to="/signup">Sign up</Link></p>
              </Form>
            </CardBody>
          </Card>
        </div>
      </div >
    )
  }
}

export default Login;
