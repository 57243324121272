import React, { Component } from 'react';
import Select from 'react-select'

import { Card, Button } from 'reactstrap';
import { Loader } from '../../../vibe';

class OrganizationRelations extends Component {

    componentDidMount() {

    }

    renderParentRelations() {
        return <React.Fragment>
            {!this.props.showNewParentForm &&
                <Button className='mb-4 mt-2' color="primary" onClick={() => this.props.updateState('showNewParentForm', true)}>Connect to parent</Button>
            }
            {this.props.showNewParentForm && 
                <Card>
                    <div className="row mx-1 my-3">
                        <div className="col">
                            <Select options={this.props.options} onChange={(e) => this.props.onParentOrgSelectChange(e)} />
                        </div>
                        <div className="col">
                            <Button className='mr-2' color="primary" disabled={!this.props.currentNewParentRelation} onClick={() => this.props.onClickNewParentRelationAdd()}>Add</Button>
                            <Button color="primary" onClick={() => this.props.updateState('showNewParentForm', false)}>Cancel</Button>
                        </div>
                    </div>
                </Card>
            }
            {this.props.pendingNewParentRelations.map((pendingNewParentRelation, i) => {
                return (
                    <Card className="bg-warning b-a card-body list-item-text bold-600" key={i}>{pendingNewParentRelation.id} | {pendingNewParentRelation.name}  </Card>
                )
            })}

            {this.props.parentOrganizationRelations.map((organizationRelation, i) => {
                var cardBgClass = this.props.pendingDeleteOrganizationRelations.includes(organizationRelation.id) ? ' bg-danger' : ''
                return (
                    <Card className={"b-a card-body" + cardBgClass} key={i}>
                        <div className="row align-items-center">
                            <div className="col list-item-text bold-600">
                                {organizationRelation.parent.id} | {organizationRelation.parent.name}
                            </div>
                            <div className="col">
                                <Button className='btn-inline-block float-right' color="danger" onClick={() => this.props.onDeleteOrganizationRelation(organizationRelation.id)}>Delete</Button>
                            </div>
                        </div>
                    </Card>
                )
            })}
        </React.Fragment>
    }

    renderChildRelations() {
        return <React.Fragment>
            {!this.props.showNewChildForm &&
                    <Button className='mb-4 mt-2' color="primary" onClick={() => this.props.updateState('showNewChildForm', true)}>Connect to child</Button>
                }
                {this.props.showNewChildForm && 
                    <Card>
                        <div className="row mx-1 my-3">
                            <div className="col">
                                <Select options={this.props.options} onChange={(e) => this.props.onChildOrgSelectChange(e)} />
                            </div>
                            <div className="col">
                                <Button className='mr-2' color="primary" disabled={!this.props.currentNewChildRelation} onClick={() => this.props.onClickNewChildRelationAdd()}>Add</Button>
                                <Button color="primary" onClick={() => this.props.updateState('showNewChildForm', false)}>Cancel</Button>
                            </div>
                        </div>
                    </Card>
                }
                {this.props.pendingNewChildRelations.map((pendingNewChildRelation, i) => {
                    return (
                        <Card className="bg-warning b-a card-body list-item-text bold-600" key={i}>{pendingNewChildRelation.id} | {pendingNewChildRelation.name}  </Card>
                    )
                })}

                {this.props.childOrganizations.map((childOrganization, i) => {
                    var cardBgClass = this.props.pendingDeleteOrganizationRelations.includes(childOrganization.id) ? ' bg-danger' : ''
                    return (
                        <Card className={"b-a card-body" + cardBgClass} key={i}>
                            <div className="row align-items-center">
                                <div className="col list-item-text bold-600">
                                    {childOrganization.organization.id} | {childOrganization.organization.name} 
                                </div>
                                <div className="col">
                                    <Button className='btn-inline-block float-right' color="danger" onClick={() => this.props.onDeleteOrganizationRelation(childOrganization.id)}>Delete</Button>
                                </div>
                            </div>
                        </Card>
                    )
                })}
        </React.Fragment>
    }

    render() {
        if (this.props.organizationRelationError) {
            return <div>{this.props.organizationRelationError}</div>
        } else if (this.props.parentOrganizationRelations && this.props.childOrganizations ) {
            return <div>
                <h1 className="h4 mb-3 mt-0">Parent organizations</h1>
                {this.props.parentOrganizationRelations.length === 0 && <div>No parent organizations</div>}
                {this.renderParentRelations()}

                <h1 className="h4 mb-3 mt-5">Child organizations</h1>
                {this.props.childOrganizations.length === 0 && <div>No child organizations</div>}
                {this.renderChildRelations()}
            </div>
        } else {
            return <Loader type="dots" />
        }
        
    }

}

export default OrganizationRelations;
