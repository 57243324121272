import React, { Component } from 'react';
import { downloadBlob, getMemberInterestsByInterestAsCsv } from '../../../../vibe/helpers/apiHelper';
import { Card, TabContent, TabPane, Nav, NavItem, NavLink, Form, Modal, ModalBody, ModalHeader, Label } from 'reactstrap';
import classnames from 'classnames';
import MemberInterestsWithFormResults from './MemberInterestsWithFormResults';

class MemberInterests extends Component {

  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      activeTab: '3',
      opened: false,
      memberInterests: [],
      isMemberInterestsModalOpen: this.props.isOpen,
      downloadCsvMessage: ''
    }

    this.toggle = this.toggle.bind(this);
    this.toggleModal = this.toggleModal.bind(this)
  }

  componentDidMount() {
    const memberInterests = this.props.interest?.member_interests
    this.setState({memberInterests: memberInterests, interest: this.props.interest})
  }
  
  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  
  toggleModal() {
    this.setState(prevState => ({
        opened: !prevState.opened,
    }))
  }
  
  isOpen() {
    return this.state.opened
  }

  async downloadCsv() {

    const interest = this.state?.interest
    if (!interest) {
        return
    }

    const memberInterestsBlob = await new Promise((resolve, reject) => {
        getMemberInterestsByInterestAsCsv(interest?.id, (blob) => {
        resolve(blob)
      }, (error) => {
        resolve(false)
      })
    })

    const memberInterestsFileName = 'interests_' + interest?.id + '.csv'

    var files = []
    if (memberInterestsBlob?.size > 0) {
      files.push({name: memberInterestsFileName, blob: memberInterestsBlob})
    }

    if (files.length === 1) {
      const file = files?.[0]
      downloadBlob(file.blob, file.name)
      this.setDownloadCsvMessage(file.name + ' downloaded!')
    } else {
      this.setDownloadCsvMessage('No interests found. No file was downloaded')
    }

  }

  setDownloadCsvMessage(message) {
    this.setState({downloadCsvMessage: message})
    setTimeout(() => {
      this.setState({downloadCsvMessage: ''})
    }, 4000);
  }

  renderTabs() {
    
    return <>
        <Card>
            <div>
                <Nav tabs style={{background:'none'}}>
                <NavItem>
                    <NavLink href="#" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => {this.toggle('3')}}>
                    Registrations of interest
                    </NavLink>
                </NavItem>
                </Nav>
                <div style={{float: 'right', textAlign: 'end'}}>
                  <Label className='mt-1' style={{cursor: 'pointer'}} onClick={() => this.downloadCsv()}>Download CSV</Label>
                  {this.state.downloadCsvMessage && <div className='mini-font'>{this.state.downloadCsvMessage}</div>}
                </div>

            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="3">
                    {this.state.activeTab === '3' && ( <>
                        <MemberInterestsWithFormResults memberInterests={this.state.memberInterests} />
                    </>)}
                </TabPane>
            </TabContent>
            </div>
        </Card>
    </>
    
  }

  renderModal() {
    return <>
        <Modal size="xl" scrollable={true} isOpen={this.props.isOpen} toggle={this.props.toggle} className="previewModal" style={{background:'#f2f5f9'}}>
            <ModalHeader toggle={this.props.toggle}></ModalHeader>
            <ModalBody className='modal-body-no-scrollbar'>
                <Form>
                    {this.renderTabs()}
                </Form>
                </ModalBody>
        </Modal>
    </>
  }

  render() {
    if (this.state.error) {
      return <div>Error</div>
    }
  
    if (this.state.loading) {
      return <div>Loading...</div>
    }

    return <>
      {this.renderModal()}
    </>
    }
  }
  
export default MemberInterests