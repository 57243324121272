import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import * as Icon from 'react-feather';


class FormItemText extends Component { 
    
    render() {
        const { formItem } = this.props
        return <>
        <div className='mx-4 col-6'>
            <div>
              <div className="input-group mb-3">
                <div className="d-flex align-items-center pr-2">
                  <Icon.AlignLeft size="20" color="#606469" />
                </div>
                <Input
                  className="form-control mr-2"
                  placeholder={formItem?.form_options?.[0]?.placeholder || "Write placeholder text here..."}
                  type="text"
                  name="placeholder"
                  id="placeholder"
                  defaultValue={""}
                  onChange={(e) => this.props.editFormOption(formItem?.form_options?.[0], formItem, 'placeholder', e.target.value)}
                  />
                <div style={{visibility: 'hidden'}}> {/* maintain consistent spacing */}
                      <Button color="link" className="pl-1">
                          <Icon.X size="20" color="#606469" />
                      </Button>
                </div>
              </div>
            </div>
          </div>
        </>
    }

}

export default FormItemText;