import React from 'react';
import { diffObject, prettyPrintDate } from '../../../vibe/helpers/util';

const ACTION_UPDATE = 'Update'

class TransactionLog extends React.Component { 

    constructor(props) {
        super(props);
        this.state = {}
    }

    getPreviousObject(newObject, transactionLogs, index, actions) {
        for (var i = 1; index - i >= 0; i++) {
            let oldObject = transactionLogs[index - i]
            if (actions.includes(newObject.action) && oldObject.model === newObject.model) {
                return oldObject
            }
        }
        return null
    }

    renderDiffs(diffObject, action) {
        return diffObject.map(diff => {
            if (this.props.elementStyles[diff.key]) {
                let first = this.props.elementStyles[diff.key].render(diff.first)
                let second = this.props.elementStyles[diff.key].render(diff.second)
                return this.getDiffElement(action, diff, first, second)
            }
            return this.getDiffElement(action, diff, diff.first, diff.second)
        })
    }

    getDiffElement(action, diff, firstElement, secondElement) {
        if (firstElement && secondElement) {
            return <p key={this.props.transactionLog.id + '_' + diff.key + '_' + diff.first + '_' + diff.second} className="list-item-text mb-0"><i className={'fa fa-edit'}></i> {diff.key}: {firstElement} {'->'} {secondElement}</p>
        } else if (firstElement) {
            return <p key={this.props.transactionLog.id + '_' + diff.key + '_' + diff.first + '_' + diff.second} className="list-item-text mb-0"><i className={'fa fa-minus'}></i> {diff.key}: {firstElement}</p>
        } else if (secondElement) {
            let iconClass = action === ACTION_UPDATE ? 'fa-plus' : 'fa-circle fa-icon-small'
            return <p key={this.props.transactionLog.id + '_' + diff.key + '_' + diff.first + '_' + diff.second} className="list-item-text mb-0"><i className={'fa ' + iconClass}></i> {diff.key}: {secondElement}</p>
        }
        return null
    }

    getListItems(previousTransactionLog, transactionLog, ignoreKeys) {
        var listItems

        var previousTransactionLogData = {}
        if (previousTransactionLog) {
            previousTransactionLogData = JSON.parse(previousTransactionLog.data)
        }

        let newData = JSON.parse(transactionLog.data)

        let diffs = diffObject(previousTransactionLogData, newData, ignoreKeys)
        if (diffs.length > 0) {
            listItems = this.renderDiffs(diffs, transactionLog.action)
        }
        return listItems
    }

    render() {
        let previousTransactionLog = this.getPreviousObject(this.props.transactionLog, this.props.transactionLogs, this.props.index, [ACTION_UPDATE])

        var listItems = this.getListItems(previousTransactionLog, this.props.transactionLog, this.props.ignoreKeys)

        return <div className='my-4' key={this.props.transactionLog.id}>{this.props.transactionLog.action} - {this.props.transactionLog.model} - {prettyPrintDate(this.props.transactionLog.created_at)}{listItems}</div>
    }

}

export default TransactionLog